import { LinearGradient } from 'expo-linear-gradient';
import React, { useState } from 'react';
import { withNamespaces } from 'react-i18next';
import { View, Image, StyleSheet, TouchableOpacity } from 'react-native';
import { useHistory } from 'react-router-dom';
import { useFormContext } from 'react-hook-form';

import { INPUT_BG } from '@assets/style/styleDefault';
import JButton from '@components/common/JButton';
import { JText as Text } from '@components/common/JText';
import ModalAlert from '@components/Modals/ModalAlert';
import { API_PATH } from '@helper/constant';
import { postMulti } from '@utils/RequestLevica';
import scroll2View from '@helper/scroll2View';

const MemberInfo = (props) => {
  const { t, isButton, setOpenBank, scrollViewRef, bankInfoData, isShowBankIn4Message, isBankInfoValid } = props;
  const history = useHistory();
  const { setError, handleSubmit, reset } = useFormContext();
  const [openModalUpdate, setOpenModalUpdate] = useState(false);
  const [isCreating, setIsCreating] = useState(false);

  const handleCreateAccountShop = async (accountShopData) => {
    if (accountShopData.phone?.length < 6) {
      setError('phone', {
        type: 'custom',
        message: t('errorMessages.TXT_JP_PHONE'),
      });
      scroll2View('phone', scrollViewRef.current);
      return;
    } else if (accountShopData.landlineNumber?.length < 6) {
      setError('landlineNumber', {
        type: 'custom',
        message: t('errorMessages.TXT_JP_PHONE'),
      });
      scroll2View('landlineNumber', scrollViewRef.current);
      return;
    }

    try {
      setIsCreating(true);

      await postMulti(API_PATH.SHOP_ACCOUNT, {
        ...accountShopData,
        capital: parseInt(accountShopData?.capital?.replace(/,/g, '')),
        ...bankInfoData,
      });
      setOpenModalUpdate(true);
      reset();
    } catch (error) {
      if (error?.response?.data?.code == '3014') {
        setError('merchantUserEmail', { type: 'custom', message: t('errorMessages.TXT_REGISTERED_EMAIL') });
        scroll2View('merchantUserEmail', scrollViewRef.current);
      } else if (error?.response?.data?.code == '3015') {
        setError('phone', { type: 'custom', message: t('errorMessages.TXT_REGISTERED_PHONE') });
        scroll2View('phone', scrollViewRef.current);
      } else if (error?.response?.data?.code == '3018') {
        setError('leviasChainAddress', {
          type: 'custom',
          message: t('errorMessages.TXT_WALLET_ADDRESS_DOES_NOT_EXIST_2'),
        });
        scroll2View('leviasChainAddress', scrollViewRef.current);
      }
    } finally {
      setIsCreating(false);
    }
  };

  return (
    <View>
      <View style={{ flexDirection: 'row', justifyContent: 'flex-end', marginBottom: 80 }}>
        <View style={{ paddingRight: 30 }}>
          <JButton
            text={t('commonText.cancelButton')}
            btnColor="#525252"
            textColor="#ffffff"
            width={150}
            hasIcon
            linkIcon="close-white.svg"
            widthIcon={20}
            heightIcon={20}
            onPressAction={() => history.push('/shop')}
          />
        </View>

        <LinearGradient colors={['#54DBB2', '#04C48B']} style={{ borderRadius: 5 }}>
          <JButton
            text={t('commonText.buttonSave')}
            textColor="#ffffff"
            width={150}
            hasIcon
            linkIcon="save.svg"
            linkIconDisabled="saveGray.svg"
            widthIcon={20}
            heightIcon={20}
            disabled={isButton}
            onPressAction={handleSubmit(handleCreateAccountShop)}
            isProcessing={isCreating}
          />
        </LinearGradient>
      </View>
      <TouchableOpacity onPress={() => setOpenBank(true)}>
        <LinkBankInfo
          t={t}
          text={t('pages_AccountShop_CreateAccount.bankAccountInformation')}
          isRequire
          isShowErrorMessage={isShowBankIn4Message}
          errorMessage={t('errorMessages.fieldRequired', {
            fieldName: t('pages_AccountShop_CreateAccount.bankAccountInformation'),
          })}
          isRegistered={isBankInfoValid}
        />
      </TouchableOpacity>
      <LinkBankInfo t={t} text={t('pages_AccountShop_CreateAccount.identityVerificationDocuments')} />
      <LinkBankInfo t={t} text={t('pages_AccountShop_CreateAccount.transactionManagerMyNumber')} />
      <ModalAlert
        linkPage="/shop"
        isModalVisible={openModalUpdate}
        setModalVisible={setOpenModalUpdate}
        icon="successGreen.svg"
        iconWidth={15}
        iconHeight={15}
        modalText={t('pages_AccountShop_CreateAccount.modal')}
        onBackdropPressCallback={() => history.push('/shop')}
      />
    </View>
  );
};

const LinkBankInfo = ({
  text,
  isRequire = false,
  t,
  isShowErrorMessage = false,
  errorMessage,
  isRegistered = false,
}) => {
  return (
    <View style={styles.warperBank}>
      <Text>
        {text}
        {isRequire && <Text style={{ color: 'red', marginLeft: 10 }}>✴︎</Text>}
      </Text>
      <View style={[isShowErrorMessage ? styles.borderError : '', { width: '60%' }]}>
        <View style={[styles.row, styles.bankInfoDetails, styles.marginBottom20]}>
          <Text numberOfLines={2} style={{ color: '#A5A5A5' }}>
            {isRegistered
              ? t('pages_AccountShop_CreateAccount.registered')
              : t('pages_AccountShop_CreateAccount.unregistered')}
          </Text>
          <Image style={styles.subfix} source={require('@assets/icons/iconShare.svg')} />
        </View>
        {isShowErrorMessage && <Text style={styles.errorMessage}>{errorMessage}</Text>}
      </View>
    </View>
  );
};

export default withNamespaces()(MemberInfo);

const styles = StyleSheet.create({
  warperBank: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 20,
    gap: 20,
    paddingRight: 20,
  },

  row: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    backgroundColor: INPUT_BG,
    border: '1px solid #555555',
    padding: 20,
    borderRadius: 5,
    cursor: 'pointer',
  },
  bankInfoDetails: {
    height: 42,
    backgroundColor: INPUT_BG,
    borderRadius: 3,
    paddingLeft: 15,
    paddingRight: 35,
    position: 'relative',
  },
  subfix: {
    position: 'absolute',
    width: 15,
    height: 15,
    top: '30%',
    right: 10,
  },
  errorMessage: {
    fontSize: 12,
    color: 'red',
    position: 'absolute',
    top: 'calc(100% + 1px)',
    transform: 'translateZ(0)',
  },
  borderError: {
    border: '1px solid red',
    borderRadius: 3,
  },
});

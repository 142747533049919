import React, { useCallback, useEffect } from 'react';
import { Col, Grid } from 'react-native-easy-grid';
import JInput from '@components/common/JInput';
import { useFormContext } from 'react-hook-form';
import { withNamespaces } from 'react-i18next';
import { View } from 'react-native';

import AccountInput from '@components/AccountComponents/AccountInput';
import styles from '../styles';
import { JText as Text } from '@components/common/JText';
import { get } from '@utils/RequestLevica';
import { API_PATH } from '@helper/constant';

//traderPostCode to traderRoom
const TransactionContactForm = ({ control, disable, t }) => {
  const {
    getValues,
    setError,
    setValue,
    formState: { errors },
    clearErrors,
    trigger,
  } = useFormContext();

  const clearAddressDependOnZipCode = useCallback(() => {
    setValue('traderPrefecture', '');
    setValue('traderCity', '');
    setValue('traderAddress', '');
  }, [setValue]);

  const setAddressByDataFetched = useCallback(
    (field, value) => {
      if (value) clearErrors(field);
      setValue(field, value);
    },
    [clearErrors, setValue]
  );

  const getPostCode = useCallback(
    async (code) => {
      try {
        const data = await get(`${API_PATH.POST_CODE}/${code}`);
        setAddressByDataFetched('traderPrefecture', data?.prefecture);
        setAddressByDataFetched('traderCity', data?.city);
        // setAddressByDataFetched('traderAddress', data?.street);
        // trigger(['traderPrefecture', 'traderCity', 'traderAddress']);
        trigger(['traderPrefecture', 'traderCity']);
      } catch (error) {
        if (error?.response?.data?.code == '3016') {
          setError('traderPostCode', { type: 'custom', message: t('errorMessages.TEXT_POSTAL_CODE_NOT_EXISTS') });
          clearAddressDependOnZipCode();
        }
      }
    },
    [clearAddressDependOnZipCode, setAddressByDataFetched]
  );

  useEffect(() => {
    if (!errors?.traderPostCode && getValues('traderPostCode')) {
      getPostCode(getValues('traderPostCode'));
    }

    if (errors?.traderPostCode) clearAddressDependOnZipCode();
  }, [!errors?.traderPostCode, clearAddressDependOnZipCode]);

  return (
    <>
      <Grid style={styles.marginBottom20}>
        <Col size={4}>
          <Text style={styles.colorWhite}>{t('pages_AccountCompany_CreateAccount.transactionContactForm')}</Text>
        </Col>
        <Col size={8}></Col>
      </Grid>

      <Grid style={styles.marginBottom20}>
        <Col size={4} style={styles.flex}>
          <View nativeID="traderPostCode">
            <Text style={[styles.colorWhite, styles.marginLeft12]}>
              {t('pages_AccountCompany_CreateAccount.transactionZipCode')}
            </Text>
          </View>
          <Text style={styles.iconRequired}>✴︎</Text>
        </Col>
        <Col size={8} style={styles.flex}>
          <Grid>
            <Col size={5} style={{ position: 'relative', minWidth: 200 }}>
              <Text style={[styles.startIcon, styles.colorWhite]}>〒</Text>
              <JInput
                disable={disable}
                control={control}
                name="traderPostCode"
                placeholder={t('pages_AccountCompany_CreateAccount.transactionZipCodePlaceholder')}
                subfix="iconEdit.svg"
                widthSubfix={15}
                heightSubfix={15}
                paddingLeft={10}
              />
            </Col>
            <Col size={7} style={styles.flex}>
              <Text style={[styles.marginLeft8, styles.colorGray]}>
                {t('pages_AccountCompany_CreateAccount.transactionZipCodeNote')}
              </Text>
            </Col>
          </Grid>
        </Col>
      </Grid>

      <Grid style={styles.marginBottom20}>
        <Col size={4} style={styles.flex}>
          <View nativeID="traderPrefecture">
            <Text style={[styles.colorWhite, styles.marginLeft12]}>
              {t('pages_AccountCompany_CreateAccount.transactionPrefectures')}
            </Text>
          </View>
          <Text style={styles.iconRequired}>✴︎</Text>
        </Col>
        <Col size={8} style={styles.flex}>
          <Grid>
            <Col size={5} style={{ position: 'relative', minWidth: 200 }}>
              <JInput
                disable={disable}
                control={control}
                name="traderPrefecture"
                placeholder={t('pages_AccountCompany_CreateAccount.transactionZipCodePlaceholder')}
                subfix="iconEdit.svg"
                widthSubfix={15}
                heightSubfix={15}
                paddingLeft={10}
              />
            </Col>
            <Col size={7} style={styles.flex}></Col>
          </Grid>
        </Col>
      </Grid>

      <AccountInput
        disable={disable}
        control={control}
        name="traderCity"
        placeholder={t('pages_AccountCompany_CreateAccount.transactionMunicipalityPlaceholder')}
        isRequired={true}
        labelText={t('pages_AccountCompany_CreateAccount.transactionMunicipality')}
        subfix="iconEdit.svg"
        ml={12}
      />

      <AccountInput
        disable={disable}
        control={control}
        name="traderAddress"
        placeholder={t('pages_AccountCompany_CreateAccount.transactionAddressPlaceholder')}
        isRequired={true}
        labelText={t('pages_AccountCompany_CreateAccount.transactionAddress')}
        subfix="iconEdit.svg"
        ml={12}
      />

      <AccountInput
        disable={disable}
        control={control}
        name="traderBuilding"
        placeholder={t('pages_AccountCompany_CreateAccount.transactionBuildingNamePlaceholder')}
        isRequired={true}
        labelText={t('pages_AccountCompany_CreateAccount.transactionBuildingName')}
        subfix="iconEdit.svg"
        ml={12}
      />

      <Grid style={styles.marginBottom20}>
        <Col size={4} style={styles.flex}>
          <Text style={[styles.colorWhite, styles.marginLeft12]}>
            {t('pages_AccountCompany_CreateAccount.transactionNumberOfFloor')}
          </Text>
        </Col>
        <Col size={8} style={styles.flex}>
          <Grid>
            <Col size={4} style={{ position: 'relative' }}>
              <JInput
                disable={disable}
                control={control}
                name="traderFloor"
                placeholder={t('pages_AccountCompany_CreateAccount.transactionNumberOfFloorPlaceholder')}
                subfix="iconEdit.svg"
                widthSubfix={15}
                heightSubfix={15}
                rightSubfix={10}
                paddingLeft={10}
              />
            </Col>
            <Col size={2} style={styles.flex}>
              <View nativeID="traderFloor">
                <Text style={[styles.marginLeft8]}>
                  {t('pages_AccountCompany_CreateAccount.transactionNumberOfRoom')}
                </Text>
              </View>
            </Col>
            <Col size={4} style={{ position: 'relative' }}>
              <JInput
                disable={disable}
                control={control}
                name="traderRoom"
                placeholder={t('pages_AccountCompany_CreateAccount.transactionNumberOfFloorPlaceholder')}
                subfix="iconEdit.svg"
                widthSubfix={15}
                heightSubfix={15}
                rightSubfix={10}
                paddingLeft={10}
              />
            </Col>
            <Col size={2} style={styles.flex}>
              <View nativeID="traderRoom">
                <Text style={[styles.marginLeft8]}>
                  {t('pages_AccountCompany_CreateAccount.transactionNumberOfRoomNote')}
                </Text>
              </View>
            </Col>
          </Grid>
        </Col>
      </Grid>
    </>
  );
};

export default withNamespaces()(TransactionContactForm);

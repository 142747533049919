import { StyleSheet } from 'react-native';
import {
  BG_COLOR,
  COLOR_TEXT_DEFAULT,
  FONT_FAMILY_DEFAULT,
  FONT_SIZE_DEFAULT,
  FONT_SIZE_HEADER,
  FONT_FAMILY_HEADER,
  BORDER_TABLE_GREY,
  BTN_COLOR_GREY,
  BG_HOVER,
  BG_SIDEBAR,
  LINE_COLOR,
} from '@assets/style/styleDefault';

const styles = StyleSheet.create({
  //common
  text: {
    color: COLOR_TEXT_DEFAULT,
    fontSize: FONT_SIZE_DEFAULT,
  },
  textHeader: {
    color: COLOR_TEXT_DEFAULT,
    fontSize: FONT_SIZE_HEADER,
  },
  container: { flex: 1, backgroundColor: BG_COLOR, height: '100%' },
  //icon
  statusIcon: {
    width: 10,
    height: 10,
    marginBottom: 2,
    marginRight: 5,
  },
  //header
  dFlex: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 20,
    paddingLeft: 50,
  },

  //body
  body: {
    display: 'flex',
    flexDirection: 'row',
    padding: 20,
    minHeight: 600,
  },

  left: {
    paddingHorizontal: 20,
  },

  mainAvatar: {
    width: 'fit-content',
    marginRight: 10,
    position: 'relative',
  },
  mainAvatarImage: {
    maxHeight: 200,
    maxWidth: 200,
    minHeight: 150,
    minWidth: 150,
    width: '10vw',
    height: '10vw',
    borderRadius: 100,
  },

  //item
  warperItem: {
    borderBottomColor: LINE_COLOR,
    borderBottomWidth: 1,
    paddingVertical: 15,
    paddingHorizontal: 10,
  },
  //right
  right: {
    padding: 10,
    borderRadius: 4,
    backgroundColor: BG_SIDEBAR,
    height: '100%',
  },
  warperItemRight: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingVertical: 15,
    paddingHorizontal: 10,
  },
  itemRightHeader: {
    borderBottomColor: LINE_COLOR,
    borderBottomWidth: 1,
    marginBottom: 10,
  },
  avatarItemRight: {
    width: 40,
    height: 40,
    marginRight: 5,
  },
  itemRightHovered: {
    backgroundColor: BG_HOVER,
    borderRadius: 4,
  },
  information: {
    paddingLeft: 10,
    paddingVertical: 50,
  },
});

export default styles;

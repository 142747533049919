import React from 'react';
import { View, StyleSheet, Image } from 'react-native';
import Modal from 'react-native-modal';
import { withNamespaces } from 'react-i18next';

import { FONT_SIZE_HEADER, BORDER_TABLE_GREY } from '@assets/style/styleDefault';
import { JText as Text } from '..//common/JText';
import ModalBackDrop from './ModalBackDrop';

const ModalSendFail = (props) => {
  const { isModalVisible, setModalVisible, t } = props;

  return (
    <View>
      <Modal
        animationIn="fadeIn"
        isVisible={isModalVisible}
        style={{ alignItems: 'center' }}
        customBackdrop={<ModalBackDrop onPress={() => setModalVisible(false)} />}
      >
        {/* <Link to="/admin" style={{ textDecoration: 'none' }}> */}
        <View style={styles.wrapperModal}>
          <View style={styles.maxWidth700}>
            <View style={styles.header}>
              <Image
                source={require(`../../assets/icons/sendBlueFail.svg`)}
                style={styles.statusIcon}
                alt="statusIcon"
              />
              <Text style={styles.textHeader}>{t('components_Modals_ModalSendFail.textHeader1')}</Text>
            </View>
            <View style={styles.body}>
              <Image source={require(`../../assets/icons/warning.svg`)} style={styles.iconWarning} alt="statusIcon" />
              <View>
                <Text style={styles.textHeader}>{t('components_Modals_ModalSendFail.textHeader2')}</Text>
                <Text style={styles.textHeader}>{t('components_Modals_ModalSendFail.textHeader3')}9999</Text>
              </View>
            </View>
          </View>
        </View>
        {/* </Link> */}
      </Modal>
    </View>
  );
};

export default withNamespaces()(ModalSendFail);

const styles = StyleSheet.create({
  //common

  textHeader: {
    color: 'black',
    fontSize: FONT_SIZE_HEADER,
    textAlign: 'center',
  },
  wrapperModal: {
    maxWidth: 900,
    minWidth: 600,
  },

  iconWarning: {
    width: 55,
    height: 46,
    padding: 10,
    marginRight: 20,
  },

  maxWidth700: {
    backgroundColor: '#fff',
    borderRadius: 10,
    paddingVertical: 30,
  },
  header: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    borderBottomColor: BORDER_TABLE_GREY,
    borderBottomWidth: 1,
    paddingBottom: 20,
  },
  statusIcon: {
    width: 60,
    height: 30,
    marginBottom: 2,
    marginRight: 10,
  },
  body: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    padding: 40,
  },
});

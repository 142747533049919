import { BG_TABLE_DEFAULT } from '@assets/style/styleDefault';
import React from 'react';
import { StyleSheet, ActivityIndicator, View } from 'react-native';

const Loading = ({ style, className }) => {
  return (
    <View style={[styles.container, style, className]}>
      <View>
        <ActivityIndicator size="large" color="#00CCB0" />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: BG_TABLE_DEFAULT,
    opacity: 0.7,
    height: '100vh',
    width: '100%',
    position: 'absolute',
    top: 0,
  },
});

export default Loading;

import { StyleSheet } from 'react-native';
import { BG_COLOR, COLOR_TEXT_DEFAULT } from '@assets/style/styleDefault';

const styles = StyleSheet.create({
  container: {
    justifyContent: 'center',
    alignItems: 'center',
    position: 'fixed',
    right: 0,
    bottom: 0,
    width: '100%',
    height: '100%',
    backgroundColor: BG_COLOR,
  },
  textContent: {
    color: COLOR_TEXT_DEFAULT,
    fontSize: 30,
    padding: 30,
  },
  textLink: {
    color: COLOR_TEXT_DEFAULT,
    fontSize: 16,
  },
});

export default styles;

import { StyleSheet } from 'react-native';
import {
  BG_COLOR,
  COLOR_TEXT_DEFAULT,
  FONT_FAMILY_DEFAULT,
  FONT_SIZE_HEADER,
  FONT_WEIGHT_DEFAULT_HEADER,
} from '@assets/style/styleDefault';
//style common
const DFlex = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
};

//style home page
const styles = StyleSheet.create({
  container: { flex: 1, backgroundColor: BG_COLOR, position: 'relative' },
  defaultTextColor: {
    color: `${COLOR_TEXT_DEFAULT}`,
    fontSize: `${FONT_FAMILY_DEFAULT}`,
    paddingRight: 30,
  },
  textHeaderTittle: {
    fontSize: FONT_FAMILY_DEFAULT,
    color: COLOR_TEXT_DEFAULT,
    fontSize: FONT_SIZE_HEADER,
    fontWeight: FONT_WEIGHT_DEFAULT_HEADER,
  },
  homePage: {
    paddingLeft: '10px',
  },
  header: {
    // paddingRight: 20,
  },
  summary: {
    ...DFlex,
    paddingRight: 30,
    paddingLeft: 10,
    marginBottom: 15,
  },
  summaryTime: {
    ...DFlex,
    paddingBottom: 10,
  },
  summaryTimeImg: {
    width: '24px',
    height: '24px',
    marginRight: '10px',
  },
  //style card
  card: {
    ...DFlex,
    marginBottom: 15,
    marginRight: 10,
    gap: 15,
  },

  CardImgBackground: {
    height: '12vw',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    textAlign: 'right',
  },
  CardContent: {
    paddingTop: '10%',
    paddingRight: '10%',
  },
  CardContentNumber: {
    fontSize: '1.7em',
    color: '#fff',
    textAlign: 'right',
  },
  CardContentText: {
    fontSize: '1.5em',
    color: '#fff',
    textAlign: 'right',
  },
  //style data1

  OverviewTable: {
    paddingRight: 30,
    paddingLeft: 30,
  },
  OverviewTableBorderBottom: {
    ...DFlex,
    paddingBottom: 15,
    paddingTop: 15,
    borderBottomColor: '#3f3e3e',
    borderBottomWidth: 1,
  },
  OverviewTableNoBorderBottom: {
    ...DFlex,
    paddingBottom: 15,
    paddingTop: 15,
  },
  //css doughnut table

  doughnutChar: {
    ...DFlex,
    justifyContent: 'center',
  },
  DoughnutTable: {
    ...DFlex,
    padding: 10,
  },
  DoughnutTableContent: {
    ...DFlex,
  },
  DoughnutTableColor: {
    width: 10,
    height: 10,
    borderRadius: 50,
    backgroundColor: 'red',
    marginRight: 5,
  },
  //style data2
  resultTable: {
    ...DFlex,
    justifyContent: 'center',
    paddingTop: 30,
    paddingBottom: 30,
  },
  resultTableItem: {
    margin: 20,
  },
  resultTableItemValue: {
    color: `${COLOR_TEXT_DEFAULT}`,
    marginBottom: 10,
    fontWeight: 'bold',
    fontSize: 22,
  },
  resultTableItemContent: {
    color: `${COLOR_TEXT_DEFAULT}`,
    fontWeight: 'bold',
    fontSize: 18,
  },
});

export default styles;

import React, { useCallback, useEffect, useState } from 'react';
import { View, Image, ScrollView, Pressable, TouchableOpacity, Dimensions } from 'react-native';
import { Col, Grid } from 'react-native-easy-grid';
import { useForm } from 'react-hook-form';
import moment from 'moment';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { LinearGradient } from 'expo-linear-gradient';
import { withNamespaces } from 'react-i18next';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Center } from 'native-base';

import { formatNumber, statusColorTransaction } from '@helper/formatTypes';
import HeaderSearch from '@components/Headers/HeaderSearch';
import Pagination from '@components/common/Pagination';
import JButton from '@components/common/JButton';
import styles from './style';
import { JText as Text } from '@components/common/JText';
import JCheckbox from '@components/common/JCheckbox';
import { BG_TABLE_DEFAULT, INPUT_BG, LINE_COLOR, TEXT_COLOR_GREEN } from '@assets/style/styleDefault';
import DateTimePicker from '@components/common/DateTimePicker';
import ModalContactStatus from './ModalContactStatus';
import { downloadCSV, get } from '@utils/RequestLevica';
import { API_PATH } from '@helper/constant';
import Loading from '@components/common/Loading';

const defaultValues = {
  startDate: '',
  endDate: '',
};

const validationSchema = Yup.object().shape({
  startDate: Yup.string(),
  endDate: Yup.string(),
});

const TransactionInformation = ({ t }) => {
  const [transactionListData, setTransactionListData] = useState([]);
  const [listChecked, setListChecked] = useState([]);
  const [isModalStatus, setModalStatus] = useState(false);
  const [startDate, setStartDate] = useState(false);
  const [endDate, setEndDate] = useState(false);
  const [transactionIDSelected, setTransactionIDSelected] = useState([]);
  const [transactionProcessedAll, setTransactionProcessedAll] = useState(false);

  // This is fake transation detail data. If have API -> remove
  const [transactionDetail, setTransactionDetail] = useState({});

  const [params, setParams] = useState({
    limit: 30,
    pageNumber: 1,
    q: '',
    startDate: '',
    endDate: '',
    order: 'desc',
    sort: '0100',
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues,
    resolver: yupResolver(validationSchema),
  });

  const [tableHeadData, setTableHeadData] = useState([
    {
      title: 'TX ID',
      hasIcon: true,
      sort: '0100',
      order: false,
      size: 2,
    },
    {
      title: t('pages_TransactionInformation.time'),
      hasIcon: true,
      sort: '0200',
      order: false,
      size: 2,
    },
    {
      title: t('pages_TransactionInformation.quantity'),
      hasIcon: true,
      sort: '0300',
      order: false,
      size: 2,
    },
    {
      title: t('pages_TransactionInformation.productName'),
      hasIcon: true,
      sort: '0400',
      order: false,
      size: 2,
    },
    {
      title: t('pages_TransactionInformation.sendingAddress'),
      hasIcon: true,
      sort: '0500',
      order: false,
      size: 3,
    },
    {
      title: t('pages_TransactionInformation.receiptAddress'),
      hasIcon: true,
      sort: '0600',
      order: false,
      size: 3,
    },
    {
      title: t('pages_TransactionInformation.status'),
      hasIcon: true,
      sort: '0700',
      order: false,
      size: 2,
    },
    {
      title: t('pages_TransactionInformation.selection'),
      size: 1,
    },
  ]);

  const {
    data: TransactionInformation,
    refetch,
    isFetching,
    remove,
  } = useQuery(
    ['TransactionInformation', params],
    async ({ signal }) => {
      const data = await get(API_PATH.TRANSACTION_INFORMATION, { ...params, signal });
      if (transactionProcessedAll) {
        const newTransactionData = data?.transactionInformations?.map((item) => ({ ...item, pickup: true }));
        setTransactionListData(newTransactionData);

        setTransactionIDSelected(() => {
          const newTransactionIDSelected = [...transactionIDSelected];
          data?.transactionInformations?.map(
            ({ id: transactionID }) =>
              !newTransactionIDSelected.includes(transactionID) && newTransactionIDSelected.push(transactionID)
          );
          return newTransactionIDSelected;
        });
      } else {
        setTransactionListData(
          data?.transactionInformations?.map((item) =>
            transactionIDSelected?.includes(item.id) ? { ...item, pickup: true } : item
          )
        );
      }
      return data;
    },
    {
      refetchOnWindowFocus: false,
    }
  );

  const selectAll = () => {
    const isAllPaymentsInTabChecked = listChecked.length === transactionListData.length;
    setTransactionProcessedAll(!isAllPaymentsInTabChecked);

    const newTransactionListData = transactionListData?.map((item) => ({
      ...item,
      pickup: listChecked?.length > 0 ? (isAllPaymentsInTabChecked ? false : true) : true,
    }));
    setTransactionListData(newTransactionListData);

    setTransactionIDSelected(() => {
      if (isAllPaymentsInTabChecked) return [];
      const newTransactionIDSelected = [...transactionIDSelected];
      transactionListData?.map(
        ({ id: transactionID }) =>
          !newTransactionIDSelected.includes(transactionID) && newTransactionIDSelected.push(transactionID)
      );
      return newTransactionIDSelected;
    });
  };

  const toggleCheckbox = (item, index) => {
    const newTransactionListData = [...transactionListData];
    newTransactionListData?.splice(index, 1, { ...item, pickup: !item.pickup });
    setTransactionListData(newTransactionListData);

    const positionOfItemID = transactionIDSelected.indexOf(item.id);
    setTransactionIDSelected((prev) => {
      const newTransactionIDSelected = [...prev];
      if (positionOfItemID > -1) {
        newTransactionIDSelected.splice(positionOfItemID, 1);
        return newTransactionIDSelected;
      }
      return [...newTransactionIDSelected, item.id];
    });

    setTransactionProcessedAll(false);
  };

  const handleSort = (item) => {
    setTableHeadData((prev) =>
      prev.map((tableHead) =>
        tableHead.sort === item.sort ? { ...tableHead, order: !tableHead.order } : { ...tableHead, order: false }
      )
    );

    setParams({
      ...params,
      sort: item.sort,
      pageNumber: 1,
      order: !item.order ? 'asc' : 'desc',
    });
  };

  const handleSetStartDate = useCallback(
    (value) => {
      setStartDate(value);
      if (!value) return setParams({ ...params, startDate: '', endDate: '' });
    },
    [setStartDate]
  );

  const handleSetEndData = useCallback(
    (value) => {
      setEndDate(value);
      if (!value) return setParams({ ...params, startDate: '', endDate: '' });
    },
    [setEndDate]
  );

  const onSubmit = (data) => {
    const newParams = {
      ...params,
      pageNumber: 1,
      startDate: moment.unix(moment(data.startDate).valueOf() / 1000).format('YYYY-MM-DD'),
      endDate: moment.unix(moment(data.endDate).valueOf() / 1000).format('YYYY-MM-DD'),
    };
    setParams(newParams);
    setTransactionProcessedAll(false);
  };

  const { mutate: exportCSV } = useMutation({
    mutationFn: async ({ url, params }) =>
      await downloadCSV(url, t('pages_TransactionInformation.numberOfTransactions'), params),
    retry: 3,
  });

  const handleExportCSV = useCallback(() => {
    const url = API_PATH.EXPORT_TRANSACTION;

    exportCSV(
      {
        url,
        params: transactionProcessedAll
          ? { isCheckedAll: true, ...params }
          : { isCheckedAll: false, ids: listChecked.map(({ id }) => id).join(','), ...params },
      },
      {
        onError: (error) => console.log(error),
      }
    );
  }, [listChecked, transactionProcessedAll]);

  const transactionStatus = {
    '-1': { text: t('pages_TransactionInformation.status_failed') },
    0: { text: t('pages_TransactionInformation.status_pending') },
    1: { text: t('pages_TransactionInformation.status_processing') },
    2: { text: t('pages_TransactionInformation.status_success') },
  };

  useEffect(() => {
    const listTransactionChecked = transactionListData?.filter((item) => {
      return item.pickup === true;
    });
    setListChecked(listTransactionChecked);
  }, [transactionListData, setListChecked]);

  useEffect(() => remove, [remove]);

  return (
    <>
      <View
        style={[
          styles.container,
          { backgroundColor: isFetching || transactionListData.length < 1 ? '' : BG_TABLE_DEFAULT },
        ]}
      >
        <View style={styles.wrapperStickyHeader}>
          <View style={styles.headerSearch}>
            <HeaderSearch
              placeholder={t('pages_TransactionInformation.placeholder')}
              setParams={setParams}
              params={params}
              onSearch={() => setTransactionProcessedAll(false)}
            />
          </View>

          <View style={[styles.wrapperHeader]}>
            <View style={styles.wrapperHeaderLeft}>
              <Text style={styles.textHeader}>{t('pages_TransactionInformation.numberOfTransactions')}</Text>
              <Text style={styles.textHeader}>
                {!TransactionInformation?.pageOut?.totalCount || isFetching
                  ? 0
                  : TransactionInformation?.pageOut?.totalCount}{' '}
                {t('pages_TransactionInformation.pieces')}
              </Text>
            </View>
          </View>

          <View style={styles.wrapperSearch}>
            <View style={styles.leftSearch}>
              <Text>{t('pages_TransactionInformation.specifiedPeriod')}</Text>
              <View style={styles.wrapperInput}>
                <View style={styles.wrapperDateTimePicker}>
                  <DateTimePicker
                    height={40}
                    name="startDate"
                    control={control}
                    type="date"
                    setDateTime={handleSetStartDate}
                    max={endDate ?? moment().format('YYYY-MM-DD')}
                  />
                </View>
                <View>
                  <Text style={styles.textDateTimePicker}>~</Text>
                </View>
                <View style={styles.wrapperDateTimePicker}>
                  <DateTimePicker
                    height={40}
                    name="endDate"
                    control={control}
                    type="date"
                    setDateTime={handleSetEndData}
                    min={startDate}
                  />
                </View>
              </View>
              <View style={{ width: 140, marginHorizontal: 20 }}>
                <LinearGradient colors={['#32A5F7', '#2D81F3']} style={{ borderRadius: 5 }}>
                  <JButton
                    text={t('pages_TransactionInformation.specifiedPeriod')}
                    textColor="#fff"
                    linkIcon="iconClockActive.svg"
                    heightIcon={15}
                    widthIcon={15}
                    disabled={!(startDate && endDate)}
                    linkIconDisabled="iconClock.svg"
                    onPressAction={() => handleSubmit(onSubmit)()}
                  />
                </LinearGradient>
              </View>
            </View>

            <View style={styles.rightSearch}>
              <TouchableOpacity activeOpacity={0.7} onPress={handleExportCSV}>
                <Text style={styles.CsvActive}>{t('pages_TransactionInformation.CSVExport')}</Text>
              </TouchableOpacity>
              <JButton
                text={t('commonText.buttonSelectAll')}
                onPressAction={() => selectAll()}
                width={150}
                textColor={TEXT_COLOR_GREEN}
                btnColor={INPUT_BG}
                borderBtn={`1px solid ${LINE_COLOR}`}
              />
            </View>
          </View>

          {transactionListData?.length > 0 && !isFetching && (
            <View>
              <Grid style={[styles.head, { gap: 20 }]} size={30}>
                {tableHeadData.map((item) => (
                  <Col size={item.size} style={styles.horizontal} key={item.title}>
                    <Text numberOfLines={2} style={styles.textHead}>
                      {item.title}
                    </Text>
                    {item.hasIcon && (
                      <Image
                        onClick={() => handleSort(item)}
                        style={item?.order ? styles.iconTableHeadDESC : styles.iconTableHeadASC}
                        source={require(`@assets/icons/iconDropdown.svg`)}
                      />
                    )}
                  </Col>
                ))}
              </Grid>
            </View>
          )}
        </View>
        <ScrollView showsHorizontalScrollIndicator={false}>
          {isFetching ? (
            <Loading style={{ height: 'calc(100vh - 183px)' }} />
          ) : transactionListData?.length > 0 ? (
            transactionListData?.map((item, index) => {
              return (
                <Pressable key={index} style={({ hovered }) => [hovered && styles.rowHovered]}>
                  <Grid
                    style={[styles.head, { gap: 20 }]}
                    size={30}
                    onPress={() => {
                      setModalStatus(true);
                      setTransactionDetail(item);
                    }}
                  >
                    <Col size={2} style={styles.wrapperItemBody}>
                      <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                        <Image style={styles.iconTableRow} source={require(`@assets/icons/iconWorld.svg`)} />
                        <Text style={styles.text} numberOfLines={2}>
                          {item.id}
                        </Text>
                      </View>
                    </Col>
                    <Col size={2} style={styles.wrapperItemBody}>
                      <Text numberOfLines={2} style={styles.text}>
                        {item?.createdAt ? moment.unix(new Date(item.createdAt) / 1000).format('YYYY.MM.DD') : ''}
                      </Text>
                    </Col>
                    <Col size={2} style={styles.wrapperItemBody}>
                      <Text style={styles.text} numberOfLines={2}>
                        {item.amount ? `${formatNumber(item.amount)}` : null}
                      </Text>
                    </Col>
                    <Col size={2} style={styles.wrapperItemBody}>
                      <Text style={styles.text} numberOfLines={2}>
                        {item.name}
                      </Text>
                    </Col>
                    <Col size={3} style={styles.wrapperItemBody}>
                      <Text style={styles.text} numberOfLines={3}>
                        {item.fromAddress}
                      </Text>
                    </Col>
                    <Col size={3} style={styles.wrapperItemBody}>
                      <Text style={styles.text} numberOfLines={3}>
                        {item.toAddress}
                      </Text>
                    </Col>
                    <Col size={1.5} style={styles.wrapperItemBody}>
                      <View
                        style={{
                          flexDirection: 'row',
                          justifyContent: 'flex-start',
                          width: '100%',
                          alignItems: 'center',
                        }}
                      >
                        <View
                          style={[styles.status, { backgroundColor: statusColorTransaction(parseInt(item.txStatus)) }]}
                        />
                        <Text style={styles.text} numberOfLines={2}>
                          {transactionStatus[item.txStatus].text}
                        </Text>
                      </View>
                    </Col>
                    <Col size={1} style={styles.wrapperItemBody}>
                      <JCheckbox
                        checkValueTable={true}
                        onValueChange={() => toggleCheckbox(item, index)}
                        value={item.pickup ?? false}
                      />
                    </Col>
                  </Grid>
                </Pressable>
              );
            })
          ) : (
            <Center w="100%" h={Dimensions.get('window').height - 183}>
              <Text>{t('pages_TransactionInformation.haveNoItem')}</Text>
            </Center>
          )}
          <View>
            {params.limit < TransactionInformation?.pageOut?.totalCount && !isFetching && (
              <Pagination
                count={Math.ceil(TransactionInformation?.pageOut?.totalCount / params.limit)}
                params={params}
                setParams={setParams}
              />
            )}
          </View>
        </ScrollView>
        <ModalContactStatus setModalVisible={setModalStatus} isVisible={isModalStatus} payload={transactionDetail} />
      </View>
    </>
  );
};

export default withNamespaces()(TransactionInformation);

import React, { useState } from 'react';
import { View, Image } from 'react-native';
import { withNamespaces } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { Col } from 'react-native-easy-grid';

import JButton from '@components/common/JButton';
import ModalSave from './modal/ModalSave';
import styles from './style';
import { JText as Text } from '@components/common/JText';

import { BTN_COLOR_GREY_2, TEXT_COLOR_GREY } from '@assets/style/styleDefault';
import { LinearGradient } from 'expo-linear-gradient';
import AdminList from '../partials/AdminList';

const defaultValues = {
  avatar: 'GergelyAvatar.png',
  userName: 'Gergely Kiss',
  adminType: 'Executive',
  adminTypeIcon: 'grayStatus.svg',
  userId: 'C00238',
  primaryID: '0x36c6D12621b02327DbD911007C0d24A7259Ed783',
  email: 'k.gergely@levias.co.jp',
  phone: '+81 70 1090 8546',
};

const validationSchema = Yup.object().shape({
  name: Yup.string(),
  avatar: Yup.string(),
});

const MasterChange = ({ t }) => {
  const [userInfo, setUserInfo] = useState(defaultValues);

  const [modalSave, setModalSave] = useState(false);
  let history = useHistory();
  const {
    control,
    handleSubmit,
    setValue,
    reset,
    formState: { isDirty },
  } = useForm({
    defaultValues,
    resolver: yupResolver(validationSchema),
  });
  const onSubmit = (data) => {
    if (isDirty) {
      setModalAlert(true);
      console.log(data);
    }
  };

  return (
    <View style={styles.container}>
      <View style={styles.header}>
        <Text style={styles.textHeader}>{t('pages_Setting_MasterChange.textHeader')}</Text>
      </View>
      <View style={styles.body}>
        <Col size={7} style={styles.left}>
          <View style={[styles.dFlex, { marginBottom: 20 }]}>
            <Col size={3}>
              <View style={styles.mainAvatar}>
                <Image
                  style={styles.mainAvatarImage}
                  source={require(`../../../assets/image/adminAvatar/${userInfo.avatar}`)}
                />
              </View>
            </Col>
            <Col size={7}>
              <Text style={styles.textHeader}>{userInfo.userName}</Text>
            </Col>
          </View>
          <View style={[styles.dFlex, styles.warperItem]}>
            <Col size={3}>
              <Text style={styles.text}>Admin type</Text>
            </Col>
            <Col size={7}>
              <View style={[styles.dFlex, { flex: 1 }]}>
                <Image
                  source={require(`../../../assets/icons/${userInfo.adminTypeIcon}`)}
                  style={styles.statusIcon}
                  alt="statusIcon"
                />
                <Text style={styles.text}>{userInfo.adminType}</Text>
              </View>
            </Col>
          </View>

          <View style={[styles.dFlex, styles.warperItem]}>
            <Col size={3}>
              <Text style={styles.text}>LEVICA ID</Text>
            </Col>
            <Col size={7}>
              <Text style={{ color: TEXT_COLOR_GREY }}>{userInfo.userId}</Text>
            </Col>
          </View>
          <View style={[styles.dFlex, styles.warperItem]}>
            <Col size={3}>
              <Text style={styles.text}>{t('pages_Setting.walletAddress')}</Text>
            </Col>
            <Col size={7}>
              <Text style={{ color: TEXT_COLOR_GREY }}>{userInfo.primaryID}</Text>
            </Col>
          </View>
          <View style={[styles.dFlex, styles.warperItem]}>
            <Col size={3}>
              <Text style={styles.text}>{t('pages_Setting.phone')}</Text>
            </Col>
            <Col size={7}>
              <Text style={styles.text}>{userInfo.email}</Text>
            </Col>
          </View>
          <View style={[styles.dFlex, styles.warperItem, { borderBottomWidth: 0 }]}>
            <Col size={3}>
              <Text style={styles.text}>{t('pages_Setting.phone')}</Text>
            </Col>
            <Col size={7}>
              <Text style={styles.text}>{userInfo.phone}</Text>
            </Col>
          </View>

          <View style={styles.information}>
            <Text style={styles.text}> {`${userInfo.userName} ${t('pages_Setting_MasterChange.information1')}`}</Text>
            <Text style={styles.text}>{t('pages_Setting_MasterChange.information2')}</Text>
          </View>
          <View style={styles.dFlex}>
            <View style={{ paddingRight: 40 }}>
              <JButton
                text={t('commonText.cancelButton')}
                width={200}
                textColor="#fff"
                btnColor={BTN_COLOR_GREY_2}
                hasIcon={true}
                onPressAction={() => history.push('/admin')}
              />
            </View>
            <LinearGradient colors={['#54DBB2', '#54DBB2']} style={{ borderRadius: 5 }}>
              <JButton
                text={t('pages_Setting_MasterChange.buttonTransition')}
                width={200}
                textColor="#fff"
                onPressAction={() => setModalSave(true)}
              />
            </LinearGradient>
          </View>
        </Col>
        <Col size={3} style={styles.right}>
          <AdminList />
        </Col>
      </View>

      <ModalSave
        userName={userInfo.userName}
        isModalVisible={modalSave}
        setModalVisible={setModalSave}
        resetFrom={reset}
        defaultValues={defaultValues}
      />
    </View>
  );
};

export default withNamespaces()(MasterChange);

import React, { useState } from 'react';
import { StyleSheet, View, Image } from 'react-native';
import { Link, useHistory } from 'react-router-dom';
import { withNamespaces } from 'react-i18next';
import { LinearGradient } from 'expo-linear-gradient';

import JButton from '../common/JButton';
import DropDown from '../common/DropDown';
import ModalAlert from '../Modals/ModalAlert';
import ModalSendSuccess from '../Modals/ModalSendSuccess';
import {
  COLOR_TEXT_DEFAULT,
  FONT_FAMILY_DEFAULT,
  FONT_SIZE_HEADER,
  FONT_WEIGHT_DEFAULT_HEADER,
  TEXT_COLOR_GREEN,
} from '@assets/style/styleDefault';
import { JText as Text } from '../common/JText';
import ModalBack from '../Modals/ModalBack';
import ModalSettingSuccess from '../Modals/ModalSettingSuccess';

const HeaderCommunity = ({
  iconBack,
  textBack,
  linkPage,
  isDirty,
  textHeaderCommon,
  iconHeader,
  iconHeaderWidth = 46,
  iconHeaderHeight = 30,
  isValid,
  handleSubmit,
  onSubmit,
  haveModal,
  t,
}) => {
  const history = useHistory();
  const [isModal, setIsModal] = useState(false);
  const [isModalBack, setIsModalBack] = useState(false);
  const [modalDelete, setModalDelete] = useState(false);
  const [isModalSendSuccess, setModalSendSuccess] = useState(false);
  const [modalSuccess, setModalSuccess] = useState(false);

  const [modalChangePage, setModalChangePage] = useState(false);

  const openModalBack = () => {
    setIsModalBack(true);
  };

  const openModalSendSuccess = () => {
    setModalSendSuccess(true);
  };

  const handleModalBack = () => {
    setIsModalBack(false);
    setIsModal(true);
  };

  const handleLoading = () => {
    setModalSuccess(false);
  };

  const handleBackBtn = () => {
    history.goBack();
  };
  const actionOptions = [
    {
      option: t('components_Headers_HeaderCommunity.delete'),
      icon: 'iconBin.svg',
      func: () => setModalDelete(true),
      color: 'red',
    },
  ];

  const languageOptions = [
    {
      option: t('components_Headers_HeaderCommunity.english'),
      func: () => {},
    },
    {
      option: t('components_Headers_HeaderCommunity.japanese'),
      func: () => {},
    },
  ];

  return (
    <>
      <View style={[styles.header]}>
        <View style={styles.headerLeft}>
          {linkPage ? (
            <Link
              to={`${linkPage}`}
              style={{
                textDecorationLine: 'none',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                flexBasis: 1,
              }}
            >
              <Image
                style={styles.buttonBack}
                source={
                  iconBack ? require(`../../assets/icons/${iconBack}`) : require(`../../assets/icons/iconLeft.svg`)
                }
              />
              <Text style={styles.textBack}>
                {textBack ? textBack : t('components_Headers_HeaderCommunity.return')}
              </Text>
            </Link>
          ) : haveModal ? (
            <View style={[styles.headerLeft, { cursor: 'pointer' }]} onClick={openModalBack}>
              <Image
                style={styles.buttonBack}
                source={
                  iconBack ? require(`../../assets/icons/${iconBack}`) : require(`../../assets/icons/iconLeft.svg`)
                }
              />
              <Text style={styles.textBack}>
                {textBack ? textBack : t('components_Headers_HeaderCommunity.return')}
              </Text>
            </View>
          ) : (
            <View style={[styles.headerLeft, { cursor: 'pointer' }]} onClick={handleBackBtn}>
              <Image
                style={styles.buttonBack}
                source={
                  iconBack ? require(`../../assets/icons/${iconBack}`) : require(`../../assets/icons/iconLeft.svg`)
                }
              />
              <Text style={styles.textBack}>
                {textBack ? textBack : t('components_Headers_HeaderCommunity.return')}
              </Text>
            </View>
          )}

          <Image
            style={{ width: iconHeaderWidth, height: iconHeaderHeight, marginRight: 15 }}
            source={require(`../../assets/icons/${iconHeader}`)}
          />
          <Text style={[styles.textLabel]}>
            {textHeaderCommon ?? t('components_Headers_HeaderCommunity.textHeaderCommon')}
          </Text>
        </View>
        <View style={styles.headerRight}>
          <View style={[styles.itemRight, { marginLeft: 0 }]}>
            <DropDown
              btnColor="#525252"
              textColor="#fff"
              text={t('components_Headers_HeaderCommunity.draft')}
              linkIcon="iconNote.svg"
              widthIcon={20}
              dataOption={actionOptions}
              colorOption="red"
              width={190}
            />
          </View>

          <View style={styles.itemRight}>
            <DropDown
              btnColor="#525252"
              textColor="#fff"
              text={t('components_Headers_HeaderCommunity.japanese')}
              linkIcon="iconLanguage.svg"
              widthIcon={20}
              dataOption={languageOptions}
              colorOption="#f2f2f2"
              textCenter={true}
              width={190}
            />
          </View>

          <View style={styles.itemRight}>
            <LinearGradient colors={['#54DBB2', '#04C48B']} style={{ borderRadius: 5, width: 190 }}>
              <JButton
                text={t('components_Headers_HeaderCommunity.saveToDraft')}
                textColor="#f2f2f2"
                linkIcon="iconDownload.svg"
                linkIconDisabled="iconDownloadGray.svg"
                widthIcon={18}
                heightIcon={18}
                onPressAction={openModalSendSuccess}
                disabled={!isDirty}
                width={190}
              />
            </LinearGradient>
          </View>
        </View>
      </View>

      <ModalAlert
        isModalVisible={isModal}
        setModalVisible={setIsModal}
        modalText={t('components_Headers_HeaderCommunity.modalAlert')}
        iconWidth={16}
        iconHeight={12.5}
        icon="successGreen.svg"
        linkPage="tokens"
      />
      <ModalBack isModal={isModalBack} setIsModal={setIsModalBack} otherModal={handleModalBack} linkPage="/tokens" />
      {/* <ModalDelete
        iconBtnLeft="close-white.svg"
        iconBtnRight="delete-white.svg"
        modalDelete={modalDelete}
        setModalDelete={setModalDelete}
        modalAlert={modalChangePage}
        setModalAlert={setModalChangePage}
        icon="deleteDraftData.svg"
        textHeader="下書きデータの削除"
        textContent={[
          ` 下書きのトークン作成データを削除します。`,
          '削除したデータは復元できません。データを削除しますか？',
        ]}
        pv={30}
        iconBody="warning.svg"
      /> */}
      <ModalSendSuccess
        textHeader={t('components_Headers_HeaderCommunity.modalSendSuccess1')}
        content={t('components_Headers_HeaderCommunity.modalSendSuccess2')}
        isModalVisible={isModalSendSuccess}
        setModalVisible={setModalSendSuccess}
        linkBack=""
        iconHeader="iconDownBlue.svg"
        iconHeaderWidth={26}
        iconHeaderHeight={25}
      />
      <ModalSettingSuccess isModal={modalSuccess} setIsModal={setModalSuccess} setIsLoading={handleLoading} />
    </>
  );
};

export default withNamespaces()(HeaderCommunity);

const styles = StyleSheet.create({
  textLabel: {
    fontSize: FONT_SIZE_HEADER,
    fontFamily: FONT_FAMILY_DEFAULT,
    fontWeight: FONT_WEIGHT_DEFAULT_HEADER,
    color: COLOR_TEXT_DEFAULT,
  },
  header: {
    paddingVertical: 20,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  headerLeft: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingRight: 10,
    flexShrink: 1,
  },
  itemLeft: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  headerRight: {
    //flexBasis: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    flexGrow: 3,
  },
  itemRight: {
    marginLeft: 20,
  },

  buttonBack: {
    height: 15,
    width: 15,
  },
  textBack: {
    fontSize: FONT_SIZE_HEADER,
    fontFamily: FONT_FAMILY_DEFAULT,
    color: TEXT_COLOR_GREEN,
    fontWeight: FONT_WEIGHT_DEFAULT_HEADER,
    marginHorizontal: 10,
    whiteSpace: 'nowrap',
  },
  iconHeader: {
    height: 24,
    width: 24,
    marginRight: 10,
  },
  flexRow: {
    flexDirection: 'row',
    alignItems: 'center',
  },
});

import React, { useEffect, useRef, useState, useMemo } from 'react';
import { View, StyleSheet, ScrollView, useWindowDimensions } from 'react-native';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Col, Grid } from 'react-native-easy-grid';
import { useParams, useHistory } from 'react-router-dom';
import { useQuery, QueryClient } from '@tanstack/react-query';
import { withNamespaces } from 'react-i18next';
import { useSelector } from 'react-redux';

import { defaultValues, validationSchema } from './createAccountManagementFormData';
import { get } from '@utils/RequestLevica';
import FomrIndividual from './partials/FomrIndividual';
import HeaderAccount from '@components/Headers/HeaderAccount';
import HeaderSearch from '@components/Headers/HeaderSearch';
import { BG_COLOR } from '@assets/style/styleDefault';
import WalletInfo from './partials/FormBankInfo/WalletInfo';
import BankInfo from './partials/FormBankInfo/BankInfo';
import { API_PATH, ROLE_USER } from '@helper/constant';
import scroll2View from '@helper/scroll2View';
import useUpdateLangueForYup from '@helper/customHook/useUpdateLangueForYup';
import Loading from '@components/common/Loading';
import { checkPermission } from '@helper/commonFunction';

const CreateAccount = ({ t }) => {
  const history = useHistory();
  const { accountId } = useParams();
  const [formValue, setFormValue] = useState(null);
  const queryClient = new QueryClient();
  const scrollViewRef = useRef();
  const { height: windowHeight } = useWindowDimensions();
  const [isInjectedDataToHookForm, setIsInjectedDataToHookForm] = useState(false);
  const userInfo = useSelector((state) => state.merchantUserInfo);
  const methods = useForm({
    defaultValues,
    resolver: yupResolver(useUpdateLangueForYup(validationSchema)),
    mode: 'onChange',
  });
  const { errors, isDirty, isValid } = methods.formState;
  const getManagementDetail = async ({ signal }) => {
    const data = await get(`${API_PATH.MANAGEMENT_ACCOUNT}/${accountId}`, { signal });
    return data;
  };
  const {
    data: managementAccount,
    isLoading,
    isSuccess,
    isFetching,
    remove,
  } = useQuery(['getManagementDetail'], getManagementDetail, {
    enabled: !!accountId,
    refetchOnWindowFocus: false,
    onError: (error) => {
      if (error?.response?.data?.code === '2000' || error?.response?.data?.code === '5009') {
        alert(error?.response?.data?.code.message);
        history.push('/accounts');
      }
    },
  });

  useEffect(() => {
    if (accountId && managementAccount && !isFetching) {
      setFormValue(managementAccount);
      const managementAccountData = {};
      for (const key in managementAccount) {
        const fieldData = managementAccount[key];
        if (!fieldData) continue;
        managementAccountData[key] = fieldData;
      }
      methods.reset({ ...defaultValues, ...managementAccountData });
      methods.trigger();
      setIsInjectedDataToHookForm(true);
    }
    return () => {
      setFormValue(null);
      methods.reset();
      queryClient.removeQueries('getManagementDetail', { exact: true });
    };
  }, [managementAccount, isFetching, setIsInjectedDataToHookForm]);

  const isShowForm = useMemo(() => {
    if (!accountId) return true;
    return !isFetching && isInjectedDataToHookForm;
  }, [accountId, isFetching, isInjectedDataToHookForm]);

  useEffect(() => {
    scroll2View(Object.keys(errors)[0], scrollViewRef.current);
  }, [errors]);

  useEffect(() => remove, [remove]);

  return (
    <FormProvider {...methods}>
      <View style={styles.headerSearch}>
        <HeaderSearch placeholder={t('pages_AccountManagement_CreateAccount.placeholder')} linkBack="/accounts" />
      </View>
      <View>
        <HeaderAccount
          showBackButton={true}
          icon="person.svg"
          text={t('pages_AccountManagement_CreateAccount.personalAccountDetails')}
          handleSubmit={methods.handleSubmit}
          isDetail={true}
          // checkDirtyFields={!isValid || !isDirty}
          // checkDirtyFields={!isDirty}
          linkWhenClose="/accounts"
          updateAPI={`${API_PATH.MANAGEMENT_ACCOUNT}/${accountId}`}
          deleteAPI={`${API_PATH.MANAGEMENT_ACCOUNT}/${accountId}`}
          scrollViewRef={scrollViewRef}
          textCreateAccountSuccess={t('pages_AccountManagement_CreateAccount.modal')}
          formValue={formValue}
        />

        {/* FORM */}
        {isShowForm ? (
          <Grid style={[styles.container]}>
            <Col size={7}>
              <View style={{ height: 'calc(100vh - 200px)' }}>
                <ScrollView showsVerticalScrollIndicator={false} ref={scrollViewRef}>
                  <FomrIndividual
                    accountId={accountId}
                    control={methods.control}
                    setValue={methods.setValue}
                    formValue={formValue}
                    t={t}
                    disableForm={checkPermission(ROLE_USER.USER_MANAGEMENT, userInfo)}
                  />
                </ScrollView>
              </View>
            </Col>
            <Col size={5}>
              <View style={{ height: 'calc(100vh - 200px)' }}>
                <View style={styles.containerRight}>
                  <BankInfo control={methods.control} t={t} />
                </View>
              </View>
            </Col>
          </Grid>
        ) : (
          <Loading style={{ height: 'calc(100vh - 80px)' }} />
        )}
      </View>
    </FormProvider>
  );
};

export default withNamespaces()(CreateAccount);

const styles = StyleSheet.create({
  headerSearch: {
    marginRight: 0,
    marginTop: 10,
  },
  container: {
    gap: 50,
    backgroundColor: BG_COLOR,
    paddingHorizontal: 20,
  },
});

import { StyleSheet } from 'react-native';
import {
  BG_COLOR,
  BG_HOVER,
  FONT_FAMILY_DEFAULT,
  FONT_SIZE_HEADER,
  FONT_WEIGHT_DEFAULT_HEADER,
  LINE_COLOR,
  BG_FORM_DEFAULT,
  TEXT_COLOR_HEADER_TABLE,
} from '@assets/style/styleDefault';

const styles = StyleSheet.create({
  container: { flex: 1, position: 'relative' },
  headerSearch: {
    marginRight: 0,
    marginTop: 10,
  },

  wrapperStickyHeader: {
    position: 'sticky',
    top: 0,
    backgroundColor: BG_COLOR,
    zIndex: 10,
  },
  wrapperHeadTable: { gap: 20, paddingTop: 50, paddingBottom: 20 },
  textHead: { color: TEXT_COLOR_HEADER_TABLE, textAlign: 'center' },
  wrapperItemTable: {
    height: 42,
    paddingVertical: 15,
    gap: 20,
    borderBottomColor: LINE_COLOR,
    borderBottomWidth: 1,
  },
  wrapperHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: 20,
  },
  textHeader: {
    fontSize: FONT_SIZE_HEADER,
    fontFamily: FONT_FAMILY_DEFAULT,
    fontWeight: FONT_WEIGHT_DEFAULT_HEADER,
  },
  wrapperHeaderLeft: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '50%',
  },
  circle: {
    width: 10,
    height: 10,
    marginRight: 5,
    borderRadius: 100,
    backgroundColor: '#00be85',
  },
  iconWarning: {
    width: 20,
    height: 20,
  },

  iconTableHeadASC: {
    width: 12,
    height: 12,
    resizeMode: 'contain',
    marginLeft: 5,
    cursor: 'pointer',
  },
  iconTableHeadDESC: {
    width: 12,
    height: 12,
    resizeMode: 'contain',
    marginLeft: 5,
    cursor: 'pointer',
    transform: [{ rotate: '180deg' }],
  },
  wrapperItem: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    paddingLeft: 10,
  },

  buttonHovered: {
    backgroundColor: BG_HOVER,
  },
});

export default styles;

import { LinearGradient } from 'expo-linear-gradient';
import React from 'react';
import { View, Image, StyleSheet, TouchableOpacity } from 'react-native';
import Modal from 'react-native-modal';

import { BTN_COLOR_GREY_2, TEXT_COLOR_GREY, TEXT_COLOR_WHITE } from '@assets/style/styleDefault';
import JButton from '../common/JButton';
import { JText as Text } from '../common/JText';
import ModalBackDrop from './ModalBackDrop';

const ModalDelete = (props) => {
  const {
    modalDelete,
    setModalDelete,
    isDeletingFile,
    modalAlert,
    setModalAlert,
    icon,
    iconWidth = 50,
    iconHeight = 50,
    iconBtnLeft,
    widthIconBtnLeft = 13,
    heightIconBtnLeft = 13,
    iconBtnRight = '',
    iconBtnRightSub,
    btnColorRight = '#fe6a54',
    widthIconBtnRight = 13,
    heightIconBtnRight = 13,
    iconBody,
    textHeader,
    textHeaderColor = '#191919',
    mtTextHeader = 0,
    textContent = [],
    func,
    pv = 80,
    textBtnLeft = '保存しない',
    textBtnRight = '保存する',
    colorTextTitle = TEXT_COLOR_GREY,
    numberItemDelete = null,
    marginBottomIconRight = 0
  } = props;
  return (
    <>
      <Modal
        animationIn="fadeIn"
        isVisible={modalDelete}
        style={{ alignItems: 'center' }}
        customBackdrop={<ModalBackDrop onPress={() => setModalDelete(!modalDelete)} />}
      >
        <View style={styles.maxWidth600}>
          <View style={styles.header}>
            {icon && (
              <Image
                source={require(`../../assets/icons/${icon}`)}
                style={[styles.icon, { height: iconHeight, width: iconWidth }]}
              />
            )}
            <Text style={{ paddingTop: mtTextHeader, color: textHeaderColor }}>{textHeader}</Text>
          </View>
          <View style={[styles.content, { paddingVertical: pv }]}>
            {iconBody && <Image source={require(`../../assets/icons/${iconBody}`)} style={[styles.iconBody]} />}

            {textContent.map((text, index) => (
              <View key={index}>
                {index === 0 ? (
                  <Text style={{ color: 'black', fontSize: 18 }}>
                    {numberItemDelete}
                    <Text style={{ color: colorTextTitle, paddingLeft: 20, paddingRight: 20 }}>{text}</Text>
                  </Text>
                ) : (
                  <Text style={{ color: colorTextTitle }}>{text}</Text>
                )}
              </View>
            ))}
          </View>
          <View style={styles.buttonWrap}>
            <JButton
              text={textBtnLeft}
              width={200}
              textColor={TEXT_COLOR_WHITE}
              btnColor={BTN_COLOR_GREY_2}
              hasIcon={true}
              linkIcon={iconBtnLeft}
              widthIcon={widthIconBtnLeft}
              heightIcon={heightIconBtnLeft}
              onPressAction={() => setModalDelete(!modalDelete)}
            />
            <LinearGradient colors={['#FE8463', '#FD4F44']} style={{ borderRadius: 5 }}>
              <JButton
                text={textBtnRight}
                width={200}
                textColor={TEXT_COLOR_WHITE}
                hasIcon={true}
                linkIcon={iconBtnRight}
                iconSubfix={iconBtnRightSub}
                widthIcon={widthIconBtnRight}
                heightIcon={heightIconBtnRight}
                widthSubfix={widthIconBtnRight}
                heightSubfix={heightIconBtnRight}
                marginBottomIcon={marginBottomIconRight}
                isProcessing={isDeletingFile}
                disabled={isDeletingFile}
                onPressAction={() => {
                  func ? func() : null;
                }}
              />
            </LinearGradient>
          </View>
        </View>
      </Modal>
    </>
  );
};

export default ModalDelete;

const styles = StyleSheet.create({
  maxWidth600: {
    minWidth: 600,
    backgroundColor: '#fff',
    borderRadius: 5,
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    paddingVertical: 10,
    borderBottomColor: '#ccc',
    borderBottomWidth: 1,
  },
  buttonWrap: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    gap: 25,
    paddingBottom: 40,
  },
  content: {
    alignItems: 'center',
    justifyContent: 'center',
    lineHeight: 2,
  },
  icon: {
    resizeMode: 'contain',
    marginRight: 10,
  },
  iconBody: {
    width: 50,
    height: 60,
    resizeMode: 'contain',
    marginBottom: 20,
  },
});

import { StyleSheet } from 'react-native';
import {
  BG_COLOR,
  COLOR_TEXT_DEFAULT,
  FONT_FAMILY_DEFAULT,
  FONT_SIZE_HEADER,
  FONT_FAMILY_HEADER,
  BTN_COLOR_GREY,
  BG_HOVER,
  BG_SIDEBAR,
  LINE_COLOR,
  TEXT_COLOR_GREY,
} from '@assets/style/styleDefault';

const styles = StyleSheet.create({
  warrperTextLeft: { height: 42, justifyContent: 'center' },
  //common
  textHeader: {
    color: COLOR_TEXT_DEFAULT,
    fontSize: FONT_SIZE_HEADER,
  },
  container: { backgroundColor: BG_COLOR, height: '100%' },
  //icon
  statusIcon: {
    width: 10,
    height: 10,
    marginBottom: 2,
    marginRight: 5,
    marginLeft: 20,
  },
  //header
  dFlex: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 20,
    paddingLeft: 10,
  },
  headerIcon: {
    width: 12,
    height: 12,
    marginBottom: 5,
    marginRight: 10,
  },
  saveSetting: {
    cursor: 'pointer',
    width: 18,
    height: 18,
    marginBottom: 5,
    marginRight: 10,
    backgroundColor: '#FE5F58',
    borderRadius: 100,
    position: 'relative',
  },
  saveSettingX: {
    display: 'none',
    margin: 0,
    position: 'absolute',
    top: -2,
    left: 0,
    color: 'black',
  },
  saveSettingContent: {
    color: TEXT_COLOR_GREY,
    lineHeight: 30,
    textAlign: 'center',
    fontSize: FONT_FAMILY_DEFAULT,
    backgroundColor: BTN_COLOR_GREY,
    display: 'none',
    margin: 0,
    position: 'absolute',
    top: 25,
    left: -5,
    width: 100,
    borderRadius: 3,
  },
  headerContent: {
    fontFamily: FONT_FAMILY_HEADER,
    fontSize: '36px',
    color: COLOR_TEXT_DEFAULT,
    textTransform: 'uppercase',
    color: '#838383',
  },

  //body
  body: {
    display: 'flex',
    flexDirection: 'row',
    padding: 20,
    paddingBottom: 0,
    flex: 1,
  },

  left: {
    paddingHorizontal: 20,
  },

  mainAvatar: {
    width: 'fit-content',
    marginRight: 10,
    position: 'relative',
  },
  mainAvatarImage: {
    maxHeight: 200,
    maxWidth: 200,
    minHeight: 150,
    minWidth: 150,
    width: '10vw',
    height: '10vw',
    borderRadius: 100,
  },
  iconCamera: {
    position: 'absolute',
    right: -15,
    bottom: -5,
    width: 50,
    height: 40,
    cursor: 'pointer',
  },
  mainAvatarRight: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'end',
  },
  //item
  warperItem: {
    borderBottomColor: LINE_COLOR,
    borderBottomWidth: 1,
    paddingVertical: 20,
    paddingHorizontal: 10,
  },
  //right
  right: {
    padding: 10,
    borderRadius: 4,
    backgroundColor: BG_SIDEBAR,
    height: '100%',
  },
  warperItemRight: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',

    paddingVertical: 15,
    paddingHorizontal: 10,
  },
  itemRightHeader: {
    borderBottomColor: LINE_COLOR,
    borderBottomWidth: 1,
    marginBottom: 10,
  },
  avatarItemRight: {
    width: 40,
    height: 40,
    marginRight: 5,
  },
  itemRightHovered: {
    backgroundColor: BG_HOVER,
  },
  error: {
    color: 'red',
    fontSize: 12,
    marginTop: 5,
    //   position: 'absolute',
    //   bottom: -20,
    //   left: 0,
  },
});

export default styles;

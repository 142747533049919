import React, { useState, useEffect } from 'react';
import { Pressable, View, ScrollView, Image, TouchableOpacity, Dimensions } from 'react-native';
import { Col, Grid } from 'react-native-easy-grid';
import { useHistory, useLocation } from 'react-router-dom';
import { LinearGradient } from 'expo-linear-gradient';
import { useQuery } from '@tanstack/react-query';
import { withNamespaces } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Center } from 'native-base';

import HeaderSearch from '@components/Headers/HeaderSearch';
import styles from '../AccountCompany/styles';
import Pagination from '@components/common/Pagination';
import { JText as Text } from '@components/common/JText';
import JButton from '@components/common/JButton';
import { get } from '@utils/RequestLevica';
import { API_PATH, ROLE_USER } from '@helper/constant';
import Loading from '@components/common/Loading';
import { BG_FORM_DEFAULT } from '@assets/style/styleDefault';
import { checkPermission } from '@helper/commonFunction';

const AccountShop = ({ t }) => {
  const history = useHistory();
  const { search } = useLocation();
  const [tableHeadData, setTableHeadData] = useState(() => [
    {
      title: 'LEVICA ID',
      size: 2,
      hasIcon: true,
      sort: '0100',
      order: false,
    },
    {
      title: t('pages_AccountShop.tradeUsername'),
      hasIcon: true,
      sort: '0200',
      order: false,
      size: 2,
    },
    {
      title: t('pages_AccountShop.walletAddress'),
      size: 3,
    },
    {
      title: t('pages_AccountShop.typeOfLegalEntity'),
      size: 2,
      hasIcon: true,
      sort: '0300',
      order: false,
    },
    {
      title: t('pages_AccountShop.industry'),
      hasIcon: true,
      sort: '0400',
      order: false,
      size: 2,
    },
    {
      title: t('pages_AccountShop.accountStatus'),
      hasIcon: true,
      sort: '0100',
      order: false,
      size: 3,
    },
  ]);

  const [params, setParams] = useState(() => ({
    limit: 30,
    pageNumber: 1,
    q: search?.slice(3) ?? '',
    sort: '0100',
    order: 'desc',
  }));

  const userInfo = useSelector((state) => state.merchantUserInfo);

  const {
    data: accountShops,
    isFetching,
    remove,
  } = useQuery(
    ['accountShops', { ...params }],
    async ({ signal }) => {
      const data = await get(API_PATH.SHOP_ACCOUNT, { ...params, signal });
      return data;
    },
    { refetchOnWindowFocus: false }
  );

  const handleSort = (item) => {
    if (accountShops?.pageInfo?.totalCount === 0) return;
    setTableHeadData((prev) =>
      prev.map((tableHead) =>
        tableHead.sort === item.sort ? { ...tableHead, order: !tableHead.order } : { ...tableHead, order: false }
      )
    );

    setParams({
      ...params,
      sort: item.sort,
      pageNumber: 1,
      order: !item.order ? 'asc' : 'desc',
    });
  };

  const handlePress = (item) => {
    history.push(`/shop/details/${item.id}`);
  };

  const corporationType = [
    { value: '1', label: t('typeOfLegalEntify.type_01') },
    { value: '2', label: t('typeOfLegalEntify.type_02') },
    { value: '3', label: t('typeOfLegalEntify.type_03') },
    { value: '4', label: t('typeOfLegalEntify.type_04') },
    { value: '5', label: t('typeOfLegalEntify.type_05') },
    { value: '6', label: t('typeOfLegalEntify.type_06') },
    { value: '7', label: t('typeOfLegalEntify.type_07') },
    { value: '8', label: t('typeOfLegalEntify.type_08') },
    { value: '9', label: t('typeOfLegalEntify.type_09') },
    { value: '10', label: t('typeOfLegalEntify.type_10') },
    { value: '11', label: t('typeOfLegalEntify.type_11') },
    { value: '12', label: t('typeOfLegalEntify.type_12') },
    { value: '13', label: t('typeOfLegalEntify.type_13') },
    { value: '14', label: t('typeOfLegalEntify.type_14') },
    { value: '15', label: t('typeOfLegalEntify.type_15') },
    { value: '16', label: t('typeOfLegalEntify.type_16') },
    { value: '17', label: t('typeOfLegalEntify.type_17') },
    { value: '18', label: t('typeOfLegalEntify.type_18') },
    { value: '19', label: t('typeOfLegalEntify.type_19') },
    { value: '20', label: t('typeOfLegalEntify.type_20') },
    { value: '21', label: t('typeOfLegalEntify.type_21') },
    { value: '22', label: t('typeOfLegalEntify.type_22') },
    { value: '23', label: t('typeOfLegalEntify.type_23') },
    { value: '24', label: t('typeOfLegalEntify.type_24') },
    { value: '25', label: t('typeOfLegalEntify.type_25') },
    { value: '26', label: t('typeOfLegalEntify.type_26') },
    { value: '27', label: t('typeOfLegalEntify.type_27') },
    { value: '28', label: t('typeOfLegalEntify.type_28') },
    { value: '29', label: t('typeOfLegalEntify.type_29') },
    { value: '30', label: t('typeOfLegalEntify.type_30') },
    { value: '31', label: t('typeOfLegalEntify.type_31') },
    { value: '32', label: t('typeOfLegalEntify.type_32') },
    { value: '33', label: t('typeOfLegalEntify.type_33') },
    { value: '34', label: t('typeOfLegalEntify.type_34') },
  ];

  const industry = [
    { value: '1', label: t('industry.type_01') },
    { value: '2', label: t('industry.type_02') },
    { value: '3', label: t('industry.type_03') },
    { value: '4', label: t('industry.type_04') },
    { value: '5', label: t('industry.type_05') },
    { value: '6', label: t('industry.type_06') },
    { value: '7', label: t('industry.type_07') },
    { value: '8', label: t('industry.type_08') },
    { value: '9', label: t('industry.type_09') },
    { value: '10', label: t('industry.type_10') },
    { value: '11', label: t('industry.type_11') },
    { value: '12', label: t('industry.type_12') },
    { value: '13', label: t('industry.type_13') },
    { value: '14', label: t('industry.type_14') },
    { value: '15', label: t('industry.type_15') },
    { value: '16', label: t('industry.type_16') },
    { value: '17', label: t('industry.type_17') },
    { value: '18', label: t('industry.type_18') },
    { value: '19', label: t('industry.type_19') },
    { value: '20', label: t('industry.type_20') },
    { value: '21', label: t('industry.type_21') },
    { value: '22', label: t('industry.type_22') },
    { value: '23', label: t('industry.type_23') },
    { value: '24', label: t('industry.type_24') },
    { value: '25', label: t('industry.type_25') },
  ];

  useEffect(() => remove, [remove]);

  return (
    <>
      <View
        style={[
          styles.container,
          { backgroundColor: isFetching || accountShops?.merchantList?.length < 1 ? '' : BG_FORM_DEFAULT },
        ]}
      >
        <View style={styles.wrapperStickyHeader}>
          <View style={styles.headerSearch}>
            <HeaderSearch
              placeholder={t('pages_AccountShop_CreateAccount.placeholder')}
              setParams={setParams}
              params={params}
              value={search?.slice(3)}
            />
          </View>

          <View style={[styles.wrapperHeader]}>
            <View style={styles.wrapperHeaderLeft}>
              <Text style={styles.textHeader}>{t('pages_AccountShop.shopAccount')}</Text>
              <Text style={styles.textHeader}>
                {!accountShops?.pageInfo?.totalCount || isFetching ? 0 : accountShops?.pageInfo?.totalCount}{' '}
                {t('pages_AccountShop.shopAccountTotal')}
              </Text>
            </View>
            {checkPermission(ROLE_USER.USER_MANAGEMENT, userInfo) && (
              <View>
                <LinearGradient colors={['#32A5F7', '#2D81F3']} style={{ borderRadius: 5 }}>
                  <JButton
                    text={t('pages_AccountShop.buttonAddShop')}
                    width={200}
                    hasIcon
                    linkIcon={'accountCompany.svg'}
                    widthIcon={30}
                    heightIcon={20}
                    textColor={'#fff'}
                    onPressAction={() => history.push('/shop/create')}
                  />
                </LinearGradient>
              </View>
            )}
          </View>
          <Grid style={styles.wrapperHeadTable} size={30}>
            {accountShops?.merchantList?.length > 0 &&
              !isFetching &&
              tableHeadData.map((item) => (
                <Col size={item.size} style={styles.wrapperItem} key={item.title}>
                  <Text numberOfLines={3} style={styles.textHead}>
                    {item.title}
                  </Text>
                  {item.hasIcon && (
                    <Image
                      onClick={() => handleSort(item)}
                      style={item?.order ? styles.iconTableHeadDESC : styles.iconTableHeadASC}
                      source={require(`@assets/icons/iconDropdown.svg`)}
                    />
                  )}
                </Col>
              ))}
          </Grid>
        </View>
        <ScrollView showsVerticalScrollIndicator={false}>
          {isFetching ? (
            <Loading style={{ height: 'calc(100vh - 213px)' }} />
          ) : accountShops?.pageInfo?.totalCount > 0 ? (
            accountShops?.merchantList?.map((item, index, { length }) => {
              return (
                <Pressable key={index} style={({ hovered }) => [hovered && styles.buttonHovered]}>
                  <TouchableOpacity onPress={() => handlePress(item)}>
                    <Grid
                      style={[styles.wrapperItemTable, { borderBottomWidth: index + 1 === length ? 0 : 1 }]}
                      size={30}
                    >
                      <Col size={2} style={styles.wrapperItem}>
                        <Text numberOfLines={3}>{item?.displayId}</Text>
                      </Col>
                      <Col size={2} style={styles.wrapperItem}>
                        <Text numberOfLines={3}>{item?.tradeName+" / "+item?.name}</Text>
                      </Col>
                      <Col size={3} style={styles.wrapperItem}>
                        <Text numberOfLines={3}>{item?.leviasChainAddress}</Text>
                      </Col>
                      <Col size={2} style={styles.wrapperItem}>
                        <Text numberOfLines={3}>{corporationType[item?.corporationType - 1]?.label}</Text>
                      </Col>
                      <Col size={2} style={styles.wrapperItem}>
                        <Text numberOfLines={3}>{industry[item?.industry - 1]?.label}</Text>
                      </Col>
                      <Col size={3} style={styles.wrapperItem}>
                        <View style={styles.circle} />
                        <Text numberOfLines={3}>稼働中</Text>
                      </Col>
                    </Grid>
                  </TouchableOpacity>
                </Pressable>
              );
            })
          ) : (
            <Center w="100%" h={Dimensions.get('window').height - 213}>
              <Text>
                {params?.q ? t('pages_AccountShop.noAccountShop') : t('pages_AccountShop.noAccountShopRegistered')}
              </Text>
            </Center>
          )}
          <View>
            {params.limit < accountShops?.pageInfo?.totalCount && !isFetching && (
              <Pagination
                count={Math.ceil(accountShops?.pageInfo?.totalCount / params.limit)}
                params={params}
                setParams={setParams}
              />
            )}
          </View>
        </ScrollView>
      </View>
    </>
  );
};

export default withNamespaces()(AccountShop);

import React from 'react';
import { StyleSheet, TouchableOpacity, View } from 'react-native';

const ModalBackDrop = ({ style, onPress }) => {
  return onPress ? (
    <TouchableOpacity onPress={onPress}>
      <View style={[styles.backdrop, style]} />
    </TouchableOpacity>
  ) : (
    <View style={[styles.backdrop, style]} />
  );
};

const styles = StyleSheet.create({
  backdrop: {
    height: '100vh',
    width: '100vw',
    backgroundColor: '#000000',
  },
});

export default ModalBackDrop;

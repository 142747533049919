import React, { useState } from 'react';
import { Box } from 'native-base';
import { StyleSheet, Image } from 'react-native';
import { Controller, useFormContext } from 'react-hook-form';

import {
  COLOR_TEXT_DEFAULT,
  FONT_FAMILY_DEFAULT,
  INPUT_BG,
  TEXT_COLOR_GREEN,
  LINE_COLOR,
} from '@assets/style/styleDefault';
import { JText as Text } from './JText';

const JSelect = ({ selectItems, control, name, placeholder = '', disable }) => {
  const [isFocused, setIsFocused] = useState(false);
  const { setValue, trigger } = useFormContext();
  return (
    <Controller
      name={name}
      control={control}
      defaultValue=""
      render={({ field: { value }, fieldState: { error } }) => (
        <Box w="100%">
          <Image style={[styles.selectIcon]} source={require('@assets/icons/iconDownBlack.svg')} />
          <select
            disabled={!disable}
            style={{
              color: value ? COLOR_TEXT_DEFAULT : '#a5a5a5',
              borderColor: isFocused ? TEXT_COLOR_GREEN : error ? 'red' : LINE_COLOR,
              borderWidth: isFocused ? 2 : 1,
              backgroundColor: INPUT_BG,
              padding: '10px',
              borderRadius: '5px',
              outlineStyle: 'none',
              height: '42px',
              fontSize: 14,
              appearance: 'none',
              fontFamily: FONT_FAMILY_DEFAULT,
            }}
            value={value ? value : placeholder}
            onChange={(e) => {
              setValue(name, e.target.value, { shouldDirty: true });
              trigger(name);
            }}
            onFocus={() => {
              setIsFocused(true);
            }}
            onBlur={() => {
              setIsFocused(false);
            }}
          >
            <option
              disabled
              style={{
                color: '#a5a5a5',
              }}
            >
              {placeholder}
            </option>
            {selectItems?.map((item) => (
              <option
                key={item.label}
                value={item.value}
                style={{
                  color: COLOR_TEXT_DEFAULT,
                  fontSize: 13,
                  fontFamily: FONT_FAMILY_DEFAULT,
                }}
              >
                {item.label}
              </option>
            ))}
          </select>
          <Text style={styles.error}>{error?.message}</Text>
        </Box>
      )}
    />
  );
};

export default JSelect;

const styles = StyleSheet.create({
  selectIcon: {
    width: 11,
    height: 11,
    right: 10,
    resizeMode: 'contain',
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
  },
  error: {
    color: 'red',
    fontSize: 12,
    position: 'absolute',
    bottom: -20,
    left: 0,
    paddingBottom: 2,
  },
});

import React, { useEffect, useState } from 'react';
import { View, Image, ScrollView, Pressable, TouchableOpacity } from 'react-native';
import { Col, Row } from 'react-native-easy-grid';
import { useHistory } from 'react-router-dom';
import { formatNumber } from '@helper/formatTypes';
import { withNamespaces } from 'react-i18next';
import { t } from 'i18next';

import HeaderSearch from '@components/Headers/HeaderSearch';
import Pagination from '@components/common/Pagination';
import JButton from '@components/common/JButton';
import { JText as Text } from '@components/common/JText';
import Loading from '@components/common/Loading';

import { BTN_COLOR_PRIMARY, TEXT_COLOR_GREEN, TEXT_COLOR_GREY, TEXT_COLOR_RED } from '@assets/style/styleDefault';
import styles from './style';
import { LinearGradient } from 'expo-linear-gradient';

const valuePrimary = {
  '0100': '下書き', //token status draft
  '0200': '運用中', //token status in operation
  '0300': '終了', //token status connecting
};
const valueAssetType = {
  '0100': 'ポイント', //point
  '0200': 'ギフト券', //gift
};

const Token = ({ t }) => {
  const history = useHistory();
  const [tokenListData, setTokenListData] = useState([]);

  const [tableHeadToken, setTableHeadToken] = useState([
    {
      title: t('pages_Token.tableHeader_code'),
      field: '0100',
      hasIcon: true,
      sort: false,
      size: 1,
    },
    {
      title: t('pages_Token.tableHeader_icon'),
      hasIcon: false,
      size: 1,
    },
    {
      title: t('pages_Token.tableHeader_symbol'),
      field: '0200',
      hasIcon: true,
      sort: false,
      size: 1,
    },
    {
      title: t('pages_Token.tableHeader_tokenName'),
      field: '0300',
      hasIcon: true,
      sort: false,
      size: 2,
    },
    {
      title: t('pages_Token.tableHeader_assetType'),
      field: '0400',
      hasIcon: true,
      sort: false,
      size: 2,
    },
    {
      title: t('pages_Token.tableHeader_activationToken'),
      field: '0500',
      hasIcon: true,
      sort: false,
      size: 2,
    },
    {
      title: t('pages_Token.tableHeader_issuerName'),
      field: '0600',
      hasIcon: true,
      sort: false,
      size: 2,
    },
    {
      title: t('pages_Token.tableHeader_status'),
      field: '0800',
      hasIcon: true,
      sort: false,
      size: 1,
    },
  ]);

  const [params, setParams] = useState({
    per_page: 30,
    page: 1,
    search: '',
    field: '0100',
    type: '0100',
  });

  const hanldeSort = (item) => {
    tableHeadToken.forEach((i) => {
      if (item.field === i.field) {
        i.sort = !i.sort;
      } else {
        i.sort = false;
      }
    });
    setParams({
      ...params,
      field: item.field,
      type: item.sort ? '0200' : '0100',
    });
  };

  const onChangePage = (e) => {
    setParams({
      ...params,
      page: e.target.innerText,
    });
  };

  const handleChangepage = (item) => {
    switch (item.assetType) {
      case Object.keys(valueAssetType)[0]: //Link to add point
        history.push(`/tokens/point/${item.productId}`);
        break;
      case Object.keys(valueAssetType)[1]: //Link To add gift
        history.push(`/tokens/gift/${item.productId}`);
        break;
      default:
        return;
    }
  };

  return (
    <View style={styles.container}>
      <View style={styles.wrapperStickyHeader}>
        <View style={styles.headerSearch}>
          <HeaderSearch params={params} setParams={setParams} placeholder={t('pages_Token.search_placeholder')} />
        </View>
        <View style={[styles.wrapperHeader]}>
          <View style={styles.wrapperHeaderLeft}>
            <Text style={styles.textHeader}>{t('pages_Token.pointGiftList')}</Text>
            <Text style={styles.textHeader}>
              {0} {t('pages_Token.kinds')}
            </Text>
          </View>
          <View style={styles.wrapperItemHead}>
            <View style={{ marginRight: 30 }}>
              <LinearGradient colors={['#32A5F7', '#2D81F3']} style={{ borderRadius: 5 }}>
                <JButton
                  text={t('pages_Token.issuingNewPoint')}
                  width={200}
                  linkIcon={'point.svg'}
                  widthIcon={46}
                  heightIcon={30}
                  textColor={'#fff'}
                  onPressAction={() => history.push('/tokens/add-point')}
                />
              </LinearGradient>
            </View>
            <View>
              <LinearGradient colors={['#FFB045', '#F0852B']} style={{ borderRadius: 5 }}>
                <JButton
                  text={t('pages_Token.newGiftIssue')}
                  width={200}
                  linkIcon={'gift.svg'}
                  widthIcon={45}
                  heightIcon={26}
                  textColor={'#fff'}
                  onPressAction={() => history.push('/tokens/add-gift')}
                />
              </LinearGradient>
            </View>
          </View>
        </View>
        <Row style={[styles.head]} size={25}>
          {tableHeadToken.map((item, index) => (
            <Col key={index} size={item.size} style={styles.wrapperItemHead}>
              <Text numberOfLines={2} style={styles.textHead}>
                {item.title}
              </Text>
              {item.hasIcon && (
                <Image
                  style={item.sort ? styles.iconTableHeadDESC : styles.iconTableHeadASC}
                  onClick={() => hanldeSort(item)}
                  source={require('@assets/icons/iconDropdown.svg')}
                />
              )}
            </Col>
          ))}
        </Row>
      </View>
      <ScrollView>
        {tokenListData?.length > 0 ? (
          <>
            {tokenListData?.map((item, index) => {
              return (
                <View key={index} style={styles.wrapperBody}>
                  <Pressable style={({ hovered }) => [hovered && styles.buttonHovered]}>
                    <TouchableOpacity onPress={() => handleChangepage(item)}>
                      <Row size={25} style={[styles.row, { gap: 20 }]}>
                        <Col size={1} style={styles.wrapperItem}>
                          <Text style={styles.text} numberOfLines={2}>
                            {item.securityTokenCd}
                          </Text>
                        </Col>
                        <Col size={1} style={styles.wrapperItem}>
                          <Image style={styles.iconWarning} source={item.productImgPath} />
                        </Col>
                        <Col size={1} style={styles.wrapperItem}>
                          <Text style={styles.text} numberOfLines={2}>
                            {item.itemSymbol}
                          </Text>
                        </Col>
                        <Col size={2} style={styles.wrapperItem}>
                          <Text style={[styles.text]} numberOfLines={2}>
                            {item.productName}
                          </Text>
                        </Col>
                        <Col size={2} style={styles.wrapperItem}>
                          <Text style={styles.text} numberOfLines={2}>
                            {valueAssetType[item?.assetType]}
                          </Text>
                        </Col>
                        <Col size={2} style={styles.wrapperItem}>
                          <View style={{ ...styles.wrapperItem, paddingRight: 19 }}>
                            <Text style={styles.text}>{formatNumber(item.issuableStockCount)}</Text>
                          </View>
                          <View style={styles.wrapperItem}>
                            <Text style={styles.text}>{`${formatNumber(item.whitelists)} 人`}</Text>
                          </View>
                        </Col>
                        <Col size={2} style={styles.wrapperItem}>
                          <Text style={styles.text} numberOfLines={2}>
                            {item.projectName}
                          </Text>
                        </Col>
                        <Col size={1} style={styles.wrapperItem}>
                          <Text
                            style={{
                              ...styles.text,
                              color:
                                valuePrimary[item.productStatusPrimary] === valuePrimary['0300']
                                  ? TEXT_COLOR_RED //終了
                                  : valuePrimary[item.productStatusPrimary] === valuePrimary['0200']
                                  ? BTN_COLOR_PRIMARY //非公開
                                  : valuePrimary[item.productStatusPrimary] === '連携中'
                                  ? TEXT_COLOR_GREEN //連携中
                                  : TEXT_COLOR_GREY, //下書き
                            }}
                            numberOfLines={2}
                          >
                            {valuePrimary[item.productStatusPrimary]}
                          </Text>
                        </Col>
                      </Row>
                    </TouchableOpacity>
                  </Pressable>
                </View>
              );
            })}
            <View>
              <Pagination
                page={params.page}
                count={Math.ceil(dataTokenList?.findTokenList?.pageInfo?.totalCount / params.per_page)}
                onChange={onChangePage}
                params={params}
                setParams={setParams}
              />
            </View>
          </>
        ) : (
          <View style={styles.wrapperNotData}>
            <View>
              <Text style={styles.textNoData}>{t('pages_Token.noToken')}</Text>
              <Text style={styles.textNoData}>{t('pages_Token.issueNewPointOrGifts')}</Text>
            </View>
          </View>
        )}
      </ScrollView>
    </View>
  );
};

export default withNamespaces()(Token);

import React, { useState } from 'react';
import { View, Image, StyleSheet, TouchableOpacity } from 'react-native';
import { Col, Grid } from 'react-native-easy-grid';
import { withNamespaces } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import {
  FONT_FAMILY_HEADER,
  TEXT_COLOR_WHITE,
  BG_COLOR,
  TEXT_COLOR_GREY,
  TEXT_COLOR_GREEN,
} from '@assets/style/styleDefault';
import { JText as Text } from '@components/common/JText';
import { formatNumber } from '@helper/formatTypes';
import ModalBurn from '@components/Modals/ModalBurn';
import { checkPermission } from '@helper/commonFunction';
import { ROLE_USER } from '@helper/constant';

const Overview = ({ t, dashboardData, refetch }) => {
  const userInfo = useSelector((state) => state.merchantUserInfo);
  const [isModalBurn, setIsModalBurn] = useState(false);
  const toggleModalBurn = () => {
    setIsModalBurn(!isModalBurn);
  };
  return (
    <>
      <View style={styles.wrapperOverview}>
        <Text style={styles.textOVerview}>LEVICA {t('pages_Home.LEVICABalance')}</Text>
        <Grid style={styles.head}>
          <Col size={35}>
            <View style={styles.leftSide}>
              <Text style={styles.textHeader}> ① {t('pages_Home.LEVICADistributed')} LEVICA</Text>
              <View style={styles.balance}>
                <Text style={styles.textHeader}>{t('pages_Home.balanceTotal')}</Text>
                <Text numberOfLines={4} style={[styles.textHeader, styles.textItalic, { fontSize: 40, padding: 5 }]}>
                  {`¥ ${formatNumber(dashboardData?.leviasChainTransactionBalance)}`}
                </Text>
              </View>
              <View style={styles.wrapperBurn}>
                {checkPermission(ROLE_USER.BURN_TOKEN, userInfo) ? (
                  <TouchableOpacity onPress={() => toggleModalBurn()}>
                    <View style={[styles.wrapperIcon, { borderBottomColor: 'red' }]}>
                      <Image style={styles.burnIcon} source={require('@assets/icons/burnIcon.svg')} />
                      <Text style={[styles.textHeader, { color: 'red' }]}>{t('pages_Home.LEVICAOfBurn')} </Text>
                    </View>
                  </TouchableOpacity>
                ) : (
                  <View style={[styles.wrapperIcon, { borderBottomColor: TEXT_COLOR_GREY }]}>
                    <Image style={styles.burnIcon} source={require('@assets/icons/burnIconGray.svg')} />
                    <Text style={[styles.textHeader, { color: TEXT_COLOR_GREY }]}>{t('pages_Home.LEVICAOfBurn')} </Text>
                  </View>
                )}

                <Link
                  to={{
                    pathname: checkPermission(ROLE_USER.USER_WALLET_OPERATION, userInfo)
                      ? '/send-token-admin-to-user'
                      : '',
                    state: {
                      balance: dashboardData?.leviasChainIssuerBalance,
                    },
                  }}
                  style={{ textDecorationLine: 'none' }}
                >
                  <View
                    style={[
                      styles.wrapperIcon,
                      {
                        borderBottomColor: checkPermission(ROLE_USER.USER_WALLET_OPERATION, userInfo)
                          ? TEXT_COLOR_GREEN
                          : TEXT_COLOR_GREY,
                      },
                    ]}
                  >
                    {checkPermission(ROLE_USER.USER_WALLET_OPERATION, userInfo) ? (
                      <Image style={styles.sendIcon} source={require('@assets/icons/sendHome.svg')} />
                    ) : (
                      <Image style={styles.sendIcon} source={require('@assets/icons/sendGray.svg')} />
                    )}

                    <Text
                      style={[
                        styles.textHeader,
                        {
                          color: checkPermission(ROLE_USER.USER_WALLET_OPERATION, userInfo)
                            ? TEXT_COLOR_GREEN
                            : TEXT_COLOR_GREY,
                        },
                      ]}
                    >
                      LEVICA {t('pages_Home.LEVICARelocation')}
                    </Text>
                  </View>
                </Link>
              </View>
            </View>
          </Col>
          <Col size={65}>
            <View style={styles.rightSide}>
              <Text style={[styles.textHeader, { textAlign: 'left', minWidth: 270 }]}>
                {`② ${t('pages_Home.manifestation')} LEVICA`}
              </Text>
              <View style={styles.wrapperNumbers}>
                <Text style={styles.textNumber}>
                  <Text numberOfLines={3} style={[styles.textNumber, styles.textItalic]}>
                    {` ¥ ${formatNumber(dashboardData?.leviasChainIssuerBalance)}`}
                  </Text>
                </Text>
                <Link
                  to={{
                    pathname: checkPermission(ROLE_USER.ADMIN_WALLET_OPERATION, userInfo)
                      ? '/send-token-from-management-wallet'
                      : '',
                    state: {
                      balance: dashboardData?.leviasChainMasterBalance,
                    },
                  }}
                  style={{ textDecorationLine: 'none', paddingLeft: 20 }}
                >
                  <View
                    style={[
                      styles.wrapperIcon,
                      {
                        borderBottomColor: checkPermission(ROLE_USER.ADMIN_WALLET_OPERATION, userInfo)
                          ? TEXT_COLOR_GREEN
                          : TEXT_COLOR_GREY,
                      },
                    ]}
                  >
                    {checkPermission(ROLE_USER.ADMIN_WALLET_OPERATION, userInfo) ? (
                      <Image style={styles.addIcon} source={require('@assets/icons/add.svg')} />
                    ) : (
                      <Image style={styles.addIcon} source={require('@assets/icons/addGray.svg')} />
                    )}
                    <Text
                      style={[
                        styles.textHeader,
                        {
                          color: checkPermission(ROLE_USER.ADMIN_WALLET_OPERATION, userInfo)
                            ? TEXT_COLOR_GREEN
                            : TEXT_COLOR_GREY,
                        },
                      ]}
                    >
                      LEVICA {t('pages_Home.manifestationOfLevica')}
                    </Text>
                  </View>
                </Link>
              </View>
              <Text style={[styles.textHeader, { textAlign: 'left', minWidth: 270 }]}>
                ③ {t('pages_Home.latent')} LEVICA
              </Text>
              <View style={styles.wrapperNumbers}>
                <Text style={[styles.textNumber, styles.textItalic]}>
                  {`¥ ${formatNumber(dashboardData?.leviasChainMasterBalance)}`}
                </Text>
                <Link
                  to={
                    checkPermission(ROLE_USER.PUBLISH_WALLET_OPERATION, userInfo)
                      ? '/send-token-from-issuing-wallet'
                      : ''
                  }
                  style={{ textDecorationLine: 'none', paddingLeft: 20 }}
                >
                  <View
                    style={[
                      styles.wrapperIcon,
                      {
                        borderBottomColor: checkPermission(ROLE_USER.PUBLISH_WALLET_OPERATION, userInfo)
                          ? TEXT_COLOR_GREEN
                          : TEXT_COLOR_GREY,
                      },
                    ]}
                  >
                    {checkPermission(ROLE_USER.PUBLISH_WALLET_OPERATION, userInfo) ? (
                      <Image style={styles.addIcon} source={require('@assets/icons/add.svg')} />
                    ) : (
                      <Image style={styles.addIcon} source={require('@assets/icons/addGray.svg')} />
                    )}
                    <Text
                      style={[
                        styles.textHeader,
                        {
                          color: checkPermission(ROLE_USER.PUBLISH_WALLET_OPERATION, userInfo)
                            ? TEXT_COLOR_GREEN
                            : TEXT_COLOR_GREY,
                        },
                      ]}
                    >
                      {t('pages_Home.latentLEVICAIssuance')}
                    </Text>
                  </View>
                </Link>
              </View>
            </View>
          </Col>
        </Grid>
      </View>
      <ModalBurn isModal={isModalBurn} setIsModal={setIsModalBurn} refetch={refetch} />
    </>
  );
};

export default withNamespaces()(Overview);

const styles = StyleSheet.create({
  wrapperOverview: {
    borderWidth: 1,
    borderColor: '#5C5C5C',
    marginRight: 10,
    position: 'relative',
    marginVertical: 20,
  },
  head: { gap: 10, marginTop: 30 },
  balance: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: 30,
  },
  textOVerview: {
    position: 'absolute',
    top: -20,
    left: 20,

    paddingHorizontal: 15,

    fontFamily: FONT_FAMILY_HEADER,
    fontSize: 26,
    backgroundColor: BG_COLOR,
    fontWeight: '500',
    color: TEXT_COLOR_WHITE,
    textTransform: 'uppercase',
  },
  leftSide: {
    textAlign: 'right',
    marginTop: 20,
  },
  rightSide: {
    textAlign: 'center',
    alignItems: 'center',
    marginHorizontal: 10,
  },
  textHeader: {
    fontSize: 20,
    fontWeight: '500',
    color: TEXT_COLOR_WHITE,
  },
  textItalic: {
    fontStyle: 'italic',
  },
  burnIcon: {
    width: '18px',
    height: '26px',
    marginRight: '10px',
  },
  sendIcon: {
    width: '25px',
    height: '20px',
    marginRight: '10px',
  },
  addIcon: {
    width: '24px',
    height: '24px',
    marginRight: '10px',
    resizeMode: 'contain',
  },
  wrapperIcon: {
    borderBottomColor: TEXT_COLOR_GREEN,
    borderBottomWidth: 1.2,
    flexDirection: 'row',
    alignItems: 'center',
  },
  wrapperBurn: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    gap: 30,
    marginBottom: 30,
    marginLeft: 30,
  },
  wrapperNumbers: {
    flexDirection: 'row',
    paddingVertical: 10,
    alignItems: 'center',
    minWidth: 450,
  },
  textNumber: {
    fontSize: 20,
    fontWeight: '500',
    color: TEXT_COLOR_WHITE,
    fontSize: 32,
    textAlign: 'right',
    width: 350,
    paddingRight: 5,
  },
});

import React from 'react';
import { LinearGradient } from 'expo-linear-gradient';
import { View, StyleSheet, Image } from 'react-native';
import Modal from 'react-native-modal';
import { withNamespaces } from 'react-i18next';

import {
  FONT_SIZE_DEFAULT,
  FONT_SIZE_HEADER,
  BORDER_TABLE_GREY,
  BG_HOVER,
  BTN_COLOR_GREY_2,
} from '@assets/style/styleDefault';
import JButton from '@components/common/JButton';
import { JText as Text } from '@components/common/JText';
import DragFileFromLocal from '@components/common/DragFileFromLocal';
import ModalBackDrop from '@components/Modals/ModalBackDrop';

const ModalAddPdfFile = (props) => {
  const { isModalVisible, setModalVisible, pickPdfFile = null, pickFileByDrag, t,isAddingFile } = props;
  const pickFileByDragAction = (file) => {
    pickFileByDrag(file);
    setModalVisible(false);
  };

  return (
    <View>
      <Modal
        animationIn="fadeIn"
        isVisible={isModalVisible}
        style={{ alignItems: 'center' }}
        customBackdrop={<ModalBackDrop onPress={() => setModalVisible(false)} />}
      >
        <DragFileFromLocal dragAction={pickFileByDragAction}>
          <View style={styles.wrapperModal}>
            <View style={styles.maxWidth700}>
              <View style={styles.header}>
                <Image source={require('@assets/icons/addFilePDF.svg')} alt="warning" style={styles.stopIcon} />
                <Text style={styles.textHeader}>{t('pages_FileManager_partials_ModalAddPdfFile.textHeader')}</Text>
              </View>
              <View style={styles.body}>
                <Text style={styles.textBody}>{t('pages_FileManager_partials_ModalAddPdfFile.textBody')}</Text>
                <View style={styles.button}>
                  <JButton
                    text={t('commonText.cancelButton')}
                    linkIcon="iconX-White.svg"
                    heightIcon={15}
                    widthIcon={15}
                    width={200}
                    textColor="#fff"
                    btnColor={BTN_COLOR_GREY_2}
                    onPressAction={() => {
                      setModalVisible(false);
                    }}
                  />
                  <View style={{ paddingRight: 30 }}></View>
                  <LinearGradient colors={['#32A5F7', '#2D81F3']} style={{ borderRadius: 5 }}>
                    <JButton
                      text={t('pages_FileManager_partials_ModalAddPdfFile.AddPdfFile')}
                      width={200}
                      textColor="#fff"
                      iconSubfix="iconPdfButton.svg"
                      widthSubfix={27}
                      disabled={isAddingFile}
                      isProcessing={isAddingFile}
                      heightSubfix={36}
                      onPressAction={() => {
                        pickPdfFile(_, _, () => {
                          setModalVisible(false)
                        });
                      }}
                    />
                  </LinearGradient>
                </View>
              </View>
            </View>
          </View>
        </DragFileFromLocal>
      </Modal>
    </View>
  );
};

export default withNamespaces()(ModalAddPdfFile);

const styles = StyleSheet.create({
  //common
  dFlex: {
    display: 'flex',
    alignItems: 'center',
  },
  text: {
    color: 'black',
    fontSize: FONT_SIZE_DEFAULT,
  },
  textHeader: {
    color: 'black',
    fontSize: FONT_SIZE_HEADER,
  },
  textBody: { color: '#797979' },
  wrapperModal: {
    maxWidth: 900,
    minWidth: 600,
  },

  maxWidth700: {
    backgroundColor: '#fff',
    borderRadius: 10,
    paddingVertical: 30,
  },
  header: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    borderBottomColor: BORDER_TABLE_GREY,
    borderBottomWidth: 1,
    paddingBottom: 20,
  },
  stopIcon: {
    width: 53,
    height: 37,
    marginRight: 10,
  },
  statusIcon: {
    width: 23,
    height: 30,
    marginRight: 10,
  },
  body: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 40,
    paddingBottom: 10,
    paddingTop: 60,
  },
  warperItem: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: 500,
    paddingVertical: 10,
    paddingHorizontal: 20,
    borderBottomColor: BORDER_TABLE_GREY,
    borderBottomWidth: 1,
    marginBottom: 5,
  },

  itemHovered: {
    backgroundColor: BG_HOVER,
    borderRadius: 4,
  },
  button: {
    marginTop: 60,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
});

import React, { useEffect, useCallback } from 'react';
import AccountInput from '@components/AccountComponents/AccountInput';
import { Col, Grid } from 'react-native-easy-grid';
import { useFormContext } from 'react-hook-form';
import { View } from 'react-native';

import styles from './styles';
import JInput from '@components/common/JInput';
import { JText as Text } from '@components/common/JText';
import { get } from '@utils/RequestLevica';
import { API_PATH } from '@helper/constant';

const Address = ({ control, disable, t }) => {
  const addressData = [
    {
      placeholder: t('pages_AccountManagement_CreateAccount.municipalityPlaceholder'),
      isRequired: false,
      labelText: t('pages_AccountManagement_CreateAccount.municipality'),
      subfix: 'iconEdit.svg',
      name: 'city',
      type: 'DEFAULT',
    },
    {
      placeholder: t('pages_AccountManagement_CreateAccount.streetAddressPlaceholder'),
      isRequired: false,
      labelText: t('pages_AccountManagement_CreateAccount.streetAddress'),
      subfix: 'iconEdit.svg',
      name: 'address',
      type: 'DEFAULT',
    },
    {
      placeholder: t('pages_AccountManagement_CreateAccount.buildingNamePlaceholder'),
      isRequired: false,
      labelText: t('pages_AccountManagement_CreateAccount.buildingName'),
      subfix: 'iconEdit.svg',
      name: 'building',
      type: 'DEFAULT',
    },
    {
      placeholder: t('pages_AccountManagement_CreateAccount.roomNumberNamePlaceholder'),
      isRequired: false,
      labelText: t('pages_AccountManagement_CreateAccount.roomNumberName'),
      subfix: 'iconEdit.svg',
      name: 'floor',
      type: 'DEFAULT',
    },
  ];

  const {
    getValues,
    setError,
    setValue,
    formState: { errors },
    clearErrors,
    trigger,
  } = useFormContext();

  const clearAddressDependOnZipCode = useCallback(() => {
    setValue('prefecture', '');
    setValue('city', '');
    setValue('address', '');
  }, [setValue]);

  const setAddressByDataFetched = useCallback(
    (field, value) => {
      if (value) clearErrors(field);
      setValue(field, value);
    },
    [clearErrors, setValue]
  );

  const getPostCode = useCallback(
    async (code) => {
      try {
        const data = await get(`${API_PATH.POST_CODE}/${code}`);
        setAddressByDataFetched('prefecture', data?.prefecture);
        setAddressByDataFetched('city', data?.city);
        // setAddressByDataFetched('address', data?.street);
        trigger(['prefecture', 'city']);
      } catch (error) {
        if (error?.response?.data?.code == '3016') {
          setError('postCode', { type: 'custom', message: t('errorMessages.TEXT_POSTAL_CODE_NOT_EXISTS') });
          clearAddressDependOnZipCode();
        }
      }
    },
    [clearAddressDependOnZipCode, setAddressByDataFetched]
  );

  useEffect(() => {
    if (!errors?.postCode && getValues('postCode')) {
      getPostCode(getValues('postCode'));
    }

    if (errors?.postCode) clearAddressDependOnZipCode();
  }, [!errors?.postCode, clearAddressDependOnZipCode]);
  return (
    <>
      <Grid style={styles.marginBottom20}>
        <Col size={4}>
          <Text style={styles.colorWhite}>{t('pages_AccountManagement_CreateAccount.address')}</Text>
        </Col>
        <Col size={8}></Col>
      </Grid>

      <Grid style={styles.marginBottom20}>
        <Col size={4} style={styles.flex}>
          <View nativeID="postCode">
            <Text style={[styles.colorWhite, styles.marginLeft12]}>
              {t('pages_AccountManagement_CreateAccount.zipCode')}
            </Text>
          </View>
        </Col>
        <Col size={8} style={styles.flex}>
          <Grid>
            <Col size={5} style={{ position: 'relative', minWidth: 200 }}>
              <JInput
                disable={disable}
                control={control}
                name="postCode"
                placeholder={t('pages_AccountManagement_CreateAccount.zipCodePlaceholder')}
                subfix="iconEdit.svg"
                widthSubfix={15}
                heightSubfix={15}
                paddingLeft={10}
              />
            </Col>
            <Col size={7} style={styles.flex}>
              <Text style={[styles.marginLeft8]}>{t('pages_AccountManagement_CreateAccount.zipCodeNote')} </Text>
            </Col>
          </Grid>
        </Col>
      </Grid>

      <Grid style={styles.marginBottom20}>
        <Col size={4} style={styles.flex}>
          <View nativeID="prefecture">
            <Text style={[styles.colorWhite, styles.marginLeft12]}>
              {t('pages_AccountManagement_CreateAccount.prefectureCode')}
            </Text>
          </View>
        </Col>
        <Col size={8} style={styles.flex}>
          <Grid>
            <Col size={5} style={{ position: 'relative', minWidth: 200 }}>
              <JInput
                disable={disable}
                control={control}
                name="prefecture"
                placeholder={t('pages_AccountManagement_CreateAccount.prefectureCodePlaceholder')}
                subfix="iconEdit.svg"
                widthSubfix={15}
                heightSubfix={15}
                paddingLeft={10}
              />
            </Col>
            <Col size={7} style={styles.flex}></Col>
          </Grid>
        </Col>
      </Grid>

      {addressData.map((i) => (
        <AccountInput
          disable={disable}
          key={i.name}
          control={control}
          name={i.name}
          placeholder={i.placeholder}
          isRequired={i.isRequired}
          labelText={i.labelText}
          subfix={i.subfix}
          ml={12}
        />
      ))}
    </>
  );
};

export default Address;

import * as Yup from 'yup';
import { isValidPhoneNumber, parsePhoneNumber } from 'react-phone-number-input';
import { t } from 'i18next';

export const defaultValues = {
  id: '',
  leviasChainAddress: '',
  name: '',
  icon: '',
  iconName: '',

  lastName: '',
  lastNameKana: '',
  firstName: '',
  firstNameKana: '',

  email: '',
  phone: '',

  postCode: '',
  prefecture: '',
  city: '',
  address: '',
  building: '',
  floor: '',
};

export const validationSchema = () => {
  const validate = Yup.object().shape({
    id: Yup.string().trim().nullable(),
    leviasChainAddress: Yup.string().trim(),
    username: Yup.string()
      .trim()
      .max(32, t('errorMessages.TXT_MAX_32'))
      .required(t('errorMessages.fieldRequired', { fieldName: t('pages_AccountManagement_CreateAccount.userIcon') }))
      .nullable(),
    iconName: Yup.string().trim().nullable(),
    lastName: Yup.string().trim().max(32, t('errorMessages.TXT_MAX_32')).nullable(),
    lastNameKana: Yup.string().trim().max(32, t('errorMessages.TXT_MAX_32')).nullable(),
    firstName: Yup.string().trim().max(32, t('errorMessages.TXT_MAX_32')).nullable(),
    firstNameKana: Yup.string().trim().max(32, t('errorMessages.TXT_MAX_32')).nullable(),

    email: Yup.string()
      .trim()
      .email(t('errorMessages.TXT_EMAIL_REQUIRED'))
      .max(32, t('errorMessages.TXT_MAX_32'))
      .required(t('errorMessages.fieldRequired', { fieldName: t('pages_AccountManagement_CreateAccount.email') })),
    phone: Yup.string()
      .trim()
      .nullable()
      .test('compare ', t('errorMessages.TXT_JP_PHONE'), (phone) => {
        if (phone && parsePhoneNumber(phone)?.nationalNumber) {
          return isValidPhoneNumber(phone);
        } else {
          return true;
        }
      })
      .required(
        t('errorMessages.fieldRequired', { fieldName: t('pages_AccountManagement_CreateAccount.mobileNumber') })
      ),

    postCode: Yup.string()
      .trim()
      .nullable()
      .test('compare ', t('errorMessages.TXT_POSTAL_CODE'), (postcode) => {
        if (!!postcode) {
          return /^[0-9]{7}$/.test(postcode);
        } else {
          return true;
        }
      }),
    prefecture: Yup.string().trim().max(128, t('errorMessages.TXT_MAX_128')).nullable(),
    city: Yup.string().trim().max(128, t('errorMessages.TXT_MAX_128')).nullable(),
    address: Yup.string().trim().max(128, t('errorMessages.TXT_MAX_128')).nullable(),
    building: Yup.string().trim().max(128, t('errorMessages.TXT_MAX_128')).nullable(),
    floor: Yup.string().trim().max(32, t('errorMessages.TXT_MAX_32')).nullable(),
  });

  return validate;
};

import React, { useCallback, useEffect, useState } from 'react';
import { View, Image, ScrollView, Pressable, TouchableOpacity, Dimensions } from 'react-native';
import { Col, Row, Grid } from 'react-native-easy-grid';
import { useHistory } from 'react-router-dom';
import { LinearGradient } from 'expo-linear-gradient';
import { withNamespaces } from 'react-i18next';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Center } from 'native-base';

import Pagination from '@components/common/Pagination';
import JButton from '@components/common/JButton';
import ModalDelete from '@components/Modals/ModalDelete';
import ModalAlert from '@components/Modals/ModalAlert';
import HeaderSearch from '@components/Headers/HeaderSearch';
import { JText as Text } from '@components/common/JText';
import JCheckbox from '@components/common/JCheckbox';
import styles from './style';
import { del, get } from '@utils/RequestLevica';
import { API_PATH } from '@helper/constant';
import Loading from '@components/common/Loading';
import { BG_TABLE_DEFAULT } from '@assets/style/styleDefault';
import formatDisplayId from '@helper/formatDisplayId';
import { formatDateTime } from '@helper/formatTypes';

const Infomation = ({ t }) => {
  const history = useHistory();
  const [modalDelete, setModalDelete] = useState(false);
  const [modalAlert, setModalAlert] = useState(false);
  const [infoList, setInfoList] = useState([]);
  const [isDeleting, setIsDeleting] = useState(false);
  const [infoIDSelected, setInfoIDSelected] = useState([]);
  const [tableHeadData, setTableHeadData] = useState([
    {
      title: 'No',
      size: 2,
      hasIcon: true,
      sort: '0100',
      order: false,
    },
    {
      title: t('pages_Infomation.image'),
      size: 1,
    },
    {
      title: t('pages_Infomation.type'),
      size: 1,
      hasIcon: true,
      sort: '0200',
      order: false,
    },
    {
      title: t('pages_Infomation.title'),
      size: 3,
    },
    {
      title: t('pages_Infomation.category'),
      size: 3,
      hasIcon: true,
      sort: '0300',
      order: false,
    },
    {
      title: t('pages_Infomation.time'),
      size: 2,
      hasIcon: true,
      sort: '0400',
      order: false,
    },
    {
      title: t('pages_Infomation.status'),
      size: 3,
      hasIcon: true,
      sort: '0500',
      order: false,
    },
    {
      title: t('pages_Infomation.select'),
      size: 1,
    },
  ]);

  const [params, setParams] = useState({
    limit: 30,
    pageNumber: 1,
    q: '',
    sort: '0100',
    order: 'desc',
  });

  const {
    data: informationData,
    refetch,
    isFetching,
    remove,
  } = useQuery({
    queryKey: ['infomation', params],
    queryFn: async ({ signal }) => {
      const data = await get(API_PATH.INFORMATION + '/list', { ...params, signal });
      setInfoList(
        data?.information?.map((item) => (infoIDSelected?.includes(item.id) ? { ...item, pickup: true } : item))
      );
      return data;
    },
    refetchOnWindowFocus: false,
  });

  const { mutate: removeInfo } = useMutation({
    mutationFn: async () => await del(API_PATH.INFORMATION + '/' + infoIDSelected.toString()),
    retry: 3,
  });

  const handleRemoveInfo = useCallback(() => {
    setIsDeleting(true);
    removeInfo(_, {
      onSuccess: () => {
        refetch();
        setInfoIDSelected([]);
        setIsDeleting(false);
        setModalDelete(false);
        setModalAlert(true);
      },
      onError: (error) => {
        setIsDeleting(false);
        setModalDelete(false);
      },
    });
  });

  const toggleCheckbox = (id) => {
    const positionOfItemID = infoIDSelected.indexOf(id);
    setInfoIDSelected((prev) => {
      const newInfoIDSelected = [...prev];
      if (positionOfItemID > -1) {
        newInfoIDSelected.splice(positionOfItemID, 1);
        return newInfoIDSelected;
      }
      return [...newInfoIDSelected, id];
    });
  };

  useEffect(() => {
    setInfoList((prev) =>
      prev.map((item) => (infoIDSelected.includes(item.id) ? { ...item, pickup: true } : { ...item, pickup: false }))
    );
  }, [setInfoList, infoIDSelected]);

  const onCreate = () => {
    history.push('information/create');
  };

  const infoDetail = (id) => {
    history.push(`/information/details/${id}`);
  };

  const handleSort = (item) => {
    if (informationData?.pageOut?.totalCount === 0) return;
    setTableHeadData((prev) =>
      prev.map((tableHead) =>
        tableHead.sort === item.sort ? { ...tableHead, order: !tableHead.order } : { ...tableHead, order: false }
      )
    );

    setParams({
      ...params,
      sort: item.sort,
      pageNumber: 1,
      order: !item.order ? 'asc' : 'desc',
    });
  };

  const handleConvertStatus = useCallback(
    (status) => {
      if (isNaN(status)) return status;

      switch (status) {
        case '1':
          return t('pages_Infomation_InformationCreate.public');
        case '2':
          return t('pages_Infomation_InformationCreate.private');
        default:
          return console.log('invalid information status!');
      }
    },
    [t]
  );

  const handleConvertDivision = useCallback(
    (division) => {
      if (isNaN(division)) return division;

      switch (division) {
        case '1':
          return t('pages_Infomation_InformationCreate.important');
        case '2':
          return t('pages_Infomation_InformationCreate.generally');
        default:
          return console.log('invalid division!');
      }
    },
    [t]
  );

  useEffect(() => remove, [remove]);

  return (
    <View style={styles.container}>
      <View style={styles.wrapperStickyHeader}>
        <View style={styles.header}>
          <HeaderSearch placeholder={t('pages_Infomation.placeholder')} params={params} setParams={setParams} />
        </View>
        <View style={[styles.wrapperHeader, styles.wrapperStickyTitle]}>
          <View style={styles.wrapperHeaderLeft}>
            <Text style={styles.textHeader}>{t('pages_Infomation.information')}</Text>
            <Text style={styles.textHeader}>
              {`${!informationData?.pageOut?.totalCount || isFetching ? 0 : informationData?.pageOut?.totalCount}`}{' '}
              {t('pages_Infomation.total')}
            </Text>
          </View>
          <View style={[styles.wrapperItemHead, { paddingBottom: 15 }]}>
            <View style={{ marginRight: 30 }}>
              <LinearGradient colors={['#32A5F7', '#2D81F3']} style={{ borderRadius: 5 }}>
                <JButton
                  text={t('pages_Infomation.addInfomation')}
                  width={200}
                  hasIcon
                  linkIcon={'iplus.svg'}
                  widthIcon={35}
                  heightIcon={22}
                  textColor={'#fff'}
                  onPressAction={() => onCreate()}
                />
              </LinearGradient>
            </View>
            <View>
              {infoIDSelected?.length > 0 ? (
                <TouchableOpacity onPress={() => setModalDelete(!modalDelete)}>
                  <Image style={styles.iconDeleted} source={require('@assets/icons/iconDeletedActive.svg')} />
                </TouchableOpacity>
              ) : (
                <Image style={styles.iconDeleted} source={require('@assets/icons/iconDeleted.svg')} />
              )}
            </View>
          </View>
        </View>
      </View>

      <Grid>
        {infoList?.length > 0 && !isFetching && (
          <Row style={[styles.headTable, styles.wrapperStickyTableHead, styles.sticky130]}>
            {tableHeadData.map((item) => (
              <Col size={item.size} style={styles.wrapperItem} key={item.title}>
                <Text numberOfLines={3} style={styles.textHead}>
                  {item.title}
                </Text>
                {item.hasIcon && (
                  <Image
                    onClick={() => handleSort(item)}
                    style={[styles.iconDropdown, item?.order && styles.iconTableHeadASC]}
                    source={require('@assets/icons/iconDropdown.svg')}
                  />
                )}
              </Col>
            ))}
          </Row>
        )}
        <ScrollView
          showsVerticalScrollIndicator={false}
          style={{ backgroundColor: isFetching || infoList?.length < 1 ? 'transparent' : BG_TABLE_DEFAULT }}
        >
          {isFetching ? (
            <Loading style={{ height: 'calc(100vh - 187px)' }} />
          ) : infoList?.length > 0 ? (
            infoList?.map((item, index, { length }) => {
              return (
                <View key={index} style={styles.wrapperBody}>
                  <Pressable style={({ hovered }) => [hovered && styles.buttonHovered]} key={index}>
                    <TouchableOpacity onPress={() => infoDetail(item.id)}>
                      <Row size={25} style={[styles.row, { borderBottomWidth: index + 1 === length ? 0 : 1 }]}>
                        <Col style={styles.wrapperItem} size={2}>
                          <Text style={styles.defaultTextColor}>{formatDisplayId(item.id)}</Text>
                        </Col>
                        <Col style={styles.wrapperItem} size={1}>
                          <View>
                            <Image style={styles.iconWarning} source={item?.image} />
                          </View>
                        </Col>
                        <Col style={styles.wrapperItem} size={1}>
                          <View style={styles.wrapperItem}>
                            <Text style={styles.defaultTextColor}>{handleConvertDivision(item.division)}</Text>
                          </View>
                        </Col>
                        <Col style={styles.wrapperItem} size={3}>
                          <Text numberOfLines={2} style={{ textAlign: 'center' }}>
                            {item.title}
                          </Text>
                        </Col>
                        <Col style={styles.wrapperItem} size={3}>
                          <View style={styles.wrapperItem}>
                            <Text style={styles.defaultTextColor}>{item.category}</Text>
                          </View>
                        </Col>
                        <Col size={2} style={styles.wrapperItem}>
                          <View>
                            <Text style={styles.defaultTextColor}>
                              {item.publishedAt ? formatDateTime(item.publishedAt) : ''}
                            </Text>
                          </View>
                        </Col>
                        <Col size={3} style={styles.wrapperItem}>
                          <View>
                            <Text style={styles.defaultTextColor}>{handleConvertStatus(item.statuses)}</Text>
                          </View>
                        </Col>
                        <Col style={styles.wrapperItem} size={1}>
                          <JCheckbox
                            checkValueTable={true}
                            onValueChange={() => toggleCheckbox(item.id)}
                            value={item.pickup}
                          />
                        </Col>
                      </Row>
                    </TouchableOpacity>
                  </Pressable>
                </View>
              );
            })
          ) : (
            <Center w="100%" h={Dimensions.get('window').height - 187}>
              <Text>{t('pages_Infomation.haveNoItem')}</Text>
            </Center>
          )}
          <View>
            {params.limit < informationData?.pageOut?.totalCount && !isFetching && (
              <Pagination
                count={Math.ceil(informationData?.pageOut?.totalCount / params.limit)}
                params={params}
                setParams={setParams}
              />
            )}
          </View>
        </ScrollView>
      </Grid>

      <ModalAlert
        isModalVisible={modalAlert}
        setModalVisible={setModalAlert}
        icon="success-red.svg"
        iconWidth={20}
        iconHeight={20}
        modalText={t('pages_Infomation.modalAlertText')}
      />

      <ModalDelete
        iconBtnLeft="close-white.svg"
        iconBtnRight="delete-white.svg"
        heightIconBtnRight={21}
        widthIconBtnRight={21}
        marginBottomIconRight={8}
        textBtnRight={t('commonText.buttonDeleteFile')}
        textBtnLeft={t('commonText.cancelButton')}
        modalDelete={modalDelete}
        setModalDelete={setModalDelete}
        modalAlert={modalAlert}
        setModalAlert={setModalAlert}
        icon="iconDeletedActive.svg"
        textHeader={t('pages_Infomation.modalDeleteTextHeader')}
        textHeaderColor="#191919"
        numberItemDelete={infoIDSelected?.length ?? null}
        textContent={[t('pages_Infomation.ModalDeleteTextContent1'), t('pages_Infomation.ModalDeleteTextContent2')]}
        colorTextTitle="#5A5A5A"
        pv={30}
        iconBody="warning.svg"
        func={handleRemoveInfo}
        isDeletingFile={isDeleting}
      />
    </View>
  );
};

export default withNamespaces()(Infomation);

import React, { useState, useCallback, useEffect } from 'react';
import { View, Text } from 'react-native';
import * as DocumentPicker from 'expo-document-picker';
import { useQuery, useMutation } from '@tanstack/react-query';
import { Center } from 'native-base';
import { withNamespaces } from 'react-i18next';

import FileManagerLayout from '../partials/FileManagerLayout';
import styles from '../partials/styles';
import TableBody from '../partials/TableBody';
import { get, postMulti, put } from '@utils/RequestLevica';
import { API_PATH } from '@helper/constant';
import Loading from '@components/common/Loading';
import ModalAlert from '@components/Modals/ModalAlert';

const MerchantTerms = ({ t }) => {
  const [depositedListData, setDepositedListData] = useState([]);
  const [documentActive, setDocumentActive] = useState(null);
  const [deleteDocument, setDeleteDocument] = useState([]);
  const [file, setFile] = useState(null);
  const [modalUploadFileError, setModalUploadFileError] = useState(false);
  const [messageModalUploadFileError, setMessageModalUploadFileError] = useState('');
  const [isAddingFile, setIsAddingFile] = useState(false);
  const [modalAddPdfFile, setModalAddPdfFile] = useState(false);

  const [params, setParams] = useState({
    category: '1',
    q: '',
    sort: 'desc',
  });

  const { refetch, isFetching, remove } = useQuery(
    ['MerchantTerms', { params }],
    async ({ signal }) => {
      const data = await get(API_PATH.CONTRACT_FILE, { ...params, signal });
      setDocumentActive(data?.find((file) => file.isUse));
      setDepositedListData(data);
      return data;
    },
    {
      refetchOnWindowFocus: false,
    }
  );

  const { mutate: updateDocsUsed } = useMutation({
    mutationFn: async (id) => await put(`${API_PATH.CONTRACT_FILE}/${id}`, JSON.stringify({ isUse: true })),
    retry: 3,
  });

  const { mutate: handleUploadFile } = useMutation({
    mutationFn: async (file) =>
      postMulti(API_PATH.CONTRACT_FILE, {
        contractFile: file,
        title: file?.name,
        category: '1',
      }),
    retry: 3,
  });

  const toggleCheckbox = (item, index) => {
    const newList = [...depositedListData];
    newList?.splice(index, 1, { ...item, pickup: !item.pickup });

    const deleteItem = newList.filter((item) => item.pickup === true);
    setDeleteDocument(deleteItem);
    setDepositedListData(newList);
  };

  const handleUpdateDocsUsed = (file) => {
    updateDocsUsed(file.id, {
      onSuccess: () => {
        setDocumentActive(file);
        setDeleteDocument((prev) => prev.filter((prevFile) => prevFile.id != file.id));
        setDepositedListData((prev) =>
          prev.map((prevFile) => (prevFile.id === file.id ? { ...prevFile, pickup: false } : prevFile))
        );
      },
      onError: (error) => console.log(error),
    });
  };

  const validateFileSize = (file) => {
    // unit: MB
    const PDF_FILE_SIZE_LIMIT = 50;
    return file.size <= PDF_FILE_SIZE_LIMIT * 1024 * 1024;
  };

  const validateFileType = (file) => file.mimeType === 'application/pdf' || file.type === 'application/pdf';

  const uploadFile = useCallback(
    (onPickedFile) => {
      if (!file) return;
      setIsAddingFile(true);
      handleUploadFile(file, {
        onSuccess: () => {
          setIsAddingFile(false);
          refetch();
          setModalAddPdfFile(false);
        },
        onError: (err) => {
          setIsAddingFile(false);
          setModalAddPdfFile(false);
        },
      });
    },
    [file, refetch]
  );

  const handleFileValidation = (file) => {
    if (!validateFileSize(file)) {
      setModalAddPdfFile(false);
      setModalUploadFileError(true);
      setMessageModalUploadFileError(t('pages_FileManager_partials_ModalAddPdfFile.fileSizeError'));
      return false;
    }

    if (!validateFileType(file)) {
      setModalAddPdfFile(false);
      setModalUploadFileError(true);
      setMessageModalUploadFileError(t('pages_FileManager_partials_ModalAddPdfFile.fileTypeError'));
      return false;
    }

    return true;
  };

  const pickPdfFile = async (onPickFileSucc = () => {}, onPickFileError = () => {}, onPickedFile = () => {}) => {
    let result = await DocumentPicker.getDocumentAsync({
      type: 'application/pdf',
    });

    if (!handleFileValidation(result)) {
      onPickFileError();
      return false;
    }

    if (result.type === 'success') {
      onPickFileSucc();
      setFile(result.file);
    }
  };

  const pickFileByDrag = (file) => {
    if (!handleFileValidation(file[0])) return false;
    setFile(file[0]);
  };

  useEffect(() => {
    if (file) {
      uploadFile();
    }
  }, [file]);

  useEffect(() => remove, [remove]);

  return (
    <FileManagerLayout
      modalAddPdfFile={modalAddPdfFile}
      setModalAddPdfFile={setModalAddPdfFile}
      params={params}
      setParams={setParams}
      deleteDocument={deleteDocument}
      pickPdfFile={pickPdfFile}
      refetchFileList={refetch}
      pickFileByDrag={pickFileByDrag}
      category="1"
      isAddingFile={isAddingFile}
      isHideTableHead={depositedListData.length < 1 || isFetching}
    >
      <View style={styles.container}>
        {isFetching ? (
          <Loading style={{ height: '100%', background: 'transparent' }} />
        ) : depositedListData.length > 0 ? (
          depositedListData.map((item, index) => (
            <TableBody
              key={index}
              item={item}
              index={index}
              setDocumentActive={setDocumentActive}
              documentActive={documentActive}
              toggleCheckbox={toggleCheckbox}
              radioClick={handleUpdateDocsUsed}
              isDisableCheckbox={item?.id != documentActive?.id}
            />
          ))
        ) : (
          <Center w="100%" h="100%">
            <Text style={{ color: '#ffff' }}>{t('pages_FileManager.haveNoFile')}</Text>
          </Center>
        )}
      </View>
      <ModalAlert
        isModalVisible={modalUploadFileError}
        setModalVisible={setModalUploadFileError}
        icon="stop.svg"
        iconWidth={20}
        iconHeight={16}
        modalText={messageModalUploadFileError}
      />
    </FileManagerLayout>
  );
};

export default withNamespaces()(MerchantTerms);

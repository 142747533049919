import React from 'react';
import { Col, Grid } from 'react-native-easy-grid';
import { withNamespaces } from 'react-i18next';

import AccountInput from '@components/AccountComponents/AccountInput';
import styles from '../styles';
import { JText as Text } from '@components/common/JText';
import AccountInputPhone from '@components/AccountComponents/AccountInputPhone';

const ContactForm = ({ control, disable, t }) => {
  //email  to landlineNumber
  return (
    <>
      <Grid style={styles.marginBottom20}>
        <Col size={4}>
          <Text style={styles.colorWhite}>{t('pages_AccountShop_CreateAccount.contactForm')}</Text>
        </Col>
        <Col size={8}></Col>
      </Grid>

      <AccountInput
        disable={disable}
        control={control}
        name="email"
        placeholder={t('pages_AccountShop_CreateAccount.emailPlaceholder')}
        isRequired={true}
        labelText={t('pages_AccountShop_CreateAccount.email')}
        subfix="iconEdit.svg"
        ml={12}
      />
      <AccountInputPhone
        disable={disable}
        labelText={t('pages_AccountShop_CreateAccount.mobileNumber')}
        isRequired={true}
        name="phone"
        control={control}
      />
      <AccountInputPhone
        disable={disable}
        labelText={t('pages_AccountShop_CreateAccount.landlineNumber')}
        isRequired={true}
        name="landlineNumber"
        control={control}
      />
    </>
  );
};

export default withNamespaces()(ContactForm);

import * as Yup from 'yup';
import { isValidPhoneNumber, parsePhoneNumber } from 'react-phone-number-input';
import { t } from 'i18next';

export const defaultValues = {
  id: '',
  leviasChainAddress: '',
  issuerUserEmail: '',
  name: '',
  icon: '',
  tradeName: '',
  corporationType: '',
  marketInfor: '',
  corporateNumber: '',
  establishedAt: '',
  capital: '',
  industry: '',
  businessContent: '',
  homePageUrl: '',
  numberOfEmployees: '',
  postCode: '',
  prefecture: '',
  city: '',
  address: '',
  building: '',
  floor: '',
  room: '',
  email: '',
  phone: '',
  landlineNumber: '',
  issuerUserLastName: '',
  issuerUserLastNameKana: '',
  issuerUserFirstName: '',
  issuerUserFirstNameKana: '',
  issuerUserTitle: '',
  traderDepartment: '',
  traderDirector: '',
  traderLastName: '',
  traderLastNameKana: '',
  traderFirstName: '',
  traderFirstNameKana: '',
  traderPhoneNumber: '',
  traderEmail: '',
  traderPostCode: '',
  traderPrefecture: '',
  traderCity: '',
  traderAddress: '',
  traderBuilding: '',
  traderFloor: '',
  traderRoom: '',
};

// export const defaultValues = {
//   id: '',
//   leviasChainAddress: '',
//   issuerUserEmail: 'demo@vm.vn',
//   name: 'demo123',
//   icon: '',
//   tradeName: 'tradeName',
//   corporationType: '1',
//   marketInfor: 'marketInfor',
//   corporateNumber: '',
//   establishedAt: '2022-12-12',
//   capital: 1234567,
//   industry: '2',
//   businessContent: 'businessContent',
//   homePageUrl: '',
//   numberOfEmployees: '',
//   postCode: '0600006',
//   prefecture: 'prefecture',
//   city: 'city',
//   address: 'address',
//   building: 'building',
//   floor: '',
//   room: '',
//   email: 'email@mail.vn',
//   phone: '+840972947822',
//   landlineNumber: '+840972947822',
//   issuerUserLastName: 'ru8weur89wr89wer',
//   issuerUserLastNameKana: 'カナ',
//   issuerUserFirstName: 'ru8weur89wr89wer',
//   issuerUserFirstNameKana: 'カナ',
//   issuerUserTitle: 'ru8weur89wr89wer',
//   traderDepartment: 'traderDepartment',
//   traderDirector: 'traderDirector',
//   traderLastName: 'traderLastName',
//   traderLastNameKana: 'カナ',
//   traderFirstName: 'traderFirstName',
//   traderFirstNameKana: 'カナ',
//   traderPhoneNumber: '+840972947822',
//   traderEmail: 'email@mail.vn',
//   traderPostCode: '0600006',
//   traderPrefecture: '',
//   traderCity: '4',
//   traderAddress: '4',
//   traderBuilding: 132,
//   traderFloor: '',
//   traderRoom: '',
// };

const urlValidate = /[a-z0-9-\.]+\.[a-z]{2,4}\/?([^\s<>\#%"\,\{\}\\|\\\^\[\]`]+)?$/;
const kanaValidate = /^[\u30A0-\u30FFー]+$/g;

export const validationSchema = () => {
  const validate = Yup.object().shape({
    //1 issuerUserEmail to numberOfEmployees = FromIndividual
    id: Yup.string().trim(),
    leviasChainAddress: Yup.string().trim(),
    issuerUserEmail: Yup.string()
      .trim()
      .email(t('errorMessages.TXT_EMAIL_REQUIRED'))
      .max(32, t('errorMessages.TXT_MAX_32'))
      .required(t('errorMessages.fieldRequired', { fieldName: t('pages_AccountCompany_CreateAccount.emailLogin') })),
    name: Yup.string()
      .trim()
      .max(32, t('errorMessages.TXT_MAX_32'))
      .required(t('errorMessages.fieldRequired', { fieldName: t('pages_AccountCompany_CreateAccount.accountName') })),

    tradeName: Yup.string()
      .trim()
      .max(32, t('errorMessages.TXT_MAX_32'))
      .required(t('errorMessages.fieldRequired', { fieldName: t('pages_AccountCompany_CreateAccount.companyName') })),
    corporationType: Yup.string()
      .trim()
      .max(32, t('errorMessages.TXT_MAX_32'))
      .required(
        t('errorMessages.fieldRequired', { fieldName: t('pages_AccountCompany_CreateAccount.corporationType') })
      ), // pending QA
    marketInfor: Yup.string()
      .trim()
      .max(128, t('errorMessages.TXT_MAX_128'))
      .required(t('errorMessages.fieldRequired', { fieldName: t('pages_AccountCompany_CreateAccount.marketInfo') })),
    corporateNumber: Yup.string().trim().max(32, t('errorMessages.TXT_MAX_32')),
    establishedAt: Yup.string()
      .trim()
      .required(t('errorMessages.fieldRequired', { fieldName: t('pages_AccountCompany_CreateAccount.birthday') })),
    capital: Yup.string()
      .trim()
      // .matches(/^[0-9]+$/, t('errorMessages.TXT_NUMERIC'))
      .max(42, t('errorMessages.TXT_MAX_32'))
      .required(t('errorMessages.fieldRequired', { fieldName: t('pages_AccountCompany_CreateAccount.capital') })),
    industry: Yup.string()
      .trim()
      .max(32, t('errorMessages.TXT_MAX_32'))
      .required(t('errorMessages.fieldRequired', { fieldName: t('pages_AccountCompany_CreateAccount.industry') })), // pending QA
    businessContent: Yup.string()
      .trim()
      .required(
        t('errorMessages.fieldRequired', { fieldName: t('pages_AccountCompany_CreateAccount.businessContent') })
      )
      .test(
        'length validation',
        t('errorMessages.TXT_MAX_128'),
        (value) => value?.replace(/\r\n/gm, '____')?.replace(/\n/gm, '____')?.length < 129
      ),
    homePageUrl: Yup.string()
      .trim()
      .test('compare', t('errorMessages.TXT_URL_WRONG'), (url) => (url ? urlValidate.test(url) : true)),
    numberOfEmployees: Yup.string()
      .trim()
      .nullable()
      .max(32, t('errorMessages.TXT_MAX_32'))
      .test('compare ', t('errorMessages.TXT_NUMERIC'), (numberOfEmployee) => {
        if (numberOfEmployee) {
          return /^[0-9]+$/.test(numberOfEmployee);
        } else {
          return true;
        }
      }),

    //2 postCode to room = LocationForm
    postCode: Yup.string()
      .trim()
      .required(t('errorMessages.fieldRequired', { fieldName: t('pages_AccountCompany_CreateAccount.zipCode') }))
      .matches(/^[0-9]+$/, t('errorMessages.TXT_POSTAL_CODE'))
      .min(7, t('errorMessages.TXT_POSTAL_CODE'))
      .max(7, t('errorMessages.TXT_POSTAL_CODE')),
    prefecture: Yup.string()
      .trim()
      .max(128, t('errorMessages.TXT_MAX_128'))
      .required(
        t('errorMessages.fieldRequired', { fieldName: t('pages_AccountCompany_CreateAccount.prefectureCode') })
      ),
    city: Yup.string()
      .trim()
      .max(128, t('errorMessages.TXT_MAX_128'))
      .required(t('errorMessages.fieldRequired', { fieldName: t('pages_AccountCompany_CreateAccount.municipality') })),
    address: Yup.string()
      .trim()
      .max(128, t('errorMessages.TXT_MAX_128'))
      .required(t('errorMessages.fieldRequired', { fieldName: t('pages_AccountCompany_CreateAccount.address') })),
    building: Yup.string().trim().max(128, t('errorMessages.TXT_MAX_128')),
    floor: Yup.string().trim().max(32, t('errorMessages.TXT_MAX_32')),
    room: Yup.string().trim().max(32, t('errorMessages.TXT_MAX_32')),

    //3 email to issuerUserTitle = ContactForm
    email: Yup.string()
      .trim()
      .email(t('errorMessages.TXT_EMAIL_REQUIRED'))
      .max(32, t('errorMessages.TXT_MAX_32'))
      .required(t('errorMessages.fieldRequired', { fieldName: t('pages_AccountCompany_CreateAccount.email') })),
    phone: Yup.string()
      .trim()
      .nullable()
      .test('compare ', t('errorMessages.TXT_JP_PHONE'), (phone) => {
        if (phone) {
          if (phone && parsePhoneNumber(phone)?.nationalNumber) {
            return isValidPhoneNumber(phone);
          } else {
            return true;
          }
        } else {
          return true;
        }
      })
      .required(t('errorMessages.fieldRequired', { fieldName: t('pages_AccountCompany_CreateAccount.mobileNumber') })),
    landlineNumber: Yup.string()
      .trim()
      .nullable()
      .test('compare ', t('errorMessages.TXT_JP_PHONE'), (phone) => {
        if (phone) {
          if (phone && parsePhoneNumber(phone)?.nationalNumber) {
            return isValidPhoneNumber(phone);
          } else {
            return true;
          }
        } else {
          return true;
        }
      })
      .required(
        t('errorMessages.fieldRequired', { fieldName: t('pages_AccountCompany_CreateAccount.landlineNumber') })
      ),

    issuerUserLastName: Yup.string()
      .trim()
      .max(32, t('errorMessages.TXT_MAX_32'))
      .required(t('errorMessages.fieldRequired', { fieldName: t('pages_AccountCompany_CreateAccount.lastName') })),
    issuerUserLastNameKana: Yup.string()
      .trim()
      .max(32, t('errorMessages.TXT_MAX_32'))
      .required(
        t('errorMessages.fieldRequired', { fieldName: t('pages_AccountCompany_CreateAccount.lastNameKatakana') })
      )
      .matches(kanaValidate, t('errorMessages.TEXT_ONLY_KANA')),
    issuerUserFirstName: Yup.string()
      .trim()
      .max(32, t('errorMessages.TXT_MAX_32'))
      .required(t('errorMessages.fieldRequired', { fieldName: t('pages_AccountCompany_CreateAccount.firstName') })),
    issuerUserFirstNameKana: Yup.string()
      .trim()
      .max(32, t('errorMessages.TXT_MAX_32'))
      .required(
        t('errorMessages.fieldRequired', { fieldName: t('pages_AccountCompany_CreateAccount.firstNameKatakana') })
      )
      .matches(kanaValidate, t('errorMessages.TEXT_ONLY_KANA')),
    issuerUserTitle: Yup.string()
      .trim()
      .max(32, t('errorMessages.TXT_MAX_32'))
      .required(t('errorMessages.fieldRequired', { fieldName: t('pages_AccountCompany_CreateAccount.title') })),

    //4 traderDepartment to traderEmail = TransactionPersonForm
    traderDepartment: Yup.string()
      .trim()
      .max(32, t('errorMessages.TXT_MAX_32'))
      .required(
        t('errorMessages.fieldRequired', { fieldName: t('pages_AccountCompany_CreateAccount.transactionDepartment') })
      ),
    traderDirector: Yup.string()
      .trim()
      .max(32, t('errorMessages.TXT_MAX_32'))
      .required(
        t('errorMessages.fieldRequired', { fieldName: t('pages_AccountCompany_CreateAccount.transactionTitle') })
      ),
    traderLastName: Yup.string()
      .trim()
      .max(32, t('errorMessages.TXT_MAX_32'))
      .required(
        t('errorMessages.fieldRequired', { fieldName: t('pages_AccountCompany_CreateAccount.transactionLastName') })
      ),
    traderLastNameKana: Yup.string()
      .trim()
      .max(32, t('errorMessages.TXT_MAX_32'))
      .required(
        t('errorMessages.fieldRequired', { fieldName: t('pages_AccountCompany_CreateAccount.transactionLastNameKana') })
      )
      .matches(kanaValidate, t('errorMessages.TEXT_ONLY_KANA')),
    traderFirstName: Yup.string()
      .trim()
      .max(32, t('errorMessages.TXT_MAX_32'))
      .required(
        t('errorMessages.fieldRequired', { fieldName: t('pages_AccountCompany_CreateAccount.transactionFirstName') })
      ),
    traderFirstNameKana: Yup.string()
      .trim()
      .max(32, t('errorMessages.TXT_MAX_32'))
      .required(
        t('errorMessages.fieldRequired', {
          fieldName: t('pages_AccountCompany_CreateAccount.transactionFirstNameKanaPlaceholder'),
        })
      )
      .matches(kanaValidate, t('errorMessages.TEXT_ONLY_KANA')),
    traderPhoneNumber: Yup.string()
      .trim()
      .nullable()
      .test('compare ', t('errorMessages.TXT_JP_PHONE'), (phone) => {
        if (phone) {
          if (phone && parsePhoneNumber(phone)?.nationalNumber) {
            return isValidPhoneNumber(phone);
          } else {
            return true;
          }
        } else {
          return true;
        }
      })
      .required(
        t('errorMessages.fieldRequired', {
          fieldName: t('pages_AccountCompany_CreateAccount.transactionPhoneNumber'),
        })
      ),
    traderEmail: Yup.string()
      .trim()
      .email(t('errorMessages.TXT_EMAIL_REQUIRED'))
      .max(32, t('errorMessages.TXT_MAX_32'))
      .required(
        t('errorMessages.fieldRequired', {
          fieldName: t('pages_AccountCompany_CreateAccount.transactionEmail'),
        })
      ),

    //6 traderPostCode to traderRoom=TransactionContactForm
    traderPostCode: Yup.string()
      .trim()
      .required(t('errorMessages.fieldRequired', { fieldName: t('pages_AccountCompany_CreateAccount.zipCode') }))
      .matches(/^[0-9]+$/, t('errorMessages.TXT_POSTAL_CODE'))
      .min(7, t('errorMessages.TXT_POSTAL_CODE'))
      .max(7, t('errorMessages.TXT_POSTAL_CODE')),
    traderPrefecture: Yup.string()
      .trim()
      .max(128, t('errorMessages.TXT_MAX_128'))
      .required(
        t('errorMessages.fieldRequired', {
          fieldName: t('pages_AccountCompany_CreateAccount.transactionPrefectures'),
        })
      ),
    traderCity: Yup.string()
      .trim()
      .max(128, t('errorMessages.TXT_MAX_128'))
      .required(
        t('errorMessages.fieldRequired', {
          fieldName: t('pages_AccountCompany_CreateAccount.transactionMunicipality'),
        })
      ),
    traderAddress: Yup.string()
      .trim()
      .max(128, t('errorMessages.TXT_MAX_128'))
      .required(
        t('errorMessages.fieldRequired', {
          fieldName: t('pages_AccountCompany_CreateAccount.transactionAddress'),
        })
      ),
    traderBuilding: Yup.string()
      .trim()
      .max(128, t('errorMessages.TXT_MAX_128')),
    traderFloor: Yup.string().trim().max(32, t('errorMessages.TXT_MAX_32')),
    traderRoom: Yup.string().trim().max(32, t('errorMessages.TXT_MAX_32')),
  });

  return validate;
};

import React, { useEffect, useState } from 'react';
import { View, Image, ScrollView, Pressable, Dimensions } from 'react-native';
import { Col, Grid } from 'react-native-easy-grid';
import { withNamespaces } from 'react-i18next';
import { Center } from 'native-base';
import { useQuery } from '@tanstack/react-query';
import { over } from 'stompjs';
import SockJS from 'sockjs-client';

import { formatDateTime, formatNumber } from '@helper/formatTypes';
import HeaderSearch from '@components/Headers/HeaderSearch';
import Pagination from '@components/common/Pagination';
import styles from '../style';
import HeaderPayManagement from '@components/PayManagement';
import { JText as Text } from '@components/common/JText';
import JCheckbox from '@components/common/JCheckbox';
import { baseURL, get } from '@utils/RequestLevica';
import { API_PATH } from '@helper/constant';
import Loading from '@components/common/Loading';
import { BG_FORM_DEFAULT } from '@assets/style/styleDefault';

let stompClient = null;
const PaymentConfirmed = ({ t }) => {
  const url = baseURL + 'ws';
  const [paymentListData, setPaymentListData] = useState([]);
  const [listPaymentChecked, setListPaymentChecked] = useState([]);
  const [paymentProcessedAll, setPaymentProcessedAll] = useState(false);
  const [tableHeadData, setTableHeadData] = useState(() => [
    {
      title: 'Symbol / I.No',
      hasIcon: true,
      sort: '0100',
      order: false,
      size: 2,
    },
    {
      title: t('pages_PaymentManager.paymentDueDate'),
      size: 2,
    },
    {
      title: t('pages_PaymentManager.userNameConfirmed'),
      size: 1,
    },
    {
      title: t('pages_PaymentManager.transferAmountOfMoney'),
      size: 2,
    },
    {
      title: t('pages_PaymentManager.bankCode'),
      size: 2,
    },
    {
      title: t('pages_PaymentManager.branchCode'),
      size: 2,
    },
    {
      title: t('pages_PaymentManager.accountType'),
      size: 1,
    },
    {
      title: t('pages_PaymentManager.transferAccountNumber'),
      size: 2,
    },
    {
      title: t('pages_PaymentManager_PaymentConfirmed.remittanceNameKana'),
      size: 2,
    },
    {
      title: t('pages_PaymentManager_PaymentConfirmed.paidCheckDate'),
      hasIcon: true,
      sort: '0300',
      order: false,
      size: 3,
    },
  ]);
  const [firstLoading, setFirstLoading] = useState(false);
  const [params, setParams] = useState({
    limit: 30,
    pageNumber: 1,
    startDate: '',
    endDate: '',
    q: '',
    sort: '0100',
    order: 'desc',
    type: '1',
  });

  const {
    data: PaymentConfirmed,
    refetch,
    isFetching,
    remove,
  } = useQuery(
    ['PaymentConfirmed', { ...params }],
    async ({ signal }) => {
      const data = await get(API_PATH.PAYMENT_CONFIRMED, { ...params, signal });
      setPaymentListData(data?.transactions);
      setFirstLoading(false);
      return data;
    },
    { refetchOnWindowFocus: false }
  );

  useEffect(() => {
    setFirstLoading(true);
  }, []);
  const handleSort = (item) => {
    setTableHeadData((prev) =>
      prev.map((tableHead) =>
        tableHead.sort === item.sort ? { ...tableHead, order: !tableHead.order } : { ...tableHead, order: false }
      )
    );

    setParams({
      ...params,
      sort: item.sort,
      pageNumber: 1,
      order: !item.order ? 'asc' : 'desc',
    });
  };

  const connect = () => {
    let Sock = new SockJS(url);
    stompClient = over(Sock);
    stompClient.connect({}, onConnected, onError);
  };

  const onConnected = () => {
    stompClient.subscribe('/paymentRefundChecked/public', onMessageReceived);
  };

  const onError = (err) => {
    connect();
    refetch();
  };

  const onMessageReceived = (payload) => {
    if (payload) {
      refetch();
    }
  };

  const selectOne = (item) => {
    if (stompClient) {
      stompClient.send(
        '/app/paymentRefundChecked',
        {},
        JSON.stringify({ listId: [item?.id], checked: item?.checkedAt != null ? false : true, isCheckedAll: null })
      );
    }
  };
  const selectAll = () => {
    if (stompClient) {
      stompClient.send(
        '/app/paymentRefundChecked',
        {},
        JSON.stringify({
          listId: [],
          checked: !PaymentConfirmed?.isCheckedAll,
          isCheckedAll: !PaymentConfirmed?.isCheckedAll,
        })
      );
    }
  };
  useEffect(() => {
    connect();
  }, []);
  useEffect(() => {
    const listPaymentChecked = paymentListData?.filter((item) => {
      return item.checkedAt != null;
    });
    setListPaymentChecked(listPaymentChecked);
  }, [paymentListData]);
  useEffect(() => remove, [remove]);
  return (
    <>
      <View
        style={[
          styles.container,
          {
            backgroundColor: isFetching || paymentListData.length < 1 ? '' : BG_FORM_DEFAULT,
          },
        ]}
      >
        <View style={styles.wrapperStickyHeader}>
          <View style={styles.headerSearch}>
            <HeaderSearch
              placeholder={t('pages_PaymentManager.placeholder')}
              setParams={setParams}
              params={params}
              onSearch={() => setPaymentProcessedAll(false)}
            />
          </View>

          <HeaderPayManagement
            totalAmount={!PaymentConfirmed?.totalAmount || isFetching ? 0 : PaymentConfirmed?.totalAmount}
            linkPaymentProcessed={API_PATH.PAYMENT_CONFIRMED}
            paymentProcessedAll={paymentProcessedAll}
            exportCSVLink={API_PATH.PAYMENT_CONFIRMED_EXPORT_CSV_ALL}
            csvName={t('components_PayManagement.paymentConfirmationList')}
            listPaymentChecked={listPaymentChecked}
            selectAll={selectAll}
            setParams={setParams}
            params={{ isCheckedAll: true, ...params }}
            active={'/payment/confirmed'}
            btn1={true}
            btn2={true}
            stompClient={stompClient}
            onlyCheckedAll={true}
            refetchQuery={refetch}
            datetimeTitle={t('components_PayManagement.timespace')}
            onDateFilter={() => setPaymentProcessedAll(false)}
            listChecked={listPaymentChecked}
            totalCount={PaymentConfirmed?.pageInfo?.totalCount ?? 0}
          />
          <View>
            {paymentListData.length > 0 && !false && (
              <Grid style={[styles.head, { gap: 20 }]} size={30}>
                {tableHeadData.map((item) => (
                  <Col size={item.size} style={styles.horizontal} key={item.title}>
                    <Text numberOfLines={3} style={styles.textHead}>
                      {item.title}
                    </Text>
                    {item.hasIcon && (
                      <Image
                        onClick={() => handleSort(item)}
                        style={item?.order ? styles.iconTableHeadDESC : styles.iconTableHeadASC}
                        source={require(`@assets/icons/iconDropdown.svg`)}
                      />
                    )}
                  </Col>
                ))}
              </Grid>
            )}
          </View>
        </View>
        <ScrollView showsVerticalScrollIndicator={false}>
          {firstLoading ? (
            <Loading style={{ height: 'calc(100vh - 245px)' }} />
          ) : paymentListData.length > 0 ? (
            paymentListData?.map((item, index, { length }) => {
              return (
                <Pressable key={index} style={({ hovered }) => [hovered && styles.rowHovered]}>
                  <Grid
                    style={[styles.headBody, { gap: 20 }, { borderBottomWidth: index + 1 === length ? 0 : 1 }]}
                    size={30}
                  >
                    <Col size={2}>
                      <View style={styles.horizontal}>
                        <Image style={styles.iconTableRow} source={item?.itemSymbol} />
                        <Text style={styles.text} numberOfLines={3}>
                          {item?.displayId}
                        </Text>
                      </View>
                    </Col>
                    <Col size={2}>
                      <Text style={styles.text} numberOfLines={3}>
                        {item?.displayTransactionAt}
                      </Text>
                    </Col>
                    <Col size={1}>
                      <Text style={styles.text} numberOfLines={3}>
                        {item?.merchantName}
                      </Text>
                    </Col>
                    <Col size={2}>
                      <Text style={styles.text} numberOfLines={3}>
                        <Text style={styles.text} numberOfLines={3}>
                          {item?.amount ? `¥ ${formatNumber(item.amount)}` : ''}
                        </Text>
                      </Text>
                    </Col>
                    <Col size={2}>
                      <Text style={styles.text} numberOfLines={3}>
                        {item?.bankCode}
                      </Text>
                    </Col>
                    <Col size={2}>
                      <Text style={styles.text} numberOfLines={3}>
                        {item?.branchCode}
                      </Text>
                    </Col>
                    <Col size={1}>
                      <Text style={styles.text} numberOfLines={3}>
                        {/* {converAccountType(item?.accountType)} */}
                        {item?.accountType}
                      </Text>
                    </Col>
                    <Col size={2}>
                      <Text style={styles.text} numberOfLines={3}>
                        {item?.accountNumber}
                      </Text>
                    </Col>
                    <Col size={2}>
                      <Text style={styles.text} numberOfLines={3}>
                        {item?.accountHolderName}
                      </Text>
                    </Col>
                    <Col size={3}>
                      <View style={styles.checkDate}>
                        <Text style={styles.text} numberOfLines={3}>
                          {item?.checkedAt && formatDateTime(item?.checkedAt)}
                        </Text>
                        <View style={styles.checkboxItem}>
                          <JCheckbox
                            checkValueTable={true}
                            onValueChange={() => selectOne(item, index)}
                            value={item?.checkedAt === null ? false : true}
                            // value={item.pickup ?? false}
                          />
                        </View>
                      </View>
                    </Col>
                  </Grid>
                </Pressable>
              );
            })
          ) : (
            <Center w="100%" h={Dimensions.get('window').height - 245}>
              <Text>{t('pages_PaymentManager.haveNoPaymentConfirmation')}</Text>
            </Center>
          )}
          <View>
            {params.limit < PaymentConfirmed?.pageInfo?.totalCount && !isFetching && (
              <Pagination
                count={Math.ceil(PaymentConfirmed?.pageInfo?.totalCount / params.limit)}
                params={params}
                setParams={setParams}
              />
            )}
          </View>
        </ScrollView>
      </View>
    </>
  );
};

export default withNamespaces()(PaymentConfirmed);

export const checkPermission = (permission, userRole) => {
  const roles = userRole?.features?.map((item) => item.code);
  if (permission === '*') {
    return true;
  } else {
    return roles?.includes(permission);
  }
};

// export const trimLongString = (name) => {
//   if (name && name.length < 13) return name;
//   const prefix = name?.match(/^\w{5}/)?.shift();
//   const postfix = name?.slice(-6);
//   return `${prefix}...${postfix}`;
// };

import React from 'react';
import { StyleSheet, View, Image } from 'react-native';
import { JText as Text } from '../common/JText';

const HeaderAccountSetting = ({ textHeader, avatar, close }) => {
  return (
    <View style={styles.headerAdmin}>
      <View style={styles.headerLeft}>
        {close && <Image style={styles.close} source={require(`../../assets/icons/iconClose.svg`)} />}
        <Text style={styles.textHeader}>{textHeader}</Text>
      </View>
      {avatar}
    </View>
  );
};

export default HeaderAccountSetting;

const styles = StyleSheet.create({
  headerAdmin: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: 80,
  },
  headerLeft: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  close: {
    height: 15,
    width: 15,
  },
  textHeader: {
    fontFamily: 'BebasNeue',
    color: '#838383',
    fontSize: '42px',
    fontWeight: '500',
    textTransform: 'uppercase',
    marginLeft: 10,
  },
});

import { StyleSheet } from 'react-native';
import {
  COLOR_TEXT_DEFAULT,
  FONT_WEIGHT_DEFAULT_HEADER,
  INPUT_BG,
  LINE_COLOR,
  TEXT_COLOR_GREY,
  TEXT_COLOR_RED,
} from '@assets/style/styleDefault';

const styles = StyleSheet.create({
  iconRequired: {
    color: TEXT_COLOR_RED,
    marginLeft: 6,
    fontSize: 16,
  },
  flexRow: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  content: {
    marginTop: 10,
    paddingLeft: '1rem',
    paddingRight: '1rem',
  },
  headerContent: {
    marginTop: 10,
    marginBottom: 20,
  },
  textHeaderContent: {
    fontSize: 18,
    color: COLOR_TEXT_DEFAULT,
    fontWeight: FONT_WEIGHT_DEFAULT_HEADER,
  },
  wrapperContent: {
    marginTop: 10,
  },
  registerForm: {
    marginTop: 25,
    paddingBottom: 25,
    borderBottomColor: '#5C5C5C',
    borderBottomWidth: 1,
    alignItems: 'center',
    width: '70%',
    minWidth: 800,
  },
  textLabel: {
    color: COLOR_TEXT_DEFAULT,
  },
  textPlaceholder: {
    color: COLOR_TEXT_DEFAULT,
  },
  notify: {
    marginTop: '3rem',
    marginBottom: '3rem',
  },
  groupButton: {
    flexDirection: 'row',
    alignItems: 'center',
    width: '50%',
    paddingBottom: 30,
  },
  select: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingLeft: '1.7rem',
    paddingRight: '0.7rem',
    position: 'relative',
    cursor: 'pointer',
  },
  selectList: {
    position: 'absolute',
    top: 40,
    minHeight: 80,
    width: '100%',
    backgroundColor: '#2d2b2b',
    borderRadius: 6,
    zIndex: 1,
    paddingLeft: 15,
    paddingRight: 15,
    border: '1px solid #2FCBA7',
  },
  listItem: {
    borderBottomColor: LINE_COLOR,
    borderBottomWidth: 1,
    paddingBottom: 10,
    marginTop: 10,
    cursor: 'pointer',
  },
  iconStatus: {
    height: 15,
    width: 15,
  },
  iconDown: {
    height: 10,
    width: 12,
  },
  textWalletAddress: {
    paddingLeft: 20,
    color: TEXT_COLOR_GREY,
  },
});

export default styles;

import React, { useState } from 'react';
import { useEffect } from 'react';
import { StyleSheet, TouchableWithoutFeedback, View, Image, Pressable } from 'react-native';
import { BG_COLOR, BG_HOVER, COLOR_TEXT_DEFAULT, TEXT_COLOR_WHITE } from '@assets/style/styleDefault';
import { JText as Text } from './JText';

const DropDownV2 = ({
  setValue,
  btnColor,
  textColor = COLOR_TEXT_DEFAULT,
  name = '',
  width = '100%',
  text,
  textLeft,
  linkIcon,
  widthIcon,
  heightIcon = 20,
  disabled,
  dataOption,
  handleToggle,
  changeColor,
  paddingLeft,
  textCenter,
  data = '',
}) => {
  const [menuHidden, setMenuHidden] = useState(false);
  const [newData, setNewData] = useState('');

  const handleOnPress = () => {
    setMenuHidden(!menuHidden);
  };

  const changeValue = (list) => {
    list.func && list.func();
    handleToggle && handleToggle(list.option);
    setNewData(list.option);
    setValue(name, list.value);
    setMenuHidden(false);
  };

  useEffect(() => {
    if (data) {
      setNewData(dataOption.find((item) => item.value === data).option);
    }
  }, [data]);

  return (
    <>
      <View style={styles.relative}>
        <TouchableWithoutFeedback onPress={handleOnPress} disabled={disabled}>
          <View
            style={[
              styles.button,
              disabled ? { backgroundColor: '#494A52' } : { backgroundColor: BG_COLOR },
              { width: width },
            ]}
          >
            {linkIcon && (
              <Image
                style={[styles.logoBtn, { width: widthIcon, height: heightIcon }]}
                source={require(`../../assets/icons/${linkIcon}`)}
              />
            )}
            {textLeft && <Text style={styles.textLeft}>{textLeft}</Text>}
            {textLeft ? (
              <Text style={[styles.text, { color: `${textColor}` }]}>{newData ? newData : text}</Text>
            ) : (
              <Text style={[styles.text, disabled ? { color: TEXT_COLOR_WHITE } : { color: `${textColor}` }]}>
                {newData ? newData : text}
              </Text>
            )}
            <Image
              style={[styles.iconDown]}
              source={
                !menuHidden
                  ? disabled
                    ? require(`../../assets/icons/iconDownDisabled.svg`)
                    : require(`../../assets/icons/iconDown.svg`)
                  : disabled
                  ? require(`../../assets/icons/iconDownDisabled.svg`)
                  : require(`../../assets/icons/iconTop.svg`)
              }
            />
          </View>
        </TouchableWithoutFeedback>
        {menuHidden && (
          <View style={[styles.listDropDown, { width: width, backgroundColor: BG_COLOR }]}>
            {dataOption?.map((list, index) => {
              return (
                <Pressable key={index} style={({ hovered }) => [hovered && styles.itemHovered]}>
                  <TouchableWithoutFeedback key={list.option} onPress={() => changeValue(list)}>
                    <View
                      style={[styles.itemOptions, textCenter && { justifyContent: 'center', alignItems: 'center' }]}
                      key={index}
                    >
                      {list.icon && (
                        <Image style={styles.imageList} source={require(`../../assets/icons/${list.icon}`)} />
                      )}
                      <Text
                        style={[
                          list.icon ? styles.textHasIcon : styles.textNoIcon,
                          list.color ? { color: list.color } : { color: '#f2f2f2' },
                          paddingLeft && { paddingLeft: paddingLeft },
                        ]}
                      >
                        {list.option}
                      </Text>
                      <Image
                        style={[styles.iconDowns]}
                        source={
                          disabled
                            ? require(`../../assets/icons/iconDownDisabled.svg`)
                            : require(`../../assets/icons/iconDown.svg`)
                        }
                      />
                    </View>
                  </TouchableWithoutFeedback>
                </Pressable>
              );
            })}
          </View>
        )}
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  isHide: {
    display: 'none',
  },
  relative: {
    position: 'relative',
  },
  button: {
    borderRadius: 5,
    height: 42,
    fontWeight: 400,
    lineHeight: 1.5,
    cursor: 'pointer',
    border: '1px solid transparent',
    fontSize: 14,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'initial',
    paddingLeft: 25,
    paddingRight: 20,
  },
  listDropDown: {
    backgroundColor: '#525252',
    position: 'absolute',
    top: 38,
    cursor: 'pointer',
    borderBottomEndRadius: 4,
    borderBottomStartRadius: 4,
  },
  itemOptions: {
    paddingLeft: '10%',
    paddingRight: '5%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  textHasIcon: {
    marginTop: 10,
    marginBottom: 10,
  },
  textNoIcon: {
    marginTop: 10,
    marginBottom: 10,
    fontFamily: 'NotoSansJP',
  },
  iconDown: {
    height: 6,
    width: 13,
  },
  text: {
    fontSize: 14,
    fontFamily: 'NotoSansJP',
    fontSize: '14px',
  },
  imageList: {
    height: 20,
    width: 20,
  },
  textLeft: {
    fontSize: 13,
    color: 'white',
    marginBottom: 20,
  },
  itemHovered: {
    backgroundColor: BG_HOVER,
  },
});

export default DropDownV2;

import { LinearGradient } from 'expo-linear-gradient';
import React from 'react';
import { View, Image, TouchableOpacity, StyleSheet } from 'react-native';
import Modal from 'react-native-modal';
import { useHistory } from 'react-router-dom';
import { withNamespaces } from 'react-i18next';

import JButton from '@components/common/JButton';
import { JText as Text } from '@components/common/JText';
import ModalBackDrop from './ModalBackDrop';

const ModalBack = (props) => {
  const { isModal, setIsModal, otherModal, linkPage, t } = props;
  const history = useHistory();
  const handleClose = () => {
    if (linkPage) {
      history.push(linkPage);
    } else {
      setIsModal(false);
    }
  };
  return (
    <View>
      <Modal
        animationIn="fadeIn"
        isVisible={isModal}
        style={{ alignItems: 'center' }}
        customBackdrop={<ModalBackDrop onPress={() => setIsModal(false)} />}
      >
        <View>
          <TouchableOpacity style={styles.modalClose} onPress={() => setIsModal(false)}>
            <Image style={styles.iconClose} source={require(`../../assets/icons/iconCloseModal.svg`)} />
            <Text style={styles.textClose}>{t('commonText.cancelButton')}</Text>
          </TouchableOpacity>
          <View style={styles.modalBack}>
            <View style={styles.headerModal}>
              <Text style={styles.textModal}>{t('components_Modals_ModalBack.modalBackText1')}</Text>
            </View>
            <View style={styles.contentModal}>
              <Image style={styles.iconModal} source={require(`../../assets/icons/iconDanger.svg`)} />
              <View style={styles.textContentModal}>
                <Text style={styles.textModal}>{t('components_Modals_ModalBack.modalBackText2')}</Text>
              </View>
            </View>
            <View style={styles.buttonModal}>
              <JButton
                text={t('components_Modals_ModalBack.modalBackButton1')}
                width={200}
                textColor="#fff"
                btnColor="#56575F"
                onPressAction={() => handleClose()}
              />
              <LinearGradient colors={['#54DBB2', '#04C48B']} style={{ borderRadius: 5 }}>
                <JButton
                  linkIcon="iconDownload.svg"
                  widthIcon={18}
                  heightIcon={18}
                  marginBottomIcon={3}
                  text={t('components_Modals_ModalBack.modalBackButton2')}
                  width={200}
                  textColor="#fff"
                  onPressAction={otherModal}
                />
              </LinearGradient>
            </View>
          </View>
        </View>
      </Modal>
    </View>
  );
};

const styles = StyleSheet.create({
  modalClose: {
    flexDirection: 'row',
    alignContent: 'center',
    marginBottom: 10,
    marginLeft: 20,
  },
  iconClose: {
    height: 15,
    width: 15,
    marginRight: 10,
  },
  textClose: {
    fontSize: 14,
    color: '#f2f2f2',
  },
  modalBack: {
    backgroundColor: '#fff',
    minHeight: 300,
    minWidth: 500,
    borderRadius: 6,
  },
  headerModal: {
    height: 50,
    border: '1px solid transparent',
    borderBottomColor: '#E0E0E0',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  textModal: {
    color: '#191919',
  },
  contentModal: {
    marginTop: 20,
    alignItems: 'center',
    flexDirection: 'column',
  },
  iconModal: {
    height: 35,
    width: 42,
  },
  textContentModal: {
    marginTop: 30,
    flexDirection: 'column',
    alignItems: 'center',
  },
  buttonModal: {
    height: 90,
    flexDirection: 'row',
    alignItems: 'end',
    justifyContent: 'space-around',
    marginBottom: 25,
  },
});

export default withNamespaces()(ModalBack);

import React, { useState } from 'react';
import { StyleSheet } from 'react-native';
import { Controller } from 'react-hook-form';
import PhoneInputWithCountry from 'react-phone-number-input/react-hook-form';

import { JText as Text } from './JText';
import { INPUT_BG, TEXT_COLOR_GREEN } from '@assets/style/styleDefault';

const PhoneInput = ({
  control,
  name,
  defaultCountry = 'JP',
  borderRadius = 20,
  paddingLeft = 20,
  textErrorPosition = 'absolute',
  disable,
}) => {
  const [isFocus, setFocus] = useState(false);

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <>
          <PhoneInputWithCountry
            value={value}
            name={name}
            defaultCountry={defaultCountry}
            onChange={onChange}
            international
            control={control}
            disabled={disable}
            style={{
              color: TEXT_COLOR_GREEN,
              border: '1px solid red',
              borderWidth: error?.message ? 1 : 0,
              justifyContent: 'center',
              backgroundColor: INPUT_BG,
              padding: 12,
              maxHeight: 22,
              borderRadius: borderRadius,
              width: paddingLeft === 20 ? '' : '100%',
              paddingLeft: paddingLeft,
              outline: isFocus && !error ? '2px solid #00BE85' : 'none',
              marginRight: 2,
            }}
            onFocus={() => setFocus(true)}
            onBlur={() => setFocus(false)}
          />
          <Text style={[styles.error, { position: textErrorPosition }]}>{error?.message}</Text>
        </>
      )}
    />
  );
};

export default PhoneInput;
const styles = StyleSheet.create({
  error: {
    color: 'red',
    fontSize: 12,
    position: 'absolute',
    bottom: -20,
    left: 0,
    transform: 'translateZ(0)',
  },
});

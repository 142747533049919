import React, { createElement, useRef, useState } from 'react';
import { View, StyleSheet } from 'react-native';
import { Controller } from 'react-hook-form';
import { flushSync } from 'react-dom';
import moment from 'moment';

import { JText as Text } from './JText';
import { COLOR_TEXT_DEFAULT, FONT_FAMILY_DEFAULT, FONT_SIZE_DEFAULT, INPUT_BG } from '@assets/style/styleDefault';

const Input = ({
  type,
  value,
  min,
  max,
  onChange,
  style,
  borderError,
  height = 40,
  disable,
  placeholder,
  onClick,
  inputRef,
}) => {
  return createElement('input', {
    type: type,
    min: min,
    max: max,
    value: value,
    onChange: onChange,
    disabled: !disable,
    placeholder,
    onClick,
    ref: inputRef,
    timezone: 'Asia/Tokyo',
    style: {
      fontFamily: FONT_FAMILY_DEFAULT,
      height: height,
      fontSize: FONT_SIZE_DEFAULT,
      color: !disable ? '#A5A5A5' : COLOR_TEXT_DEFAULT,
      padding: '0 20px',
      borderRadius: '4px',
      outlineStyle: 'none',
      borderWidth: borderError ? 1 : 0,
      borderColor: borderError,
      backgroundColor: INPUT_BG,
      colorScheme: 'dark',
      maxHeight: 42,
      width: 160,
      boxSizing: 'border-box',
      textAlign: type === 'text' && 'center',
      cursor: 'context-menu',
      caretColor: 'transparent',
      ...style,
    },
  });
};

const DateTimePicker = (props) => {
  const {
    borderError,
    name,
    control,
    type,
    min,
    max,
    style,
    dateTime = null,
    setDateTime = null,
    height,
    disable = true,
    placeholder,
  } = props;

  const [inputType, setInputType] = useState(placeholder ? 'text' : type);
  const inputRef = useRef();

  const transfromTextToTargetType = () => {
    flushSync(setInputType(type));
    type === 'date' && inputRef.current.showPicker();
  };
  return (
    <View style={styles.container}>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <>
            <Input
              disable={disable}
              onChange={(e) => {
                setDateTime ? setDateTime(e.target.value) : null;
                onChange(e);
              }}
              value={value}
              type={inputType}
              min={min ? min : null}
              max={max ? max : null}
              style={style}
              borderError={error?.message ? 'red' : ''}
              height={height}
              placeholder={placeholder}
              onClick={transfromTextToTargetType}
              inputRef={inputRef}
            />
            <Text style={styles.error}>{error?.message}</Text>
          </>
        )}
      />
    </View>
  );
};

export default DateTimePicker;

const styles = StyleSheet.create({
  container: {
    position: 'relative',
  },
  error: {
    position: 'absolute',
    bottom: -20,
    paddingTop: 3,
    color: 'red',
    fontSize: 12,
    transform: 'translateZ(0)',
  },
});

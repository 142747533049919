import React, { useState, useEffect } from 'react';
import { View, ScrollView } from 'react-native';
import { useForm } from 'react-hook-form';
import { Col, Grid, Row } from 'react-native-easy-grid';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { LinearGradient } from 'expo-linear-gradient';
import { withNamespaces } from 'react-i18next';

import HeaderToken from '@components/Headers/HeaderToken';
import styles from './style';
import JInput from '@components/common/JInput';
import ModalAlert from '@components/Modals/ModalAlert';
import JButton from '@components/common/JButton';
import { JText as Text } from '@components/common/JText';
import { useMutation } from '@tanstack/react-query';
import { post, postMulti } from '@utils/RequestLevica';
import { API_PATH } from '@helper/constant';

const MailNotificationsTest = (props) => {
  const { t, setMailNotificationsTest, mailContent, isUpdate, fileSize } = props;
  const [isModalSave, setIsModalSave] = useState(false);

  const schema = Yup.object().shape({
    toAddress: Yup.string()
      .trim()
      .required(
        t('errorMessages.fieldRequired', {
          fieldName: t('pages_FileManager_MailNotifications_MailNotificationsTest.destinationAddress'),
        })
      )
      .email(t('errorMessages.TXT_EMAIL_REQUIRED'))
      .max(32, t('errorMessages.TXT_MAX_32')),
  });

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors, isDirty, isValid },
  } = useForm({
    defaultValues: {
      toAddress: '',
    },
    resolver: yupResolver(schema),
    mode: 'onChange',
  });

  const { mutate: emailTest } = useMutation({
    mutationFn: async (templateSms) => await postMulti(`${API_PATH.TEMPLATES_EMAIL}/preview?type=1`, templateSms),
    retry: 3,
  });
  const { mutate: getTemplateMasterData } = useMutation({
    mutationFn: async () => await post(API_PATH.TEMPLATES_MASTER_DATA, { data: mailContent?.mailContentTemplate }),
    onSuccess: ({ data }) => {
      const keySettingData = `${data}\n\n\n\n\n${mailContent?.sign}`;
      setValue('keySetting', keySettingData);
    },
    retry: 3,
  });
  useEffect(() => {
    getTemplateMasterData();
  }, []);

  const onSubmit = (data) => {
    delete data.keySetting;
    const newData = { ...data, ...mailContent, type: 1 };
    const formData = new FormData();
    formData.append('toAddress', newData.toAddress);
    if (newData?.bccMailAddress) {
      const emails = newData?.bccMailAddress.split(',').map((email) => email.trim());
      formData.append('bccMailAddress', emails);
    }
    formData.append('isSelected', newData.isSelected);
    formData.append('languageCd', newData.languageCd);
    formData.append('mailContentTemplate', newData.mailContentTemplate);
    formData.append('mailTitle', newData.mailTitle);
    formData.append('senderMailAddress', newData.senderMailAddress);
    formData.append('sign', newData.sign);
    formData.append('subject', newData.subject);
    formData.append('type', newData.type);
    if (isUpdate) {
      const newArray = newData?.emailAttachmentsData?.filter((item) => item.id);
      const orderFileAttachmentIds = newArray?.map((item) => item.id).join(',');
      formData.append('availableFileIds', orderFileAttachmentIds ?? '');
    }
    if (fileSize > 0) {
      const newArray = newData.attachments?.filter((item) => item.size);
      newArray?.map((item) => {
        formData.append('attachments', item?.file);
      });
    }
    emailTest(formData, {
      onSuccess: () => {
        setIsModalSave(true);
        reset();
      },
      onError: (error) => console.log(error),
    });
  };
  const openModalBack = () => {
    setMailNotificationsTest(false);
  };

  return (
    <View style={styles.header}>
      <ScrollView showsVerticalScrollIndicator={false}>
        <View style={[styles.positionSticky]}>
          <HeaderToken
            linkBtnBack="iconX.svg"
            actionBtnBack={openModalBack}
            textBtnBack={t('commonText.cancelButton')}
            linkIcon="testMail.svg"
            textHeader={t('pages_FileManager_MailNotifications_MailNotificationsTest.textHeader')}
            fullText
          />
        </View>
        <View style={styles.formSubmit}>
          <View style={styles.borderForm}>
            <Grid>
              <Row size={1} style={styles.input}>
                <Col size={3} style={[styles.flexDirection, styles.titleInput]}>
                  <Text style={styles.whiteColor}>
                    {t('pages_FileManager_MailNotifications_MailNotificationsTest.destinationAddress')}
                  </Text>
                  <Text style={styles.redColor}>✴︎</Text>
                </Col>
                <Col size={9}>
                  <View>
                    <JInput
                      placeholder={t(
                        'pages_FileManager_MailNotifications_MailNotificationsTest.destinationAddressPlaceholder'
                      )}
                      width="100%"
                      subfix="iconEdit.svg"
                      widthSubfix={18}
                      heightSubfix={18}
                      control={control}
                      name="toAddress"
                      paddingLeft={14}
                    />
                  </View>
                </Col>
              </Row>
              <Row size={3} style={styles.textArea}>
                <Col size={3} style={[styles.flexDirection, styles.titleInput]}>
                  <Text style={styles.whiteColor}>
                    {t('pages_FileManager_MailNotifications_MailNotificationsTest.keySetting')}
                  </Text>
                  <Text style={styles.redColor}>✴︎</Text>
                </Col>
                <Col size={9}>
                  <View>
                    <JInput
                      placeholder={`${t(
                        'pages_FileManager_MailNotifications_MailNotificationsTest.keySettingPlaceholder'
                      )}`}
                      width="100%"
                      subfix="iconEdit.svg"
                      widthSubfix={18}
                      heightSubfix={18}
                      control={control}
                      name="keySetting"
                      paddingLeft={14}
                      multiline={true}
                      heightMulti={400}
                    />
                  </View>
                </Col>
              </Row>
              <Row size={4}>
                <Col size={3} style={[styles.flexDirection, styles.titleInput]}></Col>
                <Col size={9}>
                  <View style={styles.btnSubmit}>
                    <LinearGradient colors={['#32A5F7', '#2D81F3']} style={{ borderRadius: 5, width: 340 }}>
                      <JButton
                        text={t('pages_FileManager_MailNotifications_MailNotificationsTest.buttonTestSend')}
                        width={340}
                        textColor="#f2f2f2"
                        hasIcon
                        linkIcon="submit.svg"
                        linkIconDisabled="sendGray.svg"
                        widthIcon={18}
                        heightIcon={18}
                        onPressAction={() => handleSubmit(onSubmit)()}
                        disabled={!isValid || !isDirty}
                      />
                    </LinearGradient>
                  </View>
                </Col>
              </Row>
            </Grid>
          </View>
        </View>
        <ModalAlert
          isModalVisible={isModalSave}
          setModalVisible={setIsModalSave}
          modalText={t('pages_FileManager_MailNotifications_MailNotificationsTest.modalAlertText')}
          iconWidth={16}
          iconHeight={12.5}
          icon="successGreen.svg"
          func={openModalBack}
          onBackdropPressCallback={openModalBack}
        />
      </ScrollView>
    </View>
  );
};
export default withNamespaces()(MailNotificationsTest);

import React, { useCallback, useEffect, useRef, useState } from 'react';
import { View, Image, TouchableOpacity, ScrollView } from 'react-native';
import { useFormContext } from 'react-hook-form';
import { LinearGradient } from 'expo-linear-gradient';
import { Col, Grid } from 'react-native-easy-grid';
import { get, put } from '@utils/RequestLevica';
import { useMutation, useQuery } from '@tanstack/react-query';
import { withNamespaces } from 'react-i18next';
import { useSelector } from 'react-redux';

import AccountInput from '@components/AccountComponents/AccountInput';
import ModalDelete from '@components/Modals/ModalDelete';
import JButton from '@components/common/JButton';
import styles from './styles';
import ModalAlert from '@components/Modals/ModalAlert';
import { TEXT_COLOR_GREEN } from '@assets/style/styleDefault';
import { JText as Text } from '@components/common/JText';
import HeaderAvatar from '@components/Headers/HeaderAvatar';
import AccountSelect from '@components/AccountComponents/AccountSelect';
import { ROLE_USER } from '@helper/constant';
import { bankInfoDefaultValues } from './../createAccountShopFormData';
import scroll2View from '@helper/scroll2View';
import { checkPermission } from '@helper/commonFunction';

const BankInfomation = (props) => {
  const [modalDelete, setModalDelete] = useState(false);
  const [modalAlert, setModalAlert] = useState(false);
  const [openModalUpdate, setOpenModalUpdate] = useState(false);
  const scrollViewRef = useRef();
  const [isSaveBankInfo, setIsSaveBankInfo] = useState(false);
  const userInfoRole = useSelector((state) => state.merchantUserInfo);
  const { bankTypesSelect, t, setOpenBank, control, accountId, bankInfoBackup, setBankInfoBackup, accountName } = props;
  const {
    formState: { isValid, isDirty, errors },
    reset,
    watch,
    handleSubmit,
  } = useFormContext();

  const { mutate: updateBankInformation } = useMutation({
    mutationFn: async (data) => {
      setIsSaveBankInfo(true);
      await put(`/v0.2/admin/merchants/${accountId}/bankInfo`, data);
      setIsSaveBankInfo(false);
    },
    retry: 3,
  });
  const onDeleteInfoBank = useCallback(() => {
    //api clear bank
    // updateBankInformation(bankInfoDefaultValues, {
    //   onSuccess: () => {
    //     reset(bankInfoDefaultValues);
    //     setModalDelete(false);
    //     setModalAlert(true);
    //   },
    //   onError: (error) => console.log(error),
    // });
    reset(bankInfoDefaultValues);
    setModalDelete(false);
    setModalAlert(true);
  }, [reset]);

  const handleSave = useCallback(() => {
    const bankInfoData = watch();
    if (accountId) {
      return updateBankInformation(bankInfoData, {
        onSuccess: () => {
          setOpenModalUpdate(true);
          setBankInfoBackup(bankInfoData);
          reset(bankInfoData, { keepDirty: false });
        },
        onError: (error) => console.log(error),
      });
    }
    setBankInfoBackup(bankInfoData);
    setOpenBank(false);
  }, [accountId, setOpenBank, watch]);

  const handleBack = useCallback(() => {
    reset(bankInfoBackup, { keepSubmitCount: true });
    setTimeout(() => setOpenBank(false));
  }, [setOpenBank, reset, bankInfoBackup]);

  useEffect(() => {
    scroll2View(Object.keys(errors)[0], scrollViewRef.current);
  }, [errors]);

  const checkPermissionUser = checkPermission(ROLE_USER.USER_MANAGEMENT, userInfoRole);

  return (
    <View style={{ height: '100%' }}>
      <View style={styles.wrapHeader}>
        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
          <TouchableOpacity onPress={handleBack}>
            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
              <Image style={styles.iconLeft} source={require(`@assets/icons/iconLeft.svg`)} />
              <Text style={{ color: TEXT_COLOR_GREEN, fontSize: 18 }}>{t('commonText.backArrow')}</Text>
            </View>
          </TouchableOpacity>
          <View style={{ flexDirection: 'row', alignItems: 'center' }}>
            <Image style={styles.iconBank} source={require(`@assets/icons/bank-card.svg`)} />
            <Text style={styles.textHeader}>
              {accountId ? t('page_Bank_Information.edit') : t('page_Bank_Information.create')}
            </Text>
          </View>
        </View>

        <HeaderAvatar />
      </View>
      <View style={[styles.pl20, { height: 'calc(100% - 81px)' }]}>
        <Grid style={[styles.wrapperFrom, { marginBottom: 50 }]}>
          <Col size={9}>
            <View
              style={{
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              {accountId ? (
                <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                  <Image style={styles.iconUser} source={require(`@assets/icons/user.svg`)} />
                  <Text style={styles.textHeader}>
                    {`${accountName ?? ''}${t('page_Bank_Information.edit_displayTitle')}`}
                  </Text>
                </View>
              ) : (
                <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                  <Image style={styles.iconBuilding} source={require(`@assets/icons/building.svg`)} />
                  <Text style={styles.textHeader}>{t('page_Bank_Information.create_displayTitle')}</Text>
                </View>
              )}

              {checkPermissionUser ? (
                <LinearGradient colors={['#54DBB2', '#04C48B']} style={{ borderRadius: 5 }}>
                  <JButton
                    text={t('commonText.buttonSave')}
                    width={170}
                    textColor="#fff"
                    hasIcon={true}
                    linkIcon="iconDownload.svg"
                    linkIconDisabled="iconDownloadGray.svg"
                    widthIcon={17}
                    heightIcon={15}
                    onPressAction={handleSubmit(handleSave)}
                    isProcessing={isSaveBankInfo}
                    disabled={isSaveBankInfo || !isDirty}
                  />
                </LinearGradient>
              ) : null}
            </View>
          </Col>
          <Col size={3} />
        </Grid>

        <ScrollView ref={scrollViewRef} style={[styles.formWrapper, { paddingTop: 10 }]}>
          <Grid style={styles.wrapperFrom}>
            <Col size={9}>
              <AccountInput
                disable={checkPermissionUser}
                control={control}
                name="merchantBankInfoBankCode"
                placeholder={t('page_Bank_Information.bankCodePlaceholder')}
                isRequired={true}
                labelText={t('page_Bank_Information.bankCode')}
                subfix="iconEdit.svg"
              />
            </Col>
            <Col size={3} />
          </Grid>
          <Grid style={styles.wrapperFrom}>
            <Col size={9}>
              <AccountInput
                disable={checkPermissionUser}
                control={control}
                name="merchantBankInfoBankName"
                placeholder={t('page_Bank_Information.bankNamePlaceholder')}
                isRequired={true}
                labelText={t('page_Bank_Information.bankName')}
                subfix="iconEdit.svg"
              />
            </Col>
            <Col size={3} />
          </Grid>
          <Grid style={styles.wrapperFrom}>
            <Col size={9}>
              <AccountInput
                disable={checkPermissionUser}
                control={control}
                name="merchantBankInfoBranchCode"
                placeholder={t('page_Bank_Information.branchCodePlaceholder')}
                isRequired={true}
                labelText={t('page_Bank_Information.branchCode')}
                subfix="iconEdit.svg"
              />
            </Col>
            <Col size={3} />
          </Grid>
          <Grid style={styles.wrapperFrom}>
            <Col size={9}>
              <AccountInput
                disable={checkPermissionUser}
                control={control}
                name="merchantBankInfoBranchName"
                placeholder={t('page_Bank_Information.branchNamePlaceholder')}
                isRequired={true}
                labelText={t('page_Bank_Information.branchName')}
                subfix="iconEdit.svg"
              />
            </Col>
            <Col size={3} />
          </Grid>
          <Grid style={styles.wrapperFrom}>
            <Col size={9}>
              <AccountSelect
                disable={checkPermissionUser}
                control={control}
                placeholder={t('page_Bank_Information.accountTypePlaceholder')}
                isRequired={true}
                labelText={t('page_Bank_Information.accountType')}
                subfix="iconEdit.svg"
                name="merchantBankInfoAccountType"
                selectItems={bankTypesSelect}
              />
            </Col>
            <Col size={3} />
          </Grid>
          <Grid style={styles.wrapperFrom}>
            <Col size={9}>
              <AccountInput
                disable={checkPermissionUser}
                control={control}
                name="merchantBankInfoAccountNumber"
                placeholder={t('page_Bank_Information.bankNumberPlaceholder')}
                isRequired={true}
                labelText={t('page_Bank_Information.bankNumber')}
                subfix="iconEdit.svg"
              />
            </Col>
            <Col size={3} />
          </Grid>

          <Grid style={styles.wrapperFrom}>
            <Col size={9}>
              <AccountInput
                disable={checkPermissionUser}
                control={control}
                name="merchantBankInfoAccountHolderName"
                placeholder={t('page_Bank_Information.nominalPerson')}
                isRequired={true}
                labelText={t('page_Bank_Information.holderName')}
                subfix="iconEdit.svg"
              />
            </Col>
            <Col size={3} />
          </Grid>
          {accountId && checkPermissionUser ? (
            <Grid style={styles.wrapperFrom}>
              <Col size={9} style={{ alignItems: 'flex-end' }}>
                <TouchableOpacity
                  style={styles.flexAlignCenter}
                  onPress={() => {
                    setModalDelete(!modalDelete);
                  }}
                >
                  <Image style={styles.iconDelete} alt="" source={require(`@assets/icons/delete.svg`)} />
                  <Text style={styles.colorRed}>{t('page_Bank_Information.remove')}</Text>
                </TouchableOpacity>
              </Col>
              <Col size={3} />
            </Grid>
          ) : null}
        </ScrollView>
      </View>
      <ModalDelete
        textBtnRight={t('page_Bank_Information.buttonRemove')}
        textBtnLeft={t('commonText.cancelButton')}
        modalDelete={modalDelete}
        setModalDelete={setModalDelete}
        modalAlert={modalAlert}
        setModalAlert={setModalAlert}
        icon="x-card-red.svg"
        textHeader={t('commonText.title_confirmRemove')}
        textContent={[t('commonText.message_confirmRemove')]}
        func={onDeleteInfoBank}
        iconBtnLeft="close-white.svg"
        iconBtnRight="delete-white.svg"
        heightIconBtnLeft={15}
        widthIconBtnLeft={15}
        heightIconBtnRight={20}
        widthIconBtnRight={18}
        marginBottomIconRight={5}
        mtTextHeader={10}
      />
      <ModalAlert
        isModalVisible={modalAlert}
        setModalVisible={setModalAlert}
        icon="x-card-red.svg"
        iconWidth={50}
        iconHeight={50}
        modalText={t('page_Bank_Information.removeSuccesses')}
      />
      <ModalAlert
        isModalVisible={openModalUpdate}
        setModalVisible={setOpenModalUpdate}
        icon="successGreen.svg"
        iconWidth={15}
        iconHeight={15}
        modalText={t('page_Bank_Information.saveSuccesses')}
      />
    </View>
  );
};

export default withNamespaces()(BankInfomation);

import React, { useState } from 'react';
import { View, Image, TouchableOpacity } from 'react-native';
import { LinearGradient } from 'expo-linear-gradient';
import { NavLink } from 'react-router-dom';
import Modal from 'react-native-modal';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { withNamespaces } from 'react-i18next';
import { Video } from 'expo-av';

import JInput from '@components/common/JInput';
import { JText as Text } from '@components/common/JText';
import styles from './style';

import { post } from '@utils/RequestLevica';
import { API_PATH } from '@helper/constant';
import ModalBackDrop from '@components/Modals/ModalBackDrop';

const ForgotPassword = ({ t }) => {
  const [isModalVisible, setModalVisible] = useState(false);
  const [isErrorBackgroundVideo, setErrorBackgroundVideo] = useState(false);
  const schema = Yup.object().shape({
    email: Yup.string().email(t('errorMessages.TXT_EMAIL_REQUIRED')).required(t('errorMessages.TXT_REQUIRED')),
  });
  const {
    control,
    handleSubmit,
    setError,
    reset,
    formState: { errors, isDirty },
  } = useForm({
    defaultValues: {
      email: '',
    },
    resolver: yupResolver(schema),
    mode: 'onSubmit',
  });

  const onSubmit = async (email) => {
    try {
      await post(API_PATH.FORGOT_PASSWORD, email);
      setModalVisible(true);
    } catch (error) {
      if (error?.response?.data?.errorCode === '1623') {
        setError('email', {
          type: 'server',
          message: t('errorMessages.TXT_EMAIL_NOT_REGISTERED'),
        });
      } else {
        setError('email', {
          type: 'server',
          message: t('errorMessages.TXT_OTHER_ERROR'),
        });
      }
    }
  };
  const closeModal = () => {
    setModalVisible(false);
    reset({
      email: '',
    });
  };
  return (
    <>
      <View style={styles.backgroundColor}>
        {isErrorBackgroundVideo ? (
          <Image style={styles.backgroundImg} source={require('@assets/image/login.jpg')} alt="background login" />
        ) : (
          <Video
            source={require('@assets/videos/login-background.mp4')}
            style={styles.backgroundVideo}
            posterStyle={styles.backgroundImg}
            rate={1}
            shouldPlay={true}
            isLooping={true}
            isMuted={true}
            posterSource={require('@assets/image/login.jpg')}
            usePoster={true}
            onError={() => setErrorBackgroundVideo(true)}
            VideoNaturalSize={{ width: 1200 }}
            resizeMode="cover"
          />
        )}
        <View style={styles.backGroup}>
          <NavLink to={'/login'} style={{ textDecorationLine: 'none' }}>
            <View style={styles.btnClose}>
              <Image style={styles.iconClose} source={require('@assets/icons/closeGreen.svg')} />
              <View>
                <Text style={styles.textClose}>{t('commonText.cancelButton')}</Text>
              </View>
            </View>
          </NavLink>
          <View style={styles.form}>
            <View style={styles.formHeader}>
              <View style={styles.textHeader}>
                <Text style={styles.colorS}>{t('page_ForgotPassword.reset')}</Text>
              </View>
            </View>
            <View style={styles.formBody}>
              <JInput
                onPressEnter={handleSubmit(onSubmit)}
                placeholder="Please enter right email address."
                width="100%"
                prefix="mailGray.svg"
                widthPrefix={26}
                heightPrefix={20}
                topPrefix={14}
                control={control}
                name="email"
                borderError={errors.email ? '#FF0000' : '#5C5C5C'}
                heightInput={52}
              />
              <View>
                <Text style={styles.text}>{t('page_ForgotPassword.message_reset')}</Text>
              </View>
              <View style={styles.formAction}>
                {isDirty ? (
                  <LinearGradient colors={['#54DBB2', '#54DBB2']} style={styles.btnActive}>
                    <TouchableOpacity
                      onPress={() => {
                        handleSubmit(onSubmit)();
                      }}
                    >
                      <View style={styles.buttonSubmit}>
                        <Text style={styles.textBtnSubmit}>{t('page_ForgotPassword.sendEmail')}</Text>
                      </View>
                    </TouchableOpacity>
                  </LinearGradient>
                ) : (
                  <View style={styles.buttonAction}>
                    <Text style={styles.textBtnAction}>{t('page_ForgotPassword.sendEmail')}</Text>
                  </View>
                )}
              </View>
            </View>
          </View>
        </View>
      </View>
      <View>
        <Modal
          animationIn="fadeIn"
          isVisible={isModalVisible}
          style={{ alignItems: 'center' }}
          customBackdrop={<ModalBackDrop onPress={closeModal} />}
        >
          <View style={styles.maxWidth600} onClick={() => closeModal()}>
            <View style={styles.content}>
              <View style={styles.title}>
                <Text style={{ color: 'black' }}>{t('page_ForgotPassword.EmailSent')}</Text>
              </View>
              <Text style={styles.contentText}>{t('page_ForgotPassword.checkMailPls')}</Text>
            </View>
          </View>
        </Modal>
      </View>
    </>
  );
};

export default withNamespaces()(ForgotPassword);

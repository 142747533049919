import { StyleSheet } from 'react-native';
import { BG_HOVER, LINE_COLOR, TEXT_COLOR_GREY } from '@assets/style/styleDefault';

const styles = StyleSheet.create({
  headerSearch: {
    marginRight: 0,
    marginTop: 10,
  },
  headerButton: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingTop: 10,
    paddingBottom: 10,
    paddingRight: 30,
    paddingLeft: 100,
    height: 80,
  },
  container: {
    paddingHorizontal: 30,
    paddingVertical: 15,
    height: 'calc(100vh - 181px)',
  },
  icon: {
    width: 23,
    height: 23,
    resizeMode: 'contain',
    marginRight: 10,
  },
  firstCol: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 5,
  },
  item: {
    paddingVertical: 10,
    borderBottomColor: LINE_COLOR,
    borderBottomWidth: 1,
    minHeight: 51,
    flexDirection: 'row',
    alignItems: 'center',
    gap: 20,
    paddingLeft: 5,
  },
  center: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  warperStatus: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  status: {
    color: TEXT_COLOR_GREY,
    minWidth: 160,
    textAlign: 'right',
  },
  itemHovered: {
    backgroundColor: BG_HOVER,
    borderRadius: 4,
  },

  textNoData: {
    color: TEXT_COLOR_GREY,
  },
  iconImg: {
    width: 25,
    height: 20,
    marginRight: 10,
    resizeMode: 'contain',
  },
  iconImgRight: {
    width: 9,
    height: 16,
    resizeMode: 'contain',
  },
  iconImgTxt: {
    width: 25,
    height: 25,
    marginHorizontal: 5,
  },
  iconImgMail: {
    width: 28,
    height: 20,
    marginHorizontal: 5,
  },
  iconAddMail: {
    width: 53,
    height: 37,
    marginRight: 10,
  },
  iconAddTxt: {
    width: 53,
    height: 37,
    marginRight: 10,
  },
});

export default styles;

import React, { useState } from 'react';
import { View, Image } from 'react-native';
import moment from 'moment';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { withNamespaces } from 'react-i18next';
import { Video } from 'expo-av';
import { LinearGradient } from 'expo-linear-gradient';

import JInput from '@components/common/JInput';
import { JText as Text } from '@components/common/JText';
import { post } from '@utils/RequestLevica';
import JButton from '@components/common/JButton';
import { API_PATH, STORAGEKEY } from '@helper/constant';
import styles from './style';
import { TEXT_COLOR_GREY } from '@assets/style/styleDefault';
import { setCookie } from '@utils/TokenStorage';
import { setIsLoaded, setRouteByUserInfo } from '@redux/slices/authSlice';
import { language } from '@redux/slices/LanguageSlice';

const OtpAuthLogin = ({ t }) => {
  let { search } = useLocation();
  const transactionId = search.replace('?trasactionId=', '');
  const [isErrorBackgroundVideo, setErrorBackgroundVideo] = useState(false);
  const history = useHistory();
  const schema = Yup.object().shape({
    otpAuthLogin: Yup.string()
      .trim()
      .matches(/^[0-9]+$/, t('errorMessages.TXT_NUMERIC'))
      .required(t('errorMessages.TXT_REQUIRED')),
  });
  const {
    control,
    handleSubmit,
    setError,
    formState: { isValid },
  } = useForm({
    defaultValues: {
      otpAuthLogin: '',
    },
    resolver: yupResolver(schema),
    mode: 'onChange',
  });
  const onSubmit = async (value) => {
    try {
      const { accessToken, refreshToken } = await post(API_PATH.VERIFY_OTP_LOGIN, {
        otp: value.otpAuthLogin,
        transactionId: transactionId,
      });

      if (!!accessToken) {
        setCookie(STORAGEKEY.ACCESS_TOKEN, accessToken?.value, { expires: moment(accessToken?.expiry).toDate() });
        setCookie(STORAGEKEY.REFRESH_TOKEN, refreshToken?.value, { expires: moment(refreshToken?.expiry).toDate() });
        history.push('/');
        dispatch(setIsLoaded(false));
        dispatch(setRouteByUserInfo(false));
        dispatch(language());
      }
    } catch (error) {
      if (error?.response?.data?.code === '1023') {
        setError('otpAuthLogin', {
          type: 'server',
          message: t('errorMessages.TXT_OTP_INVALID'),
        });
      }
    }
  };

  return (
    <>
      <View style={styles.backgroundColor}>
        {isErrorBackgroundVideo ? (
          <Image style={styles.backgroundImg} source={require('@assets/image/login.jpg')} alt="background login" />
        ) : (
          <Video
            source={require('@assets/videos/login-background.mp4')}
            style={styles.backgroundVideo}
            posterStyle={styles.backgroundImg}
            rate={1}
            shouldPlay={true}
            isLooping={true}
            isMuted={true}
            posterSource={require('@assets/image/login.jpg')}
            usePoster={true}
            onError={() => setErrorBackgroundVideo(true)}
            VideoNaturalSize={{ width: 1200 }}
            resizeMode="cover"
          />
        )}
        <View style={styles.backGroup}>
          <NavLink to={'/login'} style={{ textDecorationLine: 'none' }}>
            <View style={styles.btnClose}>
              <Image style={styles.iconClose} source={require('@assets/icons/closeGreen.svg')} />
              <View>
                <Text style={styles.textClose}>キャンセル</Text>
              </View>
            </View>
          </NavLink>
          <View style={styles.form}>
            <View style={styles.formHeader}>
              <View style={styles.textHeader}>
                <Text style={styles.colorS}>OTP認証</Text>
              </View>
            </View>
            <View style={styles.formBody}>
              <JInput
                onPressEnter={handleSubmit(onSubmit)}
                placeholder="OTPを入力してください。"
                width="100%"
                control={control}
                name="otpAuthLogin"
                heightInput={52}
              />
              <View>
                <Text style={styles.text}>OTPをメールでお知らせします。OTPを入力してください。</Text>
              </View>
              <View style={styles.formAction}>
                <LinearGradient colors={['#54DBB2', '#54DBB2']} style={{ borderRadius: 5 }}>
                  <JButton
                    text={'送信'}
                    width={300}
                    height={52}
                    textColor={isValid ? '#fff' : TEXT_COLOR_GREY}
                    disabled={!isValid}
                    onPressAction={() => handleSubmit(onSubmit)()}
                  />
                </LinearGradient>
              </View>
            </View>
          </View>
        </View>
      </View>
    </>
  );
};

export default withNamespaces()(OtpAuthLogin);

import React, { useCallback, useEffect, useState } from 'react';
import { View, Image, ScrollView } from 'react-native';
import { Col, Row, Grid } from 'react-native-easy-grid';
import { LinearGradient } from 'expo-linear-gradient';
import { useForm } from 'react-hook-form';
import { withNamespaces } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';

import styles from './style';
import HeaderBack from '@components/Headers/HeaderBack';
import JButton from '@components/common/JButton';
import ModalAlert from '@components/Modals/ModalAlert';
import ModalRefesh from '@components/Modals/ModalRefesh';
import { defaultValue, listDataTableRole } from './dataAdminLevelSetting';
import { JText as Text } from '@components/common/JText';
import CheckboxGroupTable from '@components/AcceptLevelSetting/CheckboxGroupTable';
import { BTN_COLOR_GREY, COLOR_TEXT_DEFAULT } from '@assets/style/styleDefault';
import { API_PATH } from '@helper/constant';
import { get, put } from '@utils/RequestLevica';
import { merchantUserInfo } from '@redux/slices/authSlice';
import Loading from '@components/common/Loading';

const AdminAccountSetting = ({ t }) => {
  const [isModal, setIsModal] = useState(false);
  const [isModalSave, setIsModalSave] = useState(false);
  const [isModalRefesh, setIsModalRefesh] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [formValue, setFormValue] = useState(null);
  const dispatch = useDispatch();
  const [isInitialRole, setIsIntialRole] = useState(true);
  const [isActiveSubmitBtnManual, setIsActiveSubmitBtnManual] = useState(false);
  const [isInjectedRoleToHookForm, setIsInjectedRoleToHookForm] = useState(false);

  const {
    control,
    handleSubmit,
    setValue,
    reset,
    formState: { isDirty, isValid },
    watch,
  } = useForm({
    defaultValues: defaultValue,
  });

  useEffect(() => {
    if (formValue) {
      fillData(formValue);
    }
  }, [setValue, formValue]);

  const otherModal = () => {
    setIsModalRefesh(false);
    reset();
    setFormValue({ ...defaultValue });
    setIsModal(true);
    setIsActiveSubmitBtnManual(true);
    setIsIntialRole(true);
  };

  const openModalRefesh = () => setIsModalRefesh(!isModalRefesh);

  const fillData = () => {
    Object.entries(formValue).forEach(([name, value]) => setValue(name, value));
  };

  const {
    isLoading,
    error,
    data: roleSettingData,
    isFetching,
    remove,
  } = useQuery(
    ['getRoleSetting'],
    async () => {
      const data = await get(API_PATH.SETTING_ROLE);
      setFormValue(data);
      setIsInjectedRoleToHookForm(true);
      return data;
    },
    { refetchOnWindowFocus: false }
  );

  const onSubmit = async (settingRoleData) => {
    setIsSaving(true)
    await put(API_PATH.SETTING_ROLE, settingRoleData).then(()=>{
      setIsSaving(false)
    }).catch(()=>{
      setIsSaving(false)
    });
    setIsModalSave(!isModalSave);
  };

  const checkIsInitialRoles = useCallback(() => {
    if (!formValue || !defaultValue) return false;

    if (Object.keys(defaultValue).length != Object.keys(formValue).length) return setIsIntialRole(false);

    for (let role in formValue) {
      const initRole = defaultValue[role];
      const currentRole = formValue[role];
      if (initRole.length != currentRole.length || !initRole) return setIsIntialRole(false);
      const roleCheck = currentRole.every((object) => initRole.includes(object));
      if (!roleCheck) return setIsIntialRole(false);
    }

    setIsIntialRole(true);
  }, [formValue, setIsIntialRole]);

  useEffect(() => {
    checkIsInitialRoles();
  }, [checkIsInitialRoles]);

  useEffect(() => remove, [remove]);

  return (
    <View style={styles.container}>
      <ScrollView showsVerticalScrollIndicator={false}>
        <View style={styles.wrapperStickyHeader}>
          <HeaderBack
            btnBack="iconLeft.svg"
            textBack={t('commonText.backArrow')}
            iconHeader="adminSetting.svg"
            textHeader={t('pages_Setting_AdminSetting.textHeader')}
            linkBack={`/admin`}
            hasSubTitle
          />

          {!isFetching && isInjectedRoleToHookForm && (
            <View>
              <View style={[styles.wrapperHeader]}>
                <View style={styles.flexRow}>
                  <Image style={styles.iconWifi} source={require(`@assets/icons/wifiWarning.svg`)} />
                  <Text>{t('pages_Setting_AdminSetting.accessLevel')}</Text>
                </View>
                <View style={styles.flexDirection}>
                  <View style={styles.paddingRight}>
                    {!isInitialRole || isDirty ? (
                      <LinearGradient colors={['#FE8463', '#FD4F44']} style={{ borderRadius: 5 }}>
                        <JButton
                          text={t('pages_Setting_AdminSetting.buttonInitialize')}
                          width={200}
                          textColor={COLOR_TEXT_DEFAULT}
                          hasIcon
                          widthIcon={18}
                          heightIcon={18}
                          onPressAction={openModalRefesh}
                        />
                      </LinearGradient>
                    ) : (
                      <JButton
                        text={t('pages_Setting_AdminSetting.buttonInitialize')}
                        width={200}
                        textColor={COLOR_TEXT_DEFAULT}
                        btnColor={BTN_COLOR_GREY}
                        hasIcon
                        widthIcon={18}
                        heightIcon={18}
                        onPressAction={openModalRefesh}
                        disabled
                      />
                    )}
                  </View>
                  {isDirty || isActiveSubmitBtnManual ? (
                    <LinearGradient colors={['#54DBB2', '#54DBB2']} style={{ borderRadius: 5 }}>
                      <JButton
                        text={t('commonText.buttonSave')}
                        width={200}
                        textColor={COLOR_TEXT_DEFAULT}
                        hasIcon
                        linkIcon="iconDownload.svg"
                        linkIconDisabled="iconDownloadGray.svg"
                        widthIcon={18}
                        heightIcon={18}
                        isProcessing={isSaving}
                        disabled={isSaving}
                        onPressAction={handleSubmit(onSubmit)}
                      />
                    </LinearGradient>
                  ) : (
                    <JButton
                      text={t('commonText.buttonSave')}
                      width={200}
                      textColor={COLOR_TEXT_DEFAULT}
                      btnColor={BTN_COLOR_GREY}
                      hasIcon
                      linkIcon="iconDownload.svg"
                      linkIconDisabled="iconDownloadGray.svg"
                      widthIcon={18}
                      isProcessing={isSaving}
                      disabled={isSaving}
                      heightIcon={18}
                      onPressAction={handleSubmit(onSubmit)}
                    />
                  )}
                </View>
              </View>
              <Grid>
                <Row style={[styles.head, styles.wrapperStickyTableHead]} size={25}>
                  <Col size={1} style={[styles.wrapperItemHead, styles.flexStart]}>
                    <Text numberOfLines={1} style={styles.textLeft}>
                      {t('pages_Setting_AdminSetting.operationDetailsPrivileges')}
                    </Text>
                  </Col>
                  <Col size={1} style={styles.wrapperItemHead}>
                    <Text numberOfLines={1} style={styles.textHead}>
                      Master
                    </Text>
                  </Col>
                  <Col size={1} style={styles.wrapperItemHead}>
                    <Text numberOfLines={1} style={styles.textHead}>
                      Executive
                    </Text>
                  </Col>
                  <Col size={1} style={styles.wrapperItemHead}>
                    <Text numberOfLines={1} style={styles.textHead}>
                      Associate
                    </Text>
                  </Col>
                </Row>
              </Grid>
            </View>
          )}
        </View>
        <View>
          <Grid>
            {isFetching || !isInjectedRoleToHookForm ? (
              <Loading style={{ height: 'calc(100vh - 80px)' }} />
            ) : (
              <View style={styles.bgForm}>
                <CheckboxGroupTable
                  formValue={formValue}
                  setValue={setValue}
                  control={control}
                  title={t('pages_Setting_AdminSetting.settingAdministrativePrivileges')}
                  data={listDataTableRole.SETTING_ROLE}
                />
                <CheckboxGroupTable
                  formValue={formValue}
                  setValue={setValue}
                  control={control}
                  title={t('pages_Setting_AdminSetting.registrationOfNewAdministrators')}
                  data={listDataTableRole.CREATE_ADMIN}
                />
                <CheckboxGroupTable
                  formValue={formValue}
                  setValue={setValue}
                  control={control}
                  title={t('pages_Setting_AdminSetting.deleteAdmin')}
                  data={listDataTableRole.DELETE_ADMIN}
                />
                <CheckboxGroupTable
                  formValue={formValue}
                  setValue={setValue}
                  control={control}
                  title={t('pages_Setting_AdminSetting.userAccountManagement')}
                  data={listDataTableRole.USER_MANAGEMENT}
                />
                <CheckboxGroupTable
                  formValue={formValue}
                  setValue={setValue}
                  control={control}
                  title={t('pages_Setting_AdminSetting.userIdentificationDocumentManagement')}
                  data={listDataTableRole.USER_EKYC_TYPE_MANAGEMENT}
                />
                <CheckboxGroupTable
                  formValue={formValue}
                  setValue={setValue}
                  control={control}
                  title={t('pages_Setting_AdminSetting.myNumberCardBackSideManagement')}
                  data={listDataTableRole.NUMBER_CARD_MANAGEMENT}
                />
                <CheckboxGroupTable
                  formValue={formValue}
                  setValue={setValue}
                  control={control}
                  title={t('pages_Setting_AdminSetting.editTokenInformation')}
                  data={listDataTableRole.EDIT_TOKEN_INFO}
                />
                <CheckboxGroupTable
                  formValue={formValue}
                  setValue={setValue}
                  control={control}
                  title={t('pages_Setting_AdminSetting.changeTokenStatus')}
                  data={listDataTableRole.UPDATE_TOKEN_STATUS}
                />
                <CheckboxGroupTable
                  formValue={formValue}
                  setValue={setValue}
                  control={control}
                  title={t('pages_Setting_AdminSetting.tokenIssuance')}
                  data={listDataTableRole.PUBLISH_TOKEN}
                />
                <CheckboxGroupTable
                  formValue={formValue}
                  setValue={setValue}
                  control={control}
                  title={t('pages_Setting_AdminSetting.tokenBurn')}
                  data={listDataTableRole.BURN_TOKEN}
                />
                <CheckboxGroupTable
                  formValue={formValue}
                  setValue={setValue}
                  control={control}
                  title={t('pages_Setting_AdminSetting.workingWithIssuingWallets')}
                  data={listDataTableRole.PUBLISH_WALLET_OPERATION}
                />
                <CheckboxGroupTable
                  formValue={formValue}
                  setValue={setValue}
                  control={control}
                  title={t('pages_Setting_AdminSetting.workingWithAdministrativeWallets')}
                  data={listDataTableRole.ADMIN_WALLET_OPERATION}
                />
                <CheckboxGroupTable
                  formValue={formValue}
                  setValue={setValue}
                  control={control}
                  title={t('pages_Setting_AdminSetting.workingWithUserWallets')}
                  data={listDataTableRole.USER_WALLET_OPERATION}
                />
                {/* <CheckboxGroupTable
                formValue={formValue}
                setValue={setValue}
                control={control}
                title={t('pages_Setting_AdminSetting.acceptanceOfTransfer')}
                data={listDataTableRole.APPROVAL_TRANSFER}
              /> */}
                <CheckboxGroupTable
                  formValue={formValue}
                  setValue={setValue}
                  control={control}
                  title={t('pages_Setting_AdminSetting.documentSettingsCreateEditDelete')}
                  data={listDataTableRole.DOCUMENT_MANAGEMENT}
                />
              </View>
            )}
          </Grid>
        </View>
        <ModalAlert
          isModalVisible={isModalSave}
          setModalVisible={setIsModalSave}
          modalText={t('pages_Setting_AdminSetting.modalAlertText1')}
          iconWidth={16}
          iconHeight={12.5}
          icon="successGreen.svg"
          func={() => dispatch(merchantUserInfo())}
          onBackdropPressCallback={() => dispatch(merchantUserInfo())}
        />
        <ModalAlert
          isModalVisible={isModal}
          setModalVisible={setIsModal}
          modalText={t('pages_Setting_AdminSetting.modalAlertText2')}
          iconWidth={16}
          iconHeight={12.5}
          icon="loading-green.svg"
        />
        <ModalRefesh isModal={isModalRefesh} setIsModal={setIsModalRefesh} otherModal={otherModal} />
      </ScrollView>
    </View>
  );
};

export default withNamespaces()(AdminAccountSetting);

import { useQuery } from '@tanstack/react-query';
import { Center } from 'native-base';
import { useEffect, useState } from 'react';
import { withNamespaces } from 'react-i18next';
import { Dimensions, Image, Pressable, ScrollView, View } from 'react-native';
import { Col, Grid } from 'react-native-easy-grid';
import React from 'react';

import JButton from '@components/common/JButton';
import JCheckbox from '@components/common/JCheckbox';
import { JText as Text } from '@components/common/JText';
import Pagination from '@components/common/Pagination';
import HeaderSearch from '@components/Headers/HeaderSearch';
import HeaderPayManagement from '@components/PayManagement';
import { API_PATH } from '@helper/constant';
import { formatDateTime, formatNumber } from '@helper/formatTypes';
import { get, post } from '@utils/RequestLevica';
import styles from '../style';
import { BG_FORM_DEFAULT } from '@assets/style/styleDefault';
import Loading from '@components/common/Loading';
import { LinearGradient } from 'expo-linear-gradient';
import ModalComplete from '../Modal/ModalComplete';
import ModalConfirmPayment from '../Modal/ModalConfirmPayment';
import ModalAlert from '@components/Modals/ModalAlert';

const PaymentComplete = ({ t }) => {
  const [isModalConfirm, setIsModalConfirm] = useState(false);
  const [isModalComplete, setIsModalComplete] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [paymentListData, setPaymentListData] = useState([]);
  const [listPaymentChecked, setListPaymentChecked] = useState([]);
  const [paymentIDSelected, setPaymentIDSelected] = useState([]);
  const [paymentProcessedAll, setPaymentProcessedAll] = useState(false);
  const [totalAmountChecked, setTotalAmountChecked] = useState();
  const [modalAlert, setModalAlert] = useState(false);
  const [tableHeadData, setTableHeadData] = useState(() => [
    {
      title: 'Symbol / I.No',
      hasIcon: true,
      sort: '0100',
      order: false,
      size: 2,
    },
    {
      title: t('pages_PaymentManager.paymentDueDate'),
      size: 2,
    },
    {
      title: t('pages_PaymentManager.userNameConfirmed'),
      size: 1,
    },
    {
      title: t('pages_PaymentManager.transferAmountOfMoney'),
      size: 2,
    },
    {
      title: t('pages_PaymentManager.bankCode'),
      size: 2,
    },
    {
      title: t('pages_PaymentManager.branchCode'),
      size: 2,
    },
    {
      title: t('pages_PaymentManager.accountType'),
      size: 1,
    },
    {
      title: t('pages_PaymentManager.transferAccountNumber'),
      size: 2,
    },
    {
      title: t('pages_PaymentManager_PaymentConfirmed.remittanceNameKana'),
      size: 2,
    },
    {
      title: t('pages_PaymentManager_PaymentConfirmed.paidCheckDate'),
      hasIcon: true,
      sort: '0200',
      order: false,
      size: 3,
    },
  ]);
  const [params, setParams] = useState({
    limit: 30,
    pageNumber: 1,
    startDate: '',
    endDate: '',
    q: '',
    sort: '0200',
    order: 'desc',
    type: '2',
  });
  const {
    data: PaymentComplete,
    isFetching,
    refetch,
    remove,
  } = useQuery(
    ['PaymentComplete', { ...params }],
    async ({ signal }) => {
      const data = await get(API_PATH.PAYMENT_CONFIRMED, { ...params, signal });
      if (paymentProcessedAll) {
        const newpaymentListData = data?.transactions?.map((item) => ({
          ...item,
          pickup: true,
        }));
        setPaymentListData(newpaymentListData);

        setPaymentIDSelected(() => {
          const newPaymentIDSelected = [...paymentIDSelected];
          data?.transactions?.map(
            ({ id: paymentID }) => !newPaymentIDSelected.includes(paymentID) && newPaymentIDSelected.push(paymentID)
          );
          return newPaymentIDSelected;
        });
      } else {
        setPaymentListData(
          data?.transactions?.map((item) => (paymentIDSelected.includes(item.id) ? { ...item, pickup: true } : item))
        );
      }
      return data;
    },
    { refetchOnWindowFocus: false }
  );

  const toggleModalConfirm = () => {
    setIsModalConfirm(true);
  };

  const toggleModalComplete = async () => {
    setIsProcessing(true);
    const idBurns = listPaymentChecked?.map((item) => item.id);
    try {
      await post(API_PATH.PAID_BURN, { ids: idBurns });
      setIsModalComplete(true);
      setIsModalConfirm(false);
      setIsProcessing(false);
      refetch();
    } catch (error) {
      setIsProcessing(false);
      setIsModalConfirm(false);
      if (error?.response?.data && error?.response?.data[0]?.code === '1506') {
        setModalAlert(true);
      }
    }
  };

  // const selectAll = () => {
  //   const isAllPaymentsInTabChecked = listPaymentChecked.length === paymentListData.length;
  //   setPaymentProcessedAll(!isAllPaymentsInTabChecked);

  //   const newpaymentListData = paymentListData?.map((item) => ({
  //     ...item,
  //     pickup: listPaymentChecked?.length > 0 ? (isAllPaymentsInTabChecked ? false : true) : true,
  //   }));
  //   setPaymentListData(newpaymentListData);

  //   setPaymentIDSelected(() => {
  //     if (isAllPaymentsInTabChecked) return [];
  //     const newPaymentIDSelected = [...paymentIDSelected];
  //     paymentListData?.map(
  //       ({ id: paymentID }) => !newPaymentIDSelected.includes(paymentID) && newPaymentIDSelected.push(paymentID)
  //     );
  //     return newPaymentIDSelected;
  //   });
  // };

  const toggleCheckbox = (item, index) => {
    const newpaymentListData = [...paymentListData];
    newpaymentListData?.splice(index, 1, { ...item, pickup: !item.pickup });
    setPaymentListData(newpaymentListData);

    const positionOfItemID = paymentIDSelected.indexOf(item.id);
    setPaymentIDSelected((prev) => {
      const newPaymentSelected = [...prev];
      if (positionOfItemID > -1) {
        newPaymentSelected.splice(positionOfItemID, 1);
        return newPaymentSelected;
      }
      return [...newPaymentSelected, item.id];
    });

    setPaymentProcessedAll(false);
  };

  useEffect(() => {
    let totalAmount = 0;

    const listPaymentChecked = paymentListData?.filter((item) => {
      if (item.pickup === true) {
        totalAmount += item.amount;
        return item;
      }
    });
    setListPaymentChecked(listPaymentChecked);
    setTotalAmountChecked(totalAmount);
  }, [paymentListData]);

  const handleSort = (item) => {
    setTableHeadData((prev) =>
      prev.map((tableHead) =>
        tableHead.sort === item.sort ? { ...tableHead, order: !tableHead.order } : { ...tableHead, order: false }
      )
    );

    setParams({
      ...params,
      sort: item.sort,
      pageNumber: 1,
      order: !item.order ? 'asc' : 'desc',
    });
  };

  const selectAll = () => {
    const isAllPaymentsInTabChecked = listPaymentChecked.length === paymentListData.length;
    setPaymentProcessedAll(!isAllPaymentsInTabChecked);

    const newPaymentListData = paymentListData?.map((item) => ({
      ...item,
      pickup: listPaymentChecked?.length > 0 ? (isAllPaymentsInTabChecked ? false : true) : true,
    }));
    setPaymentListData(newPaymentListData);

    setPaymentIDSelected(() => {
      if (isAllPaymentsInTabChecked) return [];
      const newPaymentIDSelected = [...paymentIDSelected];
      paymentListData?.map(
        ({ id: paymentID }) => !newPaymentIDSelected.includes(paymentID) && newPaymentIDSelected.push(paymentID)
      );
      return newPaymentIDSelected;
    });
  };

  useEffect(() => remove, [remove]);

  return (
    <>
      <View
        style={[
          styles.container,
          {
            backgroundColor: isFetching || paymentListData?.length < 1 ? '' : BG_FORM_DEFAULT,
          },
        ]}
      >
        <View style={styles.wrapperStickyHeader}>
          <View style={styles.headerSearch}>
            <HeaderSearch
              placeholder={t('pages_PaymentManager.placeholder')}
              setParams={setParams}
              params={params}
              onSearch={() => setPaymentProcessedAll(false)}
            />
          </View>

          <HeaderPayManagement
            refetchQuery={refetch}
            totalAmount={!PaymentComplete?.totalAmount || isFetching ? 0 : PaymentComplete?.totalAmount}
            totalCount={PaymentComplete?.pageInfo?.totalCount}
            linkPaymentProcessed={API_PATH.PAYMENT_CONFIRMED}
            paymentProcessedAll={paymentProcessedAll}
            exportCSVLink={API_PATH.PAYMENT_CONFIRMED_EXPORT_CSV_ALL}
            csvName={t('components_PayManagement.paymentConfirmationList')}
            listPaymentChecked={listPaymentChecked}
            // selectAll={selectAll}
            listChecked={listPaymentChecked}
            setParams={setParams}
            params={{ isCheckedAll: true, ...params }}
            active={'/payment/complete'}
            btn1={false}
            btn2={false}
            btn3={
              <View style={{ alignItems: 'center' }}>
                <Text style={{ color: '#FD614E' }}>
                  {(paymentProcessedAll && formatNumber(PaymentComplete?.totalAmount || ' ')) ||
                    (totalAmountChecked > 0 && formatNumber(totalAmountChecked)) ||
                    ' '}
                </Text>
                <LinearGradient colors={['#FE8463', '#FD4F44']} style={{ borderRadius: 5 }}>
                  <JButton
                    text={t('pages_PaymentManager_PaymentComplete.TXprocessing')}
                    width={150}
                    textColor={'#fff'}
                    disabled={listPaymentChecked?.length > 0 ? false : true}
                    onPressAction={() => toggleModalConfirm()}
                  />
                </LinearGradient>
              </View>
            }
            // btn4={
            //   <JButton
            //     text={t('commonText.buttonSelectAll')}
            //     onPressAction={() => selectAll()}
            //     width={150}
            //     textColor={TEXT_COLOR_GREEN}
            //     btnColor={INPUT_BG}
            //     borderBtn={`1px solid ${LINE_COLOR}`}
            //   />
            // }
            datetimeTitle={t('components_PayManagement.timespace')}
            onDateFilter={() => setPaymentProcessedAll(false)}
          />
          <View>
            {paymentListData?.length > 0 && !isFetching && (
              <Grid style={[styles.head, { gap: 20 }]} size={30}>
                {tableHeadData.map((item) => (
                  <Col size={item.size} style={styles.horizontal} key={item.title}>
                    <Text numberOfLines={3} style={styles.textHead}>
                      {item.title}
                    </Text>
                    {item.hasIcon && (
                      <Image
                        onClick={() => handleSort(item)}
                        style={item?.order ? styles.iconTableHeadDESC : styles.iconTableHeadASC}
                        source={require(`@assets/icons/iconDropdown.svg`)}
                      />
                    )}
                  </Col>
                ))}
              </Grid>
            )}
          </View>
        </View>
        <ScrollView showsVerticalScrollIndicator={false}>
          {isFetching ? (
            <Loading style={{ height: 'calc(100vh - 246px)' }} />
          ) : paymentListData?.length > 0 ? (
            paymentListData?.map((item, index, { length }) => {
              return (
                <Pressable key={index} style={({ hovered }) => [hovered && styles.rowHovered]}>
                  <Grid
                    style={[styles.headBody, { gap: 20, borderBottomWidth: index + 1 === length ? 0 : 1 }]}
                    size={30}
                  >
                    <Col size={2}>
                      <View style={styles.horizontal}>
                        <Image style={styles.iconTableRow} source={item?.itemSymbol} />
                        <Text style={styles.text} numberOfLines={3}>
                          {item?.displayId}
                        </Text>
                      </View>
                    </Col>
                    <Col size={2}>
                      <Text style={styles.text} numberOfLines={3}>
                        {item?.displayTransactionAt}
                      </Text>
                    </Col>
                    <Col size={1}>
                      <Text style={styles.text} numberOfLines={3}>
                        {item?.merchantName}
                      </Text>
                    </Col>
                    <Col size={2}>
                      <Text style={styles.text} numberOfLines={3}>
                        <Text style={styles.text} numberOfLines={3}>
                          {item?.amount ? `¥ ${formatNumber(item.amount)}` : ''}
                        </Text>
                      </Text>
                    </Col>
                    <Col size={2}>
                      <Text style={styles.text} numberOfLines={3}>
                        {item?.bankCode}
                      </Text>
                    </Col>
                    <Col size={2}>
                      <Text style={styles.text} numberOfLines={3}>
                        {item?.branchCode}
                      </Text>
                    </Col>
                    <Col size={1}>
                      <Text style={styles.text} numberOfLines={3}>
                        {/* {converAccountType(item?.accountType)} */}
                        {item?.accountType}
                      </Text>
                    </Col>
                    <Col size={2}>
                      <Text style={styles.text} numberOfLines={3}>
                        {item?.accountNumber}
                      </Text>
                    </Col>
                    <Col size={2}>
                      <Text style={styles.text} numberOfLines={3}>
                        {item?.accountHolderName}
                      </Text>
                    </Col>
                    <Col size={3}>
                      <View style={styles.checkDate}>
                        <Text style={styles.text} numberOfLines={3}>
                          {formatDateTime(item?.confirmedAt)}
                        </Text>
                        <View style={styles.checkboxItem}>
                          <JCheckbox
                            checkValueTable={true}
                            onValueChange={() => toggleCheckbox(item, index)}
                            value={item.pickup ?? false}
                          />
                        </View>
                      </View>
                    </Col>
                  </Grid>
                </Pressable>
              );
            })
          ) : (
            <Center w="100%" h={Dimensions.get('window').height - 246}>
              <Text>{t('pages_PaymentManager_PaymentComplete.haveNoPaymentComplete')}</Text>
            </Center>
          )}
          <View>
            {params.limit < PaymentComplete?.pageInfo?.totalCount && !isFetching && (
              <Pagination
                count={Math.ceil(PaymentComplete?.pageInfo?.totalCount / params.limit)}
                params={params}
                setParams={setParams}
              />
            )}
          </View>
        </ScrollView>
      </View>
      <ModalConfirmPayment
        iconName="iconDanger.svg"
        titleModal={t('pages_PaymentManager_PaymentComplete.modalConfirmPaymentText')}
        heightIcon={40}
        widthIcon={49}
        isModalVisible={isModalConfirm}
        setModalVisible={setIsModalConfirm}
        countChecked={listPaymentChecked?.length}
        onPress={toggleModalComplete}
        isProcessing={isProcessing}
      />
      <ModalComplete
        isModalVisible={isModalComplete}
        setModalVisible={setIsModalComplete}
        iconName={'iconProcess.svg'}
        heightIcon={20}
        widthIcon={55}
        textContent={t('pages_PaymentManager_PaymentComplete.modalCompleteText')}
      />
      <ModalAlert
        isModalVisible={modalAlert}
        setModalVisible={setModalAlert}
        icon="iconDanger.svg"
        iconWidth={42}
        iconHeight={35}
        modalText={t('errorMessages.notEnoughPoint')}
      />
    </>
  );
};

export default withNamespaces()(PaymentComplete);

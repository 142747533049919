import React, { useEffect, useState } from 'react';
import { View, Image, Pressable } from 'react-native';
import { Col, Grid } from 'react-native-easy-grid';
import { Link, useHistory } from 'react-router-dom';
import FileManagerLayout from '../partials/FileManagerLayout';
import { withNamespaces } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';

import styles from '../partials/styles';
import { JText as Text } from '@components/common/JText';
import { TEXT_COLOR_GREEN } from '@assets/style/styleDefault';
import ModalSMS from '../partials/ModalSMS';
import ModalMail from '../partials/ModalMail';
import { API_PATH, ROLE_USER } from '@helper/constant';
import { checkPermission } from '@helper/commonFunction';
import { get } from '@utils/RequestLevica';
import ModalAlert from '@components/Modals/ModalAlert';

const OtherNotifications = ({ t }) => {
  const OtherNotificationsDefault = [
    {
      id: '01-01',
      title: t('pages_FileManager_OtherNotifications.smsTitle_01'),
      status: t('pages_FileManager_OtherNotifications.notSet'),
      icon: 'iconTXT.svg',
      iconAdd: 'iconAddTXT.svg',
      type: t('pages_FileManager_OtherNotifications.smsType_01'),
      name: 'smsRegistration_01',
    },
    {
      id: '01-02',
      title: t('pages_FileManager_OtherNotifications.mailTitle_02'),
      status: t('pages_FileManager_OtherNotifications.notSet'),
      icon: 'iconMail.svg',
      iconAdd: 'iconAddMail.svg',
      type: t('pages_FileManager_OtherNotifications.mailType_02'),
      name: 'emailRegistration_02',
    },
    // {
    //   id: '01-03',
    //   title: '残高失効通知メール',
    //   status: '（未設定）',
    //   icon: 'iconMail.svg',
    //   iconAdd: 'iconAddMail.svg',
    //   type: 'メールテンプレート追加',
    // },
    // {
    //   id: '01-04',
    //   title: 'ギフト新規発行通知メール',
    //   status: '（未設定）',
    //   icon: 'iconMail.svg',
    //   iconAdd: 'iconAddMail.svg',
    //   type: 'メールテンプレート追加',
    // },
    // {
    //   id: '01-05',
    //   title: 'ポイント新規発行通知メール',
    //   status: '（未設定）',
    //   icon: 'iconMail.svg',
    //   iconAdd: 'iconAddMail.svg',
    //   type: 'メールテンプレート追加',
    // },
    // {
    //   id: '01-06',
    //   title: '重要なアップデート通知メール',
    //   status: '（未設定）',
    //   icon: 'iconMail.svg',
    //   iconAdd: 'iconAddMail.svg',
    //   type: 'メールテンプレート追加',
    // },
  ];
  const [otherNotifications, SetOtherNotifications] = useState(OtherNotificationsDefault);
  const [isModalSMS, setModalSMS] = useState(false);
  const [isModalEmail, setModalEmail] = useState(false);

  const [modalAlert, setModalAlert] = useState(false);
  const history = useHistory();
  const [documentSelect, setDocumentSelect] = useState([]);
  const userInfo = useSelector((store) => store?.merchantUserInfo);

  const openModal = (item) => {
    setDocumentSelect(item);
    item.id === '01-01' ? setModalSMS(true) : setModalEmail(true);
  };

  const { remove } = useQuery({
    queryKey: ['otherNotification'],
    queryFn: async ({ signal }) => {
      const data = await get(API_PATH.TEMPLATES_STATISTICS, { signal });
      const newData = otherNotifications.map((item) => {
        if (data[`${item.name}`] == null) {
          return { ...item, status: t('pages_FileManager_OtherNotifications.notSet') };
        } else {
          return { ...item, status: data[`${item.name}`] };
        }
      });
      SetOtherNotifications(newData);
    },
    refetchOnWindowFocus: false,
  });
  useEffect(() => remove, [remove]);
  const updateNotification = ({ id, status }) => {
    // if (status == t('pages_FileManager_OtherNotifications.notSet')) return;
    history.push(
      id === '01-01'
        ? '/file-manager/other-notifications/sms-notifications/update'
        : '/file-manager/other-notifications/mail-notifications/update'
    );
  };

  return (
    <>
      <FileManagerLayout>
        <View style={styles.container}>
          {otherNotifications?.map((item, index, { length }) => (
            <Pressable style={({ hovered }) => [hovered ? styles.itemHovered : styles.px10]} key={item.id}>
              <Grid style={[styles.item, { borderBottomWidth: index + 1 === length ? 0 : 1 }]}>
                <Col size={3} style={styles.firstCol} onPress={() => openModal(item)}>
                  <Image style={styles.iconImgRight} source={require(`@assets/icons/arrowRight.svg`)} />
                  <Image
                    style={item?.icon === 'iconTXT.svg' ? styles.iconImgTxt : styles.iconImgMail}
                    source={require(`../../../assets/icons/${item?.icon}`)}
                  />
                  <Text style={styles.title}>{`${item.id}  ${item.title}`}</Text>
                </Col>
                <Col size={4} style={styles.warperStatus} onPress={() => updateNotification(item)}>
                  <Text style={styles.status} numberOfLines={4}>
                    {item.status}
                  </Text>
                </Col>
                <Col size={3} style={styles.center}>
                  <Image
                    style={item.id === '01-01' ? styles.iconAddTxt : styles.iconAddMail}
                    source={require(`../../../assets/icons/${item?.iconAdd}`)}
                  />
                  <Link
                    to={
                      checkPermission(ROLE_USER.DOCUMENT_MANAGEMENT, userInfo)
                        ? item.id === '01-01'
                          ? '/file-manager/other-notifications/sms-notifications/update'
                          : '/file-manager/other-notifications/mail-notifications/update'
                        : '#'
                    }
                    style={{ color: TEXT_COLOR_GREEN }}
                  >
                    {item.type}
                  </Link>
                </Col>
              </Grid>
            </Pressable>
          ))}
        </View>
      </FileManagerLayout>
      {isModalSMS && (
        <ModalSMS
          isModal={isModalSMS}
          setIsModal={setModalSMS}
          itemSelect={documentSelect}
          setModalAlert={setModalAlert}
        />
      )}
      {isModalEmail && (
        <ModalMail
          isModal={isModalEmail}
          setIsModal={setModalEmail}
          itemSelect={documentSelect}
          setModalAlert={setModalAlert}
        />
      )}
      <ModalAlert
        isModalVisible={modalAlert}
        setModalVisible={setModalAlert}
        icon="success-red.svg"
        iconWidth={20}
        iconHeight={20}
        modalText={t('pages_FileManager_partials_modalDocument.modalAlertText')}
      />
    </>
  );
};
export default withNamespaces()(OtherNotifications);

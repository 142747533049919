import { StyleSheet } from 'react-native';
import { BG_COLOR, BG_FORM_DEFAULT, COLOR_TEXT_DEFAULT, LINE_COLOR } from '@assets/style/styleDefault';

const styles = StyleSheet.create({
  header: {
    height: '100%',
    flex: 1,
    backgroundColor: BG_COLOR,
    position: 'relative',
  },
  positionSticky: {
    position: 'sticky',
    top: 0,
    backgroundColor: BG_COLOR,
    zIndex: 10,
  },
  formSubmit: {
    backgroundColor: BG_FORM_DEFAULT,
    height: 'calc(100vh - 80px)',
    minHeight: 700,
    minWidth: 1000,
  },
  flexDirection: {
    display: 'flex',
    flexDirection: 'row',
  },
  titleInput: {
    display: 'flex',
    alignItems: 'center',
    height: 42,
  },
  whiteColor: {
    color: COLOR_TEXT_DEFAULT,
  },
  redColor: {
    color: 'red',
  },
  borderForm: {
    paddingTop: 10,
    paddingLeft: 20,
    paddingRight: '10vw',
    paddingBottom: 10,
  },
  bulkhead: {
    borderColor: LINE_COLOR,
    borderWidth: '0.1px',
    marginBottom: 16,
  },
  minHeight: {
    minHeight: 'unset',
  },
  btnSubmit: {
    paddingTop: 200,
  },
  minHeight: {
    minHeight: 400,
  },
  input: {
    alignItems: 'center',
  },
  textArea: {
    paddingTop: 12,
    marginBottom: 30,
  },
});

export default styles;

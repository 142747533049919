import React from 'react';
import { StyleSheet, View, Image, TouchableOpacity } from 'react-native';
import { withNamespaces } from 'react-i18next';
import { Grid, Col } from 'react-native-easy-grid';
import { useHistory } from 'react-router-dom';

import {
  FONT_SIZE_HEADER,
  COLOR_TEXT_DEFAULT,
  FONT_SIZE_DEFAULT,
  BG_HOVER,
  BORDER_TABLE_GREY,
  TEXT_COLOR_GREEN,
} from '@assets/style/styleDefault';
import { JText as Text } from '../common/JText';

const HeaderToken = ({
  isDirty,
  linkBtnBack,
  textBtnBack,
  linkIcon,
  textHeader,
  codeToken,
  nameToken,
  scope1,
  scope2,
  scopeThreeDot,
  widthIconTitle,
  heightIconTitle,
  t,
  handleModalBack,
}) => {
  const history = useHistory();
  const openModalBack = () => {
    if (isDirty) {
      handleModalBack(true);
    } else {
      history.goBack();
    }
  };

  return (
    <View style={styles.wrapperHeader}>
      <Grid style={styles.headerToken}>
        <Col size={scopeThreeDot ? 7 : 5} style={styles.flexRow}>
          <TouchableOpacity style={[styles.flexRow, styles.cursorPointer]} onPress={() => openModalBack()}>
            <Image style={styles.buttonBack} source={require(`../../assets/icons/${linkBtnBack}`)} />
            <Text style={styles.textBack}>{textBtnBack}</Text>
          </TouchableOpacity>
          <Image
            style={[
              styles.iconHeader,
              widthIconTitle && { width: widthIconTitle },
              heightIconTitle && { height: heightIconTitle },
            ]}
            source={require(`../../assets/icons/${linkIcon}`)}
          />
          <Text style={styles.textHeader}>{textHeader}</Text>
          {codeToken && <Text style={styles.codeToken}>{codeToken}</Text>}
          {nameToken && <Text style={styles.nameToken}>{nameToken}</Text>}
        </Col>

        <Col size={4} style={[styles.flexRow, styles.spaceBetween]}>
          {scope1 && <View style={styles.spacing_btn}>{scope1}</View>}
          {scope2 && <View style={styles.spacing_btn}>{scope2}</View>}
        </Col>
      </Grid>
    </View>
  );
};

export default withNamespaces()(HeaderToken);

const styles = StyleSheet.create({
  cursorPointer: {
    cursor: 'pointer',
  },
  flexRow: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  wrapperHeader: {
    height: 80,
    zIndex: 3,
  },
  headerToken: {
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  buttonBack: {
    height: 15,
    width: 15,
  },
  textBack: {
    fontSize: FONT_SIZE_DEFAULT,
    color: TEXT_COLOR_GREEN,
    marginLeft: 10,
    marginRight: '2rem',
    fontWeight: 'bold',
  },
  iconHeader: {
    height: 24,
    width: 24,
  },
  textHeader: {
    fontSize: FONT_SIZE_HEADER,
    color: COLOR_TEXT_DEFAULT,
    marginLeft: 10,
    fontWeight: 'bold',
  },
  codeToken: {
    marginLeft: '2rem',
    color: COLOR_TEXT_DEFAULT,
    fontSize: FONT_SIZE_HEADER,
    fontWeight: 'bold',
  },
  nameToken: {
    marginLeft: '1rem',
    color: COLOR_TEXT_DEFAULT,
    fontSize: FONT_SIZE_HEADER,
  },
  spacing_btn: {
    marginRight: 5,
    marginLeft: 5,
  },
  menuScopeThreeDot: {
    zIndex: 10,
    borderRadius: 50,
    width: 40,
    height: 40,
    marginHorizontal: 10,
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  text: {
    fontSize: FONT_SIZE_DEFAULT,
    color: 'white',
    padding: 10,
    paddingLeft: 20,
  },
  menuScopeThreeDotContent: {
    borderRadius: 8,
    position: 'absolute',
    top: -5,
    right: -2,
    paddingVertical: 10,
    display: 'none',
    width: 280,
    backgroundColor: '#2e2d2d',
  },
  //modal change language
  wrapperModal: {
    maxWidth: 900,
    minWidth: 600,
  },
  mainClose: {
    maxWidth: 200,
    marginRight: 'auto',
    paddingLeft: 20,
  },
  contentClose: {
    with: 20,
    height: 40,
    backgroundColor: 'transparent',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },

  iconClose: {
    width: 15,
    height: 15,
    marginRight: 10,
  },
  maxWidth700: {
    backgroundColor: '#fff',
    borderRadius: 10,
    padding: 30,
  },

  warperItem: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: 550,
    borderBottomColor: BORDER_TABLE_GREY,
    borderBottomWidth: 1,
    paddingVertical: 15,
    paddingLeft: 10,
  },

  iconLanguage: {
    width: 26,
    height: 18,
    marginRight: 15,
  },
  itemHovered: {
    backgroundColor: BG_HOVER,
  },
  spaceBetween: {
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
  },
});

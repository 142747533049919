import React from 'react';
import { View, StyleSheet, Image } from 'react-native';
import Modal from 'react-native-modal';
import { Link } from 'react-router-dom';
import { withNamespaces } from 'react-i18next';

import { FONT_SIZE_HEADER, BORDER_TABLE_GREY } from '@assets/style/styleDefault';
import { JText as Text } from '@components/common/JText';
import ModalBackDrop from '@components/Modals/ModalBackDrop';

const ModalSave = (props) => {
  const { isModalVisible, setModalVisible, userName, t } = props;
  return (
    <View>
      <Modal
        animationIn="fadeIn"
        isVisible={isModalVisible}
        style={{ alignItems: 'center' }}
        customBackdrop={<ModalBackDrop onPress={() => setModalVisible(false)} />}
      >
        <Link to="/admin" style={{ textDecoration: 'none' }}>
          <View style={styles.wrapperModal}>
            <View style={styles.maxWidth700}>
              <View style={styles.header}>
                <Image source={require(`@assets/icons/yellowStatus.svg`)} style={styles.statusIcon} alt="statusIcon" />
                <Text style={styles.textHeader}>{t('pages_Setting_MasterChange.modalSaveText1')}</Text>
              </View>
              <View style={styles.body}>
                <Text style={styles.textHeader}>{`${userName} ${t('pages_Setting_MasterChange.modalSaveText2')}`}</Text>
              </View>
            </View>
          </View>
        </Link>
      </Modal>
    </View>
  );
};

export default withNamespaces()(ModalSave);

const styles = StyleSheet.create({
  //common

  textHeader: {
    color: 'black',
    fontSize: FONT_SIZE_HEADER,
  },
  wrapperModal: {
    maxWidth: 900,
    minWidth: 600,
  },

  maxWidth700: {
    backgroundColor: '#fff',
    borderRadius: 10,
    paddingVertical: 30,
  },
  header: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    borderBottomColor: BORDER_TABLE_GREY,
    borderBottomWidth: 1,
    paddingBottom: 20,
  },
  statusIcon: {
    width: 10,
    height: 10,
    marginBottom: 2,
    marginRight: 5,
  },
  body: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 40,
  },
});

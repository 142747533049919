import React from 'react';
import { View } from 'react-native';
import styles from '../styles';
import { JText as Text } from '@components/common/JText';

const OverviewTable = ({ content, value, lastItem }) => {
  return (
    <View style={lastItem ? styles.OverviewTableNoBorderBottom : styles.OverviewTableBorderBottom}>
      <Text style={styles.defaultTextColor}>{content}</Text>
      <Text style={styles.defaultTextColor}>{value}</Text>
    </View>
  );
};

export default OverviewTable;

import { Cookies } from 'react-cookie';
import moment from 'moment';
import store from '@redux/store';

import { API_PATH, HTTP_STATUS, STORAGEKEY } from '@helper/constant';
import { post } from './RequestLevica';
import { merchantUserInfo } from '@redux/slices/authSlice';

const cookies = new Cookies();

export const setCookie = (key, value, options) => {
  cookies.set(key, value, { ...options, path: '/' });
};

export const getCookie = (key) => {
  return cookies.get(key);
};

export const logout = () => {
  cookies.remove(STORAGEKEY.ACCESS_TOKEN, { path: '/' });
  cookies.remove(STORAGEKEY.REFRESH_TOKEN, { path: '/' });
};

export const reLoadPage = () => {
  window.location.reload();
};
export const refreshToken = async () => {
  const refreshToken = getCookie(STORAGEKEY.REFRESH_TOKEN);
  if (!refreshToken) {
    logout();
    return;
  } else {
    cookies.remove(STORAGEKEY.ACCESS_TOKEN, { path: '/' });
  }
  try {
    const { accessToken, refreshToken } = await post(API_PATH.REFRESH_TOKEN, {
      token: getCookie(STORAGEKEY.REFRESH_TOKEN),
    });
    setCookie(STORAGEKEY.ACCESS_TOKEN, accessToken?.value, { expires: moment(accessToken?.expiry).toDate() });
    setCookie(STORAGEKEY.REFRESH_TOKEN, refreshToken?.value, { expires: moment(refreshToken?.expiry).toDate() });
    store.dispatch(merchantUserInfo());
    // reLoadPage();
  } catch (error) {
    if (error?.response?.status === HTTP_STATUS.UNAUTHORIZED || error?.response?.data?.code === '1001') {
      logout();
      reLoadPage();
    }
  }
};

import React, { useState } from 'react';
import { View, Image, ScrollView, TouchableWithoutFeedback } from 'react-native';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import * as documentPicker from 'expo-document-picker';
import { useHistory } from 'react-router-dom';
import { Col } from 'react-native-easy-grid';
import { LinearGradient } from 'expo-linear-gradient';

import JButton from '@components/common/JButton';
import JInput from '@components/common/JInput';
import { JText as Text } from '@components/common/JText';
import HeaderCommunity from '@components/Headers/HeaderCommunity';
import ModalBack from '@components/Modals/ModalBack';
import ModalAlert from '@components/Modals/ModalAlert';
import DropDownV2 from '@components/common/DropDownV2';
import { COLOR_TEXT_DEFAULT, BG_COLOR } from '@assets/style/styleDefault';
import styles from '../AddPoint/styles';
import ModalSendSuccess from '@components/Modals/ModalSendSuccess';
import { withNamespaces } from 'react-i18next';

const defaultValues = {
  issuerName: '',
  pointIcon: '',
  giftName: '',
  giftTitle: '',
  giftDescription: '',
};

const AddGift = ({ t }) => {
  const history = useHistory();
  const [isModal, setIsModal] = useState(false);
  const [isModalSave, setIsModalSave] = useState(false);
  const [isModalBack, setIsModalBack] = useState(false);
  const [pickImg, setPickImg] = useState('');

  const schema = Yup.object().shape({
    // issuerName: Yup.string().required(TXT_REQUIRED),
    // pointIcon: Yup.string().required(TXT_REQUIRED),
    // giftName: Yup.string().required(TXT_REQUIRED).max(100, TXT_MAX_100),
    // giftTitle: Yup.string().required(TXT_REQUIRED).max(100, TXT_MAX_100),
    // giftDescription: Yup.string().required(TXT_REQUIRED).max(100, TXT_MAX_100),
    // holderLimit: Yup.number().typeError(TXT_REQUIRED),
    issuerName: Yup.string(),
    pointIcon: Yup.string(),
    giftName: Yup.string().max(100, t('errorMessages.TXT_MAX_100')),
    giftTitle: Yup.string().max(100, t('errorMessages.TXT_MAX_100')),
    giftDescription: Yup.string(),
  });

  const {
    control,
    handleSubmit,
    formState: { errors, isDirty, isValid },
    reset,
    setValue,
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
    mode: 'onChange',
  });

  const pickUpImg = async () => {
    const img = await documentPicker.getDocumentAsync({
      type: 'image/png',
    });
    setPickImg(img.name);
    setValue('pointIcon', img.name);
  };

  const openModalBack = () => {
    if (isDirty || isValid) {
      setIsModalBack(!isModalBack);
    } else {
      history.push('/tokens');
    }
  };

  const otherModal = () => {
    setIsModalBack(false);
    setIsModal(true);
  };

  const onSubmit = (data) => {
    setIsModal(true);
    console.log(data);
    const newData = {
      ...data,
      uploadFile: pickImg,
    };
  };

  return (
    <>
      <ScrollView showsVerticalScrollIndicator={false} showsHorizontalScrollIndicator={false}>
        <View style={styles.positionSticky}>
          <View style={styles.headerCommunity}>
            <HeaderCommunity
              textHeaderCommon={t('pages_Token_AddGift.textHeaderCommon')}
              iconBack="iconX.svg"
              iconHeader="point.svg"
              textBack={t('commonText.close')}
              haveModal
              handleBack={openModalBack}
              isDirty={isDirty}
            />
          </View>
        </View>

        <View style={styles.content}>
          <View style={styles.headerContent}>
            <Image style={styles.iconHeaderStyle} source={require(`@assets/icons/iconSetting2.svg`)} />
            <Text style={styles.textHeader}>{t('pages_Token_AddGift.initialSettingPls')}</Text>
          </View>
          <View style={[styles.inputGroup, { zIndex: 9 }]}>
            <Col size={3} style={styles.label}>
              <Text style={styles.textLabel}>{t('pages_Token_AddGift.issuerName')}</Text>
            </Col>
            <Col size={9}>
              <DropDownV2
                setValue={setValue}
                name="issuerName"
                width="80%"
                btnColor={BG_COLOR}
                text={t('pages_Token_AddGift.issuerNameNote')}
                dataOption={[
                  { option: 'demo1', value: '001' },
                  { option: 'demo2', value: '002' },
                  { option: 'demo3', value: '003' },
                  { option: 'demo4', value: '004' },
                ]}
                changeColor="#757575"
              />
            </Col>
          </View>

          <View style={styles.inputGroup}>
            <Col size={3} style={styles.label}>
              <Text style={styles.textLabel}>{t('pages_Token_AddGift.giftIcon')}</Text>
            </Col>
            <Col size={9}>
              <TouchableWithoutFeedback onPress={() => pickUpImg()}>
                <View style={styles.selectImg}>
                  <Text style={[styles.textSelect, pickImg && { color: COLOR_TEXT_DEFAULT }]}>
                    {pickImg ? pickImg : t('pages_Token_AddGift.selectAnImage')}
                  </Text>
                  <Image style={styles.subfixIcon} source={require(`@assets/icons/iconView.svg`)} />
                </View>
              </TouchableWithoutFeedback>
            </Col>
          </View>
          <View style={styles.inputGroup}>
            <Col size={3} style={styles.label}>
              <Text style={styles.textLabel}>{t('pages_Token_AddGift.giftName')}</Text>
            </Col>
            <Col size={9}>
              <JInput
                placeholder={t('pages_Token_AddGift.officialNameNote')}
                width="80%"
                subfix="iconEdit.svg"
                widthSubfix={18}
                heightSubfix={18}
                control={control}
                name="giftName"
                backgroundColor={BG_COLOR}
                borderError={errors.giftName ? '#FF0000' : '#5C5C5C'}
              />
            </Col>
          </View>

          <View style={styles.inputGroup}>
            <Col size={3} style={styles.label}>
              <Text style={styles.textLabel}>{t('pages_Token_AddGift.giftTitle')}</Text>
            </Col>
            <Col size={9}>
              <JInput
                placeholder={t('pages_Token_AddGift.giftTitleNote')}
                width="80%"
                subfix="iconEdit.svg"
                widthSubfix={18}
                heightSubfix={18}
                control={control}
                name="giftTitle"
                backgroundColor={BG_COLOR}
                borderError={errors.giftTitle ? '#FF0000' : '#5C5C5C'}
              />
            </Col>
          </View>
          <View style={[styles.inputGroup, { alignItems: 'flex-start' }]}>
            <Col size={3} style={[styles.label, { paddingTop: 20 }]}>
              <Text style={styles.textLabel}>{t('pages_Token_AddGift.giftDescription')}</Text>
            </Col>
            <Col size={9}>
              <JInput
                placeholder={t('pages_Token_AddGift.giftDescriptionNote')}
                width="80%"
                subfix="iconEdit.svg"
                widthSubfix={18}
                heightSubfix={18}
                control={control}
                name="giftDescription"
                backgroundColor={BG_COLOR}
                borderError={errors.giftDescription ? '#FF0000' : '#5C5C5C'}
                multiline
                numberOfMulti={5}
                heightMulti={150}
              />
            </Col>
          </View>

          <View style={styles.buttonSubmit}>
            <LinearGradient colors={['#54DBB2', '#04C48B']} style={{ borderRadius: 5 }}>
              <JButton
                text={t('pages_Token_AddGift.issue')}
                width={200}
                textColor="#fff"
                onPressAction={() => handleSubmit(onSubmit)()}
                // disabled={!isDirty}
              />
            </LinearGradient>
          </View>
        </View>
        <ModalAlert
          isModalVisible={isModalSave}
          setModalVisible={setIsModalSave}
          modalText={t('pages_Token_AddGift.modalAlertText')}
          iconWidth={16}
          iconHeight={12.5}
          icon="successGreen.svg"
        />
        <ModalAlert
          isModalVisible={isModal}
          setModalVisible={setIsModal}
          modalText={t('pages_Token_AddGift.modalAlertText')}
          iconWidth={16}
          iconHeight={12.5}
          icon="successGreen.svg"
          linkPage="/tokens"
        />
        <ModalSendSuccess
          textHeader={t('pages_Token_AddGift.modalAlertSuccess')}
          content={t('pages_Token_AddGift.modalAlertSuccessNote')}
          isModalVisible={isModal}
          setModalVisible={setIsModal}
          linkBack="/tokens"
        />
        <ModalBack isModal={isModalBack} setIsModal={setIsModalBack} otherModal={otherModal} />
      </ScrollView>
    </>
  );
};

export default withNamespaces()(AddGift);

import React, { useState } from 'react';
import { Image, Pressable, ScrollView, StyleSheet, View } from 'react-native';
import { withNamespaces } from 'react-i18next';
import { Center } from 'native-base';

import { FONT_FAMILY_HEADER, TEXT_COLOR_GREEN, BG_HOVER, LINE_COLOR } from '@assets/style/styleDefault';
import { JText as Text } from '@components/common/JText';

const walletData = [
  // {
  //   icon: 'iconWorld.svg',
  //   name: 'LEVICA',
  //   balance: '0',
  //   currency: 'LEVICA',
  // },
];

const WalletInfo = ({ t }) => {
  const [wallet, setWallet] = useState(walletData);

  return (
    <View style={styles.container}>
      <View style={styles.walletHeader}>
        <View style={styles.flex}>
          <Image source={require('@assets/icons/iconWallet.svg')} style={styles.image} />
          <Text style={styles.textHeading}>WALLET</Text>
        </View>
        {/* <View style={styles.flex}>
          <Text style={[styles.textLink, { fontWeight: 'bold' }]}>
            {t('pages_AccountCompany_CreateAccount.showAll')}
          </Text>
        </View> */}
      </View>

      <View
        style={{
          height: 'calc(100vh - 560px)',
          marginTop: 10,
        }}
      >
        {wallet.length === 0 ? (
          <Center pt={20}>
            <Text style={styles.textGrey}>{t('pages_AccountShop_CreateAccount.noWallet')}</Text>
          </Center>
        ) : (
          <ScrollView showsVerticalScrollIndicator={false}>
            {wallet.map((item, index) => (
              <Pressable style={({ hovered }) => [hovered && styles.buttonHovered]} key={index}>
                <View style={styles.walletItem} key={index}>
                  <View style={[styles.flex, { gap: 10 }]}>
                    {item?.icon ? (
                      <Image style={styles.walletIcon} source={{ uri: item?.icon }} />
                    ) : (
                      <Image style={styles.walletIcon} source={require('@assets/icons/earthIcon.svg')} />
                    )}

                    <Text style={styles.textWhite}>{item.name}</Text>
                  </View>
                  <View style={[styles.flex, { gap: 10 }]}>
                    <Text style={styles.textWhite}>{item.balance}</Text>
                    <Text style={styles.textWhite}>{item.currency}</Text>
                  </View>
                </View>
              </Pressable>
            ))}
            {/* <View style={{ alignItems: 'center', paddingBottom: 10 }}>
              {wallet?.length < 6 ? null : (
                <Text style={styles.textLinkFooter}>{t('pages_AccountShop_CreateAccount.showAll')}</Text>
              )}
            </View> */}
          </ScrollView>
        )}
      </View>
    </View>
  );
};

export default withNamespaces()(WalletInfo);

const styles = StyleSheet.create({
  container: {
    marginTop: 15,
    marginBottom: 50,
    borderRadius: 8,
    border: `1px solid ${LINE_COLOR}`,
  },
  image: {
    width: 36,
    height: 36,
    marginBottom: 3,
  },
  walletHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 30,
    padding: 30,
    paddingVertical: 10,
    paddingTop: 30,
  },
  flex: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },

  textLink: {
    textDecorationStyle: 'none',
    color: TEXT_COLOR_GREEN,
    borderBottomColor: TEXT_COLOR_GREEN,
    borderBottomWidth: 1,
    marginLeft: 20,
  },
  textHeading: {
    fontSize: 28,
    marginLeft: 10,
    fontWeight: 500,
    fontFamily: FONT_FAMILY_HEADER,
  },
  listWallet: {
    height: 450,
    // paddingVertical: 15,
    marginTop: 10,
  },
  textLinkFooter: {
    color: TEXT_COLOR_GREEN,
    borderBottomColor: TEXT_COLOR_GREEN,
    borderBottomWidth: 1,
    textAlign: 'center',
    width: 'fit-content',
  },

  walletIcon: {
    width: 25,
    height: 25,
  },
  walletItem: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 15,
    paddingHorizontal: 30,
  },
  buttonHovered: {
    backgroundColor: BG_HOVER,
  },
});

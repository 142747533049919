import React, { useState } from 'react';
import { View, StyleSheet, TouchableOpacity, Image, ScrollView } from 'react-native';
import Modal from 'react-native-modal';
import { withNamespaces } from 'react-i18next';
import { Col } from 'react-native-easy-grid';

import { BORDER_GREY_LIGHT, FONT_FAMILY_HEADER, TEXT_COLOR_WHITE } from '@assets/style/styleDefault';
import { JText as Text } from '@components/common/JText';
import ModalBackDrop from '@components/Modals/ModalBackDrop';
import { formatDateTime } from '@helper/formatTypes';

const ModalContactStatus = ({ isVisible, setModalVisible, payload, t }) => {
  return (
    <Modal
      isVisible={isVisible}
      animationIn="fadeIn"
      style={{ alignItems: 'center' }}
      customBackdrop={<ModalBackDrop onPress={() => setModalVisible(false)} />}
    >
      <View style={styles.container}>
        <View style={styles.closeContainer}>
          <TouchableOpacity onPress={() => setModalVisible(false)} style={styles.btnBack}>
            <Image style={styles.backIcon} source={require(`@assets/icons/close-white.svg`)} />
            <Text style={styles.textClose}>{t('commonText.close')}</Text>
          </TouchableOpacity>
        </View>
        <View style={styles.content}>
          <View style={styles.modalHeader}>
            <Text style={styles.headerTitle}>TRANSACTIONS DETAILS</Text>
          </View>
          <ScrollView showsVerticalScrollIndicator={false}>
            <View style={styles.listItem}>
              <Col size={4}>
                <Text style={styles.textRight}>TXHash</Text>
              </Col>
              <Col size={8}>
                <Text style={styles.textContent} numberOfLines={3}>
                  {payload.txHash}
                </Text>
              </Col>
            </View>

            <View style={styles.listItem}>
              <Col size={4}>
                <Text style={styles.textRight}>Blockchain</Text>
              </Col>
              <Col size={8}>
                <Text style={styles.textContent}>{'XXXXXX Chain'}</Text>
              </Col>
            </View>

            <View style={styles.listItem}>
              <Col size={4}>
                <Text style={styles.textRight}>Symbol / Token name</Text>
              </Col>
              <Col size={8}>
                <Text style={styles.textContent}>{payload.name}</Text>
              </Col>
            </View>

            <View style={styles.listItem}>
              <Col size={4}>
                <Text style={styles.textRight}>Amount</Text>
              </Col>
              <Col size={8}>
                <Text style={styles.textContent}>{`${payload.amount} ${payload.name}`}</Text>
              </Col>
            </View>

            <View style={styles.listItem}>
              <Col size={4}>
                <Text style={styles.textRight}>Timestamp</Text>
              </Col>
              <Col size={8}>
                <Text style={styles.textContent}>{formatDateTime(payload?.createdAt)}</Text>
              </Col>
            </View>

            <View style={styles.listItem}>
              <Col size={4}>
                <Text style={styles.textRight}>From</Text>
              </Col>
              <Col size={8}>
                <Text style={styles.textContent}>{payload.fromAddress}</Text>
              </Col>
            </View>

            <View style={[styles.listItem, styles.borderTopNone]}>
              <Col size={4}></Col>
              <Col size={8}>
                <Text style={styles.textContent}>{payload.fromUsername ?? ''}</Text>
              </Col>
            </View>

            <View style={styles.listItem}>
              <Col size={4}>
                <Text style={styles.textRight}>To</Text>
              </Col>
              <Col size={8}>
                <Text style={styles.textContent}>{payload.toAddress}</Text>
              </Col>
            </View>

            <View style={[styles.listItem, styles.borderTopNone, { paddingBottom: 50 }]}>
              <Col size={4}></Col>
              <Col size={8}>
                <Text style={[styles.textContent]}>{payload.toUsername ?? ''}</Text>
              </Col>
            </View>
          </ScrollView>
        </View>
      </View>
    </Modal>
  );
};

export default withNamespaces()(ModalContactStatus);

const styles = StyleSheet.create({
  listItem: {
    flexDirection: 'row',
    alignItems: 'center',
    borderTopWidth: 1,
    borderTopColor: BORDER_GREY_LIGHT,
    paddingVertical: 15,
  },
  borderTopNone: {
    borderTopWidth: 0,
  },
  textContent: {
    color: '#797979',
  },
  //main
  content: {
    maxWidth: '1100px',
    minWidth: 850,
    padding: 30,
    backgroundColor: '#fff',
  },
  modalHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    marginBottom: 20,
  },
  headerTitle: {
    fontSize: 30,
    fontFamily: FONT_FAMILY_HEADER,
    color: '#333333',
  },
  backIcon: {
    width: 16,
    height: 16,
    resizeMode: 'contain',
    margin: 10,
  },
  textClose: {
    color: TEXT_COLOR_WHITE,
  },
  btnBack: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  closeContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    paddingRight: 20,
  },
  textRight: {
    color: '#191919',
    paddingLeft: 10,
  },
});

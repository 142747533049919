const formatDisplayId = (id, ID_LENGTH = 6) => {
  if (!id) return '';
  let displayId = id.toString();
  const idLength = displayId.length;
  if (idLength >= ID_LENGTH) return displayId;

  for (let i = 0; i < ID_LENGTH - idLength; i++) {
    displayId = '0' + displayId;
  }
  return displayId;
};

export default formatDisplayId;

import { StyleSheet } from 'react-native';
import { BG_SIDEBAR, COLOR_TEXT_DEFAULT, LINE_COLOR } from '@assets/style/styleDefault';

const styles = StyleSheet.create({
  flexRow: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  headerInfo: {
    height: 80,
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 20,
  },
  iconSetting: {
    height: 25,
    width: 25,
    marginRight: 10,
  },
  textHeader: {
    color: COLOR_TEXT_DEFAULT,
    fontSize: 18,
  },
  content: {
    paddingHorizontal: 20,
  },
  textLabel: {
    color: COLOR_TEXT_DEFAULT,
  },
  rowContent: {
    paddingBottom: 15,
    borderBottomColor: '#5C5C5C',
    borderBottomWidth: 1,
    marginTop: 15,
    alignItems: 'center',
    width: '95%',
  },
  iconRight: {
    height: 15,
    width: 15,
  },
  rowBetween: {
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingBottom: 10,
    borderBottomColor: '#5C5C5C',
    borderBottomWidth: 1,
    marginTop: 15,
    paddingRight: 10,
    cursor: 'pointer',
  },
  colBetween: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingRight: 10,
    cursor: 'pointer',
  },
  wrapperAvatar: {
    backgroundColor: BG_SIDEBAR,
    padding: 20,
    borderRadius: 8,
    height: 'calc(100vh - 80px)',
    minHeight: 800,
  },
  headerAvatar: {
    flexDirection: 'row',
    alignItems: 'center',
    borderBottomColor: LINE_COLOR,
    borderBottomWidth: 1,
    paddingBottom: 20,
  },
  headerAvatarStatus: {
    flexDirection: 'row',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  status: {
    width: 10,
    height: 10,
    marginRight: 10,
    borderRadius: 100,
  },
  avatarContent: {
    marginTop: '2rem',
    marginBottom: '1rem',
  },
  imgAvatar: {
    height: 150,
    width: 150,
    marginRight: '3rem',
    borderRadius: 100,
  },
  textName: {
    color: COLOR_TEXT_DEFAULT,
    fontSize: 16,
  },
  iconNote: {
    height: 20,
    width: 20,
    marginRight: 10,
    marginBottom: 5,
  },
  infoUser: {
    marginTop: '1rem',
    marginBottom: '1rem',
  },
  rowInfo: {
    marginTop: 20,
  },
  iconLogout: {
    height: 15,
    width: 20,
    marginRight: 15,
  },
  wrapperLogout: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    height: 100,
    cursor: 'pointer',
  },
});

export default styles;

import React from 'react';
import * as DocumentPicker from 'expo-document-picker';
import { View, StyleSheet, Image as ImageNative, TouchableOpacity } from 'react-native';
import Modal from 'react-native-modal';
import { LinearGradient } from 'expo-linear-gradient';
import { withNamespaces } from 'react-i18next';

import JButton from '@components/common/JButton';
import { FONT_SIZE_DEFAULT, FONT_SIZE_HEADER, BORDER_TABLE_GREY } from '@assets/style/styleDefault';
import { JText as Text } from '@components/common/JText';
import DragFileFromLocal from './DragFileFromLocal';
import ModalBackDrop from '@components/Modals/ModalBackDrop';

const ModalChangeAvatar = (props) => {
  const {
    t,
    isModalVisible,
    setModalVisible,
    avatar,
    setUploadAvatar,
    setValue,
    setError,
    clearErrors,
    userInfo,
    trigger,
  } = props;

  const validateFileSize = (fileSize) =>
    fileSize > 10 * 1024 * 1024
      ? { type: 'custom', message: t('errorMessages.TXT_LIMIT_IMAGE_10MB'), isError: true }
      : { isError: false };

  const validateImgFile = (fileType) =>
    /^image\//i.test(fileType)
      ? { isError: false }
      : { type: 'custom', message: t('errorMessages.TEXT_ONLY_IMAGE'), isError: true };
  const checkFakeImage = (file) => {
    const img = new Image();
    img.onerror = function () {
      // The file is not an image
      setUploadAvatar(userInfo?.imageUrl);
      setError('icon', { type: 'custom', message: t('errorMessages.TEXT_ONLY_IMAGE'), isError: true });
      return;
    };
    img.src = URL.createObjectURL(file);
  };
  const uploadImage = async () => {
    try {
      let result = await DocumentPicker.getDocumentAsync({
        type: ['image/jpeg', 'image/png'],
      });
      setModalVisible(false);

      checkFakeImage(result.file);
      const validateImgFileResult = validateImgFile(result?.mimeType);
      if (validateImgFileResult.isError) return setError('icon', validateImgFileResult);
      const validateFizeSizeResult = validateFileSize(result?.size);
      if (validateFizeSizeResult.isError) return setError('icon', validateFizeSizeResult);

      clearErrors('icon');
      setValue('icon', result.file, { shouldDirty: true });
      setUploadAvatar(result.uri);
      trigger('icon');
    } catch (error) {
      console.error(error);
    }
  };
  const dragAction = async (file) => {
    checkFakeImage(file);
    setModalVisible(false);
    const validateImgFileResult = validateImgFile(file?.type);
    if (validateImgFileResult.isError) return setError('icon', validateImgFileResult);
    const validateFizeSizeResult = validateFileSize(file?.size);
    if (validateFizeSizeResult.isError) return setError('icon', validateFizeSizeResult);
    clearErrors('icon');
    setValue('icon', file, { shouldDirty: true });
    trigger('icon');
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = function () {
      const dataURL = reader.result;
      setUploadAvatar(dataURL);
    };
  };
  return (
    <View>
      <Modal
        animationIn="fadeIn"
        isVisible={isModalVisible}
        style={{ alignItems: 'center' }}
        customBackdrop={<ModalBackDrop onPress={() => setModalVisible(false)} />}
      >
        <View style={styles.wrapperModal}>
          <View style={styles.mainClose}>
            <TouchableOpacity style={styles.contentClose} onPress={() => setModalVisible(false)}>
              <ImageNative
                source={require('@assets/icons/closeIcon.svg')}
                alt=""
                style={[styles.iconClose, styles.colorWhrite]}
              />
              <Text style={styles.colorWhite}>{t('commonText.cancelButton')}</Text>
            </TouchableOpacity>
          </View>
          <View style={styles.maxWidth700}>
            <View style={styles.header}>
              <ImageNative
                source={require('@assets/icons/iconCameraGrey.svg')}
                style={styles.iconCamera}
                alt="userAvatar"
              />
              <Text style={styles.textHeader}>{t('pages_Setting_modal.modalChangeAvatarText1')}</Text>
            </View>
            <DragFileFromLocal dragAction={dragAction}>
              <View style={styles.body}>
                {!!avatar ? (
                  <ImageNative source={{ uri: avatar }} style={styles.userAvatarIcon} alt="userAvatar" />
                ) : (
                  <ImageNative
                    source={require('@assets/image/adminAvatar/userDefaultAvatar.png')}
                    style={styles.userAvatarIcon}
                    alt="userAvatar"
                  />
                )}

                <Text style={styles.textDefault}>{t('pages_Setting_modal.modalChangeAvatarText2')} </Text>
                <LinearGradient colors={['#32A5F7', '#2D81F3']} style={{ borderRadius: 5 }}>
                  <JButton
                    onPressAction={uploadImage}
                    text={t('pages_Setting_modal.modalChangeAvatarText3')}
                    width={200}
                    textColor="white"
                  />
                </LinearGradient>
              </View>
            </DragFileFromLocal>
          </View>
        </View>
      </Modal>
    </View>
  );
};

export default withNamespaces()(ModalChangeAvatar);

const styles = StyleSheet.create({
  textHeader: {
    fontSize: FONT_SIZE_HEADER,
    color: 'black',
  },
  textDefault: {
    fontSize: FONT_SIZE_DEFAULT,
    color: 'black',
    paddingVertical: 30,
  },
  wrapperModal: {
    width: '50vw',
    maxWidth: 900,
    minWidth: 600,
  },
  mainClose: {
    maxWidth: 200,
    marginRight: 'auto',
    paddingLeft: 20,
  },
  contentClose: {
    with: 20,
    height: 40,
    backgroundColor: 'transparent',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  colorWhite: {
    color: '#fff',
  },
  iconClose: {
    width: 15,
    height: 15,
    marginRight: 10,
  },
  iconCamera: {
    width: 35,
    height: 25,
    marginRight: 10,
  },
  userAvatarIcon: {
    width: 150,
    height: 150,
    borderRadius: 100,
  },
  maxWidth700: {
    backgroundColor: '#fff',
    borderRadius: 10,
    paddingVertical: 30,
  },
  header: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    borderBottomColor: BORDER_TABLE_GREY,
    borderBottomWidth: 1,
    paddingBottom: 20,
  },
  body: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 40,
  },
});

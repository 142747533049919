export const defaultValue = {
  ADMIN_WALLET_OPERATION: ['MASTER', 'EXECUTIVE', 'ASSOCIATE'],
  APPROVAL_TRANSFER: [],
  BURN_TOKEN: ['MASTER'],
  CREATE_ADMIN: ['MASTER', 'EXECUTIVE'],
  DELETE_ADMIN: ['MASTER'],
  DOCUMENT_MANAGEMENT: ['MASTER', 'EXECUTIVE', 'ASSOCIATE'],
  EDIT_TOKEN_INFO: ['MASTER', 'EXECUTIVE', 'ASSOCIATE'],
  NUMBER_CARD_MANAGEMENT: ['MASTER'],
  PUBLISH_TOKEN: ['MASTER', 'EXECUTIVE'],
  PUBLISH_WALLET_OPERATION: ['MASTER'],
  SETTING_ROLE: ['MASTER'],
  UPDATE_TOKEN_STATUS: ['MASTER', 'EXECUTIVE', 'ASSOCIATE'],
  USER_EKYC_TYPE_MANAGEMENT: ['MASTER', 'EXECUTIVE'],
  USER_MANAGEMENT: ['MASTER', 'EXECUTIVE', 'ASSOCIATE'],
  USER_WALLET_OPERATION: ['MASTER', 'EXECUTIVE', 'ASSOCIATE'],
};

export const listDataTableRole = {
  ADMIN_WALLET_OPERATION: [
    {
      name: 'ADMIN_WALLET_OPERATION',
      value: 'MASTER',
    },
    {
      name: 'ADMIN_WALLET_OPERATION',
      value: 'EXECUTIVE',
    },
    {
      name: 'ADMIN_WALLET_OPERATION',
      value: 'ASSOCIATE',
    },
  ],
  APPROVAL_TRANSFER: [
    {
      name: 'APPROVAL_TRANSFER',
      value: 'MASTER',
    },
    {
      name: 'APPROVAL_TRANSFER',
      value: 'EXECUTIVE',
    },
    {
      name: 'APPROVAL_TRANSFER',
      value: 'ASSOCIATE',
    },
  ],
  BURN_TOKEN: [
    {
      name: 'BURN_TOKEN',
      value: 'MASTER',
    },
    {
      name: 'BURN_TOKEN',
      value: 'EXECUTIVE',
    },
    {
      name: 'BURN_TOKEN',
      value: 'ASSOCIATE',
    },
  ],
  CREATE_ADMIN: [
    {
      name: 'CREATE_ADMIN',
      value: 'MASTER',
    },
    {
      name: 'CREATE_ADMIN',
      value: 'EXECUTIVE',
    },
    {
      name: 'CREATE_ADMIN',
      value: 'ASSOCIATE',
    },
  ],
  DELETE_ADMIN: [
    {
      name: 'DELETE_ADMIN',
      value: 'MASTER',
    },
    {
      name: 'DELETE_ADMIN',
      value: 'EXECUTIVE',
    },
    {
      name: 'DELETE_ADMIN',
      value: 'ASSOCIATE',
    },
  ],
  DOCUMENT_MANAGEMENT: [
    {
      name: 'DOCUMENT_MANAGEMENT',
      value: 'MASTER',
    },
    {
      name: 'DOCUMENT_MANAGEMENT',
      value: 'EXECUTIVE',
    },
    {
      name: 'DOCUMENT_MANAGEMENT',
      value: 'ASSOCIATE',
    },
  ],
  EDIT_TOKEN_INFO: [
    {
      name: 'EDIT_TOKEN_INFO',
      value: 'MASTER',
    },
    {
      name: 'EDIT_TOKEN_INFO',
      value: 'EXECUTIVE',
    },
    {
      name: 'EDIT_TOKEN_INFO',
      value: 'ASSOCIATE',
    },
  ],
  NUMBER_CARD_MANAGEMENT: [
    {
      name: 'NUMBER_CARD_MANAGEMENT',
      value: 'MASTER',
    },
    {
      name: 'NUMBER_CARD_MANAGEMENT',
      value: 'EXECUTIVE',
    },
    {
      name: 'NUMBER_CARD_MANAGEMENT',
      value: 'ASSOCIATE',
    },
  ],
  PUBLISH_TOKEN: [
    {
      name: 'PUBLISH_TOKEN',
      value: 'MASTER',
    },
    {
      name: 'PUBLISH_TOKEN',
      value: 'EXECUTIVE',
    },
    {
      name: 'PUBLISH_TOKEN',
      value: 'ASSOCIATE',
    },
  ],
  PUBLISH_WALLET_OPERATION: [
    {
      name: 'PUBLISH_WALLET_OPERATION',
      value: 'MASTER',
    },
    {
      name: 'PUBLISH_WALLET_OPERATION',
      value: 'EXECUTIVE',
    },
    {
      name: 'PUBLISH_WALLET_OPERATION',
      value: 'ASSOCIATE',
    },
  ],
  SETTING_ROLE: [
    {
      name: 'SETTING_ROLE',
      value: 'MASTER',
    },
    {
      name: 'SETTING_ROLE',
      value: 'EXECUTIVE',
    },
    {
      name: 'SETTING_ROLE',
      value: 'ASSOCIATE',
    },
  ],
  UPDATE_TOKEN_STATUS: [
    {
      name: 'UPDATE_TOKEN_STATUS',
      value: 'MASTER',
    },
    {
      name: 'UPDATE_TOKEN_STATUS',
      value: 'EXECUTIVE',
    },
    {
      name: 'UPDATE_TOKEN_STATUS',
      value: 'ASSOCIATE',
    },
  ],
  USER_EKYC_TYPE_MANAGEMENT: [
    {
      name: 'USER_EKYC_TYPE_MANAGEMENT',
      value: 'MASTER',
    },
    {
      name: 'USER_EKYC_TYPE_MANAGEMENT',
      value: 'EXECUTIVE',
    },
    {
      name: 'USER_EKYC_TYPE_MANAGEMENT',
      value: 'ASSOCIATE',
    },
  ],
  USER_MANAGEMENT: [
    {
      name: 'USER_MANAGEMENT',
      value: 'MASTER',
    },
    {
      name: 'USER_MANAGEMENT',
      value: 'EXECUTIVE',
    },
    {
      name: 'USER_MANAGEMENT',
      value: 'ASSOCIATE',
    },
  ],
  USER_WALLET_OPERATION: [
    {
      name: 'USER_WALLET_OPERATION',
      value: 'MASTER',
    },
    {
      name: 'USER_WALLET_OPERATION',
      value: 'EXECUTIVE',
    },
    {
      name: 'USER_WALLET_OPERATION',
      value: 'ASSOCIATE',
    },
  ],
};

import { LinearGradient } from 'expo-linear-gradient';
import React, { useState } from 'react';
import { withNamespaces } from 'react-i18next';
import { View, Image, StyleSheet } from 'react-native';
import { useHistory } from 'react-router-dom';
import { useFormContext } from 'react-hook-form';

import { INPUT_BG } from '@assets/style/styleDefault';
import JButton from '@components/common/JButton';
import { JText as Text } from '@components/common/JText';
import ModalAlert from '@components/Modals/ModalAlert';
import { API_PATH } from '@helper/constant';
import { postMulti } from '@utils/RequestLevica';
import scroll2View from '@helper/scroll2View';

const MemberInfo = (props) => {
  const { t, isButton, scrollViewRef } = props;
  const history = useHistory();
  const { setError, handleSubmit, reset } = useFormContext();
  const [openModalUpdate, setOpenModalUpdate] = useState(false);
  const [isCreating, setIsCreating] = useState(false);

  const onSubmit = async (companyAccountData) => {
    if (companyAccountData.phone?.length < 6) {
      setError('phone', {
        type: 'custom',
        message: t('errorMessages.TXT_JP_PHONE'),
      });
      scroll2View('phone', scrollViewRef.current);
      return;
    } else if (companyAccountData.landlineNumber?.length < 6) {
      setError('landlineNumber', {
        type: 'custom',
        message: t('errorMessages.TXT_JP_PHONE'),
      });
      scroll2View('landlineNumber', scrollViewRef.current);
      return;
    } else if (companyAccountData.traderPhoneNumber?.length < 6) {
      setError('traderPhoneNumber', {
        type: 'custom',
        message: t('errorMessages.TXT_JP_PHONE'),
      });
      scroll2View('traderPhoneNumber', scrollViewRef.current);
      return;
    }
    try {
      setIsCreating(true);
      await postMulti(API_PATH.COMPANY_ACCOUNT, {
        ...companyAccountData,
        capital: parseInt(companyAccountData?.capital?.replace(/,/g, '')),
      });
      reset();
      setOpenModalUpdate(true);
    } catch (error) {
      if (error?.response?.data?.code == '3014') {
        setError('issuerUserEmail', { type: 'custom', message: t('errorMessages.TXT_REGISTERED_EMAIL') });
        scroll2View('issuerUserEmail', scrollViewRef.current);
      } else if (error?.response?.data?.code == '3015') {
        setError('phone', { type: 'custom', message: t('errorMessages.TXT_REGISTERED_PHONE') });
        scroll2View('phone', scrollViewRef.current);
      } else if (error?.response?.data?.errorCode == '4007') {
        setError('name', { type: 'custom', message: t('errorMessages.nickNameRegisted') });
        scroll2View('name', scrollViewRef.current);
      }
      // else if (error?.response?.data?.code == '3013') {
      //   setError('name', { type: 'custom', message: 'name dky' });
      // }
    } finally {
      setIsCreating(false);
    }
  };
  return (
    <View>
      <View style={{ flexDirection: 'row', justifyContent: 'flex-end', marginBottom: 80 }}>
        <View style={{ paddingRight: 30 }}>
          <JButton
            text={t('commonText.cancelButton')}
            btnColor="#525252"
            textColor="#ffffff"
            width={150}
            hasIcon
            linkIcon="close-white.svg"
            widthIcon={20}
            heightIcon={20}
            onPressAction={() => history.push('/companies')}
          />
        </View>

        <LinearGradient colors={['#54DBB2', '#04C48B']} style={{ borderRadius: 5 }}>
          <JButton
            text={t('commonText.buttonSave')}
            textColor="#ffffff"
            width={150}
            hasIcon
            linkIcon="save.svg"
            linkIconDisabled="saveGray.svg"
            widthIcon={20}
            heightIcon={20}
            disabled={isButton}
            onPressAction={handleSubmit(onSubmit)}
            isProcessing={isCreating}
          />
        </LinearGradient>
      </View>
      {/* <TouchableOpacity onPress={() => history.push('/shop/create-bank')}>
        <LinkBankInfo t={t} text={t('pages_AccountShop_CreateAccount.bankAccountInformation')} isRequire />
      </TouchableOpacity> */}
      <LinkBankInfo t={t} text={t('pages_AccountShop_CreateAccount.identityVerificationDocuments')} />
      <LinkBankInfo t={t} text={t('pages_AccountShop_CreateAccount.transactionManagerMyNumber')} />
      <ModalAlert
        linkPage="/companies"
        isModalVisible={openModalUpdate}
        setModalVisible={setOpenModalUpdate}
        icon="successGreen.svg"
        iconWidth={15}
        iconHeight={15}
        modalText={t('pages_AccountCompany_CreateAccount.modal')}
        onBackdropPressCallback={() => history.push('/companies')}
      />
    </View>
  );
};

const LinkBankInfo = ({ text, isRequire = false, t }) => {
  return (
    <View style={styles.warperBank}>
      <Text>
        {text}
        {isRequire && <Text style={{ color: 'red', marginLeft: 10 }}>✴︎</Text>}
      </Text>
      <View style={[styles.row, styles.bankInfoDetails, styles.marginBottom20]}>
        <Text numberOfLines={2} style={{ color: '#A5A5A5' }}>
          {t('pages_AccountShop_CreateAccount.unregistered')}
        </Text>
        <Image style={styles.subfix} source={require('@assets/icons/iconShare.svg')} />
      </View>
    </View>
  );
};

export default withNamespaces()(MemberInfo);

const styles = StyleSheet.create({
  warperBank: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 20,
    gap: 20,
    paddingRight: 20,
  },

  row: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    backgroundColor: INPUT_BG,
    border: '1px solid #555555',
    padding: 20,
    borderRadius: 5,
    width: '60%',
    cursor: 'pointer',
  },
  bankInfoDetails: {
    height: 42,
    backgroundColor: INPUT_BG,
    borderRadius: 3,
    paddingLeft: 15,
    paddingRight: 35,
    position: 'relative',
  },

  subfix: {
    position: 'absolute',
    width: 15,
    height: 15,
    top: '30%',
    right: 10,
  },
});

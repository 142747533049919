import React from 'react';
import { TouchableOpacity, View } from 'react-native';
import { useFormContext } from 'react-hook-form';
import { Col, Grid } from 'react-native-easy-grid';

import AccountInput from '@components/AccountComponents/AccountInput';
import InputImage from '@components/AccountComponents/InputImage';
import ContactAddress from './ContactAddress';
import FullName from './FullName';
import { JText as Text } from '@components/common/JText';
import JInput from '@components/common/JInput';
import Address from './Address';
import styles from './styles';
import { COLOR_TEXT_DEFAULT } from '@assets/style/styleDefault';

const FomrIndividual = (props) => {
  const { control, setValue, formValue, accountId, t, disableForm } = props;
  const { getValues } = useFormContext();
  const formIndividualData = [
    {
      placeholder: t('pages_AccountManagement_CreateAccount.userIconPlaceholder'),
      isRequired: true,
      labelText: t('pages_AccountManagement_CreateAccount.userIcon'),
      subfix: 'iconEdit.svg',
      name: 'username',
      type: 'DEFAULT',
    },
    {
      placeholder: t('pages_AccountManagement_CreateAccount.imagePlaceholder'),
      isRequired: false,
      labelText: '',
      subfix: 'iconEdit.svg',
      name: 'imageUrl',
      type: 'IMAGE_PICKER',
    },
  ];
  const renderForm = (item) => {
    switch (item.type) {
      case 'DEFAULT':
        return (
          <AccountInput
            key={item.name}
            placeholder={item.placeholder}
            isRequired={item.isRequired}
            labelText={item.labelText}
            subLabelText={item.subLabelText}
            control={control}
            subfix={item.subfix}
            name={item.name}
            startIcon={item.startIcon}
            endIcon={item.endIcon}
            height={item.height}
            multiline={item.multiline}
            disable={disableForm}
          />
        );
      case 'IMAGE_PICKER':
        return (
          <InputImage
            formValue={formValue}
            setValue={setValue}
            key={item.name}
            control={control}
            placeholder={item.placeholder}
            isRequired={item.isRequired}
            labelText={item.labelText}
            subfix={item.subfix}
            name={item.name}
            disable={disableForm}
          />
        );
      default:
        break;
    }
  };
  const copyClipBoard = () => {
    try {
      navigator.clipboard.writeText(getValues('leviasChainAddress'));
    } catch (error) {
      console.error('copy clipboard only work with https');
    }
  };
  return (
    <View style={{ paddingBottom: 40 }}>
      <Grid style={styles.marginBottom20}>
        <Col size={4}>
          <View style={{ flexDirection: 'row' }}>
            <Text>LEVICA ID</Text>
          </View>
        </Col>
        <Col size={8}>
          {accountId ? (
            <Text style={{ paddingLeft: 10, color: !disableForm ? '#A5A5A5' : COLOR_TEXT_DEFAULT }}>
              {formValue?.displayId}
            </Text>
          ) : (
            <Text style={{ color: '#a5a5a5a8' }}>{t('pages_AccountShop_CreateAccount.LEVICAid')}</Text>
          )}
        </Col>
      </Grid>

      <Grid style={styles.marginBottom20}>
        <Col size={4}>
          <View style={{ flexDirection: 'row' }} nativeID="leviasChainAddress">
            <Text>{t('pages_AccountManagement_CreateAccount.walletAddress')}</Text>
          </View>
        </Col>
        <Col size={6}>
          <TouchableOpacity activeOpacity={0.7} onPress={copyClipBoard}>
            <View style={{ minWidth: 346 }}>
              <JInput
                paddingLeft={10}
                placeholder={t('pages_AccountShop_CreateAccount.walletAddressNote')}
                subfix="saveClipboard.svg"
                widthSubfix={18}
                heightSubfix={18}
                control={control}
                name="leviasChainAddress"
                disable={disableForm}
                colorTextInput={!disableForm ? '#A5A5A5' : COLOR_TEXT_DEFAULT}
              />
            </View>
          </TouchableOpacity>
        </Col>
        <Col size={2}></Col>
      </Grid>

      {/* form プライマリ ID to ユーザーアイコン */}
      {formIndividualData.map((i) => renderForm(i))}
      {/* from 氏名 to 名（カナ） */}
      <FullName control={control} t={t} disable={disableForm} />
      {/* from 連絡先 to 固定電話番号 */}
      <ContactAddress control={control} t={t} disable={disableForm} />
      {/* from 住所 to 階数・号室 */}
      <Address control={control} t={t} disable={disableForm} />
    </View>
  );
};

export default FomrIndividual;

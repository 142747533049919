import React from 'react';
import { View, StyleSheet, Image, Pressable } from 'react-native';
import moment from 'moment';
import Modal from 'react-native-modal';
import { JText as Text } from '@components/common/JText';
import ModalBackDrop from '@components/Modals/ModalBackDrop';
import { withNamespaces } from 'react-i18next';

const ModalComplete = ({
  isModalVisible,
  setModalVisible,
  onPress,
  iconName,
  heightIcon,
  widthIcon,
  textContent,
  t,
}) => {
  return (
    <View>
      <Modal
        animationIn="fadeIn"
        isVisible={isModalVisible}
        style={{ alignItems: 'center' }}
        customBackdrop={<ModalBackDrop onPress={() => setModalVisible(false)} />}
      >
        <Pressable style={styles.wrapperModal} onPress={() => setModalVisible(false)}>
          <View style={styles.maxWidth600}>
            <View style={styles.headerBox}>
              {iconName && (
                <Image
                  style={{ height: heightIcon, width: widthIcon, marginRight: 10 }}
                  source={require(`../../../assets/icons/${iconName}`)}
                />
              )}
              <Text style={styles.text}>{t('pages_PaymentManager_Modal_ModalComplete.startPaymentProcessing')}</Text>
            </View>
            <View style={styles.contentBox}>
              <Text style={styles.text}>{textContent}</Text>
              <View style={styles.time}>
                <Image style={styles.iconTime} source={require(`@assets/icons/iconTime.svg`)} />
                <Text style={styles.text}>{moment().format('YYYY.MM.DD HH:mm')}</Text>
              </View>
            </View>
          </View>
        </Pressable>
      </Modal>
    </View>
  );
};

export default withNamespaces()(ModalComplete);

const styles = StyleSheet.create({
  wrapperModal: {
    width: 650,
  },
  maxWidth600: {
    backgroundColor: '#fff',
    borderRadius: 10,
  },
  headerBox: {
    paddingBottom: 20,
    paddingTop: 20,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    borderBottomWidth: 1,
    borderBottomColor: '#E0E0E0',
  },
  iconBox: {
    padding: 30,
    justifyContent: 'center',
    alignItems: 'center',
  },
  contentBox: {
    paddingBottom: 30,
    paddingTop: 30,
    justifyContent: 'center',
    alignItems: 'center',
  },
  contentClose: {
    with: 20,
    height: 40,
    backgroundColor: 'transparent',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  colorWhrite: {
    color: '#fff',
  },
  iconClose: {
    width: 15,
    height: 15,
    marginRight: 10,
  },
  mainClose: {
    maxWidth: 200,
    marginRight: 'auto',
  },
  time: {
    marginTop: 50,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
  },
  iconTime: { height: 20, width: 20, marginRight: 20 },
  text: {
    color: '#191919',
    fontSize: 16,
  },
});

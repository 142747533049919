import React from 'react';
import { View, Image, TouchableOpacity, StyleSheet } from 'react-native';
import Modal from 'react-native-modal';
import { useHistory } from 'react-router-dom';
import { JText } from '../common/JText';
import ModalBackDrop from './ModalBackDrop';

const ModalAlert = ({
  isModalVisible,
  setModalVisible,
  icon,
  iconWidth,
  iconHeight,
  modalText,
  textPaddingTop = 3,
  linkPage,
  func,
  onBackdropPressCallback,
}) => {
  const history = useHistory();
  const handleOnPress = () => {
    if (linkPage) {
      history.push(linkPage);
    } else {
      func ? func() : null;
      setModalVisible(false);
    }
  };

  return (
    <View>
      <Modal
        animationIn="fadeIn"
        isVisible={isModalVisible}
        onBackdropPress={() => {
          setModalVisible(false);
          typeof onBackdropPressCallback === 'function' && onBackdropPressCallback();
        }}
        style={{ alignItems: 'center' }}
        customBackdrop={
          <ModalBackDrop
            onPress={() => {
              setModalVisible(false);
              typeof onBackdropPressCallback === 'function' && onBackdropPressCallback();
            }}
          />
        }
      >
        <View style={styles.maxWidth600}>
          <TouchableOpacity style={styles.content} onPress={handleOnPress}>
            <Image
              source={require(`../../assets/icons/${icon}`)}
              style={[styles.icon, { width: iconWidth, height: iconHeight }]}
            />
            <JText style={{ paddingTop: textPaddingTop, color: 'black' }}>{modalText}</JText>
          </TouchableOpacity>
        </View>
      </Modal>
    </View>
  );
};

export default ModalAlert;

const styles = StyleSheet.create({
  maxWidth600: {
    maxWidth: 600,
    minWidth: 500,
    backgroundColor: 'white',
    borderRadius: 4,
  },
  content: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 10,
    paddingHorizontal: 100,
    paddingVertical: 25,
  },
  icon: {
    resizeMode: 'contain',
  },
});

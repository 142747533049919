import React, { useEffect, useState } from 'react';
import { StyleSheet, Pressable } from 'react-native';
import { Col, Row } from 'react-native-easy-grid';

import { BG_HOVER, LINE_COLOR } from '@assets/style/styleDefault';
import { JText as Text } from '../common/JText';
import JCheckbox from '../common/JCheckbox';

const setDefaultCheckedValues = (formValue, data) => {
  const defaultCheckedValues = [];
  if (formValue && formValue?.hasOwnProperty(data[0]?.name) && formValue !== []) {
    formValue[data[0]?.name].forEach((i) => {
      defaultCheckedValues.push(i);
    });
  }
  return defaultCheckedValues;
};

const CheckboxGroupTable = ({ control, title, data, formValue }) => {
  const [checkedValues, setCheckedValues] = useState(() => setDefaultCheckedValues(formValue, data));

    useEffect(() => {
        setCheckedValues(setDefaultCheckedValues(formValue, data));
    }, [formValue])

  return (
    <>
      <Pressable style={({ hovered }) => [hovered && styles.buttonHovered]}>
        <Row size={30} style={styles.row}>
          <Col size={1} style={[styles.wrapperItem, styles.text]}>
            <Text style={styles.paddingLeft} numberOfLines={3}>
              {title}
            </Text>
          </Col>
          {data?.map((item, index) => (
            <Col size={1} style={[styles.wrapperItem]} key={index}>
              <JCheckbox
                name={item.name}
                control={control}
                checkboxValue={item.value}
                checkedValues={checkedValues}
                setCheckedValues={setCheckedValues}
                disable={!(item.value === 'MASTER')}
              />
            </Col>
          ))}
        </Row>
      </Pressable>
    </>
  );
};

export default CheckboxGroupTable;

const styles = StyleSheet.create({
  text: {
    justifyContent: 'flex-start',
  },
  paddingLeft: {
    paddingLeft: 50,
  },
  buttonHovered: {
    backgroundColor: BG_HOVER,
  },
  row: {
    borderBottomWidth: 1,
    borderBottomColor: LINE_COLOR,
    paddingTop: 15,
    paddingBottom: 15,
  },
  wrapperItem: {
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    paddingVertical: 6,
  },
  wrapperItemHead: {
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
  },
});

import React, { useState } from 'react';
import { StyleSheet, Image, View } from 'react-native';
import { withNamespaces } from 'react-i18next';
import { Col } from 'react-native-easy-grid';

import { JText as Text } from '@components/common/JText';

const TableHead = ({ params, setParams, isDisplay, t }) => {
  const [sortByDateTimeASC, setSortByDateTimeASC] = useState(false);

  const handleSortByDateTime = () => {
    setSortByDateTimeASC((prev) => !prev);
    const { category, Q } = params;
    setParams(!sortByDateTimeASC ? { category, Q } : { ...params, sort: 'desc' });
  };

  return (
    <View style={[styles.container, { display: isDisplay ? 'flex' : 'none' }]}>
      <Col size={4} style={styles.flex}>
        <Text style={styles.sidebarHead}>{t('pages_FileManager_partials_TableHead.fileName')}</Text>
      </Col>
      <Col size={2} style={styles.flex}>
        <Text style={styles.sidebarHead}>{t('pages_FileManager_partials_TableHead.size')}</Text>
      </Col>
      <Col size={2} style={styles.flex}>
        <Text style={styles.sidebarHead}>{t('pages_FileManager_partials_TableHead.AddDate')}</Text>
        <Image
          style={[styles.icon, sortByDateTimeASC ? styles.iconTableHeadASC : styles.iconTableHeadDESC]}
          source={require(`@assets/icons/v-grey.svg`)}
          onClick={handleSortByDateTime}
        />
      </Col>
      <Col size={2} style={styles.flex}>
        <Text style={styles.sidebarHead}>{t('pages_FileManager_partials_TableHead.currentlyUse')}</Text>
      </Col>
      <Col size={2} style={styles.flex}>
        <Text style={styles.sidebarHead}>{t('pages_FileManager_partials_TableHead.selection')}</Text>
      </Col>
    </View>
  );
};

export default withNamespaces()(TableHead);

const styles = StyleSheet.create({
  sidebarHead: {
    color: '#797979',
  },
  icon: {
    width: 12,
    height: 12,
    marginLeft: 5,
    marginBottom: 5,
  },
  container: {
    marginHorizontal: 30,
    flexDirection: 'row',
    gap: 20,
  },
  flex: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  iconTableHeadDESC: {
    width: 12,
    height: 12,
    resizeMode: 'contain',
    marginLeft: 5,
    cursor: 'pointer',
  },
  iconTableHeadASC: {
    width: 12,
    height: 12,
    resizeMode: 'contain',
    marginLeft: 5,
    cursor: 'pointer',
    transform: [{ rotate: '180deg' }],
  },
});

import React, { memo, useEffect } from 'react';
import { View, Image, Pressable, StyleSheet, ScrollView } from 'react-native';
import { Link } from 'react-router-dom';
import { JText as Text } from '@components/common/JText';
import { useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';

import {
  BG_HOVER,
  COLOR_TEXT_DEFAULT,
  LINE_COLOR,
  TEXT_COLOR_GREEN,
  TEXT_COLOR_GREY,
} from '@assets/style/styleDefault';
import { get } from '@utils/RequestLevica';
import { API_PATH } from '@helper/constant';
import { statusColor } from '@helper/formatTypes';

const AdminList = ({ recall, setRecall }) => {
  const userInfo = useSelector((state) => state.merchantUserInfo);
  const {
    data: AdminList,
    isLoading,
    refetch,
    remove,
  } = useQuery(
    ['AdminList'],
    async ({ signal }) => {
      const data = await get(API_PATH.ADMIN_LIST, { signal });
      setRecall(false);
      return data;
    },
    { refetchOnWindowFocus: false }
  );

  useEffect(() => {
    if (recall) {
      refetch();
    }
  }, [recall]);

  useEffect(() => remove, [remove]);

  return (
    <>
      <View style={[styles.warperItemRight, styles.itemRightHeader]}>
        {AdminList?.length ? (
          <>
            <Text> Admin Accounts </Text>
            <Text>{`${AdminList?.filter((item) => item.status === 0).length} / ${AdminList?.length}`}</Text>
          </>
        ) : null}
      </View>
      <ScrollView showsVerticalScrollIndicator={false}>
        {AdminList?.map((item, index) => {
          return (
            <Pressable key={index} style={({ hovered }) => [hovered && styles.itemRightHovered]}>
              <Link
                to={item?.id === userInfo?.id ? '/admin' : `/admin/member-change/${item.id}`}
                style={{ textDecoration: 'none' }}
              >
                <View style={styles.warperItemRight}>
                  {item?.icon ? (
                    <Image style={styles.avatarItemRight} source={{ uri: item?.icon }} />
                  ) : (
                    <Image style={styles.avatarItemRight} source={require(`@assets/image/userDefaultAvatar.jpg`)} />
                  )}

                  <View style={[styles.dFlex, { flex: 1, alignItems: 'baseline' }]}>
                    <View
                      style={[styles.statusIcon, { backgroundColor: statusColor(item?.masterType) }]}
                      alt="statusIcon"
                    />
                    <Text
                      numberOfLines={3}
                      style={{ color: item.status === 0 ? COLOR_TEXT_DEFAULT : TEXT_COLOR_GREY, marginRight: 10 }}
                    >
                      {item.name}
                    </Text>
                  </View>
                  {item.status === 0 ? (
                    <Text style={{ color: TEXT_COLOR_GREEN }}>
                      A<Text>ctive</Text>
                    </Text>
                  ) : (
                    <Text style={{ color: TEXT_COLOR_GREY }}>Close</Text>
                  )}
                </View>
              </Link>
            </Pressable>
          );
        })}
      </ScrollView>
    </>
  );
};

export default memo(AdminList);

const styles = StyleSheet.create({
  dFlex: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
  warperItemRight: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingVertical: 15,
    paddingHorizontal: 10,
  },
  avatarItemRight: {
    width: 40,
    height: 40,
    marginRight: 5,
    borderRadius: 50,
  },
  itemRightHeader: {
    borderBottomColor: LINE_COLOR,
    borderBottomWidth: 1,
    marginBottom: 10,
  },
  itemRightHovered: {
    backgroundColor: BG_HOVER,
  },
  statusIcon: {
    width: 10,
    height: 10,
    borderRadius: 10,
    marginHorizontal: 10,
  },
});

import React, { useState, useEffect, useRef } from 'react';
import { View, Image, StyleSheet, TouchableWithoutFeedback } from 'react-native';

import { JText as Text } from '@components/common/JText';
import { COLOR_TEXT_DEFAULT, LINE_COLOR } from '@assets/style/styleDefault';
import { converTextCamelCase, statusColor } from '@helper/formatTypes';
import { withNamespaces } from 'react-i18next';

const AdminType = (props) => {
  const { dataAdminType, setValue, error, userInfo, trigger, checkRole = true, t } = props;
  const ref = useRef();
  const [isHidden, setIsHidden] = useState(false);
  const [valueSelect, setValueSelect] = useState(() => t('commonText.adminType'));
  const handleClickSelect = () => {
    if (checkRole) {
      setIsHidden(!isHidden);
    }
  };

  const changeValue = (item) => {
    setValueSelect(item);
    setIsHidden(false);
    setValue ? setValue('masterType', item, { shouldDirty: true }) : null;
    trigger('masterType');
  };
  useEffect(() => {
    if (userInfo) {
      setValueSelect(userInfo?.masterType);
    }
  }, [userInfo?.masterType]);

  useEffect(() => {
    if (!checkRole) {
      setValueSelect(userInfo?.masterType);
    }
  }, [checkRole]);

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setIsHidden(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <View ref={ref}>
      <TouchableWithoutFeedback onPress={() => handleClickSelect()}>
        <View style={styles.select}>
          <View style={styles.flexRow}>
            {statusColor(valueSelect) ? (
              <View style={[styles.iconStatus, { backgroundColor: statusColor(valueSelect) }]} />
            ) : null}
            <Text>{converTextCamelCase(valueSelect)}</Text>
          </View>

          <View>
            {checkRole ? (
              <Image
                style={isHidden ? styles.iconDown : styles.iconUp}
                source={require(`@assets/icons/iconDownBlack.svg`)}
              />
            ) : null}
          </View>
        </View>
      </TouchableWithoutFeedback>

      {isHidden && (
        <View style={styles.selectList}>
          {dataAdminType?.map((item, index) => {
            return (
              <TouchableWithoutFeedback key={item} onPress={() => changeValue(item)}>
                <View
                  style={[
                    styles.flexRow,
                    dataAdminType.length === index + 1
                      ? { borderBottomWidth: 0, marginTop: 10, cursor: 'pointer' }
                      : styles.listItem,
                    { display: item === valueSelect ? 'none' : 'flex' },
                  ]}
                  value={item}
                >
                  <View style={[styles.iconStatus, { backgroundColor: statusColor(item) }]} />
                  <Text>{converTextCamelCase(item)}</Text>
                </View>
              </TouchableWithoutFeedback>
            );
          })}
        </View>
      )}
      {error ? <Text style={styles.error}>{error?.message}</Text> : null}
    </View>
  );
};
export default withNamespaces()(AdminType);

const styles = StyleSheet.create({
  flexRow: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  select: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingLeft: 20,
    paddingRight: 20,
    position: 'relative',
    cursor: 'pointer',
  },
  iconStatus: {
    height: 12,
    width: 12,
    marginRight: 10,
    borderRadius: 50,
  },
  textLabel: {
    color: COLOR_TEXT_DEFAULT,
  },
  textPlaceholder: {
    color: COLOR_TEXT_DEFAULT,
  },
  iconDown: {
    height: 10,
    width: 12,
    transform: [{ rotate: '180deg' }],
  },
  iconUp: {
    height: 10,
    width: 12,
    transitionDuration: 0.5,
  },
  selectList: {
    position: 'absolute',
    top: 40,
    minHeight: 80,
    width: '100%',
    backgroundColor: '#404040',
    borderRadius: 6,
    zIndex: 1,
    paddingLeft: 15,
    paddingRight: 15,
    border: '1px solid #2FCBA7',
    paddingLeft: 50,
    paddingBottom: 10,
  },
  listItem: {
    borderBottomColor: LINE_COLOR,
    borderBottomWidth: 1,
    paddingBottom: 10,
    marginTop: 10,
    cursor: 'pointer',
  },
  error: {
    color: 'red',
    fontSize: 12,
    paddingLeft: 20,
    paddingTop: 5,
  },
});

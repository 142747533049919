import React from 'react';
import * as d3 from 'd3';
import { COLOR_TEXT_DEFAULT, BTN_COLOR_GREY_2 } from '@assets/style/styleDefault';
import { formatNumber } from '@helper/formatTypes';
const PieChar = (props) => {
  const createPie = d3
    .pie()
    .value((d) => d.value)
    .sort(null);
  const createArc = d3.arc().innerRadius(props.innerRadius).outerRadius(props.outerRadius);
  const colors = d3.scaleOrdinal(props.data.map((item) => item.color));
  const data = createPie(props.data);
  return (
    <svg width={props.width} height={props.height}>
      <g transform={`translate(${props.outerRadius} ${props.outerRadius})`}>
        <text textAnchor="middle" alignmentBaseline="middle">
          <tspan x="0" dy="1" fontSize="24" fill={COLOR_TEXT_DEFAULT}>
            {
             formatNumber( props?.dashboardData?.leviasWalletCount)
            }
          </tspan>
          <tspan x="0" dy="1.2em" fontSize="18" fill={BTN_COLOR_GREY_2}>
            Wallet
          </tspan>
        </text>
        {data.map((item, index) => (
          <path key={index} d={createArc(item)} fill={colors(index)} />
        ))}
      </g>
    </svg>
  );
};

export default PieChar;

import React, { useState, useEffect } from 'react';
import { View, Image, ScrollView, Pressable, TouchableOpacity, Dimensions } from 'react-native';
import { Col, Row } from 'react-native-easy-grid';
import { useHistory, useLocation } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { withNamespaces } from 'react-i18next';

import HeaderSearch from '@components/Headers/HeaderSearch';
import Pagination from '@components/common/Pagination';
import { JText as Text } from '@components/common/JText';
import Loading from '@components/common/Loading';
import { get } from '@utils/RequestLevica';
import styles from '../AccountCompany/styles';
import { API_PATH } from '@helper/constant';
import { Center } from 'native-base';
import { BG_FORM_DEFAULT } from '@assets/style/styleDefault';

const AccountManagement = ({ t }) => {
  const history = useHistory();
  const { search } = useLocation();

  const [params, setParams] = useState({
    limit: 30,
    pageNumber: 1,
    q: search?.slice(3) ?? '',
    sort: '0100',
    order: 'desc',
  });

  const [tableHeadData, setTableHeadData] = useState([
    {
      title: 'LEVICA ID',
      size: 2,
      hasIcon: true,
      sort: '0100',
      order: false,
    },
    {
      title: t('pages_AccountManagement.nameUsername'),
      hasIcon: true,
      sort: '0200',
      order: false,
      size: 2,
    },
    {
      title: t('pages_AccountManagement.walletAddress'),
      size: 3,
    },
    {
      title: t('pages_AccountManagement.email'),
      size: 2,
    },
    {
      title: t('pages_AccountManagement.rank'),
      hasIcon: true,
      sort: '0300',
      order: false,
      size: 2,
    },
    {
      title: t('pages_AccountManagement.accountStatus'),
      hasIcon: true,
      sort: '0400',
      order: false,
      size: 3,
    },
  ]);

  const getListUser = async ({ signal }) => {
    return await get(API_PATH.MANAGEMENT_ACCOUNT, { ...params, signal });
  };

  const {
    data: accountMng,
    isFetching,
    remove,
  } = useQuery(['accountMng', { ...params }], getListUser, {
    refetchOnWindowFocus: false,
  });

  const handlePress = (item) => {
    history.push(`/accounts/details/${item?.id}`);
  };

  const handleSort = (item) => {
    setTableHeadData((prev) =>
      prev.map((tableHead) =>
        tableHead.sort === item.sort ? { ...tableHead, order: !tableHead.order } : { ...tableHead, order: false }
      )
    );

    setParams({
      ...params,
      sort: item.sort,
      pageNumber: 1,
      order: !item.order ? 'asc' : 'desc',
    });
  };

  useEffect(() => remove, [remove]);

  return (
    <>
      <View
        style={[
          styles.container,
          { backgroundColor: isFetching || accountMng?.pageInfo?.totalCount < 1 ? '' : BG_FORM_DEFAULT },
        ]}
      >
        <View style={styles.wrapperStickyHeader}>
          <View style={styles.headerSearch}>
            <HeaderSearch
              placeholder={t('pages_AccountManagement.placeholder')}
              params={params}
              setParams={setParams}
              value={search?.slice(3)}
            />
          </View>

          <View style={styles.wrapperHeader}>
            <View style={styles.wrapperHeaderLeft}>
              <Text style={[styles.textHeader]}>{t('pages_AccountManagement.personalAccount')}</Text>
              <Text style={[styles.textHeader]}>
                {!accountMng?.pageInfo?.totalCount || isFetching ? 0 : accountMng?.pageInfo?.totalCount}{' '}
                {t('pages_AccountManagement.personalAccountTotal')}
              </Text>
            </View>
          </View>
          <Row style={[styles.head, styles.wrapperHeadTable, { gap: 20 }]} size={30}>
            {accountMng?.pageInfo?.totalCount > 0 &&
              !isFetching &&
              tableHeadData.map((item) => (
                <Col size={item.size} style={styles.wrapperItem} key={item.title}>
                  <Text numberOfLines={3} style={styles.textHead}>
                    {item.title}
                  </Text>
                  {item.hasIcon && (
                    <Image
                      onClick={() => handleSort(item)}
                      style={item.order ? styles.iconTableHeadDESC : styles.iconTableHeadASC}
                      source={require(`@assets/icons/iconDropdown.svg`)}
                    />
                  )}
                </Col>
              ))}
          </Row>
        </View>

        <ScrollView showsVerticalScrollIndicator={false}>
          {isFetching ? (
            <Loading style={{ height: 'calc(100vh - 198px)' }} />
          ) : accountMng?.pageInfo?.totalCount > 0 ? (
            accountMng?.userInformationList?.map((user, index, { length }) => {
              return (
                <Pressable key={index} style={[({ hovered }) => [hovered && styles.buttonHovered]]}>
                  <TouchableOpacity onPress={() => handlePress(user)}>
                    <Row
                      style={[
                        styles.wrapperItemTable,
                        styles.row,
                        { gap: 20, borderBottomWidth: index + 1 === length ? 0 : 1 },
                      ]}
                      size={30}
                    >
                      <Col size={2} style={styles.wrapperItem}>
                        <Text style={styles.text} numberOfLines={3}>
                          {user?.levicaId}
                        </Text>
                      </Col>
                      <Col size={2} style={styles.wrapperItem}>
                        {/* <Text numberOfLines={3}>{`${user?.lastName ?? ''} ${user?.firstName ?? ''}`}</Text> */}
                        <Text numberOfLines={3}>{user?.username}</Text>
                      </Col>
                      <Col size={3} style={styles.wrapperItem}>
                        <Text numberOfLines={3}>{user?.leviasChainAddress}</Text>
                      </Col>
                      <Col size={2} style={styles.wrapperItem}>
                        <Text style={styles.text} numberOfLines={3}>
                          {user?.email}
                        </Text>
                      </Col>
                      <Col size={2} style={styles.wrapperItem}>
                        <Text style={styles.textWhite}>ノーマル</Text>
                      </Col>
                      <Col size={3} style={styles.wrapperItem}>
                        <View style={styles.circle} />
                        <Text numberOfLines={3}>稼働中</Text>
                      </Col>
                    </Row>
                  </TouchableOpacity>
                </Pressable>
              );
            })
          ) : (
            <Center w="100%" h={Dimensions.get('window').height - 198}>
              <Text>{t('pages_AccountManagement.haveNoAccount')}</Text>
            </Center>
          )}
          <View>
            {params.limit < accountMng?.pageInfo?.totalCount && !isFetching && (
              <Pagination
                count={Math.ceil(accountMng?.pageInfo?.totalCount / params.limit)}
                params={params}
                setParams={setParams}
              />
            )}
          </View>
        </ScrollView>
      </View>
    </>
  );
};

export default withNamespaces()(AccountManagement);

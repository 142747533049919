import React, { useCallback, useEffect, useState } from 'react';
import * as Yup from 'yup';
import { View, Image, TouchableOpacity } from 'react-native';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { LinearGradient } from 'expo-linear-gradient';
import { Col, Grid } from 'react-native-easy-grid';
import { get, post } from '@utils/RequestLevica';
import { useMutation, useQuery } from '@tanstack/react-query';
import { withNamespaces } from 'react-i18next';

import AccountInput from '@components/AccountComponents/AccountInput';
import JButton from '@components/common/JButton';
import styles from './styles';
import ModalAlert from '@components/Modals/ModalAlert';
import { TEXT_COLOR_GREEN } from '@assets/style/styleDefault';
import { JText as Text } from '@components/common/JText';
import HeaderAvatar from '@components/Headers/HeaderAvatar';
import DateTimePicker from '@components/common/DateTimePicker';
import { API_PATH } from '@helper/constant';

const defaultValues = {
  dayTrading: '',
  tradingCode: '',
  name: '',
  amount: '',
};

const AddPaymentTransaction = ({ t }) => {
  const history = useHistory();
  const [openModalUpdate, setOpenModalUpdate] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const schema = Yup.object().shape({
    dayTrading: Yup.string()
      .trim()
      .required(t('errorMessages.fieldRequired', { fieldName: t('page_AddPaymentTransaction.dayTrading') })),
    tradingCode: Yup.string()
      .trim()
      .required(t('errorMessages.fieldRequired', { fieldName: t('page_AddPaymentTransaction.tradingCode') }))
      .matches(/^[0-9]+$/g, t('errorMessages.TXT_NUMERIC'))
      .max(6, t('errorMessages.TXT_MAX_6')),
    name: Yup.string()
      .trim()
      .required(t('errorMessages.fieldRequired', { fieldName: t('page_AddPaymentTransaction.name') }))
      .max(32, t('errorMessages.TXT_MAX_32')),
    amount: Yup.string()
      .trim()
      .required(t('errorMessages.fieldRequired', { fieldName: t('page_AddPaymentTransaction.amount') })),
    // .matches(/^[0-9]+$/g, t('errorMessages.TXT_NUMERIC')),
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
    setError,
    watch,
    getValues,
    setValue,
    clearErrors,
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
    mode: 'onChange',
  });

  // const getUser = async () => {
  //   try {
  //     const data = await get(API_PATH.USER_BY_CODE + `/` + getValues('tradingCode'));
  //     setValue('name', data?.username || '');
  //   } catch (error) {
  //     setValue('name', '');
  //     if (error?.response?.data?.code == '1010') {
  //       setError('tradingCode', { message: t('errorMessages.userNotFound'), type: 'user not found' });
  //     }
  //   }
  // };

  // const { data: userData, remove } = useQuery(['getUser'], getUser, {
  //   enabled: !!getValues('name'),
  //   refetchOnWindowFocus: false,
  // });

  const { mutate: createNewTransaction } = useMutation({
    mutationFn: async (data) => post(API_PATH.PAYMENT_CONFIRMATION_REQUEST, data),
    retry: 3,
  });

  const handleCreateNewTransaction = useCallback(
    (data) => {
      setIsSaving(true);
      createNewTransaction(
        { ...data, amount: parseInt(data?.amount?.replace(/,/g, '')) },
        {
          onSuccess: () => {
            setOpenModalUpdate(true);
            setIsSaving(false);
            clearErrors('tradingCode');
          },
          onError: ({ response }) => {
            setIsSaving(false);
            switch (response?.data?.code) {
              case '1010':
                setError('tradingCode', { message: t('errorMessages.userNotFound'), type: 'user not found' });
                break;

              default:
            }
          },
        }
      );
    },

    [createNewTransaction, setOpenModalUpdate]
  );
  // useEffect(() => {
  //   if (getValues('tradingCode')?.length > 2 && getValues('tradingCode')?.length < 7) {
  // getUser();
  //   }
  // }, [watch('tradingCode')]);

  // useEffect(() => remove, [remove]);
  return (
    <View>
      <View style={{ marginVertical: 18, flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
          <TouchableOpacity onPress={() => history.goBack()}>
            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
              <Image
                style={{ width: 12, height: 22, marginHorizontal: 20 }}
                source={require(`@assets/icons/iconLeft.svg`)}
              />
              <Text style={{ color: TEXT_COLOR_GREEN, fontSize: 18 }}>{t('commonText.backArrow')}</Text>
            </View>
          </TouchableOpacity>
          <View style={{ flexDirection: 'row', alignItems: 'center' }}>
            <Image
              style={{ width: 30, height: 20, marginHorizontal: 20 }}
              source={require(`@assets/icons/bank-card.svg`)}
            />
            <Text style={styles.textHeader}>{t('page_AddPaymentTransaction.title')}</Text>
          </View>
        </View>

        <HeaderAvatar />
      </View>
      <View style={[styles.pl20, { paddingTop: 32 }]}>
        <Grid style={[styles.wrapperFrom, { marginBottom: 50 }]}>
          <Col size={7}>
            <View
              style={{
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-end',
              }}
            >
              <View>
                <LinearGradient colors={['#54DBB2', '#04C48B']} style={{ borderRadius: 5, marginLeft: 20 }}>
                  <JButton
                    text="保存"
                    width={170}
                    textColor="#fff"
                    hasIcon={true}
                    linkIcon="iconDownload.svg"
                    widthIcon={17}
                    heightIcon={15}
                    onPressAction={handleSubmit(handleCreateNewTransaction)}
                    // disabled={!isValid}
                    isProcessing={isSaving}
                    disabled={isSaving}
                    linkIconDisabled="iconDownloadGray.svg"
                  />
                </LinearGradient>
              </View>
            </View>
          </Col>
          <Col size={5} />
        </Grid>

        <View style={styles.wrapperContent}>
          <Grid style={styles.wrapperFrom}>
            <Col size={2} style={{ flexDirection: 'row' }}>
              <Text>{t('page_AddPaymentTransaction.dayTrading')}</Text>
              <Text style={styles.iconRequired}>✴︎</Text>
            </Col>
            <Col size={5}>
              <DateTimePicker name="dayTrading" control={control} type="date" max="infinity" />
            </Col>
            <Col size={5} />
          </Grid>

          <Grid style={styles.wrapperFrom}>
            <Col size={7}>
              <AccountInput
                control={control}
                name="tradingCode"
                placeholder={t('page_AddPaymentTransaction.tradingCode_placeholder')}
                isRequired={true}
                labelText={t('page_AddPaymentTransaction.tradingCode')}
                subfix="iconEdit.svg"
                titleSize={2}
                contentSize={5}
              />
            </Col>
            <Col size={5} />
          </Grid>
          <Grid style={styles.wrapperFrom}>
            <Col size={7}>
              <AccountInput
                control={control}
                name="name"
                placeholder={t('page_AddPaymentTransaction.name_placeholder')}
                isRequired={true}
                labelText={t('page_AddPaymentTransaction.name')}
                subfix="iconEdit.svg"
                titleSize={2}
                // disable={false}
                contentSize={5}
              />
            </Col>
            <Col size={5} />
          </Grid>
          <Grid style={styles.wrapperFrom}>
            <Col size={7}>
              <AccountInput
                typeMoney
                control={control}
                name="amount"
                placeholder={t('page_AddPaymentTransaction.amount_placeholder')}
                isRequired={true}
                labelText={t('page_AddPaymentTransaction.amount')}
                subfix="iconEdit.svg"
                titleSize={2}
                contentSize={5}
              />
            </Col>
            <Col size={5} />
          </Grid>
        </View>
      </View>

      <ModalAlert
        isModalVisible={openModalUpdate}
        setModalVisible={setOpenModalUpdate}
        icon="successGreen.svg"
        iconWidth={15}
        iconHeight={15}
        modalText={t('page_AddPaymentTransaction.addSuccess')}
        onBackdropPressCallback={() => history.push('/payment')}
        linkPage="/payment"
      />
    </View>
  );
};

export default withNamespaces()(AddPaymentTransaction);

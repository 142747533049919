import React from 'react';
import { Controller } from 'react-hook-form';
import { Checkbox } from 'native-base';
import { View, TouchableWithoutFeedback } from 'react-native';

const JCheckbox = ({
  disable = true,
  name,
  control,
  checkboxValue,
  checkedValues,
  setCheckedValues,
  singleCheckBox,
  checkValueTable,
  onValueChange,
  value,
}) => {
  const handleSelect = (x) => {
    const newNames = checkedValues?.includes(x)
      ? checkedValues?.filter((name) => name !== x)
      : [...(checkedValues ?? []), x];
    setCheckedValues(newNames);

    return newNames;
  };

  return (
    <>
      {checkValueTable ? (
        <>
          <View>
            <TouchableWithoutFeedback onPress={onValueChange} disabled={!disable}>
              <Checkbox
                isDisabled={!disable}
                isChecked={value}
                bg="BG_CHECKBOX"
                _checked={{ backgroundColor: 'BG_CHECKBOX' }}
                _icon={{ color: 'CHECKBOX_COLOR' }}
                accessibilityLabel={'accessibilityLabel'}
              />
            </TouchableWithoutFeedback>
          </View>
        </>
      ) : (
        <Controller
          name={name}
          control={control}
          render={({ field: { onChange, onBlur, value, name, ref }, fieldState: { error } }) => (
            <>
              {singleCheckBox ? (
                <View>
                  <Checkbox
                    isDisabled={!disable}
                    isChecked={value}
                    onChange={onChange}
                    bg="BG_CHECKBOX"
                    _checked={{ backgroundColor: 'BG_CHECKBOX' }}
                    _icon={{ color: 'CHECKBOX_COLOR' }}
                    accessibilityLabel={'accessibilityLabel'}
                  />
                </View>
              ) : (
                <View>
                  <Checkbox
                    isDisabled={!disable}
                    isChecked={checkedValues?.includes(checkboxValue)}
                    onChange={() => onChange(handleSelect(checkboxValue))}
                    bg="BG_CHECKBOX"
                    _checked={{ backgroundColor: 'BG_CHECKBOX' }}
                    _icon={{ color: 'CHECKBOX_COLOR' }}
                    accessibilityLabel={'accessibilityLabel'}
                  />
                </View>
              )}
            </>
          )}
        />
      )}
    </>
  );
};

export default JCheckbox;

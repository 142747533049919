import { StyleSheet } from 'react-native';
import { TEXT_COLOR_RED } from '@assets/style/styleDefault';

const styles = StyleSheet.create({
  flexBetween: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  wrapperFrom: {
    marginBottom: 15,
  },

  flexAlignCenter: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  iconDelete: {
    width: 24,
    height: 24,
    marginRight: 10,
    marginBottom: 5,
  },
  iconUser: {
    width: 30,
    height: 30,
    marginRight: 10,
  },
  iconBuilding: {
    width: 27,
    height: 33,
    marginRight: 10,
  },
  colorRed: {
    color: TEXT_COLOR_RED,
  },
  pl20: {
    paddingHorizontal: 40,
  },
  textHeader: {
    fontSize: 18,
    fontWeight: 600,
  },
  iconRequired: {
    color: TEXT_COLOR_RED,
    marginLeft: 6,
    fontSize: 16,
  },
});

export default styles;

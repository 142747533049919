import { LinearGradient } from 'expo-linear-gradient';
import React from 'react';
import { withNamespaces } from 'react-i18next';
import { View, StyleSheet, Image } from 'react-native';
import Modal from 'react-native-modal';
import { BTN_COLOR_GREY_2 } from '@assets/style/styleDefault';
import JButton from '@components/common/JButton';
import { JText as Text } from '@components/common/JText';
import ModalBackDrop from '@components/Modals/ModalBackDrop';

const ModalAlertPayment = ({
  isModalVisible,
  setModalVisible,
  onPress,
  countChecked,
  iconName,
  heightIcon,
  widthIcon,
  titleModal,
  t,
  isProcessing
}) => {
  return (
    <View>
      <Modal
        animationIn="fadeIn"
        isVisible={isModalVisible}
        style={{ alignItems: 'center' }}
        customBackdrop={<ModalBackDrop onPress={() => setModalVisible(false)} />}
      >
        <View style={styles.wrapperModal}>
          <View style={styles.maxWidth600}>
            <View style={styles.headerBox}>
              <Text style={styles.text}>{titleModal}</Text>
            </View>
            <View style={styles.wrapperIcon}>
              {iconName && (
                <Image
                  style={{ height: heightIcon, width: widthIcon, marginRight: 10 }}
                  source={require(`../../../assets/icons/${iconName}`)}
                />
              )}
            </View>
            <View style={styles.contentBox}>
              <Text style={styles.text}>{t('pages_PaymentManager_Modal_ModalConfirmPayment.note')}</Text>
              <Text style={styles.text}>
                {countChecked} {t('pages_PaymentManager_Modal_ModalConfirmPayment.confirmTXProcessing')}
              </Text>
            </View>
            <View style={styles.wrapperButton}>
              <JButton
                width={180}
                text={t('components_Modals_ModalBack.modalBackButton1')}
                textColor={'#fff'}
                btnColor={BTN_COLOR_GREY_2}
                onPressAction={() => setModalVisible(false)}
              />
              <LinearGradient colors={['#FE8463', '#FD4F44']} style={{ borderRadius: 5 }}>
                <JButton
                  width={180}
                  text={t('pages_PaymentManager_Modal_ModalConfirmPayment.TXprocessing')}
                  textColor={'#fff'}
                  onPressAction={onPress}
                  isProcessing={isProcessing}
                  disabled={isProcessing}
                />
              </LinearGradient>
            </View>
          </View>
        </View>
      </Modal>
    </View>
  );
};

export default withNamespaces()(ModalAlertPayment);

const styles = StyleSheet.create({
  wrapperModal: {
    width: 600,
  },
  maxWidth600: {
    backgroundColor: '#fff',
    borderRadius: 10,
  },
  wrapperIcon: {
    paddingTop: 30,
    justifyContent: 'center',
    alignItems: 'center',
  },
  headerBox: {
    paddingBottom: 20,
    paddingTop: 20,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    borderBottomWidth: 1,
    borderBottomColor: '#E0E0E0',
  },
  iconBox: {
    padding: 30,
    justifyContent: 'center',
    alignItems: 'center',
  },
  contentBox: {
    paddingBottom: 30,
    paddingTop: 30,
    justifyContent: 'center',
    alignItems: 'center',
  },
  contentClose: {
    with: 20,
    height: 40,
    backgroundColor: 'transparent',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  colorWhrite: {
    color: '#fff',
  },
  iconClose: {
    width: 15,
    height: 15,
    marginRight: 10,
  },
  mainClose: {
    maxWidth: 200,
    marginRight: 'auto',
  },
  wrapperButton: {
    paddingBottom: 30,
    paddingTop: 30,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-evenly',
  },
  text: {
    color: '#191919',
    fontSize: 16,
  },
});

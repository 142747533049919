import React from 'react';
import { Col, Grid } from 'react-native-easy-grid';

import AccountInput from '@components/AccountComponents/AccountInput';
import styles from './styles';
import { JText as Text } from '@components/common/JText';

const FullName = ({ control, disable, t }) => {
  const fullNameData = [
    {
      placeholder: t('pages_AccountManagement_CreateAccount.lastNamePlaceholder'),
      isRequired: false,
      labelText: t('pages_AccountManagement_CreateAccount.lastName'),
      subfix: 'iconEdit.svg',
      name: 'lastName',
      type: 'DEFAULT',
    },
    {
      placeholder: t('pages_AccountManagement_CreateAccount.lastNameKatakanaPlaceholder'),
      isRequired: false,
      labelText: t('pages_AccountManagement_CreateAccount.lastNameKatakana'),
      subfix: 'iconEdit.svg',
      name: 'lastNameKana',
      type: 'DEFAULT',
    },
    {
      placeholder: t('pages_AccountManagement_CreateAccount.firstNamePlaceholder'),
      isRequired: false,
      labelText: t('pages_AccountManagement_CreateAccount.firstName'),
      subfix: 'iconEdit.svg',
      name: 'firstName',
      type: 'DEFAULT',
    },
    {
      placeholder: t('pages_AccountManagement_CreateAccount.firstNameKatakanaPlaceholder'),
      isRequired: false,
      labelText: t('pages_AccountManagement_CreateAccount.firstNameKatakana'),
      subfix: 'iconEdit.svg',
      name: 'firstNameKana',
      type: 'DEFAULT',
    },
  ];
  return (
    <>
      <Grid style={styles.marginBottom20}>
        <Col size={4}>
          <Text style={styles.colorWhite}>{t('pages_AccountManagement_CreateAccount.fullName')}</Text>
        </Col>
        <Col size={8}></Col>
      </Grid>

      {fullNameData.map((i) => (
        <AccountInput
          disable={disable}
          key={i.name}
          control={control}
          name={i.name}
          placeholder={i.placeholder}
          isRequired={i.isRequired}
          labelText={i.labelText}
          subfix={i.subfix}
          ml={12}
        />
      ))}
    </>
  );
};

export default FullName;

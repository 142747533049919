import React, { useState } from 'react';
import { View, StyleSheet, TextInput, Image } from 'react-native';
import { useHistory, useLocation } from 'react-router-dom';

import { COLOR_TEXT_DEFAULT, TEXT_COLOR_HEADER_TABLE } from '@assets/style/styleDefault';
import HeaderAvatar from './HeaderAvatar';

const HeaderSearch = ({ placeholder, params, setParams, linkBack, category, onSearch = () => {}, value = '' }) => {
  const history = useHistory();
  const { pathname } = useLocation();
  const [text, onChangeText] = useState(value);
  const handleSearch = ({ nativeEvent: { key: keyValue } }) => {
    if (keyValue === 'Enter') {
      if (pathname?.includes('details')) {
        history.push(`${linkBack}?q=${text}`);
      } else if (pathname.includes('file-manager')) {
        setParams({ ...params, q: text?.trim(), category });
      } else {
        setParams({ ...params, q: text?.trim(), pageNumber: 1 });
      }
      onSearch();
    }
  };

  return (
    <View style={styles.container}>
      <View style={styles.wrapperInputSearch}>
        <Image style={styles.iconSearch} source={require('@assets/icons/iconSearch.svg')} />
        <TextInput
          placeholderTextColor={TEXT_COLOR_HEADER_TABLE}
          style={styles.input}
          placeholder={placeholder}
          onChangeText={onChangeText}
          onKeyPress={handleSearch}
          onBlur={handleSearch}
          value={text}
        />
      </View>
      <HeaderAvatar />
    </View>
  );
};

export default HeaderSearch;

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    height: 70,
  },
  wrapperInputSearch: {
    marginLeft: 20,
    height: 42,
    borderBottomWidth: 1,
    borderBottomColor: '#5C5C5C',
    width: '70%',
    paddingBottom: 15,
    paddingTop: 10,
    position: 'relative ',
  },
  wrapperAvatar: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginLeft: 20,
    height: 42,
    width: '100%',
    paddingBottom: 15,
    paddingTop: 15,
  },
  avatarText: {
    color: '#fff',
  },
  input: {
    outlineStyle: 'none',
    paddingLeft: 40,
    color: COLOR_TEXT_DEFAULT,
    fontFamily: 'NotoSansJP',
    fontSize: '16px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    minHeight: 30,
    paddingVertical: 10,
  },
  iconSearch: {
    width: 20,
    height: 20,
    position: 'absolute',
    top: '60%',
    transform: 'translateY(-50%)',
    left: 10,
  },
  circleAvatar: {
    width: 10,
    height: 10,
    marginRight: 10,
  },
  avatarImg: {
    width: 50,
    height: 50,
    marginLeft: 10,
  },
});

import React, { useEffect, useState } from 'react';
import { View, Image, ScrollView } from 'react-native';
import { Col, Grid } from 'react-native-easy-grid';
import { withNamespaces } from 'react-i18next';

import HeaderAccountSetting from '@components/Headers/HeaderAccountSetting';
import HeaderAvatar from '@components/Headers/HeaderAvatar';
import { JText as Text } from '@components/common/JText';
import Card from './partials/Card';
import OverviewTable from './partials/OverviewTable';
import DoughnutTable from './partials/DoughnutTable';
import PieChar from './partials/PieChar';
import Overview from './partials/Overview';
import styles from './styles';
import { get } from '@utils/RequestLevica';
import { API_PATH } from '@helper/constant';
import { useQuery } from '@tanstack/react-query';
import { formatDateTime, formatNumber } from '@helper/formatTypes';
import Loading from '@components/common/Loading';
import { useSelector } from 'react-redux';

const Home = ({ t }) => {
  const userInfo = useSelector((state) => state.merchantUserInfo);
  const [time, setTime] = useState(() => {
    return new Date();
  });

  useEffect(() => {
    const getTime = setInterval(function () {
      setTime(new Date());
    }, 1000);
    return () => clearInterval(getTime);
  }, []);

  const getDashboardData = async ({ signal }) => {
    return await get(API_PATH.DASHBOARD, { signal });
  };

  const {
    data: dashboardData,
    isFetching,
    refetch,
    remove,
  } = useQuery(['getDashboardData'], getDashboardData, {
    refetchOnWindowFocus: false,
  });

  const summaryTable = [
    {
      content: t('pages_Home.personalAccount'),
      value: formatNumber(dashboardData?.usersCount),
      bgImg: 'bg-green.png',
      link: '/accounts',
    },
    {
      content: t('pages_Home.corporateAccount'),
      value: formatNumber(dashboardData?.issuersCount),
      bgImg: 'bg-blue.png',
      link: '/companies',
    },
    {
      content: t('pages_Home.shopAccount'),
      value: formatNumber(dashboardData?.merchantsCount),
      bgImg: 'bg-red.png',
      link: '/shop',
    },
    {
      content: t('pages_Home.totalSettlementAmount'),
      value: '¥ ' + formatNumber(dashboardData?.totalPaymentAmount),
      bgImg: 'bg-purple.png',
      link: '#',
    },
  ];

  const dataOverviewTable = [
    { content: t('pages_Home.newUser'), value: formatNumber(dashboardData?.newUsersIssuersCount) },
    { content: t('pages_Home.new0MerchantAccounts'), value: formatNumber(dashboardData?.newMerchantsCount) },
    { content: t('pages_Home.numberOfRefundRequests'), value: formatNumber(dashboardData?.requestRefundsCount) },
    {
      content: t('pages_Home.depositWithdrawalConfirmation'),
      value: formatNumber(dashboardData?.requestAdminConfirmCount),
    },
    { content: t('pages_Home.numberOfTransactions'), value: formatNumber(dashboardData?.transactionsCount) },
  ];

  const dataDoughnutTable = [
    { color: '#06C9AB', content: t('pages_Home.homePayment'), value: '100' },
    { color: '#6A81FF', content: t('pages_Home.thirdPayments'), value: 0 },
    { color: '#5730FC', content: t('pages_Home.sameTrade'), value: 0 },
    { color: '#DB4395', content: t('pages_Home.revocationDueToExpiration'), value: 0 },
    // { color: '#C780F0', content: t('pages_Home.LEVICALock'), value: dashboardData?.pendingPointForTransaction },
    { color: '#C780F0', content: t('pages_Home.LEVICALock'), value: 0 },
    { color: '#9D4DCC', content: t('pages_Home.trance'), value: 0 },
  ];

  let totalDataDoughnutTable = 0;
  dataDoughnutTable.forEach((e) => (totalDataDoughnutTable += e?.value));

  const caculatorPerc = (value, total) => {
    // const result = (value / total * 100)
    // if (parseInt(result) === result) {
    //   return result;
    // }
    // return result.toFixed(1);
    return value;
  };

  useEffect(() => remove, [remove]);

  return (
    <View style={styles.container}>
      <ScrollView showsVerticalScrollIndicator={false}>
        {isFetching || userInfo?.isLoaded ? (
          <Loading />
        ) : (
          <View style={styles.homePage}>
            <View style={styles.header}>
              <HeaderAccountSetting textHeader={'DASHBOARD'} avatar={<HeaderAvatar />} />
            </View>

            <View>
              <View style={styles.summary}>
                <Text style={styles.textHeaderTittle}> {t('pages_Home.summary')} </Text>
                <View style={styles.summaryTime}>
                  <Image style={styles.summaryTimeImg} source={require('@assets/icons/clockIcon.svg')} />
                  <Text style={[styles.textHeaderTittle, { color: '#939393' }]}>{formatDateTime(time)}</Text>
                </View>
              </View>
              <View style={styles.card}>
                {summaryTable.map((data, index) => (
                  <Card bgImg={data.bgImg} number={data.value} content={data.content} key={index} link={data.link} />
                ))}
              </View>
              <View>
                <Overview dashboardData={dashboardData} refetch={refetch} />
              </View>
              <Grid>
                <Col>
                  <View style={styles.OverviewTable}>
                    {dataOverviewTable?.map((item, index, { length }) => {
                      return (
                        <OverviewTable
                          lastItem={length === index + 1}
                          content={item.content}
                          value={item.value}
                          key={index}
                        />
                      );
                    })}
                  </View>
                </Col>
                <Col>
                  <Grid>
                    <Col size={40} style={styles.doughnutChar}>
                      <PieChar
                        data={dataDoughnutTable}
                        width={190}
                        height={190}
                        innerRadius={55}
                        outerRadius={95}
                        dashboardData={dashboardData}
                      />
                    </Col>
                    <Col size={60} style={styles.doughnutTableDetail}>
                      {dataDoughnutTable?.map((item, index) => {
                        return (
                          <DoughnutTable
                            content={item.content}
                            value={caculatorPerc(item.value, totalDataDoughnutTable)}
                            color={item.color}
                            key={index}
                          />
                        );
                      })}
                    </Col>
                  </Grid>
                </Col>
              </Grid>
            </View>
          </View>
        )}
      </ScrollView>
    </View>
  );
};

export default withNamespaces()(Home);

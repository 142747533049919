import React from 'react';
import { Center, Radio } from 'native-base';

const RadioCustom = ({ setDocumentActive, documentActive, value }) => {
  return (
    <Center flex={1}>
      <Radio.Group
        name="myRadioGroup"
        accessibilityLabel={'accessibilityLabel'}
        value={documentActive}
        onChange={() => setDocumentActive(value)}
      >
        <Radio
          accessibilityLabel={'accessibilityLabel'}
          bg="BG_CHECKBOX"
          _icon={{ color: 'CHECKBOX_COLOR' }}
          value={value}
        />
      </Radio.Group>
    </Center>
  );
};

export default RadioCustom;

export const HTTP_STATUS = Object.freeze({ UNAUTHORIZED: 401, ROLE_UNAUTHORIZED: '5017' });
export const STORAGEKEY = Object.freeze({
  ACCESS_TOKEN: 'access_token',
  REFRESH_TOKEN: 'refresh_token',
});
export const API_PATH = Object.freeze({
  //public url
  LOGIN: 'v0.2/admin/login',
  VERIFY_OTP_LOGIN: 'v0.2/admin/verifyLoginOtp',
  FORGOT_PASSWORD: 'v0.2/admin/forgotPassword',
  FORGOT_EMAIL: 'v0.2/admin/forgotEmail',
  RESET_PASSWORD: 'v0.2/admin/resetPassword',
  REFRESH_TOKEN: 'v0.2/admin/refreshToken',
  PASSCODE_AUTH: 'v0.2/admin/passcodeAuth',
  //private url
  POST_CODE: 'v0.2/postalCodes',
  SHOP_ACCOUNT: 'v0.2/admin/merchants',
  SHOP_ACCOUNT_BANK_TYPES: '/v0.2/bank-types',
  MANAGEMENT_ACCOUNT: 'v0.2/admin/users',
  COMPANY_ACCOUNT: 'v0.2/admin/issuers',
  PAYMENT_CONFIRMED: 'v0.2/admin/transactionConfirmation',
  PAYMENT_CONFIRMATION_REQUEST: 'v0.2/admin/paymentConfirmationRequests',
  PAYMENT_CONFIRMED_EXPORT_CSV_ALL: 'v0.2/admin/exportTransactionConfirmation',
  PAYMENT_CONFIRMATION_REQUESTS_EXPORT_CSV: 'v0.2/admin/exportPaymentConfirmationRequests',
  PAYMENT_CONFIRMATION_TRANSFER_POINT: 'v0.2/admin/transferPoint',
  CONFIRM_PAYMENT_REQUESTS: 'v0.2/admin/confirmPaymentRequests',
  PAID_BURN: 'v0.2/admin/burnTransactionConfirmation',
  BURN_POINT: 'v0.2/admin/pointBurn',
  TRANSFER_POINT_TO_USER: 'v0.2/admin/pointTransferToUser',
  TRANSFER_POINT_TO_ADMIN: 'v0.2/admin/pointTransferToAdmin',
  TRANSFER_POINT_TO_MASTER: 'v0.2/admin/pointTransferToMaster',
  USER_BY_CODE: 'v0.2/admin/user/authCode',
  ADMIN_INFO: 'v0.2/admin',
  ADMIN_LIST: 'v0.2/admin/list',
  ADMIN_PROFILE: 'v0.2/admin/profile',
  SETTING_ROLE: 'v0.2/admin/settingRole',
  DASHBOARD: 'v0.2/admin/dashboard',
  HEARD_INFO: 'v0.2/admin/headerProfile',
  TRANSACTION_INFORMATION: 'v0.2/admin/transaction',
  EXPORT_TRANSACTION: 'v0.2/admin/exportTransaction',
  CONTRACT_FILE: 'v0.2/contractFile',
  INFORMATION: '/v0.2/information',
  PAYMENT_CONFIRMATION_IMPORT_CSV: '/v0.2/admin/uploadPaymentConfirmationCSV',
  SYSTEM_SETTING: 'v0.2/admin/setting',
  TEMPLATES_SMS: 'v0.2/admin/templates/sms',
  TEMPLATES_EMAIL: 'v0.2/admin/templates/email',
  TEMPLATES_STATISTICS: 'v0.2/admin/templates/statistics',
  TEMPLATES_MASTER_DATA: 'v0.2/admin/templates/masterData',
  SUM_REFUND_HISTORY: 'v0.2/admin/sumRefundHistory',
  SUM_REFUND_HISTORY_CONFIRM: 'v0.2/admin/sumRefundHistory/confirm',
  SUM_REFUND_HISTORY_EXPORT_CSV: 'v0.2/admin/sumRefundHistoryExportCSV',
});

export const ROLE_USER = Object.freeze({
  PUBLIC: '*',
  SETTING_ROLE: 'SETTING_ROLE',
  CREATE_ADMIN: 'CREATE_ADMIN',
  DELETE_ADMIN: 'DELETE_ADMIN',
  USER_MANAGEMENT: 'USER_MANAGEMENT',
  USER_EKYC_TYPE_MANAGEMENT: 'USER_EKYC_TYPE_MANAGEMENT',
  NUMBER_CARD_MANAGEMENT: 'NUMBER_CARD_MANAGEMENT',
  EDIT_TOKEN_INFO: 'EDIT_TOKEN_INFO',
  UPDATE_TOKEN_STATUS: 'UPDATE_TOKEN_STATUS',
  PUBLISH_TOKEN: 'PUBLISH_TOKEN',
  BURN_TOKEN: 'BURN_TOKEN',
  PUBLISH_WALLET_OPERATION: 'PUBLISH_WALLET_OPERATION',
  ADMIN_WALLET_OPERATION: 'ADMIN_WALLET_OPERATION',
  USER_WALLET_OPERATION: 'USER_WALLET_OPERATION',
  APPROVAL_TRANSFER: 'APPROVAL_TRANSFER',
  DOCUMENT_MANAGEMENT: 'DOCUMENT_MANAGEMENT',
});

import React from 'react';
import { TouchableOpacity, View } from 'react-native';
import { useFormContext } from 'react-hook-form';
import { Col, Grid } from 'react-native-easy-grid';
import { withNamespaces } from 'react-i18next';

import AccountInput from '@components/AccountComponents/AccountInput';
import AccountSelect from '@components/AccountComponents/AccountSelect';
import InputImage from '@components/AccountComponents/InputImage';
import FullName from './partials/FullName';
import { JText as Text } from '@components/common/JText';
import styles from './styles';
import JInput from '@components/common/JInput';
import LocationForm from './partials/LocationForm';
import ContactForm from './partials/ContactForm';
import TransactionPersonForm from './partials/TransactionPersonForm';
import TransactionContactForm from './partials/TransactionContactForm';
import { COLOR_TEXT_DEFAULT } from '@assets/style/styleDefault';

const FromIndividual = (props) => {
  const { control, setValue, formValue, accountId, t, disableForm } = props;
  const { getValues } = useFormContext();
  //id to numberOfEmployees
  const formIndividualData = [
    {
      placeholder: t('pages_AccountCompany_CreateAccount.emailLoginPlaceholder'),
      isRequired: true,
      labelText: t('pages_AccountCompany_CreateAccount.emailLogin'),
      subfix: 'iconEdit.svg',
      name: 'issuerUserEmail',
      type: 'DEFAULT',
    },
    {
      placeholder: t('pages_AccountCompany_CreateAccount.accountNamePlaceholder'),
      isRequired: true,
      labelText: t('pages_AccountCompany_CreateAccount.accountName'),
      subfix: 'iconEdit.svg',
      name: 'name',
      type: 'DEFAULT',
    },
    {
      placeholder: t('pages_AccountCompany_CreateAccount.avatarPlaceholder'),
      isRequired: false,
      labelText: t('pages_AccountCompany_CreateAccount.avatar'),
      subfix: 'iconEdit.svg',
      name: 'icon',
      type: 'IMAGE_PICKER',
    },
    {
      placeholder: t('pages_AccountCompany_CreateAccount.companyNamePlaceholder'),
      isRequired: true,
      labelText: t('pages_AccountCompany_CreateAccount.companyName'),
      subfix: 'iconEdit.svg',
      name: 'tradeName',
      type: 'DEFAULT',
    },

    {
      placeholder: t('pages_AccountCompany_CreateAccount.corporationTypePlaceholder'),
      isRequired: true,
      labelText: t('pages_AccountCompany_CreateAccount.corporationType'),
      subfix: 'iconEdit.svg',
      name: 'corporationType',
      type: 'SELECT',
      selectItems: [
        { value: '1', label: t('typeOfLegalEntify.type_01') },
        { value: '2', label: t('typeOfLegalEntify.type_02') },
        { value: '3', label: t('typeOfLegalEntify.type_03') },
        { value: '4', label: t('typeOfLegalEntify.type_04') },
        { value: '5', label: t('typeOfLegalEntify.type_05') },
        { value: '6', label: t('typeOfLegalEntify.type_06') },
        { value: '7', label: t('typeOfLegalEntify.type_07') },
        { value: '8', label: t('typeOfLegalEntify.type_08') },
        { value: '9', label: t('typeOfLegalEntify.type_09') },
        { value: '10', label: t('typeOfLegalEntify.type_10') },
        { value: '11', label: t('typeOfLegalEntify.type_11') },
        { value: '12', label: t('typeOfLegalEntify.type_12') },
        { value: '13', label: t('typeOfLegalEntify.type_13') },
        { value: '14', label: t('typeOfLegalEntify.type_14') },
        { value: '15', label: t('typeOfLegalEntify.type_15') },
        { value: '16', label: t('typeOfLegalEntify.type_16') },
        { value: '17', label: t('typeOfLegalEntify.type_17') },
        { value: '18', label: t('typeOfLegalEntify.type_18') },
        { value: '19', label: t('typeOfLegalEntify.type_19') },
        { value: '20', label: t('typeOfLegalEntify.type_20') },
        { value: '21', label: t('typeOfLegalEntify.type_21') },
        { value: '22', label: t('typeOfLegalEntify.type_22') },
        { value: '23', label: t('typeOfLegalEntify.type_23') },
        { value: '24', label: t('typeOfLegalEntify.type_24') },
        { value: '25', label: t('typeOfLegalEntify.type_25') },
        { value: '26', label: t('typeOfLegalEntify.type_26') },
        { value: '27', label: t('typeOfLegalEntify.type_27') },
        { value: '28', label: t('typeOfLegalEntify.type_28') },
        { value: '29', label: t('typeOfLegalEntify.type_29') },
        { value: '30', label: t('typeOfLegalEntify.type_30') },
        { value: '31', label: t('typeOfLegalEntify.type_31') },
        { value: '32', label: t('typeOfLegalEntify.type_32') },
        { value: '33', label: t('typeOfLegalEntify.type_33') },
        { value: '34', label: t('typeOfLegalEntify.type_34') },
      ],
    },
    {
      placeholder: t('pages_AccountCompany_CreateAccount.marketInfoPlaceholder'),
      isRequired: true,
      labelText: t('pages_AccountCompany_CreateAccount.marketInfo'),
      subfix: 'iconEdit.svg',
      name: 'marketInfor',
      type: 'DEFAULT',
    },
    {
      placeholder: t('pages_AccountCompany_CreateAccount.companyNumPlaceholder'),
      isRequired: false,
      labelText: t('pages_AccountCompany_CreateAccount.companyNum'),
      subfix: 'iconEdit.svg',
      name: 'corporateNumber',
      type: 'DEFAULT',
    },
    //DATE PICKER
    {
      placeholder: t('pages_AccountCompany_CreateAccount.birthdayPlaceholder'),
      isRequired: true,
      labelText: t('pages_AccountCompany_CreateAccount.birthday'),
      subfix: 'iconEdit.svg',
      name: 'establishedAt',
      type: 'DATE_PICKER',
    },
    {
      placeholder: t('pages_AccountCompany_CreateAccount.capitalPlaceholder'),
      isRequired: true,
      labelText: t('pages_AccountCompany_CreateAccount.capital'),
      subfix: 'iconEdit.svg',
      name: 'capital',
      startIcon: '¥',
      type: 'DEFAULT',
      typeMoney: true,
    },
    {
      placeholder: t('pages_AccountCompany_CreateAccount.industryPlaceholder'),
      isRequired: true,
      labelText: t('pages_AccountCompany_CreateAccount.industry'),
      subfix: null,
      name: 'industry',
      type: 'SELECT',
      selectItems: [
        { value: '1', label: t('industry.type_01') },
        { value: '2', label: t('industry.type_02') },
        { value: '3', label: t('industry.type_03') },
        { value: '4', label: t('industry.type_04') },
        { value: '5', label: t('industry.type_05') },
        { value: '6', label: t('industry.type_06') },
        { value: '7', label: t('industry.type_07') },
        { value: '8', label: t('industry.type_08') },
        { value: '9', label: t('industry.type_09') },
        { value: '10', label: t('industry.type_10') },
        { value: '11', label: t('industry.type_11') },
        { value: '12', label: t('industry.type_12') },
        { value: '13', label: t('industry.type_13') },
        { value: '14', label: t('industry.type_14') },
        { value: '15', label: t('industry.type_15') },
        { value: '16', label: t('industry.type_16') },
        { value: '17', label: t('industry.type_17') },
        { value: '18', label: t('industry.type_18') },
        { value: '19', label: t('industry.type_19') },
        { value: '20', label: t('industry.type_20') },
        { value: '21', label: t('industry.type_21') },
        { value: '22', label: t('industry.type_22') },
        { value: '23', label: t('industry.type_23') },
        { value: '24', label: t('industry.type_24') },
        { value: '25', label: t('industry.type_25') },
      ],
    },
    {
      placeholder: t('pages_AccountCompany_CreateAccount.businessContentPlaceholder'),
      isRequired: true,
      multiline: true,
      labelText: t('pages_AccountCompany_CreateAccount.businessContent'),
      subLabelText: t('pages_AccountCompany_CreateAccount.businessContent2'),
      subfix: 'iconEdit.svg',
      name: 'businessContent',
      height: 100,
      type: 'DEFAULT',
    },
    {
      placeholder: t('pages_AccountCompany_CreateAccount.homepagePlaceholder'),
      isRequired: false,
      labelText: t('pages_AccountCompany_CreateAccount.homepage'),
      subfix: 'iconEdit.svg',
      name: 'homePageUrl',
      type: 'DEFAULT',
    },
    {
      placeholder: t('pages_AccountCompany_CreateAccount.employeesNumPlaceholder'),
      isRequired: false,
      labelText: t('pages_AccountCompany_CreateAccount.employeesNum'),
      subfix: 'iconEdit.svg',
      name: 'numberOfEmployees',
      type: 'DEFAULT',
      endIcon: t('pages_AccountCompany_CreateAccount.employeesNum2'),
    },
  ];

  const renderForm = (item, control, setValue) => {
    switch (item.type) {
      case 'DEFAULT':
        return (
          <AccountInput
            key={item.name}
            placeholder={item.placeholder}
            isRequired={item.isRequired}
            labelText={item.labelText}
            subLabelText={item.subLabelText}
            control={control}
            subfix={item.subfix}
            name={item.name}
            startIcon={item.startIcon}
            endIcon={item.endIcon}
            height={item.height}
            multiline={item.multiline}
            disable={disableForm}
            typeMoney={item.typeMoney}
          />
        );
      case 'IMAGE_PICKER':
        return (
          <InputImage
            formValue={formValue}
            accountId={accountId}
            setValue={setValue}
            key={item.name}
            control={control}
            placeholder={item.placeholder}
            isRequired={item.isRequired}
            labelText={item.labelText}
            subfix={item.subfix}
            name={item.name}
            disable={disableForm}
          />
        );
      case 'SELECT':
        return (
          <AccountSelect
            key={item.name}
            control={control}
            placeholder={item.placeholder}
            isRequired={item.isRequired}
            labelText={item.labelText}
            subfix={item.subfix}
            name={item.name}
            selectItems={item?.selectItems}
            disable={disableForm}
          />
        );
      case 'DATE_PICKER':
        return (
          <AccountInput
            key={item.name}
            placeholder={item.placeholder}
            isRequired={item.isRequired}
            labelText={item.labelText}
            subLabelText={item.subLabelText}
            control={control}
            subfix={item.subfix}
            name={item.name}
            startIcon={item.startIcon}
            endIcon={item.endIcon}
            height={item.height}
            dateTimePicker={true}
            type="date"
            disable={disableForm}
          />
        );
      default:
        break;
    }
  };
  const copyClipBoard = () => {
    try {
      navigator.clipboard.writeText(formValue?.leviasChainAddress);
    } catch (error) {
      console.error('copy clipboard only work with https');
    }
  };

  return (
    <View style={{ paddingBottom: 40 }}>
      <Grid style={styles.marginBottom20}>
        <Col size={4}>
          <View style={{ flexDirection: 'row' }}>
            <Text>LEVICA ID</Text>
            {/* <Text style={{ color: 'red', paddingLeft: 5 }}>✴︎</Text> */}
          </View>
        </Col>
        <Col size={8}>
          {accountId ? (
            <Text style={{ paddingLeft: 10, color: !disableForm ? '#A5A5A5' : COLOR_TEXT_DEFAULT }}>
              {formValue?.displayId}
            </Text>
          ) : (
            <Text style={{ color: '#a5a5a5a8' }}> {t('pages_AccountCompany_CreateAccount.LEVICAid')}</Text>
          )}
        </Col>
      </Grid>
      <Grid style={styles.marginBottom20}>
        <Col size={4}>
          <View style={{ flexDirection: 'row' }}>
            <Text>{t('pages_AccountCompany_CreateAccount.walletAddress')}</Text>
          </View>
        </Col>
        <Col size={6}>
          {accountId ? (
            <TouchableOpacity activeOpacity={0.7} onPress={copyClipBoard}>
              <JInput
                paddingLeft={10}
                placeholder={t('pages_AccountCompany_CreateAccount.walletAddressNote')}
                subfix="saveClipboard.svg"
                widthSubfix={18}
                heightSubfix={18}
                control={control}
                name="leviasChainAddress"
                disable={disableForm}
                colorTextInput={!disableForm ? '#A5A5A5' : COLOR_TEXT_DEFAULT}
              />
            </TouchableOpacity>
          ) : (
            <Text style={{ color: '#a5a5a5a8' }}> {t('pages_AccountCompany_CreateAccount.walletAddressNote')}</Text>
          )}
        </Col>
        <Col size={2}></Col>
      </Grid>
      {/* form LEVICA ID to 従業員数 */}
      {formIndividualData.map((i) => renderForm(i))}
      {/* from 所在地  to 階数・号室  */}
      <LocationForm disable={disableForm} control={control} t={t} />
      {/* from 連絡先  to  */}
      <ContactForm disable={disableForm} control={control} t={t} />
      {/* from 代表者  to  */}
      <FullName disable={disableForm} control={control} t={t} />
      {/* from 取引担当者  to  */}
      <TransactionPersonForm disable={disableForm} control={control} t={t} />
      {/* from 取引担当者住所  to  */}
      <TransactionContactForm disable={disableForm} control={control} t={t} />
    </View>
  );
};

export default withNamespaces()(FromIndividual);

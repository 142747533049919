import React from 'react';
import { Col, Grid } from 'react-native-easy-grid';
import { withNamespaces } from 'react-i18next';

import AccountInput from '@components/AccountComponents/AccountInput';
import styles from '../styles';
import { JText as Text } from '@components/common/JText';

const FullName = ({ control, disable, t }) => {
  //merchantUserLastName to merchantUserTitle
  const fullNameData = [
    {
      placeholder: t('pages_AccountCompany_CreateAccount.lastNamePlaceholder'),
      isRequired: true,
      labelText: t('pages_AccountCompany_CreateAccount.lastName'),
      subfix: 'iconEdit.svg',
      name: 'issuerUserLastName',
      type: 'DEFAULT',
    },
    {
      placeholder: t('pages_AccountCompany_CreateAccount.lastNameKatakanaPlaceholder'),
      isRequired: true,
      labelText: t('pages_AccountCompany_CreateAccount.lastNameKatakana'),
      subfix: 'iconEdit.svg',
      name: 'issuerUserLastNameKana',
      type: 'DEFAULT',
    },
    {
      placeholder: t('pages_AccountCompany_CreateAccount.firstNamePlaceholder'),
      isRequired: true,
      labelText: t('pages_AccountCompany_CreateAccount.firstName'),
      subfix: 'iconEdit.svg',
      name: 'issuerUserFirstName',
      type: 'DEFAULT',
    },
    {
      placeholder: t('pages_AccountCompany_CreateAccount.firstNameKatakanaPlaceholder'),
      isRequired: true,
      labelText: t('pages_AccountCompany_CreateAccount.firstNameKatakana'),
      subfix: 'iconEdit.svg',
      name: 'issuerUserFirstNameKana',
      type: 'DEFAULT',
    },
    {
      placeholder: t('pages_AccountCompany_CreateAccount.titlePlaceholder'),
      isRequired: true,
      labelText: t('pages_AccountCompany_CreateAccount.title'),
      subfix: 'iconEdit.svg',
      name: 'issuerUserTitle',
      type: 'DEFAULT',
    },
  ];
  return (
    <>
      <Grid style={styles.marginBottom20}>
        <Col size={4}>
          <Text style={styles.colorWhite}>{t('pages_AccountCompany_CreateAccount.fullName')}</Text>
        </Col>
        <Col size={8}></Col>
      </Grid>

      {fullNameData.map((i) => (
        <AccountInput
          disable={disable}
          key={i.name}
          control={control}
          name={i.name}
          placeholder={i.placeholder}
          isRequired={i.isRequired}
          labelText={i.labelText}
          subfix={i.subfix}
          ml={12}
        />
      ))}
    </>
  );
};

export default withNamespaces()(FullName);

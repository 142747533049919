import React from 'react';
import { View, Image, StyleSheet } from 'react-native';
import Modal from 'react-native-modal';
import { withNamespaces } from 'react-i18next';

import JButton from '../common/JButton';
import { JText as Text } from '../common/JText';
import ModalBackDrop from './ModalBackDrop';

const ModalSettingSuccess = ({ isModal, setIsModal, setIsLoading, t }) => {
  const handleCancel = () => {
    setIsModal(false);
  };
  return (
    <View>
      <Modal
        animationIn="fadeIn"
        isVisible={isModal}
        style={{ alignItems: 'center' }}
        customBackdrop={<ModalBackDrop onPress={() => setIsModal(false)} />}
      >
        <View style={styles.modalSettingSuccess}>
          <View style={styles.headerModal}>
            <View style={styles.flexRow}>
              <Image style={styles.iconcircleSuccess} source={require(`../../assets/icons/circleSuccess.svg`)} />
              <Text style={[styles.textModal, { fontSize: 16 }]}>
                {t('components_Modals_ModalSettingSuccess.textModal')}
              </Text>
            </View>
          </View>
          <View style={styles.contentModal}>
            <Text style={styles.textColorContent}>{t('components_Modals_ModalSettingSuccess.textColorContent')}</Text>
          </View>
          <View style={styles.wrapperInfo}>
            <View style={styles.infoSuccess}>
              <Text>{t('components_Modals_ModalSettingSuccess.infoSuccess1')}</Text>
              <Text>¥ 5,390</Text>
            </View>
            <View style={styles.infoSuccess}>
              <Text>{t('components_Modals_ModalSettingSuccess.infoSuccess2')}</Text>
              <Text> ¥ 999,994,609</Text>
            </View>
          </View>
          <View style={styles.buttonModal}>
            <JButton
              linkIcon="iconCloseModal.svg"
              heightIcon={15}
              widthIcon={15}
              text={t('commonText.cancelButton')}
              width={200}
              textColor="#fff"
              btnColor="#56575F"
              onPressAction={handleCancel}
            />
            <JButton
              text={t('components_Modals_ModalSettingSuccess.buttonText')}
              width={200}
              linkIcon="iconMedia.svg"
              widthIcon={25}
              heightIcon={25}
              textColor="#fff"
              btnColor="#2A74DB"
              onPressAction={() => setIsLoading()}
            />
          </View>
        </View>
      </Modal>
    </View>
  );
};

const styles = StyleSheet.create({
  flexRow: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  modalSettingSuccess: {
    backgroundColor: '#fff',
    minHeight: 300,
    minWidth: 500,
    borderRadius: 5,
  },
  headerModal: {
    height: 50,
    border: '1px solid transparent',
    borderBottomColor: '#E0E0E0',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  iconcircleSuccess: {
    height: 20,
    width: 20,
    marginRight: 10,
  },
  contentModal: {
    marginTop: 35,
    alignItems: 'center',
    flexDirection: 'column',
  },
  textColorContent: {
    color: '#5A5A5A',
  },
  textColorContentRed: {
    color: '#FF0000',
  },
  wrapperInfo: {
    marginTop: 20,
    paddingLeft: '3rem',
    paddingRight: '3rem',
  },
  infoSuccess: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: 10,
  },
  buttonModal: {
    height: 90,
    flexDirection: 'row',
    alignItems: 'end',
    justifyContent: 'space-around',
    marginBottom: 25,
  },
});

export default withNamespaces()(ModalSettingSuccess);

import React from 'react';
import { View, Image, StyleSheet } from 'react-native';
import Modal from 'react-native-modal';
import { withNamespaces } from 'react-i18next';

import { JText as Text } from '../common/JText';
import ModalBackDrop from './ModalBackDrop';
import { Col } from 'react-native-easy-grid';
import { Row, ScrollView } from 'native-base';
import { formatTime } from '@helper/formatTypes';

const ModalInformationPreview = ({ isModal, setIsModal, getInfoData, image, t }) => {
  const infoData = { ...getInfoData(), image };

  return (
    <View>
      <Modal
        animationIn="fadeIn"
        isVisible={isModal}
        style={{ alignItems: 'center' }}
        customBackdrop={<ModalBackDrop onPress={() => setIsModal(false)} />}
      >
        <View style={styles.container}>
          <View style={styles.borderPhone}>
            <View style={styles.phoneContainer}>
              <View style={styles.topContainer}>
                <View>
                  <Row>
                    <Col style={{ alignItems: 'center' }}>
                      <Text style={[styles.textWhite, styles.paddingCustom]}>{formatTime(new Date())}</Text>
                    </Col>
                    <Col style={[{ alignItems: 'center' }]}>
                      <View style={styles.camera}>
                        <View style={styles.itemCameraTop}></View>
                        <View style={styles.itemCameraBottom}></View>
                      </View>
                    </Col>
                    <Col style={{ alignItems: 'center' }}>
                      <Row alignItems={'center'}>
                        <Image
                          source={require('./../../assets/icons/mobileSignal.svg')}
                          style={{ width: 20, height: 10 }}
                        ></Image>
                        <Text style={[styles.textWhite, styles.paddingCustom]}>4G</Text>
                        <Image
                          source={require('./../../assets/icons/mobileBattery.svg')}
                          style={{ width: 20, height: 10 }}
                        ></Image>
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Col size={30}>
                      <Text style={[styles.textWhite, styles.paddingCustom]}>
                        <Image
                          source={require('./../../assets/icons/arrowLeftWhite.svg')}
                          style={{ width: 10, height: 10, marginLeft: 10 }}
                        ></Image>{' '}
                        戻る
                      </Text>
                    </Col>
                    <Col style={{ alignItems: 'center' }} size={40}>
                      <Text style={[styles.textWhite, styles.paddingCustom]}>お知らせ詳細</Text>
                    </Col>
                    <Col style={{ alignItems: 'center' }} size={30}></Col>
                  </Row>
                </View>
              </View>
              <ScrollView showsVerticalScrollIndicator={false}>
                <View style={styles.middleContainer}>
                  <View>
                    <View>
                      <Text style={[styles.textBlack, styles.textBold]}>{infoData?.title}</Text>
                    </View>
                    <View>
                      <Text style={[styles.textBlack, styles.textMini]}>{infoData?.date + ' ' + infoData?.time}</Text>
                    </View>
                  </View>
                  <View style={[styles.paragraph]}>
                    <Image
                      style={[{ width: '100%', height: '200px' }]}
                      resizeMode={'contain'}
                      source={infoData.image}
                    />
                  </View>
                  <View style={[styles.paragraph]}>
                    <View>
                      <Text style={[styles.textBlack]}>{infoData?.body}</Text>
                    </View>
                  </View>
                </View>
              </ScrollView>
              <View style={styles.bottomContainer}></View>
            </View>
          </View>
        </View>
      </Modal>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    paddingTop: '7px',
    paddingBottom: '7px',
    backgroundColor: 'white',
    width: '300px',
    borderRadius: 40,
    borderColor: '#a1a1a3',
    borderWidth: '5px',
    height: '550px',
    position: 'relative',
  },
  textWhite: {
    color: 'white',
    fontSize: '13px',
  },
  textBlack: {
    color: 'black',
    fontSize: '13px',
  },
  textBold: {
    fontWeight: 'bold',
  },
  camera: {
    width: '100px',
    height: '25px',
    backgroundColor: 'black',
    borderBottomLeftRadius: '20px',
    borderBottomRightRadius: '20px',
    alignItems: 'center',
  },
  itemCameraTop: {
    width: 40,
    height: 6,
    backgroundColor: '#3e3a39',
  },
  itemCameraBottom: {
    width: 8,
    height: 8,
    backgroundColor: '#3e3a39',
    borderRadius: '100%',
    marginTop: 3,
    marginLeft: 33,
  },
  paddingCustom: {
    padding: 7,
  },
  title: {
    fontSize: 24,
    fontWeight: 'bold',
    marginBottom: 20,
  },
  phoneContainer: {
    width: '100%',
    height: 500,
    position: 'relative',
  },
  topContainer: {
    backgroundColor: '#2dbe85',
    height: 70,
    borderTopLeftRadius: 25,
    borderTopRightRadius: 25,
  },
  middleContainer: {
    padding: '17px',
  },
  bottomContainer: {
    backgroundColor: 'black',
    height: '4px',
    width: '50%',
    position: 'absolute',
    left: '50%',
    transform: 'translateX(-50%);',
    bottom: '1%',
  },
  paragraph: {
    paddingTop: 10,
    paddingBottom: 10,
  },
  textMini: {
    marginTop: 10,
    fontSize: 10,
    color: 'gray',
  },
  borderPhone: {
    width: '100%',
    backgroundColor: 'white',
    borderRadius: 36,
    borderColor: 'black',
    borderWidth: '10px',
    position: 'absolute',
    top: '0',
    bottom: 0,
  },
});

export default withNamespaces()(ModalInformationPreview);

import React from 'react';
import { View, ImageBackground } from 'react-native';
import { Col } from 'react-native-easy-grid';
import { useHistory } from 'react-router-dom';
import styles from '../styles';
import { JText as Text } from '@components/common/JText';

const Card = ({ bgImg, number, content, link }) => {
  const history = useHistory();
  const onClickCard = (linkTo) => {
    history.push(linkTo);
  };
  return (
    <Col onPress={() => onClickCard(link)} style={{ minHeight: 180 }}>
      <ImageBackground
        style={styles.CardImgBackground}
        resizeMode="cover"
        imageStyle={{ minHeight: 180 }}
        source={require(`../../../assets/image/${bgImg}`)}
      >
        <View style={styles.CardContent}>
          <Text style={styles.CardContentNumber}>{number}</Text>
          <Text style={styles.CardContentText}>{content}</Text>
        </View>
      </ImageBackground>
    </Col>
  );
};

export default Card;

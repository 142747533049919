import * as Yup from 'yup';
import { isValidPhoneNumber, parsePhoneNumber } from 'react-phone-number-input';
import { t } from 'i18next';

export const defaultValues = {
  id: '',
  leviasChainAddress: '',
  merchantUserEmail: '',
  name: '',
  icon: '',
  imageUrl: '',
  tradeName: '',
  corporationType: '',
  marketInfor: '',
  corporateNumber: '',
  establishedAt: '',
  capital: '',
  industry: '',
  businessContent: '',
  homePageUrl: '',
  numberOfEmployees: '',
  postCode: '',
  prefecture: '',
  city: '',
  address: '',
  building: '',
  floor: '',
  room: '',
  email: '',
  phone: '',
  landlineNumber: '',
  merchantUserLastName: '',
  merchantUserLastNameKana: '',
  merchantUserFirstName: '',
  merchantUserFirstNameKana: '',
  merchantUserTitle: '',
  traderDepartment: '',
  traderDirector: '',
  traderLastName: '',
  traderLastNameKana: '',
  traderFirstName: '',
  traderFirstNameKana: '',
  traderPhoneNumber: '',
  traderEmail: '',
  traderPostCode: '',
  traderPrefecture: '',
  traderCity: '',
  traderAddress: '',
  traderBuilding: '',
  traderFloor: '',
  traderRoom: '',
};

// export const defaultValues = {
//   id: '',
//   leviasChainAddress: '1231',
//   merchantUserEmail: 'demo@vm.vn',
//   name: 'demo123',
//   icon: '',
//   imageUrl: '',
//   tradeName: 'tradeName',
//   corporationType: '13',
//   marketInfor: 'marketInfor',
//   corporateNumber: '',
//   establishedAt: '2022-12-12',
//   capital: 1234567,
//   industry: '2',
//   businessContent: 'businessContent',
//   homePageUrl: '',
//   numberOfEmployees: '',
//   postCode: '0600006',
//   prefecture: 'prefecture',
//   city: 'city',
//   address: 'address',
//   building: 'building',
//   floor: '',
//   room: '',
//   email: 'email@mail.vn',
//   phone: '+81222222223',
//   landlineNumber: '+16398002628',
//   merchantUserLastName: 'merchantUserLastName',
//   merchantUserLastNameKana: 'カナ',
//   merchantUserFirstName: 'merchantUserFirstName',
//   merchantUserFirstNameKana: 'カナ',
//   merchantUserTitle: 'merchantUserTitle',
//   traderDepartment: 'traderDepartment',
//   traderDirector: 'traderDirector',
//   traderLastName: 'traderLastName',
//   traderLastNameKana: 'カナ',
//   traderFirstName: 'traderFirstName',
//   traderFirstNameKana: 'カナ',
//   traderPhoneNumber: '+16398002628',
//   traderEmail: 'email@mail.vn',
//   traderPostCode: '0600006',
//   traderPrefecture: 'traderPrefecture',
//   traderCity: 'traderCity',
//   traderAddress: 'traderAddress',
//   traderBuilding: 'traderBuilding',
//   traderFloor: '',
//   traderRoom: '',
// };

const urlValidate = /[a-z0-9-\.]+\.[a-z]{2,4}\/?([^\s<>\#%"\,\{\}\\|\\\^\[\]`]+)?$/;
const kanaValidate = /^[\u30A0-\u30FFー]+$/g;
export const accountShopSchema = () => {
  const validate = Yup.object().shape({
    //1 merchantUserEmail to numberOfEmployees =FromIndividual
    id: Yup.string().trim(),
    leviasChainAddress: Yup.string()
      .trim()
      .required(t('errorMessages.fieldRequired', { fieldName: t('commonText.walletAddress') })),
    merchantUserEmail: Yup.string()
      .trim()
      .required(t('errorMessages.fieldRequired', { fieldName: t('pages_AccountShop_CreateAccount.emailLogin') }))
      .email(t('errorMessages.TXT_EMAIL_REQUIRED'))
      .max(32, t('errorMessages.TXT_MAX_32')),
    name: Yup.string()
      .trim()
      .required(t('errorMessages.fieldRequired', { fieldName: t('pages_AccountShop_CreateAccount.accountName') }))
      .max(32, t('errorMessages.TXT_MAX_32')),
    imageUrl: Yup.string().trim(),
    tradeName: Yup.string()
      .trim()
      .required(t('errorMessages.fieldRequired', { fieldName: t('pages_AccountShop_CreateAccount.companyName') }))
      .max(32, t('errorMessages.TXT_MAX_32')),
    corporationType: Yup.string()
      .trim()
      .required(t('errorMessages.fieldRequired', { fieldName: t('pages_AccountShop_CreateAccount.corporationType') }))
      .max(32, t('errorMessages.TXT_MAX_32')),
    marketInfor: Yup.string()
      .trim()
      .required(t('errorMessages.fieldRequired', { fieldName: t('pages_AccountShop_CreateAccount.marketInfo') }))
      .max(128, t('errorMessages.TXT_MAX_128')),
    corporateNumber: Yup.string().trim().max(32, t('errorMessages.TXT_MAX_32')),
    establishedAt: Yup.string()
      .trim()
      .required(t('errorMessages.fieldRequired', { fieldName: t('pages_AccountShop_CreateAccount.birthday') })),
    capital: Yup.string()
      .trim()
      .required(t('errorMessages.fieldRequired', { fieldName: t('pages_AccountShop_CreateAccount.capital') }))
      // .matches(/^[0-9]+$/, t('errorMessages.TXT_NUMERIC'))
      .max(42, t('errorMessages.TXT_MAX_32')),
    industry: Yup.string()
      .trim()
      .required(t('errorMessages.fieldRequired', { fieldName: t('pages_AccountShop_CreateAccount.industry') }))
      .max(32, t('errorMessages.TXT_MAX_32')),
    businessContent: Yup.string()
      .trim()
      .required(t('errorMessages.fieldRequired', { fieldName: t('pages_AccountShop_CreateAccount.businessContent') }))
      .test('length validation', t('errorMessages.TXT_MAX_128'), (value) => {
        if (value) {
          return (value) => value?.replace(/\r\n/gm, '____')?.replace(/\n/gm, '____')?.length < 129;
        }
      }),
    homePageUrl: Yup.string()
      .trim()
      .test('compare ', t('errorMessages.TXT_URL_WRONG'), (url) => {
        if (url) {
          return urlValidate.test(url);
        } else {
          return true;
        }
      }),
    numberOfEmployees: Yup.string()
      .trim()
      .nullable()
      .max(32, t('errorMessages.TXT_MAX_32'))
      .test('compare ', t('errorMessages.TXT_NUMERIC'), (numberOfEmployee) => {
        if (numberOfEmployee) {
          return /^[0-9]+$/.test(numberOfEmployee);
        } else {
          return true;
        }
      }),

    //2 postCode to room =LocationForm
    postCode: Yup.string()
      .trim()
      .required(t('errorMessages.fieldRequired', { fieldName: t('pages_AccountShop_CreateAccount.zipCode') }))
      .matches(/^[0-9]+$/, t('errorMessages.TXT_POSTAL_CODE'))
      .min(7, t('errorMessages.TXT_POSTAL_CODE'))
      .max(7, t('errorMessages.TXT_POSTAL_CODE')),
    prefecture: Yup.string()
      .trim()
      .max(128, t('errorMessages.TXT_MAX_128'))
      .required(t('errorMessages.fieldRequired', { fieldName: t('pages_AccountShop_CreateAccount.prefectureCode') })),
    city: Yup.string()
      .trim()
      .max(128, t('errorMessages.TXT_MAX_128'))
      .required(t('errorMessages.fieldRequired', { fieldName: t('pages_AccountShop_CreateAccount.municipality') })),
    address: Yup.string()
      .trim()
      .max(128, t('errorMessages.TXT_MAX_128'))
      .required(t('errorMessages.fieldRequired', { fieldName: t('pages_AccountShop_CreateAccount.address') })),
    building: Yup.string().trim().max(128, t('errorMessages.TXT_MAX_128')),
    floor: Yup.string().trim().max(32, t('errorMessages.TXT_MAX_32')),
    room: Yup.string().trim().max(32, t('errorMessages.TXT_MAX_32')),

    //3 email  to landlineNumber =ContactForm
    email: Yup.string()
      .trim()
      .email(t('errorMessages.TXT_EMAIL_REQUIRED'))
      .max(32, t('errorMessages.TXT_MAX_32'))
      .required(t('errorMessages.fieldRequired', { fieldName: t('pages_AccountShop_CreateAccount.email') })),
    phone: Yup.string()
      .trim()
      .nullable()
      .test('compare ', t('errorMessages.TXT_JP_PHONE'), (phone) => {
        if (phone && parsePhoneNumber(phone)?.nationalNumber) {
          return isValidPhoneNumber(phone);
        } else {
          return true;
        }
      })
      .required(t('errorMessages.fieldRequired', { fieldName: t('pages_AccountShop_CreateAccount.mobileNumber') })),
    landlineNumber: Yup.string()
      .trim()
      .nullable()
      .test('compare ', t('errorMessages.TXT_JP_PHONE'), (phone) => {
        if (phone) {
          if (phone && parsePhoneNumber(phone)?.nationalNumber) {
            return isValidPhoneNumber(phone);
          } else {
            return true;
          }
        } else {
          return true;
        }
      })
      .required(t('errorMessages.fieldRequired', { fieldName: t('pages_AccountShop_CreateAccount.landlineNumber') })),

    //4 merchantUserLastName to merchantUserTitle =FullName
    merchantUserLastName: Yup.string()
      .trim()
      .required(t('errorMessages.fieldRequired', { fieldName: t('pages_AccountShop_CreateAccount.lastName') }))
      .max(32, t('errorMessages.TXT_MAX_32')),
    merchantUserLastNameKana: Yup.string()
      .trim()
      .required(t('errorMessages.fieldRequired', { fieldName: t('pages_AccountShop_CreateAccount.lastNameKatakana') }))
      .matches(kanaValidate, t('errorMessages.TEXT_ONLY_KANA'))
      .max(32, t('errorMessages.TXT_MAX_32')),
    merchantUserFirstName: Yup.string()
      .trim()
      .max(32, t('errorMessages.TXT_MAX_32'))
      .required(t('errorMessages.fieldRequired', { fieldName: t('pages_AccountShop_CreateAccount.firstName') })),
    merchantUserFirstNameKana: Yup.string()
      .trim()
      .matches(kanaValidate, t('errorMessages.TEXT_ONLY_KANA'))
      .required(t('errorMessages.fieldRequired', { fieldName: t('pages_AccountShop_CreateAccount.firstNameKatakana') }))
      .max(32, t('errorMessages.TXT_MAX_32')),
    merchantUserTitle: Yup.string()
      .trim()
      .max(32, t('errorMessages.TXT_MAX_32'))
      .required(t('errorMessages.fieldRequired', { fieldName: t('pages_AccountShop_CreateAccount.title') })),
    //5 traderDepartment to traderEmail =TransactionPersonForm
    traderDepartment: Yup.string()
      .trim()
      .max(32, t('errorMessages.TXT_MAX_32'))
      .required(
        t('errorMessages.fieldRequired', { fieldName: t('pages_AccountShop_CreateAccount.transactionDepartment') })
      ),
    traderDirector: Yup.string()
      .trim()
      .max(32, t('errorMessages.TXT_MAX_32'))
      .required(t('errorMessages.fieldRequired', { fieldName: t('pages_AccountShop_CreateAccount.transactionTitle') })),
    traderLastName: Yup.string()
      .trim()
      .max(32, t('errorMessages.TXT_MAX_32'))
      .required(
        t('errorMessages.fieldRequired', { fieldName: t('pages_AccountShop_CreateAccount.transactionLastName') })
      ),
    traderLastNameKana: Yup.string()
      .trim()
      .max(32, t('errorMessages.TXT_MAX_32'))
      .required(
        t('errorMessages.fieldRequired', { fieldName: t('pages_AccountShop_CreateAccount.transactionLastNameKana') })
      )
      .matches(kanaValidate, t('errorMessages.TEXT_ONLY_KANA')),
    traderFirstName: Yup.string()
      .trim()
      .max(32, t('errorMessages.TXT_MAX_32'))
      .required(
        t('errorMessages.fieldRequired', { fieldName: t('pages_AccountShop_CreateAccount.transactionFirstName') })
      ),
    traderFirstNameKana: Yup.string()
      .trim()
      .max(32, t('errorMessages.TXT_MAX_32'))
      .required(
        t('errorMessages.fieldRequired', { fieldName: t('pages_AccountShop_CreateAccount.transactionFirstNameKana') })
      )
      .matches(kanaValidate, t('errorMessages.TEXT_ONLY_KANA')),
    traderPhoneNumber: Yup.string()
      .trim()
      .nullable()
      .test('compare ', t('errorMessages.TXT_JP_PHONE'), (phone) => {
        if (phone) {
          return isValidPhoneNumber(phone);
        } else {
          return true;
        }
      })
      .required(
        t('errorMessages.fieldRequired', { fieldName: t('pages_AccountShop_CreateAccount.transactionPhoneNumber') })
      ),
    traderEmail: Yup.string()
      .trim()
      .email(t('errorMessages.TXT_EMAIL_REQUIRED'))
      .max(32, t('errorMessages.TXT_MAX_32'))
      .required(t('errorMessages.fieldRequired', { fieldName: t('pages_AccountShop_CreateAccount.transactionEmail') })),
    //6 traderPostCode to traderRoom=TransactionContactForm
    traderPostCode: Yup.string()
      .trim()
      .required(t('errorMessages.fieldRequired', { fieldName: t('pages_AccountCompany_CreateAccount.zipCode') }))
      .matches(/^[0-9]+$/, t('errorMessages.TXT_POSTAL_CODE'))
      .min(7, t('errorMessages.TXT_POSTAL_CODE'))
      .max(7, t('errorMessages.TXT_POSTAL_CODE')),
    traderPrefecture: Yup.string()
      .trim()
      .max(32, t('errorMessages.TXT_MAX_32'))
      .required(
        t('errorMessages.fieldRequired', { fieldName: t('pages_AccountCompany_CreateAccount.transactionPrefectures') })
      ),
    traderCity: Yup.string()
      .trim()
      .max(32, t('errorMessages.TXT_MAX_32'))
      .required(
        t('errorMessages.fieldRequired', { fieldName: t('pages_AccountCompany_CreateAccount.transactionMunicipality') })
      ),
    traderAddress: Yup.string()
      .trim()
      .max(32, t('errorMessages.TXT_MAX_32'))
      .required(
        t('errorMessages.fieldRequired', { fieldName: t('pages_AccountCompany_CreateAccount.transactionAddress') })
      ),
    traderBuilding: Yup.string()
      .trim()
      .max(32, t('errorMessages.TXT_MAX_32'))
      .required(
        t('errorMessages.fieldRequired', { fieldName: t('pages_AccountCompany_CreateAccount.transactionBuildingName') })
      ),
    traderFloor: Yup.string().trim().max(32, t('errorMessages.TXT_MAX_32')),
    traderRoom: Yup.string().trim().max(32, t('errorMessages.TXT_MAX_32')),
  });
  return validate;
};

export const bankInfoDefaultValues = {
  merchantBankInfoBankName: '',
  merchantBankInfoBankCode: '',
  merchantBankInfoBranchName: '',
  merchantBankInfoBranchCode: '',
  merchantBankInfoAccountType: '',
  merchantBankInfoAccountNumber: '',
  merchantBankInfoAccountHolderName: '',
};

// export const bankInfoDefaultValues = {
//   merchantBankInfoBankName: '1',
//   merchantBankInfoBankCode: '2345',
//   merchantBankInfoBranchName: '2',
//   merchantBankInfoBranchCode: '333',
//   merchantBankInfoAccountType: '1',
//   merchantBankInfoAccountNumber: '4',
//   merchantBankInfoAccountHolderName: '5',
// };

export const bankInfoSchema = () => {
  const validate = Yup.object().shape({
    merchantBankInfoBankCode: Yup.string()
      .trim()
      .required(t('errorMessages.fieldRequired', { fieldName: t('page_Bank_Information.bankCode') }))
      .matches(/^[0-9]{4}$/, `4-${t('errorMessages.TXT_NUMERIC')}`),
    merchantBankInfoBankName: Yup.string()
      .trim()
      .max(50, t('errorMessages.TXT_MAX_50'))
      .required(t('errorMessages.fieldRequired', { fieldName: t('page_Bank_Information.bankName') })),
    merchantBankInfoBranchCode: Yup.string()
      .trim()
      .required(t('errorMessages.fieldRequired', { fieldName: t('page_Bank_Information.branchCode') }))
      .matches(/^[0-9]{3}$/, t('errorMessages.TXT_NUMERIC_WITH_NUM', { number: 3 })),
    merchantBankInfoBranchName: Yup.string()
      .trim()
      .required(t('errorMessages.fieldRequired', { fieldName: t('page_Bank_Information.branchName') }))
      .max(50, t('errorMessages.TXT_MAX_50')),
    merchantBankInfoAccountType: Yup.string()
      .trim()
      .required(t('errorMessages.fieldRequired', { fieldName: t('page_Bank_Information.accountType') })),
    merchantBankInfoAccountNumber: Yup.string()
      .trim()
      .required(t('errorMessages.fieldRequired', { fieldName: t('page_Bank_Information.bankNumber') }))
      .matches(/^[0-9]+$/, t('errorMessages.TXT_NUMERIC'))
      .matches(/^.{0,50}$/, t('errorMessages.TXT_MAX_50_NUM')),
    merchantBankInfoAccountHolderName: Yup.string()
      .trim()
      .required(t('errorMessages.fieldRequired', { fieldName: t('page_Bank_Information.holderName') }))
      .max(50, t('errorMessages.TXT_MAX_50')),
  });
  return validate;
};

import React from 'react';
import { Col, Grid } from 'react-native-easy-grid';

import AccountInput from '@components/AccountComponents/AccountInput';
import styles from './styles';
import { JText as Text } from '@components/common/JText';
import AccountInputPhone from '@components/AccountComponents/AccountInputPhone';

const ContactAddress = ({ control, disable, t }) => {
  const ContactAddressData = [
    {
      placeholder: t('pages_AccountManagement_CreateAccount.emailPlaceholder'),
      isRequired: true,
      labelText: t('pages_AccountManagement_CreateAccount.email'),
      subfix: 'iconEdit.svg',
      name: 'email',
      type: 'DEFAULT',
    },
    // {
    //   placeholder: t('pages_AccountManagement_CreateAccount.mobileNumberPlaceholder'),
    //   isRequired: true,
    //   labelText: t('pages_AccountManagement_CreateAccount.mobileNumber'),
    //   subfix: 'iconEdit.svg',
    //   name: 'phone',
    //   type: 'DEFAULT',
    // },
  ];
  return (
    <>
      <Grid style={styles.marginBottom20}>
        <Col size={4}>
          <Text style={styles.colorWhite}>{t('pages_AccountManagement_CreateAccount.contactAddress')}</Text>
        </Col>
        <Col size={8}></Col>
      </Grid>

      {ContactAddressData.map((i) => (
        <AccountInput
          disable={disable}
          key={i.name}
          control={control}
          name={i.name}
          placeholder={i.placeholder}
          isRequired={i.isRequired}
          labelText={i.labelText}
          subfix={i.subfix}
          ml={12}
        />
      ))}
      <AccountInputPhone
        labelText={t('pages_AccountManagement_CreateAccount.mobileNumber')}
        isRequired={true}
        name="phone"
        control={control}
        disable={disable}
      />
    </>
  );
};

export default ContactAddress;

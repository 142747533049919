import React, { useEffect, useMemo, useRef, useState } from 'react';
import { View, StyleSheet, Image, ScrollView } from 'react-native';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Col, Grid } from 'react-native-easy-grid';
import { useParams, useHistory } from 'react-router-dom';
import { useQuery, QueryClient } from '@tanstack/react-query';
import { withNamespaces } from 'react-i18next';
import { useSelector } from 'react-redux';

import { defaultValues, validationSchema } from './createCompanyAccountFormData';
import { get } from '@utils/RequestLevica';
import FomrIndividual from './FomrIndividual';
import HeaderAccount from '@components/Headers/HeaderAccount';
import HeaderSearch from '@components/Headers/HeaderSearch';
import { JText as Text } from '@components/common/JText';
import { BG_COLOR } from '@assets/style/styleDefault';
import WalletInfo from './FormBankInfo/WalletInfo';
import BankInfo from './FormBankInfo/BankInfo';
import MemberInfo from './MemberInfo';
import HeaderAvatar from '@components/Headers/HeaderAvatar';
import { API_PATH, ROLE_USER } from '@helper/constant';
import scroll2View from '@helper/scroll2View';
import useUpdateLangueForYup from '@helper/customHook/useUpdateLangueForYup';
import Loading from '@components/common/Loading';
import { checkPermission } from '@helper/commonFunction';

const CreateCompanyAccount = ({ t }) => {
  const history = useHistory();
  const { accountId } = useParams();
  const [formValue, setFormValue] = useState(null);
  const queryClient = new QueryClient();
  const scrollViewRef = useRef();
  const [isInjectedDataToHookForm, setIsInjectedDataToHookForm] = useState(false);
  const userInfo = useSelector((state) => state.merchantUserInfo);

  const methods = useForm({
    defaultValues,
    resolver: yupResolver(useUpdateLangueForYup(validationSchema)),
    mode: 'onChange',
    shouldFocusError: false,
  });
  const { errors, isDirty, isValid } = methods.formState;
  const getCompanyDetail = async ({ signal }) => {
    return await get(`${API_PATH.COMPANY_ACCOUNT}/${accountId}`, { signal });
  };
  const {
    data: companyAccount,
    isLoading,
    isSuccess,
    isFetching,
    remove,
  } = useQuery(['getCompanyDetail'], getCompanyDetail, {
    enabled: !!accountId,
    refetchOnWindowFocus: false,
    onError: (error) => {
      console.log(error);
      const { code, message } = error?.response.data;
      if (code === '2000' || code === '5009') {
        alert(message);
        history.push('/companies');
      }
    },
  });

  useEffect(() => {
    if (accountId && companyAccount && !isFetching) {
      setFormValue(companyAccount);
      const companyAccountData = {};
      for (const key in companyAccount) {
        const fieldData = companyAccount[key];
        if (!fieldData) continue;
        companyAccountData[key] = fieldData;
      }
      // methods.reset({ ...companyAccountData, corporationType: '1', industry: '2' }); //fake corporationType industry by value is QA
      methods.reset({ ...defaultValues, ...companyAccountData });
      methods.trigger();
      setIsInjectedDataToHookForm(true);
    }

    return () => {
      setFormValue(null);
      methods.reset();
      queryClient.removeQueries('getShopDetail', { exact: true });
    };
  }, [companyAccount, isFetching]);

  const isShowForm = useMemo(() => {
    if (!accountId) return true;
    return !isFetching && isInjectedDataToHookForm;
  }, [accountId, isFetching, isInjectedDataToHookForm]);

  useEffect(() => {
    scroll2View(Object.keys(errors)[0], scrollViewRef.current);
  }, [errors]);

  useEffect(() => remove, [remove]);

  return (
    <FormProvider {...methods}>
      {!accountId && (
        <View
          style={{ marginVertical: 18, flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}
        >
          <View style={{ flexDirection: 'row', alignItems: 'center' }}>
            <Image
              style={{ width: 46, height: 33, marginHorizontal: 20 }}
              source={require(`@assets/icons/buildingIcon.svg`)}
            />
            <Text
              style={{
                fontSize: 16,
                fontWeight: 600,
              }}
            >
              {t('pages_AccountCompany_CreateAccount.corporateAccountCreate')}
            </Text>
          </View>
          <HeaderAvatar />
        </View>
      )}
      <View>
        {accountId && (
          <View>
            <View style={styles.headerSearch}>
              <HeaderSearch placeholder={t('pages_AccountCompany_CreateAccount.placeholder')} linkBack="/companies" />
            </View>
            <HeaderAccount
              showBackButton={true}
              icon="building.svg"
              text={t('pages_AccountCompany_CreateAccount.corporateAccountDetails')}
              handleSubmit={methods.handleSubmit}
              isDetail={true}
              // checkDirtyFields={!isDirty || !isValid}
              widthIconHeader={27}
              heightIconHeader={31}
              linkWhenClose="/companies"
              updateAPI={`${API_PATH.COMPANY_ACCOUNT}/${accountId}/update`}
              deleteAPI={`${API_PATH.COMPANY_ACCOUNT}/${accountId}`}
              textCreateAccountSuccess={t('pages_AccountCompany_CreateAccount.modal')}
              scrollViewRef={scrollViewRef}
              formValue={formValue}
              removeRecordSuccessMessage={t('components_Modals_Headers.modalDeleteText8')}
            />
          </View>
        )}

        {/* FORM */}
        {isShowForm ? (
          <Grid style={styles.container}>
            <Col size={7}>
              <View style={{ height: accountId ? 'calc(100vh - 200px)' : 'calc(100vh - 80px)' }}>
                <ScrollView showsVerticalScrollIndicator={false} ref={scrollViewRef}>
                  <FomrIndividual
                    accountId={accountId}
                    control={methods.control}
                    setValue={methods.setValue}
                    formValue={formValue}
                    t={t}
                    disableForm={checkPermission(ROLE_USER.USER_MANAGEMENT, userInfo)}
                  />
                </ScrollView>
              </View>
            </Col>
            <Col size={5}>
              {accountId ? (
                <View style={{ height: 'calc(100vh - 200px)' }}>
                  <View style={styles.containerRight}>
                    <BankInfo control={methods.control} t={t} />
                  </View>
                </View>
              ) : (
                <MemberInfo
                  t={t}
                  handleSubmit={methods.handleSubmit}
                  errors={errors}
                  isValid={isValid}
                  // isButton={!isValid}
                  scrollViewRef={scrollViewRef}
                />
              )}
            </Col>
          </Grid>
        ) : (
          <Loading />
        )}
      </View>
    </FormProvider>
  );
};

export default withNamespaces()(CreateCompanyAccount);

const styles = StyleSheet.create({
  headerSearch: {
    marginRight: 0,
    marginTop: 10,
  },
  container: {
    gap: 50,
    backgroundColor: BG_COLOR,
    paddingHorizontal: 20,
  },
});

import React, { useState, useEffect } from 'react';
import { StyleSheet, View, Image, SectionList, ScrollView, Pressable, TouchableOpacity } from 'react-native';
import { NavLink } from 'react-router-dom';
import { withNamespaces } from 'react-i18next';
import { useSelector } from 'react-redux';

import {
  BG_HOVER,
  BG_ACTIVE_SIDEBAR,
  COLOR_TEXT_DEFAULT,
  BG_SIDEBAR,
  FONT_FAMILY_DEFAULT,
  BTN_COLOR_GREY,
  BG_COLOR,
  TEXT_COLOR_GREEN,
} from '@assets/style/styleDefault';
import { JText as Text } from '../common/JText';
import ModalLogout from '../Modals/ModalLogout';
import { ROLE_USER } from '@helper/constant';
import { checkPermission } from '@helper/commonFunction';
// import i18n from '../../i18n';

const Sidebar = ({ t }) => {
  const [adminPage, setAdminPage] = useState(false);
  const [isModalLogout, setIsModalLogout] = useState(false);
  const userInfo = useSelector((state) => state.merchantUserInfo);

  const dataSiderbar = [
    {
      title: '',
      data: [
        {
          role: ROLE_USER.PUBLIC,
          icon: 'home',
          widthIcon: 18,
          heightIcon: 18,
          text: t('components_SiderbarTabs_Sidebar.home'),
          link: '/',
          exact: true,
        },
        {
          role: ROLE_USER.PUBLISH_TOKEN,
          icon: 'token_3',
          widthIcon: 18,
          heightIcon: 18,
          text: t('components_SiderbarTabs_Sidebar.pointAndGift'),
          link: '/tokens',
        },
        {
          role: ROLE_USER.PUBLIC,
          icon: 'info',
          widthIcon: 18,
          heightIcon: 18,
          text: t('components_SiderbarTabs_Sidebar.information'),
          link: '/information',
        },
      ],
    },
    {
      title: 'User Account',
      data: [
        {
          role: ROLE_USER.PUBLIC,
          icon: 'user',
          widthIcon: 18,
          heightIcon: 18,
          text: t('components_SiderbarTabs_Sidebar.personalAccount'),
          link: '/accounts',
        },
        {
          role: ROLE_USER.PUBLIC,
          icon: 'building',
          widthIcon: 18,
          heightIcon: 18,
          text: t('components_SiderbarTabs_Sidebar.corporateAccount'),
          link: '/companies',
        },
        {
          role: ROLE_USER.PUBLIC,
          icon: 'shop',
          widthIcon: 18,
          heightIcon: 18,
          text: t('components_SiderbarTabs_Sidebar.shopAccount'),
          link: '/shop',
        },
      ],
    },
    {
      title: 'Contract Data',
      data: [
        {
          role: ROLE_USER.PUBLIC,
          icon: 'folder',
          widthIcon: 18,
          heightIcon: 18,
          text: t('components_SiderbarTabs_Sidebar.fileManager'),
          link: '/file-manager',
        },
        {
          role: ROLE_USER.PUBLIC,
          icon: 'note',
          widthIcon: 18,
          heightIcon: 18,
          text: t('components_SiderbarTabs_Sidebar.transactionInformation'),
          link: '/transaction-information',
        },
        {
          role: ROLE_USER.PUBLIC,
          icon: 'payment',
          widthIcon: 18,
          heightIcon: 18,
          text: t('components_SiderbarTabs_Sidebar.payment'),
          link: '/sum-refund-payment',
        },
        {
            role: ROLE_USER.PUBLIC,
            icon: 'payment',
            widthIcon: 18,
            heightIcon: 18,
            text: t('components_SiderbarTabs_Sidebar.payment')+"-backup",
            link: '/payment',
          },
      ],
    },
    {
      title: 'Setting',
      data: [
        {
          role: ROLE_USER.PUBLIC,
          icon: 'setting',
          widthIcon: 18,
          heightIcon: 18,
          text: t('components_SiderbarTabs_Sidebar.setting'),
          link: '/setting',
        },
      ],
    },
  ];

  const dataSiderbarAdmin = [
    {
      title: '',
      data: [
        {
          role: ROLE_USER.PUBLIC,
          icon: 'home',
          widthIcon: 18,
          heightIcon: 18,
          text: t('components_SiderbarTabs_Sidebar.adminHome'),
          link: '/admin',
          exact: true,
        },
      ],
    },
    {
      title: 'Admin Setting',
      data: [
        {
          role: ROLE_USER.SETTING_ROLE,
          icon: 'settingAdmin',
          widthIcon: 18,
          heightIcon: 18,
          text: t('components_SiderbarTabs_Sidebar.settingRole'),
          link: '/admin/account/setting',
        },
        {
          role: ROLE_USER.CREATE_ADMIN,
          icon: 'personal',
          widthIcon: 18,
          heightIcon: 18,
          text: t('components_SiderbarTabs_Sidebar.registrationNewAdministrators'),
          link: '/admin/account/register',
        },
      ],
    },
  ];
  useEffect(() => {
    setAdminPage(location?.pathname?.includes('/admin'));
  }, [location?.pathname]);
  // const changeLanguage = (lng) => {
  //   i18n.changeLanguage(lng);
  // };

  return (
    // languageInfo.loading || (
    <View style={styles.show}>
      <NavLink to="/" style={{ textDecoration: 'none' }}>
        <View style={styles.sidebarHead}>
          <Image source={require('@assets/image/levicaLogo.svg')} style={styles.adminLogo} alt="" />

          <Text style={[styles.textHeader, { color: TEXT_COLOR_GREEN }]}>
            X<Text style={styles.textHeader}>XXXX ADMIN</Text>
          </Text>
        </View>
      </NavLink>
      <ScrollView showsVerticalScrollIndicator={false} style={styles.sidebarContent}>
        <SectionList
          sections={adminPage ? dataSiderbarAdmin : dataSiderbar}
          renderItem={({ item }) =>
            checkPermission(item?.role, userInfo) ? (
              <View>
                <View>
                  <Pressable style={({ hovered }) => [hovered && styles.buttonHovered]}>
                    <TouchableOpacity onPress={item?.action}>
                      <NavLink
                        exact={item?.exact}
                        to={item.link}
                        activeStyle={{
                          backgroundColor: `${BG_ACTIVE_SIDEBAR}`,
                          textDecorationLine: 'none',
                        }}
                        style={{ textDecorationLine: 'none' }}
                      >
                        <View style={styles.siderbarItem}>
                          <View style={styles.sidebarItemIcon}>
                            <Image
                              source={require(`../../assets/icons/${item.icon}.svg`)}
                              style={
                                item.subIcon
                                  ? styles.hasSubIconSidebar
                                  : (styles.logoSiderbar, { width: item.widthIcon, height: item.heightIcon })
                              }
                            />
                          </View>
                          <Text style={styles.sidebarItemTitle}>{item.text}</Text>
                        </View>
                      </NavLink>
                    </TouchableOpacity>
                  </Pressable>
                </View>
              </View>
            ) : null
          }
          // renderSectionHeader={({ section }) => <Text style={styles.sectionHeader}>{section.title}</Text>}
          renderSectionHeader={({ section }) =>
            section?.data?.some((item) => checkPermission(item.role, userInfo)) && (
              <Text style={styles.sectionHeader}>{section.title}</Text>
            )
          }
          keyExtractor={(item, index) => index}
          style={styles.itemRender}
        />
        <View>
          <Pressable style={({ hovered }) => [hovered && styles.buttonHovered]}>
            <TouchableOpacity onPress={() => setIsModalLogout(true)}>
              <View style={styles.siderbarItem}>
                <View style={styles.sidebarItemIcon}>
                  <Image source={require(`../../assets/icons/logout.svg`)} style={styles.logoSiderbar} alt="" />
                </View>
                <Text style={styles.sidebarItemTitle}>{t('components_SiderbarTabs_Sidebar.logout')}</Text>
              </View>
            </TouchableOpacity>
          </Pressable>

          <Text style={styles.textCopyRight}>© XXXXXXXXX inc. All rights reserved.</Text>
        </View>
      </ScrollView>
      {/* <View style={{ flexDirection: 'row', gap: 20, justifyContent: 'center' }}>
        <button onClick={() => changeLanguage('Japanese')}>jp</button>
        <button onClick={() => changeLanguage('English')}>en</button>
        <button onClick={() => changeLanguage('China')}>zh</button>
      </View> */}
      {isModalLogout && <ModalLogout isModal={isModalLogout} setIsModal={setIsModalLogout} />}
    </View>
  );
};
export default withNamespaces()(Sidebar);

const styles = StyleSheet.create({
  show: {
    flex: '0 0 90px',
    maxWidth: '360px',
    minWidth: '260px',
    width: '15vw',
    backgroundColor: BG_SIDEBAR,
    position: 'relative',
    flexGrow: 0,
    flexShrink: 0,
    flexBasis: '100vh',
    position: 'relative',
  },
  buttonHovered: {
    backgroundColor: BTN_COLOR_GREY,
  },
  buttonHoveredLogout: {
    backgroundColor: BG_HOVER,
  },
  sidebarHead: {
    height: 80,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: BG_COLOR,
    position: 'sticky',
    top: 0,
    zIndex: 1,
    paddingHorizontal: 10,
  },
  textHeader: {
    fontFamily: 'BebasNeue',
    color: '#838383',
    fontSize: '42px',
    fontWeight: '500',
    textTransform: 'uppercase',
  },
  adminLogo: {
    width: 36,
    height: 31,
    marginRight: 10,
    marginBottom: 4,
  },
  sidebarContent: {
    backgroundColor: BG_SIDEBAR,
    flexGrow: 0,
    overflow: 'scroll',
  },
  sectionHeader: {
    fontSize: '15px',
    paddingLeft: '30px ',
    paddingRight: '30px',
    paddingBottom: '8px',
    marginLeft: 0,
    marginRight: 0,
    marginTop: '15px',
    marginBottom: 0,
    fontWeight: 500,
    backgroundColor: BG_SIDEBAR,
    color: COLOR_TEXT_DEFAULT,
  },
  itemRender: {
    display: 'flex',
    height: 'calc(88vh - 85px)',
  },

  siderbarItem: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    alignItems: 'center',
    paddingLeft: '30px',
    paddingRight: ' 30px',
    paddingTop: '11px',
    paddingBottom: '11px',
  },
  sidebarItemIcon: {
    display: 'flex',
    alignItems: 'center',
  },

  logoSiderbar: {
    width: 28,
    height: 22,
    marginRight: 10,
  },
  hasSubIconSidebar: {
    minWidth: '20px',
    minHeight: '20px',
    display: 'block',
    position: 'relative',
    top: '2px',
  },
  sidebarItemTitle: {
    paddingLeft: '20px',
    fontSize: FONT_FAMILY_DEFAULT,
    width: '100%',
    textDecorationLineLine: 'none',
    color: COLOR_TEXT_DEFAULT,
    letterSpacing: '-1px',
  },

  textCopyRight: { fontSize: 12, textAlign: 'center', paddingTop: 50 },
});

import React, { useCallback } from 'react';
import { View, StyleSheet, TouchableOpacity, Image, ScrollView } from 'react-native';
import Modal from 'react-native-modal';
import { withNamespaces } from 'react-i18next';

import { BORDER_GREY_LIGHT, TEXT_COLOR_WHITE } from '@assets/style/styleDefault';
import { JText as Text } from '@components/common/JText';
import ModalBackDrop from '@components/Modals/ModalBackDrop';

const ErrorTemp = ({ errorPosition, errorMessage }) => {
  return (
    <Text style={styles.errorMessage}>
      <Text style={{ color: 'red', marginRight: 10 }}>&#10005;</Text>
      {errorPosition}
      <Text style={{ color: 'red', marginLeft: 10 }}>{errorMessage}</Text>
    </Text>
  );
};

const ModalErrorImportCSV = ({ isVisible, setModalVisible, payload, t }) => {
  const renderErrorMessage = useCallback(
    (error) => {
      switch (error?.errorMessageCode) {
        case 'paymentConfirmUploadCsv.authCode.isBlank':
          return (
            <ErrorTemp
              key={`${error?.errorMessageCode}-${error?.line}-${error?.columnName}`}
              errorPosition={t('errorMessages.lineColPosition', {
                line: error?.line,
                column: error?.columnName,
              })}
              errorMessage={t('errorMessages.authCodeIsBlank')}
            />
          );

        case 'paymentConfirmUploadCsv.authCode.notExists':
          return (
            <ErrorTemp
              key={`${error?.errorMessageCode}-${error?.line}-${error?.columnName}`}
              errorPosition={t('errorMessages.lineColPosition', {
                line: error?.line,
                column: error?.columnName,
              })}
              errorMessage={t('errorMessages.authCodeNotExists')}
            />
          );

        case 'paymentConfirmUploadCsv.traddingDate.empty':
          return (
            <ErrorTemp
              key={`${error?.errorMessageCode}-${error?.line}-${error?.columnName}`}
              errorPosition={t('errorMessages.lineColPosition', {
                line: error?.line,
                column: error?.columnName,
              })}
              errorMessage={t('errorMessages.traddingDateEmpty')}
            />
          );

        case 'paymentConfirmUploadCsv.traddingDate.invalidFormat':
          return (
            <ErrorTemp
              key={`${error?.errorMessageCode}-${error?.line}-${error?.columnName}`}
              errorPosition={t('errorMessages.lineColPosition', {
                line: error?.line,
                column: error?.columnName,
              })}
              errorMessage={t('errorMessages.invalidTraddingDateFormat')}
            />
          );

        case 'paymentConfirmUploadCsv.name.empty':
          return (
            <ErrorTemp
              key={`${error?.errorMessageCode}-${error?.line}-${error?.columnName}`}
              errorPosition={t('errorMessages.lineColPosition', {
                line: error?.line,
                column: error?.columnName,
              })}
              errorMessage={t('errorMessages.nameEmpty')}
            />
          );

        case 'paymentConfirmUploadCsv.amount.empty':
          return (
            <ErrorTemp
              key={`${error?.errorMessageCode}-${error?.line}-${error?.columnName}`}
              errorPosition={t('errorMessages.lineColPosition', {
                line: error?.line,
                column: error?.columnName,
              })}
              errorMessage={t('errorMessages.amountEmpty')}
            />
          );

        case 'paymentConfirmUploadCsv.amount.invalidFormat':
          return (
            <ErrorTemp
              key={`${error?.errorMessageCode}-${error?.line}-${error?.columnName}`}
              errorPosition={t('errorMessages.lineColPosition', {
                line: error?.line,
                column: error?.columnName,
              })}
              errorMessage={t('errorMessages.invalidAmountFormat')}
            />
          );

        default:
          return null;
      }
    },
    [t]
  );

  return (
    <Modal
      isVisible={isVisible}
      animationIn="fadeIn"
      style={{ alignItems: 'center' }}
      customBackdrop={<ModalBackDrop onPress={() => setModalVisible(false)} />}
    >
      <View style={styles.container}>
        <View style={styles.closeContainer}>
          <TouchableOpacity onPress={() => setModalVisible(false)} style={styles.btnBack}>
            <Image style={styles.backIcon} source={require(`../../../assets/icons/close-white.svg`)} />
            <Text style={styles.textClose}>{t('commonText.close')}</Text>
          </TouchableOpacity>
        </View>
        <View style={styles.content}>
          <View style={styles.modalHeader}>
            <Text style={styles.headerTitle}>{t('commonText.errorDetail')}</Text>
          </View>
          <ScrollView>{payload?.map((error) => renderErrorMessage(error))}</ScrollView>
        </View>
      </View>
    </Modal>
  );
};

export default withNamespaces()(ModalErrorImportCSV);

const styles = StyleSheet.create({
  listItem: {
    flexDirection: 'row',
    alignItems: 'center',
    borderTopWidth: 1,
    borderTopColor: BORDER_GREY_LIGHT,
    paddingVertical: 15,
  },
  borderTopNone: {
    borderTopWidth: 0,
  },
  textContent: {
    color: '#797979',
  },
  //main
  content: {
    maxWidth: 900,
    minWidth: 600,
    padding: 30,
    paddingRight: 0,
    backgroundColor: '#fff',
    minHeight: '40vh',
    maxHeight: '60vh',
    borderRadius: 10
  },
  modalHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    marginBottom: 20,
  },
  headerTitle: {
    fontSize: 30,
    color: '#333333',
  },
  backIcon: {
    width: 16,
    height: 16,
    resizeMode: 'contain',
    margin: 10,
  },
  textClose: {
    color: TEXT_COLOR_WHITE,
  },
  btnBack: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  closeContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    paddingRight: 20,
  },
  errorMessage: {
    color: '#191919',
  },
});

import React, { useCallback, useEffect, useState } from 'react';
import { View, Image, ScrollView, Pressable, Dimensions } from 'react-native';
import { Col, Grid } from 'react-native-easy-grid';
import moment from 'moment';
import { withNamespaces } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import { useHistory } from 'react-router-dom';
import { Center } from 'native-base';

import { formatNumber } from '@helper/formatTypes';
import HeaderSearch from '@components/Headers/HeaderSearch';
import Pagination from '@components/common/Pagination';
import JButton from '@components/common/JButton';
import styles from './style';
import { BG_FORM_DEFAULT, INPUT_BG, LINE_COLOR, TEXT_COLOR_GREEN } from '@assets/style/styleDefault';
import HeaderPayManagement from '@components/PayManagement';
import { JText as Text } from '@components/common/JText';
import JCheckbox from '@components/common/JCheckbox';
import { get } from '@utils/RequestLevica';
import { API_PATH } from '@helper/constant';
import Loading from '@components/common/Loading';
import { LinearGradient } from 'expo-linear-gradient';

const PaymentIncomes = ({ t }) => {
  const [paymentListData, setPaymentListData] = useState([]);
  const [listPaymentChecked, setListPaymentChecked] = useState([]);
  const [paymentProcessedAll, setPaymentProcessedAll] = useState(false);
  const [paymentIDSelected, setPaymentIDSelected] = useState([]);
  const [isLoadingManual, setIsLoadingManual] = useState(false);
  const history = useHistory();

  const [params, setParams] = useState({
    limit: 30,
    pageNumber: 1,
    q: '',
    startDate: '',
    endDate: '',
    sort: '0500',
    order: 'desc',
    status: '0',
  });

  const [tableHeadData, setTableHeadData] = useState([
    {
      title: t('pages_PaymentManager.paymentDueDate'),
      size: 1,
      hasIcon: true,
      order: false,
      sort: '0100',
    },
    {
      title: t('pages_PaymentManager_PaymentDeposites.codeVerify'),
      size: 1,
      hasIcon: true,
      order: false,
      sort: '0200',
    },
    {
      title: t('pages_PaymentManager_PaymentDeposites.playerName'),
      size: 1,
      hasIcon: true,
      order: false,
      sort: '0300',
    },
    {
      title: t('pages_PaymentManager_PaymentDeposites.amount'),
      size: 1,
      hasIcon: true,
      order: false,
      sort: '0400',
    },
    { title: t('pages_PaymentManager_PaymentDeposites.select'), size: 1 },
  ]);

  const {
    data: PaymentIncomes,
    refetch,
    isFetching,
    remove,
  } = useQuery(
    ['PaymentIncomes', params],
    async ({ signal }) => {
      const data = await get(API_PATH.PAYMENT_CONFIRMATION_REQUEST, { ...params, signal });
      if (paymentProcessedAll) {
        const newIncomeData = data?.paymentConfirmationRequestList?.map((item) => ({ ...item, pickup: true }));

        setPaymentListData(newIncomeData);

        setPaymentIDSelected(() => {
          const newPaymentIDSelected = [...paymentIDSelected];
          data?.paymentConfirmationRequestList?.map(
            ({ id: paymentID }) => !newPaymentIDSelected.includes(paymentID) && newPaymentIDSelected.push(paymentID)
          );
          return newPaymentIDSelected;
        });
      } else {
        setPaymentListData(
          data?.paymentConfirmationRequestList?.map((item) =>
            paymentIDSelected?.includes(item.id) ? { ...item, pickup: true } : item
          )
        );
      }
      return data;
    },
    {
      refetchOnWindowFocus: false,
    }
  );
  const selectAll = () => {
    const isAllPaymentsInTabChecked = listPaymentChecked.length === paymentListData.length;
    setPaymentProcessedAll(!isAllPaymentsInTabChecked);

    const newpaymentListData = paymentListData?.map((item) => ({
      ...item,
      pickup: listPaymentChecked?.length > 0 ? (isAllPaymentsInTabChecked ? false : true) : true,
    }));
    setPaymentListData(newpaymentListData);

    setPaymentIDSelected(() => {
      if (isAllPaymentsInTabChecked) return [];
      const newPaymentIDSelected = [...paymentIDSelected];
      paymentListData?.map(
        ({ id: paymentID }) => !newPaymentIDSelected.includes(paymentID) && newPaymentIDSelected.push(paymentID)
      );
      return newPaymentIDSelected;
    });
  };

  const toggleCheckbox = (item, index) => {
    const newpaymentListData = [...paymentListData];
    newpaymentListData?.splice(index, 1, { ...item, pickup: !item.pickup });
    setPaymentListData(newpaymentListData);

    const positionOfItemID = paymentIDSelected.indexOf(item.id);
    setPaymentIDSelected((prev) => {
      const newPaymentSelected = [...prev];
      if (positionOfItemID > -1) {
        newPaymentSelected.splice(positionOfItemID, 1);
        return newPaymentSelected;
      }
      return [...newPaymentSelected, item.id];
    });

    setPaymentProcessedAll(false);
  };

  useEffect(() => {
    const listPaymentChecked = paymentListData?.filter((item) => {
      return item.pickup === true;
    });
    setListPaymentChecked(listPaymentChecked);
  }, [paymentListData, setListPaymentChecked]);

  const hanldeSort = (item) => {
    setTableHeadData((prev) =>
      prev.map((tableHead) =>
        tableHead.sort === item.sort ? { ...tableHead, order: !tableHead.order } : { ...tableHead, order: false }
      )
    );

    setParams({
      ...params,
      sort: item.sort,
      pageNumber: 1,
      order: !item.order ? 'asc' : 'desc',
    });
  };

  const onImportCSV = useCallback(() => {
    refetch();
    setIsLoadingManual(false);
  }, [refetch, setIsLoadingManual]);

  const onImportingCSV = useCallback(() => {
    setIsLoadingManual(true);
  }, [setIsLoadingManual]);

  useEffect(() => remove, [remove]);

  return (
    <>
      <View
        style={[
          styles.container,
          {
            backgroundColor: isFetching || paymentListData.length < 1 || isLoadingManual ? '' : BG_FORM_DEFAULT,
          },
        ]}
      >
        <View style={styles.wrapperStickyHeader}>
          <View style={styles.headerSearch}>
            <HeaderSearch
              onSearch={() => setPaymentProcessedAll(false)}
              placeholder={t('pages_PaymentManager_PaymentDeposites.placeholder')}
              setParams={setParams}
              params={params}
            />
          </View>

          <HeaderPayManagement
            totalAmount={!PaymentIncomes?.totalAmount || isFetching ? 0 : PaymentIncomes?.totalAmount}
            paymentListData={paymentListData}
            setParams={setParams}
            csvName={t('components_PayManagement.paymentConfirmation')}
            params={{ isCheckedAll: true, ...params }}
            active={'/payment'}
            selectAll={selectAll}
            linkPaymentProcessed={API_PATH.CONFIRM_PAYMENT_REQUESTS}
            refetchQuery={refetch}
            btn1
            btn1Text={t('pages_PaymentManager.buttonPaymentProcessed')}
            btn2={
              <JButton
                text={t('commonText.buttonSelectAll')}
                onPressAction={() => selectAll()}
                width={150}
                textColor={TEXT_COLOR_GREEN}
                btnColor={INPUT_BG}
                borderBtn={`1px solid ${LINE_COLOR}`}
              />
            }
            isImportCSV
            paymentProcessedAll={paymentProcessedAll}
            listPaymentChecked={listPaymentChecked}
            exportCSVLink={API_PATH.PAYMENT_CONFIRMATION_REQUESTS_EXPORT_CSV}
            tabBarExtraContent={
              <View style={{ alignItems: 'center', marginLeft: 20 }}>
                <LinearGradient colors={['#32a5f7', '#2d81f3']} style={{ borderRadius: 5 }}>
                  <JButton
                    text={t('pages_PaymentManager_PaymentDeposites.create')}
                    width={165}
                    textColor={'#fff'}
                    onPressAction={() => history.push('/payment/add-payment-transaction')}
                  />
                </LinearGradient>
              </View>
            }
            datetimeTitle={t('components_PayManagement.timespace')}
            listChecked={listPaymentChecked}
            onImportCSV={onImportCSV}
            onImportingCSV={onImportingCSV}
            totalCount={PaymentIncomes?.pageInfo?.totalCount ?? 0}
          />
          {paymentListData?.length > 0 && !isFetching && !isLoadingManual && (
            <View>
              <Grid style={[styles.head, { gap: 20 }]} size={30}>
                {tableHeadData.map((item) => (
                  <Col size={item.size} style={styles.horizontal} key={item.title}>
                    <Text numberOfLines={2} style={styles.textHead}>
                      {item.title}
                    </Text>
                    {item.hasIcon && (
                      <Image
                        onClick={() => hanldeSort(item)}
                        style={item?.order ? styles.iconTableHeadDESC : styles.iconTableHeadASC}
                        source={require(`../../assets/icons/iconDropdown.svg`)}
                      />
                    )}
                  </Col>
                ))}
              </Grid>
            </View>
          )}
        </View>
        <ScrollView showsVerticalScrollIndicator={false}>
          {isFetching || isLoadingManual ? (
            <Loading style={{ height: 'calc(100vh - 308px)' }} />
          ) : paymentListData?.length > 0 ? (
            paymentListData?.map((item, index, { length }) => {
              return (
                <Pressable key={index} style={({ hovered }) => [hovered && styles.rowHovered]}>
                  <Grid
                    style={[styles.headBody, { gap: 20 }, { borderBottomWidth: index + 1 === length ? 0 : 1 }]}
                    size={30}
                  >
                    <Col size={1}>
                      <Text style={styles.text} numberOfLines={2}>
                        {item?.dayTrading
                          ? moment.unix(new Date(item?.dayTrading).getTime() / 1000).format('YYYY.MM.DD')
                          : ''}
                      </Text>
                    </Col>
                    <Col size={1}>
                      <Text style={styles.text} numberOfLines={2}>
                        {item?.tradingCode}
                      </Text>
                    </Col>
                    <Col size={1}>
                      <Text style={styles.text} numberOfLines={2}>
                        {item?.name}
                      </Text>
                    </Col>
                    <Col size={1}>
                      <Text style={styles.text} numberOfLines={2}>
                        {item?.amount ? `¥ ${formatNumber(item.amount)}` : ''}
                      </Text>
                    </Col>
                    <Col size={1} style={{ alignItems: 'center' }}>
                      <JCheckbox
                        checkValueTable={true}
                        onValueChange={() => toggleCheckbox(item, index)}
                        value={item.pickup ?? false}
                      />
                    </Col>
                  </Grid>
                </Pressable>
              );
            })
          ) : (
            <Center w="100%" h={Dimensions.get('window').height - 308}>
              <Text>{t('pages_PaymentManager.haveNoPaymentIncomes')}</Text>
            </Center>
          )}
          <View>
            {params.limit < PaymentIncomes?.pageInfo?.totalCount && !isFetching && !isLoadingManual && (
              <Pagination
                count={Math.ceil(PaymentIncomes?.pageInfo?.totalCount / params.limit)}
                params={params}
                setParams={setParams}
              />
            )}
          </View>
        </ScrollView>
      </View>
    </>
  );
};

export default withNamespaces()(PaymentIncomes);

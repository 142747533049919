import { StyleSheet } from 'react-native';
import {
  BG_COLOR,
  BG_HOVER,
  BG_TABLE_DEFAULT,
  COLOR_TEXT_DEFAULT,
  FONT_SIZE_DEFAULT,
  TEXT_COLOR_GREY,
  TEXT_COLOR_HEADER_TABLE,
} from '@assets/style/styleDefault';
const DFlex = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
};
const DEFAULT_FONT = 'NotoSansJP';
const DEFAULT_TEXT_COLOR = '#ffffff';
const DEFAULT_TEXT_SiZE = '14px';

const styles = StyleSheet.create({
  dFlex: {
    ...DFlex,
  },
  defaultTextColor: {
    color: COLOR_TEXT_DEFAULT,
    fontSize: FONT_SIZE_DEFAULT,
  },
  textWarning: {
    color: 'red',
    fontSize: DEFAULT_TEXT_SiZE,
  },

  container: { flex: 1, backgroundColor: BG_COLOR, position: 'relative' },
  wrapperStickyHeader: {
    position: 'sticky',
    top: 0,
    backgroundColor: BG_COLOR,
    zIndex: 10,
  },
  header: {
    margin: 20,
    marginRight: 0,
    marginTop: 10,
  },
  wrapperHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginHorizontal: 20,
    paddingVertical: 5,
  },
  wrapperStickyTitle: {
    position: 'sticky',
    top: 79,
    backgroundColor: `${BG_COLOR}`,
    zIndex: 10,
  },
  sticky130: {
    position: 'sticky',
    top: 172,
  },
  wrapperHeaderLeft: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '50%',
    paddingBottom: 5,
  },
  textHeader: {
    fontFamily: DEFAULT_FONT,
    fontSize: 18,
    color: TEXT_COLOR_HEADER_TABLE,
    marginRight: 15,
    fontWeight: 600,
  },
  SearchInput: {
    width: '35vw',
    paddingRight: 15,
  },
  iconDeleted: {
    width: 60,
    height: 42,
    marginLeft: 30,
  },
  //table css
  headTable: { height: 42, paddingTop: 24, paddingBottom: 24 },
  wrapperStickyTableHead: {
    position: 'sticky',
    top: 137,
    backgroundColor: BG_COLOR,
    zIndex: 10,
    alignItems: 'center',
  },
  buttonHovered: {
    backgroundColor: BG_HOVER,
  },
  wrapperItemHead: {
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
  },

  iconDropdown: {
    width: 10,
    height: 10,
    marginLeft: 5,
    cursor: 'pointer',
  },
  iconTableHeadASC: {
    transform: [{ rotate: '180deg' }],
    marginTop: 5,
  },
  textHead: { fontFamily: DEFAULT_FONT, fontSize: DEFAULT_TEXT_SiZE, color: TEXT_COLOR_GREY, textAlign: 'center' },

  iconWarning: {
    width: 41,
    height: 41,
  },
  tableItemType: {
    backgroundColor: '#0083dd',
    paddingVertical: 5,
    paddingHorizontal: 20,
    marginRight: 5,
    maxHeight: 25,
    maxWidth: 65,
  },
  row: {
    height: 65,
    borderBottomWidth: 1,
    borderBottomColor: '#5C5C5C',
    paddingTop: 15,
    paddingBottom: 15,
  },
  wrapperItem: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  wrapperBody: {
    backgroundColor: BG_TABLE_DEFAULT,
  },
  checkboxItem: {
    width: 25,
    height: 25,
  },
  elementTable: {
    width: 26,
    height: 26,
    zIndex: 1,
    position: 'relative',
  },
  elementCheckbox: {
    position: 'absolute',
    top: 0,
  },
});

export default styles;

import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { View, Image as ImageNative, ScrollView, TouchableWithoutFeedback } from 'react-native';
import * as DocumentPicker from 'expo-document-picker';
import * as Yup from 'yup';
import { Col, Grid } from 'react-native-easy-grid';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { withNamespaces } from 'react-i18next';
import { LinearGradient } from 'expo-linear-gradient';
import moment from 'moment';
import { useMutation, useQuery } from '@tanstack/react-query';

import HeaderAvatar from '@components/Headers/HeaderAvatar';
import HeaderBackHaveModal from '@components/Headers/HeaderBackHaveModal';
import Jbutton from '@components/common/JButton';
import JInput from '@components/common/JInput';
import { JText as Text } from '@components/common/JText';
import DateTimePicker from '@components/common/DateTimePicker';
import DropDown from '@components/common/DropDown';
import ModalAlert from '@components/Modals/ModalAlert';
import ModalDelete from '@components/Modals/ModalDelete';
import RadioCustom from './partials/RadioCustom';
import styles from './style';
import HeaderBack from '@components/Headers/HeaderBack';
import { API_PATH } from '@helper/constant';
import { del, get, postMulti, putMulti } from '@utils/RequestLevica';
import formatDisplayId from '@helper/formatDisplayId';
import imgDefault from '@assets/image/img-default.png';
import ModalInformationPreview from '@components/Modals/ModalInformationPreview';
import scroll2View from '@helper/scroll2View';
import Loading from '@components/common/Loading';

const InfomationCreate = ({ t }) => {
  const location = useLocation();
  const [images, setImages] = useState('');
  const [modalSave, setModalSave] = useState(false);
  const [modalDeleteSuccess, setModalDeleteSuccess] = useState(false);
  const [modalDelete, setModalDelete] = useState(false);
  const [showPreview, setShowPreview] = useState(false);
  const [isInjectedDataToHookForm, setIsInjectedDataToHookForm] = useState(false);
  const { infoId } = useParams();
  const history = useHistory();
  const scrollViewRef = useRef();
  const actionOptions = [
    {
      option: t('pages_Infomation_InformationCreate.public'),
      value: '1',
      func: () => {
        setValue('statuses', '1', { shouldDirty: true });
        trigger('statuses');
      },
    },
    {
      option: t('pages_Infomation_InformationCreate.private'),
      value: '2',
      func: () => {
        setValue('statuses', '2', { shouldDirty: true });
        trigger('statuses');
      },
    },
  ];

  const validationSchema = Yup.object().shape({
    title: Yup.string()
      .trim()
      .required(t('errorMessages.fieldRequired', { fieldName: t('pages_Infomation_InformationCreate.title') }))
      .max(50, t('errorMessages.TXT_MAX_50')),
    category: Yup.string()
      .trim()
      .required(t('errorMessages.fieldRequired', { fieldName: t('pages_Infomation_InformationCreate.category') }))
      .max(20, t('errorMessages.TXT_MAX_20')),
    date: Yup.string()
      .trim()
      .required(t('errorMessages.fieldRequired', { fieldName: t('components_PayManagement.datetime') })),
    time: Yup.string()
      .trim()
      .required(t('errorMessages.fieldRequired', { fieldName: t('pages_Infomation_InformationCreate.clock') })),
    image: Yup.mixed().required(
      t('errorMessages.fieldRequired', { fieldName: t('pages_Infomation_InformationCreate.imagePlaceholder') })
    ),
    iconName: Yup.string().trim().required(t('errorMessages.selectAnImagePls')),
    body: Yup.string()
      .trim()
      .required(t('errorMessages.fieldRequired', { fieldName: t('pages_Infomation_InformationCreate.content') }))
      .test(
        'length validation',
        t('errorMessages.TXT_MAX_1000'),
        (value) => value?.replace(/\r\n/gm, '____')?.replace(/\n/gm, '____')?.length < 1001
      ),
    division: Yup.string()
      .trim()
      .required(t('errorMessages.fieldRequired', { fieldName: t('pages_Infomation_InformationCreate.type') })),
  });

  const defaultValues = {
    title: '',
    category: '',
    body: '',
    date: '',
    time: '',
    image: '',
    iconName: '',
    division: '',
    statuses: '1',
  };

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors, isValid, isDirty },
    clearErrors,
    setError,
    getValues,
    trigger,
    watch,
    reset,
  } = useForm({
    defaultValues,
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
  });

  const {
    data: infoData,
    isFetched,
    remove,
    isFetching,
  } = useQuery(
    ['INFORMATION_DETAIL', infoId],
    async ({ signal }) => {
      const infoData = await get(`${API_PATH.INFORMATION}/${infoId}/detail`, { signal });
      return infoData;
    },
    {
      refetchOnWindowFocus: false,
      enabled: typeof infoId !== 'undefined',
    }
  );
  const validateFileType = (file) => /image\//g.test(file.mimeType);

  const validateFileSize = (file, sizeLimit) => file.size <= sizeLimit * 1024 * 1024;

  const checkFakeImage = (file) => {
    const img = new Image();
    img.onerror = function () {
      setImages(null);
      setError('iconName', { type: 'custom', message: t('errorMessages.TEXT_ONLY_IMAGE'), isError: true });
    };
    img.src = URL.createObjectURL(file);
  };

  const pickImages = async () => {
    let result = await DocumentPicker.getDocumentAsync({
      type: ['image/png', 'image/jpg', 'image/jpeg'],
    });
    checkFakeImage(result.file);
    if (!validateFileType(result)) {
      setError('iconName', { message: t('errorMessages.TEXT_ONLY_IMAGE'), type: 'image type error' });
      return false;
    }

    if (!validateFileSize(result, 10)) {
      setError('iconName', { message: t('errorMessages.TXT_LIMIT_IMAGE_10MB'), type: 'image size error' });
      return false;
    }

    setImages(result.uri);
    setValue('iconName', result.name);
    setValue('image', result.file, { shouldDirty: true });
    trigger(['iconName', 'image']);

    return true;
  };

  const handlePickImages = useCallback(async () => {
    const isValidImages = await pickImages();
    if (!isValidImages) {
      setImages(imgDefault);
      setValue('image', '');
      setValue('iconName', '');
    }
  }, [pickImages, setImages, setValue]);

  const { mutate: createInformation, isLoading: isCreating } = useMutation({
    mutationFn: async (data) => await postMulti(API_PATH.INFORMATION, data),
    retry: 3,
  });

  const { mutate: updateInformation, isLoading: isUpdating } = useMutation({
    mutationFn: async (data) => await putMulti(`${API_PATH.INFORMATION}/${infoId}`, data),
    retry: 3,
  });

  const { mutate: removeInfo, isLoading: isRemoving } = useMutation({
    mutationFn: async () => await del(API_PATH.INFORMATION + '/' + infoId),
    retry: 3,
  });

  const onSubmit = async (data) => {
    const { date, time, ...restData } = data;
    const dataSend = { ...restData, publishedAt: `${moment(date).format('YYYY-MM-DD')} ${time}` };
    return infoId
      ? updateInformation(dataSend, {
          onSuccess: () => {
            setModalSave(true);
            reset(data);
          },
          onError: (error) => console.log(error),
        })
      : createInformation(dataSend, {
          onSuccess: () => {
            setModalSave(true);
            setImages('');
            reset();
          },
          onError: (error) => console.log(error),
        });
  };

  const saveDataBeforeBack = useCallback(async () => {
    const { date, time, ...restData } = watch();
    const dataSend = { ...restData, publishedAt: `${moment(date).format('YYYY-MM-DD')} ${time}` };
    try {
      infoId
        ? await putMulti(`${API_PATH.INFORMATION}/${infoId}`, dataSend)
        : await postMulti(API_PATH.INFORMATION, dataSend);

      return true;
    } catch (err) {
      console.log(err);
      return false;
    }
  }, [infoId, watch]);

  const onDelete = () => {
    removeInfo(_, {
      onSuccess: () => setModalDeleteSuccess(true),
      onError: (error) => console.log(error),
    });
  };

  const handleUpdateDivision = useCallback(
    (value) => {
      setValue('division', value, { shouldDirty: true });
      clearErrors('division');
      trigger('division');
    },
    [setValue, clearErrors, trigger]
  );

  const handleConvertStatus = useCallback(
    (status) => {
      if (isNaN(status)) return status;

      switch (status) {
        case '1':
          return t('pages_Infomation_InformationCreate.public');
        case '2':
          return t('pages_Infomation_InformationCreate.private');
        default:
          return console.log('invalid information status!');
      }
    },
    [t]
  );

  const isUseHeaderBackHaveModal = useMemo(
    () => isDirty || (!infoId && !(JSON.stringify(watch()) === JSON.stringify(defaultValues))),
    [isDirty, watch, defaultValues, infoId]
  );

  const isShowForm = useMemo(() => {
    if (!infoId) return true;
    return !isFetching && isInjectedDataToHookForm;
  }, [infoId, isFetching, isInjectedDataToHookForm]);

  useEffect(() => remove, [remove]);

  useEffect(() => {
    (() => {
      if (!infoData) return false;

      for (let key in infoData) {
        if (key === 'publishedAt' || key === 'id') continue;
        setValue(key, infoData[key] ?? '');
      }
      setImages(infoData.image);

      setValue('date', moment(infoData.publishedAt).format('YYYY-MM-DD'));
      setValue('time', moment(infoData.publishedAt).format('HH:mm'));

      trigger();
      setIsInjectedDataToHookForm(true);
    })();
  }, [infoData, setValue, infoData, trigger, setIsInjectedDataToHookForm]);

  useEffect(() => {
    (() => {
      if (isFetched && !infoData) history.push('/page-not-found');
    })();
  }, [isFetched, infoData, history]);

  useEffect(() => {
    scroll2View(Object.keys(errors)[0], scrollViewRef.current);
  }, [errors]);

  return (
    <View style={styles.container}>
      <ScrollView showsVerticalScrollIndicator={false} ref={scrollViewRef}>
        <View style={styles.sticky}>
          {isUseHeaderBackHaveModal ? (
            <HeaderBackHaveModal
              btnBackWidth={21}
              btnBackHeight={21}
              btnBack="iconX.svg"
              textBack={t('commonText.close')}
              iconHeader="iconI.svg"
              textHeader={
                location?.pathname?.includes('/create')
                  ? t('pages_Infomation_InformationCreate.informationCreate')
                  : t('pages_Infomation_InformationCreate.informationEdit')
              }
              linkBack="/information"
              avatar={<HeaderAvatar />}
              onBackdropPressCallback={() => history.push('/information')}
              onAcceptBtnClick={() => (isValid ? { isValid, func: saveDataBeforeBack } : { isValid, func: trigger })}
            />
          ) : (
            <HeaderBack
              btnBackWidth={21}
              btnBackHeight={21}
              btnBack="iconX.svg"
              textBack={t('commonText.close')}
              iconHeader="iconI.svg"
              textHeader={
                location?.pathname?.includes('/create')
                  ? t('pages_Infomation_InformationCreate.informationCreate')
                  : t('pages_Infomation_InformationCreate.informationEdit')
              }
              linkBack="/information"
              avatar={<HeaderAvatar />}
            />
          )}
          {isShowForm && (
            <View style={styles.wrapperTitle}>
              <Text style={[styles.defaultText, styles.fontWeight600, styles.fontSize18]}>
                {infoId ? `No. ${formatDisplayId(infoId)}` : ''}
                <Text>
                  {location?.pathname?.includes('/create') ? t('pages_Infomation_InformationCreate.no000001') : null}
                </Text>
              </Text>

              <View style={styles.wrapperButton}>
                <View style={[{ width: 170 }]}>
                  <DropDown
                    btnColor="#525252"
                    textColor="#fff"
                    text={handleConvertStatus(watch('statuses')) || t('pages_Infomation_InformationCreate.public')}
                    dataOption={actionOptions}
                    colorOption="red"
                  />
                </View>
                <View style={{ paddingLeft: 20 }}>
                  <Jbutton
                    text={t('pages_Infomation_InformationCreate.preview')}
                    btnColor="#525252"
                    width={170}
                    onPressAction={() => setShowPreview(true)}
                    hasIcon
                    linkIcon={'iconArrowRight.svg'}
                    widthIcon={17}
                    heightIcon={19}
                    textColor={'#fff'}
                  />
                </View>
                {location?.pathname?.includes('/create') ? null : (
                  <View style={{ paddingLeft: 20 }}>
                    <LinearGradient colors={['#FE8463', '#FF0000']} style={{ borderRadius: 5 }}>
                      <Jbutton
                        text={t('pages_Infomation_InformationCreate.delete')}
                        width={170}
                        hasIcon
                        linkIcon={'iconRecycleBin.svg'}
                        widthIcon={30}
                        heightIcon={20}
                        textColor="#fff"
                        onPressAction={() => setModalDelete(true)}
                      />
                    </LinearGradient>
                  </View>
                )}
                <View style={{ paddingLeft: 20 }}>
                  <LinearGradient colors={['#54DBB2', '#04C48B']} style={{ borderRadius: 5 }}>
                    <Jbutton
                      onPressAction={() => handleSubmit(onSubmit)()}
                      text={t('commonText.buttonSave')}
                      width={170}
                      linkIcon={'iconDownload.svg'}
                      linkIconDisabled="iconDownloadGray.svg"
                      widthIcon={24}
                      heightIcon={20}
                      textColor="#fff"
                      btnDisabledColor="#525252"
                      // disabled={!isValid || !isDirty}
                      disabled={isCreating || isUpdating}
                      isProcessing={isCreating || isUpdating}
                    />
                  </LinearGradient>
                </View>
              </View>
            </View>
          )}
        </View>
        {isShowForm ? (
          <Grid style={styles.wrapperBody}>
            <Col size={8}>
              <View style={styles.wrapperBodyLeft}>
                <View style={styles.wrapperItem}>
                  <Col size={3} nativeID="title">
                    <Text style={[styles.defaultText, styles.ms]}>
                      {t('pages_Infomation_InformationCreate.title')}
                      <Text style={styles.textWarning}>✴︎</Text>
                    </Text>
                  </Col>
                  <Col size={7}>
                    <JInput
                      borderError={errors?.title && 'red'}
                      placeholder={t('pages_Infomation_InformationCreate.titlePlaceholder')}
                      subfix="iconEdit.svg"
                      heightSubfix={18}
                      widthSubfix={18}
                      control={control}
                      name="title"
                    />
                  </Col>
                </View>

                <View style={styles.wrapperItem}>
                  <Col size={3} nativeID="category">
                    <Text style={[styles.defaultText, styles.ms]}>
                      {t('pages_Infomation_InformationCreate.category')}
                      <Text style={styles.textWarning}>✴︎</Text>
                    </Text>
                  </Col>
                  <Col size={7}>
                    <JInput
                      borderError={errors?.category && 'red'}
                      placeholder={t('pages_Infomation_InformationCreate.categoryPlaceholder')}
                      subfix="iconEdit.svg"
                      heightSubfix={18}
                      widthSubfix={18}
                      control={control}
                      name="category"
                    />
                  </Col>
                </View>
                <View style={styles.wrapperItem}>
                  <Col size={3}>
                    <Text style={[styles.defaultText, styles.ms]}>
                      {t('pages_Infomation_InformationCreate.time')}
                      <Text style={styles.textWarning}>✴︎</Text>
                    </Text>
                  </Col>
                  <Col size={7}>
                    <View style={styles.wrapperTime}>
                      <View style={styles.wrapperTimeItem} nativeID="date">
                        <DateTimePicker
                          min={moment().format('YYYY-MM-DD')}
                          max="infinity"
                          type="date"
                          name="date"
                          borderError={errors?.date && 'red'}
                          control={control}
                        />
                      </View>
                      <View style={[styles.wrapperTimeItem, { marginLeft: 20 }]} nativeID="time">
                        <DateTimePicker type="time" name="time" borderError={errors?.time && 'red'} control={control} />
                      </View>
                    </View>
                  </Col>
                </View>
                <View style={[styles.wrapperItem, { alignItems: 'flex-start' }]}>
                  <Col size={3} nativeID="iconName">
                    <Text style={[styles.defaultText, styles.ms]}>
                      {t('pages_Infomation_InformationCreate.image')}
                      <Text style={styles.textWarning}>✴︎</Text>
                    </Text>
                  </Col>
                  <Col size={7}>
                    <Grid>
                      <Col size={4} style={[{ minWidth: 'unset' }]}>
                        {images ? (
                          <ImageNative source={{ uri: images }} style={styles.images} />
                        ) : (
                          <ImageNative source={imgDefault} style={styles.images} alt="" />
                        )}
                      </Col>
                      <Col size={6} style={{ marginLeft: 10, alignItems: 'flex-end' }}>
                        <JInput
                          disable={false}
                          placeholder={t('pages_Infomation_InformationCreate.imagePlaceholder')}
                          control={control}
                          name="iconName"
                          outlineShow={false}
                        />

                        <View style={styles.btnAddImg}>
                          <LinearGradient colors={['#32A5F7', '#2D81F3']} style={{ borderRadius: 5 }}>
                            <TouchableWithoutFeedback onPress={handlePickImages}>
                              <View style={styles.button}>
                                <Text style={{ marginLeft: 10, color: '#fff' }}>
                                  {' '}
                                  {t('pages_Infomation_InformationCreate.imageButton')}
                                </Text>
                              </View>
                            </TouchableWithoutFeedback>
                          </LinearGradient>
                        </View>
                      </Col>
                    </Grid>
                  </Col>
                </View>
                <View style={[styles.wrapperItem, { alignItems: 'start', borderBottomWidth: 0 }]}>
                  <Col size={3} nativeID="body">
                    <Text style={[styles.defaultText, styles.ms]}>
                      {t('pages_Infomation_InformationCreate.content')}
                      <Text style={styles.textWarning}>✴︎</Text>
                    </Text>
                  </Col>
                  <Col size={7}>
                    <JInput
                      borderError={errors?.text && 'red'}
                      placeholder={t('pages_Infomation_InformationCreate.contentPlaceholder')}
                      subfix="iconEdit.svg"
                      heightSubfix={18}
                      widthSubfix={18}
                      multiline={true}
                      numberOfMulti={15}
                      heightMulti={300}
                      control={control}
                      name="body"
                    />
                  </Col>
                </View>
              </View>
            </Col>
            <Col size={2}>
              <View style={styles.wrapperBodyRight}>
                <Text style={[styles.defaultText, styles.mt]} nativeID="division">
                  {t('pages_Infomation_InformationCreate.type')}
                  <Text style={styles.textWarning}>✴︎</Text>
                </Text>
                <View>
                  <View style={[styles.wrapperItemRight, { border: !!errors.division ? 'solid 1px red' : 'none' }]}>
                    <View style={styles.wrapperRadioButton}>
                      <Text style={[styles.defaultText, styles.mr]}>
                        {' '}
                        {t('pages_Infomation_InformationCreate.important')}
                      </Text>
                      <RadioCustom
                        documentActive={watch('division')}
                        setDocumentActive={() => handleUpdateDivision('1')}
                        value="1"
                      />
                    </View>
                    <View style={styles.wrapperRadioButton}>
                      <Text style={[styles.defaultText, styles.mr]}>
                        {' '}
                        {t('pages_Infomation_InformationCreate.generally')}
                      </Text>
                      <RadioCustom
                        documentActive={watch('division')}
                        setDocumentActive={() => handleUpdateDivision('2')}
                        value="2"
                      />
                    </View>
                  </View>
                  <Text style={[styles.error, { marginLeft: 20, marginTop: 1 }]}>{errors?.division?.message}</Text>
                </View>
              </View>
            </Col>
          </Grid>
        ) : (
          <Loading />
        )}
      </ScrollView>
      {/* //modal  */}
      <ModalAlert
        isModalVisible={modalSave}
        setModalVisible={setModalSave}
        modalText={t('pages_Infomation_InformationCreate.savedInformation')}
        iconWidth={16}
        iconHeight={12.5}
        icon="successGreen.svg"
        linkPage="/information"
        onBackdropPressCallback={() => history.push('/information')}
      />
      <ModalAlert
        isModalVisible={modalDeleteSuccess}
        setModalVisible={setModalDeleteSuccess}
        modalText={t('pages_Infomation_InformationCreate.removedInformation')}
        iconWidth={16}
        iconHeight={12.5}
        icon="success-red.svg"
        linkPage="/information"
        onBackdropPressCallback={() => history.push('/information')}
      />
      <ModalDelete
        iconBtnLeft="close-white.svg"
        iconBtnRight="delete-white.svg"
        heightIconBtnRight={21}
        widthIconBtnRight={21}
        marginBottomIconRight={8}
        textBtnRight={t('commonText.buttonDeleteFile')}
        textBtnLeft={t('commonText.cancelButton')}
        modalDelete={modalDelete}
        setModalDelete={setModalDelete}
        modalAlert={modalDeleteSuccess}
        setModalAlert={setModalDeleteSuccess}
        icon="newDocument.svg"
        textHeader={t('pages_Infomation_InformationCreate.confirmRemoveInformationTitle')}
        textHeaderColor="#191919"
        textContent={[
          t('pages_Infomation_InformationCreate.confirmRemoveInformationContent1', {
            title: infoData?.title ?? 'XXX',
            id: formatDisplayId(infoId),
          }),
          t('pages_Infomation.ModalDeleteTextContent2'),
        ]}
        colorTextTitle="#5A5A5A"
        pv={30}
        iconBody="warning.svg"
        func={onDelete}
      />
      <ModalInformationPreview
        isModal={showPreview}
        setIsModal={setShowPreview}
        getInfoData={getValues}
        image={images}
      />
    </View>
  );
};

export default withNamespaces()(InfomationCreate);

import React, { useCallback, useState } from 'react';
import { set, useForm } from 'react-hook-form';
import moment from 'moment';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { View, Image, Pressable, StyleSheet, TouchableOpacity } from 'react-native';
import { Link, useLocation } from 'react-router-dom';
import { withNamespaces } from 'react-i18next';
import { LinearGradient } from 'expo-linear-gradient';
import { useMutation } from '@tanstack/react-query';

import DateTimePicker from '../common/DateTimePicker';
import JButton from '../common/JButton';
import { JText as Text } from '../common/JText';
import { BG_HOVER, INPUT_BG, LINE_COLOR, TEXT_COLOR_GREEN, TEXT_COLOR_GREY } from '@assets/style/styleDefault';
import { downloadCSV, post, put } from '@utils/RequestLevica';
import ModalAlertPayment from '@pages/PaymentManager/Modal/ModalAlertPayment';
import formatNumber from '@helper/formatNumber';
import ModalImportCSV from './partials/ModalImportCSV';

const defaultValues = {
  startDate: '',
  endDate: '',
};
const validationSchema = Yup.object().shape({
  startDate: Yup.string().required(''),
  endDate: Yup.string().required(''),
});

const HeaderPayManagement = (props) => {
  const {
    t,
    active,
    btn1,
    btn1Text = t('pages_PaymentManager_PaymentConfirmed.buttonPaymentProcessed'),
    btn2,
    btn3,
    btn4,
    setParams,
    selectAll,
    params,
    csvName,
    exportCSVLink,
    linkPaymentProcessed,
    paymentProcessedAll,
    totalAmount,
    listPaymentChecked,
    refetchQuery,
    isImportCSV,
    datetimeTitle,
    onDateFilter = () => {},
    tabBarExtraContent,
    listChecked,
    onImportCSV = () => {},
    onImportingCSV = () => {},
    totalCount = 0,
    stompClient,
  } = props;
  const [isModalAlert, setIsModalAlert] = useState(false);
  const [numberPaymentProccessed, setNumberPaymentProccessed] = useState(0);
  const [isOpenImportCSVModal, setIsOpenImportCSVModal] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [startDate, setStartDate] = useState(false);
  const [endDate, setEndDate] = useState(false);
  // const [paymentListDataCSV, setpaymentListDataCSV] = useState([]);
  const { pathname } = useLocation();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues,
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = (data) => {
    const newParams = {
      ...params,
      pageNumber: 1,
      startDate: moment.unix(moment(data.startDate).valueOf() / 1000).format('YYYY-MM-DD'),
      endDate: moment.unix(moment(data.endDate).valueOf() / 1000).format('YYYY-MM-DD'),
    };
    setParams(newParams);
    onDateFilter();
  };

  const { mutate: handlePaymentConfirmation } = useMutation({
    mutationFn: async (listPaymentCheckedId) => {
      return await post(linkPaymentProcessed, JSON.stringify(listPaymentCheckedId));
    },
    retry: 3,
  });

  const { mutate: handlePaymentDeposites } = useMutation({
    mutationFn: async (listPaymentCheckedId) => {
      return await put(linkPaymentProcessed, JSON.stringify(listPaymentCheckedId));
    },
    retry: 3,
  });

  // const converAccountType = (type) => {
  //   switch (type) {
  //     case '0100':
  //       return '普通';

  //     case '0200':
  //       return '当座';
  //     default:
  //       return '';
  //   }
  // };

  // useEffect(() => {
  //   const newPaymentListDataCSV = paymentListData?.map((item) => ({
  //     ...item,
  //     paymentDt: item?.paymentDt ? moment.unix(item.paymentDt / 1000).format('YYYY.MM.DD') : '',
  //     paymentConfirmDt: item?.paymentConfirmDt ? moment.unix(item.paymentConfirmDt / 1000).format('YYYY.MM.DD') : '',
  //     firstNameKanaLastNameKana: `${item.firstNameKana ?? ''} ${item.lastNameKana ?? ''}`,
  //     accountType: converAccountType(item.accountType),
  //   }));
  //   setpaymentListDataCSV(newPaymentListDataCSV);
  // }, [paymentListData]);

  const exportCSV = async () => {
    try {
      await downloadCSV(exportCSVLink, csvName, params);
    } catch (err) {
      console.log(err);
    }
  };

  const handlePaymentProcessed = () => {
    const listPaymentCheckedId = listPaymentChecked.map((item) => item.id);
    setIsProcessing(true);
    switch (pathname) {
      case '/payment':
        return handlePaymentDeposites(
          { requestIds: listPaymentCheckedId, isCheckedAll: paymentProcessedAll ? true : false },
          {
            onSuccess: () => {
              setIsModalAlert(true);
              setNumberPaymentProccessed(paymentProcessedAll ? totalCount : listPaymentChecked.length);
              refetchQuery();
              setIsProcessing(false);
            },
            onError: (error) => {
              setIsProcessing(false);
            },
          }
        );
      case '/payment/confirmed':
        return handlePaymentConfirmation(
          { ids: listPaymentCheckedId, isCheckedAll: paymentProcessedAll ? true : false },
          {
            onSuccess: () => {
              setIsModalAlert(true);
              setNumberPaymentProccessed(paymentProcessedAll ? totalCount : listPaymentChecked.length);
              refetchQuery();
              if (stompClient) {
                stompClient.send(
                  '/app/paymentRefundChecked',
                  {},
                  JSON.stringify({ listId: [], checked: false, isCheckedAll: null })
                );
              }
              setIsProcessing(false);
            },
            onError: (error) => {
              setIsProcessing(false);
            },
          }
        );
      default:
        return false;
    }
  };

  const handleSetStartDate = useCallback(
    (value) => {
      setStartDate(value);
      if (!value) return setParams({ ...params, startDate: '', endDate: '' });
    },
    [setParams, setStartDate]
  );

  const handleSetEndDate = useCallback(
    (value) => {
      setEndDate(value);
      if (!value) return setParams({ ...params, startDate: '', endDate: '' });
    },
    [setParams, setEndDate]
  );

  return (
    <View>
      <View style={styles.wrapperList}>
        <Pressable style={({ hovered }) => [hovered && styles.buttonHovered]}>
          <Link to={'/payment'} style={{ textDecorationLine: 'none' }}>
            <View style={[styles.listHeader, active === '/payment' && styles.activeBG]}>
              <Image
                style={styles.iconList}
                source={
                  active === '/payment'
                    ? require('@assets/icons/listPayActive.svg')
                    : require('@assets/icons/listPay.svg')
                }
              />
              <Text style={active === '/payment' ? styles.textActive : styles.textNotActive}>
                {t('components_PayManagement.paymentConfirmation')}
              </Text>
            </View>
          </Link>
        </Pressable>
        <Pressable style={({ hovered }) => [hovered && styles.buttonHovered]}>
          <Link to={'/payment/deposites'} style={{ textDecorationLine: 'none' }}>
            {/* <Link to={'/payment'} style={{ textDecorationLine: 'none' }}> */}
            <View style={[styles.listHeader, active === '/payment/deposites' && styles.activeBG]}>
              <Image
                style={styles.iconCheck}
                source={
                  active === '/payment/deposites'
                    ? require('@assets/icons/iconCheckActive.svg')
                    : require('@assets/icons/iconCheckPay.svg')
                }
              />
              <Text style={active === '/payment/deposites' ? styles.textActive : styles.textNotActive}>
                {t('components_PayManagement.deposit')}
              </Text>
            </View>
          </Link>
        </Pressable>
        <Pressable style={({ hovered }) => [hovered && styles.buttonHovered]}>
          <Link to={'/payment/prepaid-usage'} style={{ textDecorationLine: 'none' }}>
            <View style={[styles.listHeader, active === '/payment/prepaid-usage' && styles.activeBG]}>
              <Image
                style={styles.iconDocument}
                source={
                  active === '/payment/prepaid-usage'
                    ? require('@assets/icons/documentPayActive.svg')
                    : require('@assets/icons/documentPay.svg')
                }
              />
              <Text style={active === '/payment/prepaid-usage' ? styles.textActive : styles.textNotActive}>
                {t('components_PayManagement.prepaidUsageHistory')}
              </Text>
            </View>
          </Link>
        </Pressable>
        <Pressable style={({ hovered }) => [hovered && styles.buttonHovered]}>
          <Link to={'/payment/confirmed'} style={{ textDecorationLine: 'none' }}>
            <View style={[styles.listHeader, active === '/payment/confirmed' && styles.activeBG]}>
              <Image
                style={styles.iconPayConfirm}
                source={
                  active === '/payment/confirmed'
                    ? require('@assets/icons/payConfirmActive.svg')
                    : require('@assets/icons/payConfirm.svg')
                }
              />
              <Text style={active === '/payment/confirmed' ? styles.textActive : styles.textNotActive}>
                {t('components_PayManagement.paymentConfirmationList')}
              </Text>
            </View>
          </Link>
        </Pressable>
        <Pressable style={({ hovered }) => [hovered && styles.buttonHovered]}>
          <Link to={'/payment/complete'} style={{ textDecorationLine: 'none' }}>
            <View style={[styles.listHeader, active === '/payment/complete' && styles.activeBG]}>
              <Image
                style={styles.iconPaymend}
                source={
                  active === '/payment/complete'
                    ? require('@assets/icons/paymedActive.svg')
                    : require('@assets/icons/paymed.svg')
                }
              />
              <Text style={active === '/payment/complete' ? styles.textActive : styles.textNotActive}>
                {t('components_PayManagement.paidList')}
              </Text>
            </View>
          </Link>
        </Pressable>
        {tabBarExtraContent ?? null}
      </View>
      <View style={styles.wrapperSearch}>
        <View style={styles.leftSearch}>
          <View style={styles.wrapperDateForm}>
            <Text style={styles.textHeader}>{datetimeTitle || t('components_PayManagement.allPeriod')}</Text>
            <View style={styles.wrapperInput}>
              <View style={styles.wrapperDateTimePicker}>
                <DateTimePicker
                  name="startDate"
                  control={control}
                  type="date"
                  setDateTime={handleSetStartDate}
                  max={endDate ?? moment().format('YYYY-MM-DD')}
                />
              </View>
              <View>
                <Text style={styles.textNotActiveDate}>~</Text>
              </View>
              <View style={styles.wrapperDateTimePicker}>
                <DateTimePicker
                  name="endDate"
                  control={control}
                  type="date"
                  min={startDate}
                  setDateTime={handleSetEndDate}
                />
              </View>
              <View style={{ marginLeft: 20 }}>
                <JButton
                  text={t('components_PayManagement.specifiedPeriod')}
                  textColor="#fff"
                  btnColor="#2D81F3"
                  linkIcon="iconClockActive.svg"
                  heightIcon={15}
                  width={140}
                  widthIcon={15}
                  disabled={!(startDate && endDate)}
                  linkIconDisabled="iconClock.svg"
                  onPressAction={() => handleSubmit(onSubmit)()}
                />
              </View>
            </View>
          </View>
          <View style={styles.wrapperItemRight}>
            <View style={styles.wrapperDateForm}>
              <Text style={styles.textHeader}>{t('components_PayManagement.transferListTotalAmount')}</Text>
              <Text style={styles.transferTotalAmount} numberOfLines={3}>
                ¥ {formatNumber(totalAmount)}
              </Text>
            </View>
            <TouchableOpacity
              activeOpacity={0.7}
              onPress={exportCSV}
              style={[styles.csvBtn, { width: isImportCSV ? '22%' : '44%' }]}
            >
              <Text style={styles.textCSV}>{t('components_PayManagement.CSVExport')}</Text>
            </TouchableOpacity>
            {isImportCSV && (
              <TouchableOpacity
                activeOpacity={0.7}
                onPress={() => setIsOpenImportCSVModal((prev) => !prev)}
                style={styles.csvBtn}
              >
                <Text style={[styles.textCSV, { color: '#32a5f7' }]}>{t('components_PayManagement.CSVImport')}</Text>
              </TouchableOpacity>
            )}
          </View>
        </View>
        <View style={styles.rightSearch}>
          {btn1 ? (
            <LinearGradient colors={['#54DBB2', '#04C48B']} style={styles.btn1}>
              <JButton
                text={btn1Text}
                width={150}
                textColor={'#fff'}
                onPressAction={handlePaymentProcessed}
                disabled={isProcessing || listChecked?.length < 1}
                isProcessing={isProcessing}
              />
            </LinearGradient>
          ) : null}
          {btn2 ? (
            btn2 === true ? (
              <JButton
                text={t('commonText.buttonSelectAll')}
                onPressAction={() => selectAll()}
                width={150}
                textColor={TEXT_COLOR_GREEN}
                btnColor={INPUT_BG}
                borderBtn={`1px solid ${LINE_COLOR}`}
              />
            ) : (
              btn2
            )
          ) : null}
          {btn3 ? btn3 : null}
          {btn4 ? btn4 : null}
        </View>
      </View>
      <ModalAlertPayment
        isModalVisible={isModalAlert}
        setModalVisible={setIsModalAlert}
        countChecked={numberPaymentProccessed}
        iconName="checkedIcon.svg"
        textContent={t('pages_PaymentManager_PaymentConfirmed.modalAlertPaymentText')}
        heightIcon={30}
        widthIcon={30}
      />
      <ModalImportCSV
        onImportCSV={onImportCSV}
        onImportingCSV={onImportingCSV}
        isModalVisible={isOpenImportCSVModal}
        setModalVisible={setIsOpenImportCSVModal}
        t={t}
      />
    </View>
  );
};

export default withNamespaces()(HeaderPayManagement);

const styles = StyleSheet.create({
  btn1: { borderRadius: 5 },
  wrapperList: {
    marginTop: 10,
    flexDirection: 'row',
    alignItems: 'center',
    minHeight: 42,
    flexWrap: 'wrap',
    marginBottom: 5,
  },
  textHeader: {
    fontSize: 16,
  },
  listHeader: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 15,
    paddingRight: 15,
  },
  iconList: {
    height: 12,
    width: 17,
    marginRight: 5,
  },
  iconCheck: {
    height: 16,
    width: 20,
    marginRight: 5,
  },
  iconDocument: {
    height: 15,
    width: 12,
    marginRight: 5,
  },
  iconPayConfirm: {
    height: 15,
    width: 22,
    marginRight: 5,
  },
  iconPaymend: {
    height: 15,
    width: 20,
    marginRight: 5,
  },
  textActive: {
    color: TEXT_COLOR_GREEN,
  },
  textCSV: { textDecorationLine: 'underline', color: TEXT_COLOR_GREEN, paddingBottom: 10, paddingLeft: 10 },

  textNotActive: {
    color: TEXT_COLOR_GREY,
  },
  textNotActiveDate: {
    fontSize: 25,
    paddingHorizontal: 10,
  },
  wrapperSearch: {
    paddingLeft: 15,
    marginBottom: 10,
    flexDirection: 'row',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    gap: 10,
    minHeight: 104,
  },
  leftSearch: {
    flexDirection: 'row',
    alignItems: 'flex-end',
    flex: 1,
    gap: 10,
  },
  wrapperDateForm: {
    gap: 10,
  },
  wrapperInput: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  wrapperDateTimePicker: {
    minWidth: 160,
    border: `1px solid ${LINE_COLOR}`,
    borderRadius: 5,
  },
  wrapperItemRight: {
    flexDirection: 'row',
    flex: 1,
    flexWrap: 'wrap',
    alignItems: 'flex-end',
    justifyContent: 'flex-start',
    marginLeft: 30,
    marginRight: 30,
  },
  transferTotalAmount: {
    lineHeight: 40,
    border: `1px solid #1E76FE`,
    borderRadius: 5,
    textAlign: 'center',
    backgroundColor: BG_HOVER,
    fontSize: 20,
    paddingHorizontal: 10,
    maxWidth: 400,
  },
  rightSearch: {
    alignItems: 'center',
    position: 'relative',
    gap: 20,
  },
  countCheckedStyle: {
    position: 'absolute',
    bottom: 45,
    right: 80,
    color: 'red',
  },
  buttonHovered: {
    backgroundColor: BG_HOVER,
  },
  csvBtn: {
    width: '22%',
    maxWidth: 'fit-content',
    marginLeft: 10,
  },
});

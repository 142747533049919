import React from 'react';
import { View } from 'react-native';
import styles from '../styles';
import { JText as Text } from '@components/common/JText';

const DoughnutTable = ({ color, content, value }) => {
  return (
    <View style={styles.DoughnutTable}>
      <View style={styles.DoughnutTableContent}>
        <View style={[styles.DoughnutTableColor, { backgroundColor: color }]} />
        <Text style={styles.defaultTextColor}>{content}</Text>
      </View>
      <Text style={styles.defaultTextColor}>{`${value} %`}</Text>
    </View>
  );
};

export default DoughnutTable;

import React, { useState } from 'react';
import { View, TouchableOpacity, Image, StyleSheet } from 'react-native';
import { useFormContext } from 'react-hook-form';
import { Grid, Col } from 'react-native-easy-grid';
import { withNamespaces } from 'react-i18next';
import moment from 'moment';
import { useSelector } from 'react-redux';

import { BG_SIDEBAR, COLOR_TEXT_DEFAULT, INPUT_BG } from '@assets/style/styleDefault';
import { JText as Text } from '@components/common/JText';
import ModalImagePreview from '@components/Modals/ModalImagePreview';
import { checkPermission } from '@helper/commonFunction';
import { ROLE_USER } from '@helper/constant';

const statusData = [
  {
    id: 1,
    text: '稼働中',
    icon: 'circleStatus0.svg',
    color: '#00be85',
  },
  {
    id: 2,
    text: 'メールアドレス登録完了',
    icon: 'circleStatus1.svg',
    color: '#191919',
  },
  {
    id: 3,
    text: '携帯電話番号登録完了',
    icon: 'circleStatus1.svg',
    color: '#191919',
  },
  {
    id: 4,
    text: '会員情報入力完了',
    icon: 'circleStatus1.svg',
    color: '#191919',
  },
  {
    id: 5,
    text: '会員情報入力完了・承認待ち',
    icon: 'circleStatus2.svg',
    color: '#1E76FE',
  },
  {
    id: 6,
    text: '会員登録情報補正・承認待ち',
    icon: 'circleStatus2.svg',
    color: '#1E76FE',
  },
  {
    id: 7,
    text: '本人確認業務NG・再提出依頼中',
    icon: 'circleStatus1.svg',
    color: '#191919',
  },
  {
    id: 8,
    text: '本人確認業務NG・個別対応中',
    icon: 'circleStatus3.svg',
    color: '#3B00FF',
  },
  {
    id: 9,
    text: 'マイナンバーNG・再提出依頼中',
    icon: 'circleStatus1.svg',
    color: '#191919',
  },

  {
    id: 10,
    text: 'マイナンバー補正・承認待ち',
    icon: 'circleStatus2.svg',
    color: '#1E76FE',
  },
  {
    id: 11,
    text: 'アカウント情報変更申請中',
    icon: 'circleStatus2.svg',
    color: '#1E76FE',
  },
  {
    id: 12,
    text: 'アカウント一時停止',
    icon: 'circleStatus4.svg',
    color: '#FF0000',
  },
  {
    id: 13,
    text: 'アカウント永久停止',
    icon: 'circleStatus4.svg',
    color: '#FF0000',
  },
];

const BankInfo = ({ t, setOpenBank, userInfo }) => {
  const [currentStatus, setCurrentStatus] = useState(statusData[0]);
  const [modalIdentityVerification, setModalIdentityVerification] = useState(false);
  const [modalNumberCard, setOpenModalNumberCard] = useState(false);
  const [modalWallet, setModalWallet] = useState(false);
  const [modalPaymentQRcode, setModalPaymentQRcode] = useState(false);

  const userInfoRole = useSelector((state) => state.merchantUserInfo);
  const { getValues } = useFormContext();
  const [accountCreateAt] = getValues(['createdAt']);

  return (
    <View style={styles.warperBank}>
      <Grid style={{ marginBottom: 10 }}>
        <Col size={5}>
          <Text style={styles.textWhite}>{t('pages_AccountShop_CreateAccount.accountValidDate')}</Text>
        </Col>
        <Col size={7} style={styles.textRight}>
          <Text style={styles.textCreateAt}>
            {moment(accountCreateAt ?? null).format('YYYY.MM.DD') === 'Invalid date'
              ? ''
              : moment(accountCreateAt).format('YYYY.MM.DD')}
          </Text>
        </Col>
      </Grid>

      <TouchableOpacity style={[styles.row, styles.bankInfoDetails]}>
        <Text style={styles.textWhite}>{t('pages_AccountShop_CreateAccount.accountStatus')}</Text>
        <Text style={{ color: currentStatus.color }} numberOfLines={2}>
          {currentStatus.text}
        </Text>
        <Image style={styles.subfix} source={require('@assets/icons/iconShare.svg')} />
      </TouchableOpacity>

      <ModalImagePreview
        card
        isModal={modalIdentityVerification}
        name={t('pages_AccountShop_CreateAccount.identityVerificationDocuments')}
        setIsModal={setModalIdentityVerification}
      />
      <ModalImagePreview
        card
        isModal={modalNumberCard}
        name={t('pages_AccountShop_CreateAccount.myNumberCard')}
        setIsModal={setOpenModalNumberCard}
      />
      <ModalImagePreview
        qrCode={userInfo?.walletQr}
        name={t('pages_AccountShop_CreateAccount.walletQRcode')}
        isModal={modalWallet}
        setIsModal={setModalWallet}
      />
      <ModalImagePreview
        qrCode={userInfo?.paymentQr}
        name={t('pages_AccountShop_CreateAccount.paymentQRcode')}
        isModal={modalPaymentQRcode}
        setIsModal={setModalPaymentQRcode}
      />
    </View>
  );
};

export default withNamespaces()(BankInfo);

const LinkBankInfo = ({ text }) => {
  return (
    <View style={[styles.row, styles.bankInfoDetails, styles.marginBottom20]}>
      <Text numberOfLines={2}>{text}</Text>
      <Image style={styles.subfix} source={require('@assets/icons/iconShare.svg')} />
    </View>
  );
};

const styles = StyleSheet.create({
  warperBank: { backgroundColor: BG_SIDEBAR, padding: 20, borderRadius: 5, marginBottom: 20, paddingBottom: 30 },
  circle: {
    width: 12,
    height: 12,
    flex: 1,
    resizeMode: 'contain',
    marginLeft: 5,
  },
  textRight: {
    textAlign: 'right',
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: INPUT_BG,
    border: '1px solid #555555',
    padding: 20,
    borderRadius: 5,
  },
  bankInfoDetails: {
    height: 42,
    backgroundColor: INPUT_BG,
    borderRadius: 3,
    paddingLeft: 15,
    paddingRight: 35,
    position: 'relative',
  },

  subfix: {
    position: 'absolute',
    width: 15,
    height: 15,
    top: '30%',
    right: 10,
  },

  minHeight: {
    minHeight: 'unset',
    gap: 10,
    marginVertical: 20,
  },
  relative: {
    position: 'relative',
  },
  titleText: {
    position: 'absolute',
    top: '15%',
    left: 10,
    color: COLOR_TEXT_DEFAULT,
    zIndex: 1,
  },
  descText: {
    textAlign: 'center',
  },
  textCreateAt: { color: '#BCBCBC' },
});

import moment from 'moment';

export const formatNumber = (number = '') => {
  return `${number}`?.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};
export const formatDateTime = (date) => {
  return moment(date).format('YYYY.MM.DD HH:mm');
};
export const formatTime = (time) => {
  return moment(time).format('HH:mm');
};
export const statusColor = (status) => {
  switch (status) {
    case 'MASTER':
      return '#e6ce51';
    case 'ASSOCIATE':
      return '#70e3ae';
    case 'EXECUTIVE':
      return '#c5cbd2';
    default:
      return false;
  }
};
export const statusColorTransaction = (status) => {
  switch (status) {
    case -1:
      return '#ff0000';
    case 0:
      return '#808080';
    case 1:
      return '#1e76fe';
    case 2:
      return '#00be85';
    default:
      return false;
  }
};

export const converTextCamelCase = (text = '') => {
  return `${text?.slice(0, 1)}${text?.slice(1).toLowerCase()}`;
};

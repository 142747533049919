import React from 'react';
import { Center, Radio } from 'native-base';

const RadioCustom = ({ item, setDocumentActive, documentActive, onClick, isDisabled }) => {
  return (
    <Center flex={1}>
      <Radio.Group
        name="myRadioGroup"
        accessibilityLabel={'accessibilityLabel'}
        value={documentActive?.id}
        onChange={() => (onClick ? onClick(item) : setDocumentActive(item))}
      >
        <Radio
          accessibilityLabel={'accessibilityLabel'}
          bg="BG_CHECKBOX"
          _icon={{ color: 'CHECKBOX_COLOR' }}
          value={item.id}
          isDisabled={isDisabled}
        />
      </Radio.Group>
    </Center>
  );
};

export default RadioCustom;

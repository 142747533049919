import React, { useState } from 'react';
import { View, Image, TouchableOpacity } from 'react-native';
import { LinearGradient } from 'expo-linear-gradient';
import { NavLink, useLocation } from 'react-router-dom';
import { withNamespaces } from 'react-i18next';
import { Video } from 'expo-av';

import { JText as Text } from '@components/common/JText';
import styles from './style';

const Completed = ({ t }) => {
  const location = useLocation();
  const checkLocation = location.pathname == '/forgot-password/phone/completed';
  const [isErrorBackgroundVideo, setErrorBackgroundVideo] = useState(false);
  return (
    <>
      <View style={styles.backgroundColor}>
        {isErrorBackgroundVideo ? (
          <Image style={styles.backgroundImg} source={require('@assets/image/login.jpg')} alt="background login" />
        ) : (
          <Video
            source={require('@assets/videos/login-background.mp4')}
            style={styles.backgroundVideo}
            posterStyle={styles.backgroundImg}
            rate={1}
            shouldPlay={true}
            isLooping={true}
            isMuted={true}
            posterSource={require('@assets/image/login.jpg')}
            usePoster={true}
            onError={() => setErrorBackgroundVideo(true)}
            VideoNaturalSize={{ width: 1200 }}
            resizeMode="cover"
          />
        )}
        <View style={styles.backGroup}>
          <View style={styles.form}>
            <View style={styles.formHeader}>
              <View style={[styles.textHeader, styles.flexDirection]}>
                <Image source={require('@assets/icons/successGreen.svg')} style={styles.iconClose} />
                <Text style={styles.colorS}>
                  {checkLocation ? t('notification.sentAnSMS') : t('notification.passwordResetComplete')}
                </Text>
              </View>
            </View>
            <View style={styles.formBody}>
              <View>
                <Text style={styles.textTitle}>
                  {checkLocation
                    ? t('notification.sentAnSMSWithEmail')
                    : t('pages_Setting_ResetPassword.modalAlertText')}
                </Text>
              </View>
              <View>
                <Text style={styles.textTitle}>
                  {checkLocation ? t('notification.checkSMSPls') : t('notification.loginByNewPassWordPls')}
                </Text>
              </View>
            </View>
            <View style={styles.formAction}>
              <LinearGradient colors={['#54DBB2', '#54DBB2']} style={{ borderRadius: 5 }}>
                <TouchableOpacity>
                  <NavLink to={'/login'} style={{ textDecorationLine: 'none' }}>
                    <View style={[styles.buttonSubmit]}>
                      <View style={styles.buttonSubmit}>
                        <Text style={styles.textBtnSubmit}>サインイン画面に戻る</Text>
                      </View>
                    </View>
                  </NavLink>
                </TouchableOpacity>
              </LinearGradient>
            </View>
          </View>
        </View>
      </View>
    </>
  );
};

export default withNamespaces()(Completed);

import { StyleSheet } from 'react-native';
import {
  BG_COLOR,
  BG_HOVER,
  BG_TABLE_DEFAULT,
  BORDER_TABLE_GREY,
  BTN_COLOR_PRIMARY,
  COLOR_TEXT_DEFAULT,
  FONT_FAMILY_DEFAULT,
  FONT_SIZE_DEFAULT,
  LINE_COLOR,
  TEXT_COLOR_GREY,
  TEXT_COLOR_HEADER_TABLE,
} from '@assets/style/styleDefault';

const styles = StyleSheet.create({
  container: { flex: 1, position: 'relative' },
  head: { height: 42, paddingVertical: 15 },
  headBody: { height: 42, paddingVertical: 15, borderBottomColor: LINE_COLOR, borderBottomWidth: 1 },
  wrapper: { flexDirection: 'row' },
  row: {
    height: 50,
    borderBottomWidth: 1,
    borderBottomColor: BORDER_TABLE_GREY,
    paddingVertical: 15,
  },
  text: {
    color: COLOR_TEXT_DEFAULT,
    fontFamily: FONT_FAMILY_DEFAULT,
    fontSize: FONT_SIZE_DEFAULT,
    textAlign: 'center',
  },
  textHead: {
    color: TEXT_COLOR_GREY,
    textAlign: 'center',
    fontFamily: FONT_FAMILY_DEFAULT,
    fontSize: FONT_SIZE_DEFAULT,
  },
  wrapperHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: 20,
  },
  textHeader: {
    fontSize: 16,
    color: TEXT_COLOR_HEADER_TABLE,
  },
  wrapperHeaderLeft: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '50%',
  },
  btnHeader: {
    height: 42,
    backgroundColor: BTN_COLOR_PRIMARY,
    paddingLeft: 15,
    paddingRight: 15,
  },
  iconDropdown: {
    width: 10,
    height: 10,
    marginLeft: 5,
    marginBottom: 4,
  },
  circle: {
    width: 10,
    height: 10,
    marginRight: 5,
  },
  iconWarning: {
    width: 22,
    height: 22,
    marginHorizontal: 10,
  },

  wrapperItemStart: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingLeft: 10,
  },
  wrapperStickyHeader: {
    position: 'sticky',
    top: 0,
    backgroundColor: BG_COLOR,
    zIndex: 100,
  },
  rowHovered: {
    backgroundColor: BG_HOVER,
  },
  wrapperStickyTitle: {
    position: 'sticky',
    top: 79,
    backgroundColor: BG_COLOR,
    zIndex: 10,
  },
  wrapperStickyTableHead: {
    position: 'sticky',
    top: 182,
    backgroundColor: BG_COLOR,
    zIndex: 10,
  },
  horizontal: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  checkboxItem: {
    marginLeft: 10,
  },
  iconTableHeadASC: {
    width: 12,
    height: 12,
    resizeMode: 'contain',
    marginLeft: 5,
    cursor: 'pointer',
  },
  iconTableHeadDESC: {
    width: 12,
    height: 12,
    resizeMode: 'contain',
    marginLeft: 5,
    cursor: 'pointer',
    transform: [{ rotate: '180deg' }],
  },
  iconTableRow: {
    width: 24,
    height: 24,
    marginRight: 5,
  },
  checkDate: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    paddingRight: 10,
  },
  headerSearch: {
    marginRight: 0,
    marginTop: 10,
  },
});

export default styles;

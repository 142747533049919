import React, { useState } from 'react';
import { View, Image, ScrollView } from 'react-native';
import { Grid, Row, Col } from 'react-native-easy-grid';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { LinearGradient } from 'expo-linear-gradient';
import { withNamespaces } from 'react-i18next';

import JButton from '@components/common/JButton';
import styles from './styles';
import ModalBurn from '@components/Modals/ModalBurn';

import { JText as Text } from '@components/common/JText';
import HeaderTokenHistoryCommon from '@components/Headers/HeaderTokenHistoryCommon';
const fakeData = {
  assetType: 'ギフト',
  issuingCompanyName: 'ANICANA JAPAN',
  tokenName: '英数字',
  symbol: ' 英数字3~5文字',

  tokenIcon: 'https://image.shutterstock.com/image-vector/black-flat-trend-icon-long-600w-1371182174.jpg',

  issuanceWallet: '発行用ウォレットアドレス表示 ',

  administrativeWallet: '管理用ウォレットアドレス表示',
  tokenIssueDate: ' 日付',
  totalIssueNumber: '数値',

  numberOfTokensActivated: '数値（発行用ウォレットアドレスから引き出された総数）',
  numberOfTokensCanSent: '数値（管理用ウォレットのトークン残高）',
  numberOfTokensBurned: '数値（Burn したトークンの総数）',

  holderLimit: '人数',
};

const GiftDetail = ({ t }) => {
  const history = useHistory();
  const { productId } = useParams();

  const [modalBurn, setModalBurn] = useState(false);

  const OpenModalBurn = () => {
    setModalBurn(true);
  };

  return (
    <ScrollView showsVerticalScrollIndicator={false} showsHorizontalScrollIndicator={false}>
      <View style={styles.positionSticky}>
        <HeaderTokenHistoryCommon />
      </View>
      <View style={styles.bgContent}>
        <Grid style={styles.textContent}>
          <Col size={9}>
            <Row style={styles.rowContent}>
              <Col size={3}>
                <Text style={styles.textLabel}>{t('pages_Token_GiftDetail.assetType')}</Text>
              </Col>
              <Col size={6}>
                <Text style={styles.textPlaceholder}>{fakeData.assetType}</Text>
              </Col>
            </Row>
            <Row style={styles.rowContent}>
              <Col size={3}>
                <Text style={styles.textLabel}>{t('pages_Token_GiftDetail.issuingCompanyName')}</Text>
              </Col>
              <Col size={6}>
                <Text style={styles.textPlaceholder}>{fakeData.issuingCompanyName}</Text>
              </Col>
            </Row>
            <Row style={styles.rowContent}>
              <Col size={3}>
                <Text style={styles.textLabel}>{t('pages_Token_GiftDetail.tokenName')}</Text>
              </Col>
              <Col size={6}>
                <Text style={styles.textPlaceholder}>{fakeData.tokenName}</Text>
              </Col>
            </Row>

            <Row style={styles.rowContent}>
              <Col size={3}>
                <Text style={styles.textLabel}>{t('pages_Token_GiftDetail.symbol')}</Text>
              </Col>
              <Col size={6}>
                <Text style={styles.textPlaceholder}>{fakeData.symbol}</Text>
              </Col>
            </Row>
            <Row style={styles.rowContent}>
              <Col size={3}>
                <Text style={styles.textLabel}>{t('pages_Token_GiftDetail.tokenIcon')}</Text>
              </Col>
              <Col size={4}>
                <Image style={styles.iconGlobal} source={fakeData.tokenIcon} />
              </Col>
              <Col size={2} style={{ alignItems: 'center' }}>
                <View onPress={() => pickImg()}>
                  <Text style={styles.textLink}>{t('pages_Token_GiftDetail.selectImg')}</Text>
                </View>
              </Col>
            </Row>
            <Row style={styles.rowContent}>
              <Col size={3}>
                <Text style={styles.textLabel}>{t('pages_Token_GiftDetail.issuanceWallet')}</Text>
                <Text style={styles.textLabel}>{t('pages_Token_GiftDetail.tokenTotalAddress')}</Text>
              </Col>
              <Col size={4}>
                <Text style={styles.textPlaceholder}>{fakeData.issuanceWallet}</Text>
              </Col>
              <Col size={2}>
                <LinearGradient colors={['#54DBB2', '#04C48B']} style={{ borderRadius: 5 }}>
                  <JButton
                    width="100%"
                    text={t('pages_Token_GiftDetail.sendToken')}
                    textColor="#f2f2f2"
                    hasIcon
                    linkIcon="iconFly.svg"
                    widthIcon={20}
                    heightIcon={15}
                    onPressAction={() => history.push('/tokens/send-gift-from-issuing-wallet')}
                  />
                </LinearGradient>
              </Col>
            </Row>
            <Row style={styles.rowContent}>
              <Col size={3}>
                <Text style={styles.textLabel}>{t('pages_Token_GiftDetail.tokenIssueDate')}</Text>
              </Col>
              <Col size={6}>
                <Text style={styles.textPlaceholder}>{fakeData.tokenIssueDate}</Text>
              </Col>
            </Row>
            <Row style={styles.rowContent}>
              <Col size={3}>
                <Text style={styles.textLabel}>{t('pages_Token_GiftDetail.issueLimit')}</Text>
              </Col>
              <Col size={6}>
                <Text style={styles.textPlaceholder}>{fakeData.totalIssueNumber}</Text>
              </Col>
            </Row>
            <Row style={styles.rowContent}>
              <Col size={3}>
                <Text style={styles.textLabel}>{t('pages_Token_GiftDetail.numberOfTokensActivated')}</Text>
              </Col>
              <Col size={6}>
                <Text style={styles.textPlaceholder}>{fakeData.numberOfTokensActivated}</Text>
              </Col>
            </Row>
            <Row style={styles.rowContent}>
              <Col size={3}>
                <Text style={styles.textLabel}>{t('pages_Token_GiftDetail.numberOfTokensCanBeSendOrBurn')}</Text>
              </Col>
              <Col size={6}>
                <Text style={styles.textPlaceholder}>{fakeData.numberOfTokensCanSent}</Text>
              </Col>
            </Row>
            <Row style={styles.rowContent}>
              <Col size={3}>
                <Text style={styles.textLabel}>{t('pages_Token_GiftDetail.numberOfTokensBurned')}</Text>
              </Col>
              <Col size={6}>
                <Text style={styles.textPlaceholder}>{fakeData.numberOfTokensBurned}</Text>
              </Col>
            </Row>
            <Row style={styles.rowContent}>
              <Col size={3}>
                <Text style={styles.textLabel}>{t('pages_Token_GiftDetail.holderLimit')}</Text>
              </Col>
              <Col size={6}>
                <Text style={styles.textPlaceholder}>{fakeData.holderLimit}</Text>
              </Col>
            </Row>
          </Col>
          <Col size={3}>
            <Row style={[styles.rowContent, { borderBottomWidth: 0 }]} />
            <Row style={[styles.rowContent, { borderBottomWidth: 0 }]} />
            <Row style={[styles.rowContent, { borderBottomWidth: 0 }]} />
            <Row style={[styles.rowContent, { borderBottomWidth: 0 }]} />
            <Row style={[styles.rowContent, { borderBottomWidth: 0 }]} />
            <Row style={[styles.rowContent, { borderBottomWidth: 0 }]}>
              <LinearGradient colors={['#FE8463', '#FD4F44']} style={{ borderRadius: 5, width: '65%' }}>
                <JButton
                  text="Burn"
                  textColor="#f2f2f2"
                  hasIcon
                  linkIcon="iconBurn.svg"
                  widthIcon={15}
                  heightIcon={20}
                  onPressAction={OpenModalBurn}
                />
              </LinearGradient>
            </Row>

            <Row style={[styles.rowContent, { borderBottomWidth: 0 }]} />
            <Row style={[styles.rowContent, { borderBottomWidth: 0 }]} />
            <Row style={[styles.rowContent, { borderBottomWidth: 0 }]} />
            <Row style={[styles.rowContent, { borderBottomWidth: 0 }]} />
            <Row style={[styles.rowContent, { borderBottomWidth: 0 }]} />
            <Row style={[styles.rowContent, { borderBottomWidth: 0 }]}></Row>
          </Col>
        </Grid>
      </View>
      <ModalBurn
        isModal={modalBurn}
        walletAddressSend="0x5754284f345afc66a98fbb0a0afe71e0f007b123"
        setIsModal={setModalBurn}
      />
    </ScrollView>
  );
};

export default withNamespaces()(GiftDetail);

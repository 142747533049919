import React from 'react';
import { ActivityIndicator, StyleSheet, View } from 'react-native';

const LoadingButton = ({ size = 'small', color = '#fff', style }) => {
  return (
    <View style={[styles.wrapper, style]}>
      <ActivityIndicator size={size} color={color} />
    </View>
  );
};

export default LoadingButton;

const styles = StyleSheet.create({
  wrapper: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
  },
});

import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import { NativeBaseProvider } from 'native-base';
import * as Font from 'expo-font';
import { BrowserRouter as Router } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import moment from 'moment-timezone';

import './i18n';
import store from './redux/store';
import MainLayout from './layouts/MainLayout';
import { theme } from './assets/style/customThemNativeBase';
import './assets/style/reactPhoneNumberInput.css';

const queryClient = new QueryClient();

const App = () => {
  const loadFonts = async () => {
    await Font.loadAsync({
      NotoSansJP: {
        uri: require('./assets/fonts/NotoSansJP.woff2'),
        fontDisplay: Font.FontDisplay.SWAP,
      },
      BebasNeue: {
        uri: require('./assets/fonts/BebasNeue-Regular.ttf'),
        fontDisplay: Font.FontDisplay.SWAP,
      },
    });
  };

  useEffect(() => loadFonts(), []);

  moment.locale('ja');
  moment.tz.setDefault('Asia/Tokyo');

  return (
    <NativeBaseProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <Provider store={store}>
          <Router>
            <MainLayout />
          </Router>
        </Provider>
      </QueryClientProvider>
    </NativeBaseProvider>
  );
};

export default App;

import React, { useEffect, useState } from 'react';
import { View, StyleSheet } from 'react-native';
import { useHistory, useParams } from 'react-router-dom';
import { withNamespaces } from 'react-i18next';

import HeaderSearch from './HeaderSearch';
import HeaderToken from './HeaderToken';
import DropDownColor from '../common/DropDownColor';
import {
  BG_COLOR,
  TEXT_COLOR_GREEN,
  TEXT_COLOR_PRIMARY,
  TEXT_COLOR_RED,
  TEXT_COLOR_WHITE,
} from '@assets/style/styleDefault';

const HeaderTokenHistoryCommon = ({ t }) => {
  const [headerData, setHeaderData] = useState({});
  const [params, setParams] = useState('');

  const { productId } = useParams();
  const history = useHistory();

  // const { setParams, params } = props;

  const openModalBack = () => {
    history.goBack();
  };

  const optionSEC = [
    {
      option: t('components_Headers_HeaderTokenHistoryCommon.draft'),
      color: TEXT_COLOR_WHITE,
      value: '0100',
    },
    {
      option: t('components_Headers_HeaderTokenHistoryCommon.private'),
      color: TEXT_COLOR_PRIMARY,
      value: '0200',
    },
    {
      option: t('components_Headers_HeaderTokenHistoryCommon.inUse'),
      color: TEXT_COLOR_GREEN,
      value: '0300',
    },
    {
      option: t('components_Headers_HeaderTokenHistoryCommon.end'),
      color: TEXT_COLOR_RED,
      value: '0400',
    },
  ];

  // useEffect(() => {
  //   setHeaderData(getHeaderInfoData?.secondaryTokenInfo);
  // }, [getHeaderInfoData]);
  return (
    <View>
      <View style={styles.positionSticky}>
        <HeaderSearch
          setParams={setParams}
          params={params}
          placeholder={t('components_Headers_HeaderTokenHistoryCommon.placeholder')}
        />
        <HeaderToken
          linkBtnBack="iconLeft.svg"
          textBtnBack={t('commonText.backArrow')}
          actionBtnBack={openModalBack}
          linkIcon={headerData?.productIconPath}
          textHeader="トークンコード"
          codeToken={headerData?.companyCd}
          nameToken={t('components_Headers_HeaderTokenHistoryCommon.nameToken')}
          scope1={
            <DropDownColor
              btnColor={'#525252'}
              textColor={optionSEC?.find((item) => item.value === headerData?.productStatusPrimary)?.color}
              text={optionSEC?.find((item) => item.value === headerData?.productStatusPrimary)?.option}
              hasText
              dataOption={optionSEC}
              changeColor="#525252"
              width={200}
            />
          }
        />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  positionSticky: {
    position: 'sticky',
    top: 0,
    backgroundColor: BG_COLOR,
    zIndex: 10,
  },
  navSticky: {
    position: 'sticky',
    top: 160,
    backgroundColor: BG_COLOR,
    zIndex: 1,
  },
});

export default withNamespaces()(HeaderTokenHistoryCommon);

import { StyleSheet } from 'react-native';
import {
  BG_COLOR,
  BG_HOVER,
  BG_TABLE_DEFAULT,
  COLOR_TEXT_DEFAULT,
  FONT_FAMILY_DEFAULT,
  FONT_SIZE_DEFAULT,
  FONT_SIZE_HEADER,
  FONT_WEIGHT_DEFAULT_HEADER,
  TEXT_COLOR_GREY,
  TEXT_COLOR_HEADER_TABLE,
} from '@assets/style/styleDefault';

const styles = StyleSheet.create({
  container: { flex: 1, backgroundColor: BG_COLOR, position: 'relative' },
  head: {
    display: 'flex',
    flexDirection: 'row',
    height: 42,
    marginTop: 30,
    marginBottom: 10,
    gap: 20,
  },
  wrapper: { flexDirection: 'row' },
  row: {
    height: 65,
    borderBottomWidth: 1,
    borderBottomColor: '#5C5C5C',
    paddingTop: 15,
    paddingBottom: 15,
  },
  wrapperBody: {
    backgroundColor: BG_TABLE_DEFAULT,
  },
  text: { color: COLOR_TEXT_DEFAULT, fontSize: FONT_SIZE_DEFAULT, fontFamily: FONT_FAMILY_DEFAULT },
  textHead: { color: TEXT_COLOR_GREY, textAlign: 'center', fontFamily: FONT_FAMILY_DEFAULT },
  wrapperHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingLeft: 20,
  },
  textHeader: {
    fontSize: FONT_SIZE_HEADER,
    color: '#9D9D9D',
    fontFamily: FONT_SIZE_DEFAULT,
    fontWeight: FONT_WEIGHT_DEFAULT_HEADER,
  },
  wrapperHeaderLeft: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '50%',
  },
  btnHeader: {
    height: 42,
    backgroundColor: BG_COLOR,
    paddingLeft: 15,
    paddingRight: 15,
  },
  iconTableHeadASC: {
    width: 12,
    height: 12,
    resizeMode: 'contain',
    marginLeft: 5,
    cursor: 'pointer',
  },
  iconTableHeadDESC: {
    width: 12,
    height: 12,
    resizeMode: 'contain',
    marginLeft: 5,
    cursor: 'pointer',
    transform: [{ rotate: '180deg' }],
  },
  circle: {
    width: 10,
    height: 10,
    marginRight: 5,
  },
  iconWarning: {
    width: 25,
    height: 24,
  },
  wrapperItemHead: {
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
  },
  wrapperItemEnd: {
    justifyContent: 'center',
    alignItems: 'flex-end',
  },
  wrapperItem: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  wrapperItemStart: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  wrapperStickyHeader: {
    position: 'sticky',
    top: 0,
    backgroundColor: BG_COLOR,
    zIndex: 10,
  },
  wrapperStickyTitle: {
    position: 'sticky',
    top: 79,
    backgroundColor: BG_COLOR,
    zIndex: 10,
  },
  wrapperStickyTableHead: {
    position: 'sticky',
    top: 120,
    backgroundColor: BG_COLOR,
    zIndex: 10,
  },
  buttonHovered: {
    backgroundColor: BG_HOVER,
  },
  wrapperItemWrap: {
    justifyContent: 'center',
  },
  wrapperStart: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  checkboxItem: {
    width: 25,
    height: 25,
  },
  marginRight: {
    marginRight: 30,
  },
  wrapperNotData: {
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: 300,
  },
  textNoData: {
    fontSize: FONT_SIZE_HEADER,
    fontFamily: FONT_FAMILY_DEFAULT,
    fontWeight: FONT_WEIGHT_DEFAULT_HEADER,
    color: TEXT_COLOR_HEADER_TABLE,
  },
  elementTable: {
    backgroundColor: 'transaction',
    width: 26,
    height: 26,
    zIndex: 1,
    position: 'relative',
  },
  elementCheckbox: {
    position: 'absolute',
    top: 0,
  },
  headerSearch: {
    marginRight: 0,
    marginTop: 10,
  },
});

export default styles;

import axios from 'axios';

import { API_PATH, HTTP_STATUS, STORAGEKEY } from '@helper/constant';
import { getCookie, refreshToken } from './TokenStorage';
import store from '@redux/store';
import { merchantUserInfo } from '@redux/slices/authSlice';

const PUBLIC_URL = [
  API_PATH.LOGIN,
  API_PATH.FORGOT_PASSWORD,
  API_PATH.FORGOT_EMAIL,
  API_PATH.RESET_PASSWORD,
  API_PATH.REFRESH_TOKEN,
  API_PATH.PASSCODE_AUTH,
  API_PATH.POST_CODE,
];
export const baseURL = process.env.API_URL_LEVICA?.endsWith('/')
  ? process.env.API_URL_LEVICA
  : process.env.API_URL_LEVICA + '/';

const instanceLevicaAdmin = axios.create({
  baseURL: baseURL,
});
const instanceWithoutAuthorization = axios.create({
  baseURL: baseURL,
});
const setHeader = async () => {
  const token = (await getCookie(STORAGEKEY.ACCESS_TOKEN)) ?? null;
  if (token) {
    instanceLevicaAdmin.defaults.headers.common['Authorization'] = `${token}`;
  }
};

export const get = async (path, params = {}) => {
  const { signal, ...restParams } = params;
  const config = signal ? { params: restParams, signal } : { params: restParams };
  try {
    if (PUBLIC_URL.includes(path)) {
      const response = await instanceWithoutAuthorization.get(path, config);
      return _responseHandler(response);
    } else {
      await setHeader();
      const response = await instanceLevicaAdmin.get(path, config);
      return _responseHandler(response);
    }
  } catch (error) {
    return _errorHandler(error);
  }
};

export const put = async (path, data = {}) => {
  try {
    await setHeader();
    const response = await instanceLevicaAdmin.put(path, data, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
    return _responseHandler(response);
  } catch (error) {
    return _errorHandler(error);
  }
};
export const putMulti = async (path, data = {}) => {
  try {
    await setHeader();
    const response = await instanceLevicaAdmin.put(path, data, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data',
      },
    });
    return _responseHandler(response);
  } catch (error) {
    return _errorHandler(error);
  }
};
export const post = async (path, data = {}) => {
  try {
    if (PUBLIC_URL.includes(path)) {
      const response = await instanceWithoutAuthorization.post(path, data, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      });
      return _responseHandler(response);
    } else {
      await setHeader();
      const response = await instanceLevicaAdmin.post(path, data, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      });
      return _responseHandler(response);
    }
  } catch (error) {
    return _errorHandler(error);
  }
};

export const postMulti = async (path, data = {}) => {
  try {
    await setHeader();
    const response = await instanceLevicaAdmin.post(path, data, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data',
      },
    });
    return _responseHandler(response);
  } catch (error) {
    return _errorHandler(error);
  }
};

export const del = async (path, data = {}) => {
  try {
    await setHeader();
    const response = await instanceLevicaAdmin.delete(path, data);
    return _responseHandler(response);
  } catch (error) {
    return _errorHandler(error);
  }
};

export const downloadCSV = async (path, csvName, params) => {
  const token = (await getCookie(STORAGEKEY.ACCESS_TOKEN)) ?? '';
  let anchor = document.createElement('a');
  document.body.appendChild(anchor);
  let url = `${baseURL}${path}?${new URLSearchParams(params).toString()}`;
  let headers = new Headers();
  headers.append('Authorization', token);
  try {
    fetch(url, { headers })
      .then((response) => response.blob())
      .then((fileType) => {
        let objectUrl = window.URL.createObjectURL(fileType);
        anchor.href = objectUrl;
        anchor.download = `${csvName}.csv`;
        anchor.click();
        window.URL.revokeObjectURL(objectUrl);
      });
  } catch (error) {
    return _errorHandler(error);
  }
};

const _responseHandler = (response, url) => {
  return response.data;
};
const _errorHandler = async (error) => {
  if (error?.response && error?.response?.status === HTTP_STATUS.UNAUTHORIZED) {
    refreshToken();
  } else if (error?.response?.data?.code === HTTP_STATUS.ROLE_UNAUTHORIZED) {
    store.dispatch(merchantUserInfo());
  }

  throw error;
};

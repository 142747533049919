import { API_PATH } from '@helper/constant';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { get } from '@utils/RequestLevica';

const initialState = {
};

export const setting = createAsyncThunk('getCurrentLanguage', async () => {
  const data = await get(API_PATH.SYSTEM_SETTING);
  return data;
});

const settingSlice = createSlice({
  name: 'setting',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(setting.fulfilled, (state, action) => {
      return (state = { ...state, setting: action.payload });
    });
  },
});

export default settingSlice.reducer;

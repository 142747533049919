import React from 'react';
import { StyleSheet, View, Image } from 'react-native';
import { Link, useHistory } from 'react-router-dom';
import { JText as Text } from '../common/JText';
import {
  BG_COLOR,
  COLOR_TEXT_DEFAULT,
  FONT_SIZE_HEADER,
  FONT_SIZE_DEFAULT,
  TEXT_COLOR_GREEN,
} from '@assets/style/styleDefault';

const HeaderBack = ({
  btnBack,
  textBack,
  iconHeader,
  textHeader,
  avatar,
  linkBack = '/',
  hasSubTitle,
  subIconHeader,
  subTextHeader,
  widthIconBack = '',
  heightIconBack = '',
  widthIconHeader = '',
  heightIconHeader = '',
  historyBack = false,
}) => {
  const history = useHistory();
  return (
    <View style={styles.headerBack}>
      <View style={styles.headerLeft}>
        {historyBack ? (
          <View
            onClick={() => history.goBack()}
            style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', cursor: 'pointer' }}
          >
            <Image
              style={[
                widthIconBack && heightIconBack ? { width: widthIconBack, height: heightIconBack } : styles.buttonBack,
              ]}
              source={require(`../../assets/icons/${btnBack}`)}
            />
            <Text style={styles.textBack}>{textBack}</Text>
          </View>
        ) : (
          <Link style={{ textDecoration: 'none', display: 'flex', alignItems: 'center' }} to={linkBack}>
            <Image
              style={[
                widthIconBack && heightIconBack ? { width: widthIconBack, height: heightIconBack } : styles.buttonBack,
              ]}
              source={require(`../../assets/icons/${btnBack}`)}
            />
            <Text style={styles.textBack}>{textBack}</Text>
          </Link>
        )}
        <View style={[styles.wrapperItem, { flexShrink: 1 }]}>
          {iconHeader && (
            <Image
              style={[
                widthIconHeader && heightIconHeader
                  ? { width: widthIconHeader, height: heightIconHeader }
                  : styles.iconHeader,
              ]}
              source={require(`../../assets/icons/${iconHeader}`)}
            />
          )}
          <Text style={styles.textHeader}>{textHeader}</Text>
        </View>
        {hasSubTitle && (
          <View style={styles.wrapperItem}>
            {subIconHeader && (
              <Image style={styles.iconHeader} source={require(`../../assets/icons/${subIconHeader}`)} />
            )}
            <Text style={styles.textHeader}>{subTextHeader}</Text>
          </View>
        )}
      </View>
      <View>{avatar}</View>
    </View>
  );
};

export default HeaderBack;

const styles = StyleSheet.create({
  headerBack: {
    display: 'flex',
    height: 80,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: BG_COLOR,
  },
  headerLeft: {
    flexDirection: 'row',
    alignItems: 'center',
    flexShrink: 1,
  },
  wrapperItem: {
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
  },
  buttonBack: {
    height: 15,
    width: 15,
  },
  textBack: {
    fontSize: FONT_SIZE_DEFAULT,
    marginLeft: 5,
    marginRight: '2rem',
    color: TEXT_COLOR_GREEN,
    fontWeight: 'bold',
  },
  iconHeader: {
    height: 25,
    width: 25,
    resizeMode: 'contain',
  },
  textHeader: {
    color: COLOR_TEXT_DEFAULT,
    fontSize: FONT_SIZE_HEADER,
    marginLeft: 10,
    fontWeight: 600,
  },
});

import { API_PATH } from '@helper/constant';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { get } from '@utils/RequestLevica';

export const merchantUserInfo = createAsyncThunk('getMerchantUserInfo', async () => {
  const data = await get(API_PATH.ADMIN_PROFILE);
  return data;
});

const initialState = { isLoaded: false, isUpdateRoutesByUserInfo: false, errorMessage: '' };
const userSlice = createSlice({
  name: 'merchantUserInfo',
  initialState,
  reducers: {
    clearUser: () => initialState,
    setRouteByUserInfo: (state, action) => {
      state.isUpdateRoutesByUserInfo = action.payload;
    },
    setIsLoaded: (state, action) => {
      state.isLoaded = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(merchantUserInfo.pending, (state) => {
      return (state = { ...state, isLoaded: true });
    });
    builder.addCase(merchantUserInfo.fulfilled, (state, action) => {
      return (state = { ...state, ...action.payload, isLoaded: false });
    });
    builder.addCase(merchantUserInfo.rejected, (state, action) => {
      return (state = { ...state, isLoaded: false, errorMessage: action.payload });
    });
  },
});

export const { setRouteByUserInfo, setIsLoaded, clearUser } = userSlice.actions;
export default userSlice.reducer;

import React, { useState, useEffect } from 'react';
import { View, ScrollView } from 'react-native';
import { useForm } from 'react-hook-form';
import { Col } from 'react-native-easy-grid';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { LinearGradient } from 'expo-linear-gradient';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { withNamespaces } from 'react-i18next';

import HeaderTokenTwoOption from '@components/Headers/HeaderTokenTwoOption';
import styles from './style';
import JInput from '@components/common/JInput';
import ModalAlert from '@components/Modals/ModalAlert';
import ModalBack from '@components/Modals/ModalBack';
import JButton from '@components/common/JButton';
import { JText as Text } from '@components/common/JText';
import { useMutation, useQuery } from '@tanstack/react-query';
import { get, post } from '@utils/RequestLevica';
import { API_PATH } from '@helper/constant';
import SMSNotificationsTest from './SMSNotificationsTest';

const TokenDetailSMS = ({ t }) => {
  const history = useHistory();
  const [isModal, setIsModal] = useState(false);
  const [isModalSave, setIsModalSave] = useState(false);
  const [isModalBack, setIsModalBack] = useState(false);
  const [isSMSNotificationsTest, setSMSNotificationsTest] = useState(false);

  const location = useLocation();
  const { smsId } = useParams();
  const isUpdate = location.pathname.includes('update');

  const schema = Yup.object().shape({
    smsTemplateName: Yup.string()
      .trim()
      .required(t('errorMessages.fieldRequired', { fieldName: t('pages_FileManager_SMSNotifications.smsName') }))
      .max(128, t('errorMessages.TXT_MAX_128')),
    // senderNumber: Yup.string().required(t('errorMessages.TXT_REQUIRED')),
    smsContentTemplate: Yup.string()
      .trim()
      .required(t('errorMessages.fieldRequired', { fieldName: t('pages_FileManager_SMSNotifications.message') }))
      .max(1000, t('errorMessages.TXT_MAX_1000')),
  });

  const {
    control,
    handleSubmit,
    reset,
    watch,
    formState: { isDirty, isValid },
  } = useForm({
    defaultValues: {
      type: 5,
      smsTemplateName: '',
      smsContentTemplate: '',
      languageCd: 1,
      isSelected: true,
    },
    resolver: yupResolver(schema),
    mode: 'onChange',
  });
  const getSmsList = async ({ signal }) => {
    const smsList = await get(`${API_PATH.TEMPLATES_SMS}?type=${5}`, { signal });
    return smsList?.smsTemplateDataList;
  };

  const { remove } = useQuery(['getSmsList'], getSmsList, {
    enabled: isUpdate,
    onSuccess: (smsList) => {
      const smsSelect = smsList.filter((item) => item.isSelected)?.[0];
      reset(smsSelect);
    },
    refetchOnWindowFocus: false,
  });

  const openModalBack = () => {
    if (isDirty || isValid) {
      setIsModalBack(!isModalBack);
    } else {
      history.goBack();
    }
  };

  const handleClick = () => {
    setSMSNotificationsTest(true);
  };

  const { mutate: createTemplateSms } = useMutation({
    mutationFn: async (templateSms) => await post(API_PATH.TEMPLATES_SMS, templateSms),
    retry: 3,
  });

  const onSubmit = (data) => {
    const newData = { ...data, type: 5, isSelected: true };
    createTemplateSms(newData, {
      onSuccess: () => {
        setIsModalSave(true);
        setIsModalBack(false);
        if (!isUpdate) {
          reset();
        }
      },
      onError: (error) => console.log(error),
    });
  };
  const onSubmit2 = (data) => {
    const newData = { ...data, type: 5, isSelected: true };
    createTemplateSms(newData, {
      onSuccess: () => {
        setIsModal(true);
        setIsModalBack(false);
        if (!isUpdate) {
          reset();
        }
      },
      onError: (error) => console.log(error),
    });
  };

  useEffect(() => {
    if (smsId) {
      reset(location.state);
    }
  }, []);
  useEffect(() => remove, [remove]);
  return (
    <>
      {isSMSNotificationsTest ? (
        <SMSNotificationsTest smsContent={watch()} setSMSNotificationsTest={setSMSNotificationsTest} />
      ) : (
        <View style={styles.header}>
          <View style={[styles.positionSticky]}>
            <HeaderTokenTwoOption
              handleModalBack={setIsModalBack}
              isDirty={isDirty && isValid}
              linkBtnBack="iconX.svg"
              actionBtnBack={openModalBack}
              textBtnBack={t('commonText.cancelButton')}
              linkIcon="text-white.svg"
              textHeader={t('pages_FileManager_SMSNotifications.textHeader')}
              widthIconTitle={36}
              heightIconTitle={36}
              scope1={
                smsId ? null : (
                  <LinearGradient
                    colors={['#32A5F7', '#2D81F3']}
                    style={{ borderRadius: 5, width: 170, marginRight: 15 }}
                  >
                    <JButton
                      text={t('pages_FileManager_SMSNotifications.buttonTestSMS')}
                      width={170}
                      textColor="#ffffff"
                      onPressAction={handleClick}
                      disabled={!isValid || !isDirty}
                    />
                  </LinearGradient>
                )
              }
              scope2={
                smsId ? null : (
                  <LinearGradient colors={['#54DBB2', '#04C48B']} style={{ borderRadius: 5, width: 170 }}>
                    <JButton
                      text={t('commonText.buttonSave')}
                      width={170}
                      textColor="#f2f2f2"
                      hasIcon
                      linkIcon="iconDownload.svg"
                      linkIconDisabled="iconDownloadGrey.svg"
                      widthIcon={18}
                      heightIcon={18}
                      onPressAction={() => handleSubmit(onSubmit)()}
                      disabled={!isDirty}
                    />
                  </LinearGradient>
                )
              }
            />
          </View>
          <ScrollView showsVerticalScrollIndicator={false}>
            <View style={[styles.borderForm]}>
              <View style={styles.borderBottomWhite}>
                <Col size={3} style={styles.flexDirection}>
                  <Text style={styles.textTitle}>{t('pages_FileManager_SMSNotifications.smsName')}</Text>
                  <Text style={styles.redColor}>✴︎</Text>
                </Col>
                <Col size={9}>
                  <View>
                    <JInput
                      placeholder={t('pages_FileManager_SMSNotifications.smsNamePlaceholder')}
                      width="100%"
                      prefix="file1.svg"
                      widthPrefix={20}
                      heightPrefix={20}
                      subfix="iconEdit.svg"
                      widthSubfix={15}
                      heightSubfix={15}
                      control={control}
                      name="smsTemplateName"
                      disable={!smsId}
                    />
                  </View>
                </Col>
              </View>
              <View style={styles.borderBottomWhite}>
                <Col size={3} style={styles.flexDirection}>
                  <Text style={styles.textTitle}>{t('pages_FileManager_SMSNotifications.smsCallerID')} </Text>
                  <Text style={styles.redColor}>✴︎</Text>
                </Col>
                <Col size={9}>
                  <View>
                    <JInput
                      placeholder={t('pages_FileManager_SMSNotifications.smsCallerIDPlaceholder')}
                      width="100%"
                      subfix="iconEdit.svg"
                      widthSubfix={18}
                      heightSubfix={18}
                      control={control}
                      name="senderNumber2"
                      disable={false}
                    />
                  </View>
                </Col>
              </View>
              <View style={styles.textArea}>
                <Col size={3} style={[styles.flexDirection, styles.titleTextArea]}>
                  <Text style={styles.textTitle}>{t('pages_FileManager_SMSNotifications.message')} </Text>
                  <Text style={styles.redColor}>✴︎</Text>
                </Col>
                <Col size={9}>
                  <View>
                    <JInput
                      placeholder={t('pages_FileManager_SMSNotifications.messagePlaceholder')}
                      width="100%"
                      subfix="iconEdit.svg"
                      widthSubfix={18}
                      heightSubfix={18}
                      control={control}
                      name="smsContentTemplate"
                      multiline={true}
                      heightMulti={400}
                      disable={!smsId}
                    />
                  </View>
                </Col>
              </View>
            </View>
          </ScrollView>

          <ModalAlert
            isModalVisible={isModalSave}
            setModalVisible={setIsModalSave}
            modalText={t('pages_FileManager_SMSNotifications.modalAlertText')}
            iconWidth={16}
            iconHeight={12.5}
            icon="successGreen.svg"
            linkPage="/file-manager/other-notifications"
          />
          <ModalAlert
            isModalVisible={isModal}
            setModalVisible={setIsModal}
            modalText={t('pages_FileManager_SMSNotifications.modalAlertText')}
            iconWidth={16}
            iconHeight={12.5}
            icon="successGreen.svg"
            linkPage="/file-manager/other-notifications"
          />
          <ModalBack
            linkPage="/file-manager/other-notifications"
            isModal={isModalBack}
            setIsModal={setIsModalBack}
            otherModal={() => handleSubmit(onSubmit2)()}
          />
        </View>
      )}
    </>
  );
};
export default withNamespaces()(TokenDetailSMS);

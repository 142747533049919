import React, { useState } from 'react';
import { StyleSheet, View, Image, TouchableOpacity } from 'react-native';
import { useSelector } from 'react-redux';

import ModalAvatar from '../Modals/ModalAvatar';
import { COLOR_TEXT_DEFAULT } from '@assets/style/styleDefault';
import ModalDuringToken from '../../pages/Token/Modals/ModalDuringToken';
import { JText as Text } from '../common/JText';
import { statusColor } from '@helper/formatTypes';

const HeaderAvatar = ({ status, nameUser, imgAvatar }) => {
  const [isModalVisible, setModalVisible] = useState(false);
  const userInfo = useSelector((state) => state.merchantUserInfo);

  const toggleModal = () => {
    setModalVisible(true);
  };

  return (
    <View>
      <TouchableOpacity onPress={() => toggleModal()}>
        {userInfo ? (
          <View style={styles.avatar}>
            {statusColor(userInfo?.masterType) ? (
              <View style={[styles.status, { backgroundColor: statusColor(userInfo?.masterType) }]} />
            ) : null}
            <Text style={styles.textAvatar}>{userInfo?.name}</Text>
            {userInfo?.imageUrl ? (
              <Image style={styles.imgAvatar} source={{ uri: userInfo?.imageUrl }} />
            ) : (
              <Image style={styles.imgAvatar} source={require(`@assets/image/userDefaultAvatar.jpg`)} />
            )}
          </View>
        ) : null}

        <ModalAvatar
          isModalVisible={isModalVisible}
          nameUser={nameUser}
          status={status}
          imgAvatar={imgAvatar}
          setModalVisible={setModalVisible}
        />
        <ModalDuringToken />
      </TouchableOpacity>
    </View>
  );
};

export default HeaderAvatar;

const styles = StyleSheet.create({
  avatar: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'initial',
  },
  textAvatar: {
    fontSize: '14px',
    color: `${COLOR_TEXT_DEFAULT}`,
    marginLeft: 10,
    marginRight: 10,
    fontWeight: 'bold',
  },
  imgAvatar: {
    height: 45,
    width: 45,
    marginRight: 20,
    marginLeft: 5,
    borderRadius: 100,
  },
  status: {
    width: 12,
    height: 12,
    resizeMode: 'contain',
    paddingRight: 10,
    borderRadius: 100,
  },
});

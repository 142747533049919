import React from 'react';
import { StyleSheet, TouchableWithoutFeedback, Image, View } from 'react-native';
import { BTN_COLOR_GREY, FONT_SIZE_DEFAULT, TEXT_COLOR_GREY } from '@assets/style/styleDefault';
import { JText as Text } from './JText';
import LoadingButton from './LoadingButton';

const JButton = ({
  text,
  width = '100%',
  height = 42,
  textColor,
  btnColor,
  linkIcon,
  heightIcon,
  widthIcon,
  marginBottomIcon = 0,
  iconSubfix,
  heightSubfix,
  widthSubfix,
  onPressAction,
  disabled,
  btnDisabledColor = BTN_COLOR_GREY,
  linkIconDisabled,
  linkSubfixDisabled,
  flexStart,
  justifyContent = 'flex-start',
  style,
  borderError,
  borderBtn = '1px solid transparent',
  isProcessing,
}) => {
  return (
    <TouchableWithoutFeedback disabled={disabled} onPress={onPressAction ? onPressAction : () => {}}>
      <View
        style={[
          styles.button,
          { width: width, height: height },
          borderError ? { border: '1px solid red' } : { border: borderBtn },
          flexStart && { justifyContent: justifyContent, paddingLeft: 4 },
          disabled ? { backgroundColor: btnDisabledColor } : { backgroundColor: `${btnColor}` },
          style,
        ]}
      >
        {isProcessing !== undefined && isProcessing ? (
          <LoadingButton />
        ) : (
          <>
            {linkIcon && (
              <Image
                style={[styles.icon, { width: widthIcon, height: heightIcon, marginBottom: marginBottomIcon }]}
                source={
                  disabled
                    ? require(`../../assets/icons/${linkIconDisabled}`)
                    : require(`../../assets/icons/${linkIcon}`)
                }
              />
            )}
            <Text
              numberOfLines={2}
              style={[styles.text, disabled ? { color: TEXT_COLOR_GREY } : { color: `${textColor}` }]}
            >
              {text}
            </Text>
            {iconSubfix && (
              <Image
                style={[styles.icon, { width: widthSubfix, height: heightSubfix, marginRight: 20 }]}
                source={
                  disabled
                    ? require(`../../assets/icons/${linkSubfixDisabled}`)
                    : require(`../../assets/icons/${iconSubfix}`)
                }
              />
            )}
          </>
        )}
      </View>
    </TouchableWithoutFeedback>
  );
};

export default JButton;

const styles = StyleSheet.create({
  button: {
    borderRadius: 4,
    height: 42,
    fontWeight: 400,
    lineHeight: 1.5,
    cursor: 'pointer',
    fontSize: 14,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
  },
  text: {
    color: TEXT_COLOR_GREY,
    paddingHorizontal: 10,
    fontSize: FONT_SIZE_DEFAULT,
    textAlign: 'center',
  },
  icon: {
    resizeMode: 'contain',
    // marginRight: 20,
    marginLeft: 5,
  },
});

import React, { useRef, useState, useMemo } from 'react';
import { View, Image, ScrollView, Switch } from 'react-native';
import { withNamespaces } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { LinearGradient } from 'expo-linear-gradient';
import { Col } from 'react-native-easy-grid';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isValidPhoneNumber, parsePhoneNumber } from 'react-phone-number-input';

import JButton from '@components/common/JButton';
import JInput from '@components/common/JInput';
import ModalChangeAvatar from './modal/ModalChangeAvatar';
import ModalTwoActorAuthentication from './modal/ModalTwoActorAuthentication';
import ModalAlert from '@components/Modals/ModalAlert';
import styles from './style';
import { JText as Text } from '@components/common/JText';
import { API_PATH, ROLE_USER } from '@helper/constant';
import AdminList from './partials/AdminList';
import { putMulti } from '@utils/RequestLevica';
import { BG_ACTIVE_SIDEBAR, BTN_COLOR_GREY } from '@assets/style/styleDefault';
import AdminType from './partials/AdminType';
import { merchantUserInfo, setIsLoaded } from '@redux/slices/authSlice';
import PhoneInput from '@components/common/PhoneInput';
import scroll2View from '@helper/scroll2View';
import Loading from '@components/common/Loading';
import { checkPermission } from '@helper/commonFunction';

const dataAdminType = ['MASTER', 'EXECUTIVE', 'ASSOCIATE'];
const defaultValues = {
  id: '',
  avatar: '',
  name: '',
  masterType: '',
  levicaId: '',
  walletAddress: '',
  email: '',
  phone: '',
  authCode: '',
  twoFactorAuthentication: '',
  status: '',
  icon: '',
};

const Setting = ({ t }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [uploadAvatar, setUploadAvatar] = useState(null);
  const [isSavingProfile, setIsSavingProfile] = useState(null);
  const [hover, setHover] = useState(false);
  const [isModalChangeAvatar, setModalChangeAvatar] = useState(false);
  const [isModalTwoActorAuthentication, setModalTwoActorAuthentication] = useState(false);
  const [isTwoActorAuthentication, setIsTwoActorAuthentication] = useState(false);
  const [modalAlert, setModalAlert] = useState(false);
  const [recallAdminList, setRecallAdminList] = useState(false);
  const userInfo = useSelector((state) => state.merchantUserInfo);
  const scrollViewRef = useRef();
  const validationSchema = Yup.object().shape({
    avatar: Yup.string(),
    name: Yup.string()
      .max(32, t('errorMessages.TXT_MAX_32'))
      .required(t('errorMessages.fieldRequired', { fieldName: t('commonText.name') })),
    masterType: Yup.string(),
    email: Yup.string()
      .email(t('errorMessages.TXT_EMAIL_REQUIRED'))
      .max(32, t('errorMessages.TXT_MAX_32'))
      .required(t('errorMessages.fieldRequired', { fieldName: t('pages_Setting.email') })),
    phone: Yup.string()
      .trim()
      .nullable()
      .test('compare ', t('errorMessages.TXT_JP_PHONE'), (phone) => {
        if (phone && parsePhoneNumber(phone)?.nationalNumber) {
          return isValidPhoneNumber(phone);
        } else {
          return true;
        }
      })
      .required(
        t('errorMessages.fieldRequired', { fieldName: t('pages_AccountManagement_CreateAccount.mobileNumber') })
      ),
    twoFactorAuthentication: Yup.string().required(
      t('errorMessages.fieldRequired', { fieldName: t('pages_Setting.twoFactorAuthentication') })
    ),
  });
  const {
    control,
    handleSubmit,
    setValue,
    reset,
    clearErrors,
    setError,
    trigger,
    formState: { errors, isDirty, isValid },
  } = useForm({
    defaultValues,
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
  });

  const onSubmit = async (userInfoData) => {
    let newData = { ...userInfoData };

    if (!userInfoData?.icon?.type) {
      delete newData.icon;
    }

    if (userInfoData.phone?.length < 6) {
      setError('phone', {
        type: 'custom',
        message: t('errorMessages.TXT_JP_PHONE'),
      });
      scroll2View('phone', scrollViewRef.current);
      return;
    }

    try {
      setIsSavingProfile(true);
      await putMulti(`${API_PATH.ADMIN_INFO}/updateProfile`, newData);
      setRecallAdminList(true);
      setModalAlert(true);
      dispatch(setIsLoaded(false));
      dispatch(merchantUserInfo());
      setIsSavingProfile(false);
    } catch (error) {
      setIsSavingProfile(false);
      if (error?.response?.data?.code == '3014') {
        setError('email', { type: 'custom', message: t('errorMessages.TXT_REGISTERED_EMAIL') });
        scroll2View('email', scrollViewRef.current);
      } else if (error?.response?.data?.code == '3015') {
        setError('phone', { type: 'custom', message: t('errorMessages.TXT_REGISTERED_PHONE') });
        scroll2View('phone', scrollViewRef.current);
      } else if (error?.response?.data?.code == '4007') {
        setError('masterType', { type: 'custom', message: t('errorMessages.TXT_MASTER_TYPE_CANNOT_CHANGE') });
        scroll2View('masterType', scrollViewRef.current);
      } else if (error?.response?.data?.code == '5017') {
        setError('masterType', { type: 'custom', message: t('errorMessages.TXT_ADMIN_TYPE_CANNOT_CHANGE') });
        scroll2View('masterType', scrollViewRef.current);
      }
    }
  };

  const onHover = (e) => {
    e.preventDefault();
    setHover(true);
  };

  const onHoverOver = (e) => {
    e.preventDefault();
    setHover(false);
  };

  useEffect(() => {
    if (!!userInfo) {
      if (errors.masterType) return;
      reset({
        ...userInfo,
      });
      setUploadAvatar(userInfo?.imageUrl);
      setIsTwoActorAuthentication(userInfo?.twoFactorAuthentication);
    }
  }, [userInfo]);

  const goDashboard = () => {
    history.push('/');
  };

  useEffect(() => {
    scroll2View(Object.keys(errors)[0], scrollViewRef.current);
  }, [errors]);

  return (
    <View style={styles.container}>
      <View style={styles.header}>
        <View
          style={styles.saveSetting}
          onMouseEnter={(e) => onHover(e)}
          onMouseLeave={(e) => onHoverOver(e)}
          onClick={() => goDashboard()}
        >
          <Text style={[styles.saveSettingX, { display: hover ? 'block' : 'none' }]}> x </Text>
          <Text style={[styles.saveSettingContent, { display: hover ? 'inline' : 'none' }]}>
            {t('commonText.close')}
          </Text>
        </View>
        <Text style={styles.headerContent}>Admin Account </Text>
      </View>

      <View style={styles.body}>
        <Col size={7} style={styles.left}>
          {/* {isShowAdminForm ? ( */}
          <ScrollView showsVerticalScrollIndicator={false} ref={scrollViewRef}>
            <View style={[styles.dFlex, { marginBottom: 20 }]}>
              <Col size={3}>
                <View style={styles.mainAvatar}>
                  {!!uploadAvatar ? (
                    <Image source={{ uri: uploadAvatar }} style={styles.mainAvatarImage} alt="userAvatar" />
                  ) : (
                    <Image style={styles.mainAvatarImage} source={require(`@assets/image/userDefaultAvatar.jpg`)} />
                  )}

                  <Image
                    onClick={() => setModalChangeAvatar(true)}
                    source={require('@assets/icons/iconCamera.svg')}
                    style={styles.iconCamera}
                    alt=""
                  />
                </View>
                <Text style={styles.error}>{errors?.icon?.message}</Text>
              </Col>
              <Col size={7} style={styles.mainAvatarRight}>
                <LinearGradient colors={['#54DBB2', '#54DBB2']} style={{ borderRadius: 5 }}>
                  <JButton
                    text={t('commonText.buttonSave')}
                    width={170}
                    textColor="#fff"
                    hasIcon={true}
                    linkIconDisabled="iconDownloadGray.svg"
                    widthIcon={21}
                    heightIcon={20}
                    linkIcon="iconDownload.svg"
                    isProcessing={isSavingProfile}
                    disabled={isSavingProfile || !isDirty}
                    onPressAction={() => handleSubmit(onSubmit)()}
                  />
                </LinearGradient>

                <View style={{ paddingTop: 30, width: '100%' }} nativeID="name">
                  <JInput
                    width="100%"
                    paddingLeft={20}
                    borderRadius={20}
                    subfix="iconEdit.svg"
                    widthSubfix={15}
                    heightSubfix={15}
                    control={control}
                    name="name"
                  />
                </View>
              </Col>
            </View>
            <View style={[styles.dFlex, styles.warperItem, { zIndex: 2 }]}>
              <Col size={3} nativeID="masterType">
                <Text>Admin type</Text>
              </Col>
              <Col size={7}>
                <AdminType
                  dataAdminType={dataAdminType}
                  setValue={setValue}
                  error={errors?.masterType}
                  userInfo={userInfo}
                  trigger={trigger}
                  checkRole={checkPermission(ROLE_USER.SETTING_ROLE, userInfo)}
                />
              </Col>
            </View>

            <View style={[styles.dFlex, styles.warperItem]}>
              <Col size={3} style={styles.warrperTextLeft}>
                <Text>LEVICA ID</Text>
              </Col>
              <Col size={7}>
                <JInput
                  width="100%"
                  paddingLeft={20}
                  control={control}
                  backgroundColor="transparent"
                  name="levicaId"
                  disable={false}
                  outlineShow={false}
                />
              </Col>
            </View>
            <View style={[styles.dFlex, styles.warperItem]}>
              <Col size={3} style={styles.warrperTextLeft}>
                <Text>{t('pages_Setting.walletAddress')}</Text>
              </Col>
              <Col size={7}>
                <JInput
                  placeholder={t('pages_Setting_AdminRegister.walletAddressPlaceholder')}
                  multiline
                  heightMulti={55}
                  width="100%"
                  paddingLeft={20}
                  control={control}
                  backgroundColor="transparent"
                  name="walletAddress"
                  disable={false}
                  outlineShow={false}
                />
              </Col>
            </View>
            <View style={[styles.dFlex, styles.warperItem]}>
              <Col size={3} nativeID="email">
                <Text>{t('pages_Setting.email')}</Text>
              </Col>
              <Col size={7}>
                <JInput
                  width="100%"
                  paddingLeft={20}
                  borderRadius={20}
                  subfix="iconEdit.svg"
                  widthSubfix={15}
                  heightSubfix={15}
                  control={control}
                  name="email"
                />
              </Col>
            </View>
            <View style={[styles.dFlex, styles.warperItem, { borderBottomWidth: 0, marginBottom: 40 }]}>
              <Col size={3} nativeID="phone">
                <Text>{t('pages_Setting.phone')}</Text>
              </Col>
              <Col size={7}>
                <PhoneInput name="phone" control={control} />
              </Col>
            </View>
            <View style={[styles.dFlex, styles.warperItem]}>
              <Col size={3}>
                <Text>{t('pages_Setting.securityCode')}</Text>
              </Col>
              <Col size={7}>
                <JInput
                  width="100%"
                  paddingLeft={20}
                  control={control}
                  backgroundColor="transparent"
                  name="authCode"
                  disable={false}
                  outlineShow={false}
                />
              </Col>
            </View>
            <View style={[styles.dFlex, styles.warperItem]}>
              <Col size={3} nativeID="twoFactorAuthentication">
                <Text>{t('pages_Setting.twoFactorAuthentication')}</Text>
              </Col>
              <Col size={7} style={styles.dFlex}>
                <Text style={[styles.text, { paddingRight: 30, paddingLeft: 20 }]}>
                  {isTwoActorAuthentication ? 'ON' : 'OFF'}
                </Text>

                <Switch
                  style={{ height: 20, color: 'white' }}
                  trackColor={{ false: BTN_COLOR_GREY, true: BG_ACTIVE_SIDEBAR }}
                  thumbColor="#FFF"
                  onClick={() => setModalTwoActorAuthentication(true)}
                  value={isTwoActorAuthentication ?? false}
                />
              </Col>
            </View>
            <View style={[styles.dFlex, styles.warperItem, { borderBottomWidth: 0 }]}>
              <Col size={3}>
                <Text>{t('pages_Setting.password')}</Text>
              </Col>
              <Col size={7}>
                <Link style={{ textDecoration: 'none' }} to={`/admin/change-password`}>
                  <View style={[styles.dFlex, { justifyContent: 'space-between' }]}>
                    <Text style={{ paddingLeft: 20 }}> {t('pages_Setting.changePassword')}</Text>
                    <Image
                      source={require('@assets/icons/arrowRight.svg')}
                      style={styles.statusIcon}
                      alt="statusIcon"
                    />
                  </View>
                </Link>
              </Col>
            </View>
          </ScrollView>
          {/* ) : (
            <Loading style={{ height: 'calc(100vh - 87px)' }} />
          )} */}
        </Col>
        <Col size={3} style={styles.right}>
          <AdminList recall={recallAdminList} setRecall={setRecallAdminList} />
        </Col>
      </View>

      <ModalChangeAvatar
        avatar={uploadAvatar}
        setUploadAvatar={setUploadAvatar}
        isModalVisible={isModalChangeAvatar}
        setModalVisible={setModalChangeAvatar}
        setValue={setValue}
        setError={setError}
        clearErrors={clearErrors}
        userInfo={userInfo}
        trigger={trigger}
      />

      <ModalTwoActorAuthentication
        setIsTwoActorAuthentication={setIsTwoActorAuthentication}
        isModalVisible={isModalTwoActorAuthentication}
        setModalVisible={setModalTwoActorAuthentication}
        setValue={setValue}
      />
      {/* {modalSave && (
        <ModalSave
          setModalAlert={setModalAlert}
          isModalVisible={modalSave}
          setModalVisible={setModalSave}
          resetFrom={reset}
          onSubmit={onSubmit}
          defaultValues={defaultValues}
        />
      )} */}

      <ModalAlert
        isModalVisible={modalAlert}
        setModalVisible={setModalAlert}
        icon="checkGreen.svg"
        iconWidth={20}
        iconHeight={16}
        modalText={t('pages_Setting.saveAdminInfoSuccess')}
      />
    </View>
  );
};

export default withNamespaces()(Setting);

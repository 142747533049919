import { StyleSheet } from 'react-native';
import {
  BG_COLOR,
  FONT_SIZE_HEADER,
  BORDER_TABLE_GREY,
  BG_HOVER,
  TEXT_COLOR_GREEN,
  BG_SIDEBAR,
  LINE_COLOR,
} from '@assets/style/styleDefault';

const styles = StyleSheet.create({
  textHeader: {
    color: TEXT_COLOR_GREEN,
    fontSize: FONT_SIZE_HEADER,
  },
  container: { backgroundColor: BG_COLOR, height: '100%' },
  //icon
  statusIcon: {
    width: 12,
    height: 12,
    marginBottom: 2,
    marginRight: 5,
    borderRadius: 100,
  },
  iconX: {
    width: 18,
    height: 18,
    marginRight: 10,
  },
  //header
  dFlex: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginVertical: 20,
    paddingLeft: 50,
  },

  //body
  body: {
    display: 'flex',
    flexDirection: 'row',
    padding: 20,
    paddingBottom: 0,
    flex: 1,
  },

  left: {
    paddingHorizontal: 20,
    marginRight: 20,
  },

  mainAvatar: {
    width: 'fit-content',
    marginRight: 10,
    position: 'relative',
  },
  mainAvatarImage: {
    maxHeight: 160,
    maxWidth: 160,
    minHeight: 140,
    minWidth: 140,
    width: '10vw',
    height: '10vw',
    borderRadius: 100,
  },

  //item
  warperItem: {
    borderBottomColor: BORDER_TABLE_GREY,
    borderBottomWidth: 1,
    paddingVertical: 15,
    paddingHorizontal: 10,
  },
  //right
  right: {
    padding: 10,
    borderRadius: 4,
    backgroundColor: BG_SIDEBAR,
    height: '100%',
  },
  warperItemRight: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingVertical: 15,
    paddingHorizontal: 10,
  },
  itemRightHeader: {
    borderBottomColor: LINE_COLOR,
    borderBottomWidth: 1,
    marginBottom: 10,
  },
  avatarItemRight: {
    width: 40,
    height: 40,
    marginRight: 5,
  },
  itemRightHovered: {
    backgroundColor: BG_HOVER,
    borderRadius: 4,
  },
  information: {
    paddingLeft: 10,
    paddingVertical: 50,
  },
});

export default styles;

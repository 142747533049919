import { StyleSheet } from 'react-native';
import { BTN_COLOR_GREY, FONT_SIZE_HEADER, TEXT_COLOR_GREY, TEXT_COLOR_RED } from '@assets/style/styleDefault';

const styles = StyleSheet.create({
  marginBottom20: {
    // border: '1px solid red',
    marginBottom: 30,
  },
  flex: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  flexJustCenter: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  flexCenter: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  flexBeetween: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },

  colorGray: {
    color: TEXT_COLOR_GREY,
  },
  iconRequired: {
    color: TEXT_COLOR_RED,
    marginLeft: 6,
    fontSize: FONT_SIZE_HEADER,
  },
  marginLeft8: {
    marginLeft: 8,
  },
  marginLeft12: {
    marginLeft: 12,
  },

  marginTop30: {
    paddingTop: 34,
  },
  paddingLeft20: {
    paddingLeft: 20,
  },
  paddingRight10: {
    paddingRight: 10,
  },
  paddingHorizontal10: {
    paddingHorizontal: 10,
  },
  startIcon: {
    position: 'absolute',
    top: '25%',
    left: -20,
  },
  title: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 20,
    gap: 5,
  },
  textRed: {
    color: TEXT_COLOR_RED,
  },
});

export default styles;

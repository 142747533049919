import { StyleSheet } from 'react-native';
import {
  BG_COLOR,
  BG_TABLE_DEFAULT,
  BTN_COLOR_GREEN,
  COLOR_TEXT_DEFAULT,
  FONT_FAMILY_HEADER,
  TEXT_COLOR_GREEN,
  TEXT_COLOR_GREY,
} from '@assets/style/styleDefault';

const styles = StyleSheet.create({
  flexRow: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  positionSticky: {
    position: 'sticky',
    top: 0,
    backgroundColor: BG_COLOR,
    zIndex: 10,
  },
  navSticky: {
    position: 'sticky',
    top: 160,
    backgroundColor: BG_COLOR,
    zIndex: 1,
  },
  bgContent: {
    backgroundColor: BG_TABLE_DEFAULT,
    paddingLeft: '2rem',
    paddingBottom: '8rem',
    minHeight: 1000,
  },
  textContent: {
    marginTop: 20,
    gap: 20,
  },
  textLabel: {
    color: COLOR_TEXT_DEFAULT,
  },
  textPlaceholder: {
    color: TEXT_COLOR_GREY,
  },
  rowContent: {
    borderBottomWidth: 1,
    borderBottomColor: '#5C5C5C',
    paddingBottom: 20,
    marginTop: 20,
    alignItems: 'center',
  },
  rowBtn: {
    paddingBottom: 29,
    marginTop: 25,
    alignItems: 'center',
    marginLeft: 20,
  },
  textLink: {
    color: TEXT_COLOR_GREEN,
    cursor: 'pointer',
  },
  iconGlobal: {
    height: 25,
    width: 25,
    borderRadius: 50,
  },
  wrapperOption: {
    marginTop: 40,
  },
  wrapperProperty: {
    marginTop: 40,
  },
  textHeader: {
    marginTop: 20,
    marginBottom: 10,
  },
  textRequire: {
    color: '#ff0000',
  },
  wrapSelect: {
    height: 42,
    width: '95%',
    backgroundColor: 'transparent',
    borderRadius: 5,
    flexDirection: 'row',
    alignItems: 'center',
    paddingLeft: 15,
    paddingRight: 15,
    justifyContent: 'space-between',
    borderColor: COLOR_TEXT_DEFAULT,
    borderWidth: 1,
  },
  customRow: {
    flexDirection: 'column',
    borderBottomWidth: 1,
    borderBottomColor: '#5C5C5C',
    paddingBottom: 25,
    marginTop: 25,
    justifyContent: 'center',
  },
  iconView: {
    width: 20,
    height: 20,
  },
  iconWifi: {
    width: 20,
    height: 16,
    marginRight: 10,
  },
  iconAdd: {
    width: 25,
    height: 25,
    marginRight: 15,
  },
  textWifiActive: {
    color: BTN_COLOR_GREEN,
  },
  btnDropDown: {
    height: 42,
    backgroundColor: '#525252',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRadius: 5,
    width: '80%',
    paddingRight: 15,
    paddingLeft: 30,
  },
  bigTitle: {
    color: COLOR_TEXT_DEFAULT,
    fontSize: 30,
    fontFamily: FONT_FAMILY_HEADER,
    textTransform: 'uppercase',
  },
  iconDropDown: {
    width: 13,
    height: 6,
  },
  iconLevias: {
    width: 25,
    height: 25,
    marginRight: 10,
  },
  iconLock: {
    height: 24,
    width: 20,
    marginRight: 10,
  },
});

export default styles;

import React from 'react';
import { View, Image, FlatList, Pressable, StyleSheet } from 'react-native';
import { NavLink, useLocation } from 'react-router-dom';
import { withNamespaces } from 'react-i18next';

import { BG_HOVER, BG_SIDEBAR, COLOR_TEXT_DEFAULT, LINE_COLOR } from '@assets/style/styleDefault';
import { JText as Text } from '@components/common/JText';

const SidebarListFolder = ({ t }) => {
  const { pathname } = useLocation();
  const SidebarListFolderData = [
    { text: t('pages_FileManager_partials_SidebarListFolder.LEVICATermsOfUse'), link: '/file-manager/LEVICA-terms' },
    {
      text: t('pages_FileManager_partials_SidebarListFolder.franchiseStoreTermsOfUse'),
      link: '/file-manager/merchant-terms',
    },
    { text: t('pages_FileManager_partials_SidebarListFolder.privacyPolicy'), link: '/file-manager/privacy-policy' },
    {
      text: t('pages_FileManager_partials_SidebarListFolder.specifiedCommercialTransactionLaw'),
      link: '/file-manager/commercial-transaction-law',
    },
    {
      text: t('pages_FileManager_partials_SidebarListFolder.merchantTermOfUse'),
      link: '/file-manager/merchant-terms-of-use',
    },
    {
      text: t('pages_FileManager_partials_SidebarListFolder.otherNotifications'),
      link: '/file-manager/other-notifications',
    },
    // {
    //   text: t('pages_FileManager_partials_SidebarListFolder.otherNotifications'),
    //   link: '/file-manager/other-notifications',
    // },
  ];
  return (
    <View style={styles.listContentLeft}>
      <FlatList
        style={styles.listContent}
        data={SidebarListFolderData}
        keyExtractor={(item, index) => index.toString()}
        renderItem={({ item, index }) => {
          const isActiveDefault = /^\/file-manager\/LEVICA-terms$/.test(item.link) && /^\/file-manager$/.test(pathname);
          return (
            <Pressable style={({ hovered }) => [hovered ? styles.listItemHover : styles.listItem]} key={item.text}>
              <NavLink
                to={item.link}
                style={(isActive) => ({
                  textDecorationLine: 'none',
                  backgroundColor: isActive || isActiveDefault ? BG_HOVER : 'initial',
                  borderRadius: isActive || isActiveDefault ? 4 : 0,
                })}
              >
                <View
                  style={
                    index === SidebarListFolderData.length - 1
                      ? [styles.listItemContainer, { borderBottomWidth: 0 }]
                      : [styles.listItemContainer]
                  }
                >
                  <Image style={styles.listItemIcon} source={require('@assets/icons/folder-blue.svg')} />
                  <Text style={styles.listItemText}>{item.text}</Text>
                </View>
              </NavLink>
            </Pressable>
          );
        }}
      />
    </View>
  );
};

export default withNamespaces()(SidebarListFolder);

const styles = StyleSheet.create({
  listContentLeft: {
    borderRadius: 5,
    height: 'calc(100vh - 200px)',
    backgroundColor: BG_SIDEBAR,
    marginLeft: 15,
  },
  listContent: {
    paddingVertical: 20,
    paddingHorizontal: 20,
  },
  listItemHover: {
    backgroundColor: BG_HOVER,
    borderRadius: 5,
  },
  listItemContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    paddingHorizontal: 15,
    paddingVertical: 20,
    borderBottomWidth: 1,
    borderBottomColor: LINE_COLOR,
  },
  listItemText: {
    color: COLOR_TEXT_DEFAULT,
    paddingLeft: 20,
  },
  listItemIcon: {
    height: 23,
    width: 31,
  },
});

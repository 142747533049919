import React, { useState, useEffect, useRef } from 'react';
import { View, ScrollView } from 'react-native';
import { Col } from 'react-native-easy-grid';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useHistory, useLocation } from 'react-router-dom';
import * as Yup from 'yup';
import { LinearGradient } from 'expo-linear-gradient';
import { withNamespaces } from 'react-i18next';
import { useSelector } from 'react-redux';

import i18n from '../../../i18n';
import HeaderAvatar from '@components/Headers/HeaderAvatar';
import HeaderBack from '@components/Headers/HeaderBack';
import JButton from '@components/common/JButton';
import JInput from '@components/common/JInput';
import { JText as Text } from '@components/common/JText';
import { INPUT_BG, TEXT_COLOR_GREY } from '@assets/style/styleDefault';
import RequireText from '@components/common/RequireText';
import ModalSendSuccess from '@components/Modals/ModalSendSuccess';
import ModalSendFail from '@components/Modals/ModalSendFail';
import styles from './style';
import { post } from '@utils/RequestLevica';
import { API_PATH } from '@helper/constant';
import { formatNumber } from '@helper/formatTypes';
import scroll2View from '@helper/scroll2View';

const defaultValues = {
  sendTo: '',
  tokenQuantity: '',
  sendTarget: 'XXXXX',
  sendFrom: '',
};

const SendTokenToUser = (props) => {
  const { t } = props;
  const location = useLocation();
  const balance = location.state?.balance;
  const validationSchema = Yup.object().shape({
    sendTo: Yup.string().trim().required(t('errorMessages.enterYourWalletAddrPls')),
    tokenQuantity: Yup.string()
      .typeError(t('errorMessages.fieldRequired', { fieldName: t('components_Modals_ModalBurn.intergerBurnToken') }))
      .min(1, t('errorMessages.fieldRequired', { fieldName: t('components_Modals_ModalBurn.intergerBurnToken') }))
      .max(19, t('errorMessages.TXT_MAX_15'))
      .test(
        'burnValue',
        t('errorMessages.fieldRequired', { fieldName: t('components_Modals_ModalBurn.intergerBurnToken') }),
        (value) => value !== '0'
      ),
  });
  const { pathname } = useLocation();

  const [content, setContent] = useState(() => ({
    title: t('pages_Home_SendTokenToUser.sendTargetLEVICATitle'),
    sendTarget: t('pages_Home_SendTokenToUser.sendTargetLEVICA'),
    sendFrom: '0x5754284f345afc66a98fbb0a0afe71e0f007b123',
  }));
  const [isModalSendSuccess, setModalSendSuccess] = useState(false);
  const [isModalSendFail, setModalSendFail] = useState(false);
  const history = useHistory();
  const scrollViewRef = useRef();
  const [isSending, setIsSending] = useState(false);
  const setting = useSelector((state) => state.setting.setting);
  const {
    control,
    handleSubmit,
    reset,
    setError,
    formState: { errors, isValid },
  } = useForm({
    defaultValues,
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
  });

  const onSubmit = async (data) => {
    setIsSending(true);
    try {
      await post(API_PATH.TRANSFER_POINT_TO_USER, {
        toAddress: data?.sendTo,
        amount: parseInt(data?.tokenQuantity?.replace(/,/g, '')),
      });
      setModalSendSuccess(true);
      reset();
      setTimeout(() => {
        history.push('/');
      }, 2000);
    } catch (error) {
      if (error?.response?.data && error?.response?.data[0]?.code === '1506') {
        setError('tokenQuantity', {
          type: 'server',
          message: t('errorMessages.notEnoughPoint'),
        });
        scroll2View('tokenQuantity', scrollViewRef.current);
      } else if (error?.response?.data && error?.response?.data[0]?.code === '1505') {
        setError('sendTo', {
          type: 'server',
          message: t('errorMessages.walletAddrNotFound'),
        });
        scroll2View('sendTo', scrollViewRef.current);
      }
    } finally {
      setIsSending(false);
    }
  };

  const converTitle = (urlPath) => {
    switch (urlPath) {
      case '/tokens/send-gift-from-issuing-wallet':
        return setContent({
          title: t('pages_Home_SendTokenToUser.sendTargetGiftTitle'),
          sendTarget: t('pages_Home_SendTokenToUser.sendTargetGift'),
          sendFrom: '0x5754284f345afc66a98fbb0a0afe71e0f007b949',
        });
      case '/tokens/send-point-from-issuing-wallet':
        return setContent({
          title: t('pages_Home_SendTokenToUser.sendTargetPointManagementTitle'),
          sendTarget: t('pages_Home_SendTokenToUser.sendTargetPointManagement'),
          sendFrom: '0x5754284f345afc66a98fbb0a0afe71e0f007b123',
        });
      case '/tokens/send-point-from-issuing-wallet':
        return setContent({
          title: t('pages_Home_SendTokenToUser.sendTargetIssuingTitle'),
          sendTarget: t('pages_Home_SendTokenToUser.sendTargetPointManagement'),
          sendFrom: '0x5754284f345afc66a98fbb0a0afe71e0f007b949',
        });
      case '/send-token-admin-to-user':
        return setContent({
          title: t('pages_Home_SendTokenToUser.sendTargetLEVICATitle'),
          sendTarget: t('pages_Home_SendTokenToUser.sendTargetLEVICA'),
          sendFrom: '0x5754284f345afc66a98fbb0a0afe71e0f007b123',
        });

      default:
        return '';
    }
  };

  useEffect(() => {
    converTitle(pathname);
  }, [pathname, i18n.language]);

  useEffect(() => {
    scroll2View(Object.keys(errors)[0], scrollViewRef.current);
  }, [errors]);

  return (
    <View style={styles.wrapperContainer}>
      <ScrollView showsVerticalScrollIndicator={false} ref={scrollViewRef}>
        <View style={styles.wrapperStickyHeader}>
          <View style={styles.headerItem}>
            <HeaderBack
              historyBack
              widthIconBack={20}
              heightIconBack={20}
              btnBack="iconX.svg"
              textBack={t('commonText.cancelButton')}
              iconHeader="iconWorld.svg"
              textHeader={content.title}
              avatar={<HeaderAvatar />}
            />
          </View>
        </View>
        <View style={styles.wrapperBody}>
          <View style={{ width: '70%', minWidth: '600px' }}>
            <View style={styles.wrapperItem}>
              <Col size={2}>
                <View style={styles.wrapperItemLeft}>
                  <Text style={styles.defaultText}>{t('pages_Home_SendTokenToUser.from')} </Text>
                </View>
              </Col>
              <Col size={8}>
                <View style={styles.wrapperItemRight}>
                  <Text style={{ color: TEXT_COLOR_GREY }}>{setting?.adminSettingAddress?.issuerAddress}</Text>
                </View>
              </Col>
            </View>
            <View style={styles.wrapperItem}>
              <Col size={2}>
                <View style={styles.wrapperItemLeft} nativeID="sendTo">
                  <Text style={styles.defaultText}>{t('pages_Home_SendTokenToUser.to')}</Text>
                  <RequireText paddingLeft={10} />
                </View>
              </Col>
              <Col size={8}>
                <View style={styles.wrapperItemRight}>
                  <JInput
                    placeholder={t('pages_Home_SendTokenToUser.toPlaceholder')}
                    subfix="iconEdit.svg"
                    control={control}
                    name="sendTo"
                    widthSubfix={16}
                    heightSubfix={16}
                    backgroundColor={INPUT_BG}
                    borderError={errors?.sendTo ? 'red' : ''}
                  />
                </View>
              </Col>
            </View>
            <View style={styles.wrapperItem}>
              <Col size={2}>
                <View style={styles.wrapperItemLeft}>
                  <Text style={styles.defaultText}>{t('pages_Home_SendTokenToUser.sendTarget')} </Text>
                </View>
              </Col>
              <Col size={8}>
                <View style={styles.wrapperItemRight}>
                  <Text style={{ color: TEXT_COLOR_GREY }}>{content.sendTarget}</Text>
                </View>
              </Col>
            </View>
            <View style={styles.wrapperItem}>
              <Col size={2}>
                <View style={styles.wrapperItemLeft} nativeID="tokenQuantity">
                  <Text style={styles.defaultText}>{t('pages_Home_SendTokenToUser.tokenQuantity')}</Text>
                  <RequireText paddingLeft={10} />
                </View>
              </Col>
              <Col size={8}>
                <View style={styles.wrapperItemRight}>
                  <JInput
                    typeMoney
                    placeholder={t('pages_Home_SendTokenToUser.tokenQuantityPlaceholder')}
                    subfix="iconEdit.svg"
                    control={control}
                    name="tokenQuantity"
                    widthSubfix={16}
                    heightSubfix={16}
                    backgroundColor={INPUT_BG}
                    borderError={errors?.tokenQuantity ? 'red' : ''}
                  />
                </View>
              </Col>
            </View>
            <View style={[styles.wrapperItem, { padding: 10 }]}>
              <Col size={2}></Col>
              <Col size={8}>
                <Text>
                  {t('pages_Home_SendTokenToUser.sendableTokenBalance')} / {formatNumber(balance)} LEVICA
                </Text>
              </Col>
            </View>
            <View style={[styles.wrapperItem]}>
              <Col size={2}></Col>
              <Col size={8}>
                <View style={styles.wrapperButton}>
                  <LinearGradient colors={['#54DBB2', '#04C48B']} style={{ borderRadius: 5, width: 200 }}>
                    <JButton
                      hasIcon
                      linkIcon="send.svg"
                      widthIcon={20}
                      heightIcon={20}
                      linkIconDisabled="sendGray.svg"
                      text={t('commonText.sendTokenButton')}
                      width={200}
                      textColor="#fff"
                      disabled={isSending}
                      onPressAction={() => handleSubmit(onSubmit)()}
                      isProcessing={isSending}
                    />
                  </LinearGradient>
                </View>
              </Col>
            </View>
          </View>
        </View>
      </ScrollView>
      <ModalSendSuccess
        setModalVisible={setModalSendSuccess}
        isModalVisible={isModalSendSuccess}
        linkBack={pathname === '/send-token-admin-to-user' ? './' : null}
        isClickBackDrop={false}
      />
      <ModalSendFail setModalVisible={setModalSendFail} isModalVisible={isModalSendFail} />
    </View>
  );
};

export default withNamespaces()(SendTokenToUser);

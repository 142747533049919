// export const COLOR_TEXT_DEFAULT = 'black';
export const COLOR_TEXT_DEFAULT = '#f2f2f2db';
export const TEXT_COLOR_GREY = '#909090';
export const TEXT_COLOR_RED = '#FE0A0A';
export const TEXT_COLOR_WHITE = '#ffffff';
export const TEXT_COLOR_GREEN = '#00BE85';
export const TEXT_COLOR_PRIMARY = '#2A74DB';
export const TEXT_COLOR_HEADER_TABLE = '#9A9A9A';

export const FONT_FAMILY_DEFAULT = 'NotoSansJP';
export const FONT_FAMILY_HEADER = 'BebasNeue';
export const FONT_SIZE = '18px';
export const FONT_SIZE_HEADER = '18px';
export const FONT_SIZE_HEADER_LOGIN = '20px';
export const FONT_SIZE_DEFAULT = '14px';

export const FONT_WEIGHT_DEFAULT_HEADER = 500;

// export const BG_COLOR = '#FFFFFF';
export const BG_COLOR = '#2E2D2D';
export const BG_SIDEBAR = '#3A3B41';
export const BG_ACTIVE_SIDEBAR = '#2DBE85';
export const BG_HOVER = '#494a52';
export const BG_FORM_DEFAULT = '#343434';
export const BG_TABLE_DEFAULT = '#343434';
export const BG_TABLE_BODY = '#f9f9f9';

export const BTN_COLOR_PRIMARY = '#2A74DB';
export const BTN_COLOR_GREEN = '#00875F';
export const BTN_COLOR_RED = '#E61F00';
export const BTN_COLOR_GREY = '#494A52';
export const BTN_COLOR_GREY_2 = '#56575F';
export const BTN_COLOR_GREY_3 = '#8B8B8B';
export const BTN_GREY_LIGHT = '#757575';
export const BTN_COLOR_ORANGE = '#FFB045';

export const INPUT_BG = '#404040';
export const LINE_COLOR = '#5c5c5c';

export const BORDER_TABLE_GREY = '#C3C3C3';
export const BORDER_GREY = '#707070';
export const BORDER_GREY_LIGHT = '#E0E0E0';
export const BORDER_MODAL = '#e5e0e0';

export const MODAL_HOVER = '#efefef';
export const BORDER_ERROR = '#FF0000';
export const BG_CHECKBOX = '#575757';

import { StyleSheet } from 'react-native';
import {
  BG_COLOR,
  BG_TABLE_DEFAULT,
  COLOR_TEXT_DEFAULT,
  FONT_SIZE_DEFAULT,
  TEXT_COLOR_GREY,
} from '@assets/style/styleDefault';

const styles = StyleSheet.create({
  positionSticky: {
    position: 'sticky',
    top: 0,
    backgroundColor: BG_COLOR,
    zIndex: 10,
    paddingHorizontal: 15,
  },
  headerCommunity: {
    zIndex: 9,
  },
  content: {
    backgroundColor: BG_TABLE_DEFAULT,
    minHeight: 800,
    height: 'calc(100vh - 10px)',
    marginHorizontal: 15,
    paddingHorizontal: 30,
  },
  headerContent: {
    marginTop: 25,
    flexDirection: 'row',
    marginBottom: 10,
  },

  textRequire: {
    color: 'red',
    fontSize: 18,
    marginLeft: 10,
  },
  placeholder: {
    color: TEXT_COLOR_GREY,
  },
  textHeader: {
    marginLeft: 10,
    color: COLOR_TEXT_DEFAULT,
    fontSize: FONT_SIZE_DEFAULT,
  },
  inputGroup: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 20,
    gap: 10,
  },
  label: {
    flexDirection: 'row',
  },
  textLabel: {
    color: COLOR_TEXT_DEFAULT,
    fontSize: FONT_SIZE_DEFAULT,
  },
  colText: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  wrapperWarn: {
    marginTop: 30,
    flexDirection: 'row',
  },
  iconWarn: {
    height: 20,
    width: 20,
  },
  textWarn: {
    color: COLOR_TEXT_DEFAULT,
    fontSize: FONT_SIZE_DEFAULT,
    width: '80%',
  },
  buttonSubmit: {
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: 100,
  },
  textError: {
    color: 'red',
    fontSize: 12,
    marginTop: 5,
  },
  selectImg: {
    backgroundColor: BG_COLOR,
    height: 42,
    width: '80%',
    borderRadius: 5,
    position: 'relative',
    cursor: 'pointer',
  },
  prefixIcon: {
    position: 'absolute',
    width: 23,
    height: 18,
    left: 15,
    top: 10,
  },
  textSelect: {
    color: '#909090b8',
    top: 9,
    left: 25,
    position: 'absolute',
  },
  subfixIcon: {
    position: 'absolute',
    width: 18,
    height: 18,
    top: 10,
    right: 20,
  },
  flexRow: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  iconHeaderStyle: {
    width: 22,
    height: 22,
    paddingRight: 10,
  },
});

export default styles;

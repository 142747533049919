import { LinearGradient } from 'expo-linear-gradient';
import React from 'react';
import { View, StyleSheet, Image } from 'react-native';
import Modal from 'react-native-modal';
import { withNamespaces } from 'react-i18next';

import {
  FONT_SIZE_DEFAULT,
  FONT_SIZE_HEADER,
  BORDER_TABLE_GREY,
  BG_HOVER,
  BTN_COLOR_GREY_2,
} from '@assets/style/styleDefault';
import JButton from '@components/common/JButton';
import { JText as Text } from '@components/common/JText';
import ModalBackDrop from '@components/Modals/ModalBackDrop';

const ModalSave = (props) => {
  const { isModalVisible, setModalVisible, func, t,isDeleteAccount } = props;
  return (
    <View>
      <Modal
        animationIn="fadeIn"
        isVisible={isModalVisible}
        style={{ alignItems: 'center' }}
        customBackdrop={<ModalBackDrop onPress={() => setModalVisible(false)} />}
      >
        <View style={styles.wrapperModal}>
          <View style={styles.maxWidth700}>
            <View style={styles.header}>
              <Text style={styles.textHeader}>{t('pages_Setting_MemberChange.modalSaveTextHeader1')}</Text>
            </View>
            <View style={styles.body}>
              <Image source={require('@assets/icons/warning.svg')} alt="warning" style={styles.iconWarning} />
              <Text style={styles.textHeader}>{t('pages_Setting_MemberChange.modalSaveTextHeader2')}</Text>
              <View style={styles.button}>
                <JButton
                  text={t('commonText.cancelButton')}
                  linkIcon="iconX-White.svg"
                  heightIcon={15}
                  widthIcon={15}
                  width={200}
                  textColor="#fff"
                  btnColor={BTN_COLOR_GREY_2}
                  onPressAction={() => {
                    setModalVisible(false);
                  }}
                />
                <View style={{ paddingRight: 30 }}></View>
                <LinearGradient colors={['#FE8463', '#FD4F44']} style={{ borderRadius: 5 }}>
                  <JButton
                    text={t('commonText.buttonDeleteFile')}
                    width={200}
                    textColor="#fff"
                    isProcessing={isDeleteAccount}
                    disabled={isDeleteAccount}
                    onPressAction={() => {
                      func ? func() : null;
                    }}
                  />
                </LinearGradient>
              </View>
            </View>
          </View>
        </View>
      </Modal>
    </View>
  );
};

export default withNamespaces()(ModalSave);

const styles = StyleSheet.create({
  //common
  dFlex: {
    display: 'flex',
    alignItems: 'center',
  },
  text: {
    color: 'black',
    fontSize: FONT_SIZE_DEFAULT,
  },
  textHeader: {
    color: 'black',
    fontSize: FONT_SIZE_HEADER,
  },
  wrapperModal: {
    maxWidth: 900,
    minWidth: 600,
  },
  iconWarning: {
    width: 55,
    height: 46,
    padding: 10,
    marginVertical: 20,
  },
  maxWidth700: {
    backgroundColor: '#fff',
    borderRadius: 10,
    paddingVertical: 30,
  },
  header: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    borderBottomColor: BORDER_TABLE_GREY,
    borderBottomWidth: 1,
    paddingBottom: 20,
  },
  statusIcon: {
    width: 23,
    height: 30,
    marginRight: 10,
  },
  body: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 30,
    paddingBottom: 0,
    paddingTop: 0,
  },
  warperItem: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: 500,
    paddingVertical: 10,
    paddingHorizontal: 20,
    borderBottomColor: BORDER_TABLE_GREY,
    borderBottomWidth: 1,
    marginBottom: 5,
  },

  itemHovered: {
    backgroundColor: BG_HOVER,
    borderRadius: 4,
  },
  button: {
    marginTop: 30,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
});

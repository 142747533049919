import i18n from 'i18next';
import { reactI18nextModule } from 'react-i18next';

import translationEN from './assets/locales/languages/en.json';
import translationJP from './assets/locales/languages/jp.json';
import translationZH from './assets/locales/languages/zh.json';

const resources = {
  English: {
    translation: translationEN,
  },
  Japanese: {
    translation: translationJP,
  },
  China: {
    translation: translationZH,
  },
};

i18n.use(reactI18nextModule).init({
  resources,
  lng: 'Japanese',
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;

import React from 'react';
import { View, StyleSheet, Image, TouchableOpacity } from 'react-native';
import { Pressable } from 'native-base';
import Modal from 'react-native-modal';
import { withNamespaces } from 'react-i18next';

import { FONT_SIZE_DEFAULT, FONT_SIZE_HEADER, BORDER_MODAL, MODAL_HOVER } from '@assets/style/styleDefault';
import { JText as Text } from '@components/common/JText';
import ModalBackDrop from '@components/Modals/ModalBackDrop';

const ModalTwoActorAuthentication = (props) => {
  const { isModalVisible, setModalVisible, setIsTwoActorAuthentication, setValue, t } = props;
  const setData = (status) => {
    setModalVisible(false);

    if (status === 'ON') {
      setIsTwoActorAuthentication(true);
      setValue('twoFactorAuthentication', true, { shouldDirty: true });
    } else {
      setIsTwoActorAuthentication(false);
      setValue('twoFactorAuthentication', false, { shouldDirty: true });
    }
  };
  return (
    <View>
      <Modal
        animationIn="fadeIn"
        isVisible={isModalVisible}
        style={{ alignItems: 'center' }}
        customBackdrop={<ModalBackDrop onPress={() => setModalVisible(false)} />}
      >
        <View style={styles.wrapperModal}>
          <View style={styles.mainClose}>
            <TouchableOpacity style={styles.contentClose} onPress={() => setModalVisible(false)}>
              <Image
                source={require('@assets/icons/closeIcon.svg')}
                alt=""
                style={[styles.iconClose, styles.colorWhrite]}
              />
              <Text style={styles.colorWhite}>{t('commonText.cancelButton')}</Text>
            </TouchableOpacity>
          </View>
          <View style={styles.maxWidth700}>
            <View style={styles.header}>
              <Image
                source={require('@assets/icons/twoFactorAuthentication.svg')}
                alt=""
                style={[styles.twoFactorAuthentication]}
              />
              <Text style={styles.textHeader}>{t('pages_Setting_modal.modalTwoActorAuthenticationText1')}</Text>
            </View>
            <View style={styles.body}>
              <Text style={[styles.textHeader, { marginBottom: 30 }]}>
                {t('pages_Setting_modal.modalTwoActorAuthenticationText2')}
              </Text>
              <Pressable>
                {({ isHovered }) => {
                  return (
                    <View style={isHovered ? styles.itemHovered : styles.notHover}>
                      {isHovered ? (
                        <Image style={styles.pointingRightIcon} source={require(`@assets/icons/pointingRight.svg`)} />
                      ) : (
                        <View style={styles.pointingRightIcon} />
                      )}
                      <View style={styles.warperItem} onClick={() => setData('ON')}>
                        <Text style={styles.textHeader}> ON </Text>
                      </View>
                    </View>
                  );
                }}
              </Pressable>
              <Pressable>
                {({ isHovered }) => {
                  return (
                    <View style={isHovered ? styles.itemHovered : styles.notHover}>
                      {isHovered ? (
                        <Image style={styles.pointingRightIcon} source={require(`@assets/icons/pointingRight.svg`)} />
                      ) : (
                        <View style={styles.pointingRightIcon} />
                      )}
                      <View style={[styles.warperItem, { borderBottomWidth: 0 }]} onClick={() => setData('OFF')}>
                        <Text style={styles.textHeader}> OFF </Text>
                      </View>
                    </View>
                  );
                }}
              </Pressable>
            </View>
          </View>
        </View>
      </Modal>
    </View>
  );
};

export default withNamespaces()(ModalTwoActorAuthentication);

const styles = StyleSheet.create({
  //common
  dFlex: {
    display: 'flex',
    alignItems: 'center',
  },
  text: {
    color: 'black',
    fontSize: FONT_SIZE_DEFAULT,
  },
  textHeader: {
    color: 'black',
    fontSize: FONT_SIZE_HEADER,
  },
  wrapperModal: {
    maxWidth: 900,
    minWidth: 600,
  },
  mainClose: {
    maxWidth: 200,
    marginRight: 'auto',
    paddingLeft: 20,
  },
  contentClose: {
    with: 20,
    height: 40,
    backgroundColor: 'transparent',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  colorWhite: {
    color: '#fff',
  },
  twoFactorAuthentication: {
    width: 24,
    height: 32,
    marginRight: 10,
  },
  iconClose: {
    width: 15,
    height: 15,
    marginRight: 10,
  },
  maxWidth700: {
    backgroundColor: '#fff',
    borderRadius: 10,
    paddingVertical: 20,
  },
  header: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    borderBottomColor: BORDER_MODAL,
    borderBottomWidth: 1,
    paddingBottom: 20,
  },
  statusIcon: {
    width: 23,
    height: 30,
    marginRight: 10,
  },
  body: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingVertical: 40,
  },
  warperItem: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    borderBottomColor: BORDER_MODAL,
    borderBottomWidth: 1,
    paddingVertical: 20,
    flex: 1,
  },

  itemHovered: {
    backgroundColor: MODAL_HOVER,
    alignItems: 'center',
    flexDirection: 'row',
    width: 600,
  },
  notHover: {
    alignItems: 'center',
    flexDirection: 'row',
    width: 600,
  },
  pointingRightIcon: {
    width: 30,
    height: 28,
    marginLeft: 20,
  },
});

import { StyleSheet } from 'react-native';
import {
  BG_COLOR,
  BG_FORM_DEFAULT,
  COLOR_TEXT_DEFAULT,
  FONT_SIZE_DEFAULT,
  INPUT_BG,
  LINE_COLOR,
} from '@assets/style/styleDefault';

const styles = StyleSheet.create({
  //common
  ms: {
    marginLeft: 10,
  },
  mr: {
    marginRight: 30,
  },
  mt: {
    marginTop: 20,
  },
  defaultText: {
    fontSize: FONT_SIZE_DEFAULT,
    color: COLOR_TEXT_DEFAULT,
  },
  fontWeight600: {
    fontWeight: 600,
  },
  fontSize18: {
    fontSize: 18,
    flexDirection: 'row',
  },
  textWarning: {
    color: 'red',
    paddingLeft: 8,
  },
  error: {
    color: 'red',
    fontSize: 12,
    transform: 'translateZ(0)',
  },
  flex: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'baseline',
  },
  sticky: {
    position: 'sticky',
    top: 0,
    zIndex: 10,
    backgroundColor: BG_COLOR,
    paddingBottom: 10,
  },
  //style header
  container: { flex: 1, backgroundColor: BG_COLOR, paddingHorizontal: 10 },
  wrapperTitle: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  wrapperButton: {
    flexDirection: 'row',
    justifyContent: 'end',
    alignItems: 'center',
    minWidth: '750px',
    flex: 1,
  },
  //style body
  wrapperBody: {
    backgroundColor: BG_FORM_DEFAULT,
  },
  wrapperBodyLeft: {
    paddingLeft: 20,
    paddingBottom: 30,
    paddingRight: 20,
  },
  wrapperBodyRight: {
    flexDirection: 'row',
    paddingLeft: 30,
  },
  wrapperItemRight: {
    marginTop: 10,
    backgroundColor: INPUT_BG,
    padding: 10,
    borderRadius: 5,
    marginLeft: 20,
    gap: 20,
  },
  wrapperRadioButton: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 20,
  },
  wrapperItem: {
    borderBottomColor: LINE_COLOR,
    borderBottomWidth: 1,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    paddingVertical: 20,
  },

  wrapperTime: {
    display: 'flex',
    flexDirection: 'row',
  },
  wrapperTimeItem: {
    flex: 1,
  },
  //img
  images: {
    width: '18vw',
    maxHeight: 190,
    maxWidth: 290,
    height: '14vw',
    objectFit: 'cover',
  },
  btnAddImg: {
    marginTop: 50,
    width: 'fit-content',
  },
  button: {
    borderWidth: 0,
    width: 200,
    borderRadius: 4,
    height: 42,
    fontWeight: 400,
    lineHeight: 1.5,
    cursor: 'pointer',
    fontSize: 14,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
  },
});

export default styles;

import { StyleSheet } from 'react-native';
import {
  BG_COLOR,
  BG_FORM_DEFAULT,
  COLOR_TEXT_DEFAULT,
  FONT_FAMILY_DEFAULT,
  FONT_SIZE_DEFAULT,
  INPUT_BG,
  LINE_COLOR,
} from '@assets/style/styleDefault';

const styles = StyleSheet.create({
  header: {
    height: '100%',
    flex: 1,
    backgroundColor: BG_COLOR,
    position: 'relative',
  },
  positionSticky: {
    position: 'sticky',
    top: 0,
    backgroundColor: BG_COLOR,
    zIndex: 10,
    marginRight: 15,
  },

  borderForm: {
    paddingTop: 10,
    paddingLeft: 20,
    paddingRight: '15vw',
    paddingBottom: 100,
    backgroundColor: BG_FORM_DEFAULT,
    minHeight: 1100,
    marginRight: 20,
    minWidth: 1000,
  },
  flexDirection: {
    flexDirection: 'row',
  },
  inputArea: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 20,
  },
  titleInput: {
    fontFamily: FONT_FAMILY_DEFAULT,
    fontSize: FONT_SIZE_DEFAULT,
    display: 'flex',
    alignItems: 'center',
    height: 42,
    paddingLeft: 10,
  },
  whiteColor: {
    color: COLOR_TEXT_DEFAULT,
  },
  redColor: {
    color: 'red',
  },

  bulkhead: {
    borderColor: '#404040',
    borderWidth: '0.1px',
    marginBottom: 16,
  },
  minHeight: {
    minHeight: 'unset',
  },
  borderBottomWhite: {
    alignItems: 'center',
    paddingVertical: 12,
    borderBottomColor: LINE_COLOR,
    borderBottomWidth: 1,
    minHeight: 'auto',
  },
  margin12: { marginTop: 25 },
  marginBottom: {
    marginBottom: 100,
    paddingBottom: 120,
  },
  noteTextArea: {
    color: '#A5A5A5',
    lineHeight: '1.2',
    paddingBottom: '10px',
    minHeight: 250,
  },
  borderFormTextArea: {
    backgroundColor: INPUT_BG,
    borderRadius: '5px',
    paddingHorizontal: 10,
    paddingTop: 10,
    paddingVertical: 20,
  },
  inputTextArea: {},
  imgTextArea: {
    width: 18,
    height: 18,
  },
  textError: {
    color: 'red',
    fontSize: 12,
    marginTop: 5,
  },
  imageRemove: {
    width: 21,
    height: 21,
    cursor: 'pointer',
    marginHorizontal: 10,
  },
  imageRemoveBccEmail: {
    width: 12,
    height: 12,
    marginLeft: 5,
    marginTop: 5,
  },
  inputFile: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 20,
  },

  container: {
    borderWidth: 1,
    padding: 5,
    borderRadius: 5,
    marginBottom: 10,
    border: `1px solid ${LINE_COLOR}`,
    maxHeight: 200,
  },
  tag: {
    borderRadius: 5,
    padding: 5,
    margin: 5,
    marginLeft: 0,
    backgroundColor: INPUT_BG,
    flexWrap: 'wrap',
    flexDirection: 'row',
    justifyContent: 'center',
    alignContent: 'center',
  },
  input: {
    maxHeight: 42,
    flex: 1,
    fontSize: 14,
    fontFamily: 'NotoSansJP',
    borderRadius: 4,
    paddingRight: '3rem',
    outlineStyle: 'none',
    paddingVertical: 15,
    paddingLeft: 14,
    backgroundColor: INPUT_BG,
    color: '#f2f2f2db',
  },
  iconSubfix: {
    width: 18,
    height: 18,
    position: 'absolute',
    top: 13,
    right: 20,
  },
  warperBcc: { flexDirection: 'row', flexWrap: 'wrap', marginTop: 5 },
});

export default styles;

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { LinearGradient } from 'expo-linear-gradient';
import { View, StyleSheet, Image } from 'react-native';
import Modal from 'react-native-modal';
import { withNamespaces } from 'react-i18next';
import * as DocumentPicker from 'expo-document-picker';
import { useMutation } from '@tanstack/react-query';
import Papa from 'papaparse';

import {
  FONT_SIZE_DEFAULT,
  FONT_SIZE_HEADER,
  BORDER_TABLE_GREY,
  BG_HOVER,
  BTN_COLOR_GREY_2,
} from '@assets/style/styleDefault';
import JButton from '@components/common/JButton';
import { JText as Text } from '@components/common/JText';
import DragFileFromLocal from '@components/common/DragFileFromLocal';
import ModalBackDrop from '@components/Modals/ModalBackDrop';
import ModalAlert from '@components/Modals/ModalAlert';
import { postMulti } from '@utils/RequestLevica';
import { API_PATH } from '@helper/constant';
import ModalErrorImportCSV from './ModalErrorImportCSV';

const ModalImportCSV = (props) => {
  const { isModalVisible, setModalVisible, onImportCSV, onImportingCSV = () => {}, t } = props;
  const [file, setFile] = useState(null);
  const [modalUploadFileError, setModalUploadFileError] = useState(false);
  const [messageModalUploadFileError, setMessageModalUploadFileError] = useState('');
  const [modalUploadFileSuccess, setModalUploadFileSuccess] = useState(false);
  const [modalErrorImportCSV, setModalErrorImportCSV] = useState(false);
  const [listErrorImportCSV, setListErrorImportCSV] = useState([]);
  const csvFileHeader = useMemo(() => ['振込期日', '認証コード', '振込人名義', '振込金額'], []);

  const pickFileByDragAction = async (file) => {
    setModalVisible(false);
    if (!(await handleFileValidation(file[0]))) return false;
    setFile(file[0]);
  };

  const validateFileSize = useCallback((file) => {
    const CSV_FILE_SIZE_LIMIT = 50;
    return file.size <= CSV_FILE_SIZE_LIMIT * 1024 * 1024;
  }, []);

  const validateFileType = (file) => file.mimeType === 'text/csv' || file.type === 'text/csv';

  const checkEmptyCSVFile = useCallback(
    (parseCSVFileResult) => {
      if (parseCSVFileResult?.data?.length < 2) {
        setModalUploadFileError(true);
        setMessageModalUploadFileError(t('components_PayManagement.importedFileEmpty'));
        return false;
      }
      return true;
    },
    [setModalUploadFileError, setMessageModalUploadFileError, csvFileHeader, t]
  );

  const checkValidCSVFile = useCallback(
    (parseCSVFileResult) => {
      if (!parseCSVFileResult || parseCSVFileResult?.errors?.length > 0) {
        const errorMessage =
          parseCSVFileResult?.errors?.every((err) => err.type === 'Delimiter') && !parseCSVFileResult?.data?.length
            ? t('components_PayManagement.importedFileEmpty')
            : t('components_PayManagement.fileTypeError');
        setModalUploadFileError(true);
        setMessageModalUploadFileError(errorMessage);

        return false;
      }

      if (JSON.stringify(parseCSVFileResult?.data[0]) != JSON.stringify(csvFileHeader)) {
        setModalUploadFileError(true);
        setMessageModalUploadFileError(t('components_PayManagement.inCorrectFileStructure'));
        return false;
      }

      return true;
    },
    [setModalUploadFileError, setMessageModalUploadFileError, csvFileHeader, t]
  );

  const handleFileValidation = useCallback(
    async (file) => {
      if (!validateFileSize(file)) {
        setModalUploadFileError(true);
        setMessageModalUploadFileError(t('components_PayManagement.fileSizeError'));
        return false;
      }

      if (!validateFileType(file)) {
        setModalUploadFileError(true);
        setMessageModalUploadFileError(t('components_PayManagement.fileTypeError'));
        return false;
      }

      const parseCSVFileResult = await parseCSVFile(file.file ?? file);

      if (!checkValidCSVFile(parseCSVFileResult) || !checkEmptyCSVFile(parseCSVFileResult)) return false;

      return true;
    },
    [
      validateFileSize,
      validateFileType,
      setModalUploadFileError,
      setMessageModalUploadFileError,
      parseCSVFile,
      checkEmptyCSVFile,
      checkValidCSVFile,
    ]
  );

  const parseCSVFile = useCallback((file) => {
    return new Promise((res) => {
      Papa.parse(file, {
        skipEmptyLines: true,
        complete: (result) => res(result),
        error: () => res(false),
      });
    });
  }, []);

  const handlePickCSVFile = useCallback(async () => {
    const result = await DocumentPicker.getDocumentAsync({
      type: 'text/csv',
    });

    setModalVisible(false);

    if (!(await handleFileValidation(result))) return false;

    if (result.type === 'success') return setFile(result.file);
  }, [setModalVisible, handleFileValidation, setFile]);

  const { mutate: importCSV } = useMutation({
    mutationFn: async (file) =>
      postMulti(API_PATH.PAYMENT_CONFIRMATION_IMPORT_CSV, {
        file,
      }),
    retry: 3,
  });

  const handleImportCSV = useCallback(() => {
    if (!file) return;
    onImportingCSV();
    importCSV(file, {
      onSuccess: () => {
        setModalUploadFileSuccess(true);
      },
      onError: (err) => {
        console.log(err);
        switch (err?.response?.data?.code) {
          case '3019':
            setModalUploadFileError(true);
            return setMessageModalUploadFileError(t('components_PayManagement.fileTypeError'));

          case '4012':
            setModalUploadFileError(true);
            return setMessageModalUploadFileError(t('components_PayManagement.importedFileEmpty'));

          case '4016':
            setModalErrorImportCSV(true);
            return setListErrorImportCSV(err?.response?.data?.errorMessages);

          default:
            setModalUploadFileError(true);
            return setMessageModalUploadFileError(t('commonText.errorTryAgain'));
        }
      },
      onSettled: onImportCSV,
    });
  }, [file, onImportCSV, importCSV, onImportingCSV]);

  useEffect(() => {
    handleImportCSV();
  }, [handleImportCSV]);

  return (
    <View>
      <Modal
        animationIn="fadeIn"
        isVisible={isModalVisible}
        style={{ alignItems: 'center' }}
        customBackdrop={<ModalBackDrop onPress={() => setModalVisible(false)} />}
      >
        <DragFileFromLocal dragAction={pickFileByDragAction}>
          <View style={styles.wrapperModal}>
            <View style={styles.maxWidth700}>
              <View style={styles.header}>
                <Image source={require('@assets/icons/csv.svg')} alt="warning" style={styles.stopIcon} />
                <Text style={styles.textHeader}>{t('components_PayManagement.importCSV_textHeader')}</Text>
              </View>
              <View style={styles.body}>
                <Text style={styles.textBody}>{t('pages_FileManager_partials_ModalAddPdfFile.textBody')}</Text>
                <View style={styles.button}>
                  <JButton
                    text={t('commonText.cancelButton')}
                    linkIcon="iconX-White.svg"
                    heightIcon={15}
                    widthIcon={15}
                    width={200}
                    textColor="#fff"
                    btnColor={BTN_COLOR_GREY_2}
                    onPressAction={() => {
                      setModalVisible(false);
                    }}
                  />
                  <View style={{ paddingRight: 30 }}></View>
                  <LinearGradient colors={['#32A5F7', '#2D81F3']} style={{ borderRadius: 5 }}>
                    <JButton
                      text={t('pages_FileManager_partials_ModalAddPdfFile.AddPdfFile')}
                      width={200}
                      textColor="#fff"
                      iconSubfix="csv.svg"
                      widthSubfix={27}
                      heightSubfix={36}
                      onPressAction={handlePickCSVFile}
                    />
                  </LinearGradient>
                </View>
              </View>
            </View>
          </View>
        </DragFileFromLocal>
      </Modal>
      <ModalAlert
        isModalVisible={modalUploadFileError}
        setModalVisible={setModalUploadFileError}
        icon="stop.svg"
        iconWidth={20}
        iconHeight={16}
        modalText={messageModalUploadFileError}
      />
      <ModalAlert
        isModalVisible={modalUploadFileSuccess}
        setModalVisible={setModalUploadFileSuccess}
        icon="successGreen.svg"
        iconWidth={20}
        iconHeight={16}
        modalText={t('components_PayManagement.fileImportComplete')}
      />
      <ModalErrorImportCSV
        isVisible={modalErrorImportCSV}
        setModalVisible={setModalErrorImportCSV}
        payload={listErrorImportCSV}
      />
    </View>
  );
};

export default withNamespaces()(ModalImportCSV);

const styles = StyleSheet.create({
  //common
  dFlex: {
    display: 'flex',
    alignItems: 'center',
  },
  text: {
    color: 'black',
    fontSize: FONT_SIZE_DEFAULT,
  },
  textHeader: {
    color: 'black',
    fontSize: FONT_SIZE_HEADER,
  },
  textBody: { color: '#797979' },
  wrapperModal: {
    maxWidth: 900,
    minWidth: 600,
  },

  maxWidth700: {
    backgroundColor: '#fff',
    borderRadius: 10,
    paddingVertical: 30,
  },
  header: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    borderBottomColor: BORDER_TABLE_GREY,
    borderBottomWidth: 1,
    paddingBottom: 20,
  },
  stopIcon: {
    width: 37,
    height: 37,
    marginRight: 10,
  },
  statusIcon: {
    width: 23,
    height: 30,
    marginRight: 10,
  },
  body: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 40,
    paddingBottom: 10,
    paddingTop: 60,
  },
  warperItem: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: 500,
    paddingVertical: 10,
    paddingHorizontal: 20,
    borderBottomColor: BORDER_TABLE_GREY,
    borderBottomWidth: 1,
    marginBottom: 5,
  },

  itemHovered: {
    backgroundColor: BG_HOVER,
    borderRadius: 4,
  },
  button: {
    marginTop: 60,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
});

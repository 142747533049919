import React, { useEffect, useState } from 'react';
import { View, ScrollView } from 'react-native';
import { useForm } from 'react-hook-form';
import { Col, Grid, Row } from 'react-native-easy-grid';
import * as Yup from 'yup';
import { withNamespaces } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { LinearGradient } from 'expo-linear-gradient';
import { isValidPhoneNumber, parsePhoneNumber } from 'react-phone-number-input';

import HeaderToken from '@components/Headers/HeaderToken';
import styles from './style';
import JInput from '@components/common/JInput';
import ModalAlert from '@components/Modals/ModalAlert';
import { BTN_COLOR_PRIMARY } from '@assets/style/styleDefault';
import JButton from '@components/common/JButton';
import { JText as Text } from '@components/common/JText';
import { useMutation } from '@tanstack/react-query';
import { post } from '@utils/RequestLevica';
import { API_PATH } from '@helper/constant';
import PhoneInput from '@components/common/PhoneInput';

const SMSNotificationsTest = ({ t, setSMSNotificationsTest, smsContent }) => {
  const [isModalSave, setIsModalSave] = useState(false);
  const schema = Yup.object().shape({
    toNumber: Yup.string()
      .trim()
      .nullable()
      .test('compare ', t('errorMessages.TXT_JP_PHONE'), (phone) => {
        if (phone && parsePhoneNumber(phone)?.nationalNumber) {
          return isValidPhoneNumber(phone);
        } else {
          return true;
        }
      })
      .required(
        t('errorMessages.fieldRequired', {
          fieldName: t('pages_FileManager_SMSNotifications_SMSNotificationsTest.destinationAddress'),
        })
      ),
  });
  const {
    control,
    handleSubmit,
    formState: { errors, isDirty, isValid },
    setValue,
  } = useForm({
    defaultValues: {
      toNumber: '',
    },
    resolver: yupResolver(schema),
    mode: 'onChange',
  });
  const { mutate: smsTest } = useMutation({
    mutationFn: async (templateSms) => await post(`${API_PATH.TEMPLATES_SMS}/preview?type=5`, templateSms),
    retry: 3,
  });
  const { mutate: getTemplateMasterData } = useMutation({
    mutationFn: async () => await post(API_PATH.TEMPLATES_MASTER_DATA, { data: smsContent?.smsContentTemplate }),
    onSuccess: (data) => {
      setValue('keySetting', data.data);
    },
    retry: 3,
  });
  useEffect(() => {
    getTemplateMasterData();
  }, []);

  const onSubmit = (data) => {
    delete data.keySetting;
    const newData = { ...data, ...smsContent, type: 5 };
    smsTest(newData, {
      onSuccess: () => {
        setIsModalSave(!isModalSave);
      },
      onError: (error) => console.log(error),
    });
  };

  const openModalBack = () => {
    setSMSNotificationsTest(false);
  };
  return (
    <View style={styles.header}>
      <ScrollView showsVerticalScrollIndicator={false}>
        <View style={[styles.positionSticky]}>
          <HeaderToken
            linkBtnBack="iconX.svg"
            actionBtnBack={openModalBack}
            textBtnBack={t('commonText.cancelButton')}
            linkIcon="testSMS.svg"
            textHeader={t('pages_FileManager_SMSNotifications_SMSNotificationsTest.textHeader')}
            widthIconTitle={60}
            heightIconTitle={42}
            fullText
          />
        </View>
        <View style={styles.formSubmit}>
          <View style={styles.borderForm}>
            <Grid>
              <Row size={1}>
                <Col size={3} style={[styles.flexDirection, styles.titleInput]}>
                  <Text style={styles.textTitle}>
                    {t('pages_FileManager_SMSNotifications_SMSNotificationsTest.destinationAddress')}
                  </Text>
                  <Text style={styles.redColor}>✴︎</Text>
                </Col>
                <Col size={9}>
                  <PhoneInput name="toNumber" control={control} borderRadius={5} />
                </Col>
              </Row>
              <Row size={9} style={styles.textArea}>
                <Col size={3} style={[styles.flexDirection, styles.titleInput]}>
                  <Text style={styles.textTitle}>
                    {t('pages_FileManager_SMSNotifications_SMSNotificationsTest.keySetting')}
                  </Text>
                  <Text style={styles.redColor}>✴︎</Text>
                </Col>
                <Col size={9}>
                  <View>
                    <JInput
                      placeholder={`${t(
                        'pages_FileManager_SMSNotifications_SMSNotificationsTest.keySettingPlaceholder'
                      )}`}
                      width="100%"
                      subfix="iconEdit.svg"
                      widthSubfix={18}
                      heightSubfix={18}
                      control={control}
                      name="keySetting"
                      paddingLeft={14}
                      multiline={true}
                      heightMulti={340}
                    />
                  </View>
                </Col>
              </Row>
              <Row size={4}>
                <Col size={3} style={[styles.flexDirection, styles.titleInput]}></Col>
                <Col size={9}>
                  <View style={styles.btnSubmit}>
                    <LinearGradient colors={['#32A5F7', '#2D81F3']} style={{ borderRadius: 5, width: 340 }}>
                      <JButton
                        text={t('pages_FileManager_SMSNotifications_SMSNotificationsTest.buttonTestSMS')}
                        width={340}
                        textColor="#f2f2f2"
                        btnColor={BTN_COLOR_PRIMARY}
                        hasIcon
                        linkIcon="submit.svg"
                        linkIconDisabled="sendGray.svg"
                        widthIcon={18}
                        heightIcon={18}
                        onPressAction={() => handleSubmit(onSubmit)()}
                        disabled={!isValid || !isDirty}
                      />
                    </LinearGradient>
                  </View>
                </Col>
              </Row>
            </Grid>
          </View>
        </View>
        <ModalAlert
          isModalVisible={isModalSave}
          setModalVisible={setIsModalSave}
          modalText={t('pages_FileManager_SMSNotifications_SMSNotificationsTest.modalAlertText')}
          iconWidth={16}
          iconHeight={12.5}
          icon="successGreen.svg"
          // linkPage="/file-manager/other-notifications"
          func={openModalBack}
          onBackdropPressCallback={openModalBack}
        />
      </ScrollView>
    </View>
  );
};
export default withNamespaces()(SMSNotificationsTest);

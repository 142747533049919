import React from 'react';
const DragFileFromLocal = ({ dragAction, children }) => {
  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };
  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      dragAction(e.dataTransfer.files[0]);
    }
  };
  return (
    <div onDragEnter={handleDrag} onDragLeave={handleDrag} onDragOver={handleDrag} onDrop={handleDrop}>
      {children}
    </div>
  );
};
export default DragFileFromLocal;

import { useEffect, useState } from 'react';
import i18n from 'i18next';

const useUpdateLangueForYup = (yupValidate) => {
  const [validate, setValidate] = useState(yupValidate);
  useEffect(() => {
    setValidate(yupValidate);
  }, [i18n.language]);
  return validate;
};
export default useUpdateLangueForYup;

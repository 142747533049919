import React, { useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { StyleSheet, Image, TextInput, View } from 'react-native';
import { TextInputMask } from 'react-native-masked-text';

import { COLOR_TEXT_DEFAULT, INPUT_BG, TEXT_COLOR_HEADER_TABLE } from '@assets/style/styleDefault';
import { JText as Text } from './JText';

const JInput = ({
  colorTextInput = COLOR_TEXT_DEFAULT,
  placeholder,
  width = '100%',
  subfix,
  widthSubfix,
  heightSubfix,
  rightSubfix = 20,
  prefix,
  widthPrefix,
  heightPrefix,
  topPrefix = 10,
  control,
  name,
  backgroundColor = INPUT_BG,
  multiline,
  numberOfMulti,
  heightMulti,
  borderError = '#FF0000',
  paddingLeft = prefix ? 40 : 20,
  paddingRight,
  border = '1px solid transparent',
  marginBottomErr,
  disable = true,
  borderRadius = 5,
  secureTextEntry = false,
  heightInput = 42,
  textAlignInput,
  cursor,
  outlineShow = true,
  onPressEnter = null,
  isNotUseValueProp = false,
  typeMoney = false,
}) => {
  const [isFocus, setFocus] = useState(false);
  const inputRef = useRef();

  const handleKeyPress = (event) => {
    if (event.nativeEvent.key === 'Enter') {
      if (onPressEnter) {
        onPressEnter();
      }
    }
  };

  return (
    <>
      {prefix ? (
        <View style={[styles.relative, { width: width }]}>
          {prefix && (
            <Image
              style={[styles.iconPrefix, { width: widthPrefix, height: heightPrefix, top: topPrefix }]}
              source={require(`../../assets/icons/${prefix}`)}
            />
          )}
          <Controller
            control={control}
            render={({ field: { onChange, onBlur, value, ref }, fieldState: { error, ...rest } }) => (
              <>
                {isNotUseValueProp ? (
                  <TextInput
                    ref={inputRef}
                    placeholderTextColor={TEXT_COLOR_HEADER_TABLE}
                    secureTextEntry={secureTextEntry}
                    caretHidden={secureTextEntry}
                    style={[
                      styles.input2,
                      {
                        backgroundColor: backgroundColor,
                        color: !disable ? '#A5A5A5' : colorTextInput,
                        height: heightInput,
                        outline: isFocus && !error ? '2px solid #00BE85' : 'none',
                      },
                      error ? { border: `1px solid ${borderError}` } : { border: `${border}` },
                      paddingLeft && { paddingLeft: paddingLeft },
                      { cursor: cursor },
                      { fontSize: secureTextEntry ? (value ? '30px' : '14px') : '14px' },
                    ]}
                    onChangeText={onChange}
                    placeholder={placeholder}
                    editable={disable}
                    onFocus={() => {
                      const input = inputRef?.current;
                      setFocus(true);
                      input && !secureTextEntry && input?.select();
                    }}
                    onBlur={() => setFocus(false)}
                    onKeyPress={handleKeyPress}
                  />
                ) : (
                  <TextInput
                    ref={inputRef}
                    placeholderTextColor={TEXT_COLOR_HEADER_TABLE}
                    secureTextEntry={secureTextEntry}
                    caretHidden={secureTextEntry}
                    style={[
                      styles.input2,
                      {
                        backgroundColor: backgroundColor,
                        color: !disable ? '#A5A5A5' : colorTextInput,
                        height: heightInput,
                        outline: outlineShow && isFocus && disable && !error ? '2px solid #00BE85' : 'none',
                      },
                      error ? { border: `1px solid ${borderError}` } : { border: `${border}` },
                      paddingLeft && { paddingLeft: paddingLeft },
                      { cursor: cursor },
                      { fontSize: secureTextEntry ? (value ? '30px' : '14px') : '14px' },
                    ]}
                    onChangeText={onChange}
                    value={value ?? ''}
                    placeholder={placeholder}
                    editable={disable}
                    onFocus={() => {
                      const input = inputRef?.current;
                      setFocus(true);
                      input && !secureTextEntry && input?.select();
                    }}
                    onBlur={() => setFocus(false)}
                    onKeyPress={handleKeyPress}
                  />
                )}
                <Text style={styles.error}>{error?.message}</Text>
              </>
            )}
            name={name}
          />
          {subfix && disable && (
            <Image
              style={[styles.iconSubfix, { width: widthSubfix, height: heightSubfix, right: rightSubfix }]}
              source={require(`../../assets//icons/${subfix}`)}
            />
          )}
        </View>
      ) : (
        <View style={[styles.relative, { width: width }]}>
          <Controller
            control={control}
            render={({ field: { onChange, onBlur, value }, fieldState: { error, ...rest } }) => (
              <>
                {isNotUseValueProp ? (
                  <TextInput
                    placeholderTextColor={TEXT_COLOR_HEADER_TABLE}
                    secureTextEntry={secureTextEntry}
                    style={[
                      styles.input,
                      {
                        backgroundColor: backgroundColor,
                        color: !disable ? '#A5A5A5' : colorTextInput,
                        height: heightInput,
                        outline: outlineShow && isFocus && disable && !error ? '2px solid #00BE85' : 'none',
                      },
                      multiline && { height: heightMulti, paddingTop: '10px' },
                      error ? { border: `1px solid ${borderError}` } : { border: `${border}` },
                      { paddingLeft: paddingLeft },
                      marginBottomErr && {
                        marginBottom: marginBottomErr,
                      },
                      { borderRadius: borderRadius },
                      { textAlign: textAlignInput },
                      paddingRight && { paddingRight: paddingRight },
                      { cursor: cursor },
                    ]}
                    onChangeText={onChange}
                    placeholder={placeholder}
                    multiline={multiline}
                    editable={disable}
                    onFocus={() => setFocus(true)}
                    onBlur={() => setFocus(false)}
                  />
                ) : typeMoney ? (
                  <TextInputMask
                    editable={disable}
                    placeholder={placeholder}
                    style={[
                      styles.input,
                      {
                        backgroundColor: backgroundColor,
                        color: !disable ? '#A5A5A5' : colorTextInput,
                        height: heightInput,
                        outline: outlineShow && isFocus && disable && !error ? '2px solid #00BE85' : 'none',
                      },
                      multiline && { height: heightMulti, paddingTop: '10px' },
                      error ? { border: `1px solid ${borderError}` } : { border: `${border}` },
                      { paddingLeft: paddingLeft },
                      marginBottomErr && {
                        marginBottom: marginBottomErr,
                      },
                      { borderRadius: borderRadius },
                      { textAlign: textAlignInput },
                      paddingRight && { paddingRight: paddingRight },
                      { cursor: cursor },
                    ]}
                    type={'money'}
                    options={{
                      precision: 0,
                      separator: '.',
                      delimiter: ',',
                      unit: '',
                      suffixUnit: '',
                    }}
                    value={value}
                    onChangeText={onChange}
                    onFocus={() => setFocus(true)}
                    onBlur={() => setFocus(false)}
                  />
                ) : (
                  <TextInput
                    placeholderTextColor={TEXT_COLOR_HEADER_TABLE}
                    secureTextEntry={secureTextEntry}
                    style={[
                      styles.input,
                      {
                        backgroundColor: backgroundColor,
                        color: !disable ? '#A5A5A5' : colorTextInput,
                        height: heightInput,
                        outline: outlineShow && isFocus && disable && !error ? '2px solid #00BE85' : 'none',
                      },
                      multiline && { height: heightMulti, paddingTop: '10px' },
                      error ? { border: `1px solid ${borderError}` } : { border: `${border}` },
                      { paddingLeft: paddingLeft },
                      marginBottomErr && {
                        marginBottom: marginBottomErr,
                      },
                      { borderRadius: borderRadius },
                      { textAlign: textAlignInput },
                      paddingRight && { paddingRight: paddingRight },
                      { cursor: cursor },
                    ]}
                    onChangeText={onChange}
                    value={value ?? ''}
                    placeholder={placeholder}
                    multiline={multiline}
                    editable={disable}
                    onFocus={() => setFocus(true)}
                    onBlur={() => setFocus(false)}
                  />
                )}
                <Text style={styles.error}>{error?.message}</Text>
              </>
            )}
            name={name}
          />
          {subfix === 'saveClipboard.svg' ? (
            <Image
              style={[styles.iconSubfix, { width: widthSubfix, height: heightSubfix, right: rightSubfix }]}
              source={require(`../../assets/icons/${subfix}`)}
            />
          ) : subfix ? (
            disable && (
              <Image
                style={[styles.iconSubfix, { width: widthSubfix, height: heightSubfix, right: rightSubfix }]}
                source={require(`../../assets/icons/${subfix}`)}
              />
            )
          ) : null}
        </View>
      )}
    </>
  );
};

const styles = StyleSheet.create({
  relative: {
    position: 'relative',
  },
  input: {
    fontSize: 14,
    fontFamily: 'NotoSansJP',
    borderRadius: 4,
    paddingLeft: 50,
    paddingRight: '3rem',
    outlineStyle: 'none',
    marginHorizontal: 2,
  },
  input2: {
    fontSize: 14,
    fontFamily: 'NotoSansJP',
    borderRadius: 4,
    paddingLeft: 50,
    paddingRight: '3rem',
    outlineStyle: 'none',
    marginHorizontal: 2,
  },
  inputMulti: {
    fontSize: 14,
    paddingLeft: 50,
    borderRadius: 4,
    paddingRight: '3rem',
    outlineStyle: 'none',
    marginHorizontal: 2,
  },
  iconSubfix: {
    position: 'absolute',
    top: 13,
  },
  iconPrefix: {
    position: 'absolute',
    left: 15,
  },
  error: {
    color: 'red',
    fontSize: 12,
    position: 'absolute',
    top: 'calc(100% + 1px)',
    left: 0,
    transform: 'translateZ(0)',
  },
});

export default JInput;

import { combineReducers } from '@reduxjs/toolkit';
import authSlice from './slices/authSlice';
import languageSlice from './slices/LanguageSlice';
import SettingSlice from './slices/SettingSlice';

export default combineReducers({
  merchantUserInfo: authSlice,
  language: languageSlice,
  setting: SettingSlice,
});

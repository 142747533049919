import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { ScrollView, Image, View } from 'react-native';
import { withNamespaces } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

import JButton from '@components/common/JButton';
import JInput from '@components/common/JInput';
import HeaderAccountSetting from '@components/Headers/HeaderAccountSetting';
import styles from './style';
import ModalAlert from '@components/Modals/ModalAlert';
import { JText as Text } from '@components/common/JText';
import { BTN_COLOR_GREY_2, LINE_COLOR } from '@assets/style/styleDefault';
import { LinearGradient } from 'expo-linear-gradient';
import { put } from '@utils/RequestLevica';
import { API_PATH } from '@helper/constant';
import scroll2View from '@helper/scroll2View';

const ChangePassword = ({ t }) => {
  const [modalAlert, setModalAlert] = useState(false);
  const history = useHistory();
  const scrollViewRef = useRef();

  const validationRule = Yup.object().shape({
    password: Yup.string()
      .min(8, t('errorMessages.TXT_VALIDATE_NEW_PASSWORD'))
      .max(32, t('errorMessages.TXT_MAX_32'))
      .matches(/^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?!.* ).{8,}$/, t('errorMessages.TXT_VALIDATE_NEW_PASSWORD'))
      .required(t('errorMessages.TXT_NEW_PASSWORD'))
      .test(
        'special characters',
        t('errorMessages.TXT_VALIDATE_NEW_PASSWORD'),
        (value) => !/[!@#\$%\^\&*\)\(+=._\-\[\]\"\'\`\~\>\<\,\?\\\/\|\:\;]/.test(value)
      ),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password'), null], t('errorMessages.TXT_CONFIRM_PASSWORD'))
      .required(t('errorMessages.TXT_INPUT_CONFIRM_PASSWORD')),
  });

  const {
    control,
    formState: { errors, isDirty, isValid },
    handleSubmit,
    reset,
    setError,
  } = useForm({
    defaultValues: {
      password: '',
      confirmPassword: '',
    },
    resolver: yupResolver(validationRule),
    mode: 'onChange',
  });

  const changePage = () => {
    history.push('/admin');
    reset({
      password: '',
      confirmPassword: '',
    });
  };

  const onSubmit = async (newPass) => {
    try {
      const data = await put(`${API_PATH.ADMIN_INFO}/changePassword`, newPass);
      console.log(data);
      setModalAlert(true);
    } catch (error) {
      if (error?.response?.data?.errors[0]?.code === '1631') {
        setError('password', {
          type: 'server',
          message: '',
        });
        setError('confirmPassword', {
          type: 'server',
          message: t('errorMessages.TEXT_NEWPASS_SAME_OLD_PASS'),
        });
        scroll2View('password', scrollViewRef.current);
      } else {
        setError('password', {
          type: 'server',
          message: '',
        });
        setError('confirmPassword', {
          type: 'server',
          message: t('errorMessages.TXT_OTHER_ERROR'),
        });
        scroll2View('password', scrollViewRef.current);
      }
    }
  };

  useEffect(() => {
    scroll2View(Object.keys(errors)[0], scrollViewRef.current);
  }, [errors]);

  return (
    <ScrollView ref={scrollViewRef}>
      <HeaderAccountSetting textHeader="ADMIN ACCOUNT" />
      <View style={styles.content}>
        <View style={styles.headerContent}>
          <Image style={styles.iconFix} source={require(`@assets/icons/iconSetting2.svg`)} />
          <Text style={styles.textLabel}>{t('pages_Setting_ResetPassword.textHeader')}</Text>
        </View>
        <View style={styles.formPass}>
          <View style={styles.rowContent} nativeID="password">
            <Text style={[styles.textLabel, { marginBottom: 10 }]}>{t('pages_Setting_ResetPassword.newPassword')}</Text>
            <JInput
              secureTextEntry={true}
              placeholder={t('pages_Setting_ResetPassword.newPasswordPlaceholder')}
              subfix="iconEdit.svg"
              widthSubfix={15}
              heightSubfix={15}
              prefix="iconLock.svg"
              heightPrefix={20}
              widthPrefix={17}
              control={control}
              name="password"
              border={`1px solid ${LINE_COLOR}`}
            />
          </View>
          <View style={styles.rowContent} nativeID="confirmPassword">
            <Text style={[styles.textLabel, { marginBottom: 10 }]}>
              {t('pages_Setting_ResetPassword.confirmPassword')}
            </Text>
            <JInput
              secureTextEntry={true}
              placeholder={t('pages_Setting_ResetPassword.confirmPasswordPlaceholder')}
              subfix="iconEdit.svg"
              widthSubfix={15}
              heightSubfix={15}
              prefix="iconLock.svg"
              heightPrefix={20}
              widthPrefix={17}
              control={control}
              name="confirmPassword"
              border={`1px solid ${LINE_COLOR}`}
            />
          </View>
        </View>
        <View style={styles.notify}>
          <Text style={styles.textLabel}>{t('pages_Setting_ResetPassword.passwordValidate')}</Text>
        </View>
      </View>
      <View style={styles.buttonGroup}>
        <JButton
          width={200}
          text={t('commonText.cancelButton')}
          textColor="#fff"
          btnColor={BTN_COLOR_GREY_2}
          style={{ marginRight: 20 }}
          onPressAction={changePage}
        />
        <LinearGradient colors={['#54DBB2', '#54DBB2']} style={{ borderRadius: 5 }}>
          <JButton
            width={200}
            text={t('pages_Setting_ResetPassword.buttonChangePass')}
            textColor="white"
            // disabled={!isValid || !isDirty}
            onPressAction={handleSubmit(onSubmit)}
          />
        </LinearGradient>
      </View>
      <ModalAlert
        isModalVisible={modalAlert}
        setModalVisible={setModalAlert}
        icon="checkGreen.svg"
        iconWidth={20}
        iconHeight={16}
        modalText={t('pages_Setting_ResetPassword.modalAlertText')}
        func={() => changePage()}
      />
    </ScrollView>
  );
};

export default withNamespaces()(ChangePassword);

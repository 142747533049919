import React, { useState, useEffect, useRef } from 'react';
import { View, ScrollView, Image, Linking, TextInput } from 'react-native';
import { Pressable } from 'native-base';
import { useFieldArray, useForm } from 'react-hook-form';
import { Col, Grid, Row } from 'react-native-easy-grid';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import * as DocumentPicker from 'expo-document-picker';
import { LinearGradient } from 'expo-linear-gradient';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { withNamespaces } from 'react-i18next';
import { useMutation, useQuery } from '@tanstack/react-query';

import HeaderTokenTwoOption from '@components/Headers/HeaderTokenTwoOption';
import styles from './style';
import JInput from '@components/common/JInput';
import ModalAlert from '@components/Modals/ModalAlert';
import ModalBack from '@components/Modals/ModalBack';
import {
  COLOR_TEXT_DEFAULT,
  INPUT_BG,
  LINE_COLOR,
  TEXT_COLOR_GREEN,
  TEXT_COLOR_GREY,
} from '@assets/style/styleDefault';
import JButton from '@components/common/JButton';
import { JText as Text } from '@components/common/JText';
import MailNotificationsTest from './MailNotificationsTest';
import { API_PATH } from '@helper/constant';
import { get, postMulti } from '@utils/RequestLevica';
import DragFileFromLocal from '@components/common/DragFileFromLocal';
import scroll2View from '@helper/scroll2View';

const emailDefaultValue = {
  type: 1,
  mailTitle: '',
  subject: '',
  senderMailAddress: '',
  bccMailAddress: '',
  isSelected: true,
  languageCd: 1,
  mailContentTemplate: '',
  sign: '',
  attachments: [],
};

const MAX_SIZE_OF_MAIL = 10 * 1024 * 1024;

const MailNotifications = ({ t }) => {
  const { mailId } = useParams();
  const history = useHistory();
  const location = useLocation();
  const [isModal, setIsModal] = useState(false);
  const [isModalSave, setIsModalSave] = useState(false);
  const [isModalBack, setIsModalBack] = useState(false);
  const [isMailNotificationsTest, setMailNotificationsTest] = useState(false);
  const [fileSize, setFileSize] = useState(0);
  const [inputValue, setInputValue] = useState('');
  const [mailBccError, setMailBccError] = useState(false);
  const [bccEmail, setBccEmail] = useState([]);
  const inputRef = useRef(null);
  const scrollViewRef = useRef();
  const isUpdate = location.pathname.includes('update');

  const validateEmails = Yup.string()
    .email(t('errorMessages.TXT_EMAIL_REQUIRED'))
    .max(32, t('errorMessages.TXT_MAX_32'));

  const schema = Yup.object().shape({
    mailTitle: Yup.string()
      .trim()
      .nullable()
      .required(t('errorMessages.fieldRequired', { fieldName: t('pages_FileManager_MailNotifications.emailName') }))
      .max(128, t('errorMessages.TXT_MAX_128')),
    subject: Yup.string()
      .trim()
      .nullable()
      .required(t('errorMessages.fieldRequired', { fieldName: t('pages_FileManager_MailNotifications.subject') }))
      .max(128, t('errorMessages.TXT_MAX_128')),
    mailContentTemplate: Yup.string()
      .trim()
      .nullable()
      .required(t('errorMessages.fieldRequired', { fieldName: t('pages_FileManager_MailNotifications.mailBody') })),
    sign: Yup.string().nullable().trim().max(1000, t('errorMessages.TXT_MAX_1000')),
  });

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { isDirty, isValid, errors },
    watch,
  } = useForm({
    defaultValues: emailDefaultValue,
    resolver: yupResolver(schema),
    mode: 'onChange',
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'attachments',
  });
  const openModalBack = () => {
    if (isDirty || isValid) {
      setIsModalBack(!isModalBack);
    } else {
      history.push('/file-manager/other-notifications');
    }
  };

  const handleClick = () => {
    setMailNotificationsTest(true);
  };

  const pickFile = async () => {
    const index = watch('attachments')?.length ? watch('attachments')?.length : 0;
    const emailAttachmentsData = watch('emailAttachmentsData');
    const url = emailAttachmentsData ? emailAttachmentsData[index]?.file : false;
    if (url) {
      Linking.openURL(url);
      return;
    }
    if (mailId) return;

    let result = await DocumentPicker.getDocumentAsync({
      type: '*/*',
    });
    const { file, size, name, cancelled } = result;

    if (cancelled) {
      return;
    } else {
      if (fileSize + size > MAX_SIZE_OF_MAIL) {
        alert(t('pages_FileManager_MailNotifications.maxMailSize'));
        return;
      }

      append({ name, size });
      setFileSize((prevFileSize) => prevFileSize + size);
      setValue(`attachments.${index}.name`, name);
      setValue(`attachments.${index}.file`, file);
      setValue(`attachments.${index}.size`, size);
      if (isUpdate) {
        const updatedEmailAttachmentData = [...emailAttachmentsData, { name: name, size: size }];
        setValue('emailAttachmentsData', updatedEmailAttachmentData);
      }
    }
  };

  const changeFileAttachment = async (index) => {
    const emailAttachmentsData = watch('emailAttachmentsData');
    const url = emailAttachmentsData ? emailAttachmentsData[index]?.file : false;
    if (!!url) {
      Linking.openURL(url);
      return;
    }
    if (mailId) return;

    let result = await DocumentPicker.getDocumentAsync({
      type: '*/*',
    });
    const { file, size, name } = result;
    if (result.cancelled) {
      return;
    } else {
      if (fileSize + size > MAX_SIZE_OF_MAIL) {
        alert(t('pages_FileManager_MailNotifications.maxMailSize'));
        return;
      }
      setFileSize((prevFileSize) => prevFileSize + size);
      setValue(`attachments.${index}.name`, name);
      setValue(`attachments.${index}.file`, file);
      setValue(`attachments.${index}.size`, size);
      if (isUpdate) {
        watch('emailAttachmentsData').push({ name, size });
      }
    }
  };
  const { mutate: createMailNotification } = useMutation({
    mutationFn: async (data) => await postMulti(API_PATH.TEMPLATES_EMAIL, data),
    retry: 3,
  });

  const getMails = async ({ signal }) => {
    const mails = await get(`${API_PATH.TEMPLATES_EMAIL}?type=${1}`, { signal });
    return mails?.emailTemplateDataList;
  };

  const { remove: removeUseQuery } = useQuery(['getMails'], getMails, {
    enabled: isUpdate,
    onSuccess: (mails) => {
      const mailSelect = mails.filter((item) => item.isSelected)?.[0];
      if (mailSelect?.emailAttachmentsData?.length > 0) {
        const totalSizeAttachments = mailSelect.emailAttachmentsData.reduce(
          (accumulator, currentValue) => accumulator + currentValue.size,
          0
        );
        setFileSize(totalSizeAttachments);
        mailSelect.emailAttachmentsData?.forEach((item) => {
          append({ file: item.file, name: item.name, size: item.size });
        });
      }
      if (mailSelect?.bccMailAddress) {
        setBccEmail(mailSelect.bccMailAddress.split(','));
      }
      reset(mailSelect);
    },
    refetchOnWindowFocus: false,
  });
  const onSubmit = (data) => {
    const newData = { ...data, type: 1, isSelected: true };
    const formData = new FormData();
    if (newData?.bccMailAddress) {
      const emails = newData?.bccMailAddress.split(',').map((email) => email.trim());
      formData.append('bccMailAddress', emails);
    }
    formData.append('isSelected', newData?.isSelected);
    formData.append('languageCd', newData?.languageCd);
    formData.append('mailContentTemplate', newData?.mailContentTemplate);
    formData.append('mailTitle', newData?.mailTitle);
    formData.append('sign', newData?.sign);
    formData.append('subject', newData?.subject);
    formData.append('type', newData?.type);
    if (isUpdate) {
      const orderFileAttachmentIds = newData?.emailAttachmentsData
        ?.filter((item) => item.id)
        .map((item) => item.id)
        .join(',');

      formData.append('availableFileIds', orderFileAttachmentIds);
    }
    if (fileSize > 0) {
      const newArray = newData?.attachments?.filter((item) => item.size);
      newArray?.map((item) => {
        formData.append('attachments', item?.file);
      });
    }

    createMailNotification(formData, {
      onSuccess: () => {
        isModalBack ? setIsModal(true) : setIsModalSave(true);
        setIsModalBack(false);
        setMailBccError(false);
        if (!isUpdate) {
          reset();
        }
      },
      onError: (error) => console.log(error),
    });
  };

  useEffect(() => {
    if (!mailId) return;
    const {
      emailAttachmentsData = [],
      bccMailAddress,
      mailContentTemplate,
      mailTitle,
      sign,
      subject,
    } = location.state || {};
    if (emailAttachmentsData?.length > 0) {
      remove(0);
      emailAttachmentsData?.forEach((item) => {
        append({ file: item.file, name: item.name, size: item.size });
      });
    }

    if (!!bccMailAddress) {
      setBccEmail(bccMailAddress?.split(','));
    } else {
      setBccEmail(false);
    }
    setValue('bccMailAddress', bccMailAddress);
    setValue('mailContentTemplate', mailContentTemplate);
    setValue('mailTitle', mailTitle);
    setValue('subject', subject);
    setValue('sign', sign);
  }, [location.state, mailId]);

  const removeItem = (index) => {
    if (isUpdate) {
      const newSize = fileSize - watch('emailAttachmentsData')[index]?.size;
      setFileSize(newSize);
      watch('emailAttachmentsData')?.splice(index, 1);
      remove(index);
    } else {
      const newSize = fileSize - watch('attachments')[index]?.size;
      setFileSize(newSize);
      remove(index);
    }
  };

  const dragAction = async (file) => {
    const attachments = watch('attachments');
    const { name, size } = file[0];
    const index = attachments?.length ? attachments?.length : 0;

    if (fileSize + size > MAX_SIZE_OF_MAIL) {
      alert(t('pages_FileManager_MailNotifications.maxMailSize'));
      return;
    }
    setFileSize((prevFileSize) => prevFileSize + size);
    append({ file: '', name, size });
    setValue(`attachments.${index}.name`, name);
    setValue(`attachments.${index}.file`, file[0]);
    setValue(`attachments.${index}.size`, size);
    if (isUpdate) {
      watch('emailAttachmentsData').push({ name, size });
    }
  };
  useEffect(() => removeUseQuery, [removeUseQuery]);

  const handleAddTag = () => {
    if (!validateEmails.isValidSync(inputValue)) {
      setMailBccError(t('errorMessages.TXT_EMAIL_REQUIRED'));
      return;
    }
    if (inputValue?.length > 0) {
      const checkTagAlready = bccEmail.findIndex((item) => item === inputValue);
      if (checkTagAlready >= 0) {
        setMailBccError(t('errorMessages.TXT_EMAIL_ALREADY_EXISTS'));
        return;
      }

      setBccEmail([...bccEmail, inputValue]);
      setValue('bccMailAddress', [...bccEmail, inputValue].toString(), { shouldDirty: true });
      setInputValue('');
      setMailBccError(false);
      // inputRef.current.focus();
    }
  };

  const bccInput = (value) => {
    setInputValue(value);
    validateEmails.isValidSync(inputValue)
      ? setMailBccError(false)
      : value?.length === 0
      ? setMailBccError(false)
      : setMailBccError(t('errorMessages.TXT_EMAIL_REQUIRED'));
  };

  const handleRemoveTag = (index) => {
    if (mailId) return;
    const newBccEmails = [...bccEmail];
    newBccEmails.splice(index, 1);
    setValue('bccMailAddress', newBccEmails.toString(), { shouldDirty: true });
    setBccEmail(newBccEmails);
  };
  useEffect(() => {
    scroll2View(Object.keys(errors)[0], scrollViewRef.current);
  }, [errors]);

  return (
    <>
      {isMailNotificationsTest ? (
        <MailNotificationsTest
          fileSize={fileSize}
          isUpdate={isUpdate}
          setMailNotificationsTest={setMailNotificationsTest}
          mailContent={watch()}
        />
      ) : (
        <View style={styles.header}>
          <View style={[styles.positionSticky]}>
            <HeaderTokenTwoOption
              handleModalBack={setIsModalBack}
              isDirty={mailId ? false : isDirty}
              linkBtnBack="iconX.svg"
              actionBtnBack={openModalBack}
              textBtnBack={t('commonText.cancelButton')}
              linkIcon="mail.svg"
              textHeader={t('pages_FileManager_MailNotifications.textHeader')}
              widthIconTitle={36}
              heightIconTitle={36}
              scope1={
                mailId ? null : (
                  <LinearGradient
                    colors={['#32A5F7', '#2D81F3']}
                    style={{ borderRadius: 5, width: 170, marginRight: 15 }}
                  >
                    <JButton
                      disabled={!isValid}
                      text={t('pages_FileManager_MailNotifications.buttonTestSMS')}
                      width={170}
                      textColor="white"
                      onPressAction={handleClick}
                    />
                  </LinearGradient>
                )
              }
              scope2={
                mailId ? null : (
                  <LinearGradient colors={['#54DBB2', '#04C48B']} style={{ borderRadius: 5, width: 170 }}>
                    <JButton
                      text={t('commonText.buttonSave')}
                      disabled={!isDirty || !isValid}
                      width={170}
                      textColor="white"
                      hasIcon
                      linkIcon="iconDownload.svg"
                      linkIconDisabled="iconDownloadGrey.svg"
                      widthIcon={18}
                      heightIcon={18}
                      onPressAction={() => handleSubmit(onSubmit)()}
                    />
                  </LinearGradient>
                )
              }
            />
          </View>
          <ScrollView showsVerticalScrollIndicator={false}>
            <View style={[styles.borderForm]}>
              <Grid>
                <Row size={1} style={styles.borderBottomWhite}>
                  <Col size={3} style={[styles.flexDirection, styles.titleInput]}>
                    <Text style={styles.whiteColor}>{t('pages_FileManager_MailNotifications.emailName')} </Text>
                    <Text style={styles.redColor}>✴︎</Text>
                  </Col>
                  <Col size={9}>
                    <View>
                      <JInput
                        placeholder={t('pages_FileManager_MailNotifications.emailNamePlaceholder')}
                        prefix="mailInput.svg"
                        widthPrefix={22}
                        heightPrefix={22}
                        subfix="iconEdit.svg"
                        widthSubfix={18}
                        heightSubfix={18}
                        control={control}
                        name="mailTitle"
                        disable={!mailId}
                      />
                    </View>
                  </Col>
                </Row>
                <Row size={1} style={styles.borderBottomWhite}>
                  <Col size={3} style={[styles.flexDirection, styles.titleInput]}>
                    <Text style={styles.whiteColor}>{t('pages_FileManager_MailNotifications.subject')}</Text>
                    <Text style={styles.redColor}>✴︎</Text>
                  </Col>
                  <Col size={9}>
                    <View>
                      <JInput
                        placeholder={t('pages_FileManager_MailNotifications.subject')}
                        subfix="iconEdit.svg"
                        widthSubfix={18}
                        heightSubfix={18}
                        control={control}
                        name="subject"
                        paddingLeft={14}
                        disable={!mailId}
                      />
                    </View>
                  </Col>
                </Row>
                <Row size={1} style={styles.borderBottomWhite}>
                  <Col size={3} style={[styles.flexDirection, styles.titleInput]}>
                    <Text style={styles.whiteColor}>{t('pages_FileManager_MailNotifications.sourceAddress')} </Text>
                    <Text style={styles.redColor}>✴︎</Text>
                  </Col>
                  <Col size={9}>
                    <View>
                      <JInput
                        placeholder={t('pages_FileManager_MailNotifications.sourceAddressPlaceholder')}
                        subfix="iconEdit.svg"
                        widthSubfix={18}
                        heightSubfix={18}
                        control={control}
                        name="senderMailAddress2"
                        paddingLeft={14}
                        disable={false}
                      />
                    </View>
                  </Col>
                </Row>
                <Row size={1} style={styles.borderBottomWhite}>
                  <Col size={3} style={[styles.flexDirection, styles.titleInput]}>
                    <Text style={styles.whiteColor}>{t('pages_FileManager_MailNotifications.bccAddress')} </Text>
                  </Col>
                  <Col size={9}>
                    {bccEmail ? (
                      <View style={styles.container}>
                        {mailId ? null : (
                          <View>
                            <View>
                              <TextInput
                                style={styles.input}
                                onChangeText={(value) => bccInput(value)}
                                value={inputValue}
                                placeholder={t('pages_FileManager_MailNotifications.bccAddressPlaceholder')}
                                onSubmitEditing={handleAddTag}
                                ref={inputRef}
                              />
                              {mailBccError && <Text style={styles.textError}>{mailBccError}</Text>}
                            </View>
                            <Image style={[styles.iconSubfix]} source={require(`@assets/icons/iconEdit.svg`)} />
                          </View>
                        )}
                        <ScrollView>
                          <View style={styles.warperBcc}>
                            {bccEmail.map((item, index) => (
                              <Pressable key={index} onPress={() => handleRemoveTag(index)}>
                                {({ isHovered }) => {
                                  return (
                                    <View
                                      style={[
                                        styles.tag,
                                        {
                                          backgroundColor: isHovered ? (mailId ? INPUT_BG : TEXT_COLOR_GREY) : INPUT_BG,
                                        },
                                      ]}
                                    >
                                      <Text style={{ color: mailId ? '#a5a5a5' : COLOR_TEXT_DEFAULT }}>{item}</Text>
                                      {mailId ? null : (
                                        <Image
                                          style={styles.imageRemoveBccEmail}
                                          source={require(`@assets/icons/XBackGreen.svg`)}
                                        />
                                      )}
                                    </View>
                                  );
                                }}
                              </Pressable>
                            ))}
                          </View>
                        </ScrollView>
                      </View>
                    ) : (
                      <JInput
                        placeholder={t('pages_FileManager_MailNotifications.notBccAddressPlaceholder')}
                        subfix="iconEdit.svg"
                        widthSubfix={18}
                        heightSubfix={18}
                        control={control}
                        name="bccMailAddress"
                        paddingLeft={14}
                        disable={!mailId}
                      />
                    )}
                  </Col>
                </Row>
                <Row
                  size={1}
                  style={[
                    styles.borderBottomWhite,
                    { paddingVertical: 20, paddingBottom: mailId && fields?.length > 0 ? 0 : 20 },
                  ]}
                >
                  <Col size={3} style={[styles.flexDirection, styles.titleInput]}>
                    <Text style={styles.whiteColor}>{t('pages_FileManager_MailNotifications.attachments')} </Text>
                  </Col>
                  <Col size={9}>
                    <>
                      {fields.map((item, index) => (
                        <View key={item.id} style={styles.inputFile}>
                          <View style={{ flex: 1 }} onClick={() => changeFileAttachment(index)}>
                            <JInput
                              placeholder={t('pages_FileManager_MailNotifications.pickImages')}
                              subfix="iconEdit.svg"
                              widthSubfix={18}
                              heightSubfix={18}
                              control={control}
                              name={`attachments.${index}.name`}
                              paddingLeft={14}
                              disable={false}
                              cursor={mailId ? 'text' : 'pointer'}
                            />
                          </View>
                          {mailId ? null : (
                            <Image
                              onClick={() => removeItem(index)}
                              style={styles.imageRemove}
                              source={require(`@assets/icons/XBackGreen.svg`)}
                            />
                          )}
                        </View>
                      ))}
                    </>
                    <View>
                      <DragFileFromLocal dragAction={mailId ? () => {} : dragAction}>
                        {mailId ? (
                          <>
                            {fields?.length > 0 ? null : (
                              <Text style={{ color: '#a5a5a5', paddingLeft: 15 }}>
                                {t('pages_FileManager_MailNotifications.noAttachmentFile')}
                              </Text>
                            )}
                          </>
                        ) : (
                          <JButton
                            text={t('pages_FileManager_MailNotifications.pickImages')}
                            btnColor={INPUT_BG}
                            textColor={TEXT_COLOR_GREEN}
                            onPressAction={() => pickFile()}
                            borderButton="#757575"
                            iconSubfix="iconEdit.svg"
                            widthSubfix={18}
                            heightSubfix={18}
                            flexStart
                            justifyContent="space-between"
                          />
                        )}
                      </DragFileFromLocal>
                    </View>
                  </Col>
                </Row>
                <Row size={8} style={styles.margin12}>
                  <Col size={3} style={[styles.flexDirection, styles.titleInput]}>
                    <Text style={styles.whiteColor}>{t('pages_FileManager_MailNotifications.mailBody')}</Text>
                    <Text style={styles.redColor}>✴︎</Text>
                  </Col>
                  <Col size={9}>
                    <View style={styles.borderFormTextArea}>
                      <View style={styles.inputArea}>
                        <JInput
                          placeholder={t('pages_FileManager_MailNotifications.mailBodyPlaceholder')}
                          subfix="iconEdit.svg"
                          widthSubfix={18}
                          heightSubfix={18}
                          control={control}
                          name="mailContentTemplate"
                          multiline={true}
                          heightMulti={340}
                          disable={!mailId}
                          border={`1px solid ${LINE_COLOR}`}
                        />
                      </View>
                      <View style={styles.inputTextArea}>
                        <JInput
                          placeholder={`${t('pages_FileManager_MailNotifications.thisArticleNotePlaceholder')}`}
                          subfix="iconEdit.svg"
                          widthSubfix={18}
                          heightSubfix={18}
                          control={control}
                          name="sign"
                          multiline={true}
                          heightMulti={140}
                          border={`1px solid ${LINE_COLOR}`}
                          disable={!mailId}
                        />
                      </View>
                    </View>
                  </Col>
                </Row>
              </Grid>
            </View>
          </ScrollView>
          <ModalAlert
            isModalVisible={isModalSave}
            setModalVisible={setIsModalSave}
            modalText={t('pages_FileManager_MailNotifications.modalAlertText1')}
            iconWidth={16}
            iconHeight={12.5}
            icon="successGreen.svg"
            linkPage="/file-manager/other-notifications"
          />
          <ModalAlert
            isModalVisible={isModal}
            setModalVisible={setIsModal}
            modalText={t('pages_FileManager_MailNotifications.modalAlertText2')}
            iconWidth={16}
            iconHeight={12.5}
            icon="successGreen.svg"
            linkPage="/file-manager/other-notifications"
          />
          <ModalBack
            linkPage="/file-manager/other-notifications"
            isModal={isModalBack}
            setIsModal={setIsModalBack}
            otherModal={() => handleSubmit(onSubmit)()}
          />
        </View>
      )}
    </>
  );
};
export default withNamespaces()(MailNotifications);

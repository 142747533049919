import React, { useState, useEffect } from 'react';
import { ScrollView, View, Image, TouchableWithoutFeedback } from 'react-native';
import { Grid, Row, Col } from 'react-native-easy-grid';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Pressable } from 'native-base';
import { withNamespaces } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';

import ModalLanguage from './modal/ModalLanguage';
import { JText as Text } from '@components/common/JText';
import ModalLogout from '@components/Modals/ModalLogout';
import styles from './style';
import { COLOR_TEXT_DEFAULT, TEXT_COLOR_GREEN } from '@assets/style/styleDefault';
import { converTextCamelCase, statusColor } from '@helper/formatTypes';
import { API_PATH } from '@helper/constant';
import { get } from '@utils/RequestLevica';
import Loading from '@components/common/Loading';

const SettingInfo = ({ t }) => {
  const { language } = useSelector((state) => state.language);
  const [modalLanguage, setModalLanguage] = useState(false);
  const [isModalLogout, setIsModalLogout] = useState(false);
  const userInfo = useSelector((state) => state.merchantUserInfo);

  const getAdminList = async () => {
    return await get(API_PATH.ADMIN_LIST);
  };
  const {
    remove,
    data: adminList,
    isFetching,
  } = useQuery(['getAdminList'], getAdminList, {
    refetchOnWindowFocus: false,
  });
  useEffect(() => remove, [remove]);
  if (isFetching) {
    return <Loading />;
  }
  return (
    <>
      <View style={styles.headerInfo}>
        <Image style={styles.iconSetting} source={require(`@assets/icons/setting.svg`)} />
        <Text style={styles.textHeader}>{t('pages_Setting_Info.setting')}</Text>
      </View>

      <Grid style={styles.content}>
        <Col size={8}>
          <ScrollView showsVerticalScrollIndicator={false} showsHorizontalScrollIndicator={false}>
            <Row size={1} style={styles.rowContent}>
              <Col size={3}>
                <Text style={styles.textLabel}>{t('pages_Setting_Info.systemName')}</Text>
              </Col>
              <Col size={6}>
                <Text style={styles.textLabel}>Xxxxxxxx Primary System</Text>
              </Col>
            </Row>
            <Row size={1} style={styles.rowContent}>
              <Col size={3}>
                <Text style={styles.textLabel}>{t('pages_Setting_Info.modelNumber')}</Text>
              </Col>
              <Col size={6}>
                <Text style={styles.textLabel}>XXXXX</Text>
              </Col>
            </Row>
            <Row size={1} style={styles.rowContent}>
              <Col size={3}>
                <Text style={styles.textLabel}>{t('pages_Setting_Info.serialNumber')}</Text>
              </Col>
              <Col size={6}>
                <Text style={styles.textLabel}>XXXXXXXXX</Text>
              </Col>
            </Row>
            <Row size={1} style={styles.rowContent}>
              <Col size={3}>
                <Text style={styles.textLabel}>{t('pages_Setting_Info.version')}</Text>
              </Col>
              <Col size={6}>
                <Text style={styles.textLabel}>02.01.17</Text>
              </Col>
            </Row>
            <Row size={1} style={styles.rowContent}>
              <Col size={3}>
                <Text style={styles.textLabel}>{t('pages_Setting_Info.software')}</Text>
              </Col>
              <Col size={6}>
                <Text style={styles.textLabel}>お使いのプライマリソフトウェアは最新です</Text>
              </Col>
            </Row>
            <Row size={1} style={styles.rowContent}>
              <Col size={3}>
                <Text style={styles.textLabel}>{t('pages_Setting_Info.blockchain')}</Text>
              </Col>
              <Col size={6}>
                <Text style={styles.textLabel}>XXXXXXXXX Chain 2.00</Text>
              </Col>
            </Row>
            <Row size={1} style={styles.rowContent}>
              <Col size={3}>
                <Text style={styles.textLabel}>{t('pages_Setting_Info.cloudServer')}</Text>
              </Col>
              <Col size={6}>
                <Text style={styles.textLabel}>XXXXXXXXX Web Services</Text>
              </Col>
            </Row>
            <Row size={1} style={styles.rowContent}>
              <Col size={3}>
                <Text style={styles.textLabel}>{t('pages_Setting_Info.privateNetworkAdministrator')}</Text>
              </Col>
              <Col size={6}>
                <Text style={styles.textLabel}>XXXXXXXXX inc.</Text>
              </Col>
            </Row>
            <Row size={1} style={styles.rowContent}>
              <Col size={3}>
                <Text style={styles.textLabel}>{t('pages_Setting_Info.numberOfAdminAccounts')}</Text>
              </Col>
              <Col size={6}>
                <Text style={styles.textLabel}>
                  {adminList
                    ? `${adminList?.filter((item) => item.status === 0).length ?? ''} / ${adminList?.length ?? ''}`
                    : null}
                </Text>
              </Col>
            </Row>
            <Row size={1} style={styles.rowContent}>
              <Col size={3}>
                <Text style={styles.textLabel}>{t('pages_Setting_Info.language')}</Text>
              </Col>
              <Col size={6} onClick={() => setModalLanguage(true)}>
                <View style={styles.colBetween}>
                  <Text style={styles.textLabel}>{converTextCamelCase(language)}</Text>
                  <Image style={styles.iconRight} source={require(`@assets/icons/arrowRight.svg`)} />
                </View>
              </Col>
            </Row>
            <Row size={1} style={[styles.rowContent, { borderBottomWidth: 0 }]}>
              <Col size={3}>
                <Text style={styles.textLabel}>{t('pages_Setting_Info.timeZone')}</Text>
              </Col>
              <Col size={6} style={styles.colBetween}>
                <Text style={styles.textLabel}>東京, 日本（GMT+09:00）</Text>
              </Col>
            </Row>
          </ScrollView>
        </Col>

        <Col size={4}>
          <ScrollView showsVerticalScrollIndicator={false} showsHorizontalScrollIndicator={false}>
            <View style={styles.wrapperAvatar}>
              <View style={styles.headerAvatar}>
                <Text style={styles.textLabel}>Admin type</Text>
                <View style={styles.headerAvatarStatus}>
                  {statusColor(userInfo?.masterType) ? (
                    <View style={[styles.status, { backgroundColor: statusColor(userInfo?.masterType) }]} />
                  ) : null}
                  <Text style={styles.textLabel}>{converTextCamelCase(userInfo?.masterType)}</Text>
                </View>
              </View>
              <View style={[styles.avatarContent, styles.flexRow]}>
                {userInfo?.imageUrl ? (
                  <Image style={styles.imgAvatar} source={{ uri: userInfo?.imageUrl }} />
                ) : (
                  <Image style={styles.imgAvatar} source={require(`@assets/image/userDefaultAvatar.jpg`)} />
                )}
                <Text style={styles.textName} numberOfLines={3}>
                  {userInfo?.name}
                </Text>
              </View>
              <Pressable>
                {({ isHovered }) => {
                  return (
                    <Link to="/admin" style={{ textDecorationLine: 'none', marginTop: '1rem' }}>
                      <View style={[styles.flexRow, { justifyContent: 'center' }]}>
                        <Image
                          style={styles.iconNote}
                          source={require(`../../../assets/icons/${isHovered ? 'iconNoteGreen.svg' : 'iconNote.svg'}`)}
                        />
                        <Text style={{ color: isHovered ? TEXT_COLOR_GREEN : COLOR_TEXT_DEFAULT }}>
                          {t('pages_Setting_Info.accountDetails')}
                        </Text>
                      </View>
                    </Link>
                  );
                }}
              </Pressable>
              <View style={styles.infoUser}>
                <View style={styles.rowInfo}>
                  <Text style={styles.textLabel}>{t('pages_Setting_Info.userNumber')}</Text>
                  <Text style={styles.textLabel}>{userInfo?.levicaId}</Text>
                </View>
                <View style={styles.rowInfo}>
                  <Text style={styles.textLabel}>{t('pages_Setting_Info.primaryID')}</Text>
                  <Text style={styles.textLabel}>{userInfo?.walletAddress}</Text>
                </View>
                <View style={styles.rowInfo}>
                  <Text style={styles.textLabel}>{t('pages_Setting_Info.email')}</Text>
                  <Text style={styles.textLabel}>{userInfo?.email}</Text>
                </View>
                <View style={styles.rowInfo}>
                  <Text style={styles.textLabel}>{t('pages_Setting_Info.phone')}</Text>
                  <Text style={styles.textLabel}>{userInfo?.phone}</Text>
                </View>
              </View>
              <TouchableWithoutFeedback>
                <Pressable>
                  {({ isHovered }) => {
                    return (
                      <View style={styles.wrapperLogout} onClick={() => setIsModalLogout(true)}>
                        <Image
                          style={styles.iconLogout}
                          source={require(`../../../assets/icons/${isHovered ? 'logoutGreen.svg' : 'logout.svg'}`)}
                        />
                        <Text style={{ color: isHovered ? TEXT_COLOR_GREEN : COLOR_TEXT_DEFAULT }}>
                          {t('pages_Setting_Info.logout')}
                        </Text>
                      </View>
                    );
                  }}
                </Pressable>
              </TouchableWithoutFeedback>
            </View>
          </ScrollView>
        </Col>
      </Grid>
      <ModalLanguage isModalVisible={modalLanguage} setModalVisible={setModalLanguage} currentLanguage={language} />
      {isModalLogout && <ModalLogout isModal={isModalLogout} setIsModal={setIsModalLogout} />}
    </>
  );
};

export default withNamespaces()(SettingInfo);

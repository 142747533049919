import { StyleSheet } from 'react-native';
import { BG_COLOR, BG_FORM_DEFAULT, LINE_COLOR } from '@assets/style/styleDefault';

const styles = StyleSheet.create({
  header: {
    height: '100%',
    flex: 1,
    backgroundColor: BG_COLOR,
    position: 'relative',
  },
  positionSticky: {
    position: 'sticky',
    top: 0,
    backgroundColor: BG_COLOR,
    zIndex: 10,
    marginRight: 15,
  },
  borderForm: {
    paddingTop: 10,
    paddingLeft: 20,
    paddingRight: '15vw',
    paddingBottom: 10,
    backgroundColor: BG_FORM_DEFAULT,
    height: 'calc(100vh - 80px)',
    minHeight: 700,
    marginRight: 20,
    minWidth: 1000,
  },
  flexDirection: {
    flexDirection: 'row',
  },
  titleTextArea: {
    paddingTop: 20,
  },
  redColor: {
    color: 'red',
  },
  textTitle: {
    paddingLeft: 10,
  },
  borderBottomWhite: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 20,
    borderBottomColor: LINE_COLOR,
    borderBottomWidth: 1,
  },
  textArea: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    paddingVertical: 20,
  },
});

export default styles;

import React from 'react';
import { StyleSheet, View } from 'react-native';
import { Col, Grid } from 'react-native-easy-grid';

import JSelect from '../common/JSelect';
import { INPUT_BG, LINE_COLOR, TEXT_COLOR_RED } from '@assets/style/styleDefault';
import { JText as Text } from '../common/JText';

const AccountSelect = ({ placeholder, name, control, labelText, isRequired, selectItems, ml, disable }) => {
  return (
    <Grid style={[styles.marginBottom20]}>
      <Col size={4}>
        <View style={[styles.mt5, styles.flexRow]} nativeID={name}>
          <Text style={[styles.colorWhite, { marginLeft: ml }]}>{labelText}</Text>
          {isRequired && <Text style={styles.iconRequired}>✴︎</Text>}
        </View>
      </Col>
      <Col size={8} style={styles.flexCenter}>
        <JSelect
          disable={disable}
          selectItems={selectItems}
          control={control}
          name={name}
          placeholder={placeholder}
          bgColor={INPUT_BG}
        />
      </Col>
    </Grid>
  );
};

export default AccountSelect;

const styles = StyleSheet.create({
  relative: {
    position: 'relative',
  },
  input: {
    border: `1px solid ${LINE_COLOR}`,
    backgroundColor: 'transparent',
    borderRadius: 4,
    paddingLeft: 25,
  },
  inputSubfix: {
    height: 15,
    width: 15,
    position: 'absolute',
    right: 20,
    top: 13,
  },
  error: {
    color: TEXT_COLOR_RED,
  },
  marginBottom20: {
    marginBottom: 25,
    marginTop: 5,
  },
  flex: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  iconRequired: {
    color: TEXT_COLOR_RED,
    marginLeft: 6,
    fontSize: 16,
  },
  flexCenter: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  mt5: {
    marginTop: 5,
  },
  flexRow: {
    display: 'flex',
    flexDirection: 'row',
  },
});

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { get } from '@utils/RequestLevica';
import { API_PATH } from '@helper/constant';
import i18n from 'i18n';

const initialState = {
  language: 'Japanese',
  isLoaded: false,
  errorMessage: '',
};

export const language = createAsyncThunk('getCurrentLanguage', async () => {
  const data = await get(API_PATH.SYSTEM_SETTING);
  i18n.changeLanguage(data.adminSettings?.find((item) => item?.item == 'language')?.value);
  return data;
});

const getDataByKey = (list, key) => {
  return list?.find((item) => item?.item == key)?.value;
};

const languageSlice = createSlice({
  name: 'language',
  initialState,
  reducers: {
    changeLanguage: (state, action) => {
      state.language = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(language.pending, (state) => {
      state.isLoaded = true;
    });
    builder.addCase(language.fulfilled, (state, action) => {
      return (state = {
        ...state,
        isLoaded: false,
        language: getDataByKey(action.payload?.adminSettings, 'language') ?? 'Japanese',
      });
    });
    builder.addCase(language.rejected, (state, action) => {
      state.isLoaded = false;
      state.errorMessage = action.payload;
    });
  },
});

export const { changeLanguage } = languageSlice.actions;
export default languageSlice.reducer;

import { yupResolver } from '@hookform/resolvers/yup';
import React, { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { View, Image, StyleSheet } from 'react-native';
import Modal from 'react-native-modal';
import * as Yup from 'yup';
import { LinearGradient } from 'expo-linear-gradient';
import { withNamespaces } from 'react-i18next';

import {
  BG_COLOR,
  BORDER_GREY,
  BORDER_TABLE_GREY,
  BTN_COLOR_GREY_2,
  COLOR_TEXT_DEFAULT,
  FONT_FAMILY_DEFAULT,
  FONT_SIZE_HEADER,
  TEXT_COLOR_GREY,
} from '@assets/style/styleDefault';
import JButton from '../common/JButton';
import JInput from '../common/JInput';
import { JText as Text } from '../common/JText';
import RequireText from '../common/RequireText';
import ModalBackDrop from './ModalBackDrop';
import { API_PATH } from '@helper/constant';
import { post } from '@utils/RequestLevica';

const ModalBurn = (props) => {
  const { isModal, setIsModal, walletAddressSend, t } = props;
  const [isModalSave, setModalSave] = useState(false);
  const [isBuring, setIsBurning] = useState(false);

  const schema = Yup.object().shape({
    walletAddress: Yup.string().trim().required(t('errorMessages.enterYourWalletAddrPls')),
    burnValue: Yup.string()
      .typeError(t('errorMessages.fieldRequired', { fieldName: t('components_Modals_ModalBurn.intergerBurnToken') }))
      .min(1, t('errorMessages.fieldRequired', { fieldName: t('components_Modals_ModalBurn.intergerBurnToken') }))
      .max(19, t('errorMessages.TXT_MAX_15'))
      .test(
        'burnValue',
        t('errorMessages.fieldRequired', { fieldName: t('components_Modals_ModalBurn.intergerBurnToken') }),
        (value) => value !== '0'
      ),
  });

  const {
    control,
    handleSubmit,
    setError,
    reset,
    formState: { isValid },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      burnValue: '',
      walletAddress: walletAddressSend ?? walletAddressSend,
    },
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data) => {
    try {
      setIsBurning(true);
      await post(API_PATH.BURN_POINT, {
        fromAddress: data?.walletAddress,
        amount: parseInt(data?.burnValue?.replace(/,/g, '')),
      });
      setIsModal(false);
      setModalSave(true);
      reset();
      setTimeout(() => {
        props.refetch();
        setModalSave(false);
      }, [2000]);
    } catch (error) {
      if (error?.response?.data && error?.response?.data[0]?.code === '1506') {
        setError('burnValue', {
          type: 'server',
          message: t('errorMessages.notEnoughPointToBurn'),
        });
      } else if (error?.response?.data && error?.response?.data[0]?.code === '1505') {
        setError('walletAddress', {
          type: 'server',
          message: t('components_Modals_ModalBurn.notFoundAddress'),
        });
      }
    } finally {
      setIsBurning(false);
    }
  };

  const onCloseModal = useCallback(() => {
    setIsModal(false);
    reset();
  }, [setIsModal, reset]);

  return (
    <View>
      <Modal
        animationIn="fadeIn"
        isVisible={isModal}
        style={{ alignItems: 'center' }}
        customBackdrop={<ModalBackDrop onPress={onCloseModal} />}
      >
        <View>
          <View style={styles.modalBack}>
            <View style={styles.headerModal}>
              <Image style={styles.iconList} source={require('@assets/icons/iconBurnRed.svg')} />
              <Text style={styles.textModal}>{t('components_Modals_ModalBurn.LEVICABurn')}</Text>
            </View>
            <View style={styles.contentModal}>
              <View style={styles.textContentModal}>
                <View style={styles.wrapperInput}>
                  {walletAddressSend ? (
                    <View style={styles.wrapperLable}>
                      <Text style={styles.textModal}>Wallet Addres </Text>
                    </View>
                  ) : (
                    <View style={styles.wrapperLable}>
                      <Text style={styles.textModal}>{t('components_Modals_ModalBurn.walletAddress')}</Text>
                      <RequireText />
                    </View>
                  )}

                  {walletAddressSend ? (
                    <Text style={{ color: TEXT_COLOR_GREY, width: 400 }}>{walletAddressSend} </Text>
                  ) : (
                    <JInput
                      placeholder={t('components_Modals_ModalBurn.walletAddressPlaceholder')}
                      control={control}
                      name="walletAddress"
                      borderError="red"
                      width={400}
                    />
                  )}
                </View>
                <View style={{ display: 'flex', flexDirection: 'row' }}>
                  <View style={styles.wrapperLable}>
                    <Text style={styles.textModal}>{t('components_Modals_ModalBurn.tokenQuantity')}</Text>
                    <RequireText />
                  </View>
                  {
                    <JInput
                      typeMoney
                      placeholder={t('components_Modals_ModalBurn.tokenQuantityPlaceholder')}
                      control={control}
                      name="burnValue"
                      borderError="red"
                      width={400}
                    />
                  }
                </View>
                <View style={[styles.wrapperInput, { paddingTop: 30 }]}>
                  <Image style={styles.iconDanger} source={require('@assets/icons/iconDanger.svg')} />
                </View>
                <View style={[styles.wrapperContentText, { paddingTop: 10 }]}>
                  <Text style={styles.textModal}>{t('components_Modals_ModalBurn.burnTokenWarning1')}</Text>
                  <Text style={styles.textModal}>{t('components_Modals_ModalBurn.burnTokenWarning2')}</Text>
                </View>
              </View>
            </View>
            <View style={styles.buttonModal}>
              <JButton
                hasIcon
                linkIcon="iconCloseModal.svg"
                widthIcon={20}
                heightIcon={20}
                text={t('commonText.cancelButton')}
                width={200}
                textColor="#fff"
                btnColor={BTN_COLOR_GREY_2}
                onPressAction={() => onCloseModal()}
              />
              <LinearGradient colors={['#FE8463', '#FD4F44']} style={{ borderRadius: 5 }}>
                <JButton
                  hasIcon
                  linkIcon="iconBurnWhite.svg"
                  widthIcon={22}
                  heightIcon={29}
                  linkIconDisabled="saveGray.svg"
                  text={t('components_Modals_ModalBurn.buttonBurnToken')}
                  width={200}
                  textColor="#fff"
                  disabled={isBuring}
                  onPressAction={handleSubmit(onSubmit)}
                  isProcessing={isBuring}
                />
              </LinearGradient>
            </View>
          </View>
        </View>
      </Modal>
      <ModalSave isModalVisible={isModalSave} setModalVisible={setModalSave} isClickBackDrop={false} t={t} />
    </View>
  );
};
export default withNamespaces()(ModalBurn);

const ModalSave = (props) => {
  const { isModalVisible, setModalVisible, isClickBackDrop, t } = props;
  return (
    <View>
      <Modal
        animationIn="fadeIn"
        isVisible={isModalVisible}
        onBackdropPress={() => setModalVisible(!isClickBackDrop)}
        style={{ alignItems: 'center' }}
      >
        <View style={styles.wrapperModal} onClick={() => setModalVisible(false)}>
          <View style={styles.maxWidth700}>
            <View style={styles.header}>
              <Image style={styles.iconBurn} source={require('@assets/icons/burnIcon.svg')} />
              <Text style={styles.textHeader}>{t('components_Modals_ModalBurn.waitForBurnTitle')}</Text>
            </View>
            <View style={styles.body}>
              <Text style={styles.textHeader}>{t('components_Modals_ModalBurn.waitForBurnText')}</Text>
            </View>
          </View>
        </View>
      </Modal>
    </View>
  );
};

const styles = StyleSheet.create({
  modalClose: {
    flexDirection: 'row',
    alignContent: 'center',
    marginBottom: 10,
    marginLeft: 20,
  },
  wrapperContentText: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  wrapperInput: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginVertical: 30,
  },
  wrapperLable: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    width: 180,
    marginRight: 30,
  },
  wrapperNumber: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  numberText: {
    fontSize: 16,
  },
  iconClose: {
    height: 15,
    width: 15,
    marginRight: 10,
  },
  textClose: {
    fontSize: 14,
    color: '#f2f2f2',
  },
  modalBack: {
    backgroundColor: BG_COLOR,
    maxWidth: 800,
    width: 700,
    borderRadius: 4,
    paddingBottom: 40,
  },
  headerModal: {
    height: 50,
    borderBottomWidth: 1,
    borderBottomColor: BORDER_GREY,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  textModal: {
    color: COLOR_TEXT_DEFAULT,
    fontSize: FONT_FAMILY_DEFAULT,
  },
  contentModal: {
    marginTop: 20,
    alignItems: 'center',
  },
  iconModal: {
    height: 35,
    width: 42,
  },
  iconDanger: {
    height: 40,
    width: 49,
  },
  textContentModal: {
    marginTop: 30,
    flexDirection: 'column',
    alignItems: 'center',
  },
  buttonModal: {
    height: 90,
    flexDirection: 'row',
    alignItems: 'end',
    justifyContent: 'space-evenly',
  },
  iconList: {
    width: 20,
    height: 25,
    marginRight: 10,
  },
  //modal save
  textHeader: {
    color: 'black',
    fontSize: FONT_SIZE_HEADER,
  },
  wrapperModal: {
    maxWidth: 900,
    minWidth: 600,
    cursor: 'pointer',
    alignItems: 'center',
  },

  maxWidth700: {
    backgroundColor: '#fff',
    borderRadius: 10,
    // paddingVertical: 30,
  },
  header: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    borderBottomColor: BORDER_TABLE_GREY,
    borderBottomWidth: 1,
    paddingVertical: 20,
  },
  iconBurn: {
    width: 33,
    height: 46,
    marginRight: 10,
    marginBottom: 5,
  },
  body: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingVertical: 100,
    paddingHorizontal: 60,
  },
});

import React, { useEffect, useState } from 'react';
import { View, Image, ScrollView, Pressable, Dimensions } from 'react-native';
import { Col, Grid } from 'react-native-easy-grid';
import { LinearGradient } from 'expo-linear-gradient';
import { withNamespaces } from 'react-i18next';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Center } from 'native-base';
import moment from 'moment';

import HeaderSearch from '@components/Headers/HeaderSearch';
import Pagination from '@components/common/Pagination';
import JButton from '@components/common/JButton';
import styles from '../style';
import HeaderPayManagement from '@components/PayManagement';
import ModalConfirmPayment from '../Modal/ModalConfirmPayment';
import ModalComplete from '../Modal/ModalComplete';
import { JText as Text } from '@components/common/JText';
import JCheckbox from '@components/common/JCheckbox';
import { formatNumber } from '@helper/formatTypes';
import { get, put } from '@utils/RequestLevica';
import { API_PATH } from '@helper/constant';
import Loading from '@components/common/Loading';
import { BG_FORM_DEFAULT, INPUT_BG, LINE_COLOR, TEXT_COLOR_GREEN } from '@assets/style/styleDefault';
import ModalAlert from '@components/Modals/ModalAlert';

const PaymentDeposites = ({ t }) => {
  const [isModalConfirm, setIsModalConfirm] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [isModalComplete, setIsModalComplete] = useState(false);
  const [depositedListData, setDepositedListData] = useState([]);
  const [listChecked, setListChecked] = useState([]);
  const [paymentIDSelected, setPaymentIDSelected] = useState([]);
  const [paymentProcessedAll, setPaymentProcessedAll] = useState(false);
  const [totalAmountChecked, setTotalAmountChecked] = useState();
  const [modalAlert, setModalAlert] = useState(false);
  const [tableHeadData, setTableHeadData] = useState([
    {
      title: t('pages_PaymentManager.paymentDueDate'),
      size: 1,
      hasIcon: true,
      order: false,
      sort: '0100',
    },
    {
      title: t('pages_PaymentManager_PaymentDeposites.codeVerify'),
      size: 1,
      hasIcon: true,
      order: false,
      sort: '0200',
    },
    {
      title: t('pages_PaymentManager_PaymentDeposites.playerName'),
      size: 1,
      hasIcon: true,
      order: false,
      sort: '0300',
    },
    {
      title: t('pages_PaymentManager_PaymentDeposites.amount'),
      size: 1,
      hasIcon: true,
      order: false,
      sort: '0400',
    },
    { title: t('pages_PaymentManager_PaymentDeposites.select'), size: 1 },
  ]);

  const csvHeader = [
    {
      label: t('pages_PaymentManager.paymentDueDate'),
      key: 'paymentDueDate',
    },
    {
      label: t('pages_PaymentManager_PaymentDeposites.codeVerify'),
      key: 'codeVerify',
    },
    {
      label: t('pages_PaymentManager_PaymentDeposites.playerName'),
      key: 'playerName',
    },
    {
      label: t('pages_PaymentManager_PaymentDeposites.amount'),
      key: 'amount',
    },
  ];

  const [params, setParams] = useState({
    limit: 30,
    pageNumber: 1,
    q: '',
    startDate: '',
    endDate: '',
    sort: '0600',
    order: 'desc',
    status: '1',
  });
  const selectAll = () => {
    const isAllDepositsInTabChecked = listChecked.length === depositedListData.length;
    setPaymentProcessedAll(!isAllDepositsInTabChecked);

    const newDepositListData = depositedListData?.map((item) => ({
      ...item,
      pickup: listChecked?.length > 0 ? (isAllDepositsInTabChecked ? false : true) : true,
    }));
    setDepositedListData(newDepositListData);

    setPaymentIDSelected(() => {
      if (isAllDepositsInTabChecked) return [];
      const newPaymentIDSelected = [...paymentIDSelected];
      depositedListData?.map(
        ({ id: paymentID }) => !newPaymentIDSelected.includes(paymentID) && newPaymentIDSelected.push(paymentID)
      );
      return newPaymentIDSelected;
    });
  };
  const {
    data: PaymentDeposites,
    refetch,
    isFetching,
    remove,
  } = useQuery(
    ['PaymentDeposites', params],
    async ({ signal }) => {
      const data = await get(API_PATH.PAYMENT_CONFIRMATION_REQUEST, { ...params, signal });
      if (paymentProcessedAll) {
        const newDepositedListData = data?.paymentConfirmationRequestList?.map((item) => ({
          ...item,
          pickup: true,
        }));
        setDepositedListData(newDepositedListData);
        setPaymentIDSelected(() => {
          const newPaymentIDSelected = [...paymentIDSelected];
          data?.paymentConfirmationRequestList?.map(
            ({ id: paymentID }) => !newPaymentIDSelected.includes(paymentID) && newPaymentIDSelected.push(paymentID)
          );
          return newPaymentIDSelected;
        });
      } else {
        setDepositedListData(
          data?.paymentConfirmationRequestList?.map((item) =>
            paymentIDSelected?.includes(item.id) ? { ...item, pickup: true } : item
          )
        );
      }
      return data;
    },
    {
      refetchOnWindowFocus: false,
    }
  );

  const toggleCheckbox = (item, index) => {
    const newDepositedListData = [...depositedListData];
    newDepositedListData?.splice(index, 1, { ...item, pickup: !item.pickup });
    setDepositedListData(newDepositedListData);

    const positionOfItemID = paymentIDSelected.indexOf(item.id);
    setPaymentIDSelected((prev) => {
      const newPaymentSelected = [...prev];
      if (positionOfItemID > -1) {
        newPaymentSelected.splice(positionOfItemID, 1);
        return newPaymentSelected;
      }
      return [...newPaymentSelected, item.id];
    });

    setPaymentProcessedAll(false);
  };

  useEffect(() => {
    let totalAmount = 0;

    const listChecked = depositedListData?.filter((item) => {
      if (item.pickup === true) {
        totalAmount += item.amount;
        return item;
      }
    });
    setTotalAmountChecked(totalAmount);
    setListChecked(listChecked);
  }, [depositedListData]);

  const toggleModalConfirm = () => {
    setIsModalConfirm(!isModalConfirm);
  };

  const { mutate: transferPoint } = useMutation({
    mutationFn: async (ids) =>
      await put(API_PATH.PAYMENT_CONFIRMATION_TRANSFER_POINT, JSON.stringify({ requestIds: ids })),
    retry: 3,
  });

  const handleTransferPoint = () => {
    setIsProcessing(true);
    const idsRequest = listChecked.map(({ id }) => id);
    transferPoint(idsRequest, {
      onSuccess: () => {
        setIsModalComplete(!isModalComplete);
        setIsModalConfirm(false);
        setIsProcessing(false);
        refetch();
      },
      onError: (error) => {
        setIsProcessing(false);
        setIsModalConfirm(false);
        if (error?.response?.data && error?.response?.data[0]?.code === '1506') {
          setModalAlert(true);
        }
      },
    });
  };

  // useEffect(() => {
  //   const newData = getDepositedProcessingList?.depositedProcessingList?.depositedProcessingList?.map((item) => ({
  //     ...item,
  //     pickup: false,
  //   }));
  //   setDepositedListData(newData);
  // }, [getDepositedProcessingList]);

  const handleSort = (item) => {
    setTableHeadData((prev) =>
      prev.map((tableHead) =>
        tableHead.sort === item.sort ? { ...tableHead, order: !tableHead.order } : { ...tableHead, order: false }
      )
    );

    setParams({
      ...params,
      pageNumber: 1,
      sort: item.sort,
      order: !item.order ? 'asc' : 'desc',
    });
  };

  useEffect(() => remove, [remove]);

  return (
    <>
      <View
        style={[
          styles.container,
          { backgroundColor: isFetching || depositedListData.length < 1 ? '' : BG_FORM_DEFAULT },
        ]}
      >
        <View style={styles.wrapperStickyHeader}>
          <View style={styles.headerSearch}>
            <HeaderSearch
              setParams={setParams}
              params={params}
              placeholder={t('pages_PaymentManager_PaymentDeposites.placeholder')}
              onSearch={() => setPaymentProcessedAll(false)}
            />
          </View>

          <HeaderPayManagement
            csvHeader={csvHeader}
            exportCSVLink={API_PATH.PAYMENT_CONFIRMATION_REQUESTS_EXPORT_CSV}
            csvName={t('components_PayManagement.deposit')}
            paymentListData={depositedListData}
            setParams={setParams}
            params={{ isCheckedAll: true, ...params }}
            countChecked={listChecked?.length}
            listChecked={listChecked}
            active={'/payment/deposites'}
            totalAmount={!PaymentDeposites?.totalAmount || isFetching ? 0 : PaymentDeposites?.totalAmount}
            paymentProcessedAll={paymentProcessedAll}
            // btn4={
            //   <JButton
            //     text={t('commonText.buttonSelectAll')}
            //     onPressAction={() => selectAll()}
            //     width={150}
            //     textColor={TEXT_COLOR_GREEN}
            //     btnColor={INPUT_BG}
            //     borderBtn={`1px solid ${LINE_COLOR}`}
            //   />
            // }
            btn3={
              <View style={{ alignItems: 'center' }}>
                <Text style={{ color: '#FD614E' }}>
                  {(paymentProcessedAll && formatNumber(PaymentDeposites?.totalAmount || ' ')) ||
                    (totalAmountChecked > 0 && formatNumber(totalAmountChecked)) ||
                    ' '}
                </Text>
                <LinearGradient colors={['#FE8463', '#FD4F44']} style={{ borderRadius: 5 }}>
                  <JButton
                    text={t('pages_PaymentManager_PaymentDeposites.TXprocessing')}
                    width={150}
                    textColor={'#fff'}
                    disabled={listChecked?.length > 0 ? false : true}
                    onPressAction={toggleModalConfirm}
                  />
                </LinearGradient>
              </View>
            }
            datetimeTitle={t('components_PayManagement.timespace')}
            onDateFilter={() => setPaymentProcessedAll(false)}
            linkPaymentProcessed={API_PATH.PAYMENT_CONFIRMATION_TRANSFER_POINT}
          />
          {depositedListData?.length > 0 && !isFetching && (
            <View>
              <Grid style={[styles.head, { gap: 20 }]} size={30}>
                {tableHeadData.map((item) => (
                  <Col size={item.size} style={styles.horizontal} key={item.title}>
                    <Text numberOfLines={2} style={styles.textHead}>
                      {item.title}
                    </Text>
                    {item.hasIcon && (
                      <Image
                        onClick={() => handleSort(item)}
                        style={item?.order ? styles.iconTableHeadDESC : styles.iconTableHeadASC}
                        source={require(`@assets/icons/iconDropdown.svg`)}
                      />
                    )}
                  </Col>
                ))}
              </Grid>
            </View>
          )}
        </View>
        <ScrollView showsVerticalScrollIndicator={false}>
          {isFetching ? (
            <Loading style={{ height: 'calc(100vh - 266px)' }} />
          ) : depositedListData.length > 0 ? (
            depositedListData?.map((item, index, { length }) => {
              return (
                <Pressable key={index} style={({ hovered }) => [hovered && styles.rowHovered]}>
                  <Grid
                    style={[styles.headBody, { gap: 20 }, { borderBottomWidth: index + 1 === length ? 0 : 1 }]}
                    size={30}
                  >
                    <Col size={1}>
                      {/* <Text style={styles.text} numberOfLines={2}>
                        {item?.confirmedAt}
                      </Text> */}
                      <Text style={styles.text} numberOfLines={2}>
                        {item?.dayTrading
                          ? moment.unix(new Date(item?.dayTrading).getTime() / 1000).format('YYYY.MM.DD')
                          : ''}
                      </Text>
                    </Col>
                    <Col size={1}>
                      <Text style={styles.text} numberOfLines={2}>
                        {item?.tradingCode}
                      </Text>
                    </Col>
                    <Col size={1}>
                      <Text style={styles.text} numberOfLines={2}>
                        {item?.name}
                      </Text>
                    </Col>
                    <Col size={1}>
                      <Text style={styles.text} numberOfLines={2}>
                        {item?.amount ? `¥ ${formatNumber(item.amount)}` : ''}
                      </Text>
                    </Col>
                    <Col size={1} style={{ alignItems: 'center' }}>
                      <JCheckbox
                        checkValueTable={true}
                        onValueChange={() => toggleCheckbox(item, index)}
                        value={item.pickup ?? false}
                      />
                    </Col>
                  </Grid>
                </Pressable>
              );
            })
          ) : (
            <Center w="100%" h={Dimensions.get('window').height - 266}>
              <Text>{t('pages_PaymentManager_PaymentDeposites.haveNoPaymentDeposites')}</Text>
            </Center>
          )}
          <View>
            {params.limit < PaymentDeposites?.pageInfo?.totalCount && !isFetching && (
              <Pagination
                count={Math.ceil(PaymentDeposites?.pageInfo?.totalCount / params.limit)}
                params={params}
                setParams={setParams}
              />
            )}
          </View>
        </ScrollView>

        <ModalConfirmPayment
          iconName="iconDanger.svg"
          titleModal={t('pages_PaymentManager_PaymentDeposites.modalConfirmPaymentText')}
          heightIcon={40}
          widthIcon={49}
          isModalVisible={isModalConfirm}
          setModalVisible={setIsModalConfirm}
          countChecked={listChecked?.length}
          onPress={handleTransferPoint}
          isProcessing={isProcessing}
        />
        <ModalComplete
          isModalVisible={isModalComplete}
          setModalVisible={setIsModalComplete}
          iconName={'iconProcess.svg'}
          heightIcon={20}
          widthIcon={55}
          textContent={t('pages_PaymentManager_PaymentDeposites.modalCompleteText')}
        />
        <ModalAlert
          isModalVisible={modalAlert}
          setModalVisible={setModalAlert}
          icon="iconDanger.svg"
          iconWidth={42}
          iconHeight={35}
          modalText={t('errorMessages.notEnoughMoney')}
        />
      </View>
    </>
  );
};

export default withNamespaces()(PaymentDeposites);

import React from 'react';
import { View } from 'react-native';
import { Link } from 'react-router-dom';
import styles from './style';
import { JText as Text } from '@components/common/JText';
import { withNamespaces } from 'react-i18next';

const NotFoundPage = ({ t }) => {
  return (
    <View style={styles.container}>
      <Text style={styles.textContent}>{t('page_404.pageNotFound')}</Text>
      <Link to={'/'} style={{ textDecoration: 'none' }}>
        <Text style={styles.textLink}>{t('page_404.backToHomePage')}</Text>
      </Link>
    </View>
  );
};

export default withNamespaces()(NotFoundPage);

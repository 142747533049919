import React, { useMemo } from 'react';
import { Image, Pressable, Linking } from 'react-native';
import { Col, Grid } from 'react-native-easy-grid';
import fileSize from 'file-size';
import moment from 'moment';
import { useSelector } from 'react-redux';

import styles from './styles';
import { JText as Text } from '@components/common/JText';
import RadioCustom from '@components/common/RadioCustom';
import JCheckbox from '@components/common/JCheckbox';
import { checkPermission } from '@helper/commonFunction';
import { ROLE_USER } from '@helper/constant';

const TableBody = ({
  item,
  index,
  toggleCheckbox,
  setDocumentActive,
  documentActive,
  radioClick,
  isDisableCheckbox,
}) => {
  const userInfo = useSelector((store) => store?.merchantUserInfo);
  const isHaveRole = useMemo(
    () => checkPermission(ROLE_USER.DOCUMENT_MANAGEMENT, userInfo),
    [checkPermission, userInfo]
  );

  return (
    <>
      <Pressable style={({ hovered }) => [hovered ? styles.itemHovered : styles.px10]} key={item.id}>
        <Grid style={styles.item}>
          <Col size={4} style={styles.firstCol} onPress={() => Linking.openURL(item?.fileUrl)}>
            <Image style={styles.icon} source={require(`@assets/icons/iconPDF.svg`)} />
            <Text style={styles.title} numberOfLines={3}>
              {item.title}
            </Text>
          </Col>
          <Col size={2} style={styles.center} onPress={() => Linking.openURL(item?.fileUrl)}>
            <Text style={styles.size}>{fileSize(item.size).human('si')}</Text>
          </Col>
          <Col size={2} style={styles.center} onPress={() => Linking.openURL(item?.fileUrl)}>
            <Text style={styles.date}>{moment(item?.createdAt).format('YYYY.MM.DD')}</Text>
          </Col>
          <Col size={2} style={styles.center}>
            <RadioCustom
              onClick={radioClick}
              item={item}
              setDocumentActive={setDocumentActive}
              documentActive={documentActive}
              isDisabled={!isHaveRole}
            />
          </Col>
          <Col size={2} style={styles.center}>
            <JCheckbox
              checkValueTable={true}
              onValueChange={() => toggleCheckbox(item, index)}
              value={item.pickup ?? false}
              disable={isDisableCheckbox && isHaveRole}
            />
          </Col>
        </Grid>
      </Pressable>
    </>
  );
};

export default TableBody;

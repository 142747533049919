import React, { useState } from 'react';
import { StyleSheet, View, Image } from 'react-native';
import { Link, useHistory } from 'react-router-dom';
import { withNamespaces } from 'react-i18next';

import { JText as Text } from '../common/JText';
import {
  BG_COLOR,
  COLOR_TEXT_DEFAULT,
  FONT_SIZE_HEADER,
  FONT_SIZE_DEFAULT,
  TEXT_COLOR_GREEN,
} from '@assets/style/styleDefault';
import ModalAlert from '../Modals/ModalAlert';
import ModalBack from '../Modals/ModalBack';

const HeaderBackHaveModal = ({
  btnBack,
  textBack,
  iconHeader,
  textHeader,
  avatar,
  linkBack = '/',
  hasSubTitle,
  subIconHeader,
  subTextHeader,
  widthIconBack = '',
  heightIconBack = '',
  widthIconHeader = '',
  heightIconHeader = '',
  historyBack = false,
  onBackdropPressCallback,
  onAcceptBtnClick = () => {},
  t,
}) => {
  const [isModal, setIsModal] = useState(false);
  const [isModalBack, setIsModalBack] = useState(false);
  const openModalBack = () => {
    setIsModalBack(true);
  };
  const handleModalBack = async () => {
    setIsModalBack(false);
    const { isValid, func } = onAcceptBtnClick();
    if (!isValid) return func();
    const succ = await func();
    if (!succ) return;
    setIsModal(true);
  };
  return (
    <>
      <View style={styles.headerBack}>
        <View style={styles.headerLeft}>
          <View
            onClick={() => openModalBack()}
            style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', cursor: 'pointer' }}
          >
            <Image
              style={[
                widthIconBack && heightIconBack ? { width: widthIconBack, height: heightIconBack } : styles.buttonBack,
              ]}
              source={require(`../../assets/icons/${btnBack}`)}
            />
            <Text style={styles.textBack}>{textBack}</Text>
          </View>

          <View style={styles.wrapperItem}>
            {iconHeader && (
              <Image
                style={[
                  widthIconHeader && heightIconHeader
                    ? { width: widthIconHeader, height: heightIconHeader }
                    : styles.iconHeader,
                ]}
                source={require(`../../assets/icons/${iconHeader}`)}
              />
            )}
            <Text style={styles.textHeader}>{textHeader}</Text>
          </View>
          {hasSubTitle && (
            <View style={styles.wrapperItem}>
              {subIconHeader && (
                <Image style={styles.iconHeader} source={require(`../../assets/icons/${subIconHeader}`)} />
              )}
              <Text style={styles.textHeader}>{subTextHeader}</Text>
            </View>
          )}
        </View>
        <View>{avatar}</View>
      </View>
      <ModalAlert
        isModalVisible={isModal}
        setModalVisible={setIsModal}
        modalText={t('components_Headers_HeaderBackHaveModal.text')}
        iconWidth={16}
        iconHeight={12.5}
        icon="successGreen.svg"
        linkPage="/information"
        onBackdropPressCallback={onBackdropPressCallback}
      />
      <ModalBack
        isModal={isModalBack}
        setIsModal={setIsModalBack}
        otherModal={handleModalBack}
        linkPage="/information"
      />
    </>
  );
};

export default withNamespaces()(HeaderBackHaveModal);

const styles = StyleSheet.create({
  headerBack: {
    height: 80,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: BG_COLOR,
  },
  headerLeft: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  wrapperItem: {
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    marginRight: 30,
  },
  buttonBack: {
    height: 15,
    width: 15,
  },
  textBack: {
    fontSize: FONT_SIZE_DEFAULT,
    marginLeft: 5,
    marginRight: '2rem',
    color: TEXT_COLOR_GREEN,
    fontWeight: 'bold',
  },
  iconHeader: {
    height: 25,
    width: 25,
    resizeMode: 'contain',
  },
  textHeader: {
    color: COLOR_TEXT_DEFAULT,
    fontSize: FONT_SIZE_HEADER,
    marginLeft: 10,
    fontWeight: 600,
  },
});

import React, { useCallback, useEffect, useRef, useState, useMemo } from 'react';
import { View, StyleSheet, Image, ScrollView } from 'react-native';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Col, Grid } from 'react-native-easy-grid';
import { useParams, useHistory } from 'react-router-dom';
import { useQuery, QueryClient } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import moment from 'moment/moment';

import { bankInfoDefaultValues, bankInfoSchema, defaultValues, accountShopSchema } from './createAccountShopFormData';
import { get } from '@utils/RequestLevica';
import FomrIndividual from './FomrIndividual';
import HeaderAccount from '@components/Headers/HeaderAccount';
import HeaderSearch from '@components/Headers/HeaderSearch';
import { JText as Text } from '@components/common/JText';
import { BG_COLOR } from '@assets/style/styleDefault';
import WalletInfo from './FormBankInfo/WalletInfo';
import BankInfo from './FormBankInfo/BankInfo';
import MemberInfo from './MemberInfo';
import HeaderAvatar from '@components/Headers/HeaderAvatar';
import { API_PATH, ROLE_USER } from '@helper/constant';
import BankInformation from './BankInformation';
import scroll2View from '@helper/scroll2View';
import useUpdateLangueForYup from '@helper/customHook/useUpdateLangueForYup';
import Loading from '@components/common/Loading';
import { checkPermission } from '@helper/commonFunction';

const CreateShopAccount = ({ t }) => {
  const history = useHistory();
  const { accountId } = useParams();
  const [formValue, setFormValue] = useState(null);
  const [openBank, setOpenBank] = useState(false);
  const queryClient = new QueryClient();
  const scrollViewRef = useRef();
  const [bankTypesSelect, setBankTypesSelect] = useState([]);
  const [bankInfoBackup, setBankInfoBackup] = useState(bankInfoDefaultValues);
  const [isInjectedDataToHookForm, setIsInjectedDataToHookForm] = useState(false);
  const userInfo = useSelector((state) => state.merchantUserInfo);

  const methods = useForm({
    defaultValues,
    resolver: yupResolver(useUpdateLangueForYup(accountShopSchema)),
    mode: 'onChange',
    shouldFocusError: false,
  });

  const bankInfoMethods = useForm({
    defaultValues: bankInfoDefaultValues,
    resolver: yupResolver(useUpdateLangueForYup(bankInfoSchema)),
    mode: 'onChange',
  });

  const {
    formState: { isValid: isBankInfoValid, submitCount },
  } = bankInfoMethods;
  const getBankType = async () => {
    const data = await get(API_PATH.SHOP_ACCOUNT_BANK_TYPES);
    if (!Array.isArray(data)) return false;
    setBankTypesSelect(data.map((bank) => ({ value: bank.id.toString(), label: bank.description })));
  };
  useEffect(() => {
    getBankType();
  }, []);

  const getShopDetail = async ({ signal }) => {
    return await get(`${API_PATH.SHOP_ACCOUNT}/${accountId}`, { signal });
  };

  const {
    data: shopAccount,
    isFetching,
    refetch,
    remove,
  } = useQuery(['getShopDetail'], getShopDetail, {
    enabled: !!accountId,
    refetchOnWindowFocus: false,
    onError: (error) => {
      if (error?.response?.data?.code === '2000' || error?.response?.data?.code === '5009') {
        alert(error?.response?.data?.message);
        history.push('/shop');
      }
    },
  });

  const onAccountShopUpdateSuccess = useCallback(() => {
    setFormValue((prev) => ({ ...prev, name: methods.watch('name') }));
  }, [setFormValue, methods]);

  const isShowForm = useMemo(() => {
    if (!accountId) return true;
    return !isFetching && isInjectedDataToHookForm;
  }, [accountId, isFetching, isInjectedDataToHookForm]);

  useEffect(() => {
    if (accountId && shopAccount && !isFetching) {
      setFormValue(shopAccount);

      const shopAccountData = {};
      const bankInfoData = {};
      const bankInfoField = [
        'merchantBankInfoBankCode',
        'merchantBankInfoBankName',
        'merchantBankInfoBranchCode',
        'merchantBankInfoBranchName',
        'merchantBankInfoAccountType',
        'merchantBankInfoAccountNumber',
        'merchantBankInfoAccountHolderName',
      ];

      for (const key in shopAccount) {
        const fieldData = shopAccount[key];
        if (!fieldData) continue;
        if (bankInfoField.includes(key)) {
          key === 'merchantBankInfoAccountType'
            ? (bankInfoData[key] = fieldData.toString())
            : (bankInfoData[key] = fieldData);
          continue;
        }
        shopAccountData[key] = fieldData;
      }
      methods.reset({
        ...defaultValues,
        ...shopAccountData,
        establishedAt: moment(shopAccountData.establishedAt).format('YYYY-MM-DD'),
      });
      bankInfoMethods.reset({ ...bankInfoDefaultValues, ...bankInfoData });
      setBankInfoBackup({ ...bankInfoDefaultValues, ...bankInfoData });
      methods.trigger();
      bankInfoMethods.trigger();

      setIsInjectedDataToHookForm(true);
    }
    return () => {
      setFormValue(null);
      methods.reset();
      bankInfoMethods.reset();
      queryClient.removeQueries('getShopDetail', { exact: true });
    };
  }, [shopAccount, isFetching, accountId, methods, bankInfoMethods, setBankInfoBackup, setIsInjectedDataToHookForm]);

  useEffect(() => {
    scroll2View(Object.keys(methods.formState.errors)[0], scrollViewRef.current);
  }, [methods.formState.errors]);

  useEffect(() => remove, [remove]);

  return (
    <FormProvider {...methods}>
      {openBank ? (
        <FormProvider {...bankInfoMethods}>
          <BankInformation
            bankTypesSelect={bankTypesSelect}
            accountName={formValue?.name}
            refetch={refetch}
            setOpenBank={setOpenBank}
            accountId={accountId}
            control={bankInfoMethods.control}
            formValue={formValue}
            bankInfoBackup={bankInfoBackup}
            setBankInfoBackup={setBankInfoBackup}
          />
        </FormProvider>
      ) : (
        <View>
          {accountId ? (
            <View>
              <View style={styles.headerSearch}>
                <HeaderSearch placeholder={t('pages_AccountShop_CreateAccount.placeholder')} linkBack="/shop" />
              </View>
              <HeaderAccount
                showBackButton={true}
                icon="building.svg"
                text={t('pages_AccountShop_CreateAccount.shopAccountDetails')}
                handleSubmit={methods.handleSubmit}
                isDetail={true}
                // checkDirtyFields={!methods.formState.isValid || Object.keys(methods.formState.dirtyFields).length < 1}
                widthIconHeader={27}
                heightIconHeader={31}
                linkWhenClose="/shop"
                updateAPI={`${API_PATH.SHOP_ACCOUNT}/${accountId}`}
                deleteAPI={`${API_PATH.SHOP_ACCOUNT}/${accountId}`}
                textCreateAccountSuccess={t('pages_AccountShop_CreateAccount.modal')}
                scrollViewRef={scrollViewRef}
                formValue={formValue}
                onUpdateSuccess={onAccountShopUpdateSuccess}
                removeRecordSuccessMessage={t('components_Modals_Headers.modalDeleteText9')}
              />
            </View>
          ) : (
            <View
              style={{
                marginVertical: 18,
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                <Image
                  style={{ width: 46, height: 33, marginHorizontal: 20 }}
                  source={require(`@assets/icons/buildingIcon.svg`)}
                />
                <Text
                  style={{
                    fontSize: 16,
                    fontWeight: 600,
                  }}
                >
                  {t('pages_AccountShop_CreateAccount.shopAccountCreate')}
                </Text>
              </View>
              <HeaderAvatar />
            </View>
          )}

          {/* FORM */}
          {isShowForm ? (
            <Grid style={styles.container}>
              <Col size={7}>
                <View style={{ height: accountId ? 'calc(100vh - 200px)' : 'calc(100vh - 80px)' }}>
                  <ScrollView ref={scrollViewRef} showsVerticalScrollIndicator={false}>
                    <FomrIndividual
                      accountId={accountId}
                      control={methods.control}
                      formValue={formValue}
                      t={t}
                      setFormValue={setFormValue}
                      disableForm={checkPermission(ROLE_USER.USER_MANAGEMENT, userInfo)}
                    />
                  </ScrollView>
                </View>
              </Col>
              <Col size={5}>
                {accountId ? (
                  <View style={{ height: 'calc(100vh - 200px)' }}>
                    <View style={styles.containerRight}>
                      <BankInfo userInfo={formValue} control={methods.control} t={t} setOpenBank={setOpenBank} />
                    </View>
                  </View>
                ) : (
                  <MemberInfo
                    t={t}
                    handleSubmit={methods.handleSubmit}
                    // isButton={!methods.formState.isValid || !isBankInfoValid}
                    bankTypesSelect={bankTypesSelect}
                    setOpenBank={setOpenBank}
                    scrollViewRef={scrollViewRef}
                    bankInfoData={bankInfoMethods.watch()}
                    isShowBankIn4Message={!isBankInfoValid && (submitCount > 0 || methods.formState.isSubmitted)}
                    isBankInfoValid={isBankInfoValid}
                  />
                )}
              </Col>
            </Grid>
          ) : (
            <Loading />
          )}
        </View>
      )}
    </FormProvider>
  );
};

export default withNamespaces()(CreateShopAccount);

const styles = StyleSheet.create({
  headerSearch: {
    marginRight: 0,
    marginTop: 10,
  },
  container: {
    gap: 50,
    backgroundColor: BG_COLOR,
    paddingHorizontal: 20,
  },
});

import React, { useEffect, useState } from 'react';
import {
  View,
  Image,
  StyleSheet,
  TouchableWithoutFeedback,
  Pressable,
  TouchableOpacity,
  ScrollView,
} from 'react-native';
import Modal from 'react-native-modal';
import { withNamespaces } from 'react-i18next';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useHistory } from 'react-router-dom';

import { BG_FORM_DEFAULT, BG_HOVER, LINE_COLOR } from '@assets/style/styleDefault';
import JCheckbox from '@components/common/JCheckbox';
import { JText as Text } from '@components/common/JText';
import ModalDelete from '@components/Modals/ModalDelete';
import ModalBackDrop from '@components/Modals/ModalBackDrop';
import { del, get } from '@utils/RequestLevica';
import { API_PATH } from '@helper/constant';

const ModalMail = ({ isModal, setIsModal, itemSelect = '', t, setModalAlert }) => {
  const [data, setData] = useState([]);
  const [listChecked, setListChecked] = useState([]);
  const [chooseFolder, setChooseFolder] = useState(true);
  const [modalDelete, setModalDelete] = useState(false);
  const history = useHistory();

  const getSmsList = async ({ signal }) => {
    const smsList = await get(`${API_PATH.TEMPLATES_EMAIL}?type=${1}`, { signal });
    return smsList?.emailTemplateDataList;
  };

  const { remove } = useQuery(['getSmsList'], getSmsList, {
    onSuccess: (smsList) => {
      setData(smsList);
    },
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    const listChecked = data?.filter((item) => {
      return item.pickup === true;
    });
    setListChecked(listChecked);
  }, [data]);

  const handleChooseFolder = () => {
    setChooseFolder(!chooseFolder);
  };
  const toggleCheckbox = (item, index) => {
    const newListData = [...data];
    newListData?.splice(index, 1, { ...item, pickup: !item.pickup });
    setData(newListData);
  };
  const handleDelete = () => {
    setModalDelete(true);
  };
  const { mutate: removeFilesSms } = useMutation({
    mutationFn: async () => {
      const ids = listChecked.map(({ id }) => id).join(',');
      return await del(`${API_PATH.TEMPLATES_EMAIL}/?ids=${ids}`);
    },
    onSuccess: () => {
      setIsModal(false);
      setModalAlert(true);
    },
    retry: 3,
  });
  useEffect(() => {
    return () => remove;
  }, [remove]);

  const DetailSms = (mail) => {
    history.push(`/file-manager/other-notifications/mail-notifications/detail/${mail.id}`, mail);
  };

  return (
    <View>
      <Modal
        animationIn="fadeIn"
        animationInTiming={300}
        animationOut="fadeOutLeft"
        isVisible={isModal}
        style={{ alignItems: 'center' }}
        customBackdrop={<ModalBackDrop onPress={() => setIsModal(false)} />}
      >
        <View style={styles.modalDocument}>
          <View style={chooseFolder ? styles.titleHeader : styles.titleHeaderChoose}>
            <TouchableOpacity onPress={() => handleChooseFolder()}>
              <View style={styles.flexRow}>
                <Image style={styles.iconFolder} source={require(`@assets/icons/folder-blue.svg`)} />
                <Text style={styles.textTitle}>
                  {itemSelect?.id} {t('pages_FileManager_partials_modalDocument.textTitle')}
                </Text>
              </View>
            </TouchableOpacity>
            <View style={styles.flexRow}>
              <Text style={styles.textTitle}>
                {listChecked.length > 0 ? listChecked.length : null}{' '}
                {t('pages_FileManager_partials_modalDocument.selection')}
              </Text>
              {listChecked.length ? (
                <TouchableOpacity onPress={() => handleDelete()}>
                  <Image style={styles.iconDelete} source={require(`@assets/icons/iconDeleteBinActive.svg`)} />
                </TouchableOpacity>
              ) : (
                <Image style={styles.iconDelete} source={require(`@assets/icons/iconDeleteBin.svg`)} />
              )}
            </View>
          </View>
          <ScrollView showsVerticalScrollIndicator={false}>
            {chooseFolder && (
              <View style={styles.contentDocument}>
                <View style={{ backgroundColor: BG_FORM_DEFAULT }}>
                  {data?.map((item, index) => {
                    return (
                      <Pressable style={({ hovered }) => [hovered ? styles.itemHovered : null]} key={index}>
                        <View style={index == data.length - 1 ? styles.borderBottomNone : styles.wrapperItem}>
                          {index === 0 ? (
                            <TouchableWithoutFeedback onPress={() => handleChooseFolder()}>
                              <Image style={styles.iconTop} source={require(`@assets/icons/iconTop.svg`)} />
                            </TouchableWithoutFeedback>
                          ) : (
                            <View style={styles.iconTop} />
                          )}
                          <View style={styles.flexRow2} onClick={() => DetailSms(item)}>
                            <Image style={styles.iconPDF} source={require(`@assets/icons/iconTXT.svg`)} />
                            <Text style={styles.textTitle} numberOfLines={2}>
                              {item.emailTemplateNameWithVersion}
                            </Text>
                            <JCheckbox
                              checkValueTable={true}
                              value={item.pickup ?? false}
                              onValueChange={() => toggleCheckbox(item, index)}
                              disable={item.isSelected ? false : true}
                            />
                          </View>
                        </View>
                      </Pressable>
                    );
                  })}
                </View>
              </View>
            )}
          </ScrollView>
        </View>
      </Modal>

      <ModalDelete
        iconBtnLeft="close-white.svg"
        iconBtnRight="delete-white.svg"
        heightIconBtnRight={21}
        widthIconBtnRight={21}
        marginBottomIconRight={8}
        textBtnRight={t('commonText.buttonDeleteFile')}
        textBtnLeft={t('commonText.cancelButton')}
        modalDelete={modalDelete}
        setModalDelete={setModalDelete}
        icon="iconDeletedActive.svg"
        textHeader={t('pages_FileManager_partials_modalDocument.modalDeleteTextHeader')}
        textHeaderColor="#191919"
        numberItemDelete={listChecked.length ?? null}
        textContent={[
          t('pages_FileManager_partials_modalDocument.modalDeleteTextContent1'),
          t('pages_FileManager_partials_modalDocument.modalDeleteTextContent2'),
        ]}
        colorTextTitle="#5A5A5A"
        pv={30}
        iconBody="warning.svg"
        func={removeFilesSms}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  flexRow: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  flexRow2: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    flex: 1,
    marginRight: 10,
    paddingVertical: 10,
  },
  wrapperItem: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingRight: 10,
    borderBottomColor: LINE_COLOR,
    borderBottomWidth: 1,
  },
  titleHeader: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 10,
    paddingLeft: 15,
    paddingRight: 15,
    justifyContent: 'space-between',
  },
  titleHeaderChoose: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 10,
    paddingLeft: 15,
    paddingRight: 15,
    justifyContent: 'space-between',
    backgroundColor: '#3A3B41',
    borderRadius: 5,
    border: '1px solid #5C5C5C',
    height: 50,
  },
  iconFolder: {
    width: 20,
    height: 15,
  },
  textTitle: {
    marginLeft: 10,
    marginRight: 10,
    flex: 1,
  },
  iconDelete: {
    height: 31,
    width: 45,
  },
  modalDocument: {
    minHeight: 200,
    maxHeight: 600,
    minWidth: 800,
    maxWidth: 900,
  },
  contentDocument: {
    backgroundColor: '#3A3B41',
    borderRadius: 5,
    border: '1px solid #5C5C5C',
  },
  iconTop: {
    height: 13,
    width: 16,
    marginHorizontal: 10,
  },
  iconPDF: {
    width: 25,
    height: 25,
  },

  borderBottomNone: {
    borderBottomWidth: 0,
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 10,
    paddingRight: 10,
  },
  checkbox: {
    height: 25,
    width: 25,
  },
  itemHovered: {
    backgroundColor: BG_HOVER,
  },
});

export default withNamespaces()(ModalMail);

import React, { useEffect, useRef, useState } from 'react';
import { View, Image, ScrollView, TouchableWithoutFeedback } from 'react-native';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import * as documentPicker from 'expo-document-picker';
import { useHistory } from 'react-router-dom';
import { Col } from 'react-native-easy-grid';
import { LinearGradient } from 'expo-linear-gradient';
import { withNamespaces } from 'react-i18next';

import JButton from '@components/common/JButton';
import JInput from '@components/common/JInput';
import { JText as Text } from '@components/common/JText';
import HeaderCommunity from '@components/Headers/HeaderCommunity';
import ModalBack from '@components/Modals/ModalBack';
import ModalAlert from '@components/Modals/ModalAlert';
import DropDownV2 from '@components/common/DropDownV2';
import { COLOR_TEXT_DEFAULT, BG_COLOR } from '@assets/style/styleDefault';
import styles from './styles';
import ModalSendSuccess from '@components/Modals/ModalSendSuccess';
import scroll2View from '@helper/scroll2View';

const defaultValues = {
  issuerName: '',
  pointIcon: '',
  symbol: '',
  pointName: '',
  totalIssueNumber: '',
  holderLimit: '',
};

const AddPoint = ({ t }) => {
  const history = useHistory();
  const [isModal, setIsModal] = useState(false);
  const [isModalSave, setIsModalSave] = useState(false);
  const [isModalBack, setIsModalBack] = useState(false);
  const [pickImg, setPickImg] = useState('');
  const scrollViewRef = useRef();

  const schema = Yup.object().shape({
    issuerName: Yup.string(),
    pointIcon: Yup.string(),
    symbol: Yup.string()
      .min(3, t('errorMessages.TXT_VALIDATE_3_5'))
      .max(5, t('errorMessages.TXT_VALIDATE_3_5'))
      .matches('(?=.*[0-9])(?=.*[A-Z]).{3,5}', t('errorMessages.TXT_VALIDATE_3_5')),
    pointName: Yup.string().max(100, t('errorMessages.TXT_MAX_100')),
    totalIssueNumber: Yup.string().max(100, t('errorMessages.TXT_MAX_100')),
    holderLimit: Yup.string(),
  });

  const {
    control,
    handleSubmit,
    formState: { errors, isDirty, isValid },
    reset,
    setValue,
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
    mode: 'onChange',
  });

  const pickUpImg = async () => {
    const img = await documentPicker.getDocumentAsync({
      type: 'image/png',
    });
    setPickImg(img.name);
    setValue('pointIcon', img.name);
  };

  const openModalBack = () => {
    if (isDirty || isValid) {
      setIsModalBack(!isModalBack);
    } else {
      history.push('/tokens');
    }
  };

  const otherModal = () => {
    setIsModalBack(false);
    setIsModal(true);
  };

  const onSubmit = (data) => {
    setIsModal(true);
    console.log(data);
    const newData = {
      ...data,
      uploadFile: pickImg,
    };
  };

  useEffect(() => {
    scroll2View(Object.keys(errors)[0], scrollViewRef.current);
  }, [errors]);

  return (
    <>
      <ScrollView ref={scrollViewRef} showsVerticalScrollIndicator={false} showsHorizontalScrollIndicator={false}>
        <View style={styles.positionSticky}>
          <View style={styles.headerCommunity}>
            <HeaderCommunity
              textHeaderCommon={t('components_Headers_HeaderCommunity.textHeaderCommon')}
              iconBack="iconX.svg"
              iconHeader="point.svg"
              textBack={t('commonText.close')}
              haveModal
              handleBack={openModalBack}
              isDirty={isDirty}
            />
          </View>
        </View>

        <View style={styles.content}>
          <View style={styles.headerContent}>
            <Image style={styles.iconHeaderStyle} source={require(`@assets/icons/iconSetting2.svg`)} />
            <Text style={styles.textHeader}>{t('pages_Token_AddPoint.makeAnInitialSettings')}</Text>
          </View>
          <View style={[styles.inputGroup, { zIndex: 9 }]}>
            <Col size={3} style={styles.label} nativeID="issuerName">
              <Text style={styles.textLabel}>{t('pages_Token_AddPoint.issuerName')}</Text>
            </Col>
            <Col size={9}>
              <DropDownV2
                setValue={setValue}
                name="issuerName"
                width="80%"
                btnColor={BG_COLOR}
                textColor="#F2f2f2"
                text={t('pages_Token_AddPoint.pointIssuerNameNote')}
                dataOption={[
                  { option: 'demo1', value: '001' },
                  { option: 'demo2', value: '002' },
                  { option: 'demo3', value: '003' },
                  { option: 'demo4', value: '004' },
                ]}
                changeColor="#757575"
              />
            </Col>
          </View>

          <View style={styles.inputGroup}>
            <Col size={3} style={styles.label} nativeID="pointIcon">
              <Text style={styles.textLabel}>{t('pages_Token_AddPoint.pointIcon')}</Text>
            </Col>
            <Col size={9}>
              <TouchableWithoutFeedback onPress={() => pickUpImg()}>
                <View style={styles.selectImg}>
                  <Text style={[styles.textSelect, pickImg && { color: COLOR_TEXT_DEFAULT }]}>
                    {pickImg ? pickImg : t('pages_Token_AddPoint.selectAnImage')}
                  </Text>
                  <Image style={styles.subfixIcon} source={require(`@assets/icons/iconView.svg`)} />
                </View>
              </TouchableWithoutFeedback>
            </Col>
          </View>
          <View style={styles.inputGroup}>
            <Col size={3} style={styles.label} nativeID="symbol">
              <Text style={styles.textLabel}>{t('pages_Token_AddPoint.symbol')}</Text>
            </Col>
            <Col size={9}>
              <JInput
                placeholder={t('pages_Token_AddPoint.symbol_placeholder')}
                width="80%"
                subfix="iconEdit.svg"
                widthSubfix={18}
                heightSubfix={18}
                control={control}
                name="symbol"
                backgroundColor={BG_COLOR}
                borderError={errors.symbol ? '#FF0000' : '#5C5C5C'}
              />
            </Col>
          </View>

          <View style={styles.inputGroup}>
            <Col size={3} style={styles.label} nativeID="pointName">
              <Text style={styles.textLabel}>{t('pages_Token_AddPoint.pointName')}</Text>
            </Col>
            <Col size={9}>
              <JInput
                placeholder={t('pages_Token_AddPoint.pointName_placeholder')}
                width="80%"
                subfix="iconEdit.svg"
                widthSubfix={18}
                heightSubfix={18}
                control={control}
                name="pointName"
                backgroundColor={BG_COLOR}
                borderError={errors.pointName ? '#FF0000' : '#5C5C5C'}
              />
            </Col>
          </View>
          <View style={styles.inputGroup}>
            <Col size={3} nativeID="totalIssueNumber">
              <View style={styles.label}>
                <Text style={styles.textLabel}>{t('pages_Token_AddPoint.holderLimit')}</Text>
              </View>
              <Text style={styles.placeholder}>{t('pages_Token_AddPoint.holderLimit_note1')}</Text>
              <Text style={styles.placeholder}>{t('pages_Token_AddPoint.holderLimit_note2')}</Text>
            </Col>
            <Col size={9}>
              <JInput
                placeholder={t('pages_Token_AddPoint.totalIssueNumber_placeholder')}
                width="80%"
                subfix="iconEdit.svg"
                widthSubfix={18}
                heightSubfix={18}
                control={control}
                name="totalIssueNumber"
                backgroundColor={BG_COLOR}
                borderError={errors.totalIssueNumber ? '#FF0000' : '#5C5C5C'}
              />
            </Col>
          </View>
          <View style={styles.inputGroup}>
            <Col size={3} style={styles.label} nativeID="holderLimit">
              <Text style={styles.textLabel}>{t('pages_Token_AddPoint.totalIssueNumber')}</Text>
            </Col>
            <Col size={9}>
              <JInput
                placeholder={t('pages_Token_AddPoint.holderLimit_placeholder')}
                width="80%"
                subfix="iconEdit.svg"
                widthSubfix={18}
                heightSubfix={18}
                control={control}
                name="holderLimit"
                backgroundColor={BG_COLOR}
                borderError={errors.holderLimit ? '#FF0000' : '#5C5C5C'}
              />
            </Col>
          </View>

          <View style={styles.buttonSubmit}>
            <LinearGradient colors={['#54DBB2', '#04C48B']} style={{ borderRadius: 5 }}>
              <JButton
                text={t('pages_Token_AddPoint.issue')}
                width={200}
                textColor="#fff"
                onPressAction={() => handleSubmit(onSubmit)()}
                // disabled={!isDirty}
              />
            </LinearGradient>
          </View>
        </View>
        <ModalAlert
          isModalVisible={isModalSave}
          setModalVisible={setIsModalSave}
          modalText={t('pages_Token_AddPoint.modalAlertText')}
          iconWidth={16}
          iconHeight={12.5}
          icon="successGreen.svg"
        />
        <ModalAlert
          isModalVisible={isModal}
          setModalVisible={setIsModal}
          modalText={t('pages_Token_AddPoint.modalAlertText')}
          iconWidth={16}
          iconHeight={12.5}
          icon="successGreen.svg"
          linkPage="/tokens"
        />
        <ModalSendSuccess
          textHeader={t('pages_Token_AddGift.modalAlertSuccess')}
          content={t('pages_Token_AddGift.modalAlertSuccessNote')}
          isModalVisible={isModal}
          setModalVisible={setIsModal}
          linkBack="/tokens"
        />
        <ModalBack isModal={isModalBack} setIsModal={setIsModalBack} otherModal={otherModal} />
      </ScrollView>
    </>
  );
};

export default withNamespaces()(AddPoint);

import React from 'react';
import { useHistory } from 'react-router-dom';
import Modal from 'react-native-modal';
import moment from 'moment';
import { StyleSheet, View, Image, TouchableOpacity } from 'react-native';
import { withNamespaces } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { JText as Text } from '../common/JText';
import { logout } from '@utils/TokenStorage';
import { clearUser, setIsLoaded, setRouteByUserInfo } from '@redux/slices/authSlice';
import ModalBackDrop from './ModalBackDrop';

const ModalLogout = ({ isModal, setIsModal, t }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const handleLogout = () => {
    dispatch(clearUser());
    dispatch(setIsLoaded(true));
    dispatch(setRouteByUserInfo(true));
    logout();
    setIsModal(false);
    history.push('/login');
  };
  return (
    <Modal
      backdropTransitionOutTiming={1}
      animationOutTiming={1}
      animationInTiming={1}
      isVisible={isModal}
      style={styles.modal}
      customBackdrop={<ModalBackDrop onPress={handleLogout} />}
    >
      <TouchableOpacity onPress={handleLogout}>
        <View style={styles.warper}>
          <Image style={styles.iconLogout} source={require(`../../assets/icons/logout-gray.svg`)} />
          <Text style={styles.text}>{`${t('commonText.signedOut')}　${moment().format('YYYY.MM.DD HH:mm')}`}</Text>
        </View>
      </TouchableOpacity>
    </Modal>
  );
};

const styles = StyleSheet.create({
  //common
  modal: {
    backgroundColor: 'transparent',
    alignItems: 'center',
    margin: 0,
  },
  warper: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    paddingHorizontal: 120,
    paddingVertical: 25,
    backgroundColor: 'white',
    height: 100,
  },
  iconLogout: {
    width: 28,
    height: 22,
    marginRight: 30,
  },
  text: {
    color: 'black',
  },
});
export default withNamespaces()(ModalLogout);

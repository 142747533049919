import React, { memo } from 'react';
import { View, StyleSheet, TouchableOpacity } from 'react-native';
import { BG_COLOR, TEXT_COLOR_GREEN, TEXT_COLOR_GREY } from '@assets/style/styleDefault';
import { JText as Text } from './JText';

const Pagination = ({ count, params, setParams }) => {
  const PAGE_BEFORE_THREE_DOTS = 4;
  const PAGE_SHOW_THREE_DOTS = 8;
  let page = params?.pageNumber;

  const onNext = () => {
    page != count
      ? setParams({
          ...params,
          pageNumber: +page + 1,
        })
      : null;
  };
  const onPrev = () => {
    page != 1
      ? setParams({
          ...params,
          pageNumber: +page - 1,
        })
      : null;
  };

  const onChange = (e) => {
    setParams({
      ...params,
      pageNumber: parseInt(e.target.innerText),
    });
  };

  return (
    <View style={styles.container}>
      <View style={styles.wrapperPagination}>
        {count >= PAGE_SHOW_THREE_DOTS && (
          <>
            <TouchableOpacity style={[styles.wrapperItem, { borderLeftWidth: 2 }]} onPress={() => onPrev()}>
              <View style={styles.wrapperText}>
                <Text style={[styles.paginationText, styles.colorGray, styles.fw600]}>-</Text>
              </View>
            </TouchableOpacity>
            {page >= 1 && page <= PAGE_BEFORE_THREE_DOTS - 1 && (
              <>
                <TouchableOpacity
                  style={page == 1 ? [styles.wrapperItem, styles.wrapperItemActive] : styles.wrapperItem}
                  onPress={onChange}
                >
                  <View style={page == 1 ? [styles.wrapperText, styles.active] : styles.wrapperText}>
                    <Text
                      style={
                        page == 1
                          ? [styles.paginationText, styles.activeText]
                          : [styles.paginationText, styles.colorGray]
                      }
                    >
                      1
                    </Text>
                  </View>
                </TouchableOpacity>

                <TouchableOpacity
                  style={page == 2 ? [styles.wrapperItem, styles.wrapperItemActive] : styles.wrapperItem}
                  onPress={onChange}
                >
                  <View style={page == 2 ? [styles.wrapperText, styles.active] : styles.wrapperText}>
                    <Text
                      style={
                        page == 2
                          ? [styles.paginationText, styles.activeText]
                          : [styles.paginationText, styles.colorGray]
                      }
                    >
                      2
                    </Text>
                  </View>
                </TouchableOpacity>

                <TouchableOpacity
                  style={page == 3 ? [styles.wrapperItem, styles.wrapperItemActive] : styles.wrapperItem}
                  onPress={onChange}
                >
                  <View style={page == 3 ? [styles.wrapperText, styles.active] : styles.wrapperText}>
                    <Text
                      style={
                        page == 3
                          ? [styles.paginationText, styles.activeText]
                          : [styles.paginationText, styles.colorGray]
                      }
                    >
                      3
                    </Text>
                  </View>
                </TouchableOpacity>
                <TouchableOpacity style={styles.wrapperItem} onPress={onChange}>
                  <View style={styles.wrapperText}>
                    <Text style={[styles.paginationText, styles.colorGray]}>4</Text>
                  </View>
                </TouchableOpacity>
                <View style={styles.wrapperItem} onPress={onChange}>
                  <View style={styles.wrapperText}>
                    <Text style={[styles.paginationText, styles.colorGray]}>...</Text>
                  </View>
                </View>
                <TouchableOpacity style={styles.wrapperItem} onPress={onChange}>
                  <View style={styles.wrapperText}>
                    <Text style={[styles.paginationText, styles.colorGray]}>{count}</Text>
                  </View>
                </TouchableOpacity>
              </>
            )}
            {page >= PAGE_BEFORE_THREE_DOTS && page <= count - PAGE_BEFORE_THREE_DOTS && (
              <>
                <TouchableOpacity style={styles.wrapperItem} onPress={onChange}>
                  <View style={styles.wrapperText}>
                    <Text style={[styles.paginationText, styles.colorGray]}>1</Text>
                  </View>
                </TouchableOpacity>
                <View style={styles.wrapperItem} onPress={onChange}>
                  <View style={styles.wrapperText}>
                    <Text style={[styles.paginationText, styles.colorGray]}>...</Text>
                  </View>
                </View>
                <TouchableOpacity style={styles.wrapperItem} onPress={onChange}>
                  <View style={styles.wrapperText}>
                    <Text style={[styles.paginationText, styles.colorGray]}>{+page - 1}</Text>
                  </View>
                </TouchableOpacity>
                <TouchableOpacity style={[styles.wrapperItem, styles.wrapperItemActive]} onPress={onChange}>
                  <View style={[styles.wrapperText, styles.active]}>
                    <Text style={[styles.paginationText, styles.activeText]}>{+page}</Text>
                  </View>
                </TouchableOpacity>
                <TouchableOpacity style={styles.wrapperItem} onPress={onChange}>
                  <View style={styles.wrapperText}>
                    <Text style={[styles.paginationText, styles.colorGray]}>{+page + 1}</Text>
                  </View>
                </TouchableOpacity>
                <View style={styles.wrapperItem} onPress={onChange}>
                  <View style={styles.wrapperText}>
                    <Text style={[styles.paginationText, styles.colorGray]}>...</Text>
                  </View>
                </View>
                <TouchableOpacity style={styles.wrapperItem} onPress={onChange}>
                  <View style={styles.wrapperText}>
                    <Text style={[styles.paginationText, styles.colorGray]}>{count}</Text>
                  </View>
                </TouchableOpacity>
              </>
            )}
            {page >= count - (PAGE_BEFORE_THREE_DOTS - 1) && page <= count && (
              <>
                <TouchableOpacity style={styles.wrapperItem} onPress={onChange}>
                  <View style={styles.wrapperText}>
                    <Text style={[styles.paginationText, styles.colorGray]}>1</Text>
                  </View>
                </TouchableOpacity>
                <View style={styles.wrapperItem} onPress={onChange}>
                  <View style={styles.wrapperText}>
                    <Text style={[styles.paginationText, styles.colorGray]}>...</Text>
                  </View>
                </View>
                <TouchableOpacity
                  style={page == count - 3 ? [styles.wrapperItem, styles.wrapperItemActive] : styles.wrapperItem}
                  onPress={onChange}
                >
                  <View style={page == count - 3 ? [styles.wrapperText, styles.active] : styles.wrapperText}>
                    <Text
                      style={
                        page == count - 3
                          ? [styles.paginationText, styles.activeText]
                          : [styles.paginationText, styles.colorGray]
                      }
                    >
                      {count - 3}
                    </Text>
                  </View>
                </TouchableOpacity>
                <TouchableOpacity
                  style={page == count - 2 ? [styles.wrapperItem, styles.wrapperItemActive] : styles.wrapperItem}
                  onPress={onChange}
                >
                  <View style={page == count - 2 ? [styles.wrapperText, styles.active] : styles.wrapperText}>
                    <Text
                      style={
                        page == count - 2
                          ? [styles.paginationText, styles.activeText]
                          : [styles.paginationText, styles.colorGray]
                      }
                    >
                      {count - 2}
                    </Text>
                  </View>
                </TouchableOpacity>
                <TouchableOpacity
                  style={page == count - 1 ? [styles.wrapperItem, styles.wrapperItemActive] : styles.wrapperItem}
                  onPress={onChange}
                >
                  <View style={page == count - 1 ? [styles.wrapperText, styles.active] : styles.wrapperText}>
                    <Text
                      style={
                        page == count - 1
                          ? [styles.paginationText, styles.activeText]
                          : [styles.paginationText, styles.colorGray]
                      }
                    >
                      {count - 1}
                    </Text>
                  </View>
                </TouchableOpacity>
                <TouchableOpacity
                  style={page == count ? [styles.wrapperItem, styles.wrapperItemActive] : styles.wrapperItem}
                  onPress={onChange}
                >
                  <View style={page == count ? [styles.wrapperText, styles.active] : styles.wrapperText}>
                    <Text
                      style={
                        page == count
                          ? [styles.paginationText, styles.activeText]
                          : [styles.paginationText, styles.colorGray]
                      }
                    >
                      {count}
                    </Text>
                  </View>
                </TouchableOpacity>
              </>
            )}
            <TouchableOpacity style={[styles.wrapperItem, { borderRightWidth: 2 }]} onPress={() => onNext()}>
              <View style={styles.wrapperText}>
                <Text style={[styles.paginationText, styles.colorGray, styles.fw600]}>+</Text>
              </View>
            </TouchableOpacity>
          </>
        )}
        {count < PAGE_SHOW_THREE_DOTS && count > 1 && (
          <>
            <TouchableOpacity style={[styles.wrapperItem, { borderLeftWidth: 2 }]} onPress={() => onPrev()}>
              <View style={styles.wrapperText}>
                <Text style={[styles.paginationText, styles.colorGray, styles.fw600]}>-</Text>
              </View>
            </TouchableOpacity>
            {[...Array(count).keys()].map((item) => {
              return page == item + 1 ? (
                <TouchableOpacity key={item} style={[styles.wrapperItem, styles.wrapperItemActive]} onPress={onChange}>
                  <View style={[styles.wrapperText, styles.active]}>
                    <Text style={[styles.paginationText, styles.activeText]}>{item + 1}</Text>
                  </View>
                </TouchableOpacity>
              ) : (
                <TouchableOpacity key={item} style={styles.wrapperItem} onPress={onChange}>
                  <View style={styles.wrapperText}>
                    <Text style={[styles.paginationText, styles.colorGray]}>{item + 1}</Text>
                  </View>
                </TouchableOpacity>
              );
            })}
            <TouchableOpacity style={[styles.wrapperItem, { borderRightWidth: 2 }]} onPress={() => onNext()}>
              <View style={styles.wrapperText}>
                <Text style={[styles.paginationText, styles.colorGray, styles.fw600]}>+</Text>
              </View>
            </TouchableOpacity>
          </>
        )}
      </View>
    </View>
  );
};

export default memo(Pagination);

const styles = StyleSheet.create({
  container: {
    paddingTop: 70,
    paddingBottom: 70,
    flex: 1,
    width: '100%',
    position: 'absolute',
    bottom: -190,
  },
  wrapperPagination: {
    flex: 1,
    justifyContent: 'center',
    flexDirection: 'row',
  },
  wrapperItem: {
    paddingTop: 10,
    paddingBottom: 10,
    paddingRight: 15,
    paddingLeft: 15,
    borderWidth: 1,
    borderColor: 'rgba(92, 92, 92, 0.3)',
    backgroundColor: `${BG_COLOR}`,
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    borderBottomWidth: 2,
    borderTopWidth: 2,
  },
  wrapperText: {
    width: 40,
    height: 40,
    justifyContent: 'center',
    alignItems: 'center',
  },
  paginationText: {
    color: `${TEXT_COLOR_GREY}`,
  },
  colorGray: {
    color: '#777777',
  },
  active: {
    borderRadius: '50%',
    borderWidth: '1px',
    borderColor: TEXT_COLOR_GREEN,
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    width: 40,
    height: 40,
  },
  activeText: {
    color: TEXT_COLOR_GREEN,
  },
  fw600: {
    fontWeight: 600,
    color: 'white',
  },
});

import React, { useMemo, useState } from 'react';
import { View, StyleSheet, Image, TouchableOpacity } from 'react-native';
import { useHistory, useLocation } from 'react-router-dom';
import { withNamespaces } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { LinearGradient } from 'expo-linear-gradient';
import { useSelector } from 'react-redux';

import JButton from '../common/JButton';
import ModalAlert from '../Modals/ModalAlert';
import ModalDelete from '@components/Modals/ModalDelete';
import { JText as Text } from '../common/JText';
import { BTN_COLOR_GREEN, COLOR_TEXT_DEFAULT, TEXT_COLOR_GREEN, TEXT_COLOR_GREY } from '@assets/style/styleDefault';
import { del, putMulti } from '@utils/RequestLevica';
import scroll2View from '@helper/scroll2View';
import { checkPermission } from '@helper/commonFunction';
import { ROLE_USER } from '@helper/constant';

const HeaderAccount = (props) => {
  const history = useHistory();
  const [openModalUpdate, setOpenModalUpdate] = useState(false);
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [openModalBack, setOpenModalBack] = useState(false);
  const [modalDeleteSuccess, setModalDeleteSuccess] = useState(false);
  const { pathname } = useLocation();
  const {
    showBackButton,
    checkDirtyFields,
    icon,
    text,
    subTitle,
    widthIconHeader = 40,
    heightIconHeader = 36,
    linkWhenClose = '/accounts',
    updateAPI,
    deleteAPI,
    t,
    textCreateAccountSuccess = '',
    scrollViewRef,
    formValue,
    onUpdateSuccess = () => {},
    removeRecordSuccessMessage = null,
  } = props;

  const { handleSubmit, setError, reset } = useFormContext();
  const [isUpdating, setIsUpdating] = useState(false);
  const [modalAdminStopDone, setModalAdminStopDone] = useState(false);
  const [modalTextAdminStopDone, setModalTextAdminStopDone] = useState();
  const userInfo = useSelector((state) => state.merchantUserInfo);

  const errorMapByPage = useMemo(() => {
    const errorMap = [
      {
        key: 'accounts',
        email: 'email',
        phone: 'phone',
      },
      {
        key: 'companies',
        email: 'issuerUserEmail',
        phone: 'phone',
      },
      {
        key: 'shop',
        email: 'merchantUserEmail',
        phone: 'phone',
      },
    ];

    return errorMap.find(({ key }) => pathname.includes(key));
  }, [pathname]);

  const handleUpdate = async (data) => {
    if (data?.phone?.length < 6) {
      setError('phone', {
        type: 'custom',
        message: t('errorMessages.TXT_JP_PHONE'),
      });
      scroll2View('phone', scrollViewRef.current);
      return;
    } else if (data?.landlineNumber?.length < 6) {
      setError('landlineNumber', {
        type: 'custom',
        message: t('errorMessages.TXT_JP_PHONE'),
      });
      scroll2View('landlineNumber', scrollViewRef.current);
      return;
    } else if (data.traderPhoneNumber?.length < 6) {
      setError('traderPhoneNumber', {
        type: 'custom',
        message: t('errorMessages.TXT_JP_PHONE'),
      });
      scroll2View('traderPhoneNumber', scrollViewRef.current);
      return;
    }
    try {
      setIsUpdating(true);
      await putMulti(updateAPI, { ...data, capital: parseInt(data?.capital?.replace(/,/g, '')) });
      setOpenModalUpdate(true);
      onUpdateSuccess();
      reset({}, { keepValues: true });
    } catch (error) {
      if (error?.response?.data?.code == '3014') {
        setError(errorMapByPage?.email, { type: 'custom', message: t('errorMessages.TXT_REGISTERED_EMAIL') });
        scroll2View(errorMapByPage?.email, scrollViewRef.current);
      } else if (error?.response?.data?.code == '3015') {
        setError(errorMapByPage?.phone, { type: 'custom', message: t('errorMessages.TXT_REGISTERED_PHONE') });
        scroll2View(errorMapByPage?.phone, scrollViewRef.current);
      } else if (error?.response?.data?.code == '3018') {
        setError('leviasChainAddress', {
          type: 'custom',
          message: t('errorMessages.TXT_WALLET_ADDRESS_DOES_NOT_EXIST'),
        });
        scroll2View('leviasChainAddress', scrollViewRef.current);
      }
    } finally {
      setIsUpdating(false);
    }
  };

  const handleDelete = async () => {
    try {
      await del(deleteAPI);
      setOpenModalDelete(false);
      setModalDeleteSuccess(true);
    } catch (error) {
      if (error?.response?.data?.code == '4018') {
        setModalAdminStopDone(true);
        setModalTextAdminStopDone('pages_AccountManagement_CreateAccount.delete_fail1');
      } else if (error?.response?.data?.code == '4017') {
        setModalAdminStopDone(true);
        setModalTextAdminStopDone('pages_AccountManagement_CreateAccount.delete_fail2');
      }
    }
  };

  const handleBackBtn = () => {
    history.push(linkWhenClose);
  };

  return (
    <View style={styles.headerContainer}>
      <View style={styles.headerLeft}>
        <View>
          <View style={styles.title}>
            {showBackButton && (
              <TouchableOpacity style={styles.headerBack} onPress={handleBackBtn}>
                <View style={styles.buttonHeaderLeft}>
                  <Image style={styles.buttonBack} source={require('@assets/icons/iconLeft.svg')} />
                  <Text style={styles.textBack}>{t('commonText.backArrow')}</Text>
                </View>
              </TouchableOpacity>
            )}
            <Image
              style={[styles.icon, { width: widthIconHeader, height: heightIconHeader }]}
              source={require(`../../assets/icons/${icon}`)}
            />

            <Text style={styles.textTitle}>{text}</Text>
          </View>
          {subTitle && <Text style={styles.subTitle}>{subTitle}</Text>}
        </View>
      </View>
      <View>
        {checkPermission(ROLE_USER.USER_MANAGEMENT, userInfo) && (
          <View style={styles.formAction}>
            <LinearGradient colors={['#FE8463', '#FF0000']} style={{ borderRadius: 5 }}>
              <JButton
                text={t('commonText.buttonDelete')}
                textColor="#ffffff"
                width={150}
                hasIcon
                linkIcon="trash.svg"
                widthIcon={20}
                heightIcon={20}
                onPressAction={() => setOpenModalDelete(!openModalDelete)}
              />
            </LinearGradient>
            <LinearGradient colors={['#54DBB2', '#04C48B']} style={{ borderRadius: 5 }}>
              <JButton
                text={t('commonText.buttonSave')}
                textColor="#ffffff"
                width={150}
                hasIcon
                linkIcon="save.svg"
                linkIconDisabled="saveGray.svg"
                widthIcon={20}
                heightIcon={20}
                disabled={isUpdating || checkDirtyFields}
                onPressAction={handleSubmit(handleUpdate)}
                isProcessing={isUpdating}
              />
            </LinearGradient>
          </View>
        )}

        <ModalAlert
          isModalVisible={openModalUpdate}
          setModalVisible={setOpenModalUpdate}
          icon="successGreen.svg"
          iconWidth={15}
          iconHeight={15}
          modalText={textCreateAccountSuccess}
        />
        <ModalAlert
          isModalVisible={modalDeleteSuccess}
          setModalVisible={setModalDeleteSuccess}
          modalText={removeRecordSuccessMessage ?? t('components_Modals_Headers.modalAlertText2')}
          iconWidth={16}
          iconHeight={12.5}
          icon="success-red.svg"
          onBackdropPressCallback={handleBackBtn}
          linkPage={linkWhenClose}
        />
        <ModalDelete
          iconBtnLeft="close-white.svg"
          iconBtnRight="delete-white.svg"
          heightIconBtnRight={21}
          widthIconBtnRight={21}
          marginBottomIconRight={8}
          modalDelete={openModalDelete}
          setModalDelete={setOpenModalDelete}
          modalAlert={modalDeleteSuccess}
          icon="deletePersonal.svg"
          textHeader={t('components_Modals_Headers.modalDeleteText1')}
          textHeaderColor="#191919"
          textContent={[
            `LEVICA ID. ${formValue?.displayId ?? ''} ${t('components_Modals_Headers.modalDeleteText2')}`,
            t('components_Modals_Headers.modalDeleteText3'),
            t('components_Modals_Headers.modalDeleteText4'),
          ]}
          colorTextTitle="#5A5A5A"
          pv={20}
          iconBody="warning.svg"
          // func={() => history.push('/accounts')}
          func={() => handleDelete()}
          textBtnLeft={t('commonText.cancelButton')}
          textBtnRight={t('commonText.buttonDeleteFile')}
        />
        <ModalDelete
          textContent={[
            t('components_Modals_Headers.modalDeleteText5'),
            t('components_Modals_Headers.modalDeleteText6'),
          ]}
          textHeader={t('components_Modals_Headers.modalDeleteText7')}
          modalDelete={openModalBack}
          setModalDelete={setOpenModalBack}
          iconBtnRight="save.svg"
          iconBody="warning.svg"
          btnColorRight={BTN_COLOR_GREEN}
          pv={20}
          func={() => {
            history.goBack();
          }}
          colorTextTitle={COLOR_TEXT_DEFAULT}
        />
        {modalAdminStopDone && (
          <ModalAlert
            isModalVisible={modalAdminStopDone}
            setModalVisible={setModalAdminStopDone}
            icon="stop.svg"
            iconWidth={20}
            iconHeight={16}
            modalText={t(modalTextAdminStopDone)}
          />
        )}
      </View>
    </View>
  );
};

export default withNamespaces()(HeaderAccount);

const styles = StyleSheet.create({
  //header

  headerBack: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    cursor: 'pointer',
  },
  buttonHeaderLeft: {
    flexDirection: 'row',
    alignItems: 'center',
    marginRight: '2rem',
  },
  buttonBack: {
    height: 23,
    width: 15,
  },
  textBack: {
    fontSize: 14,
    marginLeft: 5,
    fontWeight: 600,
    color: TEXT_COLOR_GREEN,
  },
  icon: {
    flex: 1,
    width: 40,
    height: 36,
    resizeMode: 'contain',
  },
  title: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  textTitle: {
    fontSize: 16,
    marginTop: 5,
    marginLeft: 10,
    fontWeight: 600,
  },
  subTitle: {
    color: TEXT_COLOR_GREY,
    textAlign: 'end',
    fontSize: 13,
  },
  formAction: {
    display: 'flex',
    flexDirection: 'row',
    gap: 25,
  },
  headerContainer: {
    height: 80,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingHorizontal: 15,
  },
  headerLeft: {
    display: 'flex',
    flexDirection: 'row',
  },
});

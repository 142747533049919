import React from 'react';
import { Col, Grid } from 'react-native-easy-grid';
import { withNamespaces } from 'react-i18next';

import styles from '../styles';
import AccountInput from '@components/AccountComponents/AccountInput';
import { JText as Text } from '@components/common/JText';
import AccountInputPhone from '@components/AccountComponents/AccountInputPhone';

const TransactionPersonForm = ({ control, disable, t }) => {
  //traderDepartment to traderEmail
  return (
    <>
      <Grid style={styles.marginBottom20}>
        <Col size={4}>
          <Text style={styles.colorWhite}>{t('pages_AccountCompany_CreateAccount.transactionPersonForm')}</Text>
        </Col>
        <Col size={8}></Col>
      </Grid>

      <AccountInput
        disable={disable}
        control={control}
        name="traderDepartment"
        placeholder={t('pages_AccountCompany_CreateAccount.transactionDepartmentPlaceholder')}
        isRequired={true}
        labelText={t('pages_AccountCompany_CreateAccount.transactionDepartment')}
        subfix="iconEdit.svg"
        ml={12}
      />

      <AccountInput
        disable={disable}
        control={control}
        name="traderDirector"
        placeholder={t('pages_AccountCompany_CreateAccount.transactionTitlePlaceholder')}
        isRequired={true}
        labelText={t('pages_AccountCompany_CreateAccount.transactionTitle')}
        subfix="iconEdit.svg"
        ml={12}
      />

      <AccountInput
        disable={disable}
        control={control}
        name="traderLastName"
        placeholder={t('pages_AccountCompany_CreateAccount.transactionLastNamePlaceholder')}
        isRequired={true}
        labelText={t('pages_AccountCompany_CreateAccount.transactionLastName')}
        subfix="iconEdit.svg"
        ml={12}
      />

      <AccountInput
        disable={disable}
        control={control}
        name="traderLastNameKana"
        placeholder={t('pages_AccountCompany_CreateAccount.transactionLastNameKanaPlaceholder')}
        isRequired={true}
        labelText={t('pages_AccountCompany_CreateAccount.transactionLastNameKana')}
        subfix="iconEdit.svg"
        ml={12}
      />
      <AccountInput
        disable={disable}
        control={control}
        name="traderFirstName"
        placeholder={t('pages_AccountCompany_CreateAccount.transactionFirstNamePlaceholder')}
        isRequired={true}
        labelText={t('pages_AccountCompany_CreateAccount.transactionFirstName')}
        subfix="iconEdit.svg"
        ml={12}
      />
      <AccountInput
        disable={disable}
        control={control}
        name="traderFirstNameKana"
        placeholder={t('pages_AccountCompany_CreateAccount.transactionFirstNameKanaPlaceholder')}
        isRequired={true}
        labelText={t('pages_AccountCompany_CreateAccount.transactionFirstNameKana')}
        subfix="iconEdit.svg"
        ml={12}
      />

      {/* <AccountInput
        disable={disable}
        control={control}
        name="traderPhoneNumber"
        placeholder={t('pages_AccountCompany_CreateAccount.transactionPhoneNumberPlaceholder')}
        isRequired={true}
        labelText={t('pages_AccountCompany_CreateAccount.transactionPhoneNumber')}
        subfix="iconEdit.svg"
        ml={12}
      /> */}
      <AccountInputPhone
        disable={disable}
        labelText={t('pages_AccountCompany_CreateAccount.transactionPhoneNumber')}
        isRequired={true}
        name="traderPhoneNumber"
        control={control}
      />
      <AccountInput
        disable={disable}
        control={control}
        name="traderEmail"
        placeholder={t('pages_AccountCompany_CreateAccount.transactionEmailPlaceholder')}
        isRequired={true}
        labelText={t('pages_AccountCompany_CreateAccount.transactionEmail')}
        subfix="iconEdit.svg"
        ml={12}
      />
    </>
  );
};

export default withNamespaces()(TransactionPersonForm);

import React from 'react';
import { StyleSheet, View } from 'react-native';
import { Col, Grid } from 'react-native-easy-grid';

import { INPUT_BG, TEXT_COLOR_GREY, TEXT_COLOR_RED } from '@assets/style/styleDefault';
import { JText as Text } from '../common/JText';
import PhoneInput from '@components/common/PhoneInput';
import JInput from '@components/common/JInput';

const AccountInputPhone = ({ name, control, labelText, subLabelText, isRequired, startIcon, ml, disable }) => {
  return (
    <Grid style={[styles.marginBottom20, { minHeight: 'unset' }]}>
      <Col size={4} style={styles.titleWithIcon}>
        <View nativeID={name}>
          <View style={[styles.mt5, styles.flexRow]}>
            <Text style={[styles.colorWhite, { marginLeft: ml }]}>{labelText}</Text>
            {isRequired && <Text style={styles.iconRequired}>✴︎</Text>}
          </View>
          {subLabelText && <Text style={styles.textGrey}>{subLabelText}</Text>}
        </View>
        {startIcon && <Text style={{ marginRight: 10 }}>{startIcon}</Text>}
      </Col>

      <Col size={8} style={[styles.flexCenter]}>
        {disable ? (
          <PhoneInput name={name} control={control} borderRadius={5} paddingLeft={10} disable={!disable} />
        ) : (
          <JInput disable={disable} name={name} control={control} paddingLeft={10} />
        )}
      </Col>
    </Grid>
  );
};

export default AccountInputPhone;

const styles = StyleSheet.create({
  relative: {
    position: 'relative',
  },
  input: {
    border: `1px solid ${INPUT_BG}`,
    borderRadius: 4,
    paddingLeft: 10,
    paddingRight: 30,
    outlineStyle: 'none',
  },
  inputSubfix: {
    height: 15,
    width: 15,
    position: 'absolute',
    right: 10,
    top: 13,
  },
  error: {
    color: TEXT_COLOR_RED,
  },
  marginBottom20: {
    marginBottom: 20,
  },
  flex: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  iconRequired: {
    color: TEXT_COLOR_RED,
    marginLeft: 6,
    fontSize: 16,
  },
  flexCenter: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  mt5: {
    marginTop: 5,
  },
  flexRow: {
    display: 'flex',
    flexDirection: 'row',
  },
  textGrey: {
    color: TEXT_COLOR_GREY,
  },
  startIcon: {
    position: 'absolute',
    left: -15,
    top: '25%',
  },
  titleWithIcon: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
});

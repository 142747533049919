import React, { useState } from 'react';
import { View, Image, StyleSheet, TouchableOpacity, ScrollView, TouchableWithoutFeedback } from 'react-native';
import Modal from 'react-native-modal';
import { Link } from 'react-router-dom';
import { withNamespaces } from 'react-i18next';
import { useSelector } from 'react-redux';

import { BG_SIDEBAR, COLOR_TEXT_DEFAULT, TEXT_COLOR_GREEN } from '@assets/style/styleDefault';
import { JText as Text } from '../common/JText';
import ModalLogout from './ModalLogout';
import { converTextCamelCase, statusColor } from '@helper/formatTypes';
import { Pressable } from 'native-base';
import ModalBackDrop from './ModalBackDrop';

const ModalAvatar = (props) => {
  const { t, isModalVisible, setModalVisible } = props;
  const [isModalLogout, setIsModalLogout] = useState(false);
  const userInfo = useSelector((state) => state.merchantUserInfo);
  return (
    <View>
      <Modal
        animationIn="fadeIn"
        isVisible={isModalVisible}
        style={styles.modals}
        customBackdrop={<ModalBackDrop onPress={() => setModalVisible(false)} />}
      >
        <View style={styles.wrapperModal}>
          <ScrollView showsVerticalScrollIndicator={false}>
            <View style={styles.wrapperHeader}>
              <Text style={styles.textInfo}>Admin type</Text>
              <View style={styles.avatar}>
                {statusColor(userInfo?.masterType) ? (
                  <View style={[styles.status, { backgroundColor: statusColor(userInfo?.masterType) }]} />
                ) : null}
                <Text>{converTextCamelCase(userInfo?.masterType)}</Text>
              </View>
            </View>
            <View style={styles.wrapperContent}>
              <View style={styles.wrapperImageAvatar}>
                {userInfo?.imageUrl ? (
                  <Image style={styles.imgAvatar} source={{ uri: userInfo?.imageUrl }} />
                ) : (
                  <Image style={styles.imgAvatar} source={require(`@assets/image/userDefaultAvatar.jpg`)} />
                )}
                <Text style={styles.textAvatar} numberOfLines={4}>
                  {userInfo?.name}
                </Text>
              </View>

              <Pressable>
                {({ isHovered, isFocused, isPressed }) => {
                  return (
                    <Link to="/admin" style={{ textDecorationLine: 'none', marginTop: '1rem' }}>
                      <View style={[styles.flexRow, { justifyContent: 'center' }]}>
                        <Image
                          style={styles.iconNote}
                          source={require(`../../assets/icons/${isHovered ? 'iconNoteGreen.svg' : 'iconNote.svg'}`)}
                        />
                        <Text style={{ color: isHovered ? TEXT_COLOR_GREEN : COLOR_TEXT_DEFAULT }}>
                          {t('pages_Setting_Info.accountDetails')}
                        </Text>
                      </View>
                    </Link>
                  );
                }}
              </Pressable>
              <View style={styles.wrapperInfoText}>
                <View style={styles.wrapperItem}>
                  <Text style={styles.textInfo}>LEVICA ID</Text>
                  <Text style={styles.textInfo}>{userInfo?.levicaId}</Text>
                </View>
                <View style={styles.wrapperItem}>
                  <Text style={styles.textInfo}>{t('commonText.walletAddress')}</Text>
                  <Text style={styles.textInfo}>{userInfo?.walletAddress}</Text>
                </View>
                <View style={styles.wrapperItem}>
                  <Text style={styles.textInfo}>{t('pages_Setting_Info.email')}</Text>
                  <Text style={styles.textInfo}>{userInfo?.email}</Text>
                </View>
                <View style={styles.wrapperItem}>
                  <Text style={styles.textInfo}>{t('pages_Setting_Info.phone')}</Text>
                  <Text style={styles.textInfo}>{userInfo?.phone}</Text>
                </View>
              </View>

              <TouchableOpacity>
                <Pressable>
                  {({ isHovered }) => {
                    return (
                      <View style={styles.wrapperLogout} onClick={() => setIsModalLogout(true)}>
                        <Image
                          style={styles.iconLogout}
                          source={require(`../../assets/icons/${isHovered ? 'logoutGreen.svg' : 'logout.svg'}`)}
                        />
                        <Text style={{ color: isHovered ? TEXT_COLOR_GREEN : COLOR_TEXT_DEFAULT }}>
                          {t('pages_Setting_Info.logout')}
                        </Text>
                      </View>
                    );
                  }}
                </Pressable>
              </TouchableOpacity>
            </View>
          </ScrollView>
        </View>
      </Modal>
      {isModalLogout && <ModalLogout isModal={isModalLogout} setIsModal={setIsModalLogout} />}
    </View>
  );
};

export default withNamespaces()(ModalAvatar);

const styles = StyleSheet.create({
  wrapperLogout: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    height: 100,
    cursor: 'pointer',
  },
  flexRow: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  iconNote: {
    height: 20,
    width: 20,
    marginRight: 10,
    marginBottom: 5,
  },
  modals: {
    alignItems: 'end',
    margin: 0,
    marginTop: 70,
    marginRight: 30,
  },
  wrapperModal: {
    width: '30vw',
    padding: 20,
    paddingHorizontal: 30,
    borderRadius: 5,
    backgroundColor: BG_SIDEBAR,
    flex: 1,
  },
  wrapperHeader: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingBottom: 15,
    borderBottomWidth: 1,
    borderBottomColor: '#5C5C5C',
  },
  wrapperImageAvatar: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingTop: 15,
    paddingBottom: 15,
  },
  avatar: {
    display: 'flex',
    flexDirection: 'row',
    marginLeft: '30%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  status: {
    width: 12,
    height: 12,
    resizeMode: 'contain',
    marginRight: 10,
    marginBottom: 2,
    borderRadius: 50,
  },
  textAvatar: {
    marginLeft: 40,
    fontSize: 18,
  },
  imgAvatar: {
    height: 188,
    width: 188,
    borderRadius: 100,
  },
  iconEdit: {
    width: 25,
    height: 25,
    marginRight: 10,
  },
  wrapperLinkSetting: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: 15,
    paddingBottom: 15,
  },
  wrapperInfoText: {
    paddingTop: 15,
    paddingBottom: 15,
  },
  wrapperItem: {
    paddingTop: 30,
  },

  iconLogout: {
    width: 28,
    height: 22,
    marginRight: 10,
  },
  buttonHovered: {
    backgroundColor: '#00BE85',
  },
});

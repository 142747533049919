import React, { useEffect, useState, memo } from 'react';
import { View, TouchableOpacity, StyleSheet, Image as ImageNative } from 'react-native';
import * as DocumentPicker from 'expo-document-picker';
import { useFormContext } from 'react-hook-form';
import { Col, Grid } from 'react-native-easy-grid';
import { withNamespaces } from 'react-i18next';

import { COLOR_TEXT_DEFAULT, INPUT_BG, TEXT_COLOR_RED } from '@assets/style/styleDefault';
import { JText as Text } from '../common/JText';
import JInput from '../common/JInput';

const InputImage = (props) => {
  const { setValue, getValues, setError, clearErrors, trigger } = useFormContext();
  const [picker, setPicker] = useState(null);
  const { control, placeholder, labelText, isRequired, formValue, setFormValue, t, disable } = props;

  const validateFileSize = (file) =>
    file?.size > 10 * 1024 * 1024
      ? { message: t('errorMessages.TXT_LIMIT_IMAGE_10MB'), type: 'custom', isError: true }
      : { isError: false };

  const validateImgFile = (file) =>
    /^image\//i.test(file.mimeType)
      ? { isError: false }
      : { type: 'custom', message: t('errorMessages.TEXT_ONLY_IMAGE'), isError: true };

  const checkFakeImage = (file) => {
    const img = new Image();
    img.onerror = function () {
      setPicker(null);
      setError('imageTitle', { type: 'custom', message: t('errorMessages.TEXT_ONLY_IMAGE'), isError: true });
    };
    img.src = URL.createObjectURL(file);
  };

  const pickFile = async () => {
    if (!disable) {
      return;
    }
    try {
      const result = await DocumentPicker.getDocumentAsync({
        type: ['image/jpeg', 'image/png'],
      });
      checkFakeImage(result.file);
      const validateImgFileResult = validateImgFile(result);
      if (validateImgFileResult.isError) return setError('imageTitle', validateImgFileResult);
      const validateFizeSizeResult = validateFileSize(result);
      if (validateFizeSizeResult.isError) return setError('imageTitle', validateFizeSizeResult);

      clearErrors('imageTitle');
      setValue('icon', result.file);
      setValue('imageTitle', result.name);
      setPicker(result.uri);
      trigger('imageTitle');
      setFormValue((prev) => ({ ...prev, imageUrl: result.uri }));
    } catch (error) {
      console.log(error);
    }
  };
  // useEffect(() => {
  //   if (typeof getValues('imageUrl') === 'object') {
  //     const file = getValues('imageUrl');
  //     const reader = new FileReader();
  //     reader.readAsDataURL(file);
  //     reader.onloadend = function () {
  //       const dataURL = reader.result;
  //       setPicker(dataURL);
  //     };
  //   }
  // }, []);

  useEffect(() => {
    if (formValue?.imageUrl) {
      setPicker(formValue?.imageUrl);
    }
  }, [formValue?.imageUrl]);

  return (
    <Grid style={[styles.marginBottom20, { minHeight: 'unset' }]}>
      <Col size={4}>
        <View style={[styles.mt5, styles.flexRow]} nativeID={name}>
          <Text style={styles.colorWhite}>{labelText}</Text>
          {isRequired && <Text style={styles.iconRequired}>✴︎</Text>}
        </View>
      </Col>
      <Col size={8}>
        <Grid style={{ gap: 15 }}>
          <Col size={3} style={styles.warperImage}>
            {picker ? (
              <ImageNative style={styles.image} source={{ uri: picker }} />
            ) : (
              <ImageNative style={styles.imageDefault} source={require('@assets/image/img-default.png')} />
            )}
          </Col>

          <Col size={9}>
            <JInput
              paddingLeft={10}
              placeholder={placeholder}
              control={control}
              disable={false}
              name="imageTitle"
              subfix="camera-green.svg"
            />

            <TouchableOpacity onPress={pickFile}>
              <ImageNative style={styles.inputSubfix} source={require(`@assets/icons/camera-green.svg`)} />
            </TouchableOpacity>
          </Col>
        </Grid>
      </Col>
    </Grid>
  );
};

export default withNamespaces()(memo(InputImage));

const styles = StyleSheet.create({
  relative: {
    position: 'relative',
  },
  warperImage: {
    minWidth: 100,
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: 5,
  },
  input: {
    height: 42,
    borderRadius: 4,
    paddingLeft: 15,
    paddingRight: 40,
    outlineStyle: 'none',
    paddingRight: 30,
    fontSize: 13,
    backgroundColor: INPUT_BG,
    color: COLOR_TEXT_DEFAULT,
  },
  inputSubfix: {
    height: 15,
    width: 15,
    position: 'absolute',
    right: 15,
    bottom: 13,
    flex: 1,
    resizeMode: 'contain',
  },
  textRed: {
    color: TEXT_COLOR_RED,
  },
  mt5: {
    marginTop: 5,
  },
  flexRow: {
    display: 'flex',
    flexDirection: 'row',
  },
  marginBottom20: {
    marginBottom: 20,
  },
  image: {
    width: 90,
    height: 90,
    borderRadius: 90,
  },
  imageDefault: {
    width: '8vw',
    height: '5vw',
    minHeight: 90,
    minWidth: 110,
    maxWidth: 150,
    maxHeight: 100,
    borderRadius: 0,
    marginLeft: 5,
  },
  imageContainer: {
    minHeight: 'unset',
  },
  hidden: {
    display: 'none',
  },
});

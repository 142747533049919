import React from 'react';
import { StyleSheet, View } from 'react-native';
import { Col, Grid } from 'react-native-easy-grid';

import { BORDER_ERROR, INPUT_BG, TEXT_COLOR_GREY, TEXT_COLOR_RED } from '@assets/style/styleDefault';
import { JText as Text } from '../common/JText';
import DateTimePicker from '../common/DateTimePicker';
import JInput from '../common/JInput';

const AccountInput = ({
  typeMoney = false,
  placeholder,
  name,
  control,
  subfix,
  multiline = false,
  labelText,
  subLabelText,
  isRequired,
  startIcon,
  endIcon,
  ml,
  dateTimePicker = false,
  type,
  disable,
  titleSize,
  contentSize,
}) => {
  return (
    <Grid style={[styles.marginBottom20, { minHeight: 'unset' }]}>
      <Col size={titleSize ?? 4} style={styles.titleWithIcon}>
        <View nativeID={name}>
          <View style={[styles.mt5, styles.flexRow]}>
            <Text style={[styles.colorWhite, { marginLeft: ml }]}>{labelText}</Text>
            {isRequired && <Text style={styles.iconRequired}>✴︎</Text>}
          </View>
          {subLabelText && <Text style={styles.textGrey}>{subLabelText}</Text>}
        </View>
        {startIcon && <Text style={{ marginRight: 10 }}>{startIcon}</Text>}
      </Col>
      {!dateTimePicker ? (
        <Col size={contentSize ?? 8} style={[styles.flexCenter]}>
          {!endIcon ? (
            <JInput
              typeMoney={typeMoney}
              disable={disable}
              name={name}
              control={control}
              placeholder={placeholder}
              subfix={subfix}
              widthSubfix={15}
              heightSubfix={15}
              rightSubfix={10}
              multiline={multiline}
              heightMulti={100}
              borderError={BORDER_ERROR}
              paddingLeft={10}
              paddingRight={31}
            />
          ) : (
            <Grid>
              <Col size={9}>
                <JInput
                  typeMoney={typeMoney}
                  disable={disable}
                  name={name}
                  control={control}
                  placeholder={placeholder}
                  subfix={subfix}
                  widthSubfix={15}
                  heightSubfix={15}
                  rightSubfix={10}
                  multiline={multiline}
                  heightMulti={100}
                  borderError={BORDER_ERROR}
                  paddingLeft={10}
                  paddingRight={31}
                />
              </Col>
              <Col size={3} style={{ justifyContent: 'center', alignItems: 'center', flexDirection: 'row' }}>
                <Text style={styles.colorWhite}>{endIcon}</Text>
              </Col>
            </Grid>
          )}
        </Col>
      ) : (
        <Col size={contentSize ?? 8}>
          <DateTimePicker disable={disable} name={name} control={control} type={type} />
        </Col>
      )}
    </Grid>
  );
};

export default AccountInput;

const styles = StyleSheet.create({
  input: {
    border: `1px solid ${INPUT_BG}`,
    borderRadius: 4,
    paddingLeft: 10,
    paddingRight: 30,
    outlineStyle: 'none',
  },
  inputSubfix: {
    height: 15,
    width: 15,
    position: 'absolute',
    right: 10,
    top: 13,
  },
  error: {
    color: TEXT_COLOR_RED,
  },
  marginBottom20: {
    marginBottom: 20,
  },
  flex: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  iconRequired: {
    color: TEXT_COLOR_RED,
    marginLeft: 6,
    fontSize: 16,
  },
  flexCenter: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  mt5: {
    marginTop: 5,
  },
  flexRow: {
    display: 'flex',
    flexDirection: 'row',
  },
  textGrey: {
    color: TEXT_COLOR_GREY,
  },
  startIcon: {
    position: 'absolute',
    left: -15,
    top: '25%',
  },
  titleWithIcon: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
});

import { Dimensions } from 'react-native';

const scroll2View = (viewErrorID, scrollView, padding = Dimensions.get('window').height / 2) => {
  if (!viewErrorID || !scrollView) return;

  const viewError = document.getElementById(viewErrorID);
  if (!viewError) return;

  scrollView.scrollTo({
    x: 0,
    y: getOffsetTop(viewError) - padding,
  });
};

var getOffsetTop = function (elem) {
  var distance = 0;

  if (elem.offsetParent) {
    do {
      distance += elem.offsetTop;
      elem = elem.offsetParent;
    } while (elem);
  }

  return distance < 0 ? 0 : distance;
};

export default scroll2View;

import React, { useEffect, useState } from 'react';
import { View, Image, ScrollView, Pressable } from 'react-native';
import { withNamespaces } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import { useQuery, useIsFetching } from '@tanstack/react-query';

import { Col } from 'react-native-easy-grid';

import JButton from '@components/common/JButton';
import ModalSave from './modal/ModalSave';
import ModalAdminStop from './modal/ModalAdminStop';
import ModalAlert from '@components/Modals/ModalAlert';
import styles from './style';
import { useSelector } from 'react-redux';

import { TEXT_COLOR_GREEN, TEXT_COLOR_GREY } from '@assets/style/styleDefault';
import { JText as Text } from '@components/common/JText';
import { LinearGradient } from 'expo-linear-gradient';
import AdminList from '../partials/AdminList';
import { del, get, put } from '@utils/RequestLevica';
import { API_PATH, ROLE_USER } from '@helper/constant';
import { converTextCamelCase, statusColor } from '@helper/formatTypes';
import ModalAdminActive from './modal/ModalAdminActive';
import { checkPermission } from '@helper/commonFunction';
import Loading from '@components/common/Loading';

const MemberChange = ({ t }) => {
  const { accountId } = useParams();
  const [modalDelete, setModalDelete] = useState(false);
  const [modalDeleteDone, setModalDeleteDone] = useState(false);
  const [modalAdminStop, setModalAdminStop] = useState(false);
  const [modalAdminStopDone, setModalAdminStopDone] = useState(false);
  const [modalAdminActive, setModalAdminActive] = useState(false);
  const [modalAdminActiveDone, setModalAdminActiveDone] = useState(false);
  const [isActiveAccount, setIsActiveAccount] = useState(false);
  const [isDeleteAccount, setIsDeleteAccount] = useState(false);

  const [recallAdminList, setRecallAdminList] = useState(false);
  const getUser = async () => {
    const data = await get(`${API_PATH.ADMIN_INFO}/${accountId}/detail`);
    return data;
  };
  const {
    data: userDetail,
    isLoading,
    refetch,
    isFetching,
    remove,
  } = useQuery(['userDetail'], getUser, { refetchOnWindowFocus: false, enabled: false });

  useEffect(() => {
    if (accountId) {
      refetch();
    }
  }, [accountId]);

  const handleDeleteAccount = async () => {
    try {
      setIsDeleteAccount(true)
      await del(`${API_PATH.ADMIN_INFO}/${accountId}`);
      setRecallAdminList(true);
      setModalDelete(false);
      setModalDeleteDone(true);
    } catch (error) {
      console.log(error);
      setIsDeleteAccount(false)
    }
  };

  const handleSuspendActiveAccount = async (status) => {
    try {
      setIsActiveAccount(true)
      await put(`${API_PATH.ADMIN_INFO}/${accountId}/changeStatus`, {
        status,
      });
      setRecallAdminList(true);
      userDetail.status = status;
      if (status === 0) {
        setModalAdminActive(false);
        setModalAdminActiveDone(true);
      } else {
        setModalAdminStop(false);
        setModalAdminStopDone(true);
      }
    } catch (error) {
      setIsActiveAccount(false)
    }
  };
  
  const userInfo = useSelector((state) => state.merchantUserInfo);

  useEffect(() => remove, [remove]);

  return isLoading ? (
    <Loading style={{ width: 'calc(100% - 30px)' }} />
  ) : (
    <View style={styles.container}>
      <Link to="/admin" style={{ textDecoration: 'none' }}>
        <View style={styles.header}>
          <Image source={require(`@assets/icons/XBackGreen.svg`)} style={styles.iconX} alt="statusIcon" />
          <Text style={[styles.textHeader, { fontSize: 18 }]}>{t('commonText.close')} </Text>
        </View>
      </Link>
      <View style={styles.body}>
        <Col size={7} style={styles.left}>
          <View style={[styles.dFlex, { marginBottom: 20 }]}>
            <Col size={3}>
              <View style={styles.mainAvatar}>
                {!!userDetail?.icon ? (
                  <Image style={styles.mainAvatarImage} source={{ uri: userDetail?.icon }} />
                ) : (
                  <Image style={styles.mainAvatarImage} source={require(`@assets/image/userDefaultAvatar.jpg`)} />
                )}
              </View>
            </Col>
            <Col size={7}>
              <Text style={[styles.textHeader, { color: 'white' }]}>{userDetail?.name}</Text>
            </Col>
          </View>
          <View style={[styles.dFlex, styles.warperItem]}>
            <Col size={3}>
              <Text style={styles.text}>Admin type</Text>
            </Col>
            <Col size={7}>
              <View style={[styles.dFlex, { flex: 1 }]}>
                <View
                  style={[styles.statusIcon, { backgroundColor: statusColor(userDetail?.masterType) }]}
                  alt="statusIcon"
                />
                <Text>{converTextCamelCase(userDetail?.masterType)}</Text>
              </View>
            </Col>
          </View>
          <View style={[styles.dFlex, styles.warperItem]}>
            <Col size={3}>
              <Text style={styles.text}>{t('pages_Setting_MemberChange.status')}</Text>
            </Col>
            <Col size={7}>
              <Text style={{ color: TEXT_COLOR_GREEN }}>
                {userDetail?.status === 0 ? (
                  <Text style={{ color: TEXT_COLOR_GREEN }}>
                    A<Text>ctive</Text>
                  </Text>
                ) : null}
                {userDetail?.status === 1 ? <Text style={{ color: TEXT_COLOR_GREY }}>Close</Text> : null}
              </Text>
            </Col>
          </View>
          <View style={[styles.dFlex, styles.warperItem]}>
            <Col size={3}>
              <Text style={styles.text}>LEVICA ID</Text>
            </Col>
            <Col size={7}>
              <Text style={{ color: TEXT_COLOR_GREY }}>{userDetail?.levicaId}</Text>
            </Col>
          </View>
          <View style={[styles.dFlex, styles.warperItem]}>
            <Col size={3}>
              <Text style={styles.text}>{t('pages_Setting.walletAddress')}</Text>
            </Col>
            <Col size={7}>
              <Text style={{ color: TEXT_COLOR_GREY }}>{userDetail?.walletAddress}</Text>
            </Col>
          </View>
          <View style={[styles.dFlex, styles.warperItem]}>
            <Col size={3}>
              <Text style={styles.text}>{t('pages_Setting_MemberChange.email')}</Text>
            </Col>
            <Col size={7}>
              <Text style={styles.text}>{userDetail?.email}</Text>
            </Col>
          </View>
          <View style={[styles.dFlex, styles.warperItem]}>
            <Col size={3}>
              <Text style={styles.text}>{t('pages_Setting.phone')}</Text>
            </Col>
            <Col size={7}>
              <Text style={styles.text}>{userDetail?.phone}</Text>
            </Col>
          </View>

          <View style={[styles.dFlex, { marginTop: 80 }]}>
            <View style={{ paddingRight: 40 }}>
              {userDetail?.status === 0 ? (
                <LinearGradient colors={['#32A5F7', '#2D81F3']} style={{ borderRadius: 5 }}>
                  <JButton
                    text={t('pages_Setting_MemberChange.buttonSuspendThisAccount')}
                    width={240}
                    textColor="#fff"
                    hasIcon={true}
                    onPressAction={() => setModalAdminStop(true)}
                  />
                </LinearGradient>
              ) : (
                <LinearGradient colors={['#4eb98f', '#2dbe85']} style={{ borderRadius: 5 }}>
                  <JButton
                    text={t('pages_Setting_MemberChange.buttonActiveThisAccount')}
                    width={240}
                    textColor="#fff"
                    hasIcon={true}
                    onPressAction={() => setModalAdminActive(true)}
                  />
                </LinearGradient>
              )}
            </View>

            <LinearGradient colors={['#FE8463', '#FD4F44']} style={{ borderRadius: 5 }}>
              <JButton
                text={t('pages_Setting_MemberChange.buttonDeleteThisAccount')}
                width={240}
                textColor="#fff"
                hasIcon={true}
                disabled={!checkPermission(ROLE_USER.DELETE_ADMIN, userInfo)}
                onPressAction={() => setModalDelete(true)}
              />
            </LinearGradient>
          </View>
        </Col>
        <Col size={3} style={styles.right}>
          <AdminList recall={recallAdminList} setRecall={setRecallAdminList} />
        </Col>
      </View>
      {/* delete account */}
      <ModalSave isModalVisible={modalDelete} isDeleteAccount={isDeleteAccount} setModalVisible={setModalDelete} func={() => handleDeleteAccount()} />
      <ModalAlert
        isModalVisible={modalDeleteDone}
        setModalVisible={setModalDeleteDone}
        icon="checkRed.svg"
        iconWidth={20}
        iconHeight={16}
        linkPage="/admin"
        modalText={t('pages_Setting_MemberChange.modalAlertTextDelete')}
      />

      {/* suspend acctive */}
      {modalAdminStop && (
        <ModalAdminStop
          isModalVisible={modalAdminStop}
          setModalVisible={setModalAdminStop}
          func={() => handleSuspendActiveAccount(1)}
          isActiveAccount={isActiveAccount}
        />
      )}
      {modalAdminStopDone && (
        <ModalAlert
          isModalVisible={modalAdminStopDone}
          setModalVisible={setModalAdminStopDone}
          icon="stop.svg"
          iconWidth={20}
          iconHeight={16}
          modalText={t('pages_Setting_MemberChange.modalAlertTextSuspend')}
        />
      )}
      {/* active acctive */}
      {modalAdminActive && (
        <ModalAdminActive
          isModalVisible={modalAdminActive}
          setModalVisible={setModalAdminActive}
          func={() => handleSuspendActiveAccount(0)}
          isActiveAccount={isActiveAccount}
        />
      )}
      {modalAdminActiveDone && (
        <ModalAlert
          isModalVisible={modalAdminActiveDone}
          setModalVisible={setModalAdminActiveDone}
          icon="checkGreen.svg"
          iconWidth={20}
          iconHeight={16}
          modalText={t('pages_Setting_MemberChange.modalAlertTextActive')}
        />
      )}
    </View>
  );
};

export default withNamespaces()(MemberChange);

import React from 'react';
import { StyleSheet, View, Image, TouchableOpacity } from 'react-native';
import { FONT_SIZE_HEADER, FONT_SIZE_DEFAULT, TEXT_COLOR_GREEN } from '@assets/style/styleDefault';
import { JText as Text } from '../common/JText';

const HeaderToken = ({
  linkBtnBack,
  textBtnBack,
  actionBtnBack,
  linkIcon,
  textHeader,
  widthIconTitle,
  heightIconTitle,
}) => {
  return (
    <View style={styles.wrapperHeader}>
      <TouchableOpacity style={[styles.flexRow, styles.cursorPointer]} onPress={() => actionBtnBack()}>
        <Image style={styles.buttonBack} source={require(`../../assets/icons/${linkBtnBack}`)} />
        <Text style={styles.textBack}>{textBtnBack}</Text>
      </TouchableOpacity>
      {!!linkIcon && (
        <Image
          style={[
            styles.iconHeader,
            widthIconTitle && { width: widthIconTitle },
            heightIconTitle && { height: heightIconTitle },
          ]}
          source={linkIcon}
        />
      )}
      <Text style={styles.textHeader}>{textHeader}</Text>
    </View>
  );
};

export default HeaderToken;

const styles = StyleSheet.create({
  cursorPointer: {
    cursor: 'pointer',
  },
  flexRow: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  flexRow2: {
    flexDirection: 'row',
    alignItems: 'ceter',
    justifyContent: 'flex-end',
    paddingRight: 20,
  },
  wrapperHeader: {
    height: 80,
    flexDirection: 'row',
    alignItems: 'center',
  },
  headerToken: {
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  buttonBack: {
    height: 15,
    width: 15,
  },
  textBack: {
    marginHorizontal: 10,
    fontWeight: 'bold',
    fontSize: 14,
    color: TEXT_COLOR_GREEN,
  },
  iconHeader: {
    height: 25,
    width: 25,
    marginHorizontal: 10,
    marginLeft: 20,
    borderRadius: 100,
  },
  textHeader: {
    fontSize: FONT_SIZE_HEADER,
  },

  spacing_btn: {
    marginRight: 5,
    marginLeft: 5,
  },
  menuScopeThreeDot: {
    zIndex: 10,
    borderRadius: 50,
    width: 40,
    height: 40,
    marginHorizontal: 10,
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  text: {
    fontSize: FONT_SIZE_DEFAULT,
    color: 'white',
    padding: 10,
    paddingLeft: 20,
  },
});

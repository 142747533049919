import React from 'react';
import { StyleSheet, TouchableWithoutFeedback, Image, View, Pressable } from 'react-native';
import Modal from 'react-native-modal';
import { withNamespaces } from 'react-i18next';
import { useDispatch } from 'react-redux';
import i18n from 'i18next';

import { MODAL_HOVER } from '@assets/style/styleDefault';
import { changeLanguage } from '@redux/slices/LanguageSlice';
import { JText as Text } from '@components/common/JText';
import ModalBackDrop from '@components/Modals/ModalBackDrop';
import { put } from '@utils/RequestLevica';
import { API_PATH } from '@helper/constant';

const LanguageData = [
  {
    language: 'English',
    iconLanguage: 'imgAmerica.png',
    code: 'english',
  },
  {
    language: 'Japanese',
    iconLanguage: 'imgJapan.png',
    code: 'japanese',
  },
  {
    language: 'China',
    iconLanguage: 'imgChina.png',
    code: 'china',
  },
];

const ModalLanguage = ({ isModalVisible, setModalVisible, t, currentLanguage }) => {
  const dispatch = useDispatch();
  const changeLanguages = async (list) => {
    if (list.language == currentLanguage) {
      setModalVisible(false);
    } else {
      dispatch(changeLanguage(list?.language));
      i18n.changeLanguage(list.language);
      await put(API_PATH.SYSTEM_SETTING, [{ item: 'language', value: list.language }]);
      setModalVisible(false);
    }
  };

  return (
    <View>
      <Modal
        animationIn="fadeIn"
        isVisible={isModalVisible}
        style={{ alignItems: 'center' }}
        customBackdrop={<ModalBackDrop onPress={() => setModalVisible(false)} />}
      >
        <View>
          <TouchableWithoutFeedback onPress={() => setModalVisible(false)}>
            <View style={[styles.flexRow, styles.toggleClose]}>
              <Image style={styles.iconClose} source={require(`@assets/icons/iconCloseModal.svg`)} />
              <Text style={styles.textClose}>{t('commonText.cancelButton')}</Text>
            </View>
          </TouchableWithoutFeedback>
          <View style={styles.bgContent}>
            <View style={styles.content}>
              <View style={styles.title}>
                <Image style={styles.iconLanguage} source={require(`@assets/icons/iconLanguageModal.svg`)} />
                <Text style={styles.textLabel}> Language</Text>
              </View>
              {LanguageData?.map((list, index) => (
                <Pressable key={index} style={({ hovered }) => [hovered && styles.itemHovered]}>
                  <TouchableWithoutFeedback onPress={() => changeLanguages(list)}>
                    <View
                      style={[
                        styles.listLanguage,
                        {
                          borderBottomWidth: index + 1 === LanguageData.length ? 0 : 1,
                        },
                      ]}
                    >
                      <Image
                        style={styles.iconAmerica}
                        source={require(`../../../../assets/icons/${list.iconLanguage}`)}
                      />
                      <Text style={styles.textLabel}>{list.language} </Text>
                    </View>
                  </TouchableWithoutFeedback>
                </Pressable>
              ))}
            </View>
          </View>
        </View>
      </Modal>
    </View>
  );
};

const styles = StyleSheet.create({
  textLabel: {
    color: '#191919',
  },
  flexRow: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  toggleClose: {
    paddingBottom: 10,
    paddingLeft: 10,
    cursor: 'pointer',
  },
  iconClose: {
    height: 15,
    width: 15,
    marginRight: 10,
  },
  textClose: {
    color: 'white',
  },
  bgContent: {
    backgroundColor: '#fff',
    minHeight: 200,
    minWidth: 600,
    borderRadius: 6,
    alignItems: 'center',
    paddingBottom: 40,
  },
  content: {
    width: '80%',
    // paddingLeft: 40,
  },
  title: {
    height: 60,
    flexDirection: 'row',
    alignItems: 'center',
    borderBottomColor: '#E0E0E0',
    borderBottomWidth: 1,
    paddingLeft: 10,
  },
  iconLanguage: {
    height: 20,
    width: 20,
    marginRight: 10,
  },

  listLanguage: {
    paddingBottom: 10,
    borderBottomColor: '#E0E0E0',
    borderBottomWidth: 1,
    marginTop: 10,
    flexDirection: 'row',
    alignItems: 'center',
    cursor: 'pointer',
    paddingLeft: 10,
    paddingRight: 40,
  },
  iconAmerica: {
    height: 15,
    width: 24,
    marginRight: 10,
  },
  iconJapan: {
    height: 15,
    width: 24,
    marginRight: 10,
  },
  iconChina: {
    height: 15,
    width: 24,
    marginRight: 10,
  },
  itemHovered: {
    backgroundColor: MODAL_HOVER,
  },
});

export default withNamespaces()(ModalLanguage);

import React from 'react';
import { View, StyleSheet, Image, TouchableOpacity } from 'react-native';
import Modal from 'react-native-modal';
import { useHistory } from 'react-router-dom';
import JButton from '@components/common/JButton';
import { JText as Text } from '@components/common/JText';
import ModalBackDrop from '@components/Modals/ModalBackDrop';
import { withNamespaces } from 'react-i18next';

const ModalDuringToken = ({ isModalVisible, setModalVisible, onPress, t }) => {
  const history = useHistory();

  return (
    <View>
      <Modal
        animationIn="fadeIn"
        isVisible={isModalVisible}
        style={{ alignItems: 'center' }}
        customBackdrop={<ModalBackDrop onPress={() => setModalVisible(false)} />}
      >
        <View style={styles.wrapperModal}>
          <View style={styles.mainClose}>
            <TouchableOpacity style={styles.contentClose} onPress={() => setModalVisible(false)}>
              <Image
                source={require('@assets/icons/closeIcon.svg')}
                alt=""
                style={[styles.iconClose, styles.colorWhrite]}
              />
              <Text style={styles.colorWhrite}>{t('commonText.close')}</Text>
            </TouchableOpacity>
          </View>
          <View style={styles.maxWidth600}>
            <View style={styles.headerBox}>
              <Text>{t('pages_Token_Modals_ModalDuring_Token.tokenProgress')}</Text>
            </View>
            <View style={styles.iconBox}>
              <Image style={{ height: 30, width: 40 }} source={require(`@assets/icons/earthWifi.svg`)} />
            </View>
            <View style={styles.contentBox}>
              <Text>{t('pages_Token_Modals_ModalDuring_Token.coordinationProcessing')}</Text>
              <Text>{t('pages_Token_Modals_ModalDuring_Token.tokenNote')}</Text>
            </View>
            <View style={styles.contentBox}>
              <JButton
                text={t('pages_Token_Modals_ModalDuring_Token.abortConnection')}
                width={170}
                textColor="#fff"
                btnColor="#fe8463"
                onPressAction={() => onPress()}
              />
            </View>
          </View>
        </View>
      </Modal>
    </View>
  );
};

export default withNamespaces()(ModalDuringToken);

const styles = StyleSheet.create({
  wrapperModal: {
    maxWidth: 600,
    minWidth: 500,
  },
  maxWidth600: {
    backgroundColor: '#fff',
    borderRadius: 10,
  },
  headerBox: {
    paddingBottom: 30,
    paddingTop: 30,
    justifyContent: 'center',
    alignItems: 'center',
    borderBottomWidth: 1,
    borderBottomColor: '#E0E0E0',
  },
  iconBox: {
    padding: 30,
    justifyContent: 'center',
    alignItems: 'center',
  },
  contentBox: {
    paddingBottom: 30,
    justifyContent: 'center',
    alignItems: 'center',
  },
  contentClose: {
    with: 20,
    height: 40,
    backgroundColor: 'transparent',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  colorWhrite: {
    color: '#fff',
  },
  iconClose: {
    width: 15,
    height: 15,
    marginRight: 10,
  },
  mainClose: {
    maxWidth: 200,
    marginRight: 'auto',
  },
});

import React, { useEffect, useState } from 'react';
import { StyleSheet, View } from 'react-native';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import Sidebar from '@components/SiderbarTabs/Sidebar';
import Routes from '@config/routers';
import { BG_COLOR, BG_SIDEBAR } from '@assets/style/styleDefault';
import { whiteList } from '@config/routers';
import { refreshToken, getCookie } from '@utils/TokenStorage';
import { STORAGEKEY } from '@helper/constant';
import Loading from '@components/common/Loading';
import { merchantUserInfo, setIsLoaded, setRouteByUserInfo } from '@redux/slices/authSlice';
import { language } from '@redux/slices/LanguageSlice';

const MainLayout = () => {
  const userInfo = useSelector((state) => state.merchantUserInfo);
  const languageInfo = useSelector((state) => state.language);
  const location = useLocation();
  const [routes, setRoutes] = useState(null);
  const token = getCookie(STORAGEKEY.ACCESS_TOKEN);
  const refreshTokenCookie = getCookie(STORAGEKEY.REFRESH_TOKEN);

  const dispatch = useDispatch();
  useEffect(() => {
    if (!token) {
      refreshToken();
    } else {
      dispatch(language());
    }
  }, []);

  useEffect(() => {
    setRoutes(Routes(userInfo));
  }, [userInfo, setRoutes]);

  useEffect(() => {
    (() => {
      if (whiteList.includes(location.pathname)) {
        return dispatch(setIsLoaded(true));
      }
      if (token) {
        return dispatch(merchantUserInfo());
      }
    })();
  }, [location.pathname, dispatch]);

  useEffect(() => {
    if (userInfo?.isLoaded) {
      dispatch(setRouteByUserInfo(true));
    }
  }, [userInfo, dispatch]);

  return (
    <View style={styles.main}>
      {!whiteList.includes(location.pathname) && userInfo?.isUpdateRoutesByUserInfo && !languageInfo?.isLoaded ? (
        <View style={styles.sidebar}>
          <Sidebar />
        </View>
      ) : null}
      <View style={styles.mainContent}>
        {userInfo?.isUpdateRoutesByUserInfo && !languageInfo?.isLoaded ? (
          routes
        ) : !refreshTokenCookie ? (
          routes
        ) : (
          <Loading className={styles.loading} />
        )}
      </View>
    </View>
  );
};

export default MainLayout;

const styles = StyleSheet.create({
  main: {
    flex: 1,
    flexDirection: 'row',
    minWidth: '1280px',
    flexBasis: '100vh',
  },
  sidebar: {
    backgroundColor: BG_SIDEBAR,
  },
  mainContent: {
    flex: 5,
    width: '100%',
    backgroundColor: BG_COLOR,
    paddingHorizontal: 15,
  },
  loading: {
    width: '100%',
    height: '100%',
    position: 'relative',
  },
});

import React from 'react';
import Modal from 'react-native-modal';
import { withNamespaces } from 'react-i18next';
import { View, StyleSheet, FlatList, Image, Pressable } from 'react-native';

import { BORDER_MODAL, MODAL_HOVER } from '@assets/style/styleDefault';
import { JText as Text } from '../common/JText';
import ModalBackDrop from './ModalBackDrop';

const statusNote = [
  {
    icon: 'circleStatus0.svg',
    text: '運用・公開中',
  },
  {
    icon: 'circleStatus4.svg',
    text: '停止中',
  },
  {
    icon: 'circleStatus1.svg',
    text: 'ユーザー対応',
  },
  {
    icon: 'circleStatus2.svg',
    text: '管理者対応',
  },
  {
    icon: 'circleStatus3.svg',
    text: '特殊ケース',
  },
];

const ModalStatus = (props) => {
  const { t, openModal, setOpenModal, handleModalStatus, statusData, currentStatus, setCurrentStatus } = props;
  const handleChangeStatus = (item) => {
    // setCurrentStatus(item)
    handleModalStatus();
  };
  return (
    <View>
      <Modal
        animationIn="fadeIn"
        isVisible={openModal}
        style={{ alignItems: 'center' }}
        customBackdrop={<ModalBackDrop onPress={() => setOpenModal(!openModal)} />}
      >
        <View>
          <Pressable style={styles.close} onPress={handleModalStatus}>
            <Image style={styles.iconNote} source={require(`../../assets/icons/close-white.svg`)} />
            <Text style={[styles.textWhite, styles.ml10]}>{t('commonText.cancelButton')}</Text>
          </Pressable>
          <View style={styles.content}>
            <View style={styles.listHeader}>
              <Text style={{ color: '#191919' }}>{t('components_Modals_ModalStatus.text1')}</Text>
              <View style={styles.statusContainer}>
                <Text style={{ color: currentStatus.color }}>{currentStatus.text}</Text>
              </View>
            </View>
            <View style={styles.list}>
              <Text style={{ color: '#191919', padding: 18 }}>{t('components_Modals_ModalStatus.text2')}</Text>
              <FlatList
                showsVerticalScrollIndicator={false}
                showsHorizontalScrollIndicator={false}
                data={statusData}
                renderItem={({ item }) => (
                  <Pressable
                    onPress={() => handleChangeStatus(item)}
                    style={({ hovered }) => [hovered && styles.itemHovered]}
                  >
                    <View style={styles.item}>
                      <View style={styles.left}>
                        <Image style={styles.icon} source={require(`../../assets/icons/${item.icon}`)} />
                        <Text style={[styles.text, { color: item.color }]}>{item.text}</Text>
                      </View>
                      {item.id === currentStatus.id && (
                        <Text style={{ color: '#8B8B8B' }}>（{t('components_Modals_ModalStatus.text3')}</Text>
                      )}
                    </View>
                  </Pressable>
                )}
                keyExtractor={(item, index) => index.toString()}
              />
              <View style={styles.note}>
                <Text style={styles.textWhite}>{t('components_Modals_ModalStatus.text4')}</Text>
                <FlatList
                  data={statusNote}
                  renderItem={({ item }) => (
                    <View style={styles.noteItem}>
                      <Image style={styles.iconNote} source={require(`../../assets/icons/${item.icon}`)} />
                      <Text style={[styles.text, { color: 'white' }]}>{item.text}</Text>
                    </View>
                  )}
                />
              </View>
            </View>
          </View>
        </View>
      </Modal>
    </View>
  );
};

export default withNamespaces()(ModalStatus);

const styles = StyleSheet.create({
  content: {
    backgroundColor: 'white',
    minWidth: 600,
    padding: 28,
    borderRadius: 4,
  },

  icon: {
    width: 12,
    height: 13,
    marginRight: 20,
    resizeMode: 'contain',
  },
  iconNote: {
    width: 12,
    height: 13,
    resizeMode: 'contain',
  },
  item: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: 50,
    borderTopColor: BORDER_MODAL,
    borderTopWidth: 1,
  },
  left: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: 15,
  },
  textBlue: {
    color: '#1e76fe',
  },
  listHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  statusContainer: {
    border: '1px solid #D8D8D8',
    borderRadius: 4,
    paddingVertical: 10,
    paddingHorizontal: 15,
    width: 250,
    textAlign: 'center',
    height: 40,
  },
  list: {
    paddingHorizontal: 30,
    position: 'relative',
    height: 550,
  },
  desc: {
    marginVertical: 20,
    marginLeft: 15,
  },
  note: {
    position: 'absolute',
    right: -150,
    top: '25%',
  },
  textWhite: {
    color: '#fff',
  },
  noteItem: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 10,
    marginVertical: 10,
  },
  close: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'end',
    alignItems: 'center',
    marginBottom: 10,
  },
  ml10: {
    marginLeft: 10,
  },
  itemHovered: {
    backgroundColor: MODAL_HOVER,
  },
});

import React from 'react';
import { View, Text } from 'react-native';

const RequireText = ({ paddingLeft = 3, paddingRight = 3 }) => {
  return (
    <View>
      <Text style={{ color: 'red', paddingLeft, paddingRight }}>✴︎</Text>
    </View>
  );
};

export default RequireText;

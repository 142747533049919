import React, { useState } from 'react';
import { StyleSheet, TouchableWithoutFeedback, View, Image, Pressable } from 'react-native';
import { BG_HOVER } from '@assets/style/styleDefault';
import { JText as Text } from './JText';

const DropDown = (props) => {
  const {
    btnColor,
    textColor,
    width = '100%',
    text = '',
    disabled,
    dataOption,
    handleToggle,
    changeColor,
    textCenter,
  } = props;

  const [menuHidden, setMenuHidden] = useState(false);
  const [value, setValue] = useState('');

  const handleOnPress = () => {
    setMenuHidden(!menuHidden);
  };

  const changeValue = (list) => {
    console.log(list);
    list.func && list.func();
    handleToggle && handleToggle(list);
    setValue(list);
    setMenuHidden(false);
  };

  return (
    <>
      <View style={styles.relative}>
        <TouchableWithoutFeedback onPress={handleOnPress} disabled={disabled}>
          <View
            style={[
              styles.button,
              { backgroundColor: menuHidden && changeColor ? changeColor : btnColor },
              { width: width },
            ]}
          >
            <Text style={[styles.text, { color: value?.color ? value?.color : textColor }]}>
              {value ? value.option : text}
            </Text>

            <Image
              style={[styles.iconDown]}
              source={
                !menuHidden
                  ? disabled
                    ? require(`../../assets/icons/iconDownDisabled.svg`)
                    : require(`../../assets/icons/iconDown.svg`)
                  : disabled
                  ? require(`../../assets/icons/iconDownDisabled.svg`)
                  : require(`../../assets/icons/iconTop.svg`)
              }
            />
          </View>
        </TouchableWithoutFeedback>

        {menuHidden && (
          <View
            style={[
              styles.listDropDown,
              { width: width, backgroundColor: menuHidden && changeColor ? changeColor : btnColor },
            ]}
          >
            {dataOption?.map((list, index) => {
              return (
                <Pressable key={index} style={({ hovered }) => [hovered && styles.itemHovered]}>
                  <TouchableWithoutFeedback key={list.option} onPress={() => changeValue(list)}>
                    <View
                      style={[styles.itemOptions, textCenter && { justifyContent: 'center', alignItems: 'center' }]}
                      key={index}
                    >
                      {list.icon && (
                        <Image style={styles.imageList} source={require(`../../assets/icons/${list.icon}`)} />
                      )}
                      <Text style={[styles.textNoIcon, { color: list.color }]}>{list.option}</Text>
                      <Image
                        style={[styles.iconDowns]}
                        source={
                          disabled
                            ? require(`../../assets/icons/iconDownDisabled.svg`)
                            : require(`../../assets/icons/iconDown.svg`)
                        }
                      />
                    </View>
                  </TouchableWithoutFeedback>
                </Pressable>
              );
            })}
          </View>
        )}
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  isHide: {
    display: 'none',
  },
  relative: {
    position: 'relative',
  },
  button: {
    borderRadius: 5,
    height: 42,
    fontWeight: 400,
    lineHeight: 1.5,
    cursor: 'pointer',
    border: '1px solid transparent',
    fontSize: 14,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'initial',
    paddingLeft: '10%',
    paddingRight: '5%',
  },
  listDropDown: {
    backgroundColor: '#525252',
    position: 'absolute',
    top: 38,
    cursor: 'pointer',
    borderBottomEndRadius: 4,
    borderBottomStartRadius: 4,
  },
  itemOptions: {
    paddingLeft: '10%',
    paddingRight: '5%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  textHasIcon: {
    marginTop: 10,
    marginBottom: 10,
  },
  textNoIcon: {
    marginTop: 10,
    marginBottom: 10,
    paddingLeft: 50,
  },
  iconDown: {
    height: 6,
    width: 13,
  },
  text: {
    paddingLeft: 50,
  },
  imageList: {
    height: 20,
    width: 20,
  },
  itemHovered: {
    backgroundColor: BG_HOVER,
  },
});

export default DropDown;

import { StyleSheet } from 'react-native';
import {
  BG_COLOR,
  BG_HOVER,
  BTN_COLOR_PRIMARY,
  COLOR_TEXT_DEFAULT,
  LINE_COLOR,
  TEXT_COLOR_GREY,
} from '@assets/style/styleDefault';

const styles = StyleSheet.create({
  container: { flex: 1, backgroundColor: BG_COLOR, position: 'relative' },
  head: { height: 42, paddingTop: 15, paddingBottom: 15 },
  wrapper: { flexDirection: 'row' },
  row: {
    borderBottomWidth: 1,
    borderBottomColor: LINE_COLOR,
    paddingTop: 15,
    paddingBottom: 15,
  },
  text: { color: COLOR_TEXT_DEFAULT },
  textHead: { color: TEXT_COLOR_GREY, textAlign: 'center' },
  flexStart: { justifyContent: 'flex-start' },
  textLeft: { color: TEXT_COLOR_GREY, paddingLeft: 50 },
  textHeader: {
    fontSize: 16,
    color: COLOR_TEXT_DEFAULT,
    marginLeft: 30,
    fontWeight: 'bold',
  },
  wrapperHeader: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingLeft: 20,
    paddingBottom: 10,
    justifyContent: 'space-between',
  },
  wrapperSearch: {
    marginLeft: 40,
    flex: 1,
    alignItems: 'flex-end',
    paddingRight: '6vw',
  },
  inputSearch: {
    height: 42,
    border: `1px solid ${LINE_COLOR}`,
    borderRadius: ' 5px',
    width: '86%',
    minWidth: '260px',
    paddingLeft: 20,
    color: TEXT_COLOR_GREY,
  },

  wrapperTextHeader: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  wrapperItemList: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: 20,
    paddingTop: 20,
  },
  borderBottomColor: {
    borderBottomWidth: 1,
    borderBottomColor: LINE_COLOR,
  },
  btnHeader: {
    height: 42,
    backgroundColor: BTN_COLOR_PRIMARY,
    paddingLeft: 15,
    paddingRight: 15,
  },
  iconDropdown: {
    width: 10,
    height: 10,
    marginLeft: 5,
    marginBottom: 4,
  },
  circle: {
    width: 10,
    height: 10,
    marginRight: 5,
  },
  iconWarning: {
    width: 25,
    height: 24,
  },
  wrapperItemHead: {
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
  },
  wrapperItemEnd: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    paddingRight: '2%',
  },
  wrapperItem: {
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
  },
  wrapperStickyHeader: {
    position: 'sticky',
    top: 0,
    backgroundColor: BG_COLOR,
    zIndex: 10,
  },
  wrapperStickyTitle: {
    position: 'sticky',
    top: 78,
    backgroundColor: BG_COLOR,
    zIndex: 10,
  },
  wrapperStickyTableHead: {
    position: 'sticky',
    top: 128,
    backgroundColor: BG_COLOR,
    zIndex: 10,
  },
  buttonHovered: {
    backgroundColor: BG_HOVER,
  },
  wrapperItemWrap: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingBottom: 20,
    paddingTop: 15,
  },

  wrapperTopRight: {
    padding: 30,
    backgroundColor: BG_COLOR,
    borderRadius: 8,
  },
  wrapperTopLeft: {
    marginRight: '5%',
  },
  wrapperStart: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  checkboxItem: {
    width: 25,
    height: 25,
  },
  marginRight: {
    marginRight: 30,
  },
  deleteIconHeader: {
    width: 60,
    height: 42,
    cursor: 'pointer',
  },
  wrapperError: {
    flexDirection: 'row',
    justifyContent: 'center',
    paddingTop: 50,
  },
  icontWarning: {
    marginRight: 10,
    marginTop: 20,
    height: 30,
    width: 30,
  },
  wrapperText: {
    color: TEXT_COLOR_GREY,
    paddingTop: 10,
    fontSize: 18,
  },
  iconTopRight: {
    width: 28,
    height: 26,
  },
  iconTX: {
    width: 25,
    height: 30,
  },
  flexDirection: {
    flexDirection: 'row',
  },
  paddingRight: {
    paddingRight: 20,
  },
  padding40: {
    paddingBottom: 40,
  },
  bgForm: {
    backgroundColor: BG_COLOR,
    width: '100%',
  },
  flexRow: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  iconWifi: {
    height: 20,
    width: 17,
    marginRight: 10,
  },
});

export default styles;

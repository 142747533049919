import React, { useEffect, useCallback } from 'react';
import { Col, Grid } from 'react-native-easy-grid';
import { withNamespaces } from 'react-i18next';
import { useFormContext } from 'react-hook-form';

import JInput from '@components/common/JInput';
import AccountInput from '@components/AccountComponents/AccountInput';
import styles from '../styles';
import { JText as Text } from '@components/common/JText';
import { BORDER_ERROR } from '@assets/style/styleDefault';
import { API_PATH } from '@helper/constant';
import { get } from '@utils/RequestLevica';
import { View } from 'react-native';

const LocationForm = ({ control, disable, t }) => {
  //postCode to room
  const {
    getValues,
    setError,
    setValue,
    formState: { errors },
    clearErrors,
    trigger,
  } = useFormContext();

  const clearAddressDependOnZipCode = useCallback(() => {
    setValue('prefecture', '');
    setValue('city', '');
    setValue('address', '');
  }, [setValue]);

  const setAddressByDataFetched = useCallback(
    (field, value) => {
      if (value) clearErrors(field);
      setValue(field, value);
    },
    [clearErrors, setValue]
  );

  const getPostCode = useCallback(
    async (code) => {
      try {
        const data = await get(`${API_PATH.POST_CODE}/${code}`);
        setAddressByDataFetched('prefecture', data?.prefecture);
        setAddressByDataFetched('city', data?.city);
        // setAddressByDataFetched('address', data?.street);
        // trigger(['prefecture', 'city', 'address']);
        trigger(['prefecture', 'city']);
      } catch (error) {
        if (error?.response?.data?.code == '3016') {
          setError('postCode', { type: 'custom', message: t('errorMessages.TEXT_POSTAL_CODE_NOT_EXISTS') });
          clearAddressDependOnZipCode;
        }
      }
    },
    [clearAddressDependOnZipCode, setAddressByDataFetched]
  );

  useEffect(() => {
    if (!errors?.postCode && getValues('postCode')) {
      getPostCode(getValues('postCode'));
    }

    if (errors?.postCode) clearAddressDependOnZipCode();
  }, [!errors?.postCode, clearAddressDependOnZipCode]);
  return (
    <>
      <Grid style={styles.marginBottom20}>
        <Col size={4}>
          <Text>{t('pages_AccountShop_CreateAccount.locationForm')}</Text>
        </Col>
        <Col size={8}></Col>
      </Grid>

      <Grid style={styles.marginBottom20}>
        <Col size={4} style={styles.flex}>
          <View nativeID="postCode">
            <Text style={[styles.marginLeft12]}>{t('pages_AccountShop_CreateAccount.zipCode')}</Text>
          </View>
          <Text style={styles.iconRequired}>✴︎</Text>
        </Col>
        <Col size={8} style={styles.flex}>
          <Grid>
            <Col size={5} style={{ position: 'relative', minWidth: 200 }}>
              <Text style={[styles.startIcon]}>〒</Text>
              <JInput
                disable={disable}
                control={control}
                name="postCode"
                placeholder={t('pages_AccountShop_CreateAccount.zipCodePlaceholder')}
                subfix="iconEdit.svg"
                widthSubfix={15}
                heightSubfix={15}
                borderError={BORDER_ERROR}
                paddingLeft={10}
              />
            </Col>
            <Col size={7} style={styles.flex}>
              <Text style={[styles.marginLeft8, styles.colorGray]}>
                {t('pages_AccountShop_CreateAccount.zipCodeNote')}{' '}
              </Text>
            </Col>
          </Grid>
        </Col>
      </Grid>

      <Grid style={styles.marginBottom20}>
        <Col size={4} style={styles.flex}>
          <View nativeID="prefecture">
            <Text style={[styles.marginLeft12]}>{t('pages_AccountShop_CreateAccount.prefectureCode')} </Text>
          </View>
          <Text style={styles.iconRequired}>✴︎</Text>
        </Col>
        <Col size={8} style={styles.flex}>
          <Grid>
            <Col size={5} style={{ position: 'relative', minWidth: 200 }}>
              <JInput
                disable={disable}
                control={control}
                name="prefecture"
                placeholder={t('pages_AccountShop_CreateAccount.prefectureCodePlaceholder')}
                subfix="iconEdit.svg"
                widthSubfix={15}
                heightSubfix={15}
                borderError={BORDER_ERROR}
                paddingLeft={10}
              />
            </Col>
            <Col size={7} style={styles.flex}></Col>
          </Grid>
        </Col>
      </Grid>

      <AccountInput
        disable={disable}
        control={control}
        name="city"
        placeholder={t('pages_AccountShop_CreateAccount.municipalityPlaceholder')}
        isRequired={true}
        labelText={t('pages_AccountShop_CreateAccount.municipality')}
        subfix="iconEdit.svg"
        ml={12}
      />

      <AccountInput
        disable={disable}
        control={control}
        name="address"
        placeholder={t('pages_AccountShop_CreateAccount.addressPlaceholder')}
        isRequired={true}
        labelText={t('pages_AccountShop_CreateAccount.address')}
        subfix="iconEdit.svg"
        ml={12}
      />

      <AccountInput
        disable={disable}
        control={control}
        name="building"
        placeholder={t('pages_AccountShop_CreateAccount.buildingNamePlaceholder')}
        labelText={t('pages_AccountShop_CreateAccount.buildingName')}
        subfix="iconEdit.svg"
        ml={12}
      />

      <Grid style={styles.marginBottom20}>
        <Col size={4} style={styles.flex}>
          <Text style={[styles.colorWhite, styles.marginLeft12]}>
            {t('pages_AccountShop_CreateAccount.numberOfFloor')}{' '}
          </Text>
        </Col>
        <Col size={8} style={styles.flex}>
          <Grid>
            <Col size={4} style={{ position: 'relative' }}>
              <JInput
                disable={disable}
                control={control}
                name="floor"
                placeholder={t('pages_AccountShop_CreateAccount.numberOfFloorPlaceholder')}
                subfix="iconEdit.svg"
                widthSubfix={15}
                heightSubfix={15}
                rightSubfix={10}
                paddingLeft={10}
              />
            </Col>
            <Col size={2} style={styles.flex}>
              <View nativeID="floor">
                <Text style={[styles.marginLeft8]}>{t('pages_AccountShop_CreateAccount.numberOfRoom')} </Text>
              </View>
            </Col>
            <Col size={4} style={{ position: 'relative' }}>
              <JInput
                disable={disable}
                control={control}
                name="room"
                placeholder={t('pages_AccountShop_CreateAccount.numberOfFloorPlaceholder')}
                subfix="iconEdit.svg"
                widthSubfix={15}
                heightSubfix={15}
                rightSubfix={10}
                paddingLeft={10}
              />
            </Col>
            <Col size={2} style={styles.flex}>
              <View nativeID="room">
                <Text style={[styles.marginLeft8]}>{t('pages_AccountShop_CreateAccount.numberOfRoomNote')}</Text>
              </View>
            </Col>
          </Grid>
        </Col>
      </Grid>
    </>
  );
};

export default withNamespaces()(LocationForm);

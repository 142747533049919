import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  content: {
    padding: '1rem',
  },
  headerContent: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 20,
    marginBottom: 20,
  },
  iconFix: {
    height: 25,
    width: 25,
    marginRight: 20,
  },

  rowContent: {
    marginTop: 20,
    width: '50%',
    minWidth: 700,
  },
  notify: {
    width: '50%',
    minWidth: 700,
    paddingVertical: 60,
    marginBottom: 30,
  },
  wrapperCaptcha: {
    paddingTop: 15,
    width: '50%',
    minWidth: 700,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    height: 120,
    cursor: 'pointer',
  },
  inputCaptcha: {
    width: '50%',
    minWidth: 700,
  },
  buttonGroup: {
    padding: '1rem',
    marginTop: 60,
    marginBottom: '3rem',
    flexDirection: 'row',
    width: '50%',
    minWidth: 700,
    alignItems: 'center',
  },
});

export default styles;

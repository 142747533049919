import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { ScrollView, View } from 'react-native';
import { Grid, Row, Col } from 'react-native-easy-grid';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { withNamespaces } from 'react-i18next';
import { LinearGradient } from 'expo-linear-gradient';
import { isValidPhoneNumber, parsePhoneNumber } from 'react-phone-number-input';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import JButton from '@components/common/JButton';
import JInput from '@components/common/JInput';
import HeaderBack from '@components/Headers/HeaderBack';
import ModalAlert from '@components/Modals/ModalAlert';
import styles from './style';
import { JText as Text } from '@components/common/JText';
import { BTN_COLOR_GREY_2, COLOR_TEXT_DEFAULT } from '@assets/style/styleDefault';
import AdminType from '../partials/AdminType';
import { post } from '@utils/RequestLevica';
import { API_PATH } from '@helper/constant';
import PhoneInput from '@components/common/PhoneInput';
import scroll2View from '@helper/scroll2View';

const dataAdminType = ['MASTER', 'EXECUTIVE', 'ASSOCIATE'];
const defaultValues = {
  masterType: '',
  levicaId: '',
  name: '',
  walletAddress: '',
  email: '',
  phone: '',
  twoFactorAuthentication: false,
};

const AdminRegister = ({ t }) => {
  const [modalSubmit, setModalSubmit] = useState(false);
  const setting = useSelector((state) => state.setting.setting);
  const history = useHistory();
  const scrollViewRef = useRef();

  const validationSchema = Yup.object().shape({
    masterType: Yup.string().required(t('errorMessages.TXT_ADMIN_TYPE')),
    levicaId: Yup.string()
      .max(6, t('errorMessages.TXT_MAX_6'))
      .required(t('errorMessages.fieldRequired', { fieldName: 'LEVICA ID' })),
    name: Yup.string()
      .max(32, t('errorMessages.TXT_MAX_32'))
      .required(t('pages_Setting_AdminRegister.namePlaceholder')),
    email: Yup.string()
      .email(t('errorMessages.TXT_EMAIL_REQUIRED'))
      .max(32, t('errorMessages.TXT_MAX_32'))
      .required(t('errorMessages.fieldRequired', { fieldName: t('pages_Setting.email') })),
    avatar: Yup.string(),
    phone: Yup.string()
      .trim()
      .nullable()
      .required(t('pages_Setting_AdminRegister.phonePlaceholder'))
      .test('compare ', t('errorMessages.TXT_JP_PHONE'), (phone) => {
        if (phone && parsePhoneNumber(phone)?.nationalNumber) {
          return isValidPhoneNumber(phone);
        } else {
          return true;
        }
      }),
  });
  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
    setError,
    clearErrors,
    trigger,
  } = useForm({
    defaultValues,
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
  });
  const onSubmit = async (userFromData) => {
    if (userFromData.phone?.length < 6) {
      setError('phone', {
        type: 'custom',
        message: t('errorMessages.TXT_JP_PHONE'),
      });
      scroll2View('phone', scrollViewRef.current);
      return;
    }
    try {
      await post(API_PATH.ADMIN_INFO, userFromData);
      reset();
      clearErrors();
      setModalSubmit(true);
    } catch (error) {
      if (error?.response?.data?.errorCode == '3014') {
        setError('email', { type: 'custom', message: t('errorMessages.TXT_REGISTERED_EMAIL') });
      } else if (error?.response?.data?.code == '3015') {
        setError('phone', { type: 'custom', message: t('errorMessages.TXT_REGISTERED_PHONE') });
      } else if (error?.response?.data?.code == '4003') {
        setError('levicaId', { type: 'custom', message: t('errorMessages.TXT_LEVICAID') });
      }
    }
  };
  const goBack = () => {
    history.goBack();
  };

  useEffect(() => {
    scroll2View(Object.keys(errors)[0], scrollViewRef.current);
  }, [errors]);

  return (
    <ScrollView ref={scrollViewRef} showsVerticalScrollIndicator={false} showsHorizontalScrollIndicator={false}>
      <HeaderBack
        btnBack="iconLeft.svg"
        textBack={t('commonText.backArrow')}
        iconHeader="adminAdd.svg"
        textHeader={t('pages_Setting_AdminRegister.textHeader')}
        linkBack={`/admin`}
        widthIconHeader={23}
        heightIconHeader={20}
      />
      <View style={styles.content}>
        <View style={styles.headerContent}>
          <Text style={styles.textHeaderContent}>{t('pages_Setting_AdminRegister.textHeaderContent')}</Text>
        </View>
        <Grid style={styles.wrapperContent}>
          <Row style={[styles.registerForm, { zIndex: 1 }]}>
            <Col size={2} nativeID="masterType">
              <View style={styles.flexRow}>
                <Text style={styles.textLabel}>Admin type</Text>
                <Text style={styles.iconRequired}>✴︎</Text>
              </View>
            </Col>

            <Col size={6}>
              <AdminType
                dataAdminType={dataAdminType}
                setValue={setValue}
                error={errors?.masterType}
                trigger={trigger}
              />
            </Col>
          </Row>
          <Row style={styles.registerForm}>
            <Col size={2}>
              <Text>{t('pages_Setting.walletAddress')}</Text>
            </Col>
            <Col size={6}>
              <Text style={styles.textWalletAddress}>{setting?.adminSettingAddress?.issuerAddress}</Text>
            </Col>
          </Row>
          <Row style={styles.registerForm}>
            <Col size={2} nativeID="levicaId">
              <View style={styles.flexRow}>
                <Text style={styles.textLabel}>LEVICA ID</Text>
                <Text style={styles.iconRequired}>✴︎</Text>
              </View>
            </Col>
            <Col size={6}>
              <JInput
                placeholder={t('pages_Setting_AdminRegister.levicaIdPlaceholder')}
                width="100%"
                paddingLeft={20}
                borderRadius={20}
                subfix="iconEdit.svg"
                widthSubfix={15}
                heightSubfix={15}
                control={control}
                name="levicaId"
              />
            </Col>
          </Row>

          <Row style={styles.registerForm}>
            <Col size={2} nativeID="name">
              <View style={styles.flexRow}>
                <Text>{t('pages_Setting_AdminRegister.name')}</Text>
                <Text style={styles.iconRequired}>✴︎</Text>
              </View>
            </Col>
            <Col size={6}>
              <JInput
                placeholder={t('pages_Setting_AdminRegister.namePlaceholder')}
                width="100%"
                paddingLeft={20}
                borderRadius={20}
                subfix="iconEdit.svg"
                widthSubfix={15}
                heightSubfix={15}
                control={control}
                name="name"
              />
            </Col>
          </Row>
          <Row style={styles.registerForm}>
            <Col size={2} nativeID="email">
              <View style={styles.flexRow}>
                <Text>{t('pages_Setting.email')}</Text>
                <Text style={styles.iconRequired}>✴︎</Text>
              </View>
            </Col>
            <Col size={6}>
              <JInput
                placeholder={t('pages_Setting_AdminRegister.emailPlaceholder')}
                width="100%"
                paddingLeft={20}
                borderRadius={20}
                subfix="iconEdit.svg"
                widthSubfix={15}
                heightSubfix={15}
                control={control}
                name="email"
              />
            </Col>
          </Row>
          <Row style={styles.registerForm}>
            <Col size={2} nativeID="phone">
              <View style={styles.flexRow}>
                <Text>{t('pages_Setting.phone')}</Text>
                <Text style={styles.iconRequired}>✴︎</Text>
              </View>
            </Col>
            <Col size={6}>
              <PhoneInput name="phone" control={control} />
            </Col>
          </Row>
        </Grid>
        <View style={styles.notify}>
          <Text style={styles.textLabel}>{t('pages_Setting_AdminRegister.textLabel')}</Text>
        </View>
      </View>
      <View style={styles.groupButton}>
        <View style={{ marginRight: 20 }}>
          <JButton
            width={200}
            text={t('commonText.cancelButton')}
            textColor={COLOR_TEXT_DEFAULT}
            btnColor={BTN_COLOR_GREY_2}
            onPressAction={goBack}
          />
        </View>
        <View style={{ marginLeft: 20 }}>
          <LinearGradient colors={['#54DBB2', '#54DBB2']} style={{ borderRadius: 5 }}>
            <JButton
              width={200}
              text={t('pages_Setting_AdminRegister.buttonSendRegistrationEmail')}
              textColor="#fff"
              onPressAction={handleSubmit(onSubmit)}
            />
          </LinearGradient>
        </View>
      </View>
      <ModalAlert
        isModalVisible={modalSubmit}
        setModalVisible={setModalSubmit}
        icon="groupMail.svg"
        iconHeight={30}
        iconWidth={40}
        modalText={t('pages_Setting_AdminRegister.modalAlertText')}
        linkPage="/admin"
      />
    </ScrollView>
  );
};

export default withNamespaces()(AdminRegister);

import React, { useEffect, useState } from 'react';
import { View, Text, Image, ScrollView, Pressable, Dimensions } from 'react-native';
import { Col, Grid } from 'react-native-easy-grid';
import { withNamespaces } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import { Center } from 'native-base';

import HeaderSearch from '@components/Headers/HeaderSearch';
import Pagination from '@components/common/Pagination';
import styles from '../style';
import HeaderPayManagement from '@components/PayManagement';
import { formatNumber } from '@helper/formatTypes';
import { get } from '@utils/RequestLevica';
import { API_PATH } from '@helper/constant';
import Loading from '@components/common/Loading';
import { JText } from '@components/common/JText';
import { BG_FORM_DEFAULT } from '@assets/style/styleDefault';

const PaymentPrepaidUsage = ({ t }) => {
  const [params, setParams] = useState({
    limit: 30,
    pageNumber: 1,
    q: '',
    startDate: '',
    endDate: '',
    sort: '0700',
    order: 'desc',
    status: '2',
  });

  const [tableHeadData, setTableHeadData] = useState([
    {
      label: t('pages_PaymentManager_PaymentPrepaidUsage.paymentDt'),
      size: 1,
      key: 'paymentDt',
      hasIcon: true,
      order: false,
      sort: '0700',
    },
    {
      label: t('pages_PaymentManager_PaymentPrepaidUsage.codeVerify'),
      size: 1,
      key: 'codeVerify',
      hasIcon: true,
      order: false,
      sort: '0200',
    },
    {
      label: t('pages_PaymentManager_PaymentPrepaidUsage.accountName'),
      size: 1,
      key: 'accountName',
      hasIcon: true,
      order: false,
      sort: '0300',
    },
    {
      label: t('pages_PaymentManager_PaymentPrepaidUsage.settlementAmount'),
      size: 1,
      key: 'amount',
      hasIcon: true,
      order: false,
      sort: '0400',
    },
    {
      label: t('pages_PaymentManager_PaymentPrepaidUsage.other'),
      size: 1,
      key: 'other',
      hasIcon: true,
      order: false,
      sort: '0800',
    },
    {
      label: 'TX ID',
      size: 3,
      key: 'txid',
      hasIcon: true,
      order: false,
      sort: '0900',
    },
  ]);

  const csvHeader = [
    {
      label: 'Symbol',
      key: 'itemSymbol',
    },
    {
      label: ' I.No',
      key: 'applicantId',
    },
    {
      label: 'ペイメント名',

      key: 'paymentNetworkName',
    },
    {
      label: t('pages_PaymentManager_PaymentPrepaidUsage.paymentDt'),
      key: 'paymentDt',
    },
    {
      label: t('pages_PaymentManager.userName'),
      key: 'firstNameKanaLastNameKana',
    },
    {
      label: '決済金額',
      key: 'paymentAmount',
    },
    {
      label: '相手先',
      key: 'accountNumber',
    },
    {
      label: 'TX ID',
      key: 'bankCode',
    },
  ];

  const {
    data: paidInfoListData,
    isFetching,
    remove,
  } = useQuery(
    ['PaymentPrepaidUsage', params],
    async ({ signal }) => {
      const data = await get(API_PATH.PAYMENT_CONFIRMATION_REQUEST, { ...params, signal });
      return data;
    },
    {
      refetchOnWindowFocus: false,
    }
  );

  const hanldeSort = (item) => {
    setTableHeadData((prev) =>
      prev.map((tableHead) =>
        tableHead.sort === item.sort ? { ...tableHead, order: !tableHead.order } : { ...tableHead, order: false }
      )
    );

    setParams({
      ...params,
      pageNumber: 1,
      sort: item.sort,
      order: !item.order ? 'asc' : 'desc',
    });
  };

  useEffect(() => remove, [remove]);

  return (
    <>
      <View
        style={[
          styles.container,
          {
            backgroundColor:
              isFetching || paidInfoListData?.paymentConfirmationRequestList?.length < 1 ? '' : BG_FORM_DEFAULT,
          },
        ]}
      >
        <View style={styles.wrapperStickyHeader}>
          <View style={styles.headerSearch}>
            <HeaderSearch
              setParams={setParams}
              params={params}
              placeholder={t('pages_PaymentManager_PaymentPrepaidUsage.placeholder')}
            />
          </View>

          <HeaderPayManagement
            csvHeader={csvHeader}
            csvName="前払利用履歴"
            exportCSVLink={API_PATH.PAYMENT_CONFIRMATION_REQUESTS_EXPORT_CSV}
            paymentListData={paidInfoListData}
            setParams={setParams}
            params={{ isCheckedAll: true, ...params }}
            countChecked={0}
            active={'/payment/prepaid-usage'}
            totalAmount={!paidInfoListData?.totalAmount || isFetching ? 0 : paidInfoListData?.totalAmount}
            datetimeTitle={t('components_PayManagement.timespace')}
          />
          <View>
            {paidInfoListData?.paymentConfirmationRequestList?.length > 0 && !isFetching && (
              <Grid style={[styles.head, { gap: 20 }]} size={30}>
                {tableHeadData?.map((item) => (
                  <Col size={item.size} style={styles.horizontal} key={item.label}>
                    <Text numberOfLines={2} style={styles.textHead}>
                      {item.label}
                    </Text>
                    {item.hasIcon && (
                      <Image
                        onClick={() => hanldeSort(item)}
                        style={item?.order ? styles.iconTableHeadDESC : styles.iconTableHeadASC}
                        source={require(`@assets/icons/iconDropdown.svg`)}
                      />
                    )}
                  </Col>
                ))}
              </Grid>
            )}
          </View>
        </View>
        <ScrollView showsVerticalScrollIndicator={false}>
          {isFetching ? (
            <Loading style={{ height: 'calc(100vh - 246px)' }} />
          ) : paidInfoListData?.paymentConfirmationRequestList?.length > 0 ? (
            paidInfoListData?.paymentConfirmationRequestList?.map((item, index, { length }) => {
              return (
                <Pressable key={index} style={({ hovered }) => [hovered && styles.rowHovered]}>
                  <Grid
                    style={[styles.headBody, { gap: 20, borderBottomWidth: index + 1 === length ? 0 : 1 }]}
                    size={30}
                  >
                    <Col size={1}>
                      <Text style={styles.text} numberOfLines={3}>
                        {item?.appliedAt}
                      </Text>
                    </Col>
                    <Col size={1}>
                      <Text numberOfLines={3} style={styles.text}>
                        {item?.tradingCode}
                      </Text>
                    </Col>
                    <Col size={1}>
                      <Text numberOfLines={3} style={styles.text}>
                        {item?.name}
                      </Text>
                    </Col>
                    <Col size={1}>
                      <Text numberOfLines={3} style={styles.text}>
                        {item?.amountPaidFormat ?? item.amount ? `¥ ${formatNumber(item.amount)}` : ''}
                      </Text>
                    </Col>
                    <Col size={1}>
                      <Text style={styles.text} numberOfLines={2}>
                        {item?.superAdminName}
                      </Text>
                    </Col>
                    <Col size={3}>
                      <Text style={styles.text} numberOfLines={2}>
                        {item?.txHash}
                      </Text>
                    </Col>
                  </Grid>
                </Pressable>
              );
            })
          ) : (
            <Center w="100%" h={Dimensions.get('window').height - 246}>
              <JText>{t('pages_PaymentManager_PaymentPrepaidUsage.haveNoPaymentPrepaidUsage')}</JText>
            </Center>
          )}
          <View>
            {params.limit < paidInfoListData?.pageInfo?.totalCount && !isFetching && (
              <Pagination
                count={Math.ceil(paidInfoListData?.pageInfo?.totalCount / params.limit)}
                params={params}
                setParams={setParams}
              />
            )}
          </View>
        </ScrollView>
      </View>
    </>
  );
};

export default withNamespaces()(PaymentPrepaidUsage);

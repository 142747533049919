import { StyleSheet } from 'react-native';
import { BG_COLOR, BG_FORM_DEFAULT, COLOR_TEXT_DEFAULT, FONT_SIZE_DEFAULT } from '@assets/style/styleDefault';

const styles = StyleSheet.create({
  //style common
  DFlex: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
  },
  defaultText: {
    fontSize: FONT_SIZE_DEFAULT,
    color: COLOR_TEXT_DEFAULT,
  },
  textError: {
    fontSize: '14px',
    color: 'red',
    paddingLeft: 5,
  },
  error: {
    color: 'red',
    fontSize: 12,
  },

  wrapperContainer: {
    flex: 1,
    backgroundColor: BG_COLOR,
    position: 'relative',
  },

  wrapperStickyHeader: {
    position: 'sticky',
    top: 0,
    backgroundColor: BG_COLOR,
    zIndex: 10,
  },
  headerItem: {
    paddingHorizontal: 5,
  },
  //style body
  wrapperBody: {
    backgroundColor: BG_FORM_DEFAULT,
    paddingHorizontal: 25,
    paddingTop: 30,
    paddingBottom: 50,
    minHeight: '90vh',
  },
  wrapperItem: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 15,
    gap: 15,
  },
  wrapperItemLeft: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  wrapperButton: {
    paddingTop: '30vh',
  },
});

export default styles;

import React, { useState } from 'react';
import { View, ScrollView } from 'react-native';
import { useLocation } from 'react-router-dom';
import { LinearGradient } from 'expo-linear-gradient';
import { Col, Grid } from 'react-native-easy-grid';
import { withNamespaces } from 'react-i18next';
import { useMutation } from '@tanstack/react-query';
import { useSelector } from 'react-redux';

import { BG_COLOR } from '@assets/style/styleDefault';
import HeaderSearch from '@components/Headers/HeaderSearch';
import SidebarListFolder from './SidebarListFolder';
import { JText as Text } from '@components/common/JText';
import TableHead from './TableHead';
import JButton from '@components/common/JButton';
import styles from './styles';
import ModalAlert from '@components/Modals/ModalAlert';
import ModalDelete from '@components/Modals/ModalDelete';
import ModalAddPdfFile from './ModalAddPdfFile';
import { del } from '@utils/RequestLevica';
import { API_PATH } from '@helper/constant';
import { checkPermission } from '@helper/commonFunction';
import { ROLE_USER } from '@helper/constant';

const FileManagerLayout = ({
  children,
  deleteDocument,
  pickPdfFile,
  modalAddPdfFile,
  setModalAddPdfFile,
  refetchFileList,
  params,
  setParams,
  pickFileByDrag,
  category,
  isHideTableHead,
  t,
  isAddingFile,
}) => {
  const [modalDelete, setModalDelete] = useState(false);
  const [modalAlert, setModalAlert] = useState(false);
  const [isDeletingFile, setIsDeletingFile] = useState(false);

  const { pathname } = useLocation();
  const hideIfOtherNotifications = pathname.includes('other-notifications');
  const userInfo = useSelector((store) => store?.merchantUserInfo);

  const { mutate: removeFiles } = useMutation({
    mutationFn: async (idsFileRemove) => {
      return await del(`${API_PATH.CONTRACT_FILE}/remove/${idsFileRemove}`);
    },
    retry: 3,
  });

  const onDelete = () => {
    setIsDeletingFile(true);
    removeFiles(
      deleteDocument.reduce((ids, { id }, index) => (index === 0 ? (ids += id) : (ids += `,${id}`)), ''),
      {
        onSuccess: () => {
          refetchFileList();
          setModalDelete && setModalDelete(!modalDelete);
          setModalAlert && setModalAlert(!modalAlert);
          setIsDeletingFile(false);
        },
        onError: (error) => {
          setIsDeletingFile(false);
        },
      }
    );
  };

  const handleDelete = () => {
    if (deleteDocument.length > 0) {
      setModalDelete(true);
    }
  };

  return (
    <View style={{ height: 'calc(100vh - 10px)' }}>
      <View style={styles.headerSearch}>
        <HeaderSearch
          placeholder={t('pages_FileManager_partials_FileManagerLayout.placeholder')}
          params={params}
          setParams={setParams}
          category={category}
        />
      </View>

      <View style={styles.headerButton}>
        <Text style={{ color: '#797979' }}>{t('pages_FileManager_partials_FileManagerLayout.folderName')}</Text>
        {!hideIfOtherNotifications && (
          <View style={{ flexDirection: 'row', gap: 30, paddingBottom: 10 }}>
            <LinearGradient colors={['#32A5F7', '#2D81F3']} style={{ borderRadius: 5 }}>
              <JButton
                textColor={'#fff'}
                text={t('pages_FileManager_partials_FileManagerLayout.buttonAddFile')}
                width={200}
                disabled={!checkPermission(ROLE_USER.DOCUMENT_MANAGEMENT, userInfo)}
                onPressAction={() => setModalAddPdfFile(true)}
              />
            </LinearGradient>
            <LinearGradient colors={['#FE8463', '#FF0000']} style={{ borderRadius: 5 }}>
              <JButton
                disabled={deleteDocument.length < 1}
                text={t('commonText.buttonDelete')}
                width={130}
                linkIcon={'iconDeleteGray.svg'}
                linkIconDisabled={'iconDeleteDisable.svg'}
                widthIcon={23}
                heightIcon={23}
                marginBottomIcon={8}
                textColor={'#fff'}
                onPressAction={() => handleDelete()}
              />
            </LinearGradient>
          </View>
        )}
      </View>
      <Grid style={{ backgroundColor: BG_COLOR }}>
        <Col size={3}>
          <ScrollView showsVerticalScrollIndicator={false}>
            <SidebarListFolder />
          </ScrollView>
        </Col>

        <Col size={9}>
          {/* {!hideIfOtherNotifications && !isHideTableHead && <TableHead params={params} setParams={setParams} />} */}
          {
            <TableHead
              isDisplay={!hideIfOtherNotifications && !isHideTableHead}
              params={params}
              setParams={setParams}
            />
          }
          <ScrollView showsVerticalScrollIndicator={false}>{children}</ScrollView>
        </Col>
      </Grid>
      <ModalAddPdfFile
        isModalVisible={modalAddPdfFile}
        setModalVisible={setModalAddPdfFile}
        pickPdfFile={pickPdfFile}
        pickFileByDrag={pickFileByDrag}
        isAddingFile={isAddingFile}
      />
      <ModalAlert
        isModalVisible={modalAlert}
        setModalVisible={setModalAlert}
        icon="success-red.svg"
        iconWidth={20}
        iconHeight={20}
        modalText={t('pages_FileManager_partials_FileManagerLayout.ModalAlertText')}
      />
      <ModalDelete
        iconBtnLeft="close-white.svg"
        iconBtnRight="delete-white.svg"
        heightIconBtnRight={21}
        widthIconBtnRight={21}
        marginBottomIconRight={8}
        textBtnRight={t('commonText.buttonDeleteFile')}
        textBtnLeft={t('commonText.cancelButton')}
        modalDelete={modalDelete}
        setModalDelete={setModalDelete}
        modalAlert={modalAlert}
        setModalAlert={setModalAlert}
        icon="iconDeletedActive.svg"
        textHeader={t('pages_FileManager_partials_FileManagerLayout.ModalDeleteTextHeader')}
        textHeaderColor="#191919"
        numberItemDelete={deleteDocument?.length ?? null}
        textContent={[
          t('pages_FileManager_partials_FileManagerLayout.ModalDeleteTextContent1'),
          t('pages_FileManager_partials_FileManagerLayout.ModalDeleteTextContent2'),
          ,
        ]}
        colorTextTitle="#5A5A5A"
        pv={30}
        iconBody="warning.svg"
        func={onDelete}
        isDeletingFile={isDeletingFile}
      />
    </View>
  );
};

export default withNamespaces()(FileManagerLayout);

import React from 'react';

import { Route, Switch, Redirect } from '@config/react-router-web';
import { getCookie } from '@utils/TokenStorage';
import { ROLE_USER, STORAGEKEY } from '@helper/constant';
import { checkPermission } from '@helper/commonFunction';

// 1 Login page
import Login from '@pages/Login';
import ResetPassword from '@pages/Login/ResetPassword';
import Completed from '@pages/Login/Completed';
import ForgotPassword from '@pages/Login/ForgotEmail';
import ForgotAddress from '@pages/Login/ForgotPassword';
import OtpAuthLogin from '@pages/Login/OtpAuthLogin';

// 2 Home page
import Home from '@pages/Home';
import SendTokenToUser from '@pages/Home/SendTokenToUser';
import SendTokenFromManagement from '@pages/Home/SendTokenFromManagement';
import SendTokenFromIssuing from '@pages/Home/SendTokenFromIssuing';

// 3 Admin pages
import AdminSetting from '@pages/Setting';
import AdminRegister from '@pages/Setting/AdminRegister';
import AdminAccountSetting from '@pages/Setting/AdminSetting';
import SettingInfo from '@pages/Setting/Info';
import MasterChange from '@pages/Setting/MasterChange';
import MemberChange from '@pages/Setting/MemberChange';
import AdminChangePassword from '@pages/Setting/ChangePassword';

// 4 Token pages
import Token from '@pages/Token';
import AddGift from '@pages/Token/AddGift';
import AddPoint from '@pages/Token/AddPoint';
import PointDetail from '@pages/Token/PointDetail';
import GiftDetail from '@pages/Token/GiftDetail';

// AccountManagement list
import AccountManagement from '@pages/AccountManagement';
import CreateAccount from '@pages/AccountManagement/CreateAccount';

// AccountCompany list
import AccountCompany from '@pages/AccountCompany';
import CompanyCreate from '@pages/AccountCompany/CreateAccount';

// AccountCompany list
import AccountShop from '@pages/AccountShop';
import ShopCreate from '@pages/AccountShop/CreateAccount';
import BankInformation from '@pages/AccountShop/CreateAccount/BankInformation';

// File Manager
import LEVICATerms from '@pages/FileManager/LEVICATerms';
import MerchantTerms from '@pages/FileManager/MerchantTerms';
import PrivacyPolicy from '@pages/FileManager/PrivacyPolicy';
import CommercialTransactionLaw from '@pages/FileManager/CommercialTransactionLaw';
import OtherNotifications from '@pages/FileManager/OtherNotifications';
import SMSNotifications from '@pages/FileManager/SMSNotifications';
import MailNotifications from '@pages/FileManager/MailNotifications';
import MerchantTermsOfUse from '@pages/FileManager/MerchantTermsOfUse';

//TransactionInformation
import TransactionInformation from '@pages/TransactionInformation';

// Information
import Information from '@pages/Infomation';
import InfomationCreate from '@pages/Infomation/InformationCreate';

//Payment
import PaymentIncomes from '@pages/PaymentManager';
import PaymentDeposites from '@pages/PaymentManager/PaymentDeposites';
import PaymentPrepaidUsage from '@pages/PaymentManager/PaymentPrepaidUsage';
import PaymentConfirmed from '@pages/PaymentManager/PaymentConfirmed';
import PaymentComplete from '@pages/PaymentManager/PaymentComplete';
import AddPaymentTransaction from '@pages/PaymentManager/AddPaymentTransaction';

// Others
import NotFoundPage from '@pages/404';
import SumRefundPayment from '@pages/SumRefundPayment';

const appRouter = [
  //1 login
  {
    name: 'Login',
    path: '/login',
    component: Login,
    meta: {
      role: ROLE_USER.PUBLIC,
      isPrivate: false,
      hidden: false,
      child: false,
    },
  },

  {
    name: 'OtpAuthLogin ',
    path: '/otp-auth',
    component: OtpAuthLogin,
    meta: {
      role: ROLE_USER.PUBLIC,
      isPrivate: false,
      hidden: false,
      child: false,
    },
  },

  {
    name: 'ForgotPasswordAddress ',
    path: '/forgot-password/address',
    component: ForgotAddress,
    meta: {
      role: ROLE_USER.PUBLIC,
      isPrivate: false,
      hidden: false,
      child: true,
    },
    children: [
      {
        name: 'ForgotPasswordAddress Competed',
        path: '/completed',
        component: Completed,
        meta: {
          role: ROLE_USER.PUBLIC,
          isPrivate: false,
          hidden: false,
          child: false,
        },
      },
    ],
  },

  {
    name: 'ForgotPasswordPhone ',
    path: '/forgot-password/phone',
    component: ForgotPassword,
    meta: {
      role: ROLE_USER.PUBLIC,
      isPrivate: false,
      hidden: false,
      child: true,
    },
    children: [
      {
        name: 'ForgotPasswordPhoneCompleted ',
        path: '/completed',
        component: Completed,
        meta: {
          role: ROLE_USER.PUBLIC,
          isPrivate: false,
          hidden: false,
          child: false,
        },
      },
    ],
  },

  {
    name: 'ResetPassword ',
    path: '/reset-password',
    component: ResetPassword,
    meta: {
      role: ROLE_USER.PUBLIC,
      isPrivate: false,
      hidden: false,
      child: true,
    },
    children: [
      {
        name: 'ResetPasswordCompleted',
        path: '/completed',
        component: Completed,
        meta: {
          role: ROLE_USER.PUBLIC,
          isPrivate: false,
          hidden: false,
          child: false,
        },
      },
    ],
  },

  //2 home
  {
    name: 'Home',
    path: '/',
    component: Home,
    meta: {
      role: ROLE_USER.PUBLIC,
      isPrivate: true,
      hidden: false,
      child: false,
    },
  },
  {
    name: 'SendTokenToUser',
    path: '/send-token-admin-to-user',
    component: SendTokenToUser,
    meta: {
      role: ROLE_USER.USER_WALLET_OPERATION,
      isPrivate: true,
      hidden: false,
      child: false,
    },
  },

  {
    name: 'SendTokenFromManagement',
    path: '/send-token-from-management-wallet',
    component: SendTokenFromManagement,
    meta: {
      role: ROLE_USER.ADMIN_WALLET_OPERATION,
      isPrivate: true,
      hidden: false,
      child: false,
    },
  },
  {
    name: 'SendTokenFromIssuing',
    path: '/send-token-from-issuing-wallet',
    component: SendTokenFromIssuing,
    meta: {
      role: ROLE_USER.PUBLISH_WALLET_OPERATION,
      isPrivate: true,
      hidden: false,
      child: false,
    },
  },
  //3 admin setting
  {
    name: 'Admin Setting',
    path: '/admin',
    component: AdminSetting,
    meta: {
      role: ROLE_USER.PUBLIC,
      isPrivate: true,
      hidden: false,
      child: true,
    },
    children: [
      {
        name: 'Admin Setting Role',
        path: '/account/setting',
        component: AdminAccountSetting,
        meta: {
          role: ROLE_USER.SETTING_ROLE,
          isPrivate: true,
          hidden: false,
          child: false,
        },
      },
      {
        name: 'Admin_account_register',
        path: '/account/register',
        component: AdminRegister,
        meta: {
          role: ROLE_USER.CREATE_ADMIN,
          isPrivate: true,
          hidden: false,
          child: false,
        },
      },
      {
        name: 'Master Change Confirm',
        path: '/master-change/:accountId',
        component: MasterChange,
        meta: {
          role: ROLE_USER.PUBLIC,
          isPrivate: true,
          hidden: false,
          child: false,
        },
      },
      {
        name: 'Master Change Confirm',
        path: '/member-change/:accountId',
        component: MemberChange,
        meta: {
          role: ROLE_USER.PUBLIC,
          isPrivate: true,
          hidden: false,
          child: false,
        },
      },
      {
        name: 'Change Password',
        path: '/change-password',
        component: AdminChangePassword,
        meta: {
          role: ROLE_USER.PUBLIC,
          isPrivate: true,
          hidden: false,
          child: false,
        },
      },
    ],
  },
  //4 token list
  {
    name: 'Token',
    path: '/tokens',
    component: Token,
    meta: {
      role: ROLE_USER.PUBLISH_TOKEN,
      isPrivate: true,
      hidden: false,
      child: true,
    },
    children: [
      {
        name: 'Add Point',
        path: '/add-point',
        component: AddPoint,
        meta: {
          role: ROLE_USER.PUBLISH_TOKEN,
          isPrivate: true,
          hidden: false,
          child: false,
        },
      },
      {
        name: 'Add Gift',
        path: '/add-gift',
        component: AddGift,
        meta: {
          role: ROLE_USER.PUBLISH_TOKEN,
          isPrivate: true,
          hidden: false,
          child: false,
        },
      },
      {
        name: 'Point detail',
        path: '/point/:productId',
        component: PointDetail,
        meta: {
          role: ROLE_USER.PUBLISH_TOKEN,
          isPrivate: true,
          hidden: false,
          child: false,
        },
      },
      {
        name: 'SendTokenToUser',
        path: '/send-point-from-issuing-wallet',
        component: SendTokenToUser,
        meta: {
          role: ROLE_USER.PUBLISH_TOKEN,
          isPrivate: true,
          hidden: false,
          child: false,
        },
      },
      {
        name: 'SendTokenToUser',
        path: '/send-point-from-management-wallet',
        component: SendTokenToUser,
        meta: {
          role: ROLE_USER.PUBLISH_TOKEN,
          isPrivate: true,
          hidden: false,
          child: false,
        },
      },
      {
        name: 'Gift detail',
        path: '/gift/:productId',
        component: GiftDetail,
        meta: {
          role: ROLE_USER.PUBLISH_TOKEN,
          isPrivate: true,
          hidden: false,
          child: false,
        },
      },
      {
        name: 'SendTokenToUser',
        path: '/send-gift-from-issuing-wallet',
        component: SendTokenToUser,
        meta: {
          role: ROLE_USER.PUBLISH_TOKEN,
          isPrivate: true,
          hidden: false,
          child: false,
        },
      },
    ],
  },

  //AccountManagement list
  {
    name: 'AccountManager',
    path: '/accounts',
    component: AccountManagement,
    meta: {
      role: ROLE_USER.PUBLIC,
      isPrivate: true,
      hidden: false,
      child: true,
    },

    children: [
      {
        name: 'AccountManagerDetails',
        path: '/details/:accountId',
        component: CreateAccount,
        meta: {
          role: ROLE_USER.PUBLIC,
          isPrivate: true,
          hidden: false,
          child: false,
        },
      },
    ],
  },

  //AccountCompany list
  {
    name: 'AccountCompany',
    path: '/companies',
    component: AccountCompany,
    meta: {
      role: ROLE_USER.PUBLIC,
      isPrivate: true,
      hidden: false,
      child: true,
    },
    children: [
      {
        name: 'CompanyDetails',
        path: '/details/:accountId',
        component: CompanyCreate,
        meta: {
          role: ROLE_USER.PUBLIC,
          isPrivate: true,
          hidden: false,
          child: false,
        },
      },
      {
        name: 'CompanyCreate',
        path: '/create',
        component: CompanyCreate,
        meta: {
          role: ROLE_USER.USER_MANAGEMENT,
          isPrivate: true,
          hidden: false,
          child: false,
        },
      },
    ],
  },

  //AccountShop list
  {
    name: 'AccountShop',
    path: '/shop',
    component: AccountShop,
    meta: {
      role: ROLE_USER.PUBLIC,
      isPrivate: true,
      hidden: false,
      child: true,
    },
    children: [
      {
        name: 'ShopDetails',
        path: '/details/:accountId',
        component: ShopCreate,
        meta: {
          role: ROLE_USER.PUBLIC,
          isPrivate: true,
          hidden: false,
          child: false,
        },
      },
      {
        name: 'ShopCreate',
        path: '/create',
        component: ShopCreate,
        meta: {
          role: ROLE_USER.USER_MANAGEMENT,
          isPrivate: true,
          hidden: false,
          child: false,
        },
      },
      {
        name: 'CompanyDetails',
        path: '/details/:accountId/bank',
        component: BankInformation,
        meta: {
          role: ROLE_USER.USER_MANAGEMENT,
          isPrivate: true,
          hidden: false,
          child: false,
        },
      },
      {
        name: 'CompanyDetails',
        path: '/create-bank',
        component: BankInformation,
        meta: {
          role: ROLE_USER.USER_MANAGEMENT,
          isPrivate: true,
          hidden: false,
          child: false,
        },
      },
    ],
  },

  //File Manager

  {
    name: 'Investment Contract',
    path: '/file-manager',
    component: LEVICATerms,
    meta: {
      role: ROLE_USER.PUBLIC,
      isPrivate: true,
      hidden: false,
      child: true,
    },
    children: [
      {
        name: 'LEVICA-terms',
        path: '/LEVICA-terms',
        component: LEVICATerms,
        meta: {
          role: ROLE_USER.PUBLIC,
          isPrivate: true,
          hidden: false,
          child: false,
        },
      },
      {
        name: 'Merchant Terms ',
        path: '/merchant-terms',
        component: MerchantTerms,
        meta: {
          role: ROLE_USER.PUBLIC,
          isPrivate: true,
          hidden: false,
          child: false,
        },
      },
      {
        name: 'Privacy Policy',
        path: '/privacy-policy',
        component: PrivacyPolicy,
        meta: {
          role: ROLE_USER.PUBLIC,
          isPrivate: true,
          hidden: false,
          child: false,
        },
      },
      {
        name: 'Commercial Transaction Law',
        path: '/commercial-transaction-law',
        component: CommercialTransactionLaw,
        meta: {
          role: ROLE_USER.PUBLIC,
          isPrivate: true,
          hidden: false,
          child: false,
        },
      },
      {
        name: 'Merchant Term Of Use',
        path: '/merchant-terms-of-use',
        component: MerchantTermsOfUse,
        meta: {
          role: ROLE_USER.PUBLIC,
          isPrivate: true,
          hidden: false,
          child: false,
        },
      },
      {
        name: 'Other Notifications',
        path: '/other-notifications',
        component: OtherNotifications,
        meta: {
          role: ROLE_USER.PUBLIC,
          isPrivate: true,
          hidden: false,
          child: true,
        },
        children: [
          {
            name: 'SMS Notifications',
            path: '/sms-notifications/create',
            component: SMSNotifications,
            meta: {
              role: ROLE_USER.DOCUMENT_MANAGEMENT,
              isPrivate: true,
              hidden: false,
              child: false,
            },
          },
          {
            name: 'SMS Notifications',
            path: '/sms-notifications/detail/:smsId',
            component: SMSNotifications,
            meta: {
              role: ROLE_USER.DOCUMENT_MANAGEMENT,
              isPrivate: true,
              hidden: false,
              child: false,
            },
          },
          {
            name: 'SMS Notifications',
            path: '/sms-notifications/update',
            component: SMSNotifications,
            meta: {
              role: ROLE_USER.DOCUMENT_MANAGEMENT,
              isPrivate: true,
              hidden: false,
              child: false,
            },
          },
          {
            name: 'Mail Notifications',
            path: '/mail-notifications/create',
            component: MailNotifications,
            meta: {
              role: ROLE_USER.DOCUMENT_MANAGEMENT,
              isPrivate: true,
              hidden: false,
              child: false,
            },
          },
          {
            name: 'Mail Notifications',
            path: '/mail-notifications/detail/:mailId',
            component: MailNotifications,
            meta: {
              role: ROLE_USER.DOCUMENT_MANAGEMENT,
              isPrivate: true,
              hidden: false,
              child: false,
            },
          },
          {
            name: 'Mail Notifications',
            path: '/mail-notifications/update',
            component: MailNotifications,
            meta: {
              role: ROLE_USER.DOCUMENT_MANAGEMENT,
              isPrivate: true,
              hidden: false,
              child: false,
            },
          },
        ],
      },
    ],
  },

  //TransactionInformation

  {
    name: 'TransactionInformation ',
    path: '/transaction-information',
    component: TransactionInformation,
    meta: {
      role: ROLE_USER.PUBLIC,
      isPrivate: true,
      hidden: false,
      child: false,
    },
  },

  //Information
  {
    name: 'Information',
    path: '/information',
    component: Information,
    meta: {
      role: ROLE_USER.PUBLIC,
      isPrivate: true,
      hidden: false,
      child: true,
    },
    children: [
      {
        name: 'InformationCreate',
        path: '/create',
        component: InfomationCreate,
        meta: {
          role: ROLE_USER.PUBLIC,
          isPrivate: true,
          hidden: false,
          child: false,
        },
      },
      {
        name: 'CompanyDetails',
        path: '/details/:infoId',
        component: InfomationCreate,
        meta: {
          role: ROLE_USER.PUBLIC,
          isPrivate: true,
          hidden: false,
          child: false,
        },
      },
    ],
  },

  //setting
  {
    name: 'Setting_Info',
    path: '/setting',
    component: SettingInfo,
    meta: {
      role: ROLE_USER.PUBLIC,
      isPrivate: true,
      hidden: false,
      child: false,
    },
  },

  //payment
  {
    name: 'PaymentListManager',
    path: '/payment',
    component: PaymentIncomes,
    meta: {
      role: ROLE_USER.PUBLIC,
      isPrivate: true,
      hidden: false,
      child: true,
    },
    children: [
      {
        name: 'PaymentDeposites',
        path: '/deposites',
        component: PaymentDeposites,
        meta: {
          role: ROLE_USER.PUBLIC,
          isPrivate: true,
          hidden: false,
          child: false,
        },
      },
      {
        name: 'PaymentPrepaidUsage',
        path: '/prepaid-usage',
        component: PaymentPrepaidUsage,
        meta: {
          role: ROLE_USER.PUBLIC,
          isPrivate: true,
          hidden: false,
          child: false,
        },
      },
      {
        name: 'PaymentConfirmed',
        path: '/confirmed',
        component: PaymentConfirmed,
        meta: {
          role: ROLE_USER.PUBLIC,
          isPrivate: true,
          hidden: false,
          child: false,
        },
      },
      {
        name: 'PaymentComplete',
        path: '/complete',
        component: PaymentComplete,
        meta: {
          role: ROLE_USER.PUBLIC,
          isPrivate: true,
          hidden: false,
          child: false,
        },
      },
      {
        name: 'AddPaymentTransaction',
        path: '/add-payment-transaction',
        component: AddPaymentTransaction,
        meta: {
          role: ROLE_USER.PUBLIC,
          isPrivate: true,
          hidden: false,
          child: false,
        },
      },
    ],
  },

    //sum refund payment
    {
        name: 'PaymentListManager',
        path: '/sum-refund-payment',
        component: SumRefundPayment,
        meta: {
          role: ROLE_USER.PUBLIC,
          isPrivate: true,
          hidden: false,
          child: true,
        },
      },
];

export const whiteList = [
  '/login',
  '/forget-password',
  '/sign-up',
  '/forgot-password/phone',
  '/forgot-password/phone/completed',
  '/reset-password',
  '/reset-password/completed',
  '/forgot-password/address',
  '/forgot-password/address/completed',
  '/otp-auth',
];

const PrivateRoute = (props) => {
  return (
    <Route
      path={props.path}
      exact
      render={(prop) =>
        getCookie(STORAGEKEY.REFRESH_TOKEN) ? (
          <>{React.createElement(props.component, prop)}</>
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { redirect_url: prop.location },
            }}
          />
        )
      }
    />
  );
};

const WhiteListRoute = (props) => {
  const isWhiteList = (path) => !getCookie(STORAGEKEY.REFRESH_TOKEN) && whiteList.indexOf(path) >= 0;

  return (
    <Route
      path={props.path}
      exact
      render={(prop) =>
        isWhiteList(props.path) ? (
          <>{React.createElement(props.component, prop)}</>
        ) : (
          <Redirect to={{ pathname: '/' }} />
        )
      }
    />
  );
};

const renderRouter = (routes) => {
  let arr = [];
  routes.forEach((route) => {
    if (route) {
      const tmpRoute = route.meta.isPrivate ? (
        <PrivateRoute exact path={route.path} component={route.component} key={route.name} />
      ) : (
        <WhiteListRoute exact path={route.path} component={route.component} key={route.name} />
      );

      arr.push(tmpRoute);
    }
  });
  return arr;
};
const mergeRouter = (routes) => {
  let arr = [];
  routes?.forEach((route) => {
    arr.push(route);
    if (route?.meta?.child) {
      const newRoute = route?.children?.map((item) => {
        return { ...item, path: route?.path.concat(item?.path) };
      });
      arr = arr.concat(mergeRouter(newRoute));
    }
  });
  return arr;
};

export const routes = (userInfo) => {
  const newRoutes = mergeRouter(appRouter).map((item) => (checkPermission(item?.meta?.role, userInfo) ? item : false));

  return (
    <Switch>
      {renderRouter(newRoutes).map((render) => render)}
      <Route path="*" component={NotFoundPage} />
    </Switch>
  );
};

export default routes;

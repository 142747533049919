import React from 'react';
import { View, StyleSheet, Image } from 'react-native';
import Modal from 'react-native-modal';
import { Link, useHistory } from 'react-router-dom';
import { withNamespaces } from 'react-i18next';

import { FONT_SIZE_HEADER, BORDER_TABLE_GREY } from '@assets/style/styleDefault';
import { JText as Text } from '../common/JText';
import ModalBackDrop from './ModalBackDrop';

const ModalSendSuccess = (props) => {
  const {
    isModalVisible,
    setModalVisible,
    textHeader,
    content,
    linkBack,
    iconHeader = 'sendBlue.svg',
    heightIconHeader = 28,
    widthIconHeader = 35,
    isClickBackDrop=true,
    t,
  } = props;
  const history = useHistory();

  const goBack = () => {
    if (linkBack&&isClickBackDrop) {
      history.push(linkBack);
    } else {
      setModalVisible(false);
    }
  };
  return (
    <View>
      <Modal
        animationIn="fadeIn"
        isVisible={isModalVisible}
        style={{ alignItems: 'center' }}
        customBackdrop={<ModalBackDrop onPress={() => setModalVisible(!isClickBackDrop)} />}
        onClick={() => goBack()}
      >
        <View style={styles.wrapperModal} >
          <View style={styles.maxWidth700}>
            <View style={styles.header}>
              <Image
                source={require(`../../assets/icons/${iconHeader}`)}
                style={[styles.statusIcon, { width: widthIconHeader, height: heightIconHeader }]}
                alt="statusIcon"
              />
              <Text style={styles.textHeader}>{textHeader ?? t('components_Modals_ModalSendSuccess.textHeader1')}</Text>
            </View>
            <View style={styles.body}>
              <Text style={styles.textHeader}>{content ?? t('components_Modals_ModalSendSuccess.textHeader2')}</Text>
            </View>
          </View>
        </View>
      </Modal>
    </View>
  );
};

export default withNamespaces()(ModalSendSuccess);

const styles = StyleSheet.create({
  //common

  textHeader: {
    color: 'black',
    fontSize: FONT_SIZE_HEADER,
  },
  wrapperModal: {
    maxWidth: 900,
    minWidth: 600,
    cursor: 'pointer',
  },

  iconWarning: {
    width: 55,
    height: 46,
    padding: 10,
    marginVertical: 20,
  },
  maxWidth700: {
    backgroundColor: '#fff',
    borderRadius: 10,
  },
  header: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    borderBottomColor: BORDER_TABLE_GREY,
    borderBottomWidth: 1,
    paddingVertical: 15,
  },
  statusIcon: {
    width: 35,
    height: 28,
    marginBottom: 2,
    marginRight: 10,
  },
  body: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 40,
    minHeight: 200,
  },
});
